<template>
  <Container class="statistics">
    <div class="text item">
      <el-table
        style="width: 100%"
        height="calc(100vh - 265px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
       <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="recharge_record_no"
          label="充值流水号"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="get_user.nickname"
          label="用户"
          min-width="200"
          align="left"
        ></el-table-column>

        <el-table-column label="支付方式" min-width="150" align="left">
          <template slot-scope="scope">
            {{ stateMap[scope.row.to_payment] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="total_amount"
          label="充值金额"
          min-width="130"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.total_amount }}元</div>
          </template>
        </el-table-column>
         <el-table-column
          label="额外赠送金额"
          min-width="130"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.new_money }}元</div>
          </template>
        </el-table-column>
         <el-table-column
          label="活动赠送金额"
          min-width="130"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.use_money }}元</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="remark"
          label="备注"
          width="180"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="state"
          label="支付状态"
          width="120"
          align="left"
        >
        <template slot-scope="scope">
            {{ statusMap[scope.row.status] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="支付时间"
          width="200"
          align="left"
        ></el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
    tableData: {
      default: () => [],
    },
  },
  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {

      typeMap: [
        {
          text: "收入",
          type: "success",
          symbol: "+",
        },
        {
          text: "支出",
          type: "danger",
          symbol: "-",
        },
      ],

      moduleMap: {
        points: "积分",
        money: "消费",
        card: "购物卡",
      },
      stateMap: ['账户资金', '现金', '支付宝', '微信', '银行卡', '购物卡', '保险', '其它'],
      statusMap: ['未支付', '已支付'],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;
}
</style>
