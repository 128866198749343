/**
 * 编辑一个患者的预约详情，更改预约信息 API
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/appointment_record/method/add.patient.vital.signs/';

export default (data) => {

    return axios.post(url, qs.stringify({
        "format": "json",
        ...data
    })).then(({data}) => data)
        .then(({data}) => data);
}
