<template>
  <Container>
    <Header></Header>
    <Content></Content>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Header", "Content"], "configuration.Hospital.Center"),

    ...components(["Container"]),
  },

  props: {},

  data() {
    return {};
  },
  watch: {},
  methods: {},
};
</script>

<style lang="scss" scoped>
// .see-patient-history-list-item {
//   width: 98%;
//   padding: 0 19px;
//   height: calc(100vh - 140px);
//   box-shadow: 0 2px 7px 0 #d8d8d8;
//   margin: 2px auto 10px;
// }
</style>
