<template>
  <Container class="details hb-layout-horizontal">
    <Container class="left hb-layout-vertical">
      <LeftMain @getleftchangesix="onGetLeftChangeSix"></LeftMain>
    </Container>
    <Container class="centent hb-layout-flex-1 hb-layout-vertical">
      <CenterMain
       :loading="loading"
        :listRecordsData="treeDatas"
        :tabData="tabData"
        @handleselectitem="onHandleSelectItem"
        @handledelselectitem="onHandleDelDelectItem"
        @addsubmit="onAddSubmit"
      ></CenterMain>
    </Container>
    <Container class="right hb-layout-vertical">
      <RightMain
        ref="rightSelectData"
        :tabData="tabData"
        :companyDatas="companyDatas"
         :levelDatas="levelDatas"
        :departmentData="departmentData"
        :rolesData="rolesData"
        @submititemdata="onSubmitItemData"
        @selectlevelitem="onSelectLevelItem"
      ></RightMain>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      ["Left.Main", "Center.Main", "Center.Main", "Right.Main"],
      "configuration.Insurance"
    ),
    ...components(["Container"]),
  },

  props: {
    loading: {
      default: false,
    },
    levelDatas:{},
    companyDatas: {},
    treeDatas: {},
    rolesData: {},
    departmentData: {},
    tabData: 0,
  },

  data() {
    return {};
  },

  methods: {
    onGetLeftChangeSix(val) {
      this.$emit("getleftchangesix", val);
    },
    onHandleSelectItem(itemdata) {
      this.$refs.rightSelectData.handleSelectItems(itemdata);
    },
    onHandleDelDelectItem(itemdata) {
      this.$emit("delselectitem", itemdata);
    },
    onSubmitItemData(itemdata) {
      //console.log("122",itemdata);
      this.$emit("submitexamination", itemdata);
    },
    onAddSubmit() {
      this.$refs.rightSelectData.onAddSubmit();
    },
    onSelectLevelItem(data) {
      this.$emit("selectlevelitem", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .left {
    width: 300px;
  }
  .right {
    width: 300px;
  }
}
</style>
