<template>
  <Container class="hb-layout-vertical">
    <!--    左：顶部搜索-->
    <LeftSearch :loading="loading" @leftsearch="onleftSearch"></LeftSearch>

    <LeftScreen
      @alldoctor="onAllDoctor"
      @todayevent="onTodayEvent"
      @sevenevent="onSevenEvent"
      @thirtyevent="onThirtyEvent"
      @calendarevent="onCalendarEvent"
      :allDoctors="allDoctors"
    ></LeftScreen>

    <div
      class="hb-layout-flex-1"
      :style="{
        position: 'relative',
      }"
    >
      <div class="hb-layout-fit-sized">
        <List
          ref="list"
          class="hb-layout-fit-unsized"
          itemCls=".see-patient-list-item"
          @itemselect="onItemSelect"
          v-model="selected"
          :scroll="true"
          :is_test="true"
          :data="data"
        >
          <template v-slot:item="{ record, selected }">
            <LeftItems
              :doctorId="record.doctorId"
              :name="record.name"
              :appointment_num="record.count_appointment"
              :register_num="record.count_register"
              :wait_num="record.count_wait"
              :end_num="record.count_end"
              :selected="selected"
            ></LeftItems>
          </template>
        </List>
      </div>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Left.Items", "Left.Search", "Left.Screen"], "appointment"),
    ...components(["List", "Container"]),
  },

  props: {
    data: Array,
    allDoctors:0,
  },

  watch: {
    data(data) {
      let me = this;

      // me.$nextTick(() => {
      //   if (data.length) {
      //     me.$refs.list.select(data[0]);
      //   }
      // });
    },
  },

  data() {
    return {
      loading: {},
      selected: null,
      options: [
        {
          value: undefined,
          label: "全部",
        },
        {
          value: 0,
          label: "在班",
        },
        {
          value: 1,
          label: "未在班",
        },
      ],
      status: undefined,
    };
  },

  methods: {
    /**
     * 改变状态
     */
    onChangeStatus(command) {
      this.$emit("changestatus", command);
    },
    onItemSelect(doctorId) {
      // onItemSelect绑定父页面的方法doctoritemselect

      this.$emit("doctoritemselect", doctorId);
    },

    // select(doctorId) {
    //   this.$refs.list.select(doctorId);
    // },
    onleftSearch(command) {
      this.$emit("leftsearch", command);
    },
    onAllDoctor() {
      this.selected=1;
      this.$emit("alldoctor");
      //console.log("selected",this.selected);
    },
    onTodayEvent() {
      this.$emit("todayevent");
    },
    onSevenEvent() {
      this.$emit("sevenevent");
    },
    onThirtyEvent() {
      this.$emit("thirtyevent");
    },
    onCalendarEvent(e) {
      this.$emit("calendarevent",e);
    },
  },
};
</script>

<style lang="scss" scoped>
$see-patient-list-header-height: 50px;

$see-patient-list-padding: 0 10px 0 10px;

.see-patient-list {
  > .header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 16px 0 18px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;

    > .tody {
      padding-right: 15px;
    }
  }

  .el-dropdown-link {
    font-size: 12px;
    padding-left: 20px;
  }
}
</style>
