let USER ;

if(sessionStorage.getItem('HB-LOGIN-USER-INFO')){
     USER = JSON.parse(sessionStorage.getItem('HB-LOGIN-USER-INFO'));
}

export default () => {

     return USER ;
}

export function token(){
    
    if(USER){

        return USER.token ;
    }
}

export function scope(){

    if(USER){

        return USER.scope ;
    }
}

export function store_token(){

    if(USER){

        return USER.store_token ;
    }
}


export function client_id(){

    if(USER){

        return USER.client_id ;
    }
}

