<template>
  <Container class="hb-layout-vertical">
    <!--    左：顶部搜索-->
    <LeftSearch :loading="loading" @leftsearch="onleftSearch"></LeftSearch>

    <LeftScreenWeek
      v-if="calendarSetting == 2"
      @alldoctor="onAllDoctor"
      @changestatus="onChangeStatus"
      :allDoctors="allDoctors"
    ></LeftScreenWeek>
    <LeftScreen
      v-if="calendarSetting == 1"
      @alldoctor="onAllDoctor"
      @fiftyevent="onFiftyEvent"
      @sevenevent="onSevenEvent"
      @thirtyevent="onThirtyEvent"
      @calendarevent="onCalendarEvent"
      :screenTabs=screenTabs
    ></LeftScreen>

    <div
      class="hb-layout-flex-1"
      :style="{
        position: 'relative',
      }"
    >
      <div class="hb-layout-fit-sized">
        <List
          ref="list"
          class="hb-layout-fit-unsized"
          itemCls=".see-patient-list-item"
          @itemselect="onItemSelect"
          v-model="selected"
          :scroll="true"
          :is_test="true"
          :data="datas"
        >
          <template v-slot:item="{ record, selected }">
            <LeftItems
              :doctorId="record.doctorId"
              :name="record.name"
              :appointment_num="record.count_appointment"
              :register_num="record.count_register"
              :wait_num="record.count_wait"
              :end_num="record.count_end"
              :selected="selected"
            ></LeftItems>
          </template>
        </List>
      </div>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      ["Left.Items", "Left.DayItems", "Left.Search", "Left.Screen","Left.ScreenWeek"],
      "appointmentCalendar"
    ),
    ...components(["List", "Container"]),
  },

  props: {
    data: Array,
    dayList: {},
    calendarSetting: 0,
    allDoctors:0,
    screenTabs:0
  },

  watch: {

    datas(data){

      //console.log('datas' , data) ;

      if(data.length){

          let me = this ;

          me.$nextTick(() => me.$refs.list.select(data[0])) ;
      }

    },

    data(data) {
      let me = this;
      let top = [];

      me.$nextTick(() => {
        if (data.length) {
          me.$refs.list.select(data[0]);
        }
      });
      // data.forEach(function (arr) {
      //   // top["doctorId"] = arr.doctorId;
      //   if (arr.count_appointment == undefined) {
      //      top.count_appointment = 0;
      //   } else {
      //      top.count_appointment = arr.count_appointment;
      //   }
      //   top.doctorId =arr.doctorId
      //   arrList.push(top);
      // });
      data.map(function (arr, index) {
        let count_appointment,count_register,count_wait,count_end;
        if (arr.count_appointment == undefined) {
          count_appointment = 0;
        } else {
          count_appointment= arr.count_appointment;
        }
        if (arr.count_register == undefined) {
          count_register= 0;
        } else {
          count_register= arr.count_register;
        }
        if (arr.count_wait == undefined) {
          count_wait= 0;
        } else {
          count_wait= arr.count_wait;
        }
        if (arr.count_end == undefined) {
         count_end= 0;
        } else {
          count_end= arr.count_end;
        }
        top.push({
          name: arr.name,
          doctorId:arr.doctorId,
          count_appointment:count_appointment,
          count_register:count_register,
          count_wait:count_wait,
          count_end:count_end
         });
         // top.doctorId =arr[index].doctorId
      //   
    
      //   top["doctorId"] = arr.doctorId;
      //   top["name"] = arr.name;
      //     console.log("arr", index,arr);
      //    console.log("data", index,top);
      //   arrList.push(top);

       
      });
       //console.log("datatop",top);
       me.datas = top;
       //console.log("data", top);
    },
  },

  data() {
    return {
      loading: {},
      selected: null,
      options: [
        {
          value: undefined,
          label: "全部",
        },
        {
          value: 0,
          label: "在班",
        },
        {
          value: 1,
          label: "未在班",
        },
      ],
      status: undefined,
      datas: [],
    };
  },

  methods: {
    /**
     * 改变状态
     */
    onChangeStatus(command) {
      this.$emit("leftchangestatus", command);
    },
    onItemSelect(doctorId) {
      // onItemSelect绑定父页面的方法doctoritemselect

      this.$emit("doctoritemselect", doctorId);
    },

    select(doctorId) {
      this.$refs.list.select(doctorId);
    },
    onleftSearch(command) {
      this.$emit("leftsearch", command);
    },
    onAllDoctor() {
      this.selected=1;
  
      this.$emit("alldoctor");
    },
    onTodayEvent() {
      this.$emit("todayevent");
    },
    onSevenEvent() {
      this.$emit("sevenevent");
    },
    onFiftyEvent() {
   
      this.$emit("fiftyevent");
    },
    onThirtyEvent() {
      this.$emit("thirtyevent");
    },
    onCalendarEvent(e) {
      this.$emit("calendarevent", e);
    },
  },
};
</script>

<style lang="scss" scoped>
$see-patient-list-header-height: 50px;

$see-patient-list-padding: 0 10px 0 10px;

.see-patient-list {
  > .header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 16px 0 18px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;

    > .tody {
      padding-right: 15px;
    }
  }

  .el-dropdown-link {
    font-size: 12px;
    padding-left: 20px;
  }
}
</style>
