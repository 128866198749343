<template>
  <Container>
    <div class="screen">
      <div class="left-details">
        <div class="search">
          <el-input
            v-model="search"
            placeholder="搜索保险公司"
            class="input-with-select search">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </div>
      <el-button type="primary" @click="addInsurance = true">新增保险</el-button>
    </div>
    <div>
      <el-table
        ref="list"
        :data="hospitalList"
        highlight-current-row
        stripe
        height="calc(100vh - 220px)"
        style="width: 100%">

        <el-table-column fixed prop="company" label="保险公司" min-width="200" align="center"></el-table-column>

        <el-table-column prop="contacts" label="联系人" min-width="120" align="center"></el-table-column>
        
        <el-table-column prop="phone" label="联系电话" min-width="120" align="center"></el-table-column>

        <el-table-column prop="remarks" label="备注" min-width="350" align="center"></el-table-column>

        <!-- <el-table-column prop="customerVolume" label="客户量" min-width="150" align="center"></el-table-column>

        <el-table-column prop="reimbursementRatio" label="报销比例" min-width="150" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.reimbursementRatio}}%</div>
          </template>
        </el-table-column>

        <el-table-column prop="upperLimit" label="报销金额上限" min-width="170" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.upperLimit}}元</div>
          </template>
        </el-table-column>

        <el-table-column prop="reimbursementNumber" label="报销次数" min-width="130" align="center"></el-table-column>

        <el-table-column prop="amountReceivable" label="应收金额" min-width="150" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.amountReceivable}}元</div>
          </template>
        </el-table-column>

        <el-table-column prop="amountNetReceipts" label="实收金额" min-width="150" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.amountNetReceipts}}元</div>
          </template>
        </el-table-column>

        <el-table-column prop="amountClaimsRejected" label="拒赔金额" min-width="150" align="center">
          <template slot-scope="scope">
            <div>{{scope.row.amountClaimsRejected}}元</div>
          </template>
        </el-table-column>

        <el-table-column prop="numberClaimsRejected" label="拒赔次数" min-width="110" align="center"></el-table-column> -->

        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="editRow(scope.$index, hospitalList)"
              type="text"
              size="small">
              编辑
            </el-button>
            <el-button
              @click.native.prevent="deleteRow(scope.$index, hospitalList)"
              type="text"
              size="small">
              删除
            </el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <!-- 添加保险 -->
    <el-drawer
      title="新增保险"
      :visible.sync="addInsurance"
      style="fontSize:20px;">
      <AddInsurance></AddInsurance>
    </el-drawer>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["AddInsurance"], "configuration.Insurance"),
    ...components(["Container"]),
  },
  data() {
    return {
        hospitalList: [
            {
                company: "中国人寿保险",                // 保险公司
                contacts: '何九华',                    // 联系人
                phone: '17878928567',                 // 联系电话
                remarks: '长期合作关系',                // 备注

                customerVolume: "187",                // 客户量
                reimbursementRatio: '70',             // 报销比例
                upperLimit: "10000.00",               // 报销金额上限
                reimbursementNumber: "6",             // 报销次数
                amountReceivable: '7200.00',          // 应收金额
                amountNetReceipts: '6899.00',         // 实收金额
                amountClaimsRejected: '567.00',       // 拒赔金额
                numberClaimsRejected: '3',            // 拒赔次数
            }
        ],

        search: "",  // 搜索保险公司

        addInsurance: false,   // 新增保险弹窗
    };
  },
  methods:{
    // 添加一行
    add() {
      if (this.hospitalList == undefined) {
        this.hospitalList = new Array();
      }
      let obj = {};
      obj.name = "";
      obj.address = "";
      obj.contacts = "";
      obj.phone = "";
      obj.number = "";

      this.hospitalList.push(obj);
    },

    // 删除一行
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    // 编辑
    editRow(index, rows){

    }
  }
};
</script>

<style lang="scss" scoped>
  .screen {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-details{
      display: flex;
      align-items: center;
      margin-right: 10px;

      .select{
        margin: 0 10px;
      }

      .date{
        width: 240px;
      }
    }
  }

  .el-range-editor.el-input__inner{
    width: 100%;
  }

</style>
