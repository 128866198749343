<template>
  <Container class="statistics">
    <div class="text item">
      <div class="total">
        <div class="total-amount">
          <el-image class="image" src="../../image/recharge.png"></el-image>
          <div class="content">
	        <div class="total-number">{{statisticsData.total_price.toFixed(2)}}</div>
            <div class="total-name">充值总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image total-img" src="../../image/give.png"></el-image>
          <div class="content">
	          <div class="total-number">{{statisticsData.total_price_new.toFixed(2)}}</div>
            <div class="total-name">额外赠送总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image total-img" src="../../image/give.png"></el-image>
          <div class="content">
	          <div class="total-number">{{statisticsData.total_price_use.toFixed(2)}}</div>
            <div class="total-name">活动赠送总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image img" src="../../image/ywc.png"></el-image>
          <div class="content">
	          <div class="total-number">{{statisticsData.count_paid_0}}</div>
            <div class="total-name">已支付</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image img" src="../../image/wwc.png"></el-image>
          <div class="content">
	          <div class="total-number">{{statisticsData.count_paid_1}}</div>
            <div class="total-name">未支付</div>
          </div>
        </div>
      </div>
      <el-table
        style="width: 100%"
        height="calc(100vh - 385px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
         <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="recharge_record_no"
          label="充值流水号"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="get_user.nickname"
          label="用户"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column label="支付方式" min-width="150" align="left">
          <template slot-scope="scope">
            {{ stateMap[scope.row.to_payment] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="total_amount"
          label="充值金额"
          min-width="150"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.total_amount }}元</div>
          </template>
        </el-table-column>
         <el-table-column
          label="额外赠送金额"
          min-width="150"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.new_money }}元</div>
          </template>
        </el-table-column>
         <el-table-column
          label="活动赠送金额"
          min-width="150"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.use_money }}元</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="state"
          label="支付状态"
          min-width="130"
          align="left"
        >
        <template slot-scope="scope">
            {{ statusMap[scope.row.status] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="支付时间"
          width="160"
          align="left"
        ></el-table-column>

      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
    tableData: {
      default: () => [],
    },
	statisticsData: {

      default(){

        return {
          total_price:0,
          total_price_new:0,
          total_price_use:0
        } ;
      }

  },
  },

  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {

      typeMap: [
        {
          text: "收入",
          type: "success",
          symbol: "+",
        },
        {
          text: "支出",
          type: "danger",
          symbol: "-",
        },
      ],

      moduleMap: {
        points: "积分",
        money: "消费",
        card: "购物卡",
      },
      stateMap: [
        '',
        '现金',
        '支付宝',
        '微信',
        '',
        '',
        '',
        '',
        '江西银行',
        '工商银行',
      ],
      statusMap: ['未支付', '已支付'],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;

  .total{
    display: flex;
    align-items: center;

    .total-amount{
      height: 80px;
      margin: 20px 10px 20px 5px;
      padding: 0 40px 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 1px 4px 1px #d8d8e3;

      .image{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .img{
        width: 44px;
        height: 44px;
      }
      .total-img{
        width: 54px;
        height: 54px;
      }

      .content{
        font-family: PingFangSC-Medium, PingFang SC;

        .total-name{
          font-size: 12px;
          color: #999;
        }
        .total-number{
          font-size: 18px;
          color: #333;
        }
      }
    }
  }
}
</style>
