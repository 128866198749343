<template>
  <Container class="appoientment-main-operation hb-layout-horizontal">
    <div class="screen">
      <div class="left-details">
        <div class="search">
          <el-input
            v-model="search"
            placeholder="搜索患者"
            class="input-with-select search"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
      </div>
      <el-button type="primary" @click="addHospital = true">新增医院</el-button>
    </div>
    <div>
      <el-table
        ref="list"
        :data="hospitalList"
        highlight-current-row
        stripe
        @row-click="detailsDrawer = true"
        height="calc(100vh - 220px)"
        style="width: 100%"
      >
        <el-table-column
          fixed
          prop="name"
          label="医院名称"
          min-width="180"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="address"
          label="地址"
          min-width="180"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="contacts"
          label="联系人"
          min-width="150"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="phone"
          label="联系电话"
          min-width="150"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="number"
          label="转院人数"
          min-width="150"
          align="center"
        ></el-table-column>

        <el-table-column label="操作" min-width="150" align="center">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="editRow(scope.$index, hospitalList)"
              type="text"
              size="small"
            >
              编辑
            </el-button>
            <el-button
              @click.native.prevent="deleteRow(scope.$index, hospitalList)"
              type="text"
              size="small"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 
    <el-drawer
      title="转院详情"
      :visible.sync="detailsDrawer"
      size="55%"
      style="fontSize:20px;">
      <HospitalDetails></HospitalDetails>
    </el-drawer>
-->
    <!-- <el-drawer
      title="新增医院"
      :visible.sync="addHospital"
      style="fontSize:20px;">
      <AddHospital></AddHospital>
    </el-drawer> -->
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  data() {
    return {
      hospitalList: [
        {
          name: "苏二大医院", // 医院名称
          address: "江苏省苏州市吴中区", // 地址
          contacts: "米邵飞", // 联系人
          phone: "18907789673", // 联系电话
          number: "3", // 转院人数
        },
      ],

      search: "", // 搜索患者

      detailsDrawer: false,

      addHospital: false, // 添加医院弹窗
    };
  },
  methods: {
    // 添加一行
    add() {
      if (this.hospitalList == undefined) {
        this.hospitalList = new Array();
      }
      let obj = {};
      obj.name = "";
      obj.address = "";
      obj.contacts = "";
      obj.phone = "";
      obj.number = "";

      this.hospitalList.push(obj);
    },

    // 删除一行
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    // 编辑
    editRow(index, rows) {
      this.addHospital = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 98%;
  padding: 19px;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px auto 10px;

  .list {
    width: 100%;
    height: 80px;
    line-height: 80px;
    padding: 0 19px;
    align-items: center;
    border-bottom: 1px solid #e1e1e1;
    font-size: 12px;
    text-align: center;

    &.selected {
      box-shadow: 0 2px 7px 0 #d8d8d8;
      margin: 2px 0;
      background: #92c2f957;
    }
    &:hover {
      background: #92c2f921;
      color: #2063b695;
    }
  }
}
.custom-tree-node {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
.node-title {
  width: 30px;
}
.node-item {
  width: 50px !important;
}
.screen {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-details {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .select {
      margin: 0 10px;
    }

    .date {
      width: 240px;
    }
  }
}

.el-range-editor.el-input__inner {
  width: 100%;
}
</style>
