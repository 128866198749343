<template>
  <Container class="statistics">
    <div class="box-card">
        <div class="card-list-item">
					<div class="item-number">{{statics_data.count_appointment}} 人</div>
					<div class="item-name">今日预约</div>
				</div>
				<div class="card-list-item">
					<div class="item-number number2">{{statics_data.count_diagnosis}} 人</div>
					<div class="item-name">今日看诊</div>
				</div>
				<div class="card-list-item">
					<div class="item-number number3">{{statics_data.count_visit_2}} 人</div>
					<div class="item-name">今日回访</div>
				</div>
				<div class="card-list-item">
					<div class="item-number  number4">{{statics_data.count_new_patient}} 人</div>
					<div class="item-name">今日新增</div>
				</div>
    </div>
  </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props:{
   statics_data:{}
  },
  data(){
    return {
      statistics_data:{},
    }
  },
  methods:{
	  
  },
}
</script>

<style  lang="scss" scoped>
.statistics{
  .box-card{
    padding: 45px 0;
    margin: 4px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    box-shadow: 0px 0px 10px 0px #C5C5C5;

    .card-list-item{
      margin: 0 90px;
      text-align: center;

      .item-number{
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #2DBA5C;
        line-height: 25px;
      }
      .number2{
        color: #6EAAA2;
      }
      .number3{
        color: #FFC546;
      }
      .number4{
        color: #FF9206;
      }

      .item-name{
        font-size: 18px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #000000;
        line-height: 25px;
        padding-top: 14px;
      }
    }
  }
}
</style>
