<template>
  <Container class="see-main-operation hb-layout-horizontal">
    <template>
      <div class="display">
        <div class="user-info" v-if="medical_basic">
          <!-- <div class="follow-up-visit" v-if="type !== ''">
            <el-tag :type="typeMap[visitStatus].type">{{
              typeMap[visitStatus].text
            }}</el-tag>
          </div>
          <div class="card-number">
            病例号：{{ medical_basic.medical_record_no }}
          </div>
          <div class="name">姓名：{{ medical_basic.real_name }}</div>
          <div class="info">性别：{{ sexMap[medical_basic.sex] }}</div>
          <div class="info">年龄：{{ medical_basic.age }}岁</div> -->
          <div class="btn-between">
            <el-button
              type="success"
              @click="onStartTreatmentButtonClick"
              v-if="start_reception === 1"
              icon="el-icon-check"
              >开始接诊</el-button
            >
            <el-button
              type="success"
              @click="onStartTreatmentButtonClick"
              v-if="start_reception === 0"
              >开始接诊</el-button
            >
            <el-button
              type="danger"
              @click="onEndTreatmentButtonClick"
              v-if="end_reception === 1"
              icon="el-icon-check"
              >结束诊断
            </el-button>
            <el-button
              type="danger"
              @click="onEndTreatmentButtonClick"
              v-if="end_reception === 0"
              >结束诊断
            </el-button>
          </div>
        </div>

        <div class="btn">
          <div class="btn-between">
            <!-- <el-button type="success" @click="medicalReportPreview">病历报告</el-button> -->
            <el-dropdown split-button type="primary">
              打印
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item @click.native="medicalReportPreview"
                  >病历报告</el-dropdown-item
                >
                <el-dropdown-item @click.native="RehabilitationReportPreview"
                  >康复病例</el-dropdown-item
                >
                <el-dropdown-item @click.native="medicalCertificatePreview"
                  >请假单</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>

            <el-dropdown
              split-button
              type="primary"
              class="select-but"
            >
              转诊
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item>转医生</el-dropdown-item> -->
                <el-dropdown-item @click.native="referralPreview(true)"
                  >转院</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
            <el-button type="success" @click="onSaveButtonClick"
              >保存</el-button
            >
            <!-- <el-button type="success" @click="referralPreview">打印</el-button> -->
          </div>
        </div>
      </div>

      <!-- 转诊信打印 -->
      <ReferralPreview
        ref="referralPreview"
        :referral_title="referral_preview_title"
        :size="size"
        :direction="direction"
        :vital="item.vital_signs"
        :impressions="item.impressions"
        :medical_record="medical_basic"
        :before-close="handleClose"
        @turnhospital="onTurnHospital"
      >
      </ReferralPreview>

      <!-- 病历打印 -->
      <MedicalReportPreview
        ref="medicalReportPreview"
        :medical_report_title="medical_report_preview_title"
        :size="size"
        :direction="direction"
        :medical_record="medical_basic"
        :before-close="handleClose"
      >
      </MedicalReportPreview>

      <!-- 请假单打印 -->
      <MedicalCertificatePreview
        ref="medicalCertificatePreview"
        :medical_certificate_title="medical_certificate_preview_title"
        :size="size"
        :direction="direction"
        :medical_record="medical_basic"
        :before-close="handleClose"
      >
      </MedicalCertificatePreview>
      <!-- 康复病例 -->
      <RehabilitationReportPreview
        ref="RehabilitationReportPreview"
        :rehabilitation_report_title="rehabilitation_report_preview_title"
        :size="size"
        :direction="direction"
        :medical_record="medical_basic"
        :before-close="handleClose"
      >
      </RehabilitationReportPreview>
    </template>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  props: {
    type: {
      type: Number | String,
      default: "",
    },
    item: {
      type: Object,
      require: true,
      default: {
        // allergies: '无',         // 过敏史
        // past_history: '无',      // 既往史
        // family_history: '无',    // 家族史
      },
    },
    visitStatus: 0,
    medical_basic: {
      type: Object,
      default: () => {},
    },
    start_reception: 0,
    end_reception: 0,
 
  },

  components: {
    // Container,
    ...components(
      ["ReferralPreview", "MedicalReportPreview", "MedicalCertificatePreview", "RehabilitationReportPreview"],
      "see.Center"
    ),
    ...components(["Container"]),
  },
  watch:{
    start_reception:function(val){
     // console.log("start_reception2",val);
    }
  },
  data() {
    return {
      sexMap: ['保密', '男', '女'],
      typeMap: [
        {},
        {
          type: "success",
          text: "首诊",
        },
        {
          type: "primary",
          text: "初诊",
        },
        {
          type: "danger",
          text: "复诊",
        }
      ],
      direction: "rtl",
      size: "60%",

      // 打印预览标题
      referral_preview_title: "转诊信",
      medical_report_preview_title: "病历报告",
      medical_certificate_preview_title: "请假单",
      rehabilitation_report_preview_title: "康复病例"
    };
  },

  methods: {
    onSaveButtonClick() {
      this.$emit("save");
    },

    onReferralButtonClick() {
      this.$emit("referral");
    },

    onStartTreatmentButtonClick() {
      this.$emit("starttreatment");
    },

    onEndTreatmentButtonClick() {
      this.$emit("endtreatment");
    },

    /**
     * 弹窗：转诊信
     */
    referralPreview(val) {
      this.$refs.referralPreview.changeDrawer(val);
    },
    /**
     * 弹窗：病历报告
     */
    medicalReportPreview() {
      this.$refs.medicalReportPreview.changeDrawer(true);
    },
    /**
     * 弹窗：康复病例
     */
    RehabilitationReportPreview() {
      this.$refs.RehabilitationReportPreview.changeDrawer(true);
    },
    /**
     * 弹窗：请假单
     */
    medicalCertificatePreview() {
      this.$refs.medicalCertificatePreview.changeDrawer(true);
    },

    /**
     * 关闭前的回调，会暂停 Drawer 的关闭
     */
    handleClose() {
      //
    },


    onTurnHospital(val) {
      this.$emit("turnhospital", val);
      console.log("topHeader", val)
    },

  },
};
</script>

<style lang="scss" scoped>
.see-main-operation {
  width: 99%;
  align-items: center;
  padding: 0 18px;
  height: 60px;
  // line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 5px auto 10px;
  display: flex;
  justify-content: space-between;

  .see-main-operation:active {
    background-color: #ff0000;
  }
}

.display {
  width: 100%;
  overflow-x: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .user-info {
    display: flex;
    align-items: center;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;
    // line-height: 25px;
    white-space: nowrap;

    > .card-number {
      padding-left: 15px;
    }

    > .name {
      padding-left: 15px;
    }

    > .info {
      padding-left: 15px;
    }

    > .btn-between {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }
  .btn {
    margin-left: 25px;
    white-space: nowrap;

    > .btn-between {
      margin-left: 10px;

      > .select-but {
        margin: 0 10px;
      }
    }
  }
}
</style>
