<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header></Header>
    <Content
    ></Content>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      [
        "Header",
        "Content",
      ],
      "configuration.Diagnosis.Right"
    ),

    ...components(["Container"]),
  },

  props: {

  },
   watch: {

  },

  data() {
    return {
      
    };
  },

  methods: {
  },
};
</script>

<style lang="scss" scoped>
// .see-patient-history-list-item {
//   width: 99%;
//   padding: 0 19px;
//   height: calc(100vh - 140px);
//   box-shadow: 0 2px 7px 0 #d8d8d8;
//   margin: 2px 0px 10px 2px;
// }
</style>
