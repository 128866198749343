<template>
  <Container class="details hb-layout-horizontal">
    <Container class="centent hb-layout-flex-1 hb-layout-vertical">
      <LeftMain
        @submitadddrugs="onSubmitAddDrugs"
        :Data="Data"
        :pharmacyItem="pharmacyItem"
        @changeeditdrugs="onChangeEditDrugs"
        :brandDatas="brandDatas"
        :supplierDatas="supplierDatas"
        :factoryDatas="factoryDatas"
        :pharmacyCateDatas="pharmacyCateDatas"
        :Param="Param"
        @changesubmit="onChangeSubmit"
        @submitreset="onSubmitReset"
        @changepagination="onChangePagination"
        @delselectitem="onDelSelectItem"
        :smallunitDatas="smallunitDatas"
        ref="left_main"
      ></LeftMain>
    </Container>
    <!-- <Container class="right hb-layout-vertical">
      <RightMain></RightMain>
    </Container> -->
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Left.Main", "Right.Main"], "pharmacy.Drugs"),
    ...components(["Container"]),
  },

  props: {
    pharmacyCateDatas: {},
    brandDatas: {},
    supplierDatas: {},
    factoryDatas: {},
    pharmacyDatas: {},
    pharmacyItem: {},
    Data: {},
    loading: {
      default: false,
    },
    Param: {},
    smallunitDatas:{}
  },

  data() {
    return {};
  },

  methods: {
    onSubmitAddDrugs(data) {
      let me = this;
      me.$emit("submitadddrugs", data);
    },
    onSubmitEditDrugs(data) {
      let me = this;
      me.$emit("submiteditdrugs", data);
    },
    onChangeEditDrugs(val) {
      let me = this;
      me.$emit("changeeditdrugs", val);
    },
    onDelSelectItem(data){
      this.$emit("delselectitem", data);
    },
      onChangeSubmit(form, isRestore = false) {
      this.$emit("changesubmit", form, isRestore);
    },
    onSubmitReset(data) {
      let me = this;
      me.$emit("submitreset",data);
    },
     pageChangeSubmit(){
      this.$nextTick(() => {
        this.$refs.left_main.pageChangeSubmit();
      });
    }, // 分页变化改动
    onChangePagination(val) {
      //this.page = val;
      this.$emit("changepagination", val);
      //   this.$nextTick(() => {
      //     this.$refs.header.handleFormSubmit();
      //   });
    },
    changeDrawer(v) {
      let me = this;
      //console.log("112");
      me.$refs.left_main.changeDrawer(v);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .right {
    width: 300px;
  }
}
</style>
