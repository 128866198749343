<template>
  <el-card shadow="always" class="card">
    <el-row type="flex">
      <el-col :span="24">
        <el-col :span="5">
          <el-button type="text" class="name" @click="todayEvent">今日</el-button>
        </el-col>
        <el-col :span="5">
          <el-button type="text" class="name" @click="sevenEvent">7日</el-button>
        </el-col>
        <el-col :span="5">
          <el-button type="text" class="name" @click="thirtyEvent">30日</el-button>
        </el-col>
      </el-col>
      <el-col :span="9">
         <el-dropdown @command="changeStatusFee">
          <span class="el-dropdown-link">
            处理状态<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in options"
              :command="item.value"
              :key="index"
              >{{ item.label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    loading: {},
  },
  data() {
    return {
      data: {
        day: "yyyy-MM-dd",
      },
      real_name: "",
      options: [
        {
          value: "all",
          label: "全部",
        },
        {
          value: 1,
          label: "待审核",
        },
        {
          value: 2,
          label: "通过",
        },
        {
          value: 3,
          label: "未通过",
        },
        {
          value: 4,
          label: "已处理",
        },
        {
          value: 5,
          label: "未处理",
        },
        {
          value: 6,
          label: "返回",
        }
      ],
      dataValue: "",
    };
  },
  methods: {
    /**
     * 患者列表 筛选状态
     */
    changeStatusFee(e) {
      this.$emit("changestatusfee", e);
    },
    allDoctor() {
      this.$emit("alldoctor");
    },
    todayEvent() {
      this.$emit("todayevent");
    },
    sevenEvent() {
      this.$emit("sevenevent");
    },
    thirtyEvent() {
      this.$emit("thirtyevent");
    },
    handleEventClick(e) {
       this.$emit("calendarevent",e);
    },
  },
};
</script>

<style lang="scss" scoped>
.name{
  font-size: 13px;
  color: #606266;
  padding: 3px 5px;
}

.name:focus{
  border: 1px solid #84c4bc;
  background: #e6fdfbb6;
  color: #5ba097;
}
/deep/.el-card__body{
  padding: 20px 10px;
}
.el-calendar{
  width: 500px;
}
.el-calendar /deep/.el-calendar-day {
  height: 30px!important;
}
</style>
