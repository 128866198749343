<template>
	<Container class="see-main-operation hb-layout-horizontal">
		<template>
			<div class="user-info">
				<div class="name">李生</div>
				<div class="info">
					<span class="info-sex">男</span>
					<span>/</span>
					<span>22岁</span>
					<span>/</span>
					<span>15193787731</span>
				</div>
				<div class="btn-between">
					<div class="follow-up-visit"><el-tag type="success">复诊</el-tag></div>
				</div>
			</div>

			<div class="btn">
				<div class="btn-between">
					<el-button type="success">保存</el-button>
					<el-button type="info">打印</el-button>
				</div>
			</div>
		</template>
	</Container>
</template>

<script>
import {
  components
} from '@/helper' ;

export default {
	components: components([
		'Container'
	]),
		
	data(){
		return {}
	},

	methods: {

	}
};
</script>

<style lang="scss" scoped>
	.see-main-operation {
		align-items: center;
		padding: 0 19px;
		height: 60px;
		line-height: 60px;
		box-shadow: 0 2px 7px 0 #d8d8d8;
		margin: 5px 5px 10px;
		width: 99%;
        display: flex;
        justify-content: space-between;

		.see-main-operation:active{
			background-color: #ff0000;
		}

		>.user-info {
			display: flex;
			align-items: center;
            font-size: 16px;

			>.name {
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #5B5B5B;
				line-height: 25px;
				padding: 0 20px 0 0;
				white-space: nowrap;
			}

			>.info {
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #5B5B5B;
				line-height: 25px;
				white-space: nowrap;

				>.info-sex {
					padding-right: 5px;
				}
			}

			>.btn-between{
				margin-left: 10px;
				display: flex;
			    align-items: center;
				white-space: nowrap;
			}
		}


		>.btn {
			margin-left: 25px;
			// width: 72%;
			white-space: nowrap;
			float: right;

			>.btn-between{
                margin-left: 10px;

				> .select-but{
					margin-right: 10px;
				}
			}


		}
	}
</style>
