<template>
    <Container class="patient-detail-doctor">
        <div class="details">
            <el-card style="height:calc(100vh - 90px)">
                <Container class="see-patient-history-list-item hb-layout-vertical">
                    <Container class="body">
                         <Container class="inner hb-layout-horizontal">
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">姓名</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
                                        <el-input v-model="name" placeholder="请输入姓名"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">用户名</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
                                        <el-input v-model="userName" placeholder="请输入用户名"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">部门</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
                                        <el-select v-model="departmentValue" placeholder="请选择部门" style="width:100%">
                                            <el-option
                                            v-for="item in department"
                                            :key="item.value"
                                            :label="item.label"
                                            :value="item.value">
                                            </el-option>
                                        </el-select>
                                    </div>
                                </el-col>
                            </el-row>
                        </Container>
                        <div class="btn">
                            <el-button type="info" class="edit-buttom">取消</el-button>
                            <el-button type="success" class="edit-buttom">添加</el-button>
                        </div>
                        
                    </Container>
                </Container>
            </el-card>
        </div>
    </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
 
  components: components([
    'Container'
  ]),

    data(){
        return{
            name: '',            // 姓名
            userName: '',       // 用户名
            department: [
                {
                    value: '1',
                    label: '门诊部',
                },{
                    value: '2',
                    label: '住院部',
                }
            ],
            departmentValue: '',   // 选择部门
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
.see-patient-history-list-item {
    width: 100%;
    border: 1px solid #e1e1e1;

    >.header {
        padding: 0 10px;
        height: 50px;
        line-height: 50px;
        background-color: #ededed;
        border-bottom: 1px solid #e1e1e1;

        >.time {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5b5b5b;
        }

        >.button {
            height: 30px;
            margin-top: 12px;
        }
    }

    >.body {
        padding: 10px;

        .inners {
            display: flex;
            flex-direction: column;

            .row-select {
                margin-bottom: 7px;
            }
        }

        >.inner {
            display: flex;
            flex-direction: column;
            border: 1px solid #979797;

            .el-row {
                width: 100%;
                height: auto;
                line-height: 38px;
                border-bottom: 1px solid #979797;
                font-size: 12px;

                &:last-child {
                    border-bottom: none;
                }
            }

            .bg-purple {
                text-align: center;
                background: #EBEBEB;
                border-right: 1px solid #979797;
            }


            .bg-purple-light-select {
                padding: 0;
                font-size: 12px;
            }
        }

        .title {
            line-height: 34px;
            margin-top: 10px;
            font-size: 16px;
        }

    }

    .btn{
        display: flex;
        justify-content: flex-end;
        // position: fixed;
        // bottom: 30px;
        // right: 20px;

        .edit-buttom {
           margin-top: 30px;
        }
    }
    
}
</style>
