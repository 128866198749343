<template>
  <el-card
    class="box-card"
    shadow="hover"
    :class="{
      'see-patient-list-item': true,
      selected,
    }"
    :body-style="{ padding: '0 10px' }"
  >
    <el-row style="width: 100%" type="flex" justify="center" align="middle">
      <el-col :span="6">
        <div class="name">{{ name }}</div>
      </el-col>
      <el-col :span="5">
        <div class="name">预约({{ appointment_num }})</div>
      </el-col>
      <el-col :span="5">
        <div class="name">到店({{ register_num }})</div>
      </el-col>
      <el-col :span="4">
        <div class="name">待诊({{ wait_num }})</div>
      </el-col>
      <el-col :span="4">
        <div class="name">已诊({{ end_num }})</div>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    name: {
      required: true,
      type: String,
    },
    doctorId: {
      required: true,
      type: [String, Number],
    },
    appointment_num: {
      required: true,
      type: [Number],
    },
    register_num: {
      required: true,
      type: [Number],
    },
    wait_num: {
      required: true,
      type: [Number],
    },
    end_num: {
      required: true,
      type: [Number],
    },
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      lvlMap: {},
      statusMap: [
        {
          text: "全部",
          type: "",
        },
        {
          text: "在班",
          type: "warning",
        },
        {
          text: "未在班",
          type: "success",
        },
      ],

      order: 13,
      treated: 3,
    };
  },
  computed: {
    add: function () {
      this.order = this.order + Math.floor(Math.random() * 10);
      return this.order;
    },
    adds: function () {
      this.treated = this.treated + Math.floor(Math.random() * 10);
      return this.treated;
    },
  },
};
</script>

<style lang="scss" scoped>
$see-patient-list-item-height: 80px;

.see-patient-list-item {
  height: $see-patient-list-item-height;
  line-height: $see-patient-list-item-height;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  font-size: 12px;

  &.selected {
    box-shadow: 0 2px 7px 0 #d8d8d8;
    margin: 2px 0;
    background: #92c2f957;
  }
  &:hover {
    background: #92c2f921;
    color: #2063b695;
  }
  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }
}
</style>
