<template>
  <Container class="statistics">
    <div class="text item">
      <div class="total">
        <div class="total-amount">
          <el-image class="image" src="../../image/pay.png"></el-image>
          <div class="content">
	             <div class="total-number" v-if="statisticsData && statisticsData.total_price >= 0">{{statisticsData.total_price.toFixed(2)}}</div>
            <div class="total-name">总价</div>
          </div>
        </div>
        <!-- <div class="total-amount">
          <el-image class="image img" src="../../image/zsl.png"></el-image>
          <div class="content">
             <div class="total-number" v-if="statisticsData.count_all >= 0">{{statisticsData.count_all}}</div>
            <div class="total-name">总数量</div>
          </div>
        </div> -->
        <div class="total-amount">
          <el-image class="image img" src="../../image/wwc.png"></el-image>
          <div class="content">
             <div class="total-number" v-if="statisticsData.count_execute_0 >= 0">{{statisticsData.count_execute_0}}</div>
            <div class="total-name">待执行</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image img" src="../../image/ywc.png"></el-image>
          <div class="content">

             <div class="total-number" v-if="statisticsData.count_execute_1 >= 0">{{statisticsData.count_execute_1}}</div>
            <div class="total-name">已执行</div>
          </div>
        </div>
      </div>
      <el-table
        style="width: 100%"
        height="calc(100vh - 385px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
       <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="get_user.nickname"
          label="用户"
          min-width="220"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="medical_record_no"
          label="病历号"
          min-width="180"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="diagnosis_record_no"
          label="看诊号"
          min-width="240"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="appointment_record_no"
          label="预约号"
          min-width="240"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="getExaminationTemplate.name"
          label="项目名称"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="unit"
          label="单位"
          width="140"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="count"
          label="项目数量"
          width="100"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="price"
          label="项目价格"
          width="110"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="total_price"
          label="总价"
          width="110"
          align="left"
        ></el-table-column>

        <el-table-column label="执行状态" width="100" align="left">
          <template slot-scope="scope">
            {{ statusMap[scope.row.status] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="160"
          align="left"
        ></el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
	  tableData: {
		  default: () => [],
	  },
	  statisticsData: {
      default(){
        return {
          total_price: 0,
          count_execute_0: 0,
          count_execute_1: 0,
        } ;
      }
    },
  },
  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {
      statusMap: ['待执行', '待执行', '待执行', '已执行'],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;

  .total{
    display: flex;
    align-items: center;

    .total-amount{
      height: 80px;
      margin: 20px 10px 20px 5px;
      padding: 0 40px 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 1px 4px 1px #d8d8e3;

      .image{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .img{
        width: 44px;
        height: 44px;
      }

      .content{
        font-family: PingFangSC-Medium, PingFang SC;

        .total-name{
          font-size: 12px;
          color: #999;
        }
        .total-number{
          font-size: 18px;
          color: #333;
        }
      }
    }
  }
}
</style>
