<template>
  <div>
    <Container class="charge-detail hb-layout-vertical">
      <Container class="vital-signs">
        <el-card class="box-card"  v-if="diagnosisData.length > 0">
          <el-row style="background: #fff">
            <el-col :span="7">
              <div class="grid-content bg-purple">诊断 Diagnosis</div>
            </el-col>
            <el-col :span="15">
              <div class="grid-content bg-purple-light" >
                {{ diagnosisData.item.impressions.join(",") }}
              </div>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="box-card" >
          <div slot="header" class="clearfix">
            <div class="title">诊疗 Consultation</div>
          </div>
          <Column :Data="diagnosisData.consulation"></Column>
        </el-card>

        <el-card class="box-card" v-if="inspectionItems.length > 0">
          <div slot="header" class="clearfix">
            <div class="title">检查项目 Examination</div>
          </div>
          <Column :Data="inspectionItems"></Column>
        </el-card>

        <el-card class="box-card" v-if="treatmentItems.length > 0">
          <div slot="header" class="clearfix">
            <div class="title">治疗项目 Treatment</div>
          </div>
          <Column :Data="treatmentItems"></Column>
        </el-card>

        <el-card class="box-card" v-if="westernmedicineItems.length > 0">
          <div slot="header" class="clearfix">
            <div class="title">西药处方 Pharmacy Prescription(Western Medicine)</div>
          </div>
          <Column :Data="westernmedicineItems"></Column>
        </el-card>

        <el-card class="box-card" v-if="pharmacyItems.length > 0">
          <div slot="header" class="clearfix">
            <div class="title">中药处方 TCM Prescription</div>
          </div>
          <Column :Data="pharmacyItems"></Column>
        </el-card>

        <el-card class="box-card" v-if="paymentRecordData !== null" style="margin-bottom: 60px">
          <div slot="header" class="clearfix">
            <div class="title">费用结算 Bill Summary</div>
          </div>
          <el-row>
            <el-col :span="7">
              <div class="grid-content bg-purple-light purple height">项目 Item</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light display">
                <div></div>
                <div>费用 Amount(RMB)</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <div class="grid-content bg-purple height">总计金额 Total Price</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <!-- <div>呼吸科</div> -->
                <div>{{ parseFloat(paymentItems.pay_amount).toFixed(2) }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="paymentItems.use_level != 0">
            <el-col :span="7">
              <div class="grid-content bg-purple height">会员折扣 Member Discount</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <!-- <div>验血单</div> -->
                <div>-{{ parseFloat(paymentItems.use_level).toFixed(2) }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="paymentItems.use_promotion != 0">
            <el-col :span="7">
              <div class="grid-content bg-purple height">优惠活动抵扣 Promotion Discount</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <div>
                  -{{ parseFloat(paymentItems.use_promotion).toFixed(2) }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="paymentRecordData.use_insurance != 0">
            <el-col :span="7">
              <div class="grid-content bg-purple height">保险抵扣 Insurance Discount</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <div>
                  -{{ parseFloat(paymentRecordData.use_insurance).toFixed(2) }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="paymentItems.use_coupon !== 0">
            <el-col :span="7">
              <div class="grid-content bg-purple height">优惠券抵扣 Coupon Discount</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <div>
                  -{{ parseFloat(paymentItems.use_coupon).toFixed(2) }}
                </div>
              </div>
            </el-col>
          </el-row>
           <el-row v-if="paymentItems.use_integral != 0">
            <el-col :span="7">
              <div class="grid-content bg-purple height">积分抵扣 Integral Discount</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <div>
                  -{{ parseFloat(paymentItems.use_integral).toFixed(2) }}
                </div>
              </div>
            </el-col>
          </el-row>
           <el-row v-if="paymentItems.use_justment != 0">
            <el-col :span="7">
              <div class="grid-content bg-purple height">额外抵扣 Additional Discount</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <div>
                  -{{ parseFloat(paymentItem.use_justment).toFixed(2) }}
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <div class="grid-content bg-purple height">实付金额 Patient Amount Due</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <!-- <div>验血单</div> -->
                <div>{{ parseFloat(paymentRecordData.total_amount).toFixed(2) }}</div>
              </div>
            </el-col>
          </el-row>
        </el-card>


        <el-card class="box-card" v-if="paymentRecordData == null" style="margin-bottom: 60px" >
          <div slot="header" class="clearfix">
            <div class="title">费用结算 Bill Summary</div>
          </div>
          <el-row>
            <el-col :span="7">
              <div class="grid-content bg-purple-light purple height">项目 Item</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light display">
                <div></div>
                <div>费用 Amount(RMB)</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <div class="grid-content bg-purple height">总计金额 Total Price</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <!-- <div>呼吸科</div> -->
                <div>¥{{ parseFloat(paymentItems.pay_amount).toFixed(2) }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <div class="grid-content bg-purple height">会员折扣 Member Discount</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <!-- <div>验血单</div> -->
                <div>-¥{{ parseFloat(paymentItems.use_level).toFixed(2) }}</div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="7">
              <div class="grid-content bg-purple height">实付金额 Patient Amount Due</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content purple-light display">
                <!-- <div>验血单</div> -->
                <!-- <div>¥{{ parseFloat(paymentItems.total_money).toFixed(2) }}/{{paymentItems.payment_status }}</div> -->
                <div>¥{{ parseFloat(paymentItems.total_money).toFixed(2) }}</div>
              </div>
            </el-col>
          </el-row>
        </el-card>

        <div class="pay" v-if="paymentRecordData == null">
          <div class="pay-btn">
            <!-- @click="paymentSubmit" -->
            <el-button style="width: 150px" type="info" @click="hangvingOrderDialog(true)"
              >挂单</el-button
            >
            <el-button style="width: 150px" type="success" @click="settlementDialog()"
              >结算</el-button
            >
          </div>
        </div>
        <div class="pay" v-if="paymentItems.paymentStatus == 3">
          <div class="pay-btn">

            <el-button style="width: 150px" type="success" @click="settlementDialog()"
              >挂单结算</el-button
            >
          </div>
        </div>
      </Container>
    </Container>
    
    <PaymentMain
      ref="add_payment"
      :direction="direction"
      :size="size"
      :drawer_title="drawer_add_title"
      :paymentDatas="paymentDatas"
      :usermoneyData="usermoneyData"
      :paymentAmount="parseFloat(paymentItems.total_money).toFixed(2)"
      @savepaymentitem="onSavePaymentItem"
      @insuranceaccount="onInsuranceAccount"
      :insuranceAccountDatas="insuranceAccountDatas"
      :paymentItems="paymentItems"
    ></PaymentMain>

    <HangingOrder
      ref="hangving_order"
      :drawer_title="hanging_order_title"
      :hanging_size="size"
      :direction="direction"
      @submithangingorder="onSubmitHangingOrder"
    ></HangingOrder>

  </div>
</template>

<script>
import { components, blocks } from "@/helper";

export default {
  components: {
    // ...components([
    //     "Single"
    //    ], "charge.list"),
    ...components(["List", "Container"]),
     ...components(["HangingOrder"],"charge.Center"),
    ...components(["payment.Main"]),
    ...blocks(["Column"], "Templates"),
  },
  props: {
    diagnosisData: {},
    usermoneyData: {},
    paymentDatas: {},
    paymentItems: {},
    inspectionItems: {},
    treatmentItems: {},
    pharmacyItems: {},
    westernmedicineItems: {},
    diagnosisRecordList: {
      //看诊列表
      type: Object,
      default: () => {},
    },
    insuranceAccountDatas: {},
    paymentRecordData: {},
    paymentStatus:0
  },

  data() {
    return {
      direction: "rtl",
      size: "1000px",
      drawer_add_title: "消费结算支付",
      hanging_size: "30%",
      hanging_order_title:"结算挂单",
      pricePayable: 0,
      paymentMethod: [
        {
          value: "1",
          label: "现金",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "3",
          label: "保险",
        },
        {
          value: "4",
          label: "余额",
        },
        {
          value: "5",
          label: "支付宝",
        },
        {
          value: "6",
          label: "银行卡",
        },
      ],
      paymentMethodValue: "", // 支付方式
      settlementAmount: "", // 充值金额
      settlementRemarks: "", // 余额充值的备注信息
    };
  },
   watch: {
    paymentRecordData:function (val) {
      //console.log("logva222222",val);
    }
  },
  methods: {
    paymentSubmit() {
      this.$emit("paymentsubmit");
    },
    settlementDialog() {
      let me = this;
      console.log(me.paymentItems.paymentStatus);
      if (me.paymentItems.total_money > 0) {
        me.$emit("getpaymentrecrods");
        me.$emit("getpaymentaccount");
        me.$refs.add_payment.changeDrawer(true);
      } else {
        me.$message({
          type: "warning",
          message: "请选择患者！",
        });
      }
    },
    onSavePaymentItem(data) {
      this.$emit("savepaymentitem", data);
    },
    onInsuranceAccount() {
      this.$emit("insuranceaccount");
    },
    changeInfoDrawer(val) {
      this.$refs.add_payment.changeDrawer(val);
      this.$refs.hangving_order.changeDrawer(val);
    },
    hangvingOrderDialog(val){
      this.$refs.hangving_order.changeDrawer(val);
    },
    onSubmitHangingOrder(){
      //console.log("1212", this.paymentItems);
      this.$emit("submithangingorder");
    }
  },
};
</script>

<style lang="scss" scoped>
.charge-detail {
  width: 100%;

  .vital-signs {
    margin: 2px 5px 15px;
    overflow: auto;
    height: calc(100vh - 170px);

    > .box-card {
      margin-bottom: 11px;
      box-shadow: 0 2px 7px 0 #d8d8d8;

      .title {
        font-size: 16px;
      }

      .el-row {
        // margin-bottom: 20px;
        height: 40px;
        line-height: 40px;
        border-top: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;

        &:last-child {
          margin-bottom: 0;
          border-bottom: 1px solid #e1e1e1;
        }

        &:first-child {
          background: #ebebeb;
        }
      }

      .bg-purple {
        text-align: center;
        border-right: 1px solid #e1e1e1;
        font-size: 14px;
        color: #5b5b5b;
        font-family: PingFangSC-Medium, PingFang SC;
      }

      .height{
        padding: 0 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      .purple {
        text-align: center;
      }

      .bg-purple-light {
        padding: 0 15px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
      }

      .purple-light {
        padding: 0 15px;
        float: right;
        font-size: 14px;
        color: #5b5b5b;
        font-family: PingFangSC-Medium, PingFang SC;
      }

      .display {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;

        .display-item {
          display: flex;
          // align-items: center;

          .display-item-name {
            padding-right: 100px;
          }
        }
      }
    }

    .pay {
      width: calc(100% - 615px);
      position: fixed;
      bottom: 10px;
      background: #fff;
      // right: 0;

      .method {
        display: flex;
        justify-content: flex-end;
      }

      .pay-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 10px;
      }
    }
  }
}

.grade {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;

  .name {
    white-space: nowrap;
    margin-right: 5px;
    font-size: 14px;
  }

  .content {
    width: 70%;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
</style>
