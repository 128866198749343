import { render, staticRenderFns } from "./VisitRecords.vue?vue&type=template&id=582cb314&scoped=true&"
import script from "./VisitRecords.vue?vue&type=script&lang=js&"
export * from "./VisitRecords.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "582cb314",
  null
  
)

export default component.exports