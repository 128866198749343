<template>
<Container class="see-patient-history-list hb-layout-vertical">
    <el-tabs>
            <Container class="see-patient-history-list-item hb-layout-vertical">
                <Container class="header hb-layout-horizontal">
                    <div class="time hb-layout-flex-1">时间:2020-10-23  14:15</div>
                    <el-button type="success" size="mini" class="button">初诊</el-button>
                </Container>
                <Container class="body">
                    <Container class="inner hb-layout-horizontal">
                        <el-row type="flex" align="middle">
                            <el-col :span="7">
                                <div class="grid-content bg-purple">看诊</div>
                            </el-col>
                            <el-col :span="17">
                                <div class="grid-content bg-purple-light">呼吸科</div>
                            </el-col>
                        </el-row>
                        <el-row type="flex" align="middle">
                            <el-col :span="7">
                                <div class="grid-content bg-purple">采血化验</div>
                            </el-col>
                            <el-col :span="17">
                                <div class="grid-content bg-purple-light">验血单</div>
                            </el-col>
                        </el-row>
                        <el-row type="flex" align="middle">
                            <el-col :span="7">
                                <div class="grid-content bg-purple">药物</div>
                            </el-col>
                            <el-col :span="17">
                                <el-row>
                                    <el-col :span="17">
                                        <div class="grid-content bg-purple-light overflow">小儿葵花药业</div>
                                    </el-col>
                                    <el-col :span="7">
                                        <div class="grid-content">X 3盒</div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="17">
                                        <div class="grid-content bg-purple-light overflow">阿莫西林</div>
                                    </el-col>
                                    <el-col :span="7">
                                        <div class="grid-content ">X 1盒</div>
                                    </el-col>
                                </el-row>
                            </el-col>
                        </el-row>
                        <el-row type="flex" align="middle">
                            <el-col :span="7">
                                <div class="grid-content bg-purple">支付</div>
                            </el-col>
                            <el-col :span="17">
                                <div class="grid-content purple-light">100元</div>
                            </el-col>
                        </el-row>
                        
                    </Container>
                </Container>
            </Container>
  

    </el-tabs>
</Container>
</template>

<script>
import {
  components
} from '@/helper' ;

export default {
    components: components([
        'Container'
    ]),
    data() {
        return {
           
        }
    },
    methods: {
        
    }
};
</script>

<style lang="scss" scoped>
.see-patient-history-list {
    padding: 0 10px 10px;

    .see-patient-history-list-item {
        width: 100%;
        border: 1px solid #e1e1e1;

        >.header {
            padding: 0 10px;
            height: 50px;
            line-height: 50px;
            background-color: #ededed;
            border-bottom: 1px solid #e1e1e1;

            >.time {
                font-size: 12px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #5b5b5b;
            }

            >.button {
                height: 30px;
                margin-top: 12px;
            }
        }

        >.body {
            padding: 10px;

            >.inner {
                display: flex;
                flex-direction: column;
                border: 1px solid #979797;

                .el-row {
                    width: 100%;
                    height: auto;
                    line-height: 38px;
                    border-bottom: 1px solid #979797;
                    font-size: 12px;

                    &:last-child {
                        border-bottom: none;
                    }
                }

                .bg-purple {
                    text-align: center;
                    // background: #EBEBEB;
                    // border-right: 1px solid #979797;
                }

                .bg-purple-light {
                    padding: 0 10px;
                    border-left: 1px solid #979797;
                }
                .purple-light{
                    padding: 0 10px;
                    text-align: end;
                    border-left: 1px solid #979797;
                }
                .display{
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                }
                .bg-purple-light-select {
                    padding: 0;
                    font-size: 12px;
                }
            }
        }

        .edit-buttom {
            width: 100%;
            margin-top: 20px;
        }
    }
}

.overflow{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    border-left: 1px solid #979797;
}

</style>
