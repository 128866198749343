<template>
  <div class="hb-layout-fit-unsized hb-layout-horizontal">
    <div class="calendar hb-layout-vertical hb-layout-flex-1">
      <div class="calendar-details">
        <Header
          @changebuttonclick="onChangeButtonClick"
          @previousbuttonclick="onPreviousButtonClick"
          @nextbuttonclick="onNextButtonClick"
          :calendarSetting="calendarSetting"
        ></Header>
        <div class="form">
          <!--
            2:周视图
          -->
          <Calendar
            v-if="calendarSetting == 2" 
            ref="calendar"
            :times="times"
            :plans="plans"
            class="hb-layout-flex"
            @blankclick="onBlankClick"
            @weekchange="onWeekChange"
          >
            <template v-slot:plan="props">
              <!-- 待确认 
                --status = 预约状态 0=待确认 1=确认 2=取消预约 3=客户爽约
                --diagnosis_status =  看诊状态 1=到店 0=未到店
                --see_status = 看诊状态 1=待诊 2=已诊
                --status=0 && diagnosis_status=0 待确认 
              -->
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-warning"
                v-if="props.plan.status == 0 && props.plan.diagnosis_status == 0"
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
              <!-- 已确认 
                 --status=1 && diagnosis_status=0 待确认 
              -->
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-success"
                v-else-if="props.plan.status == 1 && props.plan.diagnosis_status == 0"
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
              <!-- 取消预约--工作人员主动取消 
                 --status=2 && diagnosis_status=0&&see_status=0
               -->
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center  plan-danger"
                v-else-if="
                  props.plan.status == 2 &&
                  props.plan.diagnosis_status == 0 &&
                  props.plan.see_status == 0
                "
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>

              <!-- 客户爽约
                  --status=3 && diagnosis_status=0&&see_status=0
               -->
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-danger"
                v-else-if="
                  props.plan.status == 3 &&
                  props.plan.diagnosis_status == 0 &&
                  props.plan.see_status == 0
                "
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
              <!-- 已到店
                 --diagnosis_status=1&&see_status=0
              -->
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-primary"
                v-else-if="props.plan.diagnosis_status == 1 && props.plan.see_status == 0"
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
              <!-- 已服务 
                 -- diagnosis_status=1&&see_status=2
              -->
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-info"
                v-else-if="props.plan.see_status == 1 || props.plan.see_status == 2"
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
            </template>
          </Calendar>
          <!--
            1:日视图
          -->
          <ClinicSchedulingDay
            ref="calendar"
            v-if="calendarSetting == 1"
            :times="times"
            :doctors="timeDoctors"
            :plans="plans"
            @cellselect="onCellSelect"
            @daychange="onDayChange"
          >
            <template v-slot:plan="props">
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-warning"
                v-if="props.plan.status == 0 && props.plan.diagnosis_status == 0"
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-success"
                v-else-if="props.plan.status == 1 && props.plan.diagnosis_status == 0"
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>

              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center  plan-danger"
                v-else-if="
                  props.plan.status == 2 &&
                  props.plan.diagnosis_status == 0 &&
                  props.plan.see_status == 0
                "
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-danger"
                v-else-if="
                  props.plan.status == 3 &&
                  props.plan.diagnosis_status == 0 &&
                  props.plan.see_status == 0
                "
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-primary"
                v-else-if="props.plan.diagnosis_status == 1 && props.plan.see_status == 0"
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
              <div
                @click="onPlanClick(props.plan)"
                class="plan hb-layout-center plan-info"
                v-else-if="props.plan.see_status == 1 || props.plan.see_status == 2"
              >
                <el-tooltip effect="dark" :content="props.plan.patient" placement="top">
                  <div class="plan-text">
                    {{ props.plan.patient }}-{{ props.plan.duration }}分钟
                  </div>
                </el-tooltip>
              </div>
            </template>
          </ClinicSchedulingDay>

          <Add
            ref="appoinetDrawer"
            :direction="direction"
            :size="size"
            style="fontsize: 16px"
            :drawer_title="drawer_add_title"
            :appoinetDates="appoientDates"
            @medicalrecordsearch="onMedicalRecordSearch"
            :searchrecords="searchrecords"
            :doctors="timeDoctors"
            :departmentDatas="departmentDatas"
            :settingDatas="settingDatas"
            @appointmentsaveclick="onAppointmentSaveClick"
            @getdoctortimereturn="onGetDoctorTimeReturn"
            :doctorDayTime="doctorDayTime"
            :doctordaylistData="doctordaylistData"
            :classesSettingData="classesSettingData"
            :doctorClassesListData="doctorClassesListData"
          
          ></Add>
        </div>
        <Footer></Footer>
      </div>
    </div>
  </div>
</template>

<script>
import { components } from "@/helper";
import "hb_calendar/dist/calendar.css";
import ClinicSchedulingDay from "clinic-scheduling-day";
import Calendar from "hb_calendar";
import "clinic-scheduling-day/index.css";
export default {
  props: {
    doctorClassesListData: {},
    classesSettingData: {},
    doctordaylistData: {},
    doctorDayTime: true,
    settingDatas: {},
    departmentDatas: {},
    searchrecords: {},
    times: Array,
    plans: Array,
    doctors: Array,
    data: Array,
    calendarSetting: 0,
    timeDoctors: {},
    doctorId: "",
    dayTime: "",
    
  },

  components: {
    ClinicSchedulingDay,
    Calendar,
    ...components(["Add"], "appointmentCalendar.Center"),
    ...components(["Header", "Footer"], "appointmentCalendar.Center"),
    ...components(["Container"]),
  },
  data() {
    return {
      direction: "rtl",
      size: "35%",
      drawer_add_title: "新增预约",
      appoientDates: "",
      ranges:[],
      computedRanges: null,
        selectedRangesAdd: [],
    };
  },
  watch: {
    classesSettingData:function(val){
      // console.log("classesSettingData-add",val);
    }
  },
  methods: {


    onDayChange(day){
      this.$emit("daychange", day);
    },

    //日视图-点击时间-返回医生ID+时间
    onCellSelect(scheduling, doctor, time) {
      //console.log("planclick",doctor);
    
      let times = this.dayTime + " " + time;
      let timeReplace = times.replace(/-/g, "/");
      let data = {
        dateTime: this.formatWeek(new Date(timeReplace)),
        startTime: this.formatTime(new Date(timeReplace)),
        endTime: this.formatEndTime(new Date(timeReplace)),
        doctorId: doctor,
        time: new Date(),
      };
      this.appoientDates = data; //传递给ADD.vue
      this.$emit("blankclick",data);
      this.$refs.appoinetDrawer.changeDrawer(true);
    },
    onAppointmentSaveClick(val) {
      this.$emit("appointmentsaveclick", val);
    },
    onWeekChange(date) {
      this.$emit("weekchang", date);
    },
    onPreviousButtonClick() {


      this.$refs.calendar.previous();
    },

    onNextButtonClick() {
      this.$refs.calendar.next();
    },

    onDoctorButtonClick(plans) {
      this.plans = plans;
    },
    //搜索用户
    onMedicalRecordSearch(val) {
      //search(4):触发上级事件
      this.$emit("medicalrecordsearch", val);
    },
    onPlanClick(plan) {
      console.log("planclick",plan);
      this.$emit("planclick", plan);
    },
    //周视图空白区域事件
    onBlankClick(time) {
      //console.log("sdsd",time);
      let timeReplace = time.replace(/-/g, "/");
      let data = {
        dateTime: this.formatWeek(new Date(timeReplace)),
        startTime: this.formatTime(new Date(timeReplace)),
        endTime: this.formatEndTime(new Date(timeReplace)),
        doctorId: this.doctorId,
        time: new Date(),
      };
      this.appoientDates = data;
      this.$emit("blankclick",data);
      this.$refs.appoinetDrawer.changeDrawer(true);
      //this.$emit("blankclick", time);
    },
    changeDrawer(val) {
      this.$refs.appoinetDrawer.changeDrawer(val);
    },
    onChangeButtonClick(val) {
      this.$emit("changebuttonclick", val);
    },
    formatWeek(date) {
      let myyear = date.getFullYear();
      let mymonth = date.getMonth() + 1;
      let myweekday = date.getDate();
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
    formatTime(date) {
      let myyear = date.getHours();
      let mymonth = date.getMinutes();
      if (myyear < 10) {
        myyear = "0" + myyear;
      }
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      return myyear + ":" + mymonth;
    },
    formatEndTime(date) {
      let datas = date.setMinutes(date.getMinutes() + 15);

      return this.formatDateTime3(datas, "HH:mm");
    },
    formatDateTime3: function (time, format) {
      var t = new Date(time);
      var tf = function (i) {
        return (i < 10 ? "0" : "") + i;
      };
      return format.replace(/yyyy|MM|dd|HH|mm|ss/g, function (a) {
        switch (a) {
          case "yyyy":
            return tf(t.getFullYear());
            break;
          case "MM":
            return tf(t.getMonth() + 1);
            break;
          case "mm":
            return tf(t.getMinutes());
            break;
          case "dd":
            return tf(t.getDate());
            break;
          case "HH":
            return tf(t.getHours());
            break;
          case "ss":
            return tf(t.getSeconds());
            break;
        }
      });
    },
    onGetDoctorTimeReturn(val){

      this.$emit("doctortimereturn", val);
    }
  },
};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.doctors {
  width: 200px;
  padding: 5px;
  border-right: 1px solid #000;

  > button {
    margin: 10px 0 10px 0;
  }
}

.calendar {
  padding: 5px;
}

.calendar-details {
  height: 100%;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  padding: 10px;
}

.toolbar {
  height: 60px;
  margin-bottom: 20px;

  > button {
    margin: 0 10px 0 10px;
  }
}

.plan {
  background-color: rgb(235, 192, 192);
  font-size: 13px;
  height: 100%;

  .plan-text{
    padding: 5px 10px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}

.plan:hover {
  background-color: rgb(242, 216, 216);
}

.hb-calendar.week {
  .title {
    height: 30px !important;
  }

  .time:hover {
    background-color: rgb(242, 216, 216);
  }

  > .times {
    border: none !important;
    border-right-width: 0 !important;
    line-height: 30px;

    > .title,
    > .time:not(:last-child) {
      border-bottom: 1px solid #979797 !important;
    }
  }

  > .weeks {
    > .week {
      border: none !important;
      border-left: 1px solid #979797 !important;
      border-right: none !important;

      > .title,
      > .time:not(.time:last-of-type) {
        border-bottom: 1px solid #979797 !important;
      }
    }
  }
}

.hb-layout-flex {
  border: 1px solid #979797 !important;
}

.form {
  height: calc(100% - 130px);
  overflow-y: auto;
}
.plan-primary {
  background-color: #409eff;
  color: white;
}
.plan-success {
  background-color: #67c23a;
  color: white;
  height: 100% !important;
}
.plan-info {
  background-color: #909399;
  color: white;
}
.plan-danger {
  background-color: #f56c6c;
  color: white;
}
.plan-warning {
  background-color: #e6a23c;
  color: white;
}
</style>
