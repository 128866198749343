<template>
  <Container>
    <Container class="appoientment-main-operation hb-layout-horizontal">
      <div>评价咨询</div>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  data() {
    return {
      
    };
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  padding: 0 19px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 2px 10px 0px;

  div {
    width: 100%;
    font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #5B5B5B;
		line-height: 22px;
    text-align: center;
    line-height: 60px;
  }
}

</style>
