import axios from 'axios' ;
// import { Message, MessageBox } from 'element-ui'
import serverConfig from "@/../config/index";
import qs from 'qs';
import {
    token,
    scope,
    store_token,
    client_id
} from './user' ;

if(token()){

    // 创建一个axios实例
   axios.create({
      
    // api的base_url
    baseURL: serverConfig.BASE_API,
    // request timeout
    timeout: 30000,
    // 默认使用简单请求,避免复杂请求(多一次OPTIONS请求)
    // 如有特殊需求或协议不同,可修改为例如"application/json; charset=utf-8"
    headers: { 'Content-Type': 'text/plain; charset=utf-8' },
    // 启用跨域访问携带Cookie
    withCredentials: true
  
  })

    axios.interceptors.request.use(function (config) {
        
        let {
            url
        } = config ;

        url = new URL(url) ;

        let {
            searchParams
        } = url ;

        searchParams.set('token' , token()) ;

        searchParams.set('scope' , scope()) ;

        searchParams.set('client_id' , client_id()) ;

        searchParams.set('STORETOKEN' , store_token()) ;
        

        config.url = url.toString() ;
        // config.token = "121212".toString();
        return config;;
    });
    
}
