<template>
        <!--tree-->
        <el-tree
                :props="defaultProps"
                :data="data"
                show-checkbox
                node-key="id"
                :default-expand-all="false"
                :expand-on-click-node="false">
      <span class="custom-tree-node" slot-scope="{ node, data }">
        <span>{{ node.label }}</span>
        <span>
          <el-button
                  type="text"
                  size="mini"
                  @click="() => append(data)">
            增加
          </el-button>
          <el-button
                  type="text"
                  size="mini"
                  @click="() => remove(node, data)">
            删除
          </el-button>
            <el-button
                    type="text"
                    size="mini"
                    @click="() => edit(node, data)">
            编辑
          </el-button>
        </span>
      </span>
        </el-tree>
    </el-card>
</template>

<script>
    //import {getCategoryList} from "../../api/item/category";

    export default {
        name: "Category",
        data() {
            return {
                data: [],
                defaultProps: {
                    label: 'name'
                }
            }
        },
        created() {
            this.getlist();

        },
        methods: {
            getlist() {
                getCategoryList().then(res => {
                    this.data = this.arraytotree(res.data);
                }).catch(res => {

                })

            },
            handleNodeClick(data) {
            },

            //数组转化为树
            arraytotree(arr) {
                var top = [], sub = [], tempObj = {};

                arr.forEach(function (item) {
                    if (item.parentId === 0) { // 顶级分类
                        top.push(item)
                    } else {
                        sub.push(item) // 其他分类
                    }
                    item.children = []; // 默然添加children属性
                    tempObj[item.id] = item // 用当前分类的id做key，存储在tempObj中
                })

                sub.forEach(function (item) {
                    // 取父级
                    var parent = tempObj[item.parentId] || {'children': []}
                    // 把当前分类加入到父级的children中
                    parent.children.push(item)
                })

                return top
            },

            append(node,data) {
            },

            remove(node, data) {
            },
            edit(node,data){
            }
        }
    }
</script>