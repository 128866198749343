import Vue from 'vue' ;

import App from '@/pages/StoreLogin' ;
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import '../assets/styles/account.less';
// 导入组件库
import vueParticleLine from '../packages/index1'
import 'vue-particle-line/dist/vue-particle-line.css'
// 注册组件库
Vue.use(vueParticleLine)
//console.log('adfasdfsaf')
Vue.use(ElementUI);
new Vue({
  render: h => h(App),
}).$mount('#app') ;

