<template>
    <Page style="overflow:auto;height: calc(100vh - 110px);">
		<div class="header">
			<div class="header-title">欢迎你李医生，请开始今天的工作吧！</div>
		</div>
		<div class="content-icon">
			<div class="icon-list">
				<div class="icon-list-item">
					<div class="item-number">10人</div>
					<div class="item-name">今日预约</div>
				</div>
				<div class="icon-list-item">
					<div class="item-number">24人</div>
					<div class="item-name">今日看诊</div>
				</div>
				<div class="icon-list-item">
					<div class="item-number">20人</div>
					<div class="item-name">今日回访</div>
				</div>
				<div class="icon-list-item">
					<div class="item-number">2人</div>
					<div class="item-name">今日取消</div>
				</div>
			</div>
		</div>
		<div class="details">
			<div class="details-list">
				<div class="details-list-item">
					<div class="list-item-header">
						<div class="list-item-name">收费</div>
						<div class="list-item-price">12132元</div>
					</div>
					<div class="list-item-footer">
						<div slot="header" class="clearfix">
                            <div class="title">收费明细</div>
                        </div>
						<div class="form">
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">预约</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">13元</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">看诊</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123元</div></el-col>
							</el-row>
							
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">输液</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">155元</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">中药</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">323元</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">西药</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">273元</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">其他</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">86元</div></el-col>
							</el-row>
						</div>
						
					</div>
				</div>
				<div class="details-list-item">
					<div class="list-item-header">
						<div class="list-item-name">会员充值</div>
						<div class="list-item-price">34454元</div>
					</div>
					<div class="list-item-footer">
						<div slot="header" class="clearfix">
                            <div class="title">病种分析</div>
                        </div>
						<div class="form">
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">看诊</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">预约</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">输液</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">中药</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">西药</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">其他</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
						</div>
						
					</div>
				</div>
				<div class="details-list-item">
					<div class="list-item-header">
						<div class="list-item-name">充值人数</div>
						<div class="list-item-price">34人</div>
					</div>
					<div class="list-item-footer">
						<div slot="header" class="clearfix">
                            <div class="title">看病区域</div>
                        </div>
						<div class="form">
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">急诊</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">567元</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">预约</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">输液</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">中药</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">西药</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
							<el-row>
								<el-col :span="16"><div class="grid-content bg-purple">其他</div></el-col>
								<el-col :span="8"><div class="grid-content bg-purple-light">123</div></el-col>
							</el-row>
						</div>
						
					</div>
				</div>
					
			</div>
		</div>
	</Page>
</template>

<script>

import Page from '@/components/Page' ;

export default {
  components:{
    Page
  }
}
</script>
<style lang="scss" scoped>
	.header{
		width: 100%;
		height: 77px;
		line-height: 77px;
		text-align: center;
		margin: 4px 0px 20px ;
		box-shadow: 0 2px 7px 0 #d8d8d8;
		background: rgba(255, 244, 217, 0.673);
		
	    >.header-title{
			font-size: 16px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #5B5B5B;
		}
	}
	.content-icon{
		width: 100%;
		padding: 44px 0;
		line-height: 43px;
		text-align: center;
		box-shadow: 0 2px 7px 0 #d8d8d8;
		
		> .icon-list{
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 0 280px;
			
			> .icon-list-item:not(:last-child){
			    margin-right: 214px;
			}
			> .icon-list-item{
				white-space: nowrap;
				> .item-number{
					font-size: 18px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #5B5B5B;
					line-height: 14px;
				}
				>.item-name{
					// font-size: 16px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #5B5B5B;
					line-height: 14px;
					padding-top: 14px;
				}
			}
			
		}
	}
	.details{
		width: 100%;
		
		> .details-list{
			margin: 91px 20px 0;
			display: flex;
			justify-content: space-between;
			
			> .details-list-item{
				width: 33%;
				
				> .list-item-header{
					height: 214px;
					border: 1px solid #e4e2e2;
					box-shadow: 0 2px 7px 0 #d8d8d8;
					
					> .list-item-name{
						font-size: 16px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #5B5B5B;
						line-height: 14px;
						padding: 20px 15px;
						background: rgba(255, 244, 217, 0.673);
					}
					>.list-item-price{
						text-align: center;
						font-size: 24px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #5B5B5B;
						line-height: 120px;
					}
				}
				>.list-item-footer{
					border: 1px solid #e4e2e2;
					box-shadow: 0 2px 7px 0 #d8d8d8;
					margin-top: 10px;
					// padding: 39px 25px 18px;
					padding: 20px 15px;
					> .list-item-name{
						font-size: 18px;
						font-family: PingFangSC-Medium, PingFang SC;
						font-weight: 500;
						color: #5B5B5B;
						line-height: 14px;
						
					}
					>.list-item-info{
						margin-top: 19px;
						> .info-row{
							display: flex;
							align-items: center;
							justify-content: space-between;
							padding-top: 5px;
							> .row-name{
								font-size: 24px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #5B5B5B;
								line-height: 38px;
								width: 60%;
								white-space: nowrap;
								overflow: hidden;
								text-overflow: ellipsis;
							}
							> .row-text{
								font-size: 24px;
								font-family: PingFangSC-Medium, PingFang SC;
								font-weight: 500;
								color: #5B5B5B;
								line-height: 38px;
								width: 40%;
								white-space: nowrap;
								text-align: right;
								overflow: hidden;
								text-overflow: ellipsis;
							}
						}
					}

					 .clearfix {
						margin-bottom: 10px;

						.title {
							font-size: 16px;
							font-family: PingFangSC-Medium, PingFang SC;
							font-weight: 500;
						    color: #5B5B5B;
							line-height: 22px;
							margin-right: 20px;
						}
					}

					.form{
						height: 300px;
						overflow: auto;

						.el-row {
							border-right: 1px solid #e1e1e1;
							border-left: 1px solid #e1e1e1;
							// margin-bottom: 20px;
							&:last-child {
								margin-bottom: 0;
								border-bottom: 1px solid #e1e1e1;
							}
						}
						.bg-purple-dark{
							border-top: 1px solid #e1e1e1;
						}
						.bg-purple-light {
							text-align: right;
						}
						.grid-content {
							height: 40px;
							line-height: 40px;
							padding: 0 10px;
							border-top: 1px solid #e1e1e1;
						}
						.row-bg {
							padding: 10px 0;
							background-color: #f9fafc;
						}
					}
					
				}
			}
		}
	}
</style>