<template>
    <Container class="centent hb-layout-flex-1 hb-layout-vertical">
        <el-card>
            <List></List>
        </el-card>
        
    </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components([
        "List"
    ], "configuration.Staff"),
    ...components([
        "Container"
    ]),
  },

  props: {
    
  },
  
  data() {
    return {
      
    };
  },

  methods: {
   
  },
};
</script>

<style lang="scss" scoped>
.details{

    .right{
        width: 300px;
        
    }
}

</style>
