/**
 * 药品记录列表 API
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/pharmacy/method/get.pharmacy.list';

export default (data) => {

    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data)
        .then(({data}) => data)
        .then(({items}) => items.map((item) =>({ ...item })));
}
