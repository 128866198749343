<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container class="patient-detail-doctor">
      <el-container>
        <!-- <el-header></el-header> -->
        <el-main>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <div class="title">支付方式</div>
            </div>
            <el-row :gutter="20">
              <el-col
                :span="3"
                v-for="(item, index) in paymentDatas"
                :key="index"
              >
                <div
                  class="grid-content bg-purple"
                  :class="{ active: activeKey === index }"
                  @click="handleClick(index, item.code)"
                  style="padding: 20px 0"
                >
                  {{ item.name }}
                </div>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <div class="title">结算明细</div>
            </div>
            <el-row :gutter="20">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <div class="top_insurance">应付金额:</div>
                  <div class="con_insurance">
                    <span style="float: right; font-size: 24px; color: #d46b7d"
                      >¥{{ paymentAmount }}元</span
                    >
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <div class="top_insurance">已收金额:</div>
                  <div class="con_insurance">
                    <span style="float: right; font-size: 24px; color: #d46b7d"
                      >¥0.00元</span
                    >
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <div class="top_insurance">待付金额:</div>
                  <div class="con_insurance">
                    <span style="float: right; font-size: 24px; color: #d46b7d"
                      >¥0.00元</span
                    >
                  </div>
                </div>
              </el-col>
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <div class="top_insurance">找零金额:</div>
                  <div class="con_insurance">
                    <span style="float: right; font-size: 24px; color: #d46b7d"
                      >¥0.00元</span
                    >
                  </div>
                </div>
              </el-col>
            </el-row>
          </el-card>
          <el-card class="box-card">
            <el-row :gutter="20" v-if="code === 0">
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <span style="float: left">账户余额</span>
                  <span style="float: right; font-size: 24px; color: #d46b7d"
                    >¥{{ usermoneyData.balance }}元</span
                  >
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <span style="float: left">实收金额</span>
                  <span style="float: right">
                    <el-input
                      v-model="moneyInput"
                      placeholder="请输入实收金额"
                    ></el-input>
                  </span>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-else-if="code === 6">
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <div class="top_insurance">保险账户</div>
                  <div class="con_insurance">
                    <el-col :span="12" style="padding-left: 0px">
                      <el-select v-model="insurance_id" placeholder="请选择">
                        <el-option
                          v-for="(item, index) in insuranceAccountDatas"
                          :key="item.insurance_account_id"
                          :label="item.name"
                          :value="index"
                        >
                          <span style="float: left">{{ item.name }}/</span>
                          <span
                            style="float: left; color: #8492a6; font-size: 13px"
                            >{{ item.levelname }}/</span
                          >
                          <span style="float: left" v-if="item.type == 1"
                            >门诊福利</span
                          >
                          <span style="float: left" v-if="item.type == 2"
                            >物理治疗</span
                          >
                        </el-option>
                      </el-select>
                    </el-col>
                    <el-col :span="12">
                      <span style="float: right">
                        <el-input
                          disabled
                          v-model="insurance_balance"
                        ></el-input> </span
                    ></el-col>
                  </div>
                </div>
              </el-col>
              <el-col :span="12">
                <div class="grid-content bg-purple">
                  <div class="top_insurance">
                    <el-col :span="12" style="padding-left: 0px"
                      >保险支付</el-col
                    >
                    <el-col :span="12" style="padding-left: 0px"
                      >客户自付</el-col
                    >
                  </div>
                  <div class="con_insurance">
                    <el-col :span="12" style="padding-left: 0px">
                      <el-input
                        v-model="balanceInput"
                        placeholder="请输入保险支付金额"
                      ></el-input>
                    </el-col>
                    <el-col :span="12">
                      <span style="float: right">
                        <el-input
                          v-model="moneyInput"
                          placeholder="请输入客户自付金额"
                        ></el-input> </span
                    ></el-col>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20" v-else>
              <el-col :span="24">
                <div class="grid-content bg-purple">
                  <span style="float: left">实收金额</span>
                  <span style="float: right">
                    <el-input
                      v-model="moneyInput"
                      placeholder="请输入实收金额"
                    ></el-input>
                  </span>
                </div>
              </el-col>
            </el-row>
          </el-card>
        </el-main>
        <el-footer class="dialog-footer" style="height:42px">
          <el-button style="width: 150px" @click="settlementDialog = false">取 消</el-button>
          <el-button style="width: 150px" type="primary" @click="submitPaymentItem()">确 定</el-button>
        </el-footer>
      </el-container>
    </Container>
  </el-drawer>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    paymentDatas: {},
    usermoneyData: {},
    paymentAmount: "", //应付金额
    insuranceAccountDatas: {},
    paymentItems:{}
  },
  watch: {
    paymentAmount: function (val) {
      this.moneyInput = val;
    },
    //change药品分类->get药品类别
    categoryValue: function (val) {
      this.$emit("gettreatmenttemplatechildren", val);
    },
    //监控gpharmacyrecords是否记录变化 add->footer->select(val)->storehouse
    // recordsstockin: function (val) {
    treatmenttemplateChildrenDatas: function (val) {
      this.childSelectList = val;

    },
    rows: function (val) {
      let amount = 0;
      let discount = 0;
      let valArr = [];
      for (var i = 0; i < val.length; i++) {
        val[i].discount = parseFloat(
          this.NumberMul(val[i].use_price, val[i].num)
        ).toFixed(2);
        val[i].amount = parseFloat(val[i].price).toFixed(2);
        //this.now_amount += now_priceArray[i];
        amount = this.NumberAdd(amount, val[i].amount);
        discount = this.NumberAdd(discount, val[i].discount);
      }
      this.paymentParams.use_discount = parseFloat(discount).toFixed(2);
      this.paymentParams.pay_amount = parseFloat(amount).toFixed(2);
    },
    deep: true,
    insurance_id: function (val) {
      this.insuranceAccountDatas.findIndex((value, index, arr) => {
        if (index === val) {

          this.insurance_balance = value.balance;
          this.insuranceAID = value.insurance_account_id;
        }
        //return value.time == val;
      });

      if (Number(this.insurance_balance) > Number(this.paymentAmount)) {
        this.balanceInput = this.paymentAmount;
        this.moneyInput = "";
      } else {
        this.balanceInput = this.insurance_balance;
        this.moneyInput = this.NumberSub(
          this.paymentAmount,
          this.insurance_balance
        );
      }
    },
  },
  data() {
    return {
      drawer: false,
      activeKey: 0,
      code: 0,
      moneyInput: "",
      insurance_balance: 0,
      insurance_id: "",
      balanceInput: '',
      insuranceAID: "",
    };
  },
  methods: {
    handleClick(key, value) {
      this.activeKey = key;
      this.code = value;
      if (value === 6) {
        this.$emit("insuranceaccount");
        this.moneyInput = "";
      }
    },
    changeItem(val) {
      if (this.rows == undefined) {
        this.rows = new Array();
      }
      this.selectItem(val);

      let obj = {};
      obj.treatment_template_id = this.selectListItem.treatment_template_id;
      obj.name = this.selectListItem.name;
      obj.unit = this.selectListItem.unit;
      obj.num = 1;
      obj.discount = parseFloat(
        this.NumberMul(this.selectListItem.price, this.paymentParam.user_level)
      ).toFixed(2);
      obj.use_price = this.NumberMul(
        this.selectListItem.price,
        this.paymentParam.user_level
      );
      obj.amount = parseFloat(this.selectListItem.price).toFixed(2);
      obj.price = this.selectListItem.price;
      this.rows.push(obj);
    },
    //footer->child->select选择后的item
    selectItem(val) {
      this.childSelectList.findIndex((value, index, arr) => {
        if (index === val) {
          this.selectListItem = value;
        }
        //return value.time == val;
      });
    },
    //结算方能发
    submitPaymentItem() {
      if (this.moneyInput == "" || this.moneyInput == null) {
        this.$alert("请输入实收金额");
        return;
      }
      if (this.code === 0 && this.moneyInput > this.usermoneyData.balance) {
        this.$alert("账户余额不足，请选择其它支付方式或者先给账户充值！");
        return;
      }
      let payStatus =0;
      if(this.paymentItems.paymentStatus==3){ //paymentStatus==3 欠费结算
          payStatus = 3;
      }else{
          payStatus = 2;
      }
      let data = {
        to_payment: this.code,
        total_amount: Number(this.moneyInput),
        insurance_amount: Number(this.balanceInput),
        payment_status: payStatus, 
        execute_status: 1,
        insuranceAID: this.insuranceAID,
        // paymentStatus:this.paymentItems.paymentStatus
      };
      this.$emit("savepaymentitem", data);
      //结算完后需更改：apppientment_record=>payment_status(2)\execute_status(2)
      //               treatments       =>status(1)
      //               examination      =>status(1)
      //               Prescription     =>status(1)
      //               treatments       =>status(1)
      //               userMoney        =>blance
      //               Transaction->log
      //               PaymentLog->log
      //               PaymentRecord->log
    },

    //加减方法
    handleNumChange(ins, val) {
      let fun = 0;
      if (this.numss > val) {
        fun = 1;
      }
      this.numss = val;
      let rowarr = this.rows;
      let old_amount = 0;
      let old_discount = 0;
      rowarr.findIndex((value, index, arr) => {
        if (index === ins) {
          // old_discounts =
          old_discount = this.NumberMul(val - 1, this.rows[index].use_price); //事件前的价格
          old_amount = this.NumberMul(val - 1, this.rows[index].price);
          rowarr[index].discount = this.NumberMul(
            val,
            this.rows[index].use_price
          ); //事件后的价格
          rowarr[index].amount = this.NumberMul(val, this.rows[index].price); //事件后的价格
          if (fun === 0) {
            // 加
            this.paymentParams.use_discount = this.NumberSub(
              this.NumberAdd(
                this.paymentParams.use_discount,
                rowarr[index].discount
              ),
              old_discount
            ); //减去事件前的价格
          } else if (fun === 1) {
            //减
            this.paymentParams.use_discount = this.NumberSub(
              this.paymentParams.use_discount,
              rowarr[index].use_price
            ); //减去事件前的价格
          }

          this.paymentParams.pay_amount = this.NumberSub(
            this.NumberAdd(this.paymentParams.pay_amount, rowarr[index].amount),
            old_amount
          ); //减去事件前的价格
        }
        //return value.time == val;
      });

      this.rows = rowarr;
    },
    changeDrawer(v) {
      this.drawer = v;
    },
    /**
     * 生产批号填写的方法
     * @param index
     * @param remark
     */
    onBatchNumberInput(index, batch_number) {
      this.rows[index].batch_number = batch_number;
    },

    /**
     * 数量填写的方法
     * @param index
     * @param remark
     */
    onNumInput(index, num) {
      this.rows[index].num = num;
    },
    /**
     * 进价填写的方法
     * @param index
     * @param remark
     */
    onPriceInput(index, price) {
      this.rows[index].price = price;
    },

    /**
     * 删除的方法
     * @param index
     */
    onDeleteButtonClick(index, item) {
      //this.$emit('delexamination',this.rows[index].examinations_id);
      this.rows.splice(index, 1);
    },
    NumberMul(arg1, arg2) {
      let m = 0;
      let s1 = arg1.toString();
      let s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}

      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) /
        Math.pow(10, m)
      );
    },
    NumberAdd(arg1, arg2) {
      let r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m + arg2 * m) / m).toFixed(n);
    },
    NumberSub(arg1, arg2) {
      let re1, re2, m, n;
      try {
        re1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        re1 = 0;
      }
      try {
        re2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        re2 = 0;
      }
      m = Math.pow(10, Math.max(re1, re2));
      n = re1 >= re2 ? re1 : re2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    // 除数，被除数， 保留的小数点后的位数
    NumberDiv(arg1, arg2, digit) {
      let t1 = 0,
        t2 = 0,
        r1,
        r2;
      try {
        t1 = arg1.toString().split(".")[1].length;
      } catch (e) {}
      try {
        t2 = arg2.toString().split(".")[1].length;
      } catch (e) {}
      r1 = Number(arg1.toString().replace(".", ""));
      r2 = Number(arg2.toString().replace(".", ""));
      //获取小数点后的计算值
      var result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString();
      var result2 = result.split(".")[1];
      result2 = result2.substring(
        0,
        digit > result2.length ? result2.length : digit
      );

      return Number(result.split(".")[0] + "." + result2);
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-detail-doctor {
  height: 88vh;
  overflow: auto;
}
.top_insurance {
  width: 100%;
  height: 20px;
  text-align: left;
  line-height: 20px;
}

.box-card {
  margin-bottom: 20px;
}

.el-row {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.bg-purple {
  padding: 20px;
  text-align: center;
  height: 100px;
  line-height: 60px;
  background: #d3dce6;
}

.active {
  //被选中的样式
  background: #d46b7d;
  border: 1px solid #d46b7d;
  color: #fff;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.btn {
  display: flex;
  justify-content: flex-end;

  .edit-buttom {
    margin-top: 20px;
  }
}

.header {
  width: 100%;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  background-color: #ededed;
  border-bottom: 1px solid #e1e1e1;

  > .time {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;
  }

  > .button {
    height: 30px;
    margin-top: 12px;
  }
}

.title-top {
  text-align: center;
}

.cloumn-head {
  padding: 8px 12px;
  line-height: 40px;
}

.cloumn-footer {
  padding: 8px 12px;
}

$see-detail-test-inspect-item-title-width: 132px;

$see-detail-test-inspect-item-height: 45px;

.see-detail-test-inspect {
  .el-row {
    width: 100%;
    border-bottom: 1px solid #979797;

    &:last-child {
      margin-bottom: 0;
      // border-bottom: 1px solid #979797;
    }
  }

  .basic-inspect {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .bg-purple {
    background: #e5e9f2;
  }

  .bg-purple-light {
    background: #ffffff;
    // border-left: 1px solid #979797;
    // border-right: 1px solid #979797;
  }

  .purple-light {
    // border-left: 1px solid #979797;
  }

  .grid-content {
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .grid-left {
    text-align: left;
    padding-left: 10px;
  }

  .grid-right {
    text-align: right;
    padding-right: 20px;
    color: #ff0000;
    // text-decoration: underline;
    position: absolute;
    right: 0;
    top: 0;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}
</style>
