<template>
  <Container>
    <div id="printTest" style="display:inline-block;">
        <div style="page-break-after:always">
            <el-image class="item-img" src="../../image/preview_bg.png"></el-image>
            <div class="content">
                <el-row style="border:none;padding-left:10px;">
                    <el-col :span="5"><div>姓名：迈凯轮</div></el-col>
                    <el-col :span="4"><div>性别：男</div></el-col>
                    <el-col :span="4"><div>年龄：28</div></el-col>
                    <el-col :span="11"><div>病历号：MED_B51678799</div></el-col>
                </el-row>
                <el-row style="border-bottom: 1px solid #979797;margin-top:10px">
                    <el-col :span="7"><div class="grid-content bg-purple">诊断</div></el-col>
                    <el-col :span="17"><div class="grid-content bg-purple-light">上呼吸道感染</div></el-col>
                </el-row>

                <div style="margin-top:20px">
                    <div class="title">检查</div>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">项目</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">金额（元）</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">CBC</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">180.00 元</div></el-col>
                    </el-row>
                </div>
                <div style="margin-top:20px">
                    <div class="title">治疗</div>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">项目</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">金额（元）</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">CBC</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">180.00 元</div></el-col>
                    </el-row>
                </div>
                <div style="margin-top:20px">
                    <div class="title">西药处方</div>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">项目</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">金额（元）</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">CBC</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">180.00 元</div></el-col>
                    </el-row>
                </div>
                <div style="margin-top:20px">
                    <div class="title">中药处方</div>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">项目</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">金额（元）</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">CBC</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">180.00 元</div></el-col>
                    </el-row>
                </div>
                <div style="margin-top:20px">
                    <div class="title">输液</div>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">项目</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">金额（元）</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">CBC</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">180.00 元</div></el-col>
                    </el-row>
                </div>
                <div style="margin-top:20px">
                    <div class="title">账单汇总表</div>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">项目</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">金额（元）</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">总价</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">180.00 元</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">会员折扣</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">80.00 元</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">支付方式</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">会员卡</div></el-col>
                    </el-row>
                </div>
                <div style="margin-top:20px">
                    <div class="title">账单汇总表</div>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">项目</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">金额（元）</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">总价</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">180.00 元</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">会员折扣</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">80.00 元</div></el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="7"><div class="grid-content bg-purple">支付方式</div></el-col>
                        <el-col :span="17"><div class="grid-content bg-purple-light">会员卡</div></el-col>
                    </el-row>
                </div>
            </div>
        </div>
        
    </div>
    <el-button type="info" v-print="'#printTest'">打印</el-button>
  </Container>
</template>
<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  data() {
    return {
      
    };
  },
  
  methods: {
    
  },
};
</script>
<style lang="scss" scoped>
.item-img{
    position: relative;
}
.content{
    width: calc(100% - 140px);
    height: 90vh;
    overflow-y: auto;
    position: absolute;
    top: 115px;
    left: 70px;
    z-index: 99;
}
.title{
    height: 30px;
    line-height: 30px;
    padding-left: 10px;
}

.el-row {
    width: 100%;

    border-top: 1px solid #979797;
    border-left: 1px solid #979797;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 1px solid #979797;
    }
  }

  .bg-purple-light {
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
    padding-left: 10px;
  }
  .bg-purple{
    background: #EBEBEB;
    text-align: center;
  }

  .grid-content {
    height: 30px;
    line-height: 30px;
    font-size: 13px;
  }
  .row-bg {
    padding: 10px 0;
    // background-color: #f9fafc;
  }
</style>
