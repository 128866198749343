<template>
	<Container class="see-patient-list hb-layout-vertical">
		<el-card shadow="always">
			<el-row type="flex">
				<el-col :span="16">
					<el-col :span="6">
						<span>今天</span>
					</el-col>
					<el-col :span="6">
						<span>全部</span>
					</el-col>
				</el-col>
				<el-col :span="8">
                <el-dropdown>
                    <span class="el-dropdown-link">
                        接诊状态<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item , index) in statusOptions" :command="item.value" :key="index">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
			</el-row>
		</el-card>

		
	</Container>
</template>

<script>
	
	import {
	components
	} from '@/helper' ;

	export default {
		components: components([
			'Container'
		]),
		props: {
			data: Array
		},

		data() {

			return {
				statusOptions: [{
                value: undefined,
                label: '全部'
            }, {
                value: 0,
                label: '待诊'
            }, {
                value: 1,
                label: '接诊中'
            }, {
                value: 2,
                label: '检测中'
            }, {
                value: 3,
                label: '待付款'
            }, {
                value: 4,
                label: '已付款'
            }],
			};

		},

		

		methods: {

			
		}
	}
</script>

<style lang="scss" scoped>
	$see-patient-list-header-height: 50px;

	$see-patient-list-padding: 0 10px 0 10px;

	.see-patient-list {

		>.header {
			width: 100%;
			height: 50px;
			line-height: 50px;
			padding: 0 16px 0 18px;
			font-size: 14px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #5B5B5B;

			>.tody {
				padding-right: 15px;
			}
		}

	}
</style>
