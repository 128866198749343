<template>
  <ConfigurationLayout>
    <template v-slot:configuration-details>
      <div class="details">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="商品管理" name="first">
            <ShopDetails :dataList="dataList" @changeEvent="onChangeEvent"></ShopDetails>
          </el-tab-pane>
          <el-tab-pane label="商品配置" name="second">
            <SettingDetails
              :dataList="dataList"
              @changeEvent="onChangeEvent"
            ></SettingDetails>
          </el-tab-pane>
          <el-tab-pane label="评价咨询" name="third">
            <ConsultDetails
              :dataList="dataList"
              @changeEvent="onChangeEvent"
            ></ConsultDetails>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </ConfigurationLayout>
</template>

<script>
import { components, layouts, mock } from "@/helper";
import { showLoading, hideLoading } from "../utils/loading";
import initData from "../setting/Shop.js";
export default {
  components: {
    ...layouts(["Configuration"]),
    ...components(["Shop.Details", "Setting.Details", "Consult.Details"], "shop"),
  },

  data() {
    return {
      dataList: {
        create_id: "",
        loading: true,
        tabData: 0,
        page_no: 1,
        page_size: 20,
        treeRecords: {},
      },
      initSetting: {},
      activeName: "first",
      getUrl: "",
      saveUrl: "",
      delUrl: "",
      loadSetting: [],
      changeParam: {
        type: undefined,
        page_no: 1,
        page_size: 20,
        river_basin_id: undefined,
      },
      urlName: "treeRecords", //默认加载数据名称定义
      tabData: 0,
    };
  },
  mounted() {
    let that = this;
    that.initSetting = initData;
    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    that.dataList.create_id = that.userInfo.client_id;

    {
      let { activeName } = that;

      if (activeName) {
        that.$nextTick(() => {
          let { $children: tabs } = that.$refs.tabs;

          for (let tab of tabs) {
            if (tab.name === activeName) {
              that.handleClick(tab);

              break;
            }
          }
        });
      }
    }
    // 模版数据请求
    that.initTemplate();
  },
  methods: {
    async initTemplate() {
      let me = this;
        me.onGetLeftChangeOne(1);
    },
    //********************************************tabs*************************************************************************** */

    handleClick(tab, event) {
      let me = this;
      me.changeParam.page_no = 1;
      me.changeParam.page_size = 20;
      switch (Number(tab.index)) {
        case 0:
          me.dataList.tabData = 1;
          me.onGetLeftChangeOne();
          break;
        case 1:
          me.dataList.tabData = 1;
          me.loadSetting = [
   
          ]; //默认设置
          me.setPublicLoadData(me.loadSetting);
          me.onGetLeftChangeTwo(1);
          break;
        case 2:
          me.dataList.tabData = 1;
          me.loadSetting = [
         
          ];
          me.setPublicLoadData(me.loadSetting);
          me.onGetLeftChangeThree(1);
          break;
        case 3:
          me.dataList.tabData = 1;
          me.loadSetting = [
            //"divisionSetting",
         
          ];
          me.setPublicLoadData(me.loadSetting);
          me.onGetLeftChangeFour(1);
          break;
      }
    },

    //获取商品详情信息
    onGetGoodsList(tabData) {
      let me = this;
      me.tabData = 1;
      me.getUrl = "shop/Goods/getGoodsList";
      me.dataName = "GoodsList";
      let data = {};
      me.getListRecords(me.dataName, me.getUrl, 1);
    },
    //添加商品
    onAddGoods() {},
    async getListRecords(param, url, data) {
      showLoading();
      let me = this;
      //me.loading = true;
      //me.treeRecords = await mock(url, data);
      let res = await mock(url, data);

      if (res.status === 200 || res.length > 0) {
        me[param] = res.data;
        if (res.data == null) {
          me[param] = res;
        }
      }
      me.loading = false;
      hideLoading();
    },
    //分页点击事件
    onChangePagination(val) {
      let me = this;
      me.changeParam.page_no = val.current;
      me.changeParam.current = val.current;
      me.loading = true;
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },

    //*********************************************排班start*************************************************************************** */
    // 排班
    onSchedulingList() {
      let me = this;
      me.tabData = 1;
      me.getUrl = "configuration/Inside/Classes/getRecords";

      let data = {
        status: 0,
      };
      me.getTreeRecords("classesRecords", me.getUrl, data);
      //if (me.schedulingRecords.Data.length) {
      //me.schedulingRolesID = me.schedulingRecords.items[0].roles_id;
      //}
    },
    //角色
    onGetRolesList() {
      let me = this;
      me.tabData = 1;
      me.getUrl = "configuration/Inside/Roles/getRecords";

      let data = {
        status: 0,
      };
      me.getTreeRecords("rolesRecords", me.getUrl, data);
    },
    //根据角色拉取用户数据
    onGetUserList() {
      let me = this;

      me.tabData = 1;
      me.getUrl = "configuration/Inside/Doctor/getRecordsList";

      let data = {
        roles_id: 1,
      };
      me.getTreeRecords("doctorRecords", me.getUrl, data);
    },
    //拉取排班记录表
    onGetSchedulinngRecords() {
      let me = this;

      me.tabData = 1;
      me.getUrl = "configuration/ClassesScheduling/getRecords";

      let data = {
        roles_id: 1,
        new_data: me.MondayTime,
      };
      me.getTreeRecords("schedulinngRecords", me.getUrl, data);
    },
    onSchedulingPlan(data) {
      let me = this;
      me.planParam.doctor_id = data.doctor_id;
      me.planParam.scheduling_date = data.scheduling_date;
      me.getUrl = "configuration/ClassesScheduling/getTodayRecords";
      me.getTreeRecords("todaySchedulinngRecords", me.getUrl, data);
    },

    onClassesPlanChange(val) {
      let me = this;
      me.planParam.classes_id = val;
      me.tabData = 0;
      me.setUrl = "configuration/ClassesScheduling/setItem";
      me.getUrl = "configuration/ClassesScheduling/getRecords";
      me.getPlanUrl = "configuration/ClassesScheduling/getTodayRecords";
      me.setPublicItem(me.setUrl, "schedulinngRecords", me.planParam);
      //  let data={
      //    doctor_id: me.planParam.doctor_id,
      //    scheduling_date:me.planParam.scheduling_date,
      //  }
      //  me.onSchedulingPlan(data);
      // me.getTreeRecords("todaySchedulinngRecords", me.getPlanUrl, me.planParam);
    },
    //*********************************************内部管理 start*************************************************************************** */
   async onGetLeftChangeTwo(val) {
      let me = this;
      let param = undefined;
      let moduleParam = undefined;
      // let urlName = "dataSourceSetting";

      me.changeEventProcessLocked = true;

      switch (Number(val)) {
        case 1:
          // 商品列表
          me.urlHook("GoodsListSetting"); //地址钩子重新赋值
          break;
        case 2:
          // 新增商品
          me.urlHook("AddGoodsSetting");
          break;
      }
      await me.getTreeRecords(me.urlName, me.getUrl, me.changeParam);

      me.changeEventProcessLocked = false;
    },

    // //**************************************** public global start*************************************************************************** */

    onChangeEvent(data) {
      let me = this;

      // if (me.changeEventProcessLocked) {
      //   return;
      // }

      //console.log(data);
      switch (data.refName) {
        case "content-del": //调用删除事件，centent区域 内容删除
          me.onDelSelectItem(data);
          break;
        case "right-submit": //调用提交事件，right区域 内容提交，包括新增和编辑
          me.onSubmitExamination(data);
          break;
        case "left-tab": //left区域调用时间，根据传的参，调用参数的方法和值【onGetLeftChangeOne】
          let methods = me.$options.methods;
          methods[data.fun].call(me, data.tabNumber);
          break;
        case "content-search": //center区域-》screen-》search
          //console.log(data.tabNumber);
          if (data.isRestore) {
            me.dataList.loading = true;
            me.dataList.current = 1;
            me.formParam(data.formData);
            me.getTreeRecords(me.urlName, me.getUrl, me.changeParam);
          }
          break;
        case "content-pagination": //center区域-》screen-》search
          me.changeParam.page_no = data.formData.current;
          me.changeParam.page_size = data.formData.size;
          me.dataList.current = data.formData.current;
          me.dataList.page_size = data.formData.size;
          me.getTreeRecords(me.urlName, me.getUrl, me.changeParam);
          break;
        case "content-search-reset": //center区域-》screen-》reset
          me.changeParam = data.formData;
          //console.log("formData", data.formData);
          me.dataList.current = 1;

          if (data.isDestroyed !== true) {
            me.getTreeRecords(me.urlName, me.getUrl, me.changeParam);
          }

          break;
      }
    },

    //定义的缓冲数据调用
    setPublicLoadData(param) {
      let me = this;
      let typeParam = {};
      // if (param && param.typeParam) {
      //   typeParam = param.typeParam;
      // }
      me.loadSetting.map((item) => {
        let name = me.initSetting[item].name;
        let url = me.initSetting[item].getUrl;
        typeParam = me.initSetting[item].typeParam;
        me.getTreeRecords(name, url, typeParam); //me[item].name, me[item].getUrl, me[item].typeParam
      });
    },

    onSubmitExamination(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.setPublicItem(me.saveUrl, "treeRecords", data);
        })
        .catch();
    },

    onDelSelectItem(data) {
      let me = this;
      me.$confirm("确认删除信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.delPublicItem(me.delUrl, "treeRecords", data);
         // console.log("haha", me.delUrl);
        })
        .catch();
    },

    /**
     * 地址钩子，重新赋值
     * @param urlName
     * @param param
     */
    urlHook(urlName, param) {
      let me = this;
      if (param && param.tabData) {
        me.dataList.tabData = param.tabData;
        param.typeParam ? me.formParam(param.typeParam) : "";
      } else {
        me.dataList.tabData = me.initSetting[urlName].tabData;
      }
      me.initSetting[urlName].typeParam
        ? me.formParam(me.initSetting[urlName].typeParam)
        : "";
      //me.changeParam = me.initSetting[urlName].typeParam;
      // me.changeParam.page_no = undefined;
      // me.changeParam.page_size = undefined;
      me.getUrl = me.initSetting[urlName].getUrl;
      me.saveUrl = me.initSetting[urlName].saveUrl;
      me.delUrl = me.initSetting[urlName].delUrl;
    },

    /**
     * 【异步请求】
     * 获取一个数据列表
     * @param param 条件参数
     * @param url   mock地址
     * @param  Object data  请求参数
     * @returns {Promise<void>}
     */
    async getTreeRecords(param, url, data) {
      showLoading(); // 开启，页面 Loading 过渡动画
      let me = this,
        _data = {};
      //console.log("_data",data);
      // return
      // ?参数过滤
      _data = {
        ...data,
      };
      _data.type = data && data.type ? data.type : undefined;
      // _data.page_no = me.dataList.page_no;
      // _data.page_size = me.dataList.page_size;
      //console.log("_data",_data);
      try {
        let res = await mock(url, _data); // 发起请求数据

        // 处理请求结果
        if (res.status === 200 || res.length > 0) {
          this.$set(this.dataList, param, res.data); // 页面数据赋值
        }

        me.dataList.loading = false;
        hideLoading(); // 关闭，页面 Loading 过渡动画
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    /**
     * 【异步请求】
     * 删除一个指定数据
     * @param url   mock地址
     * @param param 条件参数
     * @param data  请求参数
     * @returns {Promise<void>}
     */
    async delPublicItem(url, param, data) {
      let me = this;

      try {
        let res = await mock(url, data); // 发起请求数据
        if (res.status === 200) {
          me.$message({
            type: "success",
            message: "保存成功!",
          });

          // 提交数据后根据 typeParam 的参数进行重新数据读取
          await me.getTreeRecords("treeRecords", me.getUrl, me.changeParam);
        } else {
          me.$message({
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    /**
     * 【异步请求】
     * 修改一个指定数据
     * @param url   mock地址
     * @param param 条件参数
     * @param data  请求参数
     * @returns {Promise<void>}
     */
    async setPublicItem(url, param, data) {
      let me = this;

      try {
        let res = await mock(url, data); // 发起请求数据
        if (res.status === 200) {
          me.$message({
            type: "success",
            message: "保存成功!",
          });
          // 提交数据后根据 typeParam 的参数进行重新数据读取
          await me.getTreeRecords(param, me.getUrl, me.changeParam);
        } else {
          me.$message({
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    formParam(form) {
      let me = this;
      //console.log(form);
      Object.getOwnPropertyNames(form).forEach(function (key) {
        if (form[key] !== undefined && form[key] !== null) {
          me.changeParam[key] = form[key];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
