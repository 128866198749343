<template>
<Container class="statistics">
    <div class="text item">
        <el-table ref="list" :data="tableData" highlight-current-row v-loading="loading" stripe height="calc(100vh - 265px)" style="width: 100%" >
            <el-table-column fixed type="index" width="40"></el-table-column>

            <el-table-column fixed prop="name" label="药品名称" min-width="220" align="left"></el-table-column>
              <el-table-column prop="english_name" label="英文名称" min-width="220" align="left"></el-table-column>
            <el-table-column prop="get_category.name" label="类目" min-width="100" align="left"></el-table-column>

            <el-table-column prop="specifications" label="规格" min-width="80" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.specifications }}克</div>
                </template>
            </el-table-column>

            <el-table-column sortable prop="trade_price" label="进价(元)" min-width="120" align="left">

            </el-table-column>

            <el-table-column trade_price prop="retail_price" label="售价(元)" min-width="120" align="left">

            </el-table-column>
            <el-table-column sortable prop="stock" label="库存量" min-width="120" align="left">
            </el-table-column>

            <el-table-column  prop="unitName"  label="单位" min-width="80" align="left">
            
            </el-table-column>
            <el-table-column label="规格数量" min-width="80" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.small_unit_num }}</div>
                </template>
            </el-table-column>
            <el-table-column   prop="smallUnitName"  label="规格单位" min-width="120" align="left">
                <!-- <template slot-scope="scope">
                    <div>{{ scope.row.get_small_unit.name }}</div>
                </template> -->
            </el-table-column>
            <el-table-column label="包装规格" min-width="120" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.specs }}</div>
                </template>
            </el-table-column>
            <el-table-column sortable prop="stock_warning" label="预警值" min-width="100" align="left"></el-table-column>

            <el-table-column fixed="right" label="操作" width="80" align="left">
                <template slot-scope="scope">
                    <el-dropdown trigger="click">
                        <span class="el-dropdown-link">
                            操作<i class="el-icon-arrow-down el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item icon="el-icon-plus" @click.native="editdrawer(scope.row.pharmacy_id)">编辑</el-dropdown-item>
                            <el-dropdown-item icon="el-icon-delete" @click.native="handleDelClick(scope.row.pharmacy_id)">删除</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </template>
            </el-table-column>
        </el-table>
    </div>

    <EditDrugs
      ref="edit_drawer"
      @submitadddrugs="onSubmitAddDrugs"
      :direction="direction"
      :size="size"
      :drawer_title="drawer_title"
      :pharmacyItem="pharmacyItem"
      :brandDatas="brandDatas"
      :supplierDatas="supplierDatas"
      :factoryDatas="factoryDatas"
      :pharmacyCateDatas="pharmacyCateDatas"
       :unitList="smallunitDatas"
    ></EditDrugs>
</Container>
</template>

<script>
import {
    components,
    layouts
} from "@/helper";

export default {
    components: {
        ...components(["EditDrugs"], "pharmacy.Drugs.Left"),
        ...components(["Container"]),
    },
    props: {
        loading: {
            default: false,
        },
        pharmacyCateDatas: {},
        brandDatas: {},
        supplierDatas: {},
        factoryDatas: {},
        tableData: {},
        storehouseDatas: {},
        pharmacyItem: {},
        smallunitDatas:{}
    },
    // watch: {
    //   tableData:function(val){
    //     console.log("tableData", val);
    //   }
    // },

    data() {
        return {
            size: "50%",
            drawer_title: "编辑药品",
            // drawer_add_title: "新增物资",
            direction: "rtl",
            search: "", // 搜索
            categoryValue: "", // 类目
            manufactor: [{
                    value: "1",
                    label: "内科",
                },
                {
                    value: "2",
                    label: "外科",
                },
            ],
            manufactorValue: "", // 厂家
            brandValue: "", // 品牌
            supplierValue: ""
        };
    },

    methods: {
        onSubmitAddDrugs(data) {
            let me = this;
            me.$emit("submitadddrugs", data);
        },
        onSubmitEditStorehouse(data) {
            let me = this;
            me.$emit("submiteditstorehouse", data);
        },
        //点击编辑事件->调用Drawer窗口
        editdrawer(val) {
            //this.editdrawers=true;
            //通过ref=edit_drawer 调用 editdrugs 组件里的changeDrawer方法
            this.$refs.edit_drawer.changeDrawer(true, val);
            this.$emit("changeeditdrugs", val);
            //this.changeDrawer(true);
            //console.log("1212", val);
        },
        handleDelClick(val){
           let data = {
               pharmacy_id:val
           }
          this.$emit("delselectitem", data);
        },
        addMaterial() {
            this.$refs.add_drawer.changeDrawer(true);
        },
        changeDrawers(val) {
            //this.editdrawers=true;
            //通过ref=edit_drawer 调用 editdrugs 组件里的changeDrawer方法
            this.$refs.edit_drawer.changeDrawers(val);
            //this.changeDrawer(true);
            //console.log("1212", val);
        },
    },
};
</script>

<style lang="scss" scoped>
.item {
//   margin-top: 10px;
}
</style>
