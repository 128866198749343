<template>
  <AppLayout>
    <template v-slot:logo>
      <img class="header-logo" src="../public/image/logo.png" />
    </template>
    <template v-slot:menu>
      <Menu :items="pages" @menuitemselect="onMenuItemSelect"></Menu>
    </template>
    <!-- <template v-slot:lang>
      <h1 class="language">中文</h1>
    </template> -->
    <template v-slot:user>
      <div class="user">
        <el-dropdown>
          <span class="el-dropdown-link user-name">
            {{ userInfo.name }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="handleLoginOutClick()"
              >退出</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </template>
    <template v-slot:body>
      <router-view></router-view>
    </template>
  </AppLayout>
</template>

<script>
import { mock, components, layouts, blocks, config } from "@/helper";

const pages = config("pages");

export default {
  data() {
    return {
      pages,
      //circleUrl: "https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2606549919,3922038670&fm=26&gp=0.jpg",
      userInfo: "",
    };
  },

  components: {
    ...layouts(["App"]),
    ...blocks([]),
    ...components(["Menu"], "app"),
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
  },

  methods: {
    onMenuItemSelect(path) {
      this.$router.push(path);
    },
    handleLoginOutClick() {
      this.hasloginOut();
    },
    async hasloginOut() {
      let me = this;

      let res = await mock("user/setLoginOut");

      if (res.status === 200) {
        me.$message({
          type: "success",
          message: "登录成功!",
        });

        sessionStorage.removeItem("HB-LOGIN-USER-INFO");

        location.href = "login.html";

        return;
      } else {
        me.$message({
          type: "warning",
          message: res.message,
        });
        me.loading = false;
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.header-logo {
  width: 160px;
  height: 40px;
}

.language {
  padding: 4px 15px;
  background-color: #d8d8d8;
  border: 1px solid #979797;
  margin-right: 30px;
  white-space: nowrap;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
}

.user {
  display: flex;
  align-items: center;
  white-space: nowrap;

  > .logo {
    margin-right: 10px;
  }

  > .user-name {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #3b3b3b;
    line-height: 20px;
    white-space: nowrap;
  }
}
</style>
