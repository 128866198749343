<template>
<Container class="appoientment-detail hb-layout-vertical">
    <div class="screen">
        <el-button-group>
            <el-button type="primary">全部</el-button>
            <el-button type="primary">已约</el-button>
            <el-button type="primary">到店</el-button>
            <el-button type="primary">待诊</el-button>
            <el-button type="primary">已诊</el-button>
            <el-button type="primary">已退</el-button>
        </el-button-group>
        <div style="margin-left: 10px">
            <el-select v-model="departmentValue" placeholder="请选择科室">
                <el-option
                    v-for="item in departmentOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                </el-option>
            </el-select>
        </div>
        
    </div>
    <div>
        <el-table
            :data="tableData"
            style="width: 100%"
            >
            <el-table-column
                fixed
                prop="name"
                label="姓名"
                width="150"
                align="center">
            </el-table-column>
            <el-table-column
                prop="sex"
                label="性别"
                width="80"
                align="center">
            </el-table-column>
            <el-table-column
                prop="age"
                label="年龄"
                width="120"
                align="center">
            </el-table-column>
            <el-table-column
                prop="phone"
                label="联系电话"
                width="120"
                align="center">
            </el-table-column>
            
            <el-table-column
                prop="typeOfVisit"
                label="就诊类型"
                width="100"
                align="center">
            </el-table-column>
            <el-table-column
                prop="department"
                label="科室"
                width="100"
                align="center">
            </el-table-column>
            <el-table-column
                prop="doctor"
                label="医生"
                width="100"
                align="center">
            </el-table-column>
            <el-table-column
                prop="appointmentStatus"
                label="预约状态"
                width="100"
                align="center">
            </el-table-column>
            <el-table-column
                prop="arrivalTime"
                label="到店时间"
                width="160"
                align="center">
            </el-table-column>
            <el-table-column
                prop="timeOfAppointment"
                label="预约时间"
                width="160"
                align="center">
            </el-table-column>
            <el-table-column
                prop="visitTime"
                label="看诊时间"
                width="160"
                align="center">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="80"
                align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">到店</el-button>
                </template>
            </el-table-column>
            
        </el-table>
    </div>

    <!-- <el-row style="width: 100%;">
        <el-col :span="3">
            <div class="grid-content bg-purple">姓名</div>
        </el-col>
        <el-col :span="2">
            <div class="grid-content bg-purple-light">性别</div>
        </el-col>
        <el-col :span="4">
            <div class="grid-content bg-purple">出生年月</div>
        </el-col>
        <el-col :span="4">
            <div class="grid-content bg-purple-light">联系方式</div>
        </el-col>
        <el-col :span="3">
            <div class="grid-content bg-purple">就诊类型</div>
        </el-col>
        <el-col :span="4">
            <div class="grid-content bg-purple-light">预约时间</div>
        </el-col>
        <el-col :span="4">
            <div class="grid-content bg-purple">看诊时间</div>
        </el-col>
    </el-row> -->
    <!-- <List ref="list" class="body hb-layout-flex-1" itemCls=".see-patient-list-item" :scroll="true" :is_test="true" :data="plans" @itemselect="onItemSelect">
        <template v-slot:item="{
                record,
                selected
            }">
            <AppointmentListPatientItem :doctorId="record.doctorId" :name="record.real_name" :sex="record.sex" :patient="record.patient" :age="record.age" :start="record.start" :end="record.end" :selected="selected"></AppointmentListPatientItem>
        </template>
    </List> -->

</Container>
</template>

<script>
import {
    components,
    layouts
} from '@/helper';

export default {
    components: {
        ...components([
            'List',
            'Container',
            'appointment.list.patient.Item',
        ])
    },
    props: {
        plans: Array,
    },

    data() {
        return {
            tableData: [{
                name: 'James',
                sex: '男',
                age: '21岁',
                phone: '16797650082',
                state: '待诊',
                typeOfVisit: '初诊',
                department: '内科',
                doctor: '赵圣贤医生',
                appointmentStatus: '待确认',    // 0、待确认  1、已确认   2、已取消
                arrivalTime: '2021-05-19 11:00',
                timeOfAppointment: '2021-05-19 11:30',
                visitTime: '2021-05-19 11:30',
            }],
            departmentOptions: [
                {
                    value: '1',
                    label: '内科'
                },{
                    value: '2',
                    label: '外科'
                },{
                    value: '3',
                    label: '神经内科'
                }
            ],
            departmentValue: ''
        }
    },
    methods: {

        onDoctorButtonClick(plans) {

            this.plans = plans;
        },

        onPlanClick() {

            this.$emit('planclick');
          //  console.log('预约信息');
        },
        onItemSelect(doctorId) {
           // console.log('预约信息');
            this.$emit('planclick', doctorId);
        },
    }
}
</script>

<style lang="scss" scoped>
.appoientment-detail {
    width: 99%;
    height: 100%;
    margin: 2px 5px 15px;
    box-shadow: 0 2px 7px 0 #d8d8d8;
    padding: 15px 15px 35px;
    overflow: auto;
    
    .screen{
        margin-bottom: 10px;
        display: flex;
        align-items: center;
    }

    .el-row {
        border-top: 1px solid #d8d8d8;

        &:last-child {
            margin-bottom: 0;
            border-bottom: 1px solid #d8d8d8;
        }
    }

    .grid-content {
        height: 60px;
        line-height: 60px;
        text-align: center;
    }

}
</style>
