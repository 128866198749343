/**
 * 保存看诊记录 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/appointment_record/method/get.patient.appointment.item/';

export default (data) => {

    return axios.get(url, {
            params: {
                appointment_record_no: data
            }
        }).then(({
            data
        }) => data)
        .then(({
            data
        }) => data);
}