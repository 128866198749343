<template>
  <el-pagination
    background
    :current-page="current"
    :page-size="size"
    :total="total"
    :page-sizes="sizes"
    :disabled="loading"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    style="margin-top: 20px;">
  </el-pagination>
</template>

<script>
import { components } from "@/helper";
export default {
  props: {
    current: {
      default: 0
    },
    size: {
      default: 0
    },
    total: {
      default: 0
    },
    loading: {
      default: false
    },
    isSize: {
      default: true
    }
  },
  data() {
    return {
      sizes: [10, 25, 50, 100, 250, 500],
      layout: 'total, sizes, prev, pager, next, jumper',
      simple: 'total, prev, pager, next, jumper'
    }
  },
  methods: {
    handleSizeChange(val) {
      this.databasePage({ user: true })
        .then(res => {
          res.set('size', val).write()
        })

      this.$emit('change', {
        current: this.current,
        size: val,
        total: this.total
      })
    },
    handleCurrentChange(val) {
      this.$emit('change', {
        current: val,
        size: this.size,
        total: this.total
      })
    }
  }
}
</script>
