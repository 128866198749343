<template>
  <Container>
    <!--    左：顶部搜索-->
    <LeftSearch :loading="loading" @leftsearch="onLeftSearch"></LeftSearch>

    <LeftScreen
      @changestatus="onChangeStatus"
      @todayevent="onTodayEvent"
      @sevenevent="onSevenEvent"
      @thirtyevent="onThirtyEvent"
    ></LeftScreen>

    <div>
      <div v-if="medicalRecordList.length > 0">
        <List
          ref="list"
          class="body hb-layout-flex-1"
          itemCls=".see-patient-list-item"
          @itemselect="onItemSelect"
          v-model="selected"
          :scroll="true"
          :data="medicalRecordList"
        >
          <template v-slot:item="{ record, selected }">
            <LeftItems
              :appointment_record_no="record.appointment_record_no"
              :amedicalRecordNo="record.medicalRecordNo"
              :lvlname="record.lvlname"
              :name="record.real_name"
              :start_time="record.start_time"
              :end_time="record.end_time"
              :executeStatus="record.executeStatus"
              :selected="selected"
            >
            </LeftItems>
          </template>
        </List> 
      </div>
      <div class="text item return-visit" v-else>
        <el-empty description="暂无数据" :image-size="100"></el-empty>
      </div>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Left.Items", "Left.Search", "Left.Screen"], "implement"),
    ...components(["List", "Container"]),
  },

  props: {
    medicalRecordList: {
      type: Array,
      default: () => [],
    },
  },
  watch:{
    medicalRecordList(data){

      let me = this ;

        me.$nextTick(() => {
        if (data.length) {
          me.$refs.list.select(data[0]);
        }
      });


      // if(data.length){

      //   me.$refs.list.select(data[0]) ;
        
      // }
    }
  },

  data() {
    return {
      loading: {},
      selected: null,
      options: [
        {
          value: undefined,
          label: "全部",
        },
        {
          value: 0,
          label: "待诊",
        },
        {
          value: 1,
          label: "已诊",
        },
        {
          value: 2,
          label: "检测中",
        },
        {
          value: 3,
          label: "待打款",
        },
        {
          value: 4,
          label: "已打款",
        },
      ],
      status: undefined,
    };
  },

  methods: {
    onItemSelect(requestData) {
      //c//onsole.log("患者预约号", requestData);
      this.$emit("medicalRecordSelect", requestData);
    },
    select(patient) {
      this.$refs.list.select(patient);
    },
    /**
     * 改变状态
     */
    onChangeStatus(command) {
      this.$emit("changestatus", command);
    },
    onLeftSearch(command) {
      this.$emit("leftsearch", command);
    },
    onTodayEvent() {
     // console.log("2");
      this.$emit("todayevent");
    },
    onSevenEvent() {
     // console.log("2");
      this.$emit("sevenevent");
    },
    onThirtyEvent() {
     // console.log("2");
      this.$emit("thirtyevent");
    },
  },
};
</script>

<style lang="scss" scoped>
$see-patient-list-header-height: 50px;

$see-patient-list-padding: 0 10px 0 10px;

.see-patient-list {
  > .header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 16px 0 18px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;

    > .tody {
      padding-right: 15px;
    }
  }

  .el-dropdown-link {
    font-size: 12px;
    padding-left: 20px;
  }
}
</style>
