/**
 * 编辑一个患者的预约详情，更改预约信息 API
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/classes_scheduling/method/get.doctor.list';

export default (data) => {
 
    return axios.post(url, qs.stringify({
            ...data
        }))
        .then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items
        }) => items.map(({
            doctor_id,
            getDoctor
        }) => ({
            name: getDoctor.nickname,
            id: doctor_id,
        })));
}