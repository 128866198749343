<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container>
      <div class="details">
        <el-card class="box-card card-details">
          <div slot="header" class="clearfix">
            <div class="title">基本信息  <span style="color:#ff0000;font-weight: bold;">  (姓、名、中文名必须填写一个)</span> </div>
          </div>
          <div class="form">
            <el-descriptions class="margin-top" :column="3" :size="size" border>
              <el-descriptions-item>
                <template slot="label">
                   <span style="color:#ff0000;font-weight: bold;">*</span>  
                  姓<br />
                  First Name
                </template>
                <el-input v-model="surnameInput" placeholder="请输入姓" value=""></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                   <span style="color:#ff0000;font-weight: bold;">*</span>  
                  名<br />
                  Last Name
                </template>
                <el-input v-model="nameInput" placeholder="请输入名" value=""></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                   <span style="color:#ff0000;font-weight: bold;">*</span>  
                  中文名<br />
                  Chinese Name
                </template>
                <el-input v-model="chineseNameInput" placeholder="请输入中文名" value=""></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  性别<br />
                  Sex
                </template>
                <el-select v-model="sexValue" placeholder="请选择性别" style="width: 100%">
                  <el-option
                    v-for="(item , index)  in sexOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  生日<br />
                  Birthday
                </template>
                <el-date-picker
                  v-model="birthdayValue"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                  @change="jsGetAge"
                  value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  年龄<br />
                  Age
                </template>
                <el-input v-model="ageInput" placeholder="请输入年龄"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  证件号码<br />
                  Certificate No
                </template>
                <el-input v-model="statusIdInput"  placeholder="请输入证件号码"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  社保号码<br />
                  Social Security No
                </template>
                <el-input v-model="socialSecurityInput" placeholder="请输入社保号码"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  国家<br />
                  Country
                </template>
                <el-select v-model="countryValue" placeholder="请选择国家" style="width: 100%">
                  <el-option
                    v-for="(item , index) in prefixDefault"
                    :key="index"
                    :label="item.cn"
                    :value="item.prefix"
                  >
                    <span style="float: left">{{ item.cn }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.en }}</span>
                  </el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  籍贯<br />
                  Native Place
                </template>
                <el-cascader
                  style="width: 100%"
                  v-model="nativePlaceValue"
                  :props="defaultParams"
                  :options="treeSelectData"
                  :show-all-levels="false"
                  @change="handleChange">
                </el-cascader>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  身高(cm)<br />
                  Height
                </template>
                <el-input v-model="heightInput" placeholder="请输入身高"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  体重(kg)<br />
                  Weight
                </template>
                <el-input v-model="weightInput" placeholder="请输入体重"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  婚否<br />
                  Marriage
                </template>
                <el-select v-model="maritalStatusValue" placeholder="请选择婚姻状况" style="width: 100%">
                  <el-option
                    v-for="(item , index) in maritalStatusOptions"
                    :key="index"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  职业<br />
                  Occupation
                </template>
                <el-input v-model="occupationInput" placeholder="请输入职业"></el-input>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-card>

        <el-card class="box-card card-details">
          <div slot="header" class="clearfix">
            <div class="title">联系方式</div>
          </div>
          <div class="form">
            <el-descriptions class="margin-top" :column="3" :size="size" border>
              <el-descriptions-item>
                <template slot="label">
                  <span style="color:#ff0000;font-weight: bold;">*</span>  手机号<br />
                  Phone
                </template>
                <el-input v-model="phoneInput" placeholder="请输入手机号" value=""></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  电话<br />
                  Telephone
                </template>
                <el-input v-model="telephoneInput" placeholder="请输入电话" value=""></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  邮编<br />
                  Zip Code
                </template>
                <el-input v-model="postcodeInput" placeholder="请输入邮编" value=""></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  邮箱<br />
                  Email
                </template>
                <el-input v-model="emailInput" placeholder="请输入邮箱"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  所在地区<br />
                  Location
                </template>
                <el-cascader
                  ref="cascaderAddr"
                  style="width: 100%"
                  v-model="locationInput"
                  :props="defaultParams"
                  :options="treeSelectData"
                  :show-all-levels="true"
                  @change="handleChanges"
                ></el-cascader>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  详细地址<br />
                  Address
                </template>
                <el-input v-model="detailedAddressInput" placeholder="请输入详细地址"></el-input>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-card>
        <el-card class="box-card card-details">
          <div slot="header" class="clearfix">
            <div class="title">病史纪录</div>
          </div>
          <div class="form">
            <el-descriptions class="margin-top" :column="3" :size="size" border>
              <el-descriptions-item>
                <template slot="label">
                  过敏史<br />
                  Allergic History
                </template>
                <el-cascader
                  ref="allergicInput"
                  style="width: 100%"
                  v-model="allergicInput"
                  :props="historyParams"
                  :options="historyOne"
                  :show-all-levels="false"
                  @change="historyOneChanges"
                ></el-cascader>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  既往史<br />
                  Past History
                </template>
                <el-cascader
                  ref="pastInput"
                  style="width: 100%"
                  v-model="pastInput"
                  :props="historyParams"
                  :options="historyTwo"
                  :show-all-levels="false"
                  @change="historyTwoChanges"
                ></el-cascader>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  家族史<br />
                  Family History
                </template>
                <el-cascader
                  ref="familyInput"
                  style="width: 100%"
                  v-model="familyInput"
                  :props="historyParams"
                  :options="historyThree"
                  :show-all-levels="false"
                  @change="historyThreeChanges"
                ></el-cascader>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  现病史<br />
                  Present Disease History
                </template>
                <el-input v-model="presentDiseaseInput" placeholder="请输入现病史"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  外伤史<br />
                  Trauma History
                </template>
                <el-input v-model="traumaInput" placeholder="请输入外伤史"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  手术史<br />
                  Surgical History
                </template>
                <el-input v-model="operationInput" placeholder="请输入手术史"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  传染病史<br />
                  Infectious History
                </template>
                <el-input v-model="infectiousDiseasesInput" placeholder="请输入传染病史"></el-input>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  预防接种史<br />
                  Vaccination History
                </template>
                <el-input v-model="vaccinationHistoryInput" placeholder="请输入预防接种史"></el-input>
              </el-descriptions-item>
            </el-descriptions>
          </div>
        </el-card>
        <div class="submit-btn">
          <el-button type="danger" class="edit-buttom">重置</el-button>
          <el-button type="success" class="edit-buttom" @click="preservation"
            >保存</el-button
          >
        </div>
      </div>
    </Container>
  </el-drawer>
</template>

<script>
import { components, blocks } from "@/helper";
export default {
  props: {
    historyDatas: {},
    regionDatas: {},
    Data: {
      //病历信息
      type: Object,
      default: () => {},
    },
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
  },
  components: {
    ...blocks(["EmptyHeader"], "Header"),
    ...components(["Container"]),
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
  },
  data() {
    return {
      drawer: false,
      BasicInfoTitle: "基本信息",
      updateDrawer: false,
      surnameInput: "", //姓
      nameInput: "", //名
      chineseNameInput: "", //中文姓名
      sexOptions: [
        {
          value: 0,
          label: "保密",
        },
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      sexValue: "", //性别
      birthdayValue: "", //生日
      statusIdInput: "", // 身份证号码
      socialSecurityInput: "", // 社保号码
      countryOptions: [
        {
          value: "1",
          label: "中国",
        },
        {
          value: "2",
          label: "美国",
        },
      ],
      countryValue: "中国", // 国家
      ageInput: "", // 年龄
      heightInput: "", // 身高
      weightInput: "", // 体重
      nativePlaceOptions: [
        {
          value: "1",
          label: "江苏省",
        },
        {
          value: "2",
          label: "湖北省",
        },
      ],
      nativePlaceValue: "", // 籍贯
      nativePlaceValues: "",
      maritalStatusOptions: [
        {
          value: 0,
          label: "保密",
        },
        {
          value: "1",
          label: "未婚",
        },
        {
          value: "2",
          label: "已婚",
        },
      ],
      occupationInput: "",
      maritalStatusValue: "", //婚姻状况
      nationOptions: [
        {
          value: "1",
          label: "汉族",
        },
        {
          value: "2",
          label: "维吾尔族",
        },
      ],
      nationValue: "", // 民族
      phoneInput: "", // 手机号
      telephoneInput: "", // 电话
      postcodeInput: "", // 邮编
      locationInput: "", // 所在地区
      detailedAddressInput: "", //详细地址
      insuranceCompanyOptions: [
        {
          value: "1",
          label: "中国人寿保险",
        },
        {
          value: "2",
          label: "中国太平洋保险",
        },
      ],
      emailInput: "",
      insuranceCompanyValue: "", // 保险公司
      reimbursementRatioInput: "", // 报销比例
      allergicInput: "", //过敏史
      pastInput: "", //既往史
      familyInput: "", // 家族史
      presentDiseaseInput: "", // 现病史
      traumaInput: "", // 外伤史
      operationInput: "", // 手术史
      infectiousDiseasesInput: "", // 传染病史
      vaccinationHistoryInput: "", // 预防接种史
      diagnosis_record_no: "MAA_B5148560515416360001",
      treeSelectData: [],
      treeSelectDatas: [],
      defaultParams: {
        //可以选中一级内容
        // checkStrictly: true,
        //指定触发方式
        //expandTrigger: "hover",

        label: "region_name",
        value: "region_id",
        children: "children",
      },
      historyParams: {
        //可以选中一级内容
        checkStrictly: true,
        //指定触发方式
        //expandTrigger: "hover",
        multiple: true,
        label: "name",
        value: "medical_history_id",
        children: "childrenarr",
      },
      thsArea: "",
      thsAreaCode: "",
      historyOne: [],
      historyTwo: [],
      historyThree: [],
      toparr: [],
      toparrTwo: [],
      toparrThree: [],
      thsAreass: "",
      allergie: "",
      family_histories: "",
      past_histories: "",
      userInfo: "",
      prefixDefault: [
        { prefix: "86", en: "China", cn: "中国" },
        { prefix: "93", en: "Afghanistan", cn: "阿富汗" },
        { prefix: "355", en: "Albania", cn: "阿尔巴尼亚" },
        { prefix: "213", en: "Algera", cn: "阿尔格拉" },
        { prefix: "376", en: "Andorra", cn: "安道尔" },
        { prefix: "244", en: "Angola", cn: "安哥拉" },
        { prefix: "1264", en: "Anguilla", cn: "安圭拉" },
        { prefix: "247", en: "Ascension", cn: "阿森松岛" },
        { prefix: "1268", en: "Antigua and Barbuda", cn: "安提瓜和巴布达" },
        { prefix: "54", en: "Argentina", cn: "阿根廷" },
        { prefix: "374", en: "Armenia", cn: "亚美尼亚" },
        { prefix: "297", en: "Aruba", cn: "阿鲁巴" },
        { prefix: "61", en: "Australia", cn: "澳大利亚" },
        { prefix: "43", en: "Austria", cn: "奥地利" },
        { prefix: "994", en: "Azerbaijan", cn: "阿塞拜疆" },
        { prefix: "1242", en: "Bahamas", cn: "巴哈马" },
        { prefix: "973", en: "Bahrain", cn: "巴林" },
        { prefix: "880", en: "Bangladesh", cn: "孟加拉国" },
        { prefix: "1246", en: "Barbados", cn: "巴巴多斯" },
        { prefix: "375", en: "Belarus", cn: "白俄罗斯" },
        { prefix: "32", en: "Belgium", cn: "比利时" },
        { prefix: "501", en: "Belize", cn: "伯利兹" },
        { prefix: "229", en: "Benin", cn: "贝宁" },
        { prefix: "1441", en: "Bermuda", cn: "百慕大" },
        { prefix: "975", en: "Bhutan", cn: "不丹" },
        { prefix: "591", en: "Bolivia", cn: "玻利维亚" },
        { prefix: "387", en: "Bosnia and Herzegovina", cn: "波斯尼亚和黑塞哥维那" },
        { prefix: "267", en: "Botwana", cn: "博茨瓦纳" },
        { prefix: "55", en: "Brazill", cn: "巴西" },
        { prefix: "673", en: "Brunei", cn: "文莱" },
        { prefix: "359", en: "Bulgaria", cn: "保加利亚" },
        { prefix: "226", en: "Burkina Faso", cn: "布基纳法索" },
        { prefix: "257", en: "Burundi", cn: "布隆迪" },
        { prefix: "855", en: "Cambodia", cn: "柬埔寨" },
        { prefix: "237", en: "Cameroon", cn: "喀麦隆" },
        { prefix: "1", en: "Canada", cn: "加拿大" },
        { prefix: "238", en: "Cape Verde", cn: "佛得角" },
        { prefix: "1345", en: "Cayman Islands", cn: "开曼群岛" },
        { prefix: "236", en: "Central African Republic", cn: "中非共和国" },
        { prefix: "235", en: "Chad", cn: "乍得" },
        { prefix: "56", en: "Chile", cn: "智利" },
        { prefix: "57", en: "Colombia", cn: "哥伦比亚" },
        { prefix: "269", en: "Comoros", cn: "科摩罗" },
        { prefix: "242", en: "Republic of the Congo", cn: "刚果共和国" },
        { prefix: "243", en: "Democratic Republic of the Congo", cn: "刚果民主共和国" },
        { prefix: "682", en: "Cook Islands", cn: "库克群岛" },
        { prefix: "506", en: "Costa Rica", cn: "哥斯达黎加" },
        { prefix: "225", en: "Cote divoire", cn: "科特迪沃" },
        { prefix: "385", en: "Croatia", cn: "克罗地亚" },
        { prefix: "53", en: "Cuba", cn: "古巴" },
        { prefix: "357", en: "Cyprus", cn: "塞浦路斯" },
        { prefix: "420", en: "Czech Republic", cn: "捷克共和国" },
        { prefix: "45", en: "Denmark", cn: "丹麦" },
        { prefix: "253", en: "Djibouti", cn: "吉布提" },
        { prefix: "1767", en: "Dominica", cn: "多米尼加" },
        { prefix: "1809", en: "Dominican Republic", cn: "多米尼加共和国" },
        { prefix: "593", en: "Ecuador", cn: "厄瓜多尔" },
        { prefix: "20", en: "Egypt", cn: "埃及" },
        { prefix: "503", en: "EISalvador", cn: "艾萨尔瓦多" },
        { prefix: "372", en: "Estonia", cn: "爱沙尼亚" },
        { prefix: "251", en: "Ethiopia", cn: "埃塞俄比亚" },
        { prefix: "298", en: "Faroe Islands", cn: "法罗群岛" },
        { prefix: "679", en: "Fiji", cn: "斐济" },
        { prefix: "358", en: "Finland", cn: "芬兰" },
        { prefix: "33", en: "France", cn: "法国" },
        { prefix: "594", en: "French Guiana", cn: "法属圭亚那" },
        { prefix: "689", en: "French Polynesia", cn: "法属波利尼西亚" },
        { prefix: "241", en: "Gabon", cn: "加蓬" },
        { prefix: "220", en: "Gambia", cn: "冈比亚" },
        { prefix: "995", en: "Georgia", cn: "格鲁吉亚" },
        { prefix: "94", en: "Germany", cn: "德国" },
        { prefix: "233", en: "Ghana", cn: "加纳" },
        { prefix: "350", en: "Gibraltar", cn: "直布罗陀" },
        { prefix: "30", en: "Greece", cn: "希腊" },
        { prefix: "299", en: "Greenland", cn: "格陵兰" },
        { prefix: "1473", en: "Grenada", cn: "格林纳达" },
        { prefix: "590", en: "Guadeloupe", cn: "瓜德罗普" },
        { prefix: "1671", en: "Guam", cn: "关岛" },
        { prefix: "502", en: "Guatemala", cn: "危地马拉" },
        { prefix: "240", en: "Guinea", cn: "几内亚" },
        { prefix: "44", en: "Guernsey", cn: "根西" },
        { prefix: "224", en: "Guinea", cn: "几内亚" },
        { prefix: "592", en: "Guyana", cn: "圭亚那" },
        { prefix: "509", en: "Haiti", cn: "海地" },
        { prefix: "504", en: "Honduras", cn: "洪都拉斯" },
        { prefix: "852", en: "Hong Kong", cn: "香港" },
        { prefix: "95", en: "Myanmar", cn: "缅甸" },
        { prefix: "36", en: "Hungary", cn: "匈牙利" },
        { prefix: "354", en: "Iceland", cn: "冰岛" },
        { prefix: "91", en: "Indea", cn: "印度" },
        { prefix: "62", en: "Indonesia", cn: "印度尼西亚" },
        { prefix: "98", en: "Iran", cn: "伊朗" },
        { prefix: "964", en: "Iraq", cn: "伊拉克" },
        { prefix: "353", en: "Ireland", cn: "爱尔兰" },
        { prefix: "44", en: "Isle of Man", cn: "马恩岛" },
        { prefix: "972", en: "Israel", cn: "以色列" },
        { prefix: "93", en: "Italy", cn: "意大利" },
        { prefix: "1876", en: "Jamaica", cn: "牙买加" },
        { prefix: "81", en: "Japan", cn: "日本" },
        { prefix: "44", en: "Jersey", cn: "泽西岛" },
        { prefix: "962", en: "Jordan", cn: "约旦" },
        { prefix: "7", en: "Kazeakhstan", cn: "哈萨克斯坦" },
        { prefix: "254", en: "Kenya", cn: "肯尼亚" },
        { prefix: "383", en: "Kosovo", cn: "科索沃" },
        { prefix: "965", en: "Kuwait", cn: "科威特" },
        { prefix: "996", en: "Kyrgyzstan", cn: "吉尔吉斯斯坦" },
        { prefix: "856", en: "Laos", cn: "老挝" },
        { prefix: "371", en: "Latvia", cn: "拉脱维亚" },
        { prefix: "961", en: "Lebanon", cn: "黎巴嫩" },
        { prefix: "266", en: "Lesotho", cn: "莱索托" },
        { prefix: "231", en: "Liberia", cn: "利比里亚" },
        { prefix: "218", en: "Libya", cn: "利比亚" },
        { prefix: "423", en: "Liechtenstein", cn: "列支敦士登" },
        { prefix: "370", en: "Lithuania", cn: "立陶宛" },
        { prefix: "352", en: "Luxembourg", cn: "卢森堡" },
        { prefix: "853", en: "Macao", cn: "澳门" },
        { prefix: "389", en: "Macedonia", cn: "马其顿" },
        { prefix: "261", en: "Madagascar", cn: "马达加斯加" },
        { prefix: "265", en: "Malawi", cn: "马拉维" },
        { prefix: "60", en: "Malaysia", cn: "马来西亚" },
        { prefix: "960", en: "Maldives", cn: "马尔代夫" },
        { prefix: "223", en: "Mali", cn: "马里" },
        { prefix: "356", en: "Malta", cn: "马耳他" },
        { prefix: "596", en: "Martinique", cn: "马提尼克" },
        { prefix: "222", en: "Mauritania", cn: "毛里塔尼亚" },
        { prefix: "230", en: "Mauritius", cn: "毛里求斯" },
        { prefix: "262", en: "Mayotte", cn: "马约特" },
        { prefix: "52", en: "Mexico", cn: "墨西哥" },
        { prefix: "373", en: "Moldova", cn: "摩尔多瓦" },
        { prefix: "377", en: "Monaco", cn: "摩纳哥" },
        { prefix: "976", en: "Mongolia", cn: "蒙古" },
        { prefix: "382", en: "Montenegro", cn: "黑山" },
        { prefix: "1664", en: "Montserrat", cn: "蒙特塞拉特" },
        { prefix: "212", en: "Morocco", cn: "摩洛哥" },
        { prefix: "258", en: "Mozambique", cn: "莫桑比克" },
        { prefix: "264", en: "Namibia", cn: "纳米比亚" },
        { prefix: "977", en: "Nepal", cn: "尼泊尔" },
        { prefix: "31", en: "Netherlands", cn: "荷兰" },
        { prefix: "599", en: "Netherlands Antillse", cn: "荷属安的列斯" },
        { prefix: "687", en: "New Caledonia", cn: "新喀里多尼亚" },
        { prefix: "64", en: "NewZealand", cn: "新西兰" },
        { prefix: "505", en: "Nicaragua", cn: "尼加拉瓜" },
        { prefix: "227", en: "Niger", cn: "尼日尔" },
        { prefix: "234", en: "Nigeria", cn: "尼日利亚" },
        { prefix: "47", en: "Norway", cn: "挪威" },
        { prefix: "968", en: "Oman", cn: "阿曼" },
        { prefix: "92", en: "Pakistan", cn: "巴基斯坦" },
        { prefix: "970", en: "Palestinian", cn: "巴勒斯坦" },
        { prefix: "507", en: "Panama", cn: "巴拿马" },
        { prefix: "675", en: "Papua New Guinea", cn: "巴布亚新几内亚" },
        { prefix: "595", en: "Paraguay", cn: "巴拉圭" },
        { prefix: "51", en: "Peru", cn: "秘鲁" },
        { prefix: "63", en: "Philippines", cn: "菲律宾" },
        { prefix: "48", en: "Poland", cn: "波兰" },
        { prefix: "351", en: "Portugal", cn: "葡萄牙" },
        { prefix: "1", en: "PuertoRico", cn: "波多黎各" },
        { prefix: "974", en: "Qotar", cn: "库塔" },
        { prefix: "262", en: "Reunion", cn: "留尼汪" },
        { prefix: "40", en: "Romania", cn: "罗马尼亚" },
        { prefix: "7", en: "Russia", cn: "俄罗斯" },
        { prefix: "250", en: "Rwanda", cn: "卢旺达" },
        { prefix: "684", en: "Samoa Eastern", cn: "萨摩亚东部" },
        { prefix: "685", en: "Samoa Western", cn: "萨摩亚西部" },
        { prefix: "378", en: "San Marino", cn: "圣马力诺" },
        { prefix: "239", en: "Sao Tome and Principe", cn: "圣多美和普林西比" },
        { prefix: "966", en: "Saudi Arabia", cn: "沙特阿拉伯" },
        { prefix: "221", en: "Senegal", cn: "塞内加尔" },
        { prefix: "381", en: "Serbia", cn: "塞尔维亚" },
        { prefix: "248", en: "Seychelles", cn: "塞舌尔" },
        { prefix: "232", en: "Sierra Leone", cn: "塞拉利昂" },
        { prefix: "65", en: "Singapore", cn: "新加坡" },
        { prefix: "421", en: "Slovakia", cn: "斯洛伐克" },
        { prefix: "386", en: "Slovenia", cn: "斯洛文尼亚" },
        { prefix: "27", en: "South Africa", cn: "南非" },
        { prefix: "82", en: "Korea", cn: "韩国" },
        { prefix: "34", en: "Spain", cn: "西班牙" },
        { prefix: "94", en: "SriLanka", cn: "斯里兰卡" },
        { prefix: "1869", en: "St Kitts and Nevis", cn: "圣基茨和尼维斯" },
        { prefix: "1758", en: "St.Lucia", cn: "圣卢西亚" },
        { prefix: "1784", en: "St.Vincent", cn: "圣文森特" },
        { prefix: "249", en: "Sudan", cn: "苏丹" },
        { prefix: "597", en: "Suriname", cn: "苏里南" },
        { prefix: "268", en: "Swaziland", cn: "斯威士兰" },
        { prefix: "46", en: "Sweden", cn: "瑞典" },
        { prefix: "41", en: "Switzerland", cn: "瑞士" },
        { prefix: "963", en: "Syria", cn: "叙利亚" },
        { prefix: "886", en: "Taiwan", cn: "台湾" },
        { prefix: "992", en: "Tajikistan", cn: "塔吉克斯坦" },
        { prefix: "255", en: "Tanzania", cn: "坦桑尼亚" },
        { prefix: "66", en: "Thailand", cn: "泰国" },
        { prefix: "670", en: "Timor Leste", cn: "东帝汶" },
        { prefix: "228", en: "Togo", cn: "多哥" },
        { prefix: "676", en: "Tonga", cn: "汤加" },
        { prefix: "1868", en: "Trinidad and Tobago", cn: "特立尼达和多巴哥" },
        { prefix: "216", en: "Tunisia", cn: "突尼斯" },
        { prefix: "90", en: "Turkey", cn: "土耳其" },
        { prefix: "993", en: "Turkmenistan", cn: "土库曼斯坦" },
        { prefix: "1649", en: "Turks and Caicos Islands", cn: "特克斯和凯科斯群岛" },
        { prefix: "256", en: "Uganda", cn: "乌干达" },
        { prefix: "380", en: "Ukraine", cn: "乌克兰" },
        { prefix: "971", en: "United Arab Emirates", cn: "阿拉伯联合酋长国" },
        { prefix: "44", en: "United Kingdom", cn: "英国" },
        { prefix: "1", en: "USA", cn: "美国" },
        { prefix: "598", en: "Uruguay", cn: "乌拉圭" },
        { prefix: "998", en: "Uzbekistan", cn: "乌兹别克斯坦" },
        { prefix: "678", en: "Vanuatu", cn: "瓦努阿图" },
        { prefix: "58", en: "Venezuela", cn: "委内瑞拉" },
        { prefix: "84", en: "Vietnam", cn: "越南" },
        { prefix: "1340", en: "Virgin Islands", cn: "维尔京群岛" },
        { prefix: "967", en: "Yemen", cn: "也门" },
        { prefix: "260", en: "Zambia", cn: "赞比亚" },
        { prefix: "263", en: "Zimbabwe", cn: "津巴布韦" },
      ],
    };
  },
  watch: {
    regionDatas: function (val) {
      let treeOne = val;
      // let treeTwo = val;
      // if (treeOne.length > 0) {
      this.getTreeData(this.arraytotree(treeOne), 0, 3); //去除最后children为空
      // }
      // if (treeTwo.length > 0) {
      //   this.getTreeDatas(this.arraytotrees(treeTwo),0, 4); //去除最后children为空
      // }

    },
    historyDatas: function (val) {
      //console.log("historyDatas",val);
       this.arraytolist(val);
       this.historyOne = this.getTreeList(this.toparr);
      this.historyTwo = this.getTreeList(this.toparrTwo);
      this.historyThree = this.getTreeList(this.toparrThree);
    },
  },
  methods: {
    preservation() {
      let me = this;
  

      if ((me.surnameInput === "" || me.surnameInput === null) && (me.nameInput === "" || me.nameInput === null) && (me.chineseNameInput === "" || me.chineseNameInput === null)) {
        me.$alert("请输入姓、名、中文名");

        return;
      }

      if (me.phoneInput == "" || me.phoneInput == null) {
        me.$alert("请输入手机号码");

        return;
      }
      me.handleAddressFun();

      let data = {
        diagnosis_record_no: me.diagnosis_record_no,
        last_name: me.surnameInput,
        first_name: me.nameInput,
        chinese_name: me.chineseNameInput,
        age: me.ageInput,
        sex: me.sexValue,
        birthday: me.birthdayValue,
        identity_card: me.statusIdInput,
        social_security_no: me.socialSecurityInput,
        country: me.countryValue,
        stature: me.heightInput,
        weight: me.weightInput,
        nativity: me.nativePlaceValue,
        tel: me.telephoneInput,
        is_marriage: me.maritalStatusValue,
        race: me.nationValue,
        zipcode: me.postcodeInput,
        //region_list: me.locationInput,
        region_list: me.thsAreaCode,
        address: me.thsArea,
        complete_address: me.thsArea + "/" + me.detailedAddressInput,
        insurance_record_id: me.insuranceCompanyValue,
        mobile: me.phoneInput,
        //weight:me.reimbursementRatioInput,
        allergies: JSON.stringify(me.allergicInput),
        allergie: me.allergie,
        // thsAreass
        //allergies: me.thsAreass,
        past_history: JSON.stringify(me.pastInput),
        family_histories: me.family_histories,
        past_histories: me.past_histories,
        family_history: JSON.stringify(me.familyInput),
        illness_history: me.presentDiseaseInput,
        trauma: me.traumaInput,
        surgery: me.operationInput,
        infectious_diseases: me.infectiousDiseasesInput,
        immunizations: me.vaccinationHistoryInput,
        create_id: me.userInfo.client_id,
        occupation: me.occupationInput,
        email: me.emailInput,
      };
      me.$emit("preservation", data);
      me.updateDrawer = true;
    },

    handleAddressFun: function () {
      let me = this;
      try {
        // thsAreaCode = this.form.areaCode    // 注意1：获取value值
        //thsAreaCode = this.$refs['cascaderAddr'].getCheckedNodes()[0].pathLabels;    //注意2： 获取label值
        me.thsArea = me.$refs["cascaderAddr"].getCheckedNodes()[0].pathLabels.join("/");
        // thsAreaCode = me.$refs['cascaderAddr'].getCheckedNodes();   对象
        me.thsAreaCode = me.$refs["cascaderAddr"].getCheckedNodes()[0].path.join(",");
        //return thsAremeaCode;
        //	alert(thsAreaCode)  // 注意3： 最终结果是个一维数组对象
      } catch (err) {}
    },
    handleChange(value) {
      //me.parentValue= value;
      for (var i = 0; i < value.length; i++) {
        this.nativePlaceValue = value[i];
      }
    },
    handleChanges(value) {
      //this.parentValue= value;
      for (var i = 0; i < value.length; i++) {
        this.nativePlaceValues = value[i];
      }
    },
    historyOneChanges() {
      // this.allergie = this.$refs["allergicInput"]
      //   .getCheckedNodes()[0]
      //   .pathLabels.join("/");
      let reslist = this.$refs["allergicInput"].getCheckedNodes(); //注意2： 获取label值
      let resarr = "";
      reslist.forEach((element) => {
        resarr = resarr + element.pathLabels.slice(-1) + "/";
      });
      this.allergie = resarr.substring(0, resarr.length - 1);
      //this.allergicInput = JSON.stringify(newVal);
      // this.thsAreass = value.join(","); //value值
      //this.parentValue= value;
      // for (var i = 0; i < value.length; i++) {
      //   this.allergicInput = value[i];
      // }
    },
    // historyThreeChanges(value) {
    //   //this.parentValue= value;
    //   for (var i = 0; i < value.length; i++) {
    //     this.pastInput = value[i];
    //   }
    // },
    // historyTwoChanges(value) {
    //   //this.parentValue= value;
    //   for (var i = 0; i < value.length; i++) {
    //     this.familyInput = value[i];
    //   }
    // },
    historyTwoChanges() {
      //  this.past_histories = this.$refs["pastInput"]
      //   .getCheckedNodes()[0]
      //   .pathLabels.join("/");
      let reslist = this.$refs["pastInput"].getCheckedNodes(); //注意2： 获取label值
      let resarr = "";
      reslist.forEach((element) => {
        resarr = resarr + element.pathLabels.slice(-1) + "/";
      });
      this.past_histories = resarr.substring(0, resarr.length - 1);
      //this.pastInput = JSON.stringify(newVal);
    },
    historyThreeChanges() {
      //  this.family_histories = this.$refs["familyInput"]
      //   .getCheckedNodes()[0]
      //   .pathLabels.join("/");

      let reslist = this.$refs["familyInput"].getCheckedNodes(); //注意2： 获取label值
      let resarr = "";
      reslist.forEach((element) => {
        resarr = resarr + element.pathLabels.slice(-1) + "/";
      });
      this.family_histories = resarr.substring(0, resarr.length - 1);
      //this.pastInput = JSON.stringify(newVal);
    },
    changeDrawer(v) {
      this.drawer = v;
    },
    //数组转化为树
    arraytotree(arr) {
      var top = [],
        sub = [],
        tempObj = {};

      arr.forEach(function (item) {
        if (item.parent_id === 0) {
          // 顶级分类
          top.push(item);
        } else {
          sub.push(item); // 其他分类
        }
        item.children = []; // 默然添加children属性
        tempObj[item.region_id] = item; // 用当前分类的id做key，存储在tempObj中
      });

      sub.forEach(function (item) {
        // 取父级
        var parent = tempObj[item.parent_id] || { children: [] };
        // 把当前分类加入到父级的children中
        parent.children.push(item);
      });

      return top;
    },
    getTreeData(data, level, clevel) {
      let lvls = clevel;
      let lvl = level + 1;
      // if (lvl < 3) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else if (lvl < lvls) {
          data[i].level = lvl;
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children, lvl, clevel);
        } else {
          data[i].children = undefined;
        }
      }
      this.treeSelectData = data;
      //return data;
      // }
    },

    arraytolist(arrs) {
      let toparr = [],
        subarr = [],
        tempObjarr = {},
        toparrTwo = [],
        subarrTwo = [],
        tempObjarrTwo = {},
        toparrThree = [],
        subarrThree = [],
        tempObjarrThree = {};

      arrs.forEach(function (item) {
        if (item.type == 1) {
          if (item.parent_id === 0) {
            // 顶级分类
            toparr.push(item);
          } else {
            subarr.push(item); // 其他分类
          }
          item.childrenarr = []; // 默然添加children属性
          tempObjarr[item.medical_history_id] = item; // 用当前分类的id做key，存储在tempObj中
        } else if (item.type == 2) {
          if (item.parent_id === 0) {
            // 顶级分类
            toparrTwo.push(item);
          } else {
            subarrTwo.push(item); // 其他分类
          }
          item.childrenarr = []; // 默然添加children属性
          tempObjarrTwo[item.medical_history_id] = item; // 用当前分类的id做key，存储在tempObj中
        } else if (item.type == 3) {
          if (item.parent_id === 0) {
            // 顶级分类
            toparrThree.push(item);
          } else {
            subarrThree.push(item); // 其他分类
          }
          item.childrenarr = []; // 默然添加children属性
          tempObjarrThree[item.medical_history_id] = item; // 用当前分类的id做key，存储在tempObj中
        }
      });

      subarr.forEach(function (item) {
        // 取父级
        var parent = tempObjarr[item.parent_id] || { childrenarr: [] };
        // 把当前分类加入到父级的children中
        parent.childrenarr.push(item);
      });
      subarrTwo.forEach(function (item) {
        // 取父级
        var parent = tempObjarrTwo[item.parent_id] || { childrenarrtwo: [] };
        // 把当前分类加入到父级的children中
        parent.childrenarr.push(item);
      });
      subarrThree.forEach(function (item) {
        // 取父级
        var parent = tempObjarrThree[item.parent_id] || { childrenarrthree: [] };
        // 把当前分类加入到父级的children中
        parent.childrenarr.push(item);
      });
      this.toparr = toparr;
      this.toparrTwo = toparrTwo;
      this.toparrThree = toparrThree;
      //return toparr;
    },
    getTreeList(data) {
      // if (lvl < 3) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].childrenarr.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].childrenarr = undefined;
        } else {
          this.getTreeList(data[i].childrenarr);
        }
      }
      return data;
      // }
    },

    jsGetAge(strBirthday) {
      var returnAge;
      var strBirthdayArr = strBirthday.split("-");
      var birthYear = strBirthdayArr[0];
      var birthMonth = strBirthdayArr[1];
      var birthDay = strBirthdayArr[2];

      let d = new Date();
      var nowYear = d.getFullYear();
      var nowMonth = d.getMonth() + 1;
      var nowDay = d.getDate();

      if (nowYear == birthYear) {
        returnAge = 0; //同年 则为0岁
      } else {
        var ageDiff = nowYear - birthYear; //年之差
        if (ageDiff > 0) {
          if (nowMonth == birthMonth) {
            var dayDiff = nowDay - birthDay; //日之差
            if (dayDiff < 0) {
              returnAge = ageDiff - 1;
            } else {
              returnAge = ageDiff;
            }
          } else {
            var monthDiff = nowMonth - birthMonth; //月之差
            if (monthDiff < 0) {
              returnAge = ageDiff - 1;
            } else {
              returnAge = ageDiff;
            }
          }
        } else {
          returnAge = -1; //返回-1 表示出生日期输入错误 晚于今天
        }
      }
      this.ageInput = returnAge;
      return returnAge; //返回周岁年龄
    },
  },
};
</script>

<style lang="scss" scoped>
.see-main-operation {
  align-items: center;
  padding: 0 19px;
  height: 60px;
  line-height: 60px;
  margin: 0 4px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 5px 5px 10px;

  > div {
    font-size: 16px;
  }
}

.details {
  padding: 0 20px;
  height: calc(100vh - 110px);
  overflow: auto;

  .card-details {
    margin-top: 15px;

    .clearfix {
      .title {
        font-size: 16px;
        line-height: 20px;
      }
    }
  }

  .header {
    line-height: 40px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;
    border: 1px solid #e1e1e1;
    padding-left: 15px;
  }

  .form {
    .el-row {
      width: 100%;
      border-top: 1px solid #979797;
      border-left: 1px solid #979797;

      &:last-child {
        margin-bottom: 0;
        border-bottom: 1px solid #979797;
      }
    }

    .bg-purple-light {
      border-left: 1px solid #979797;
      border-right: 1px solid #979797;
    }
    .bg-purple {
      background: #ebebeb;
    }
    .purple-light {
      text-align: right;
      padding-right: 20px;
    }

    .grid-content {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
    }

    .content {
      height: 40px;
      line-height: 40px;
      font-size: 14px;
      padding-left: 15px;
    }

    .remarks {
      border-right: 1px solid #979797;
      min-height: 90px;
      padding: 10px;
      font-size: 14px;
    }

    .row-bg {
      padding: 10px 0;
    }
  }
}

.el-tag.el-tag--info {
  margin: 0 9px 9px 0;
}
.submit-btn {
  margin: 50px 0 20px;
  float: right;
}
</style>
