<template>
  <el-dialog
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :width="size"
  >
    <Container>
      <div class="grade">
        <div class="name">执  行  人：</div>
        <div class="content">
          <el-select
            style="width: 100%"
            v-model="userValue"
            filterable
            remote
            reserve-keyword
            placeholder="请输入姓名或手机号"
            :remote-method="remoteMethod"
            :loading="loading"
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="grade">
        <div class="name">备注信息：</div>
        <div class="content">
          <el-input
            style="width: 100%"
            v-model="bindParam.Remarks"
            placeholder="输入备注信息"
          ></el-input>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="gradeDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitBindSource">确 定</el-button>
      </div>
    </Container>
  </el-dialog>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    usermoney: {},
    drawer_title: "",
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    gradelevelDatas: {},
    bindSearchDatas: {},
  },
  watch: {
    //search(7): 监听props值是否变化，有变化就触发
    bindSearchDatas: function (newVal) {
      console.log("wathc", newVal);
      this.states = newVal;
    },
    //监听userValue值变化后，对值进行重组后赋值给bindParam
    userValue: function () {
      let me = this;
      let arr = me.userValue;
      //str = arr.join(",")
      //console.log("value", arr);
      let strs = [];
      strs = arr.split(","); //字符分割
      //console.log("原有数组元素",strs);
      strs.map(function (ele, index, arr) {
        me.bindParam.doctor_name = arr[0];
        me.bindParam.doctor_id = arr[1];
        me.bindParam.department_id = arr[2];
      });
      // console.log("原有数组元素",me.info);
    },
  },
  computed: {
    //search(8): 监听status值是否变化，变化就赋值
    listSet() {
      return this.states.map((item) => {
        return {
          value: `${item.name},${item.doctorID},${item.departmentID}`,
          label: `${item.name}-${item.mobile}`,
        };
      });
    },
  },
  data() {
    return {
      gradeDialog: false, // 会员升级的弹窗
      grade: [
        {
          value: "1",
          label: "银卡",
        },
        {
          value: "2",
          label: "金卡",
        },
      ],
      states: [],
      userValue: [],
      options: [],
      loading: false,
      bindParam: {
        doctor_id:"",
        doctor_name:"",
        department_id:"",
        Remarks: "",
      },
      drawer: false,
    };
  },
  methods: {
    changeDrawer(val,type,value) {
        //console.log("bindParam",value);
      this.drawer = val;
      this.bindParam.type=type;
      this.bindParam.e_ID=value;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        //search(2):触发上级事件
        this.$emit("binddoctorsearch", query);
        setTimeout(() => {
          this.loading = false;
          this.options = this.listSet.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    submitBindSource(){
         //console.log("bindParam",this.bindParam);
         this.$emit("submitbindsource", this.bindParam);
    }
  },
};
</script>

<style lang="scss" scoped>
.patient-detail-base {
  .clearfix {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 15px;

    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      line-height: 22px;
      margin-right: 20px;
    }

    .btn {
      margin-right: 5px;
    }
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 10%;

    .item {
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 10px;

      .price {
        font-size: 18px;
        color: #5b5b5b;
        line-height: 30px;
      }

      .name {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}

.grade {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;

  .name {
    width: 70px;
    white-space: nowrap;
    margin-right: 5px;
    font-size: 14px;
  }
  .content {
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
</style>
