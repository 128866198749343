<template>
  <div>
    <Container class="see-detail hb-layout-vertical">
     
      <el-button-group class="margin display">
        <el-button type="primary" @click="todayClick()">今天</el-button>
        <el-button type="primary" @click="weekClick()">本周</el-button>
        <el-button type="primary" @click="monthClick()">本月</el-button>
        <el-button type="primary" @click="yearClick()">今年</el-button>
      </el-button-group>
      <div style="height: calc(100vh - 180px); overflow-y: auto;">
        <Income class="body" :statics_data="statics_data" :Param="Param"></Income>
        <Container class="classify">
          <Container class="classify-details">
            <div class="box-card">
              <div class="title">费用分类</div>
              <div class="time">{{Param.todayTime}}</div>
              <CostClassification :Param="Param"></CostClassification>
            </div>
          </Container>
          <Container class="classify-details">
            <div class="box-card">
              <div class="title">支付方式</div>
              <div class="time">{{Param.todayTime}}</div>
              <PayMethod :Param="Param"></PayMethod>
            </div>
          </Container>
        </Container>
        <!-- <Container class="classify">
          <Container class="classify-details">
            <div class="box-card">
              <div class="title">客户来源</div>
              <div class="time">2021-08-18</div>
              <CustomerSource></CustomerSource>
            </div>
          </Container>
          <Container class="classify-details">
            <div class="box-card">
              <div class="title">客户渠道</div>
              <div class="time">2021-08-18</div>
              <CustomerChannel></CustomerChannel>
            </div>
          </Container>
        </Container> -->
      </div>
    </Container>
  </div>
</template>

<script>
import { components, layouts } from "@/helper";

export default {
  components: {
    ...components(
      ["Income", "CostClassification", "PayMethod", "CustomerSource", "CustomerChannel"],
      "financialStatistics.BusinessOverview"
    ),

    ...components(["Container"]),
  },
  props: {
    statics_data: {},
    Param:{}
  },
  data() {
    return {
       tabPosition: "left",
    };
  },
  methods: {
    todayClick() {
      let me = this;
      me.dataTime = "";
      me.$emit("todayclick");
    },
    weekClick() {
      let me = this;
      me.dataTime = "";
      me.$emit("weekclick");
    },
    monthClick() {
      let me = this;
      me.dataTime = "";
      me.$emit("monthclick");
    },
    yearClick() {
      let me = this;
      me.dataTime = "";
      me.$emit("yearclick");
    },
  },
};
</script>

<style lang="scss" scoped>
.see-detail {
  padding: 0 10px;

  .classify {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .classify-details {
      width: 49%;
      margin: 12px 4px;
      padding: 20px 30px 30px;
      box-shadow: 0px 0px 10px 0px #c5c5c5;
      border-radius: 12px;

      .box-card {
        .title {
          font-size: 18px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #333;
          line-height: 33px;
        }

        .time {
          padding-top: 3px;
          font-size: 14px;
          color: #919191;
          font-family: PingFangSC-Medium, PingFang SC;
        }
      }
    }
  }
}
</style>
