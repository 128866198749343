import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";
/**
 * 医生获取患者列表 API
 */
let url = serverConfig.BASE_API + '/v2/payment/method/get.patient.basic.list/';

//TODO 用患者管理的患者列表做测试，上面的接口没有数据
//let url = serverConfig.BASE_API + '/v2/patient/method/get.patient.basic.list/';


export default (data) => {
 
    return axios.post(url, {
            ...data
        }).then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items
        }) => items.map(({
            patient_information,
            appointment_record_no,
            appointment_record_id,
            appointment_type,
            user_id,
            start_time,
            end_time,
            execute_status,
            get_user
        }) => ({
            real_name:patient_information.real_name, //姓名 ：no112233
            sex:patient_information.sex,  //性别：男、女
            age:patient_information.age, //年龄：20
            start_time:start_time,
            end_time:end_time,
            executeStatus:execute_status,
            //header: photo, //头像：
           // header:"https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2606549919,3922038670&fm=26&gp=0.jpg",
            member: appointment_type, //会员卡类别：1、2
            medicalRecordNo:patient_information.medical_record_no,//病历编号
            appointment_record_no:appointment_record_no,
            user_id:user_id,//生日：null
            lvlname:get_user.get_user_level.name,
            lvldiscount:get_user.get_user_level.discount,


        })));
}