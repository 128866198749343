<template>
	<Container class="appointment-detail hb-layout-vertical">
		<div class="screen">
			<div class="search">
				<el-input v-model="search" placeholder="搜索员工" class="input-with-select search">
					<el-button slot="append" icon="el-icon-search"></el-button>
				</el-input>
			</div>
		</div>
		<div class="table-list">
			<el-table ref="list" :data="listRecordsData.filter(filter)" highlight-current-row @current-change="appointmentDetailChange" stripe>
				<el-table-column fixed type="index" width="45"/>
				<el-table-column fixed prop="nickname" label="员工姓名" min-width="180" align="left"/>
				<el-table-column prop="english_name" label="英文姓名" min-width="180" align="left"/>
				<el-table-column prop="getDepartment.name" label="部门" min-width="120" align="left"/>
				<el-table-column prop="getRoles.name" label="角色" min-width="100" align="left"/>
				<el-table-column prop="reducible" label="可预约" min-width="100" align="left">
					<template v-slot="scope">
						<el-switch
							v-model="scope.row.reducible"
							active-color="#13ce66"
							inactive-color="#ff4949"
							:active-value="1"
							:inactive-value="0"
							@change="handleChange(scope.row.doctor_id, scope.row.reducible)"
						>
						</el-switch>
					</template>
				</el-table-column>
				<el-table-column label="显示" min-width="80" align="left">
					<template v-slot="scope">{{ statusMap[scope.row.is_delete] }}</template>
				</el-table-column>
				<el-table-column fixed="right" label="操作" width="80" align="left">
					<template v-slot="scope">
						<el-dropdown trigger="click">
							<span class="el-dropdown-link">
								<span>操作</span>
								<i class="el-icon-arrow-down el-icon--right" aria-hidden="true"/>
							</span>
							<el-dropdown-menu slot="dropdown">
								<el-dropdown-item icon="el-icon-plus" @click.native="handleClick(scope.row)">编辑</el-dropdown-item>
								<el-dropdown-item icon="el-icon-delete" @click.native="handleDelClick(scope.row.doctor_id)">删除</el-dropdown-item>
								<el-dropdown-item icon="el-icon-lock" @click.native="handlePassClick(scope.row)">密码</el-dropdown-item>
							</el-dropdown-menu>
						</el-dropdown>
					</template>
				</el-table-column>
			</el-table>
		</div>
	</Container>
</template>

<script>
import {components, layouts} from "@/helper";

export default {
	components: {
		...components(["List", "Container", "appointment.list.patient.Item"]),
	},
	props: {
		listRecordsData: {
			type: Array,
			default: () => [],
		},
		doctors: {},
	},
	
	data() {
		return {
			departmentValue: "",
			search: "",
			type: null,
			statusMap: ['显示', '不显示'],
		};
	},
	watch: {
		search: function (val, oldVal) {
			this.tableData = this.otableData.filter((item) => ~item.name.indexOf(val));
		},
	},
	methods: {
		handleClick(itemData) {
			this.$emit("handleselectitem", itemData);
		},
		handlePassClick(itemData) {
			this.$emit("handlepassselectitem", itemData);
		},
		handleDelClick(itemData) {
			let data = {
				doctor_id: itemData,
			};
			this.$emit("handledelselectitem", data);
		},
		filter(data) {
			let result = false,
				{search, type} = this;
			
			result = search ? data.name.toLowerCase().includes(search.toLowerCase()) : true;
			
			return type !== null ? Boolean(data.type === type) : result;
		},
		
		appointmentDetailChange(val) {
			this.$emit("appointmentdetailchange", val);
		},
		filterTag(value, row) {
			return row.type === value;
		},
		handleChange(No, val) {
			let data = {
				doctor_id: No,
				reducible: val,
				change: "doctor",
			};
			this.$emit("handablechange", data);
		},
	},
};
</script>

<style lang="scss" scoped>
.appointment-detail {
	width: 99%;
	height: calc(100% - 90px);
	margin: 2px auto 15px;
	box-shadow: 0 2px 7px 0 #d8d8d8;
	padding: 15px 15px 35px;
	
	.table-list {
		.el-table{
			height: calc(100vh - 290px);
			width: 100%;
		}
	}
	.screen {
		margin-left: 10px;
		margin-bottom: 10px;
		display: flex;
		align-items: center;
	}
	
	.el-row {
		border-top: 1px solid #d8d8d8;
		
		&:last-child {
			margin-bottom: 0;
			border-bottom: 1px solid #d8d8d8;
		}
	}
	
	.grid-content {
		height: 60px;
		line-height: 60px;
		text-align: center;
	}
}

.el-button-group {
	display: flex;
	vertical-align: middle;
	flex-wrap: nowrap;
}
</style>
