module.exports = [{
    title:'工作台',
    page:'Workbench'
},{
    title:'执行站',
    page:'Implement'
},{
    title:'预约管理',
    page:'Appointment'
},{
    title:'预约日历',
    page:'AppointmentCalendar'
},{
    title:'患者管理',
    page:'Patient'
},{
    title:'看诊管理',
    page:'See'
},{
    title:'库房管理',
    page:'Storage'
},{
    title:'药房管理',
    page:'Pharmacy'
},{
    title:'收费管理',
    page:'Charge'
},
// {
//     title:'商品管理',
//     page:'Shop'
// },
{
    title:'统计管理',
    page:'FinancialStatistics'
},{
    title:'设置',
    page:'Configuration'
}] ;