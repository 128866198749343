<template>
	<Container class="see-main-operation hb-layout-horizontal">
		<template>
			<div class="user-info" v-if="medical_basic">

				<div class="follow-up-visit" v-if="type !== ''"><el-tag type="success">{{typeMap[type].text}}</el-tag></div>
				<div class="card-number">{{medical_basic.medical_record_no}}</div>
				<div class="name">{{medical_basic.real_name}}</div>
				<div class="info">
					<span class="info-sex">{{sexMap[medical_basic.sex]}}</span>
					<span>/</span>
					<span>{{medical_basic.age}}岁</span>
				</div>
			</div>

			<div class="btn">
				<div class="btn-between">
					<el-button type="success" @click="onStartTreatmentButtonClick">开始接诊</el-button>
					<el-button type="danger" @click="onEndTreatmentButtonClick">结束诊断</el-button>
				</div>
				<div class="btn-between">
					<el-dropdown split-button type="primary" @click="handleClick" class="select-but">
					  转诊
					  <el-dropdown-menu slot="dropdown">
					    <el-dropdown-item>转医生</el-dropdown-item>
					    <el-dropdown-item>转院</el-dropdown-item>
					  </el-dropdown-menu>
					</el-dropdown>
					<el-button type="success" @click="onSaveButtonClick">保存</el-button>
				</div>
			</div>
		</template>
	</Container>
</template>

<script>
	import Container from '../../Container';

	export default {

		props: {
			patient: Object,
      type: {
			  type: Number | String
      },
      medical_basic:{
			  type: Object,
        default: ()=>{}
      }
		},

		components: {
			Container
		},
    data(){
		return {
		    sexMap: ['保密', '男', '女'],
            typeMap: [
                {
                    type: '',
                    text: '首诊'
                },
                {
                    type: '',
                    text: '初诊'
                },
                {
                    type: '',
                    text: '复诊'
                },
            ]
      }
    },

		methods: {

			onSaveButtonClick() {

				this.$emit('save');
			},

			onReferralButtonClick() {

				this.$emit('referral');
			},

			onStartTreatmentButtonClick() {

				this.$emit('starttreatment');
			},

			onEndTreatmentButtonClick() {

				this.$emit('endtreatment');
			},
			handleClick() {
			        alert('button click');
			      }
		}
	};
</script>

<style lang="scss" scoped>
	.see-main-operation {
		align-items: center;
		padding: 0 19px;
		height: 60px;
		line-height: 60px;
		margin: 0 4px;
		box-shadow: 0 2px 7px 0 #d8d8d8;
		margin: 5px 5px 10px;
		.see-main-operation:active{
			background-color: #ff0000;
		}

		>.user-info {
			display: flex;
			align-items: center;
      font-size: 16px;
			>.card-number {
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #5B5B5B;
				line-height: 25px;
				white-space: nowrap;
				margin-left: 20px;
			}

			>.name {
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #5B5B5B;
				line-height: 25px;
				padding: 0 20px;
				white-space: nowrap;
			}

			>.info {
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #5B5B5B;
				line-height: 25px;
				white-space: nowrap;

				>.info-sex {
					padding-right: 5px;
				}
			}
		}


		>.btn {
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-left: 25px;
			width: 72%;
			white-space: nowrap;

			>.btn-between{
        margin-left: 10px;
				> .select-but{
					margin-right: 10px;
				}
			}


		}
	}
</style>
