<template>
    <Container class="patient-detail-doctor">
        <div class="details">
            <el-card>
                <div>
                    <el-table
                        :data="addList"
                        stripe
                        height="calc(100vh - 130px)"
                        style="width: 100%">

                        <el-table-column fixed prop="name" label="药品名称" min-width="180" align="center"></el-table-column>

                        <el-table-column prop="manufactor" label="厂家" min-width="220" align="center"></el-table-column>

                        <el-table-column prop="purchasePrice" label="进价" min-width="130" align="center">
                            <template slot-scope="scope">
                                <div>{{scope.row.purchasePrice}}元</div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="amountSold" label="售价" min-width="130" align="center">
                            <template slot-scope="scope">
                                <div>{{scope.row.amountSold}}元</div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="batchNumber" label="生产批号" min-width="150" align="center"></el-table-column>

                        <el-table-column prop="manufactureDate" label="生产日期" min-width="150" align="center"></el-table-column>

                        <el-table-column prop="validityDate" label="有效期" width="150" align="center"></el-table-column>

                        <el-table-column prop="number" label="数量" width="100" align="center"></el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
    </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
 
  components: components([
    'Container'
  ]),

    data(){
        return{
            addList: [
                {
                    name: "乳酸菌素分散片",     // 名称
                    manufactor: "杭州苏泊尔南洋药业有限公司",                // 厂家
                    purchasePrice: "15.00",            // 进价
                    amountSold: "20.00",               // 售价
                    batchNumber: "202111",                   // 生产批号
                    manufactureDate: "2021-04-31",            // 生产日期 
                    validityDate: "2年",                   // 有效期
                    number: "68",            // 数量 
                }
            ],

            
        }
    },
    methods: {
       
  },
}
</script>

<style lang="scss" scoped>
.btn{
    display: flex;
    justify-content: flex-end;

    .edit-buttom {
        margin-top: 20px;
    }
}
</style>
