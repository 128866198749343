/**
 * 医生嘱咐模版 API
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/doctor_enjoin_template/method/get.doctors.advice.template.list';

export default (data) => {

    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data)
        .then(({data}) => data)
        .then(({items}) => items.map((item) =>({ ...item })));
}

