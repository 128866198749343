import 'zbee-sdk/package/hb-admin-system-template/index.css' ;
import 'hb_calendar/zbee/index.css' ;

import Vue from 'vue' ;

// import store from './store/index'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import App from './App.vue' ;

import router from './router' ;

import serverConfig from "@/../config/index";

import echarts from 'echarts';

import moment from 'moment'

import '@/utils/axios' ;

import pluginPrint from './utils/print'

if(!sessionStorage.getItem('HB-LOGIN-USER-INFO')){

    
    location.href = 'login.html' ;

}else{

  Vue.prototype.$moment = moment ;

  Vue.config.productionTip = false ;

  Vue.prototype.$serverConfig = serverConfig;

  Vue.prototype.$echarts = echarts;

  Vue.use(pluginPrint);


  Vue.use(ElementUI);

  new Vue({
    router,
    // store,
    render: h => h(App)
  }).$mount('#app') ;

};

