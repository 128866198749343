<template>
  <Container class="see-patient-history-list hb-layout-vertical">
    <div style="margin: 10px auto;">
      <el-date-picker
          @change="onDatePickerChange"
          value-format="yyyy-MM"
          v-model="selectMonth"
          type="month"
          placeholder="选择月">
      </el-date-picker>
    </div>

    <Container class="hb-layout-flex-1">
      <div class="hb-layout-fit-sized">
          <el-tabs class="hb-layout-fit-unsized" v-model="activeName" type="card" @tab-click="handleClick" :stretch="true">
            <el-tab-pane label="历史诊断" name="first">
              <div v-if="diagnosis_record_history.length > 0">
                <List class="body" :scroll="true" v-if="diagnosis_record_history" :data="diagnosis_record_history" @itemselect="onItemSelect"
                      itemCls=".see-patient-history-list-item">
                  <template v-slot:item="{
                          record,
                          selected
                      }">
                    <SeeListPatientHistoryItem
                        :create_time="record.create_time"
                        :chief_complaint="record.chief_complaint"
                        :inspection_record="record.inspection_record"
                        :type="record.type"
                        :impressions="record.impressions"
                    ></SeeListPatientHistoryItem>
                  </template>
                </List>
              </div>
              <div class="text item return-visit" v-else>
                <el-empty description="暂无数据" :image-size="100"></el-empty>
              </div>
            </el-tab-pane>

            <el-tab-pane label="历史用药" name="second">
              <div v-if="prescription_history.length > 0">
                <List class="body" :scroll="true" v-if="prescription_history" :data="prescription_history"
                      itemCls=".see-patient-history-list-item">
                  <template v-slot:item="{
                          record
                      }">
                    <SeeListPatientHistoryMedication
                        :create_time="record.create_time"
                        :dosage="record.dosage"
                        :remark="record.remark"
                        :pharmacy_name="record.pharmacy_name"
                    ></SeeListPatientHistoryMedication>
                  </template>
                </List>
              </div>
              <div class="text item return-visit" v-else>
                <el-empty description="暂无数据" :image-size="100"></el-empty>
              </div>
            </el-tab-pane>
          </el-tabs>
      </div>
    </Container>

  </Container>
</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components:{
    ...components([
      'List',
      'Container',
      'see.list.patient.history.Medication',
      'see.list.patient.history.Item'
    ])
  },
  // components: components([
  //   'List',
  //   'Container',
  //   {
  //     name: 'HistoryItem',
  //     code: 'see.list.patient.history.Item',
  //   },
  //   {
  //     name: 'HistoryMedication',
  //     code: 'see.list.patient.history.Item',
  //   }
  // ]),
  props: {
    data: Array,
    diagnosis_record_history: {  //看诊历史记录
      type: Array,
      default: () => []
    },
    prescription_history: {    //用药记录
      type: Array,
      default: () => []
    }
  },

  watch:{
    diagnosis_record_history(diagnosis_record_history){

      let me = this ;

      if(diagnosis_record_history.length){

       // me.$refs.list.select(diagnosis_record_history[0]) ;
        
      }
    },
    prescription_history(prescription_history){

      let me = this ;

      if(prescription_history.length){

        //me.$refs.list.select(prescription_history[0]) ;
        
      }
    }
  },

  data() {

    return {
      selected: null,
      selectMonth: '',
      activeName: 'first'
    };

  },

  methods: {

    onDatePickerChange(date) {

      this.$emit('datechange', date);
    },

    onItemSelect(historyItem) {

      this.$emit('historyitemselect', historyItem);
    },

    handleClick(tab, event) {
      //console.log(tab, event);
    }
  }


}
</script>

<style lang="scss" scoped>

$see-patient-list-padding: 18px 10px 0 10px;

.see-patient-history-list {
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 0px 2px;

}
.see-patient-history-list-item{
  font-size: 12px;
}

.hb-container, .css-container {
    overflow: auto;
}
</style>
