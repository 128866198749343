<template>
  <Container>
    <Container class="appoientment-main-operation hb-layout-horizontal">
      <div v-for="(items, index) in departmentDatas" :key="index">
        <el-button
          v-if="(index / 2) % 1 == 0"
          type="primary"
          plain
          @click="onRolesChange(items.department_id)"
          >{{ items.name }}</el-button
        >
        <el-button
          v-else
          type="success"
          plain
          @click="onRolesChange(items.department_id)"
          >{{ items.name }}</el-button
        >
      </div>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  props: {
    departmentDatas:{},
    rolesDatas: {},
  },

  watch:{

    departmentDatas(data){

      let me = this ;

      if(data.length){

        me.$nextTick(() => me.onRolesChange(data[0].department_id)) ;
      }

    }

  },

  data() {
    return {};
  },
  methods: {
    onRolesChange(val) {
      //console.log("单元格选中val", val);
      this.$emit("roleschange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  padding: 0 19px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 15px 10px 0px;
}
.el-button--primary.is-plain,
.el-button--success.is-plain {
  width: 100px;
}
.el-button {
  margin-right: 10px;
}
</style>
