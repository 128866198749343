const serverConfig = {
    //BASE_API: 'https://rufu.admin.hobooa.com/api',  //网络
     // BASE_API: 'http://rufu-admin:8099/api',  //本地  本机
    //BASE_API: 'http://local.manage.rufu.com/api',  //本地 docker
    //   BASE_LOCAL_API: 'https://api.demo.hobooa.com/mock/91/api', // mock 数据
    //   APP_KEY: '58763752',
    //   APP_SECRET: '8c891b7ed8136207a9558b882be855fb'
    BASE_API: 'https://market.hb.hobooa.com/api',
    APP_KEY: '75736212',
    APP_SECRET: '807664789bf800fb4aba9b3719e8e4cf'
}


export default serverConfig;