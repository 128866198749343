<template>
    <Container class="patient-detail-doctor">
        <div class="details">
            <el-card style="height:calc(100vh - 90px)">
                <Container class="see-patient-history-list-item hb-layout-vertical">
                    <Container class="body">
                         <Container class="inner hb-layout-horizontal">
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">医院名称</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
                                        <el-input v-model="name" placeholder="请输入医院名称"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">医院地址</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
                                        <el-input v-model="name" placeholder="请输入医院地址"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">联系人</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
                                        <el-input v-model="manufactor" placeholder="请输入联系人"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">联系电话</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
                                        <el-input v-model="brand" placeholder="请输入联系电话"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">转院人数</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
                                        <el-input v-model="placeOfOrigin" placeholder="请输入转院人数"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                        </Container>
                        <div class="btn">
                            <el-button type="info" class="edit-buttom">取消</el-button>
                            <el-button type="success" class="edit-buttom">添加</el-button>
                        </div>
                        
                    </Container>
                </Container>
            </el-card>
        </div>
    </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
 
  components: components([
    'Container'
  ]),

    data(){
        return{
           name: '',            // 物资名称
            type: [
                {
                    value: '1',
                    label: '医药材料',
                },{
                    value: '2',
                    label: '后勤材料',
                },{
                   value: '3',
                    label: '固定资产',
                }
            ],
            typeValue: [],       // 物资类型
            manufactor: '',      // 厂家
            brand: '',           // 品牌
            placeOfOrigin: '',   // 产地
            barCode: '',         // 条形码
            weight: '',          // 重量
            company: '',         // 单位
            purchasePrice: '',   // 进价
            amountSold: '',      // 售价
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
.see-patient-history-list-item {
    width: 100%;
    border: 1px solid #e1e1e1;

    >.header {
        padding: 0 10px;
        height: 50px;
        line-height: 50px;
        background-color: #ededed;
        border-bottom: 1px solid #e1e1e1;

        >.time {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5b5b5b;
        }

        >.button {
            height: 30px;
            margin-top: 12px;
        }
    }

    >.body {
        padding: 10px;

        .inners {
            display: flex;
            flex-direction: column;

            .row-select {
                margin-bottom: 7px;
            }
        }

        >.inner {
            display: flex;
            flex-direction: column;
            border: 1px solid #979797;

            .el-row {
                width: 100%;
                height: auto;
                line-height: 38px;
                border-bottom: 1px solid #979797;
                font-size: 12px;

                &:last-child {
                    border-bottom: none;
                }
            }

            .bg-purple {
                text-align: center;
                background: #EBEBEB;
                border-right: 1px solid #979797;
            }


            .bg-purple-light-select {
                padding: 0;
                font-size: 12px;
            }
        }

        .title {
            line-height: 34px;
            margin-top: 10px;
            font-size: 16px;
        }

    }

    .btn{
        display: flex;
        justify-content: flex-end;
        // position: fixed;
        // bottom: 30px;
        // right: 20px;

        .edit-buttom {
           margin-top: 30px;
        }
    }
    
}
</style>
