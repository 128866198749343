<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container class="patient-detail-doctor">
      <Container class="header hb-layout-horizontal">
        <el-row style="width: 100%; margin-bottom: 5px">
          <el-col :span="4">
            <div class="hb-layout-flex-1">创建人: {{ warehousParam.create_name }}</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 title-top">
              <span style="color: #ff0000; font-weight: bold">*</span> 出库类型:
            </div>
          </el-col>
          <el-col :span="4">
            <div class="hb-layout-flex-1">
              <el-select
                v-model="warehousParam.type"
                filterable
                style="width: 100%"
                placeholder="出库类型"
              >
                <el-option
                  v-for="item in attributeDatas"
                  :key="item.stock_attribute_id"
                  :label="item.name"
                  :value="item.stock_attribute_id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="1">
            <div class="hb-layout-flex-1 title-top">仓库:</div>
          </el-col>
          <el-col :span="4">
            <div class="hb-layout-flex-1">
              <el-select
                v-model="warehousParam.stock_warehouse_id"
                filterable
                style="width: 100%"
                placeholder="仓库"
              >
               <el-option
									v-for="item in warehousDatas"
									:key="item.stock_warehouse_id"
									:label="item.name"
									:value="item.stock_warehouse_id"
								>
								</el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="1">
            <div class="hb-layout-flex-1 title-top">备注:</div>
          </el-col>
          <el-col :span="8">
            <div class="hb-layout-flex-1">
              <el-input
                v-model="warehousParam.remarks"
                placeholder="请输入备注"
              ></el-input>
            </div>
          </el-col>
        </el-row>
      </Container>
      <Container class="see-detail-test-inspect">
        <el-row style="width: 100%; height: 50px; line-height: 35px; margin-bottom: 5px">
          <el-col :span="3">
            <div class="hb-layout-flex-1 cloumn-head">药品名称</div>
          </el-col>
          <el-col :span="1">
            <div class="hb-layout-flex-1 cloumn-head">售价</div>
          </el-col>
          <el-col :span="3">
            <div class="hb-layout-flex-1 cloumn-head">
              <span style="color: #ff0000; font-weight: bold">*</span> 生产批号
            </div>
          </el-col>
          <el-col :span="3">
            <div class="hb-layout-flex-1 cloumn-head">
              <span style="color: #ff0000; font-weight: bold">*</span> 生产日期
            </div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">
              <span style="color: #ff0000; font-weight: bold">*</span> 有效期
            </div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">
              <span style="color: #ff0000; font-weight: bold">*</span> 单位数量
            </div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">单位</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">
              <span style="color: #ff0000; font-weight: bold">*</span> 规格数量
            </div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">规格单位</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">进价</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head"></div>
          </el-col>
        </el-row>
      </Container>
      <Container class="see-detail-test-inspect">
        <template
          v-for="(
            { batch_number, validity_date, unit_num, small_unit_num, price }, index
          ) in rows"
        >
          <el-row style="width: 100%; margin-bottom: 5px" :key="index">
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                {{ rows[index].pharmacy_name }}
              </div>
            </el-col>
            <el-col :span="1">
              <div class="hb-layout-flex-1 cloumn-head">
                {{ rows[index].retail_price }}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="batch_number"
                  @input="(value) => onBatchNumberInput(index, value)"
                  placeholder="生产批号"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-date-picker
                  v-model="rows[index].product_date"
                  value-format="yyyy-MM-dd"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="validity_date"
                  @input="(value) => onValidityDateInput(index, value)"
                  placeholder="有效期"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="unit_num"
                  @input="(value) => onUnitNumInput(index, value)"
                  placeholder="单位数量"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-select
                  v-model="rows[index].unit_id"
                  filterable
                  style="width: 100%"
                  placeholder="单位"
                >
                  <el-option
                    v-for="item in unitData"
                    :key="item.unit_id"
                    :label="item.name"
                    :value="item.unit_id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="small_unit_num"
                  @input="(value) => onSmallUnitNumInput(index, value)"
                  placeholder="规格数量"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-select
                  v-model="rows[index].small_unit_id"
                  filterable
                  style="width: 100%"
                  placeholder="规格单位"
                >
                  <el-option
                    v-for="item in smallunitData"
                    :key="item.unit_id"
                    :label="item.name"
                    :value="item.unit_id"
                  >
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="price"
                  @input="(value) => onPriceInput(index, value)"
                  placeholder="进价"
                ></el-input>
              </div>
            </el-col>
            <el-col
              @click.native="onDeleteButtonClick(index)"
              :span="2"
              style="height: 57px; padding: 2px 0 0 12px"
            >
              <div>
                <el-button type="primary" style="margin-top: 7px" icon="el-icon-delete"
                  >删除</el-button
                >
              </div>
            </el-col>
          </el-row>
        </template>
      </Container>
      <Container class="hb-layout-horizontal">
        <el-row style="width: 86%; margin-bottom: 5px">
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-select v-model="pharmacycategoryValue" filterable placeholder="请选择">
                <el-option
                  v-for="item in pharmacyCategoryRecords.items"
                  :key="item.pharmacy_category_id"
                  :label="item.name"
                  :value="item.pharmacy_category_id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-select v-model="value" filterable placeholder="请选择">
                <el-option
                  v-for="(item, index) in pharmacyList"
                  :key="item.pharmacy_id"
                  :label="item.name"
                  :value="item.pharmacy_id"
                >
                  <div @click="add(index)">
                    <div style="float: left">{{ item.name }}</div>
                    <div
                      style="
                        float: right;
                        color: #8492a6;
                        font-size: 13px;
                        margin-left: 20px;
                      "
                    >
                      {{ item.english_name }}
                    </div>
                  </div>
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-input
                v-model="warehousParam.product_num"
                placeholder="出库种类数量"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-input
                v-model="warehousParam.total_num"
                placeholder="出库总数量"
                :disabled="true"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cloumn-footer">
              <el-button type="info" class="edit-buttom">取消</el-button>
              <el-button type="success" class="edit-buttom" @click="saveDelivery"
                >确定</el-button
              >
            </div>
          </el-col>
        </el-row>
      </Container>
    </Container>
  </el-drawer>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    attributeDatas: {},
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    warehousParam: {},
    warhousingData: {
      type: Array,
      default: () => [],
    },
    pharmacyCategoryRecords: {},
    pharmacyrecordsstockin: {},
    unitList: {},
    warehousDatas:{}
  },
  watch: {
    warhousingData: function (val) {
      //初始化数据
      if (val.length > 0) {
        this.rows = val.map((item) => {
          return {
            pharmacy_name: item.pharmacy_name,
            // examination_template_id: item.examination_template_id,
            // remark: item.remark,
            // image: item.remark,
            // edit: false,
          };
        });
      }
    },
    //change药品分类->get药品类别
    pharmacycategoryValue: function (val) {
      this.$emit("getpharmcyrecord", val);
    },
    //监控gpharmacyrecords是否记录变化
    pharmacyrecordsstockin: function (val) {
      this.pharmacyList = val;

      //åconsole.log(this.pharmacyList);
    },
    rows: function (val) {
      let num = 0;
      for (var i = 0; i < val.length; i++) {
        num = this.NumberAdd(num, val[i].small_unit_num);
      }
      this.total_num = num;
    },
    deep: true,
    unitList: function (val) {
      //console.log("unitlist", val);
      let me = this;
      if (val.length > 0) {
        val.map((item) => {
          switch (item.type) {
            case 1:
              me.unitData.push(item);
              break;
            case 2:
              me.smallunitData.push(item);
              break;
          }
        });
      }
      //console.log("unitData", me.unitData);
    },
  },
  data() {
    return {
      drawer: false,
      category: "",
      stockCategory: [
        {
          value: 1,
          label: "销售",
        },
        {
          value: 2,
          label: "调货",
        },
      ],
      value: "",
      linkman: "",
      sotckList: [
        {
          value: 1,
          label: "默认仓库",
        },
      ],
      unitData: [],
      smallunitData: [],
      rows: [],
      pharmacycategoryValue: "",
      pharmacyList: [],
      pharmacyListItem: [],
    };
  },
  methods: {
    add(val) {
      if (this.rows == undefined) {
        this.rows = new Array();
      }
      this.pharyItem(val);

      let obj = {};
      obj.pharmacy_id = this.pharmacyListItem.pharmacy_id;
      obj.pharmacy_name = this.pharmacyListItem.name;
      obj.retail_price = this.pharmacyListItem.retail_price;
      obj.unit_id = this.pharmacyListItem.unit_id;
      obj.small_unit_id = this.pharmacyListItem.small_unit_id;
      obj.batch_number = "";
      obj.validity_date = "";
      obj.unit_num = "";
      obj.stype = 2;
      obj.small_unit_num = "";
      obj.price = this.pharmacyListItem.trade_price;
      this.rows.push(obj);
    },
    pharyItem(val) {
      this.pharmacyList.findIndex((value, index, arr) => {
        if (index === val) {
          this.pharmacyListItem = value;
        }
        //return value.time == val;
      });
    },
    saveDelivery() {
      let me = this;

      if (me.warehousParam.type == "" || me.warehousParam.type == null) {
        this.$alert("请选择入库类型");

        return;
      }

      for (var i = 0; i < me.rows.length; i++) {
        if (me.rows[i].batch_number == "" || me.rows[i].batch_number == null) {
          me.$alert("请选择生产批号");

          return;
        }
        if (me.rows[i].product_date == "" || me.rows[i].product_date == null) {
          me.$alert("请输入生产日期");

          return;
        }

        if (me.rows[i].validity_date == "" || me.rows[i].validity_date == null) {
          me.$alert("请输入有效期");

          return;
        }
        if (me.rows[i].unit_num == "" || me.rows[i].unit_num == null) {
          me.$alert("请输入单位数量");

          return;
        }
        if (me.rows[i].small_unit_num == "" || me.rows[i].small_unit_num == null) {
          me.$alert("请输入规格数量");

          return;
        }
      }

      me.warehousParam.balance = "reduce"; //减库存判断
      me.$emit("savedelivery", me.warehousParam, me.rows);
    },
    changeDrawer(v) {
      this.drawer = v;
      this.warehousParam.type = "";
    },
    /**
     * 生产批号填写的方法
     * @param index
     * @param remark
     */
    onBatchNumberInput(index, batch_number) {
      this.rows[index].batch_number = batch_number;
    },
    /**
     * 有效期填写的方法
     * @param index
     * @param remark
     */
    onValidityDateInput(index, validity_date) {
      this.rows[index].validity_date = validity_date;
    },
    /**
     * 数量填写的方法
     * @param index
     * @param remark
     */
    onUnitNumInput(index, unit_num) {
      this.rows[index].unit_num = unit_num;
    },
    /**
     * 规格数量填写的方法
     * @param index
     * @param remark
     */
    onSmallUnitNumInput(index, small_unit_num) {
      let me = this;
      let num = 0;
      me.rows[index].small_unit_num = small_unit_num;
      for (var i = 0; i < me.rows.length; i++) {
        num = me.NumberAdd(num, me.rows[i].small_unit_num);
      }
      me.warehousParam.total_num = num;
      me.warehousParam.product_num = me.rows.length;
    },
    /**
     * 进价填写的方法
     * @param index
     * @param remark
     */
    onPriceInput(index, price) {
      this.rows[index].price = price;
    },

    /**
     * 删除的方法
     * @param index
     */
    onDeleteButtonClick(index, item) {
      //this.$emit('delexamination',this.rows[index].examinations_id);
      this.rows.splice(index, 1);
    },
    NumberAdd(arg1, arg2) {
      let r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m + arg2 * m) / m).toFixed(n);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: flex-end;

  .edit-buttom {
    margin-top: 20px;
  }
}

.header {
  width: 100%;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  background-color: #ededed;
  border-bottom: 1px solid #e1e1e1;

  > .time {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;
  }

  > .button {
    height: 30px;
    margin-top: 12px;
  }
}

.title-top {
  text-align: center;
}

.cloumn-head {
  padding: 8px 0 8px 12px;
  line-height: 40px;
}

.cloumn-footer {
  padding: 8px 0 8px 12px;
}

$see-detail-test-inspect-item-title-width: 132px;

$see-detail-test-inspect-item-height: 45px;

.see-detail-test-inspect {
  .el-row {
    width: 100%;
    border-bottom: 1px solid #979797;

    &:last-child {
      margin-bottom: 0;
      // border-bottom: 1px solid #979797;
    }
  }

  .basic-inspect {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .bg-purple {
    background: #e5e9f2;
  }

  .bg-purple-light {
    background: #ffffff;
    // border-left: 1px solid #979797;
    // border-right: 1px solid #979797;
  }

  .purple-light {
    // border-left: 1px solid #979797;
  }

  .grid-content {
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .grid-left {
    text-align: left;
    // padding-left: 10px;
  }

  .grid-right {
    text-align: right;
    padding-right: 20px;
    color: #ff0000;
    // text-decoration: underline;
    position: absolute;
    right: 0;
    top: 0;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}
</style>
