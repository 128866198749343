<template>
  <ImplementLayout>
    <template v-slot:implement-left>
      <LeftMain
        ref="medicalRecordList"
        :loading="loading.status"
        :medicalRecordList="medicalRecordList"
        @medicalRecordSelect="onItemSelect"
        @todayevent="onTodayEvent"
        @sevenevent="onSevenEvent"
        @thirtyevent="onThirtyEvent"
        @changestatus="onChangeStatus"
        @leftsearch="onLeftSearch"
      ></LeftMain>
    </template>
    <template v-slot:implement-detail>
      <CenterMain
        ref="centermain"
        :medicalRecord="medicalRecord"
        :diagnosisItemData="diagnosisItem"
        :inspectionItems="examination"
        :treatmentItems="treatment"
        :pharmacyItems="chinamedicine"
        :westernmedicineItems="westernmedicine"
        @executechange="onExecuteChange"
        :bindSearchDatas="bindsearchList"
        @binddoctorsearch="onBindDoctorSearch"
        @submitbindsource="onSubmitBindSource"
      ></CenterMain>
    </template>

    <template v-slot:implement-right>
      <RightMain :visitPathDatas="visitPathList.items"></RightMain>
    </template>
  </ImplementLayout>
</template>

<script>
import Page from "@/components/Page";

import { mock, components, layouts } from "@/helper";

export default {
  components: {
    ...layouts(["Implement"]),
    ...components(["Left.Main", "Center.Main", "Right.Main"], "implement"),
  },
  watch: {
    examination: function (val) {
      let me = this;
      let strs = [];
      let excute = 0;
      let total = 0;
      val.map(function (ele, index) {
        if (ele.status == 3) {
          excute += 1;
        }
        total += 1;
      });
      me.examination_total_num = me.total_num + total;
      me.examination_excute_num = me.excute_num + excute;
      me.executeParam.total_num = me.examination_total_num + me.treatment_total_num+me.chinamedicine_total_num+me.westernmedicine_total_num;
      me.executeParam.excute_num = me.examination_excute_num + me.treatment_excute_num+me.chinamedicine_excute_num+me.westernmedicine_excute_num;
      // console.log("examination_total_num", me.executeParam.total_num);
      // console.log("examination_excute_num", me.executeParam.excute_num);
    },
    treatment: function (val) {
      let me = this;
      let strs = [];
      let excute = 0;
      let total = 0;
      val.map(function (ele, index) {
        if (ele.status == 3) {
          excute += 1;
        }
        total += 1;
      });
      me.treatment_total_num = me.total_num + total;
      me.treatment_excute_num = me.excute_num + excute;
     me.executeParam.total_num = me.examination_total_num + me.treatment_total_num+me.chinamedicine_total_num+me.westernmedicine_total_num;
      me.executeParam.excute_num = me.examination_excute_num + me.treatment_excute_num+me.chinamedicine_excute_num+me.westernmedicine_excute_num;
      // console.log("treatment_total_num", me.executeParam.total_num);
    },
    chinamedicine: function (val) {
      let me = this;
      let strs = [];
      let excute = 0;
      let total = 0;
      val.map(function (ele, index) {
        if (ele.status == 3) {
          excute += 1;
        }
        total += 1;
      });
      me.chinamedicine_total_num = me.total_num + total;
      me.chinamedicine_excute_num = me.excute_num + excute;
       me.executeParam.total_num = me.examination_total_num + me.treatment_total_num+me.chinamedicine_total_num+me.westernmedicine_total_num;
      me.executeParam.excute_num = me.examination_excute_num + me.treatment_excute_num+me.chinamedicine_excute_num+me.westernmedicine_excute_num;
      // console.log("chinamedicine_total_num", me.executeParam.total_num);
    },
    westernmedicine: function (val) {
      let me = this;
      let strs = [];
      let excute = 0;
      let total = 0;
      val.map(function (ele, index) {
        if (ele.status == 3) {
          excute += 1;
        }
        total += 1;
      });
      me.westernmedicine_total_num = me.total_num + total;
      me.westernmedicine_excute_num = me.excute_num + excute;
      me.executeParam.total_num = me.examination_total_num + me.treatment_total_num+me.chinamedicine_total_num+me.westernmedicine_total_num;
      me.executeParam.excute_num = me.examination_excute_num + me.treatment_excute_num+me.chinamedicine_excute_num+me.westernmedicine_excute_num;
      // console.log("total_num", me.executeParam.total_num);
      // console.log("excute_num", me.executeParam.excute_num);
    },
  },
  data() {
    return {
      medicalRecordList: [], //病历列表
      loading: {
        status: false, //预约列表loading
      },
      medicalRecord: {}, //触发点击后的病历对象
      leftListParam: {
        //列表参数
        page_no: 1,
        page_size: 10,
        keyword: "",
        appdate: "",
        execute_status: "1,2,3",
      },
      treatment: {}, //治疗项目
      chinamedicine: {}, //治
      westernmedicine: {},
      examination: {},
      centerRecordsParam: {
        //列表参数
        appointment_date: undefined,
        dayEvent: 1, //1
        eventEvent: undefined, //7
        thirtyEvent: undefined, //30
        execute_status: "1,2,3", //默认all(5,6)
      },
      executeParam: {
        type: undefined,
        s_ID: undefined,
        execute_status: 3,
        total_num: 0,
        excute_num: 0,
      },
      appointment_record_no: "",
      medical_record_no: "",
      create_id: "",
      bindsearchList: {},
      visitPathList: {},
      total_num: 0,
      excute_num: 0,
      examination_total_num: 0,
      examination_excute_num: 0,
      treatment_total_num: 0,
      treatment_excute_num: 0,
      chinamedicine_total_num: 0,
      chinamedicine_excute_num: 0,
      westernmedicine_total_num: 0,
      westernmedicine_excute_num: 0,

      diagnosisItem: {},
    };
  },
  async mounted() {
    let that = this;

    await this.initPage();

    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    that.create_id = that.userInfo.client_id;

    // 默认选中操作å
    if (that.medicalRecordList.length) {
      that.$refs.medicalRecordList.select(that.medicalRecordList[0]);
    }
  },
  methods: {
    /** left start */
    /**
     * 初始化页面的方法
     */
    initPage() {
      this.formatDate();
      this.getMedicalRecordList();
    },
    /**
     * 患者列表 筛选状态
     */
    onChangeStatus(e) {
      let me = this;
      let statusVlaue = e;
      if (statusVlaue === "all") {
        me.centerRecordsParam.execute_status = "1,2,3";
      } else {
        me.centerRecordsParam.execute_status = statusVlaue;
      }

      me.getMedicalRecordList();
    },
    onLeftSearch(e) {
      let me = this;
      me.centerRecordsParam.keywords = e;
      me.centerRecordsParam.appointment_date = undefined;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      this.getMedicalRecordList();
    },
    onTodayEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.getMedicalRecordList();
    },
    onSevenEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.centerRecordsParam.eventEvent = 1;
      me.getMedicalRecordList();
    },
    onThirtyEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = 1;
      me.getMedicalRecordList();
    },
    onItemSelect(val) {
      let me = this;

      me.executeParam.total_num = 0;

      me.executeParam.excute_num = 0;

      me.appointment_record_no = val.appointment_record_no;

      me.medical_record_no = val.medicalRecordNo;

      // 根据患者的病历号获取患者的简要信息
      me.getMedicalRecord(val.medicalRecordNo);

      //获取诊疗费配置信息和其它信息
      me.getDiagnosisItem(val.appointment_record_no);

      // 根据患者的病历号获取患者的检查项目
      me.getExaminationRecord(val.appointment_record_no);
      // // 根据患者的病历号获取患者的治疗明细
      me.getTreatmentRecord(val.appointment_record_no);
      // // 根据患者的病历号获取患者的中
      me.getPharmacyChinaRecord(val.appointment_record_no);
      me.getWesternmedicineRecord(val.appointment_record_no);
      //执行记录
      me.getVisitPath(val.appointment_record_no);
    },
    /** left end */
    onExecuteChange(type, val) {
      let me = this;
      me.executeParam.type = type;
      me.executeParam.e_ID = val;
      (me.executeParam.medical_record_no = me.medical_record_no),
        (me.executeParam.appointment_record_no = me.appointment_record_no),
        (me.executeParam.create_id = me.create_id),
        (me.executeParamexecute_status = 3), // 已执行
        me
          .$confirm("确认执行吗？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
          .then(async () => {
            me.setExecuteRecordItem();
          })
          .catch();
    },
    async setExecuteRecordItem() {
      let me = this;
      try {
        let res = await mock("implement/Center/setExecuteBindItem", me.executeParam);
        if (res.status == 200) {
          me.$message({
            type: "success",
            message: "执行成功!",
          });
        } else {
          me.$message({
            type: "warning",
            message: "执行失败!",
          });
        }
        if (me.executeParam.type === "inspection") {
          me.getExaminationRecord(me.appointment_record_no);
        } else if (me.executeParam.type === "treatment") {
          me.getTreatmentRecord(me.appointment_record_no);
        } else if (me.executeParam.type === "pharmacy") {
          me.getPharmacyChinaRecord(me.appointment_record_no);
          me.getWesternmedicineRecord(me.appointment_record_no);
        }
        me.getVisitPath(me.appointment_record_no);
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    async getMedicalRecordList() {
      let me = this;
      try {
        me.medicalRecordList = await mock(
          "implement/Left/getMedicalRecordList",
          me.centerRecordsParam
        );
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 根据患者的病历号获取一个患者病历记录信息
     */
    async getMedicalRecord(val) {
      let me = this;
      try {
        me.medicalRecord = await mock("implement/Center/getMedicalRecordInfoItem", {
          medical_record_no: val,
        });
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 根据患者的病历号获取一个患者检查项目
     */
    async getExaminationRecord(val) {
      let me = this;
      try {
        me.examination = await mock("implement/Center/getExaminationRecord", {
          appointment_record_no: val,
        });
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 根据患者的病历号获取一个患者治疗项目
     */
    async getTreatmentRecord(val) {
      let me = this;
      try {
        me.treatment = await mock("implement/Center/getTreatmentRecord", {
          appointment_record_no: val,
        });
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 根据患者的病历号获取一个患者中药
     */
    async getPharmacyChinaRecord(val) {
      let me = this;
      try {
        me.chinamedicine = await mock("implement/Center/getPharmacyRecord", {
          appointment_record_no: val,
          type: 1,
        });
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 根据患者的病历号获取一个患者中药
     */
    async getWesternmedicineRecord(val) {
      let me = this;
      try {
        me.westernmedicine = await mock("implement/Center/getPharmacyRecord", {
          appointment_record_no: val,
          type: 2,
        });
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 搜索员工-绑定客户来源
     */
    async getBindDoctorSearch(e) {
      let me = this;
      //me.searchList =  me.states;
      //search(6): 获取远程返回的值并赋值
      try {
        me.bindsearchList = await mock("patient/Center/getDoctorSearchRecords", e);
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    //绑定客户来源--根据关键词搜索员工信息-doctor表
    onBindDoctorSearch(query) {
      let me = this;
      me.getBindDoctorSearch(query);
    },
    //绑定客户来源
    onSubmitBindSource(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setBindSourceItem(data);
        })
        .catch();
    },
    //绑定客户来源
    async setBindSourceItem(data) {
      let me = this;
      me.executeParam.type = data.type;
      //重组数据
      let datas = {
        bind_doctor_id: data.doctor_id,
        bind_name: data.doctor_name,
        bind_department_id: data.department_id,
        medical_record_no: me.medical_record_no,
        appointment_record_no: me.appointment_record_no,
        create_id: me.create_id,
        type: data.type, //检查、治疗、药品
        e_ID: data.e_ID, //检查、治疗、药品的ID
        execute_status: 3, // 已执行
        total_num: me.executeParam.total_num, //执行项目总数量
        excute_num: me.executeParam.excute_num, //已执行项目总数量
      };

      //console.log("datas", datas);

      try {
        let res = await mock("implement/Center/setExecuteBindItem", datas);
        if (res.status == 200) {
          me.$message({
            type: "success",
            message: "保存成功!",
          });
          me.$refs.centermain.changeInfoDrawer(false);
          if (me.executeParam.type === "inspection") {
            me.getExaminationRecord(me.appointment_record_no);
          } else if (me.executeParam.type === "treatment") {
            me.getTreatmentRecord(me.appointment_record_no);
          } else if (me.executeParam.type === "pharmacy") {
            me.getPharmacyChinaRecord(me.appointment_record_no);
            me.getWesternmedicineRecord(me.appointment_record_no);
          }
          me.getVisitPath(me.appointment_record_no);
          me.getMedicalRecordList();
        } else if (res.status == 500) {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: "保存失败",
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 搜索员工-绑定客户来源
     */
    async getVisitPath(e) {
      let me = this;
      //me.searchList =  me.states;
      //search(6): 获取远程返回的值并赋值
      let data = {
        appointment_record_no: e,
        status: "9,10,11",
      };
      try {
        let res = await mock("implement/Right/getVisitPathRecords", data);

        if (res.status == 200) {
          me.visitPathList = res.data;
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 根据患者的病历号获取一个患者病历记录信息
     */
    async getDiagnosisItem(val) {
      let me = this;
      let res = await mock("charge/Center/getDiagnosisRecord", {
        appointment_record_no: val,
      });

      if (res !== "") {
        me.diagnosisItem = res.item;
      }

      // console.log("res", me.diagnosisItem);
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.centerRecordsParam.appointment_date = `${year}-${month}-${day}`;
      this.nowDate = `${year}-${month}-${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-left {
  margin: 4px 2px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  height: 60px;
  line-height: 60px;
}

.search {
  width: 295px;
  padding: 0 10px;

  > .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 160px;
    margin-right: 10px;
  }
}
</style>
