<template>
  <Container class="statistics">
    <div class="box-card">
      <div class="card-list-item">
        <div class="item-title">营业收费</div>
        <div class="time">{{Param.todayTime}}</div>
        <div class="item-content" >
          <div v-if="statics_data.total_transaction==undefined" >0.00 元</div>
          <div v-if="statics_data.total_transaction!==undefined">{{ statics_data.total_transaction}} 元</div>
        </div>
      </div>
      <div class="card-list-item">
        <div class="item-title">营业收费人数</div>
        <div class="time">{{Param.todayTime}}</div>
        <div class="item-content">
          <div class="item-number number2">
            {{ statics_data.count_diagnosis }} 人
          </div>
        </div>
      </div>
      <div class="card-list-item">
        <div class="item-title">会员充值</div>
        <div class="time">{{Param.todayTime}}</div>
        <div class="item-content"  v-if="statics_data.total_recharge!==''">
          <div v-if="statics_data.total_recharge==undefined" >0.00 元</div>
          <div v-if="statics_data.total_recharge!==undefined">{{ statics_data.total_recharge}} 元</div>
        </div>
      </div>
      <div class="card-list-item">
        <div class="item-title">充值人数</div>
        <div class="time">{{Param.todayTime}}</div>
        <div class="item-content">
          <div>{{ statics_data.count_recharge }} 人</div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    statics_data: { },
    Param:{}
  },
  data() {
    return {

    };
  },
};
</script>

<style  lang="scss" scoped>
.statistics {
  .box-card {
    margin: 20px 4px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-list-item {
      width: 24%;
      padding: 20px 30px;
      box-shadow: 0px 0px 10px 0px #c5c5c5;
      border-radius: 12px;

      .item-title {
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #333;
        line-height: 33px;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
      .time {
        padding-top: 3px;
        font-size: 14px;
        color: #919191;
        font-family: PingFangSC-Medium, PingFang SC;
      }

      .item-content {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 55px 0;

        div {
          font-size: 22px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #48797e;
          line-height: 33px;
          margin-left: 14px;
        }
      }
    }
  }
}
</style>
