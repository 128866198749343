<template>
  <Container class="see-detail-test-inspect">
    <template v-for="({ edit, remark, examination_template_id }, index) in rows">
      <!-- <el-row v-if="!edit" style="width: 100%; margin-bottom: 20px" :key="index">
        <el-row>
          <el-col :span="24">
            <el-row style="width: 100%; border: none;margin-bottom: 5px;" type="flex">
              <el-col :span="24">
                <el-cascader
                  :options="examination_template"
                  :show-all-levels="false"
                  :props="{ label: 'name', value: 'examination_template_id', emitPath: false }"
                  :disabled="true"
                  @input="(value) => onItemInput(index, value)"
                  v-model="rows[index].examination_template_id"
                  clearable
                ></el-cascader>
              </el-col>
              <el-col
                @click.native="onUpdateButtonClick(index)"
                class="hb-layout-center"
                style="height: 40px;margin:0 10px;width:90px;">
              <el-button type="primary" icon="el-icon-edit">编辑</el-button>
              </el-col>
              <el-col
                @click.native="onDeleteButtonClick(index)"
                class="hb-layout-center"
                style="height: 40px;width:90px;"
              >
                <el-button type="primary" icon="el-icon-delete">删除</el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-input
              disabled
              class="grid-left"
              :value="remark"
              placeholder="填写备注"
            ></el-input>
          </el-col>
        </el-row>
      </el-row> -->
      <el-row v-if="!edit" style="width: 100%; margin-bottom: 10px" :key="index">
        <el-col :span="14">
          <el-cascader
            :options="examination_template"
            :show-all-levels="false"
            :props="{ label: 'full_name', value: 'examination_template_id', emitPath: false }"
            :disabled="true"
            @input="(value) => onItemInput(index, value)"
            v-model="rows[index].examination_template_id"
            clearable
          ></el-cascader>
        </el-col>
        <el-col :span="10">
          <el-row style="width: 100%; border: none" type="flex">
            <el-col :span="24">
              <el-input
                disabled
                class="grid-left"
                :value="remark"
                placeholder="填写备注"
              ></el-input>
            </el-col>
            <el-col
              @click.native="onUpdateButtonClick(index)"
              class="hb-layout-center"
              style="height: 40px; color: #ff0000;margin-right: 10px;width:90px;">
              <el-button type="primary" style="padding: 12px 15px;" icon="el-icon-edit">编辑</el-button>
            </el-col>
            <el-col
              @click.native="onDeleteButtonClick(index)"
              class="hb-layout-center"
              style="height: 40px; color: #ff0000;width:90px;"
            >
              <el-button type="primary" style="padding: 12px 15px;" icon="el-icon-delete">删除</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>
      <el-row v-else style="width: 100%; margin-bottom: 5px" :key="index">
        <el-col :span="14">
          <el-cascader
            :options="examination_template"
            :show-all-levels="false"
            :props="{ label: 'full_name', value: 'examination_template_id', emitPath: false }"
            @input="(value) => onItemInput(index, value)"
            v-model="rows[index].examination_template_id"
            clearable
            filterable
          ></el-cascader>
        </el-col>
        <el-col :span="10">
          <el-row style="width: 100%; border: none" type="flex">
            <el-col :span="24">
              <el-input
                class="grid-left"
                :value="remark"
                @input="(value) => onRemarkInput(index, value)"
                placeholder="填写备注"
              ></el-input>
            </el-col>
            <el-col
              @click.native="onSaveButtonClick(index)"
              class="hb-layout-center"
              style="height: 40px;width:90px;"
            >
              <el-button type="primary" style="padding: 12px 15px;" icon="el-icon-check">保存</el-button>
            </el-col>
          </el-row>
        </el-col>
      </el-row>

      <!-- <el-row v-else style="width: 100%; margin-bottom: 10px" :key="index">
        <el-row>
          <el-col :span="24">
            <el-row style="width: 100%; border: none;margin-bottom: 5px;" type="flex">
              <el-col :span="24">
                <el-cascader
                  :options="examination_template"
                  :show-all-levels="false"
                  :props="{ label: 'name', value: 'examination_template_id', emitPath: false }"
                  @input="(value) => onItemInput(index, value)"
                  v-model="rows[index].examination_template_id"
                  clearable
                ></el-cascader>
              </el-col>
              <el-col
                @click.native="onSaveButtonClick(index)"
                class="hb-layout-center"
                style="height: 40px;width:90px;margin-left: 10px;"
              >
                <el-button type="primary" icon="el-icon-check">保存</el-button>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24">
            <el-input
              class="grid-left"
              :value="remark"
              @input="(value) => onRemarkInput(index, value)"
              placeholder="填写备注"
            ></el-input>
          </el-col>
        </el-row>
      </el-row> -->
      
    </template>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    examination_template: {
      type: Array,
      default: () => [],
    },
    examinations: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      rows: [],
      value: "",
    };
  },
  watch: {
    examinations: function (val) {
      //初始化数据
      if (val.length > 0) {
        this.rows = val.map((item) => {
          return {
            examinations_id: item.examinations_id,
            examination_template_id: item.examination_template_id,
            remark: item.remark,
            image: item.remark,
            edit: false,
          };
        });
      }else{
         this.rows = val.map((item) => {
          return {
            examinations_id: "",
            examination_template_id: "",
            remark: "",
            image: "",
            edit: false,
          };
        });
      }
      
    },
     deep: true,
  },
  methods: {
    add() {
      this.rows.push({
        examinations_id: "",
        examination_template_id: undefined,
        remark: "",
        image: "",
        edit: true,
      });
    },

    /**
     * 选择框的方法
     * @param index
     * @param item
     */
    onItemInput(index, item) {
      this.rows[index].examination_template_id = item;
    },

    /**
     * 备注填写的方法
     * @param index
     * @param remark
     */
    onRemarkInput(index, remark) {
      this.rows[index].remark = remark;
    },

    /**
     * 修改的方法
     * @param index
     */
    onUpdateButtonClick(index) {
      this.rows[index].edit = true;
    },

    /**
     * 保存的方法
     * @param index
     */
    onSaveButtonClick(index) {
      this.rows[index].edit = false;
    },

    /**
     * 删除的方法
     * @param index
     */
    onDeleteButtonClick(index,item) {
      //console.log("onDeleteButtonClick",this.rows[index].examinations_id);
      this.$emit('delexamination',this.rows[index].examinations_id);
      this.rows.splice(index, 1);
    },

    /**
     * 获取检验检查
     * @returns {*}
     */
    getRows() {
      //重组，修改看诊记录保存是所需的结构
      return this.rows.map((item) => {
        return {
          examinations_id: item.examinations_id,
          examination_template_id: item.examination_template_id,
          remark: item.remark,
          image: item.image,
        };
      });
    },
  },
};
</script>

<style lang="scss" scoped>
$see-detail-test-inspect-item-title-width: 132px;

$see-detail-test-inspect-item-height: 45px;

.see-detail-test-inspect {
  .el-row {
    width: 100%;
    border-bottom: none;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .basic-inspect {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .bg-purple {
    background: #e5e9f2;
  }

  .bg-purple-light {
    background: #ffffff;
  }

  .grid-content {
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .grid-left {
    text-align: left;
    padding: 0 10px;
  }

  .grid-right {
    text-align: right;
    padding-right: 20px;
    color: #ff0000;
    position: absolute;
    right: 0;
    top: 0;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}
.el-cascader{
  width: 100%;
}
</style>
