/**
 * 执行提交
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/appointment_record/method/set.execute.status/';

export default (data) => {

    return axios.post(url, qs.stringify({
        "format": "json",
        ...data
    })).then(({data}) => data);
        // .then(({data}) => data);
}
