<template>
    <Container class="patient-detail-info-project">
        <div>
            <el-table
                :data="drugsData"
                style="width: 100%"
                max-height="200">
                <el-table-column
                    prop="name"
                    label="药品名称"
                    width="200"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="type"
                    label="类型"
                    min-width="120"
                    align="center">
                </el-table-column>
                <el-table-column
                    prop="usage"
                    label="用法"
                    min-width="120" 
                    align="center">
                </el-table-column>
                <el-table-column
                    label="状态"
                    min-width="100" 
                    align="center">
                    <template slot-scope="scope">
                        <div class="state" size="small" v-if="scope.row.state == true">待执行</div>
                        <div size="small" v-else>已执行</div>
                    </template>
                </el-table-column>
                <el-table-column
                    fixed="right"
                    label="操作"
                    width="100" 
                    align="center">
                    <template slot-scope="scope">
                        <el-button
                            size="mini"
                            type="primary"
                            @click="handleRow(scope.row)">执行</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
    </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
    components: components([
        'Container'
    ]),
    
    data(){
        return {
            drugsData:[
                {
                    name: '小儿葵花药业',   // 药品名称
                    type: '',   // 类型
                    usage: '一天两次',     // 用法
                    operation: '待执行',   //操作  0、待执行   1、已执行
                    state:true
                }
            ]
        }
    },
    methods:{
        handleRow(row){
            row.state = false;
        }
  }
}
</script>

<style  lang="scss"  scoped>
.state{
    color: #409EFF;
}
</style>


