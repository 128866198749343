<template>
  <div style="height: 95%; overflow: auto">
    <Container class="see-detail hb-layout-vertical">

      <Header
        @submit="handleSubmit"
        ref="header"
        :loading="loading"
        @submitreset="onSubmitReset"
        @todayclick="onTodayClick"
        @weekclick="onWeekClick"
        @monthclick="onMonthClick"
        @yearclick="onYearClick"
      ></Header>
      <List :tableData="Data.items" :loading="loading"></List>

      <BasicFooter
        :loading="loading"
        :size="Param.page_size"
        :total="Data.total_result"
        @change="handlePaginationChange"
      ></BasicFooter>

    </Container>
  </div>
</template>

<script>
import { components, layouts, blocks } from "@/helper";

export default {
  props: {
    Data: {},
    Param: {},
    loading: {
      default: false,
    },
  },
  components: {
    ...components(["Header", "List"], "financialStatistics.Leave"),
    ...blocks(["BasicFooter"], "Footer"),
    ...blocks(["BasicPagination"], "Pagination"),
    ...components(["Container"]),
  },
  data() {
    return {
      table: [],
      toPayment: {},
      page: {
        current: 1,
        size: 0,
        total: 0,
      },
      order: {
        order_type: undefined,
        order_field: undefined,
      },
    };
  },
  methods: {
    // 分页变化改动
    handlePaginationChange(val) {
      this.$emit("changepagination", val);
    },
    onChangeSubmit() {
      this.$nextTick(() => {
        this.$refs.header.handleFormSubmit();
      });
    },

    // 确定查询
    handleSubmit(form, isRestore = false) {
      this.$emit("changesubmit", form, isRestore);
    },
    onSubmitReset(data) {
      let me = this;
      me.$emit("submitreset", data);
    },
    onTodayClick() {
      let me = this;
      me.$emit("todayclick");
    },
    onWeekClick() {
      let me = this;
      me.$emit("weekclick");
   
    },
    onMonthClick() {
      let me = this;
      me.$emit("monthclick");
    },
    onYearClick() {
      let me = this;
      me.$emit("yearclick");
    },
  },
};
</script>

<style lang="scss" scoped></style>
