<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header></Header>
    <ContentUsage
      ref="rightSelectData"
      v-if="tabDatas == 1"
      @submititemdata="onSubmitItemData"
    ></ContentUsage>
    <ContentConsumption
      ref="rightSelectData"
      v-if="tabDatas == 2"
      @submititemdata="onSubmitItemData"
    ></ContentConsumption>
    <ContentTaboo
      ref="rightSelectData"
      v-if="tabDatas == 3"
      @submititemdata="onSubmitItemData"
    ></ContentTaboo>
    <ContentMedicalAdvice
      ref="rightSelectData"
      v-if="tabDatas == 4"
      @submititemdata="onSubmitItemData"
    ></ContentMedicalAdvice>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
     [
        "Header",
        "ContentUsage",
        "ContentConsumption",
        "ContentTaboo",
        "ContentMedicalAdvice",
      ],
      "configuration.Drug.Right"
    ),

    ...components(["Container"]),
  },

  props: {
    treeDatas: {},
    tabData: 0,
  },
  watch: {
    tabData: function (val) {
      this.tabDatas = val;
     // console.log(this.tabDatas);
    },
  },

  data() {
    return {
      tabDatas: 0,
    };
  },
  methods: {
    handleSelectItems(itemdata) {
      this.$refs.rightSelectData.handleSelectItemss(itemdata);
    },
    onSubmitItemData(itemdata) {
      //console.log("12112", itemdata);
      this.$emit("submititemdata", itemdata);
    },
    onAddSubmit() {
      this.$refs.rightSelectData.onAddSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>
// .see-patient-history-list-item {
//   width: 99%;
//   padding: 0 19px;
//   height: calc(100vh - 140px);
//   box-shadow: 0 2px 7px 0 #d8d8d8;
//   margin: 2px 0px 10px 2px;
// }
</style>
