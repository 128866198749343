/**
 * 获取药房列表 API
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/department/method/get.list';
 
 export default (data) => {
 
     return axios.post(url, qs.stringify({
         ...data
     })).then(({data}) => data);
         //.then(({data}) => data);
 }
 
 