<template>
  <Container class="appoientment-main-operation hb-layout-horizontal">
    <div style="width: 100%">
      <Container class="see-patient-history-list-item hb-layout-vertical">
        <Container class="body">
          <Container class="inner hb-layout-horizontal">
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  门店店长</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input v-model="selectData.username" placeholder="请输入门店店长"></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple grid-upload">门店图片</div>
              </el-col>
              <el-col :span="18" style="height: 148px">
                <div class="grid-content bg-purple-light">
                  <el-upload
                    :class="{hide:hideUpload}"
                    action="https://market.hb.hobooa.com/api/v1/upload/method/add.upload.list.html"
                    list-type="picture-card"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :on-change="onChange"
                    :on-success="handleAvatarSuccess"
                    >
                    <img v-if="selectData.image" :src="'https://' + selectData.image" class="store-img">
                    <i v-else class="el-icon-plus"></i>
                  </el-upload>
                  <el-dialog :visible.sync="dialogVisible">
                    <img width="100%" :src="selectData.image" alt="">
                  </el-dialog>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">门店名称</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.name"
                    placeholder="请输入门店名称"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">门店简介</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.description"
                    placeholder="请输入门店简介"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">联系电话</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.mobile"
                    placeholder="请输入联系电话"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">门店地址</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.address"
                    placeholder="请输入门店地址"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">营业状态</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light" style="padding-left: 15px">
                  <el-radio-group v-model="selectData.operating_status">
                    <el-radio :label="0">营业中</el-radio>
                    <el-radio :label="1">未营业</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">营业时间</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.opening_hours"
                    placeholder="请输入营业时间"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple grid-textarea">备注</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="selectData.remarks"
                    placeholder="请输入备注"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
          </Container>
          <div class="btn">
            <el-button type="success" class="edit-buttom" @click="onSubmitItemData"
              >提交</el-button
            >
          </div>
        </Container>
      </Container>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  props: {
    treeDatas: {},
  },
  watch: {
    treeDatas: function (val) {
      if (val.length > 0) {
        this.treeSelectData = this.getTreeData(this.arraytotree(val)); //去除最后children为空
      }
    },
  },
  data() {
    return {
      selectData: [],
      Datas: {
        username: "",
        image: "",
        name: "",
        description: "",
        mobile: "",
        address: "",
        operating_status: "",
        opening_hours: "",
        remarks: "",
      },
      name: "",
      dialogImageUrl: '',
      dialogVisible: false,
      hideUpload: false,
      limitCount:1,
      fileList: []
    };
  },
  methods: {
    handleRemove(file, fileList) {
      this.hideUpload = fileList.length >= this.limitCount;
    },
    handlePictureCardPreview(file) {
      this.image = file.url;
      this.dialogVisible = true;
    },
    onChange(file, fileList){
      this.hideUpload = fileList.length >= this.limitCount;
    },
    handleAvatarSuccess(res, file) {
      this.selectData.image = file.response.data[0].url
    },

    handleSelectItemss(itemData) {
      this.selectData = itemData;
    },

    onSelectData(itemData, treeData) {
      this.selectData = itemData;
      this.parentValue = itemData.parent_id;
    },
    addtopitem() {
      this.selectData = {};
    },
    onSubmitItemData() {
      if (this.selectData.name == "" || this.selectData.name == null) {
        this.$alert("请输入部门名称");

        return;
      }
      let data = {
        store_id: this.selectData.store_id,
        username: this.selectData.username,
        image: this.selectData.image,
        name: this.selectData.name,
        description: this.selectData.description,
        mobile: this.selectData.mobile,
        address: this.selectData.address,
        operating_status: this.selectData.operating_status,
        opening_hours: this.selectData.opening_hours,
        remarks: this.selectData.remarks,
      };

      this.$emit("submititemdata", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 10px 2px;
}

.see-patient-history-list-item {
  width: 100%;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }
      .grid-textarea {
        height: 96px;
        line-height: 96px;
      }
      .grid-upload{
        height: 148px;
        line-height: 148px;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;

    .edit-buttom {
      margin-top: 30px;
    }
  }
}

.hide /deep/.el-upload--picture-card {
  width: 148px;
  height: 148px;
  display: none !important;
}
.store-img{
  width: 148px;
  height: 148px;
}
</style>
