<template>
  <Container class="patient-detail-doctor">
    <el-timeline>
      <el-timeline-item v-for="(visit, index) in Data" :key="index" :type="visit.type" :color="visit.color" :timestamp="visit.name + '回访于:' + visit.timestamp">
        {{visit.content}}
        <div class="tags">
          <el-tag v-for="tag in visit.tags" type="info"  color="#DDEDFD" size="small" key="tag">{{tag}}</el-tag>
        </div>
        <div class="state">{{visit.state}}</div>
      </el-timeline-item>
    </el-timeline>
  </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
  props:{
    Data:{
      type: Array,
      default: () => []
    }
  },
  components: components([
    'Container'
  ]),
}
</script>

<style lang="scss" scoped>
.patient-detail-doctor{
  padding-left: 2px; 
}
.state {
  padding: 0 0 4px;
  font-family: PingFangSC-Medium, PingFang SC;
  color: #5B5B5B;
}
.tags {
  padding: 13px 0 4px;
  display: flex;
  align-items: center;
  overflow: hidden;
  flex-wrap: wrap;
}
.el-tag.el-tag--info {
  margin: 0 9px 9px 0;
  border-color: #DDEDFD;
  color: #669ff5;
}
</style>
