<template>
  <el-dialog
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :width="size"
  >
    <Container>
      <div class="grade">
        <div class="name">绑定来源：</div>
        <div class="content">
         <el-cascader
                    style="width: 100%"
                    v-model="bindParam.bind_source_id"
                    :props="defaultParams"
                    :options="treeSelectData"
                    :show-all-levels="false"
                  ></el-cascader>
        </div>
      </div>
      <div class="grade">
        <div class="name">备注信息：</div>
        <div class="content">
          <el-input
            style="width: 100%"
            v-model="bindParam.Remarks"
            placeholder="输入备注信息"
          ></el-input>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="gradeDialog = false">取 消</el-button>
        <el-button type="primary" @click="submitBindSource">确 定</el-button>
      </div>
    </Container>
  </el-dialog>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    usermoney: {},
    drawer_title: "",
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    gradelevelDatas: {},
    bindSearchDatas: {},
    sourceDatas:{}
  },
  watch: {
    //监听客户渠道数据
   sourceDatas: function (val) {
     //console.log("112val",val);
      if (val.length > 0) {
        this.treeSelectData = this.getTreeData(this.arraytotree(val)); //去除最后children为空
      }
     // console.log("112",this.treeSelectData);
    },
   
  },
  computed: {
    //search(8): 监听status值是否变化，变化就赋值
    listSet() {
      return this.states.map((item) => {
        return {
          value: `${item.name},${item.doctorID},${item.departmentID}`,
          label: `${item.name}-${item.mobile}`,
        };
      });
    },
  },
  data() {
    return {
      gradeDialog: false, // 会员升级的弹窗
      grade: [
        {
          value: "1",
          label: "银卡",
        },
        {
          value: "2",
          label: "金卡",
        },
      ],
      states: [],
      userValue: [],
      options: [],
      loading: false,
      bindParam: {
        doctor_id:0,
        doctor_name:0,
        department_id:0,
        bind_source_id:"",
        Remarks: "",
      },
      parentValue:0,
      drawer: false,
      treeSelectData:[],
      defaultParams: {
        //可以选中一级内容
        //checkStrictly: true,
        //指定触发方式
        //expandTrigger: "hover",

        label: "name",
        value: "customer_source_id",
        children: "children",
      },
    };
  },
  methods: {
    changeDrawer(val) {
      this.drawer = val;
    },
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        //search(2):触发上级事件
        this.$emit("binddoctorsearch", query);
        setTimeout(() => {
          this.loading = false;
          this.options = this.listSet.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    submitBindSource(){
        this.$emit("submitbindsource", this.bindParam);
    },
     //数组转化为树
    arraytotree(arr) {
      var top = [],
        sub = [],
        tempObj = {};

      arr.forEach(function (item) {
        if (item.parent_id === 0) {
          // 顶级分类
          top.push(item);
        } else {
          sub.push(item); // 其他分类
        }
        item.children = []; // 默然添加children属性
        tempObj[item.customer_source_id] = item; // 用当前分类的id做key，存储在tempObj中
      });

      sub.forEach(function (item) {
        // 取父级
        var parent = tempObj[item.parent_id] || { children: [] };
        // 把当前分类加入到父级的children中
        parent.children.push(item);
      });

      return top;
    },
    getTreeData(data) {
      // console.log("12", data.length);
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-detail-base {
  .clearfix {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 15px;

    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      line-height: 22px;
      margin-right: 20px;
    }

    .btn {
      margin-right: 5px;
    }
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 10%;

    .item {
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 10px;

      .price {
        font-size: 18px;
        color: #5b5b5b;
        line-height: 30px;
      }

      .name {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}

.grade {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;

  .name {
    white-space: nowrap;
    margin-right: 5px;
    font-size: 14px;
  }
  .content {
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
</style>
