/**
 * 获取医生列表 API
 */

 import axios from 'axios';
 import qs from 'qs';
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/doctor/method/get.time.list.count';
 //let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment_record/method/get.doctor.list/';
 export default (data) => {
 
     return axios.post(url, qs.stringify({
             ...data
         }))
         .then(({
             data
         }) => data)
         .then(({
             data
         }) => data)
         .then(({
             items
         }) => items.map(({
             day_time,
             info
         }) => ({
             name: day_time,
             count_appointment: info.count_appointment,
             count_register: info.count_register,
             count_wait: info.count_wait,
             count_end: info.count_end
         })));
 }