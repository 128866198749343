<template>
  <AppointmentCalendarLayout>
    <template v-slot:doctor-list>
      <LeftMain
        ref="doctorlist"
        class="hb-layout-fit-sized"
        :loading="loading"
        :data="leftList"
        @leftsearch="onleftSearch"
        @doctoritemselect="onDoctorItemSelect"
        @sevenevent="onSevenEvent"
        @fiftyevent="onFiftyEvent"
        @thirtyevent="onThirtyEvent"
        @calendarevent="onCalendarEvent"
        :calendarSetting="calendarSetting"
        :dayList="dayList"
        @alldoctor="onAllDoctor"
        :allDoctors="allDoctors"
        @leftchangestatus="onLeftChangestatus"
        :screenTabs="screenTabs"
      ></LeftMain>
      <!-- <ListDoctor ref="doctorlist" class="hb-layout-fit-sized" :data="doctors" @changeStatus="changeStatus" @doctoritemselect="onDoctorItemSelect">
        </ListDoctor> -->
    </template>
    <!-- <template v-slot:appointmentCalendar-operation-main>
        <OperationMain></OperationMain>
    </template> -->

    <template v-slot:appointmentCalendar-detail>
      <CenterMain
        v-if="ready"
        ref="appoientment"
        :appoinetDate="nowDate"
        :times="times"
        :plans="plans"
        :doctors="doctors"
        :data="leftList"
        @planclick="onPlanClicks"
        @blankclick="onBlankClicks"
        @daychangs="onDayChange"
        @weekchang="onWeekChange"
        :calendarSetting="calendarSetting"
        @changebuttonclick="onChangeButtonClick"
        @medicalrecordsearch="onMedicalRecordSearch"
        :searchrecords="searchList"
        :departmentDatas="departmentRecords"
        :settingDatas="settingRecords.items"
        @appointmentsaveclick="onAppointmentSaveClick"
        :timeDoctors="timeDoctors"
        :doctorId="centerRecordsParam.doctor_id"
        :dayTime="centerRecordsParam.appointment_date"
         @doctortimereturn="onGetDoctorTimeReturn"
        :doctorDayTime="doctorDayTime"
        :doctordaylistData="doctordayrecords"
        :doctorClassesListData ="doctorClassesList"
        :classesSettingData="classesSetting.items"
      ></CenterMain>
    </template>

    <!-- 右：列表数据详情 -->
    <template v-slot:patient-detail>
      <RightMain
        :patientData="patientRecord"
        :signsdata="signsRecord"
        @appoientmentsaveclick="onAppoientmentSaveClick"
        @diagnosissaveclick="onDiagnosisSaveClick"
        :doctors="doctors"
        :timeDoctors="timeDoctors"
        :settingDatas="settingRecords.items"
        :settingTrees="settingTrees"
        :departmentDatas="departmentRecords"
        @changestatus="onChangeStatus"
        @changediagnosisstatus="onChangeDiagnosisStatus"
        :doctorDayTime="doctorDayTime"
        :doctordaylistData="doctordayrecords"
        :doctorClassesListData ="doctorClassesList"
        :classesSettingData="classesSetting.items"
      ></RightMain>
    </template>
  </AppointmentCalendarLayout>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  mock,
  components,
  layouts,
} from "@/helper";

export default {
  components: {
    ...layouts(["AppointmentCalendar"]),
    ...components(["Left.Main", "Center.Main", "Right.Main"], "appointmentCalendar"),
    // ...components(
    //   ["operation.Main", "list.Doctor", "Detail", "list.patient.Detail"],
    //   "appointmentCalendar"
    // ),
  },

  data() {
    return {
      ready:false,
      calendarSetting: 1, //tab 切换定义 1=day 2=week
      histories: [],
      doctors: [], //预约列表
      times: [
        {
          start: "09:00",
          split: 15,
          end: "11:45",
        },
        {
          start: "13:00",
          split: 15,
          end: "17:30",
        },
      ],
      searchList: {},
      patientRecord: {}, //患者预约详情信息
      signsRecord: {}, //患者生命体征详情信息
      appointmentRecordNo: null, //患者预约编号
      plans: [],
      doctor: null, //选中的预约医生信息
      loading: {
        doctors: false, //预约列表loading
        leftList:false
      },
      timeDoctors: {},
      oneWeekAppointmentRecord: [], //医生一周预约记录
      doctorsParam: {
        //医生列表参数
        real_name: undefined,
        diagnosis_status: undefined,
        appointment_date: undefined,
        page_no: 1,
        page_size: 10,
      },
      MondayTime: null, ////当周周一日期；
      centerRecordsParam: {
        //点击医生获取医生预约列表参数
        doctor_id: undefined,
        appointment_date: undefined,
        week_data: 1,
        keywords: undefined,
        dayEvent: undefined, //1
        eventEvent: 1, //7
        thirtyEvent: undefined, //30
        commend:undefined
      },
      dayList: [
        {
          doctorId: 1,
          name: "2021-09-07",
          count_appointment: 5,
          count_register: 6,
          count_wait: 0,
          count_end: 10,
        },
        {
          doctorId: 1,
          name: "2021-09-08",
          count_appointment: 5,
          count_register: 6,
          count_wait: 0,
          count_end: 10,
        },
        {
          doctorId: 1,
          name: "2021-09-09",
          count_appointment: 5,
          count_register: 6,
          count_wait: 0,
          count_end: 10,
        },
        {
          doctorId: 1,
          name: "2021-09-10",
          count_appointment: 5,
          count_register: 6,
          count_wait: 0,
          count_end: 10,
        },
        {
          doctorId: 1,
          name: "2021-09-11",
          count_appointment: 5,
          count_register: 6,
          count_wait: 0,
          count_end: 10,
        },
        {
          doctorId: 1,
          name: "2021-09-12",
          count_appointment: 5,
          count_register: 6,
          count_wait: 0,
          count_end: 10,
        },
        {
          doctorId: 1,
          name: "2021-09-13",
          count_appointment: 5,
          count_register: 6,
          count_wait: 0,
          count_end: 10,
        },
      ],
      doctorList: [
        {
          name: "叶医生",
          doctorId: 1,
        },
        {
          name: "黄医生",
          doctorId: 2,
        },
        {
          name: "陈医生",
          doctorId: 3,
        },
        {
          name: "朱医生",
          doctorId: 3,
        },
        {
          name: "卡医生",
          doctorId: 3,
        },
        {
          name: "法医生",
          doctorId: 3,
        },
      ],
      patientList: [
        {
          doctorId: 1,
          name: "患者1",
          start: "2021-08-20 10:00",
          end: "2021-08-20 10:30",
        },
        {
          doctorId: 2,
          name: "患者1",
          start: "2021-08-20 11:00",
          end: "2021-08-20 11:30",
        },
        {
          doctorId: 3,
          name: "患者1",
          start: "2021-08-20 13:00",
          end: "2021-08-20 14:30",
        },
        {
          doctorId: 4,
          name: "患者1",
          start: "2021-08-20 14:00",
          end: "2021-08-20 15:30",
        },
        {
          doctorId: 5,
          name: "患者1",
          start: "2021-08-20 15:30",
          end: "2021-08-20 16:30",
        },
        {
          doctorId: 1,
          name: "患者2",
          start: "2021-08-20 10:00",
          end: "2021-08-20 10:30",
        },
        {
          doctorId: 2,
          name: "患者2",
          start: "2021-08-20 11:00",
          end: "2021-08-20 11:30",
        },
        {
          doctorId: 3,
          name: "患者2",
          start: "2021-08-20 13:00",
          end: "2021-08-20 14:30",
        },
        {
          doctorId: 4,
          name: "患者2",
          start: "2021-08-20 14:00",
          end: "2021-08-20 15:30",
        },
        {
          doctorId: 5,
          name: "患者2",
          start: "2021-08-20 15:30",
          end: "2021-08-20 16:30",
        },
      ],
      doctors: [], //医生列表
      leftList:[],//左边区域数据
      departmentRecords: [],
      settingRecords: {},
      // doctors: [
      //   {
      //     id: 1,
      //     name: "张医生",
      //   },
      //   {
      //     id: 2,
      //     name: "李医生",
      //   },
      //   {
      //     id: 3,
      //     name: "叶医生",
      //   },
      //   {
      //     id: 4,
      //     name: "王医生",
      //   },
      //   {
      //     id: 5,
      //     name: "刘医生",
      //   },
      //   {
      //     id: 6,
      //     name: "陈医生",
      //   },
      //   {
      //     id: 7,
      //     name: "赵医生",
      //   },
      //   {
      //     id: 8,
      //     name: "周医生",
      //   },
      //   {
      //     id: 9,
      //     name: "苏医生",
      //   },
      // ],
      plansday: [
        {
          id: 1,
          patient: "张三",
          doctor: 2,
          start: "10:00",
          end: "15:00",
        },
        {
          id: 2,
          patient: "李四",
          doctor: 5,
          start: "13:00",
          end: "15:00",
        },
        {
          id: 3,
          patient: "王五",
          doctor: 5,
          start: "09:30",
          end: "10:00",
        },
      ],
      settingTrees: [],
      userInfo: {},
      allDoctors: 0, //week-left-screen-button-change-style
      screenTabs:2, //day-left-screen-button-change-style | settting=>2（sevenEven）
      doctordayrecords: {}, //当天排班记录
      doctorDayTime: true, // 选择的医生当天是否可约
      classesSetting: {},
      doctorClassesList: [], //医生所在部门班次记录
      doctordayrecordstwo:{}, //right 当天排班记录
      basicDepartmentID:0
    };
  },

  async mounted() {
    let that = this;

    // 获取医生列表
   // await that.getDoctorsList();
    //获取当天时间
    that.formatDate();
    //获取周一时间
    that.weekCheckingIn();

    //排班设置list记录
    await that.getClassesSettingRecords();
    //默认appointment_date为周一时间
    that.centerRecordsParam.appointment_date = that.MondayTime;

    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));

    that.getStoreRecordsDepartmentID();

        // 模版数据请求
    that.initTemplate();

    // if (that.doctors.length) {
    //   that.$refs.doctorlist.select(that.doctors[0]);
    // }

    // 模版数据请求
    // await this.initTemplate();
    // let me = this, patients = await mock('see/patient/list');

    // me.patients = patients;
    //
    // if (patients.length) {
    //
    // 	me.$refs.patientlist.select(patients[0]);
    // }

    that.onChangeButtonClick(1) ;

    that.ready = true ;
  },
  methods: {
    /**
     * 初始化模版数据
     *
     */
    initTemplate() {
      let me = this;

      //预约项list记录
      me.onSettingList();
      //trees记录
      me.getSettingTrees();
      //科室记录
      me.getDeatrtmentList();
      //预约记录--默认全部医生-一周
      // me.getAppointmentRecords();

      // me.getClassesSettingRecords();


    },
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *left start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    /**
     * 医生列表 筛选状态
     */
    changeStatus(e) {
      this.doctorsParam.diagnosis_status = e;
      this.loading.status = true;
      this.getDoctorsList();
    },
    onleftSearch(e) {
      this.doctorsParam.keywords = e;
      this.getDoctorsList();
    },
    onAllDoctor() {
      
      let me = this;
      me.allDoctors = 0;
      me.centerRecordsParam.doctor_id = undefined;
      me.getAppointmentRecords();
    },
    onLeftChangestatus(commonnd) {
      let me = this;
      me.centerRecordsParam.commend = commonnd;
      me.getAppointmentRecords();
    },
    // onTodayEvent() {
    //   let me = this;
    //   me.centerRecordsParam.appointment_date = this.nowDate;
    //   me.centerRecordsParam.dayEvent = 1;
    //   me.centerRecordsParam.eventEvent = undefined;
    //   me.centerRecordsParam.thirtyEvent = undefined;
    //   me.getAppointmentRecords();
    // },
    onSevenEvent() {
      let me = this;
      me.screenTabs=2;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.centerRecordsParam.fiftyEvent = undefined;
      me.centerRecordsParam.eventEvent = 1;
      me.getTimesList();
    },
     onFiftyEvent() {     
      let me = this;
      me.screenTabs=3;
      //me.formatDate();
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.fiftyEvent = 1;
      me.getTimesList();

    },
    onThirtyEvent() {
      let me = this;
      me.screenTabs=4;
      //me.formatDate();
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.fiftyEvent = undefined;
      me.centerRecordsParam.thirtyEvent = 1;
      me.getTimesList();
    },
    onCalendarEvent(e) {
      //console.log("centerRecordsParam",this.centerRecordsParam);
      let me = this;
      me.centerRecordsParam.appointment_date = e;
      me.getTimesList();
    },
    /**
     * 点击医生时出发 获取医生一周内的预约记录
     * @param doctor
     * @returns {Promise<void>}
     */
    onDoctorItemSelect(val) {

      let me = this;

      me.doctor = val;

      me.allDoctors = 1; //left-screen-button-change-style

      me.centerRecordsParam.doctor_id = val.doctorId;
      //me.centerRecordsParam.appointment_date = me.MondayTime; //当周周一日期；
      if (me.calendarSetting == 2) { //周视图
        me.getAppointmentRecords();
      } else if (me.calendarSetting == 1) { //日视图
        me.centerRecordsParam.daysEvent = 1; //只针对日视图的获取预约记录
        me.centerRecordsParam.eventEvent = 1;
        me.centerRecordsParam.thirtyEvent = undefined;
        me.centerRecordsParam.doctor_id = undefined;
        me.centerRecordsParam.week_data = undefined;
        me.centerRecordsParam.appointment_date = val.name;
        me.getTimeDoctorRecords(val.name);
        me.getAppointmentRecords();
      }

    },


    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *left end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *Center start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

    async onChangeButtonClick(val) {
      let me = this;
      me.calendarSetting = val;
      //日视图
      if (val == 1) {
        me.centerRecordsParam.appointment_date = me.nowDate;
        //me.centerRecordsParam.dayEvent = 1;
        me.centerRecordsParam.eventEvent = 1;
        me.centerRecordsParam.week_data = undefined;
       // me.doctors = me.dayList;
        await me.getTimesList();

        let {
          leftList
        } = me ;

        if(leftList.length){

          me.onDoctorItemSelect(leftList[0]) ;
        }

      }
      //周视图
      if (val == 2) {
        me.centerRecordsParam.appointment_date = me.MondayTime;
        me.centerRecordsParam.dayEvent = undefined;
        //me.centerRecordsParam.doctor_id = undefined;
        me.centerRecordsParam.week_data = 1;
        me.getDoctorsList();
      }
      me.getAppointmentRecords();
    },
    /**
     * 点击获取预约详细信息
     * 和Detial的 onPlanClick 联动
     */
    onPlanClicks(plan) {
      console.log("onPlanClicks 日",plan);
      let me = this;
      //更改预约编号
      me.appointmentRecordNo = plan.appointment_record_no;
      //请求预约详情
      me.getPatientRecord(plan.appointment_record_no);
      //请求生命体征
      me.getSignsRecord(plan.appointment_record_no);

      // 根据医生I0D请求医生当天预约记录
        // let data = {
        //   appointment_date: me.centerRecordsParam.appointment_date,
        //   doctor_id: plan.doctor,
        // };
        // me.getDoctorDayList(data);

        //请求医生当天是否可约，并返回医生部门班次记录
        let doctorData = {
          appointment_date: me.nowDate,
         // department_id: val.department_id,
          doctor_id: plan.doctor,
          click:"plan"
        };
        me.getDoctorTimeReturnRecords(doctorData); 
    },
    onPreviousButtonClick() {
      this.$refs.calendar.previous();
    },
    onWeekChange(val) {
      let me = this;
      // 获取医生下周周内的预约记录
      //me.centerRecordsParam.doctor_id = val.doctorId;
      me.centerRecordsParam.appointment_date = val; //下周周一
      me.getAppointmentRecords();
    },
     onDayChange(val) {
      let me = this;
      // 获取医生下周周内的预约记录
      //me.centerRecordsParam.doctor_id = val.doctorId;
      me.centerRecordsParam.appointment_date = val; //下周周一
      me.getAppointmentRecords();
    },

    onGetDoctorTimeReturn(val) {
      let me = this;
      me.getDoctorTimeReturnRecords(val); //请求医生当天是否可约，并返回医生部门班次记录
    },

    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *Center end** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *Right start** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    // onNextWeekClick() {

    //     let me = this;

    //     // 获取医生下周周内的预约记录
    //     me.getDoctorRecord({
    //         //'doctor_id': doctor.doctor_id,
    //         'doctor_id': '1',
    //         'appointment_date': '2021-05-10'
    //     });
    // },

    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *Right end** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

   //获取门店绑定的预约排班部门ID

   async getStoreRecordsDepartmentID() {

      let me = this;
     // console.log("basicDepartmenntID",  me.userInfo);
      let data={
          STORETOKEN:me.userInfo.store_token
      }
      let res = await mock("store/getStoreRecords",data);
      if (res.status == "200") {
       // console.log("resData",res.data);
         //me.basicDepartmentID = res.data.items[0].department_id;
         me.userInfo.department_id = res.data.items[0].department_id;
        
      }

    //   console.log("basicDepartmentID",  me.basicDepartmentID );
    },

    /**
     * 获取看诊项->预约管理->预约项树型数据
     */
    async getSettingTrees() {
      let me = this;

      try {
        me.settingTrees = await mock("configuration/Appointment/Setting/getTreeList");
      } catch (err) {
        me.$message({
          showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }

    },
    /**
     * 取消预约，客户爽约
     */
    onChangeStatus(val) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.setStatusSave(val);
        })
        .catch();
    },
    /**
     * 到店
     */
    onChangeDiagnosisStatus() {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.setDiagnosisStatusSave();
        })
        .catch();
    },
    /**
     * 生命体征添加
     */
    onDiagnosisSaveClick(val) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.onDiagnosisSave(val);
        })
        .catch();
    },

    /**
     * 根据搜索词搜索患者信息
     */
    //search(5):触发上级事件
    onMedicalRecordSearch(val) {
      this.getMedicalRecordSearch(val);
    },
    /**
     * 搜索患者
     */
    async getMedicalRecordSearch(e) {
      let me = this;
      //me.searchList =  me.states;
      //search(6): 获取远程返回的值并赋值
      me.searchList = await mock("appointment/getSearchRecords", e);

    },
    onDoctorButtonClick(plans) {
      this.plans = plans;
    },

    //点击 空白区域事件
    onBlankClicks(e) {
    // console.log("onBlankClicks 日",e);
      let me = this;
     // console.log("blank",e);
       //请求医生当天是否可约，并返回医生部门班次记录
        let doctorData = {
          appointment_date: e.dateTime,
          endTime:e.endTime,
          startTime:e.startTime,
         // department_id: val.department_id,
          doctor_id: e.doctorId,
          click:"blank"
        };
        me.getDoctorTimeReturnRecords(doctorData); 
    },
    onAppoientmentSaveClick(val) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.onAppoientmentSave(val);
        })
        .catch();
    },

    /**
     * 搜索医生
     */
    doctorsSearch() {
      this.loading.doctors = true;
      // this.getPatientsList();
    },

    /**
     * 医生列表 筛选状态
     */
    changeStatus(e) {
      this.doctorsParam.diagnosis_status = e;
      this.loading.doctors = true;
      this.getDoctorsList();
    },
    //预约诊状、持续时间
    onSettingList() {
      let me = this;
      me.getSettingRecords();
    },

    onAppointmentSaveClick(val) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.AppoientmentRecordSave(val);
        })
        .catch();
    },

    /**
     * 提交
     */
    async setDiagnosisStatusSave() {
      let me = this;
      try {
        let data = {
          appointment_record_no: me.appointmentRecordNo, //预约号
          medical_record_no: me.patientRecord.patient_information.medical_record_no,
          diagnosis_status: 1,
          create_id: me.userInfo.client_id,
          create_user: me.userInfo.name,
        };
        let res = await mock("public/State/setAppointmentDiagnosisItem", data);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.getAppointmentRecords();
        }
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 提交
     */
    async setStatusSave(e) {
      let me = this;
      try {
        let data = {
          appointment_record_no: me.appointmentRecordNo, //预约号
          medical_record_no: me.patientRecord.patient_information.medical_record_no,
          status: e,
          create_id: me.userInfo.client_id,
          create_user: me.userInfo.name,
        };
        let res = await mock("appointmentCalendar/right/setStatusItem", data);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.getAppointmentRecords();
        }
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 提交新的预约记录
     */
    async AppoientmentRecordSave(e) {
      let me = this;
      try {
        let res = await mock("appointment/center/setAppoientmentRecordDetail", e);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });

          me.$refs.appoientment.changeDrawer(false);
          me.getAppointmentRecords();
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    /**
     * 获取看诊项->预约管理->添加预约记录时使用
     */
    async getSettingRecords() {
      let me = this;
      try {
        let res = await mock("configuration/Appointment/Setting/getRecords");
        if (res.status === 200) {
          me.settingRecords = res.data;
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }

    },

    /**
     * 获取医生列表
     */
    async getDoctorsList() {
      this.doctors = await mock(
        "appointmentCalendar/left/getDoctorCountRecords",
        this.doctorsParam
      );
      this.leftList =  this.doctors; //赋值给左边的数据


      this.loading.leftList = false;
    },

    /**
     * 获取时间列表-left
     */
    async getTimesList() {
      this.leftList = await mock(
        "appointmentCalendar/left/getTimeCountRecords",
        this.centerRecordsParam
      );


      this.loading.leftList = false;
    },
    /**
     * 科室记录
     */
    async getDeatrtmentList() {
      let me = this;
      try {
        me.departmentRecords = await mock(
          "configuration/Appointment/ClinicalDepartment/getTreeList"
        );
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 点击获取医生一周预约记录，二种调用办法
     * 1:点击医生获取
     * 2:点击上一周，下一周获取
     */

    async getAppointmentRecords() {
      let me = this;

      // 1:点击医生获取
      //   me.plans = await mock("appointmentCalendar/center/List", data).then(async (res) => {
      //     return res;
      //   });
      //日视图
      if (me.calendarSetting == 1) {
        me.plans = await mock(
          "appointmentCalendar/center/getAppointmentTimeRecords",
          me.centerRecordsParam
        );
      }
      //周视图
      if (me.calendarSetting == 2) {
        me.plans = await mock(
          "appointmentCalendar/center/getAppointmentDoctorRecords",
          me.centerRecordsParam
        );
      }

      //2:点击上一周，下一周获取
      //待实现 TODO
    },

    /**
     * 点击获取当天的当班的医生记录

     */

    async getTimeDoctorRecords(time) {
      let me = this;

      let data = {
        dayTime: time,
      };
      me.timeDoctors = await mock(
        "appointmentCalendar/center/getTimeDoctorRecords",
        data
      );

      //2:点击上一周，下一周获取
      //待实现 TODO
    },

    /**
     * 获取预约详情
     */
    async getPatientRecord(e) {
      this.patientRecord = await mock(
        "appointmentCalendar/right/getAppointmentDetails",
        e
      );
    },
    /**
     * 获取生命体征
     */
    async getSignsRecord(e) {
      //this.signsRecord = await mock("appointmentCalendar/right/getPatientVitalSigns", e);
      try {
        let res = await mock("appointment/right/getPatientVitalSigns", e);
       // if(res.status="200"){
        this.signsRecord = res.item;
       // }

      
      } catch (err) {
        this.signsRecord = [];
      }
    },
    async onAppoientmentSave(e) {
      let me = this;

      let data = {
        appointment_record_no: me.appointmentRecordNo, //预约号
        doctor_id: e.doctorID, //预约医生ID
        clinical_department_id: e.departmentID, ////预约科室ID
        // symptom: e.symptom, // //症状
        // duration_time: e.duration, ////持续时间
        setting: e.setting,
        appointment_date: e.dataTime, ////持续时间
        start_time: e.startTime, ////预约时间：开始时间
        end_time: e.endTime, ////预约时间：结束时间
        status: e.status, //// 就诊状态 1=初诊 2=复诊 3=首诊
        visit_status: e.visitStatusValue, //就诊状态 1=初诊 2=复诊 3=首诊
        create_id: me.userInfo.client_id, //创建人ID=doctor表
        medical_record_no: me.patientRecord.patient_information.medical_record_no,
        create_user: me.userInfo.name,
        remarks:e.remarks
      };
      me.ressave = await mock(
        "appointmentCalendar/right/setAppointmentDetails",
        data
      ).then((res) => {
        // 获取医生一周内的预约记录
        //TODO 刷新数据
        // me.getDoctorRecord({
        //   doctor_id: e.doctorID,
        //   appointment_date: "2021-04-28",
        // });
        me.getAppointmentRecords();
        me.$message({
           showClose: true,
          type: "success",
          message: "保存成功!",
        });
      });
    },
    async onDiagnosisSave(e) {
      let me = this;

      let data = {
        user_id: me.patientRecord.patient_information.user_id,
        temperature: e.temperature, // 体温
        breathe: e.breathe,
        blood_pressure: e.blood_pressure,
        heart_rate: e.heart_rate,
        stature: e.stature,
        weight: e.weight,
        head_circumference: e.head_circumference,
        bmi: e.bmi,
        allergies: e.allergies,
        past_history: e.past_history,
        family_history: e.family_history,
        type: e.type,
        appointment_record_no: me.appointmentRecordNo,
        medical_record_no: me.patientRecord.patient_information.medical_record_no,
        see_status: 1, //待诊
        create_id: me.userInfo.client_id, //创建人ID=doctor表
        create_user: me.userInfo.name,
        doctor_id: e.doctor_id,
      };
      me.ressave = await mock("appointmentCalendar/right/setDiagnosis", data).then(
        (res) => {
          me.getAppointmentRecords();
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
        }
      );
    },
     /**
     *排班配置
     */
    async getClassesSettingRecords() {
      let me = this;
      let data = {
           department_id: me.userInfo.department_id, 
      };
      //let res = await mock("appointmentCalendar/center/getClassSettingCalendarRecords", data);
      let res = await mock("appointment/center/getClassSettingRecords", data);
      if (res.status == "200") {


        me.classesSetting = res.data; //
        me.times  = res.data.items;
        // me.classesSetting.items = [{
        //     "classes_id": 1,
        //     "start": "9:30",
        //     "end": "11:30",
        //     "step": 15,
        //     "name": "早班",
        //     "english_name": "12"
        // }, {
        //     "classes_id": 2,
        //     "start": "13:00",
        //     "end": "17:30",
        //     "step": 15,
        //     "name": "中班",
        //     "english_name": ""
        // }, {
        //     "classes_id": 9,
        //     "start": "18:00",
        //     "end": "21:30",
        //     "step": 15,
        //     "name": "晚班",
        //     "english_name": ""
        // }]
        // //
      }
    },

    /**
     * 根据医生ID、时间获取医生当天的预约记录
     */
    async getDoctorDayList(e) {
      let me = this;
      //me.searchList =  me.states;
      //search(6): 获取远程返回的值并赋值
      let res = await mock("appointment/center/getDoctorDayRecords", e);

      if (res.status == "200" && res.data !== null) {
          if(e.request_type=="add"){
            me.doctordayrecords = res.data;
          }
     
      } else {
        me.doctordayrecords = [];
      }
     // console.log("me.doctordayrecords",me.doctordayrecords)
    },
  /**
     * 根据医生、日期获取医生的排班记录
     */
    async getDoctorTimeReturnRecords(e) {
      let me = this;
      let res = await mock("appointment/center/getDoctorTimeReturnRecords", e);

      if (res.status == "200" && res.data !== null) {

      //console.log("classesItems",res.data.classesItems,res.data.classesItems.length==0);
         //根据res.data.classesItems.length==0判断该医生该时间段没有排班
        //if(res.data.compareTime>0){
        if(res.data.classesItems.length !==0){
           me.doctorDayTime = true; //今日可约
        }else{
           me.doctorDayTime = false;
        } 
     
        me.doctorClassesList = res.data.classesItems; //[{classes_id: 1}] //医生部门排班记录
         //根据排班设置list记录
         //let departmentID = me.doctorClassesList[0].department_id;
        //me.getClassesSettingRecords();

        let data = {
          appointment_date: e.appointment_date,
          doctor_id: e.doctor_id,
          request_type:e.request_type
        };
        me.getDoctorDayList(data);
      } else {
        me.doctorDayTime = false;
      }

     // console.log("doctorDayTime",me.doctorDayTime);
    },

    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.centerRecordsParam.appointment_date = `${year}-${month}-${day}`;
      this.nowDate = `${year}-${month}-${day}`;
      this.doctorsParam.appointment_date = `${year}-${month}-${day}`;

    },
    //获取当周的周一和周日日期
    weekCheckingIn() {
      let me = this;
      me.weekChecking_inloading = true;
      let now = new Date();
      let nowTime = now.getTime();
      let day = now.getDay() || 7; // 不加 || 7.周末会变成下周一
      let oneDayTime = 24 * 60 * 60 * 1000;
      let MondayTime = nowTime - (day - 1) * oneDayTime; //显示周一
      //var SundayTime = nowTime + (7 - day) * oneDayTime; //显示周日
      //调用方法
      me.MondayTime = me.formatWeek(new Date(MondayTime));
    },
    formatWeek(date) {
      let myyear = date.getFullYear();
      let mymonth = date.getMonth() + 1;
      let myweekday = date.getDate();
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-left {
  margin: 4px 2px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  height: 60px;
  line-height: 60px;
}

.search {
  width: 295px;
  padding: 0 10px;

  > .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 160px;
    margin-right: 10px;
  }
}
</style>
