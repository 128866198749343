<template>
  <Container>
    <Container class="appoientment-main-operation hb-layout-horizontal">
      <div class="item">
        <el-button type="primary" @click="addTopItem">新增</el-button>
        <el-button type="primary">刷新</el-button>
        <el-button
          icon="el-icon-circle-plus-outline"
          :disabled="treeLoading"
          @click="checkedNodes(true)"
          >展开</el-button
        >
        <el-button
          icon="el-icon-remove-outline"
          :disabled="treeLoading"
          @click="checkedNodes(false)"
          >收起</el-button
        >
        <div style="white-space: nowrap;padding-left: 10px;">
          <el-input
          v-model="filterText"
          :disabled="treeLoading"
          placeholder="输入关键词进行过滤"
          style="width: 240px;padding: 0;"
          :clearable="true">
        </el-input>
        </div>
      </div>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  data() {
    return {
      screening: "",
       hackReset: true,
      isExpandAll: true,
      expanded: [],
      filterText: '',
      treeLoading: false,
    };
  },
   watch: {
    filterText(val) {
      this.$emit("filtertext",val);
    },
  },
  methods: {
     // 过滤节点
    filterNode(value, data) {
      if (!value) { return true }
      return data.name.indexOf(value) !== -1
    },
    // 展开或收起节点
    checkedNodes(isVal) {
      this.$emit("isshowall",isVal);
    },
    addTopItem(){
      this.$emit('addtopitem');
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    loadAll() {
      return [
        { value: "三全鲜食（北新泾店）", address: "长宁区新渔路144号" },
        { value: "Hot honey 首尔炸鸡（仙霞路）", address: "上海市长宁区淞虹路661号" },
        { value: "新旺角茶餐厅", address: "上海市普陀区真北路988号创邑金沙谷6号楼113" },
        { value: "泷千家(天山西路店)", address: "天山西路438号" },
        {
          value: "胖仙女纸杯蛋糕（上海凌空店）",
          address: "上海市长宁区金钟路968号1幢18号楼一层商铺18-101",
        },
        { value: "贡茶", address: "上海市长宁区金钟路633号" },
        { value: "豪大大香鸡排超级奶爸", address: "上海市嘉定区曹安公路曹安路1685号" },
      ];
    },
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px auto 10px;

  .item {
    width: 100%;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;
    line-height: 22px;
    line-height: 60px;
    padding: 0 20px;
    display: flex;
    align-items: center;
  }
}
</style>
