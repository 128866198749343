<template>
<Container>
	<Container class="appoientment-main-operation hb-layout-horizontal">
		<div class="search">
			<el-input v-model="patientInput" placeholder="搜索患者" class="input-with-select search">
				<el-button slot="append" icon="el-icon-search"></el-button>
			</el-input>
		</div>
		<el-button type="primary"  @click="newAppointmentDrawer = true">新增预约</el-button>

	</Container>
	<el-drawer title="新增预约" :visible.sync="newAppointmentDrawer" style="fontSize:20px;">
        <div>
			<el-card>
                <Container class="see-patient-history-list-item hb-layout-vertical">
                    <Container class="header hb-layout-horizontal">
                        <div class="time hb-layout-flex-1">
                            就诊时间:2020-01-01 14:15-14:30
                        </div>
                    </Container>
                    <Container class="body">
                        <Container class="inner hb-layout-horizontal">
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">姓名</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
										<el-input v-model="nameInput" placeholder="请输入名字"></el-input>
									</div>
                                </el-col>
                            </el-row>
                            <el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">性别</div>
                                </el-col>
                                <el-col :span="18">
									<el-select style="width: 100%" v-model="sexValue" placeholder="请选择性别">
										<el-option v-for="item in sexOptions" :key="item.value" :label="item.label" :value="item.value">
										</el-option>
									</el-select>
                                </el-col>
                            </el-row>
							<el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">出生年月</div>
                                </el-col>
                                <el-col :span="18">
									<el-date-picker
										v-model="dataValue"
										type="date"
										placeholder="请选择日期"
										style="width: 100%" >
									</el-date-picker>
                                </el-col>
                            </el-row>
							<el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">联系方式</div>
                                </el-col>
                                <el-col :span="18">
                                    <div class="grid-content bg-purple-light">
										<el-input v-model="phoneInput" placeholder="请输入联系方式"></el-input>
									</div>
                                </el-col>
                            </el-row>
							<el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">预约时间</div>
                                </el-col>
                                <el-col :span="18">
									<el-select style="width: 100%" v-model="timeValue" placeholder="请选择预时间">
										<el-option v-for="item in timeOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
                                </el-col>
                            </el-row>
							<el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">预约医生</div>
                                </el-col>
                                <el-col :span="18">
									<el-select style="width: 100%" v-model="doctorValue" placeholder="请选择预约医生">
										<el-option v-for="item in doctorOptions" :key="item.value" :label="item.label" :value="item.value">
										</el-option>
									</el-select>
                                </el-col>
                            </el-row>
							<el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">预约科室</div>
                                </el-col>
                                <el-col :span="18">
									<el-select style="width: 100%" v-model="departmentValue" placeholder="请选择预约科室">
										<el-option v-for="item in departmentOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
                                </el-col>
                            </el-row>
							<el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">症状</div>
                                </el-col>
                                <el-col :span="18">
									<el-select style="width: 100%" v-model="symptomValue" placeholder="请选择症状">
										<el-option v-for="item in symptomOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
                                </el-col>
                            </el-row>
							<el-row type="flex" align="middle">
                                <el-col :span="6">
                                    <div class="grid-content bg-purple">持续时间</div>
                                </el-col>
                                <el-col :span="18">
									<el-select style="width: 100%" v-model="durationValue" placeholder="请选择持续时间">
										<el-option v-for="item in durationOptions" :key="item.value" :label="item.label" :value="item.value"></el-option>
									</el-select>
                                </el-col>
                            </el-row>
                        </Container>

                        <el-button type="success" class="edit-buttom">提交</el-button>
                    </Container>
                </Container>
            </el-card>
		</div>
    </el-drawer>
</Container>
	

</template>

<script>
	import Container from '../../Container';

	export default {
		components: {
			Container
		},
		data(){
			return{
				patientInput: '',

				newAppointmentDrawer: false,  
				nameInput: '',  // 姓名
				sexOptions: [
					{
						value: '1',
						label: '男'
					},{
						value: '2',
						label: '女'
					}
				],
				sexValue: '',  
				dataValue: '',   // 出生年月  
				phoneInput: '',  // 联系方式
				doctorOptions: [
					{
						value: '1',
						label: '宋医生'
					},{
						value: '2',
						label: '董医生'
					}
				],
				doctorValue: '',  // 预约医生
				timeOptions: [
					{
						value: '1',
						label: '2021-05-19 10:30'
					},{
						value: '2',
						label: '2021-05-20 13:30'
					},{
						value: '3',
						label: '2021-05-21 13:30'
					}
				],
				timeValue: '',   // 预约时间
				 // 选择科室
                departmentOptions: [
					{
						value: "1",
						label: "内科",
					},{
						value: "2",
						label: "外科",
					},{
						value: "3",
						label: "神经内科",
					}
				],
                departmentValue: "",

				// 症状
				symptomOptions: [
					{
						value: "1",
						label: "头疼",
					},{
						value: "2",
						label: "流鼻涕",
					},
				],
				symptomValue: "",

				// 持续时间
				durationOptions: [
					{
						value: "1",
						label: "三天",
					},{
						value: "2",
						label: "一周",
					},
				],
				durationValue:''

			}
		}

	};
</script>

<style lang="scss" scoped>
	.appoientment-main-operation {
		width: 99%;
		padding: 0 19px;
		height: 60px;
		line-height: 60px;
		box-shadow: 0 2px 7px 0 #d8d8d8;
		margin: 5px 5px 10px;
		position: relative;

		.search{
			width: 300px;
		}
		
		>.el-button{
			position: absolute;
			right: 20px;
			top: 10px;
		}
	}


	// 新增回访
.see-patient-history-list-item {
    width: 100%;
    border: 1px solid #e1e1e1;

    >.header {
        padding: 0 10px;
        height: 50px;
        line-height: 50px;
        background-color: #ededed;
        border-bottom: 1px solid #e1e1e1;

        >.time {
            font-size: 12px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5b5b5b;
        }

        >.button {
            height: 30px;
            margin-top: 12px;
        }
    }

    >.body {
        padding: 10px;

        .inners {
            display: flex;
            flex-direction: column;

            .row-select {
                margin-bottom: 7px;
            }
        }

        >.inner {
            display: flex;
            flex-direction: column;
            border: 1px solid #979797;

            .el-row {
                width: 100%;
                height: auto;
                line-height: 38px;
                border-bottom: 1px solid #979797;
                font-size: 12px;

                &:last-child {
                    border-bottom: none;
                }
            }

            .bg-purple {
                text-align: center;
                background: #EBEBEB;
                border-right: 1px solid #979797;
            }

           

            .bg-purple-light-select {
                padding: 0;
                font-size: 12px;
            }
        }

        .title {
            line-height: 34px;
            margin-top: 10px;
            font-size: 16px;
        }

    }

    .edit-buttom {
        width: 100%;
        margin-top: 20px;
    }
}
</style>
