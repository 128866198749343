import axios from 'axios' ;

let url = 'https://api.demo.hobooa.com/mock/91/api/v2/patient/method/get.diagnosis.record.basic.list/';

export default () =>{

    return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/appointment/method/get.appointment.basic.list/').then(({
        data
    }) => data)
    .then(({
        data
    }) => data)
    .then(({
        items
    }) => items.map(({
        get_user,
        payment_status,
        appointment_time
    }) =>({
        name:get_user.nickname,
        header:get_user.head_pic,
        member:get_user.card_type,
        time:appointment_time,
        state:payment_status
    })));
}

