<template>
  <Container class="appoientment-main-operation hb-layout-horizontal">
    <div style="width: 100%">
      <el-menu class="el-menu-vertical-demo" default-active="1">
        <el-menu-item index="1" @click="getLeftChangeSeven(1)">
          <span slot="title">用法管理</span>
        </el-menu-item>
        <el-menu-item index="2" @click="getLeftChangeSeven(2)">
          <span slot="title">用量管理</span>
        </el-menu-item>
        <el-menu-item index="3" @click="getLeftChangeSeven(3)">
          <span slot="title">禁忌管理</span>
        </el-menu-item>
        <el-menu-item index="4" @click="getLeftChangeSeven(4)">
          <span slot="title">医嘱管理</span>
        </el-menu-item>
      </el-menu>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  data() {
    return {};
  },
  methods: {
    getLeftChangeSeven(val) {
      //console.log("1",val);
      this.$emit("getleftchangeseven", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 2px 10px 0px;

  .el-menu-item,
  .el-submenu__title {
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 12px;
    border-bottom: 1px solid #e1e1e1;
  }
  .el-menu-item.is-active {
    background: #92c2f957;
    box-shadow: 0 2px 7px 0 #d8d8d8;
    margin: 2px 0;
  }
  .el-menu-item.is-active:nth-child(1) {
    margin: 0 0 2px;
  }
}
</style>
