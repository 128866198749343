<template> 
  <ConfigurationLayout>
    <template v-slot:configuration-details>
      <div class="details">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="排班管理" name="first">
            <SchedulingDetails
              :loading="loading"
              :schedulingDatas="schedulinngRecords"
              :classesDatas="classesRecords.items"
              :departmentDatas="departmentCategory.items"
              :doctorDatas="doctorRecords"
              @schedulingplan="onSchedulingPlan"
              :todaySchedulinngDatas="todaySchedulinngRecords.items"
              @classesplanchange="onClassesPlanChange"
              @roleschange="onRolesChange"
            ></SchedulingDetails>
          </el-tab-pane>
          <el-tab-pane label="内部管理" name="second">
            <InsideDetails
              :listRecordsData="treeRecords.items"
              :tabData="tabData"
              :loading="loading"
              :departmentData="departmentCategory.items"
              :rolesData="rolesCategory.items"
              @getleftchangeone="onGetLeftChangeOne"
              @handledelitem="onDelSelectItem"
              @submititemdata="onSubmitExamination"
              @handablechange="onHandAbleChange"
              :systemKeywordsData="systemKeywords.items"
            ></InsideDetails>
          </el-tab-pane>
          <el-tab-pane label="营销管理" name="fourth">
            <MarketingDetails
              :listRecordsData="treeRecords.items"
              :tabData="tabData"
              :loading="loading"
              @getleftchangeTwo="onGetLeftChangeTwo"
              @handledelitem="onDelSelectItem"
              @submititemdata="onSubmitExamination"
            ></MarketingDetails>
          </el-tab-pane>
          <el-tab-pane label="库存设置" name="five">
            <StockDetails
              :listRecordsData="treeRecords.items"
              :tabData="tabData"
              @getleftchangethree="onGetLeftChangeThree"
              @handledelitem="onDelSelectItem"
              @submititemdata="onSubmitExamination"
              :loading="loading"
            ></StockDetails>
          </el-tab-pane>
          <el-tab-pane label="病史设置" name="sex">
            <MedicalHistoryDetails
              :treeDatas="treeRecords.items"
              :tabData="tabData"
              :loading="loading"
              @submitexamination="onSubmitExamination"
              @delselectitem="onDelSelectItem"
              @getleftchangefour="onGetLeftChangeFour"
            ></MedicalHistoryDetails>
          </el-tab-pane>
          <el-tab-pane label="看诊项管理" name="seven">
            <DiagnosisDetails
              :treeDatas="treeRecords.items"
              :tabData="tabData"
              :loading="loading"
              @submitexamination="onSubmitExamination"
              @delselectitem="onDelSelectItem"
              @getleftchangefive="onGetLeftChangeFive"
            ></DiagnosisDetails>
          </el-tab-pane>
          <el-tab-pane label="保险管理" name="eight">
            <InsuranceDetails
              :treeDatas="treeRecords.items"
              :tabData="tabData"
              :loading="loading"
              @submitexamination="onSubmitExamination"
              @delselectitem="onDelSelectItem"
              @getleftchangesix="onGetLeftChangeSix"
              :companyDatas="companyRecord.items"
              @selectlevelitem="onSelectLevelItem"
              :levelDatas="levelRecords.items"
            ></InsuranceDetails>
          </el-tab-pane>
          <el-tab-pane label="药品项管理" name="nine">
            <DrugDetails
              :listRecordsData="treeRecords.items"
              :tabData="tabData"
              @getleftchangeseven="onGetLeftChangeSeven"
              @handledelitem="onDelSelectItem"
              @submititemdata="onSubmitExamination"
              :loading="loading"
            ></DrugDetails>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </ConfigurationLayout>
</template>

<script>
import { components, layouts, mock } from "@/helper";
import { showLoading, hideLoading } from "../utils/loading";

export default {
  components: {
    ...layouts(["Configuration"]),
    ...components(
      [
        "Personnel.Details",
        "Scheduling.Details",
        "Inside.Details",
        "Hospital.Details",
        "Staff.Main",
        "Stock.Details",
        "Marketing.Details",
        "Insurance.Details",
        "MedicalHistory.Details",
        "Diagnosis.Details",
        "Drug.Details"
      ],
      "configuration"
    ),
  },

  data() {
    return {
      loading: true,
      activeName: "first",
      treeRecords: {},
      departmentCategory: {},
      rolesCategory: {},
      examinationTemplateTreeRecords: {},
      tabData: 0,
      saveUrl: "",
      delUrl: "",
      getPlanUrl: "",
      cateUrl: "",
      rolesUrl: "",
      getLevelUrl: "",
      typeParam: {
        type: 0,
      },
      systemKeywordsUrl: "",
      systemKeywords: {},
      listRecordsOne: {},
      companyRecord: {}, //保险公司
      levelRecords: {},
      rolesRecords: {},
      schedulinngRecords: {},
      classesRecords: {},
      schedulingRolesID: 0,
      doctorRecords: {},
      MondayTime: "",
      todaySchedulinngRecords: {},
      planParam: {
        doctor_id: undefined,
        new_data: undefined,
        scheduling_date: undefined,
        classes_id: undefined,
        create_id: 1,
        roles_id: 1,
      },
    };
  },
  mounted() {
    let that = this;

    that.weekCheckingIn(); //获取当前周一时间
    //that.formatDate();

    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    that.planParam.create_id = that.userInfo.client_id;
    // that.onGetRolesList();
    // that.onGetUserList();
    // that.onGetSchedulinngRecords();

    // 模版数据请求
    that.initTemplate();
  },
  watch: {
    schedulingRecordss: function (val) {},
  },
  methods: {
    async initTemplate() {
      let me = this;
      //me.onGetRolesList();
      me.onDepartmentRecords();
      me.onSchedulingList();
      me.onGetUserList(1);
      me.onGetSchedulinngRecords(1);
    },
    //********************************************tabs*************************************************************************** */

    handleClick(tab, event) {
      let me = this;
      switch (Number(tab.index)) {
        case 0:
          me.onDepartmentRecords();
          break;
        case 1:
          me.tabData = 1;
          me.onGetLeftChangeOne(1);
          break;
        case 2:
          me.tabData = 1;
          me.onGetLeftChangeTwo(1);
          break;
        case 3:
          me.tabData = 1;
          me.onGetLeftChangeThree(1);
          break;
        case 4:
          me.tabData = 1;
          me.onGetLeftChangeFour(1);
          break;
        case 5:
          me.tabData = 1;
          me.onGetLeftChangeFive(1);
          break;
        case 6:
          me.tabData = 1;
          me.onGetLeftChangeSix(1);
          break;
        case 7:
          me.tabData = 1;
          me.onGetLeftChangeSeven(1);
          break;
          
      }
    },

    //*********************************************排班start*************************************************************************** */
    // 排班
    onSchedulingList(val) {
      let me = this;
      me.tabData = 1;
      me.getUrl = "configuration/Inside/Classes/getRecords";

      let data = {
        department_id: val,
        status: 0,
      };
      me.getTreeRecords("classesRecords", me.getUrl, data);
      //if (me.schedulingRecords.Data.length) {
      //me.schedulingRolesID = me.schedulingRecords.items[0].roles_id;
      //}
    },
    //角色
    onGetRolesList() {
      let me = this;
      me.tabData = 1;
      me.getUrl = "configuration/Inside/Roles/getRecords";

      let data = {
        status: 0,
        scheduling_status: 0,
      };
      me.getTreeRecords("rolesRecords", me.getUrl, data);
    },
    //根据角色拉取用户数据
    onGetUserList(val) {
      let me = this;
      me.planParam.department_id = val;
      me.tabData = 1;
      me.getUrl = "configuration/Inside/Doctor/getRecordsList";

      let data = {
        department_id: val,
        reducibl: 1,
      };
      me.getTreeRecords("doctorRecords", me.getUrl, data);
    },
    //拉取排班记录表
    onGetSchedulinngRecords(val) {
      let me = this;

      me.tabData = 1;
      me.getUrl = "configuration/ClassesScheduling/getRecords";

      let data = {
        department_id: val,
        new_data: me.MondayTime,
      };
      me.getTreeRecords("schedulinngRecords", me.getUrl, data);
    },
    //部门
    onDepartmentRecords() {
      let me = this;
      let data = {
        scheduling_status: 1,
      };
      me.getUrl = "configuration/Inside/Department/getRecords";
      me.getTreeRecords("departmentCategory", me.getUrl, data);
    },

    //点击空白处方法
    onSchedulingPlan(data) {
      let me = this;
      me.planParam.doctor_id = data.doctor_id;
      me.planParam.scheduling_date = data.scheduling_date;
      me.getUrl = "configuration/ClassesScheduling/getTodayRecords";
      me.getTreeRecords("todaySchedulinngRecords", me.getUrl, me.planParam);
    },

    onClassesPlanChange(val, departmentValue) {
      let me = this;
      me.planParam.classes_id = val;
      me.planParam.department_id = departmentValue;
      me.tabData = 0;
      me.setUrl = "configuration/ClassesScheduling/setItem";
      me.getUrl = "configuration/ClassesScheduling/getRecords";
      me.getPlanUrl = "configuration/ClassesScheduling/getTodayRecords";
      me.setPublicItem(me.setUrl, "schedulinngRecords", me.planParam);
      //  let data={
      //    doctor_id: me.planParam.doctor_id,
      //    scheduling_date:me.planParam.scheduling_date,
      //  }
      //  me.onSchedulingPlan(data);
      // me.getTreeRecords("todaySchedulinngRecords", me.getPlanUrl, me.planParam);
    },
    onRolesChange(val) {
      let me = this;
      me.onGetUserList(val);
      me.onGetSchedulinngRecords(val);
      me.onSchedulingList(val);
    },

    //*********************************************内部管理 start*************************************************************************** */
    /**
     * 内部管理
     * @param val
     */
    onGetLeftChangeOne(val) {
      let me = this;
      me.tabData = val;

      switch (Number(val)) {
        case 1: // 部门管理
          me.getUrl = "configuration/Inside/Department/getRecords";
          me.saveUrl = "configuration/Inside/Department/setItem";
          me.delUrl = "configuration/Inside/Department/delItem";
          //me.getTreeRecords("departmentCategory", me.getUrl);
          break;
        case 2: // 角色管理
          me.getUrl = "configuration/Inside/Roles/getRecords";
          me.saveUrl = "configuration/Inside/Roles/setItem";
          me.delUrl = "configuration/Inside/Roles/delItem";
          me.systemKeywordsUrl = "system/keywords";
          me.getTreeRecords("systemKeywords", me.systemKeywordsUrl);
          break;
        case 3: // 员工管理
          me.cateUrl = "configuration/Inside/Department/getRecords";
          me.rolesUrl = "configuration/Inside/Roles/getRecords";
          me.getTreeRecords("rolesCategory", me.rolesUrl);

          me.getUrl = "configuration/Inside/Doctor/getRecords";
          me.saveUrl = "configuration/Inside/Doctor/setItem";
          me.delUrl = "configuration/Inside/Doctor/delItem";
          me.typeParam.type = 1; // 员工
          break;
        case 4: // 班次管理
          me.getUrl = "configuration/Inside/Classes/getRecords";
          me.saveUrl = "configuration/Inside/Classes/setItem";
          me.delUrl = "configuration/Inside/Classes/delItem";
          break;
        case 5: // 客户来源
          me.getUrl = "configuration/Inside/Source/getRecords";
          me.saveUrl = "configuration/Inside/Source/setItem";
          me.delUrl = "configuration/Inside/Source/delItem";
          break;
        case 6: // 回访设置
          me.getUrl = "configuration/Inside/Returnvisit/getRecords";
          me.saveUrl = "configuration/Inside/Returnvisit/setItem";
          me.delUrl = "configuration/Inside/Returnvisit/delItem";
          break;
        case 7: // 门店管理
          me.getUrl = "configuration/Inside/Store/getList";
          me.saveUrl = "configuration/Inside/Store/setItem";
          me.delUrl = "configuration/Inside/Store/delItem";
          break;
        case 8: // ? 标签管理
          break;
        case 9: // 管理员管理
          me.getUrl = "configuration/Inside/Doctor/getRecords";
          me.saveUrl = "configuration/Inside/Doctor/setItem";
          me.delUrl = "configuration/Inside/Doctor/delItem";
          me.typeParam.type = 2; //管理员
          break;
        default:
          break;
      }

      me.getTreeRecords("treeRecords", me.getUrl, me.typeParam);
    },

    onHandAbleChange(data) {
      let me = this;
      switch (data.change) {
        case "roles":
          me.tabData = 2;
          me.ableUrl = "configuration/Inside/Roles/setReducibleItem";
          break;
        case "doctor":
          me.tabData = 3;
          me.ableUrl = "configuration/Inside/Doctor/setReducibleItem";
          break;
      }

      me.setPublicItem(me.ableUrl, "treeRecords", data);
    },

    //*********************************************营销管理 start*************************************************************************** */
    onGetLeftChangeTwo(val) {
      let me = this;
      if (val === 1) {
        //检验检查
        me.tabData = 1;
        me.getUrl = "configuration/Marketing/Level/getRecords";
        me.saveUrl = "configuration/Marketing/Level/setItem";
        me.delUrl = "configuration/Marketing/Level/delItem";
      } else if (val === 2) {
        //检验检查
        me.tabData = 2;
        me.getUrl = "configuration/Marketing/Activities/getRecords";
        me.saveUrl = "configuration/Marketing/Activities/setItem";
        me.delUrl = "configuration/Marketing/Activities/delItem";
      } else if (val === 5) {
        //检验检查
        me.tabData = 5;
        me.getUrl = "configuration/Marketing/Promotion/getRecords";
        me.saveUrl = "configuration/Marketing/Promotion/setItem";
        me.delUrl = "configuration/Marketing/Promotion/delItem";
      }
      me.getTreeRecords("treeRecords", me.getUrl);
    },

    //*********************************************营销管理 start*************************************************************************** */
    onGetLeftChangeThree(val) {
      let me = this;
      if (val === 1) {
        //检验检查
        me.tabData = 1;
        me.getUrl = "configuration/Stock/Storehouse/getRecords";
        me.saveUrl = "configuration/Stock/Storehouse/setItem";
        me.delUrl = "configuration/Stock/Storehouse/delItem";
      } else if (val === 2) {
        //检验检查
        me.tabData = 2;
        me.getUrl = "configuration/Stock/Pharmacy/getRecords";
        me.saveUrl = "configuration/Stock/Pharmacy/setItem";
        me.delUrl = "configuration/Stock/Pharmacy/delItem";
      } else if (val === 3) {
        //检验检查
        me.tabData = 3;
        me.getUrl = "configuration/Stock/Brand/getRecords";
        me.saveUrl = "configuration/Stock/Brand/setItem";
        me.delUrl = "configuration/Stock/Brand/delItem";
      } else if (val === 4) {
        //检验检查
        me.tabData = 4;
        me.getUrl = "configuration/Stock/Supplier/getRecords";
        me.saveUrl = "configuration/Stock/Supplier/setItem";
        me.delUrl = "configuration/Stock/Supplier/delItem";
      } else if (val === 5) {
        //检验检查
        me.tabData = 5;
        me.getUrl = "configuration/Stock/Factory/getRecords";
        me.saveUrl = "configuration/Stock/Factory/setItem";
        me.delUrl = "configuration/Stock/Factory/delItem";
      } else if (val === 6) {
        //检验检查
        me.tabData = 6;
        me.typeParam.type = 1;
        me.getUrl = "configuration/Stock/Attribute/getRecords";
        me.saveUrl = "configuration/Stock/Attribute/setItem";
        me.delUrl = "configuration/Stock/Attribute/delItem";
      } else if (val === 7) {
        //检验检查
        me.tabData = 7;
        me.typeParam.type = 2;
        me.getUrl = "configuration/Stock/Attribute/getRecords";
        me.saveUrl = "configuration/Stock/Attribute/setItem";
        me.delUrl = "configuration/Stock/Attribute/delItem";
      } else if (val === 8) {
        //检验检查
        me.tabData = 8;
        me.getUrl = "configuration/Stock/Warehouse/getRecords";
        me.saveUrl = "configuration/Stock/Warehouse/setItem";
        me.delUrl = "configuration/Stock/Warehouse/delItem";
      } else if (val === 9) {
        //检验检查
        me.tabData = 9;
        me.getUrl = "configuration/Stock/Unit/getRecords";
        me.saveUrl = "configuration/Stock/Unit/setItem";
        me.delUrl = "configuration/Stock/Unit/delItem";
        me.typeParam.type = 1;
      } else if (val === 10) {
        //检验检查
        me.tabData = 10;
        me.getUrl = "configuration/Stock/Unit/getRecords";
        me.saveUrl = "configuration/Stock/Unit/setItem";
        me.delUrl = "configuration/Stock/Unit/delItem";
        me.typeParam.type = 2;
      }

      me.getTreeRecords("treeRecords", me.getUrl, me.typeParam);
    },

    //*********************************************库存 start*************************************************************************** */
    onGetLeftChangeFour(val) {
      let me = this;

      me.tabData = val;
      me.getUrl = "configuration/History/getRecords";
      me.saveUrl = "configuration/History/setItem";
      me.delUrl = "configuration/History/delItem";

      me.typeParam = {
        type: val,
        param: true,
      };

      me.getTreeRecords("treeRecords", me.getUrl, me.typeParam);
    },

    //*********************************************看诊项管理 start*************************************************************************** */
    /**
     * 看诊项管理
     * @param menuIndex   Left-菜单点选索引值
     */
    onGetLeftChangeFive(menuIndex) {
      let me = this;
      me.tabData = Number(menuIndex);

      switch (me.tabData) {
        case 1: // 预约设置 appointment_setting
          me.getUrl = "configuration/Appointment/Setting/getRecords";
          me.saveUrl = "configuration/Appointment/Setting/setItem";
          me.delUrl = "configuration/Appointment/Setting/delItem";
          break;
        case 2: // 检验检查 examination_template
          me.getUrl = "configuration/Appointment/examinationtemplate/getTreeRecords";
          me.saveUrl =
            "configuration/Appointment/examinationtemplate/setExaminationTemplateItem";
          me.delUrl =
            "configuration/Appointment/examinationtemplate/delExaminationTemplateItem";
          break;
        case 3: // 治疗项目 treatment_template
          me.getUrl = "configuration/Appointment/TreatmentTemplate/getTreeRecords";
          me.saveUrl =
            "configuration/Appointment/TreatmentTemplate/setTreatmentTemplateItem";
          me.delUrl =
            "configuration/Appointment/TreatmentTemplate/delTreatmentTemplateItem";
          break;
        case 4: // 诊断管理 diagnosis_template
          me.getUrl = "configuration/Appointment/DiagnosisTemplate/getTreeRecords";
          me.saveUrl =
            "configuration/Appointment/DiagnosisTemplate/setDiagnosisTemplateItem";
          me.delUrl =
            "configuration/Appointment/DiagnosisTemplate/delDiagnosisTemplateItem";
          break;
        case 5: // 科室管理 clinical_department
          me.getUrl = "configuration/Appointment/ClinicalDepartment/getTreeRecords";
          me.saveUrl =
            "configuration/Appointment/ClinicalDepartment/setClinicalDepartmentItem";
          me.delUrl =
            "configuration/Appointment/ClinicalDepartment/delClinicalDepartmentItem";
          break;
        case 6: // 主诉管理 complained_template
          me.getUrl = "configuration/Appointment/ComplainedTemplate/getTreeRecords";
          me.saveUrl =
            "configuration/Appointment/ComplainedTemplate/setComplainedTemplateItem";
          me.delUrl =
            "configuration/Appointment/ComplainedTemplate/delComplainedTemplateItem";

          break;
        default:
          break;
      }

      me.getTreeRecords("treeRecords", me.getUrl,me.typeParam);
    },

    //****************************************x*****看诊项管理 start*************************************************************************** */
    onGetLeftChangeSix(val) {
      let me = this;
      if (val === 1) {
        //检验检查
        me.tabData = 1;
        me.getUrl = "configuration/Insurnace/Company/getRecords";
        me.saveUrl = "configuration/Insurnace/Company/setItem";
        me.delUrl = "configuration/Insurnace/Company/delItem";
      } else if (val === 2) {
        //检验检查
        me.tabData = 2;
        me.getUrl = "configuration/Insurnace/Level/getRecords";
        me.saveUrl = "configuration/Insurnace/Level/setItem";
        me.delUrl = "configuration/Insurnace/Level/delItem";
        me.getCompanyUrl = "configuration/Insurnace/Company/getRecords";
        me.getTreeRecords("companyRecord", me.getCompanyUrl);
      } else if (val === 3) {
        //检验检查
        me.tabData = 3;
        me.getUrl = "configuration/Insurnace/Setting/getRecords";
        me.saveUrl = "configuration/Insurnace/Setting/setItem";
        me.delUrl = "configuration/Insurnace/Setting/delItem";
        me.getCompanyUrl = "configuration/Insurnace/Company/getRecords";
        me.getTreeRecords("companyRecord", me.getCompanyUrl);
      } else if (val === 4) {
        //检验检查
        me.tabData = 4;
        me.getUrl = "configuration/Insurnace/User/getRecords";
        me.saveUrl = "configuration/Insurnace/User/setItem";
        me.delUrl = "configuration/Insurnace/User/delItem";
      } else if (val === 5) {
        //检验检查
        me.tabData = 5;
        me.getUrl = "configuration/Insurnace/Account/getRecords";
        me.saveUrl = "configuration/Insurnace/Account/setItem";
        me.delUrl = "configuration/Insurnace/Account/delItem";
      }
      me.getTreeRecords("treeRecords", me.getUrl);
    },

    //*********************************************药品项管理 start*************************************************************************** */
    onGetLeftChangeSeven(val) {
      let me = this;
      if (val === 1) {
        //用法
        me.tabData = 1;
        me.typeParam.type = 1;
        me.getUrl = "configuration/DrugsSetting/getRecords";
        me.saveUrl = "configuration/DrugsSetting/setItem";
        me.delUrl = "configuration/DrugsSetting/delItem";
      } else if (val === 2) {
        //用量
        me.tabData = 2;
        me.typeParam.type = 2;
        me.getUrl = "configuration/DrugsSetting/getRecords";
        me.saveUrl = "configuration/DrugsSetting/setItem";
        me.delUrl = "configuration/DrugsSetting/delItem";
      } else if (val === 3) {
        //禁忌
        me.tabData = 3;
        me.typeParam.type = 3;
        me.getUrl = "configuration/DrugsSetting/getRecords";
        me.saveUrl = "configuration/DrugsSetting/setItem";
        me.delUrl = "configuration/DrugsSetting/delItem";
      } else if (val === 4) {
        //医嘱
        me.tabData = 4;
        me.typeParam.type = 4;
        me.getUrl = "configuration/DrugsSetting/getRecords";
        me.saveUrl = "configuration/DrugsSetting/setItem";
        me.delUrl = "configuration/DrugsSetting/delItem";
      } 

      me.getTreeRecords("treeRecords", me.getUrl, me.typeParam);
    },

    //****************************************x*****保险管理 start*************************************************************************** */
    onSelectLevelItem(data) {
      let me = this;
      me.getLevelUrl = "configuration/Insurnace/Level/getRecords";
      me.getTreeRecords("levelRecords", me.getLevelUrl, data);
    },

    onSubmitExamination(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.setPublicItem(me.saveUrl, "treeRecords", data);
        })
        .catch();
    },
    onDelSelectItem(data) {
      let me = this;
      me.$confirm("确认删除信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.delPublicItem(me.delUrl, "treeRecords", data);
        })
        .catch();
    },

    /**
     * 公共函数：异步请求执行函数
     * @description 根据传入参数执行请求，获取数据并完成页面参数赋值
     * @param param 请求结果赋值参数名称
     * @param url   请求mock地址
     * @param data  请求携带参数
     * @returns {Promise<void>}
     */
    async getTreeRecords(param, url, data) {
      showLoading();
      let me = this;

      // 过渡变量
      let _data = {
        ...data,
        type: !Boolean(data) || !Boolean(data.type) ? "" : data.type,
      };

      try {
        let res = await mock(url, _data);
        if (res.status === 200 || res.length > 0) {
          me[param] = res.data;
          if (!Boolean(res.data)) {
            me[param] = res; //获取的数据赋值给变量参数；
          }
        }
        me.loading = false;
        hideLoading();
      } catch (err) {
        me.$message({
          showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    // 保存看诊项记录ExaminationTemplate表
    async setPublicItem(url, param, data) {
      let me = this;
      try {
        let res = await mock(url, data);
        if (res.status === 200) {
          me.$message({
            showClose: true,
            type: "success",
            message: "保存成功!",
          });
          // me.onGetLeftChange(fun);
          await me.getTreeRecords(param, me.getUrl, me.typeParam);
        } else {
          me.$message({
            showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
          showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    async delPublicItem(url, param, data) {
      let me = this;
      try {
        let res = await mock(url, data);
        if (res.status === 200) {
          me.$message({
            showClose: true,
            type: "success",
            message: "保存成功!",
          });
          // me.onGetLeftChange(fun);
          await me.getTreeRecords("treeRecords", me.getUrl, me.typeParam);
        } else {
          me.$message({
            showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
          showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    //*************************************************看诊项管理 end*********************************************************************** */
    //获取当周的周一和周日日期
    weekCheckingIn() {
      let me = this;
      // me.weekChecking_inloading = true;
      let now = new Date();
      let nowTime = now.getTime();
      let day = now.getDay() || 7; // 不加 || 7.周末会变成下周一
      let oneDayTime = 24 * 60 * 60 * 1000;
      let MondayTime = nowTime - (day - 1) * oneDayTime; //显示周一
      //var SundayTime = nowTime + (7 - day) * oneDayTime; //显示周日
      //调用方法
      me.MondayTime = me.formatWeek(new Date(MondayTime));
      me.planParam.new_data = me.formatWeek(new Date(MondayTime));
    },

    formatWeek(date) {
      let myYear = date.getFullYear();
      let myMonth = date.getMonth() + 1;
      let myWeekday = date.getDate();
      if (myMonth < 10) {
        myMonth = "0" + myMonth;
      }
      if (myWeekday < 10) {
        myWeekday = "0" + myWeekday;
      }
      return myYear + "-" + myMonth + "-" + myWeekday;
    },
  },
};
</script>

<style lang="scss" scoped></style>
