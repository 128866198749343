<template>
  <div style="height: 100%; overflow: auto">
    <Container class="see-detail hb-layout-vertical">
      <!--左：顶部搜索-->
      <Header
        @submit="handleSubmit"
        ref="header"
        :loading="loading"
        @submitreset="onSubmitReset"
        @todayclick="onTodayClick"
        @weekclick="onWeekClick"
        @monthclick="onMonthClick"
        @yearclick="onYearClick"
        :brandDatas="brandDatas"
        :supplierDatas="supplierDatas"
        :factoryDatas="factoryDatas"
        :pharmacyCateDatas="pharmacyCateDatas"
         @submitadddrugs="onSubmitAddDrugs"
         :smallunitDatas="smallunitDatas"
      ></Header>
      <Content
        ref="content"
        :loading="loading"
         @submitadddrugs="onSubmitAddDrugs"
        :tableData="Data.items"
        @changeeditdrugs="onChangeEditDrugs"
        :pharmacyItem="pharmacyItem"
        @submiteditstorehouse="onSubmitEditStorehouse"
        :brandDatas="brandDatas"
        :supplierDatas="supplierDatas"
        :factoryDatas="factoryDatas"
        :pharmacyCateDatas="pharmacyCateDatas"
        :smallunitDatas="smallunitDatas"
        @delselectitem="onDelSelectItem"
      ></Content>
      <BasicFooter
        :loading="loading"
        :size="Param.page_size"
        :total="Data.total_result"
        @change="handlePaginationChange"
      ></BasicFooter>
    </Container>
  </div>
</template>

<script>
import { components, blocks } from "@/helper";

export default {
  components: {
    ...components(["Header", "Content"], "pharmacy.Drugs.Left"),
    ...components(["Container"]),
    ...blocks(["BasicFooter"], "Footer"),
    ...blocks(["BasicPagination"], "Pagination"),
  },

  props: {
    loading: {
      default: false,
    },
    pharmacyCateDatas: {},
    brandDatas: {},
    supplierDatas: {},
    factoryDatas: {},
    storehouseDatas: {},
    Data:{},
    pharmacyItem: {},
    Param:{},
    smallunitDatas:{}
    
  },

  data() {
    return {
      page: {
        current: 1,
        size: 0,
        total: 0,
      },
      order: {
        order_type: undefined,
        order_field: undefined,
      },
    };
  },

  methods: {
      // 分页变化改动
    handlePaginationChange(val) {
      //this.page = val;
      this.$emit("changepagination", val);
      //   this.$nextTick(() => {
      //     this.$refs.header.handleFormSubmit();
      //   });
    },
    onSubmitAddDrugs(data) {
      let me = this;
      me.$emit("submitadddrugs", data);
     // console.log("pe3");
    },
    onChangeEditDrugs(data) {
      let me = this;
      me.$emit("changeeditdrugs", data);
    },
    onDelSelectItem(data){
      this.$emit("delselectitem", data);
    },
    onSubmitEditStorehouse(data) {
      let me = this;
      me.$emit("submiteditstorehouse", data);
    },
      // 确定查询
    handleSubmit(form, isRestore = false) {
      this.$emit("changesubmit", form, isRestore);
      //   if (isRestore) {
      //     this.page.current = 1;
      //   }

      //   this.loading = true;
      //   getTransactionList({
      //     ...form,
      //     ...this.order,
      //     page_no: this.page.current,
      //     page_size: this.page.size,
      //   })
      //     .then((res) => {
      //       this.table = res.data.items || [];
      //       this.page.total = res.data.total_result;
      //     })
      //     .finally(() => {
      //       this.loading = false;
      //     });
    },
      onSubmitReset(data) {
      let me = this;
      me.$emit("submitreset",data);
    },
    onTodayClick() {
      let me = this;
      me.$emit("todayclick");
    },
    onWeekClick() {
      let me = this;
      me.$emit("weekclick");
   
    },
    onMonthClick() {
      let me = this;
      me.$emit("monthclick");
    },
    onYearClick() {
      let me = this;
      me.$emit("yearclick");
    },
    changeDrawer(v) {
       let me = this;
      //console.log("112");
       me.$refs.header.changeDrawer(v);
       me.$refs.content.changeDrawers(v);
    },
  },
};
</script>

<style lang="scss" scoped></style>
