import { render, staticRenderFns } from "./ClinicScheduling.vue?vue&type=template&id=0dbc2285&scoped=true&"
import script from "./ClinicScheduling.vue?vue&type=script&lang=js&"
export * from "./ClinicScheduling.vue?vue&type=script&lang=js&"
import style0 from "./ClinicScheduling.vue?vue&type=style&index=0&id=0dbc2285&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0dbc2285",
  null
  
)

export default component.exports