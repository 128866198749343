<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container class="patient-detail-doctor">
      <div class="details">
        <el-card>
          <Container class="see-patient-history-list-item hb-layout-vertical">
            <Container class="body">
              <Container class="inner hb-layout-horizontal">
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  物资名称</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.name"
                        placeholder="请输入物资名称"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">英文名称(全)</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.english_name"
                        placeholder="英文名称(全)"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">英文名称(简)</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.en_name"
                        placeholder="英文名称(简)"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  物资类目</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.storehouse_category_id"
                        filterable
                        style="width: 100%"
                        placeholder="请选择物资类目"
                      >
                        <el-option
                          v-for="item in storehouseCateDatas"
                          :key="item.storehouse_category_id"
                          :label="item.name"
                          :value="item.storehouse_category_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">生产厂家</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.factory_id"
                        filterable
                        placeholder="请选择生产厂家"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in factoryDatas"
                          :key="item.factory_id"
                          :label="item.name"
                          :value="item.factory_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">供应商</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.supplier_id"
                        filterable
                        placeholder="请选择供应商"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in supplierDatas"
                          :key="item.supplier_id"
                          :label="item.name"
                          :value="item.supplier_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">品牌</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.brand_id"
                        filterable
                        placeholder="请选择品牌"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in brandDatas"
                          :key="item.brand_id"
                          :label="item.name"
                          :value="item.brand_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">国药准字</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.storehouse_code"
                        placeholder="请输入国药准字"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">批准文号</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.approval_number"
                        placeholder="请输入批准文号"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">生产批号</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.batch_number"
                        placeholder="请输入生产批号"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">药品编号</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.drug_number"
                        placeholder="请输入药品编号"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">条形码</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.bar_code"
                        placeholder="请输入条形码"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">用途</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.purpose"
                        placeholder="请输入用途"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">用量</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.dosage"
                        placeholder="请输入用量"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>

                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  单位</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.unit_id"
                        filterable
                        placeholder="请选择单位"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in unitData"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  规格数量</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                      v-model="drugsParam.small_unit_num"
                      placeholder="请输入规格数量"
                    ></el-input>
                    </div>
                  </el-col>
                </el-row>

                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  规格单位</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.small_unit_id"
                        filterable
                        placeholder="请选择规格单位"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in smallunitData"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">最小制剂单位</div>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="drugsParam.mini_unit"
                      placeholder="最小制剂单位"
                    ></el-input>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  进价(元)</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.trade_price"
                        placeholder="请输入进价(元)"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  售价(元)</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.retail_price"
                        placeholder="请输入售价(元)"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>

                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">库存预警值</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.stock_warning"
                        placeholder="请输入库存预警值"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">启用</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light" style="padding-left: 15px">
                      <el-radio v-model="drugsParam.status" label=1>启用</el-radio>
                      <el-radio v-model="drugsParam.status" label=0>不启用</el-radio>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">备注</div>
                  </el-col>
                  <el-col :span="21">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.remarks"
                        placeholder="请输入备注"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
              </Container>
              <div class="btn">
                <el-button type="info" class="edit-buttom">取消</el-button>
                <el-button
                  type="success"
                  class="edit-buttom"
                  @click="submitEditStorehouse"
                  >确定</el-button
                >
              </div>
            </Container>
          </Container>
        </el-card>
      </div>
    </Container>
  </el-drawer>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    storehouseCateDatas: {},
    brandDatas: {},
    supplierDatas: {},
    factoryDatas: {},
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    storehouseRecordItem: {},
  },
  data() {
    return {
      drugsParam: {
        storehouse_id: "",
        name: "", // 药品名称
        english_name: "", // 药品名称
        en_name: "", // 药品名称
        storehouse_category_id: "", //药品分类
        factory_id: "", //生产厂家
        supplier_id: "", //供应商
        brand_id: "", //品牌
        storehouse_code: "", //国药准字
        approval_number: "", //批准文号
        batch_number: "", // 生产批号
        drug_number: "", //药品编号
        bar_code: "", //条形码
        purpose: "", //用途
        dosage: "", //用量
        unit_id: "请选择单位", //单位
        small_unit_id: "请选择单位", //最小单位//规格制剂单位
        taboo: "", //禁忌
        effective_date: "", //有效期
        small_unit_num: "",
        trade_price: "", //批发价
        retail_price: "", //零售价
        mini_unit: "", //最小制剂单位
        stock_warning: "", //库存预警值
        remarks: "",
        status: "",
      },
      storehouseCategory: [
        { value: 1, label: "产品" },
        { value: 2, label: "注射" },
        { value: 3, label: "耗材" },
        { value: 4, label: "办公用品" },
        { value: 5, label: "其它" },
      ],
      factoryData: [
        { value: 1, label: "广东恒健制药有限公司" },
        { value: 2, label: "福元药业股份有限公司" },
        { value: 3, label: "北京同仁堂大药房" },
        { value: 4, label: "哈药集团三精制药有限公司" },
      ],
      supplierData: [
        { value: 1, label: "苏州海德大药房" },
        { value: 2, label: "上海海德大药房" },
      ],
      brandData: [
        { value: 2, label: "999" },
        { value: 3, label: "北京同仁堂" },
        { value: 4, label: "21金维他" },
        { value: 5, label: "哈药" },
        { value: 6, label: "奥诺" },
        { value: 7, label: "朗迪" },
      ],
      unitData: [
        { value: 1, label: "箱" },
        { value: 2, label: "盒" },
      ],
      smallunitData: [
        { value: 3, label: "支" },
        { value: 4, label: "针" },
      ],
      effectiveData: [
        { value: 1, label: "6个月" },
        { value: 2, label: "12个月" },
        { value: 3, label: "18个月" },
        { value: 4, label: "24个月" },
        { value: 5, label: "36个月" },
      ],
      zh: "",
      en: "",
      dr: "",
      typeValue: [], // 药品类型
      manufactor: "", // 厂家
      licence: "", // 国药准字
      barCode: "", // 条形码
      dose: "", // 剂量
      preparation: "", // 制剂
      packing: "", // 包装
      purchasePrice: "", // 进价
      amountSold: "", // 售价
      drawer: false,
    };
  },
  watch: {
    storehouseRecordItem: function (newVal, oldVal) {
      //   console.log("wathc", newVal);
      //   console.log("wathco", oldVal);
      if (newVal == null) {
        this.drugsParam.name = ""; //体温
        this.drugsParam.english_name = ""; //呼吸
        this.drugsParam.en_name = ""; //血压
      } else {
        this.drugsParam.storehouse_id = newVal.storehouse_id;
        this.drugsParam.name = newVal.name; //药品名称
        this.drugsParam.english_name = newVal.english_name; //英文名称(全)
        this.drugsParam.en_name = newVal.en_name; //英文名称(简)
        this.drugsParam.storehouse_category_id = newVal.storehouse_category_id; //药品分类
        this.drugsParam.factory_id = newVal.factory_id==0?"":newVal.factory_id; //生产厂家
        this.drugsParam.supplier_id = newVal.supplier_id==0?"":newVal.supplier_id; //供应商
        this.drugsParam.brand_id = newVal.brand_id==0?"":newVal.brand_id; //品牌
        this.drugsParam.storehouse_code = newVal.storehouse_code; //国药准字
        this.drugsParam.approval_number = newVal.approval_number; //批准文号
        this.drugsParam.batch_number = newVal.batch_number; //生产批号
        this.drugsParam.drug_number = newVal.drug_number; //药品编号
        this.drugsParam.bar_code = newVal.bar_code; //条形码
        this.drugsParam.purpose = newVal.purpose; //用途
        this.drugsParam.dosage = newVal.dosage; //用量
        this.drugsParam.unit_id = newVal.unit_id; //单位
        this.drugsParam.small_unit_num = newVal.small_unit_num; //规格
        this.drugsParam.small_unit_id = newVal.small_unit_id; //规格
        this.drugsParam.mini_unit = newVal.mini_unit; //最小制剂单位
        this.drugsParam.trade_price = newVal.trade_price; //批发价
        this.drugsParam.retail_price = newVal.retail_price; //批发价
        this.drugsParam.stock_warning = newVal.stock_warning; //库存预警值
        this.drugsParam.status = newVal.status.toString(); //启用
        this.drugsParam.remarks = newVal.remarks; //启用
      }
    },
  },
  methods: {
    submitEditStorehouse() {
      let me = this;
      me.$emit("submitmaterial", me.drugsParam);
    },
    changeDrawer(v, val) {
      console.log("112", v, val);
      this.drawer = v;
      this.drugsParam.pharmacy_id = val;
    },
    changeDrawers(val) {
      this.drawer = val;
    },
  },
};
</script>

<style lang="scss" scoped>
// 新增回访
.see-patient-history-list-item {
  width: 100%;
  border: 1px solid #e1e1e1;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;

    .edit-buttom {
      // width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
