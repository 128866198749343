<template>
  <Container class="statistics">
    <div class="text item">
      <div class="total">
        <div class="total-amount">
          <el-image class="image" src="../../image/pay.png"></el-image>
          <div class="content">
              <div class="total-number" v-if="statisticsData && statisticsData.total_price>0">{{statisticsData.total_price}}</div>
            <div class="total-name">总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image img" src="../../image/ywc.png"></el-image>
          <div class="content">
        
              <div class="total-number" v-if="statisticsData && statisticsData.count_settlement_1>0">{{statisticsData.count_settlement_1}}</div>
            <div class="total-name">已交</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image img" src="../../image/wwc.png"></el-image>
          <div class="content">
        
             <div class="total-number" v-if="statisticsData && statisticsData.count_settlement_0>0">{{statisticsData.count_settlement_0}}</div>
            <div class="total-name">未交</div>
          </div>
        </div>
      </div>
      <el-table
        style="width: 100%"
        height="calc(100vh - 385px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
        <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="getDoctor.nickname"
          label="姓名"
          width="100"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="recharge_record_no"
          label="充值流水号"
          width="220"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="getDepartment.name"
          label="部门"
          width="170"
          align="left"
        ></el-table-column>

        <!-- 
         <el-table-column
          prop="getRoles.name"
          label="职位"
          width="170"
          align="left"
        ></el-table-column> -->

        <el-table-column label="金额" min-width="150" align="left">
          <template slot-scope="scope">
            <el-tag :type="typeMap[scope.row.type].type" effect="plain" size="mini">
              {{ typeMap[scope.row.type].symbol }}
              {{ Number(scope.row.amount).toFixed(2) }}
            </el-tag>
          </template>
        </el-table-column>

        <!-- <el-table-column label="诊疗状态" min-width="130" align="left">
          <template slot-scope="scope">
            {{ diagnosisMap[scope.row.diagnosis_status] }}
          </template>
        </el-table-column>

        <el-table-column label="就诊状态" min-width="130" align="left">
          <template slot-scope="scope">
            {{ visitMap[scope.row.visit_status] }}
          </template>
        </el-table-column> -->

        <!-- <el-table-column label="活动类型" min-width="130" align="left">
          <template slot-scope="scope">
            {{ payMap[scope.row.pay_type] }}
          </template>
        </el-table-column> -->

        <el-table-column label="产品类型" min-width="130" align="left">
          <template slot-scope="scope">
            {{ proMap[scope.row.pro_type] }}
          </template>
        </el-table-column>

        <el-table-column label="结算状态" min-width="130" align="left">
          <template slot-scope="scope">
            {{ arrearsMap[scope.row.arrears] }}
          </template>
        </el-table-column>

        <el-table-column label="是否转诊" min-width="110" align="left">
          <template slot-scope="scope">
            {{ changeMap[scope.row.change_into] }}
          </template>
        </el-table-column>

        <!-- <el-table-column
          label="支付类型"
          min-width="150"
          align="left"
        >
           <template slot-scope="scope">
            {{ amounttypeMap[scope.row.amount_type] }}
          </template>
        </el-table-column> -->

        <el-table-column label="结算类型" min-width="150" align="left">
          <template slot-scope="scope">
            {{ moduleMap[scope.row.module] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="支付时间"
          min-width="200"
          align="left"
        ></el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
    tableData: {
      default: () => [],
    },
    statisticsData: {},
  },
  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {
      typeMap: [
        {
          text: "收入",
          type: "success",
          symbol: "+",
        },
        {
          text: "支出",
          type: "danger",
          symbol: "-",
        },
      ],

      moduleMap: {
        points: "积分",
        money: "消费",
        card: "购物卡",
      },
      stateMap: [
        "账户资金",
        "现金",
        "支付宝",
        "微信",
        "银行卡",
        "购物卡",
        "保险",
        "其它",
      ],
      statusMap: ["未支付", "已支付"],
      diagnosisMap: ["", "前诊", "复诊"],
      visitMap: ["", "首诊", "初诊", "复诊"],
      payMap: ["", "办卡", "充值", "门诊", "产品"],
      // proMap: ["看诊", "检查", "诊疗", "药品", "产品"],
      proMap: ["充值", "消费"],
      arrearsMap: ["已交", "欠费"],
      changeMap: ["否", "是"],
      amounttypeMap: ["结算金额", "折扣金额", "免费金额"],
      moduleMap: ["", "现金", "保险"],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;

  .total {
    display: flex;
    align-items: center;

    .total-amount {
      height: 80px;
      margin: 20px 10px 20px 5px;
      padding: 0 40px 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 1px 4px 1px #d8d8e3;

      .image {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .img {
        width: 44px;
        height: 44px;
      }

      .content {
        font-family: PingFangSC-Medium, PingFang SC;

        .total-name {
          font-size: 12px;
          color: #999;
        }
        .total-number {
          font-size: 18px;
          color: #333;
        }
      }
    }
  }
}
</style>
