/**
 * 获取患者看诊历史 API
 */

 import axios from 'axios';
 import qs from 'qs';
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/patient/method/get.diagnosis.return.visit';
 
 export default (data) => {
 
     return axios.post(url, qs.stringify({
             ...data
         })).then(({
             data
         }) => data)
         .then(({
             data
         }) => data)
         .then(({
             items
         }) => !items ? [] : items.map(({
             chief_complaint,
             get_inspection_record,
             create_time,
             type,
             diagnosis_record_id,
             impressions
         }) => ({
             create_time: create_time, //看诊时间
             impressions: impressions, //诊断
             chief_complaint: chief_complaint, //主诉
             inspection_record: get_inspection_record, //检验检查
             type: type, //诊断类型
             diagnosis_record_id //诊断ID
         })));
 }