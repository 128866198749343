<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container class="patient-detail-doctor">
      <Container class="see-detail-test-inspect">
        <el-row style="width: 100%; height: 50px; line-height: 35px; margin-bottom: 5px">
          <el-col :span="3">
            <div class="hb-layout-flex-1 cloumn-head">保险名称</div>
          </el-col>
          <el-col :span="4">
            <div class="hb-layout-flex-1 cloumn-head">套餐名称</div>
          </el-col>
          <el-col :span="3">
            <div class="hb-layout-flex-1 cloumn-head">开始日期</div>
          </el-col>
          <el-col :span="3">
            <div class="hb-layout-flex-1 cloumn-head">结束日期</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">福利余额</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">剩余次数</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">累计消费</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">累计次数</div>
          </el-col>
          <el-col :span="1">
            <div class="hb-layout-flex-1 cloumn-head">开启</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">编辑信息</div>
          </el-col>
        </el-row>
      </Container>
      <Container class="see-detail-test-inspect">
        <template
          v-for="(
            { edit, balance, count, total_account, total_count, insurance_account_id,type },
            index
          ) in rows"
        >
          <el-row v-if="!edit" style="width: 100%; margin-bottom: 5px" :key="index">
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                {{ rows[index].name }}
              </div>
            </el-col>
            <el-col :span="4">
              <div class="hb-layout-flex-1 cloumn-head" v-if="type==1">
                {{ rows[index].levelname }}/门诊福利
              </div>
               <div class="hb-layout-flex-1 cloumn-head" v-if="type==2">
                {{ rows[index].levelname }}/物理治疗
              </div>
            </el-col>
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-date-picker
                  disabled
                  v-model="rows[index].start_time"
                  value-format="yyyy-MM-dd"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-date-picker
                  disabled
                  v-model="rows[index].end_time"
                  value-format="yyyy-MM-dd"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  disabled
                  class="grid-left"
                  :value="balance"
                  @input="(value) => onSmallUnitNumInput(index, value)"
                  placeholder="福利余额"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  disabled
                  class="grid-left"
                  :value="count"
                  @input="(value) => onSmallUnitNumInput(index, value)"
                  placeholder="剩余次数"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  disabled
                  class="grid-left"
                  :value="total_account"
                  @input="(value) => onSmallUnitNumInput(index, value)"
                  placeholder="累计消费"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  disabled
                  class="grid-left"
                  :value="total_count"
                  @input="(value) => onSmallUnitNumInput(index, value)"
                  placeholder="累计次数"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="1">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-switch
                  disabled
                  v-model="rows[index].is_delete"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  @change="handChange(insurance_account_id)"
                >
                </el-switch>
              </div>
            </el-col>
            <el-col :span="2" style="height: 57px; color: #ff0000">
              <div>
                <el-button
                  v-if="insurance_account_id !== 0"
                  type="primary"
                  style="margin-top: 7px"
                  @click.native="hanldeditaccount(index)"
                  >编辑</el-button
                >
                <!-- <el-button
                  v-if="insurance_account_id !== 0"
                  type="info"
                  style="margin-top: 7px"
                  >详情</el-button
                > -->
              </div>
            </el-col>
          </el-row>

          <el-row v-else style="width: 100%; margin-bottom: 5px" :key="index">
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                {{ rows[index].name }}
              </div>
            </el-col>
            <el-col :span="4">
              <div class="hb-layout-flex-1 cloumn-head" v-if="type==1">
                {{ rows[index].levelname }}/门诊福利
              </div>
               <div class="hb-layout-flex-1 cloumn-head" v-if="type==2">
                {{ rows[index].levelname }}/物理治疗
              </div>
            </el-col>
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-date-picker
                  v-model="rows[index].start_time"
                  value-format="yyyy-MM-dd"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-date-picker
                  v-model="rows[index].end_time"
                  value-format="yyyy-MM-dd"
                  align="right"
                  type="date"
                  placeholder="选择日期"
                  style="width: 100%"
                >
                </el-date-picker>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="balance"
                  @input="(value) => onSmallBlanceInput(index, value)"
                  placeholder="福利余额"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="count"
                  @input="(value) => onCountInput(index, value)"
                  placeholder="剩余次数"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="total_account"
                  @input="(value) => onTotalAccountInput(index, value)"
                  placeholder="累计消费"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="total_count"
                  @input="(value) => onTotalCountInput(index, value)"
                  placeholder="累计次数"
                ></el-input>
              </div>
            </el-col>
            <el-col :span="1">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-switch
                  v-model="rows[index].is_delete"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  :active-value="1"
                  :inactive-value="0"
                  @change="hanldaddaccount(index, 2)"
                >
                </el-switch>
              </div>
            </el-col>
            <el-col :span="2" style="height: 57px; color: #ff0000">
              <div>
                <el-button
                  v-if="insurance_account_id == 0"
                  t
                  type="primary"
                  style="margin-top: 7px"
                  @click.native="hanldaddaccount(index, 1)"
                  >添加</el-button
                >
                <el-button
                  v-if="insurance_account_id !== 0"
                  type="primary"
                  style="margin-top: 7px"
                  @click.native="hanldaddaccount(index, 1)"
                  >保存</el-button
                >
                <!-- <el-button
                  v-if="insurance_account_id !== 0"
                  type="info"
                  style="margin-top: 7px"
                  >详情</el-button
                > -->
              </div>
            </el-col>
          </el-row>
        </template>
      </Container>
      <Container class="hb-layout-horizontal">
        <el-row style="width: 100%; margin-bottom: 5px;margin-left:10px;">
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-select v-model="categoryValue" filterable placeholder="请选择">
                <el-option
                  v-for="item in insuranceCompanyDatas"
                  :key="item.insurance_id"
                  :label="item.name"
                  :value="item.insurance_id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cloumn-footer">
              <el-select v-model="selectChiledValue" filterable placeholder="请选择">
                <el-option
                  v-for="item in insuranceLevelDatas"
                  :key="item.insurance_setting_id"
                  :label="item.getInsuranceLevel.name "
                  :value="item.insurance_setting_id"
                >
                  <span style="float: left">{{ item.getInsuranceLevel.name }}/</span>
                  <span style="float: left; color: #8492a6;">{{ item.getInsuranceLevel.years }}/</span>
                  <span style="float: left" v-if="item.type==1">门诊福利</span>
                  <span style="float: left" v-if="item.type==2">物理治疗</span>
                  
                </el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
      </Container>
    </Container>
  </el-drawer>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    paymentDatas: {},
    attributeDatas: {},
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    paymentParam: {},
    categoryRecords: {},
    insuranceCompanyDatas: {},
    insuranceLevelDatas: {},
    insuranceSettingDatas: {},
    insuranceAccountDatas: {},
  },
  watch: {
    //change药品分类->get药品类别
    categoryValue: function (val) {
      let data = {
        insurance_id: val,
      };
      this.paymentParams.insurance_id = val;
      this.$emit("insurancelevel", data);
    },
    selectChiledValue: function (val) {
      let data = {
        insurance_setting_id: val,
      };
      this.paymentParams.insurance_setting_id = val;
      this.$emit("insurancesetting", data);
      //if(val.length>0){
      //  this.insuranceSettingData = val;
      //}
    },
    insuranceSettingDatas: function (val) {
      if (this.rows == undefined) {
        this.rows = new Array();
      }
      let obj = {};
      obj.insurance_setting_id = val.insurance_setting_id;
      obj.insurance_level_id = val.insurance_level_id;
      obj.name = val.getInsurance.name;
      obj.type = val.type;
      obj.levelname = val.getInsuranceLevel.name + "/" + val.getInsuranceLevel.years;
      obj.balance = val.amount;
      obj.count = val.count;
      obj.total_count = 0;
      obj.total_account = 0;
      obj.is_delete = 0;
      obj.start_time = "";
      obj.end_time = "";
      obj.insurance_account_id = 0;
      obj.edit = true;
      this.rows.push(obj);
    },
    insuranceAccountDatas: function (val) {
     // if (val.length > 0) {
        this.rows = val;
    //  }
    },
    paymentParam:function(val){
      this.paymentParams.medical_record_no=val.medical_record_no;
      this.paymentParams.user_id=val.client_id;
      console.log("paymentParams.user_id",this.paymentParams);
    }
  },
  data() {
    return {
      drawer: false,
      category: "",
      payList: [
        {
          value: 0,
          label: "未结算",
        },
        {
          value: 1,
          label: "已结算",
        },
      ],
      selectChiledValue: "",
      linkman: "",
      rows: [],
      categoryValue: "",
      selectList: {},
      childSelectList: {}, ////add->footer->select(val)->storehouse
      selectListItem: {},
      pay_amount: 0,
      total_amount: "",
      numss: 0,
      paymentParams: {
        insurance_id: 0,
        insurance_level_id: 0,
        to_payment: 0,
        user_id: 1,
        create_id: 0,
        payment_status: 0, //0=未付，1=已付
        trade_status: 0, //交易状态 0:待处理，1:已处理，2:欠账
        cause: "",
        medical_record_no: "",
        use_level: 0, //会员抵扣
        use_discount: 0, //折后价格
        total_amount: "", //实际支付价格
        use_justment: 0, //赠送金额
        pay_amount: 0, //应付金额
        treatments: [],
      },
      insuranceSettingData: {},
      userInfo:""
    };
  },
   mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    this.paymentParams.create_id=this.userInfo.client_id;
  },
  methods: {
    //结算方能发
    hanldaddaccount(val, fun) {
      let rowarr = this.rows;
      let rowData = [];

      rowarr.findIndex((value, index, arr) => {
        if (index === val) {
          // old_discounts =
          rowData = this.rows[index];
        }
        //return value.time == val;
      });
      if (fun == 1) {
        let data = {
          fun: "add",
          balance: rowData.balance,
          type:rowData.type,
          count: rowData.count,
          total_account: rowData.total_account,
          total_count: rowData.total_count,
          medical_record_no: this.paymentParams.medical_record_no,
          user_id: this.paymentParam.client_id,
          start_time: rowData.start_time,
          end_time: rowData.end_time,
          insurance_account_id: rowData.insurance_account_id,
          insurance_id: this.paymentParams.insurance_id,
          insurance_level_id: rowData.insurance_level_id,
          insurance_setting_id: rowData.insurance_setting_id,
          create_id:this.paymentParams.create_id,
          levelname:rowData.levelname,
        };
        this.$emit("saveinsuranceaccountitem", data);
      } else if (fun == 2) {
        let data = {
          fun: "active",
          medical_record_no: this.paymentParams.medical_record_no,
          user_id: this.paymentParam.client_id,
          is_delete: rowData.is_delete,
          insurance_account_id: rowData.insurance_account_id,
        };
        this.$emit("saveinsuranceaccountitem", data);
      }

      //this.paymentParams.treatments.status=1;
      //this.paymentParams.use_level=

    },

    /**
     * 修改的方法
     * @param index
     */
    hanldeditaccount(index) {
      this.rows[index].edit = true;
    },
    changeDrawer(v) {
      this.drawer = v;
    },
    /**
     * 账户余额的方法
     * @param index
     * @param remark
     */
    onSmallBlanceInput(index, blance) {
      this.rows[index].blance = blance;
    },

    /**
     * 账户次数填写的方法
     * @param index
     * @param remark
     */
    onCountInput(index, count) {
      this.rows[index].count = count;
    },
    /**
     * 累计消费填写的方法
     * @param index
     * @param remark
     */
    onTotalAccountInput(index, count) {
      this.rows[index].total_account = count;
    },
    /**
     * 累计次数填写的方法
     * @param index
     * @param remark
     */
    onTotalCountInput(index, count) {
      this.rows[index].total_count = count;
    },

    /**
     * 删除的方法
     * @param index
     */
    onDeleteButtonClick(index, item) {
      //this.$emit('delexamination',this.rows[index].examinations_id);
      this.rows.splice(index, 1);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: flex-end;

  .edit-buttom {
    margin-top: 20px;
  }
}

.header {
  width: 100%;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  background-color: #ededed;
  border-bottom: 1px solid #e1e1e1;

  > .time {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;
  }

  > .button {
    height: 30px;
    margin-top: 12px;
  }
}

.title-top {
  text-align: center;
}

.cloumn-head {
  padding: 5px 5px;
  line-height: 40px;
}

.cloumn-footer {
  padding: 8px 12px;
}

$see-detail-test-inspect-item-title-width: 132px;

$see-detail-test-inspect-item-height: 45px;

.see-detail-test-inspect {
  .el-row {
    margin-left: 20px;
    width: 100%;
    border-bottom: 1px solid #979797;

    &:last-child {
      margin-bottom: 0;
      // border-bottom: 1px solid #979797;
    }
  }

  .basic-inspect {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .bg-purple {
    background: #e5e9f2;
  }

  .bg-purple-light {
    background: #ffffff;
    // border-left: 1px solid #979797;
    // border-right: 1px solid #979797;
  }

  .purple-light {
    // border-left: 1px solid #979797;
  }

  .grid-content {
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .grid-left {
    text-align: left;
    padding-left: 10px;
  }

  .grid-right {
    text-align: right;
    padding-right: 20px;
    color: #ff0000;
    // text-decoration: underline;
    position: absolute;
    right: 0;
    top: 0;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}
</style>
