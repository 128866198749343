<template>
  <Container>
    <Container class="appoientment-main-operation hb-layout-horizontal">
      <!-- <div class="search">
        <el-input
          v-model="patientSearch"
          placeholder="搜索患者"
          class="input-with-select search"
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>-->
      <el-button type="primary" @click="newAppointmentDrawer()">新增预约</el-button>
    </Container>
    <!-- <el-drawer
      title="新增预约"
      :visible.sync="newAppointmentDrawer"
      style="fontsize: 20px"
    >-->
    <Add
      :appoinetDate="appoinetDate"
      :searchrecords="searchrecords"
      :doctors="doctors"
      @medicalrecordsearch="onMedicalRecordSearch"
      @appointmentsaveclick="onAppointmentSaveClick"
      ref="appoinetDrawer"
      :direction="direction"
      :size="size"
      style="fontSize: 16px"
      :drawer_title="drawer_add_title"
      :settingDatas="settingDatas"
      :departmentDatas="departmentDatas"
      @getdoctortimereturn="onGetDoctorTimeReturn"
      :doctorDayTime="doctorDayTime"
      :doctordaylistData="doctordaylistData"
      :classesSettingData=" classesSettingData"
      :doctorClassesListData="doctorClassesListData"
      :doctorId="doctorId"
    ></Add>
    <!-- </el-drawer> -->
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Add"], "appointment.Center"),
    ...components(["Container"])
  },
  props: {
    doctorClassesListData:{},
    classesSettingData:{},
    doctordaylistData:{},
    doctorDayTime:true,
    appoinetDate:"",
    departmentDatas: {
      type: Array,
      default: () => []
    },
    settingDatas: {},
    searchrecords: {},
    doctors: {},
    doctorId:""
  },
  data() {
    return {
      direction: "rtl",
      size: "35%",
      drawer_add_title: "新增预约",
      patientSearch: "",
      nameInput: "", // 姓名
      sexOptions: [
        {
          value: "1",
          label: "男"
        },
        {
          value: "2",
          label: "女"
        }
      ],
      sexValue: "",
      dataValue: "", // 出生年月
      phoneInput: "", // 联系方式
      doctorOptions: [
        {
          value: "1",
          label: "宋医生"
        },
        {
          value: "2",
          label: "董医生"
        }
      ],
      doctorValue: "", // 预约医生
      timeOptions: [
        {
          value: "1",
          label: "2021-05-19 10:30"
        },
        {
          value: "2",
          label: "2021-05-20 13:30"
        },
        {
          value: "3",
          label: "2021-05-21 13:30"
        }
      ],
      timeValue: "", // 预约时间
      // 选择科室
      departmentOptions: [
        {
          value: "1",
          label: "内科"
        },
        {
          value: "2",
          label: "外科"
        },
        {
          value: "3",
          label: "神经内科"
        }
      ],
      departmentValue: "",

      // 症状
      symptomOptions: [
        {
          value: "1",
          label: "头疼"
        },
        {
          value: "2",
          label: "流鼻涕"
        }
      ],
      symptomValue: "",

      // 持续时间
      durationOptions: [
        {
          value: "1",
          label: "三天"
        },
        {
          value: "2",
          label: "一周"
        }
      ],
      durationValue: ""
    };
  },
  methods: {
    onMedicalRecordSearch(val) {
      //search(3):触发上级事件
      this.$emit("medicalrecordsearch", val);
    },
    onAppointmentSaveClick(val) {
      this.$emit("appointmentsaveclick", val);
    },
    newAppointmentDrawer() {
      // this.$emit("settinglist");
      this.$refs.appoinetDrawer.changeDrawer(true);
    },
    changeDrawer(val){
      this.$refs.appoinetDrawer.changeDrawer(val);
    },
    onGetDoctorTimeReturn(val){
      this.$emit("doctortimetreturn", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  padding: 0 19px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 5px auto 10px;
  position: relative;

  .search {
    width: 300px;
  }

  > .el-button {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}

// 新增回访
.see-patient-history-list-item {
  width: 100%;
  border: 1px solid #e1e1e1;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .edit-buttom {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
