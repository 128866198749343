<template>
  <div class="com">
    <el-drawer title="我是标题" :visible.sync="drawer" :direction="direction">
      <span>我来啦! {{ task.name }}</span>
    </el-drawer>
  </div>
</template>

<script>
export default {
  props: {
    task: {
      type: Object,
      default: () => {},
    },

    direction: {
      type: String,
      default: "rtl",
    },
  },
  data() {
    return {
      drawer:false,
    };
  },
  computed: {
    // drawer_:{
    //     get(){
    //         return this.drawer
    //     },
    //     set(v){
    //         this.$emit("changeDrawer",v)
    //    }
    // }
  },
  methods: {
    changeDrawer(v) {
       // console.log("112");
      this.drawer = v;
    },
  },
};
</script>
<style scoped></style>
//
<task-form
  @changeDrawer="changeDrawer"
  :task="taskFormData"
  :drawer="drawer"
  :direction="direction"
></task-form>

// data() { // return { // taskData: [],// 任务数据 // drawer: false, // direction: 'rtl',
// taskFormData:{} // } // }, // methods: { // changeDrawer(v){ // this.drawer = v; // },
// 　　　　}
