<template>
  <Container class="statistics">
    <div class="text item">
      <el-table
        ref="list"
        :data="tableData"
        highlight-current-row
        v-loading="loading"
        stripe
        height="calc(100vh - 265px)"
        style="width: 100%"
      >
        <el-table-column fixed type="index" width="40"></el-table-column>

        <el-table-column
          fixed
          prop="name"
          label="名称"
          min-width="200"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="english_name"
          label="英文名称"
          min-width="200"
          align="left"
        ></el-table-column>
        <el-table-column
          prop="get_category.name"
          label="类目"
          min-width="120"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="specifications"
          label="规格"
          min-width="120"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.specifications }}克</div>
          </template>
        </el-table-column>

        <el-table-column
          sortable
          prop="trade_price"
          label="进价(元)"
          min-width="100"
          align="left"
        >
        </el-table-column>

        <el-table-column
          sortable
          prop="retail_price"
          label="售价(元)"
          min-width="100"
          align="left"
        >
        </el-table-column>
        <el-table-column
          sortable
          prop="stock"
          label="库存量"
          min-width="100"
          align="left"
        >
        </el-table-column>

        <el-table-column label="单位" min-width="100" align="left">
          <template slot-scope="scope">
            <div>{{ scope.row.get_unit.name }}</div>
          </template>
        </el-table-column>
        <el-table-column label="规格数量" min-width="100" align="left">
          <template slot-scope="scope">
            <div>{{ scope.row.small_unit_num }}</div>
          </template>
        </el-table-column>
        <el-table-column label="规格单位" min-width="120" align="left">
          <template slot-scope="scope">
	          <div>{{ scope.row.get_small_unit ? scope.row.get_small_unit.name : '-' }}</div>
          </template>
        </el-table-column>
        <el-table-column label="包装规格" min-width="120" align="left">
          <template slot-scope="scope">
            <div>{{ scope.row.mini_unit }}</div>
          </template>
        </el-table-column>
        <el-table-column
          sortable
          prop="stock_warning"
          label="预警值"
          min-width="100"
          align="left"
        ></el-table-column>

        <el-table-column fixed="right" label="操作" width="80" align="left">
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="el-icon-plus"
                  @click.native="editdrawer(scope.row.storehouse_id)"
                  >编辑</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-delete"
                  @click.native="handleDelClick(scope.row.storehouse_id)"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <EditMaterial
      ref="edit_drawer"
      @submitmaterial="onSubmitAddMaterial"
      :direction="direction"
      :size="size"
      :drawer_title="drawer_title"
      :storehouseRecordItem="storehouseRecordItem"
      :brandDatas="brandDatas"
      :supplierDatas="supplierDatas"
      :factoryDatas="factoryDatas"
      :storehouseCateDatas="storehouseCateDatas"
      :unitList="smallunitDatas"
    ></EditMaterial>
  </Container>
</template>

<script>
import { components, layouts } from "@/helper";

export default {
  components: {
    ...components(["AddMaterial", "EditMaterial"], "storage.Material.Left"),
    ...components(["Container"]),
  },
  props: {
    loading: {
      default: false,
    },
    storehouseCateDatas: {},
    brandDatas: {},
    supplierDatas: {},
    factoryDatas: {},
    tableData: {},
    storehouseDatas: {},
    storehouseRecordItem: {},
    smallunitDatas:{}
  },

  data() {
    return {
      size: "50%",
      drawer_title: "编辑物资",
      drawer_add_title: "新增物资",
      direction: "rtl",
      search: "", // 搜索
      categoryValue: "", // 类目
      manufactor: [
        {
          value: "1",
          label: "内科",
        },
        {
          value: "2",
          label: "外科",
        },
      ],
      manufactorValue: "", // 厂家
      brandValue: "", // 品牌
      supplierValue: "",
    };
  },

  methods: {
    onSubmitAddMaterial(data) {
      let me = this;
      me.$emit("submitmaterial", data);
    },
    //点击编辑事件->调用Drawer窗口
    editdrawer(val) {
      //this.editdrawers=true;
      //通过ref=edit_drawer 调用 editdrugs 组件里的changeDrawer方法
      this.$refs.edit_drawer.changeDrawer(true, val);
      this.$emit("changeeditmaterial", val);
      //this.changeDrawer(true);
      //console.log("1212", val);
    },
    handleDelClick(val) {
      let data = {
        storehouse_id: val,
      };
      this.$emit("delselectitem", data);
    },
    changeDrawers(val) {
      //this.editdrawers=true;
      //通过ref=edit_drawer 调用 editdrugs 组件里的changeDrawer方法
      this.$refs.edit_drawer.changeDrawers(val);
      //this.changeDrawer(true);
      //console.log("1212", val);
    },
    addMaterial() {
      this.$refs.add_drawer.changeDrawer(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;
}
</style>
