import axios from 'axios' ;

/**
 * 主诉模板获取
 */
// export default () =>{

//     return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/complained_template/method/get.complained.template.list/').then(({
//         data
//     }) => data)
//     .then(({
//         data
//     }) => data)
//     .then(({
//         items
//     }) => items.map(({
//         complained_template_id,
// 		content
//     }) =>({
// 		complained_template_id:complained_template_id,
//         content:content,
          
//     })));
// }

/**
 * 主诉模板获取
 */
export default () =>{

    return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/complained_template/method/get.complained.template.list/').then(({
        data
    }) => data)
    .then(({
        data
    }) => data.map(({
		complained_template_id,
		content
	})=>({
		complained_template_id:complained_template_id,
		content:content,
	})));
}