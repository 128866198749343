<template>
  <Container class="see-patient-history-list hb-layout-vertical">
    <el-tabs
      v-model="activeName"
      type="card"
      @tab-click="handleClick"
      :stretch="true"
      v-if="data.patient_information"
    >
      <el-tab-pane label="预约详情" name="first">
        <Container class="see-patient-history-list-item hb-layout-vertical">
          <Container class="header hb-layout-horizontal">
            <div class="time hb-layout-flex-1" v-if="data !== undefined">
              预约时间:{{ data.appointment_date }}/{{ data.start_time }}-{{data.end_time}}
            </div>
            <div class="time hb-layout-flex-1" v-if="data == undefined">
              预约时间:{{}}
            </div>
            <el-button type="success" size="mini" class="button">初诊</el-button>
          </Container>
          <Container class="body">
            <Container class="inner hb-layout-horizontal">
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">姓名</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light">
                    {{
                      data.patient_information.real_name === undefined
                        ? ""
                        : data.patient_information.real_name
                    }}
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">性别</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light">
                    {{ statusSex[data.patient_information.sex].text }}
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">出生年月</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light">
                    {{
                      data.patient_information.birthday === undefined
                        ? "暂无"
                        : data.patient_information.birthday
                    }}
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">联系方式</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light">
                    {{
                      data.patient_information.mobile === undefined
                        ? "暂无"
                        : data.patient_information.mobile
                    }}
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">详细地址</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light">
                    {{
                      data.patient_information.address === undefined
                        ? "暂无"
                        : data.patient_information.address
                    }}
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">保险公司</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light">
                    {{
                      data.patient_information.insurance_record_id === undefined
                        ? "暂无"
                        : data.patient_information.insurance_record_id
                    }}
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">预约医生</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-select v-model="doctorValue">
                      <el-option
                        v-for="item in doctors"
                        :key="item.doctorId.value"
                        :label="item.name"
                        :value="item.doctorId"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">预约科室</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-select v-model="departmentValue">
                      <el-option
                        v-for="item in department"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">症状</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-select v-model="symptomValue" placeholder="请选择">
                      <el-option
                        v-for="item in symptom"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">持续时间</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-select v-model="DurationValue" placeholder="请选择">
                      <el-option
                        v-for="item in Duration"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">预约日期</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-date-picker
                      v-model="dataPick"
                      align="right"
                      type="date"
                      placeholder="选择日期"
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">开始时间</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-time-select
                      placeholder="开始时间"
                      v-model="startTime"
                      :picker-options="{
                        start: '09:30',
                        step: '00:15',
                        end: '17:30',
                      }"
                    >
                    </el-time-select>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">结束时间</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-time-select
                      placeholder="结束时间"
                      v-model="endTime"
                      :picker-options="{
                        start: '09:30',
                        step: '00:15',
                        end: '17:30',
                        minTime: startTime,
                      }"
                    >
                    </el-time-select>
                    <!-- <el-select v-model="timeLineValue" placeholder="请选择">
                      <el-option
                        v-for="item in timeLine"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select> -->
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">预约状态</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">

                    <el-select v-model="status" placeholder="请选择">
                      <el-option
                        v-for="item in appointment_status"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
            </Container>
            <el-button type="success" class="edit-buttom" @click="AppoientmentSaveClick"
              >确认修改</el-button
            >
          </Container>
        </Container>
      </el-tab-pane>

      <el-tab-pane label="生命体征" name="second">
        <Container class="see-patient-history-list-item hb-layout-vertical">
          <Container class="header hb-layout-horizontal">
            <div class="time hb-layout-flex-1">预约时间:2021-1-30 14:30</div>
            <el-button type="success" size="mini" class="button">初诊</el-button>
          </Container>
          <Container class="body">
            <Container class="inner hb-layout-horizontal">
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">体温</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="temperature" placeholder="请填写体温"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">呼吸</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="breathing" placeholder="请填写呼吸"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">血压</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="bloodPressure" placeholder="请填写血压"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">心率</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="heartRate" placeholder="请填写心率"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">身高</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="height" placeholder="请填写身高"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">体重</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="weight" placeholder="请填写体重"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">头围</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input
                      v-model="headCircumference"
                      placeholder="请填写头围"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">BMI</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="BMI" placeholder="请填写BMI"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">过敏史</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input
                      v-model="allergicHistory"
                      placeholder="请填写过敏史"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">既往史</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="pastHistory" placeholder="请填写既往史"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">家族史</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input
                      v-model="familyHistory"
                      placeholder="请填写家族史"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </Container>
            <el-button type="success" class="edit-buttom" @click="diagnosisSaveClick"
              >确认提交</el-button
            >
          </Container>
        </Container>
      </el-tab-pane>
    </el-tabs>
  </Container>
</template>

<script>
import { components, layouts } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    data: {},
    doctors: {},
  },

  watch: {
   
    data({
        doctor_id,
        appointment_date,
        start_time,
        end_time,
        symptom,
        duration_time,
        clinical_department_id,
		status,
    }){

      let me = this ;

      me.doctorValue = doctor_id;

      me.dataPick = appointment_date;

      me.startTime = start_time;

      me.endTime = end_time ;

      me.symptomValue = symptom;

      me.DurationValue = duration_time ;

      me.departmentValue = clinical_department_id ;
	  
	  me.status=status;

    }
  },

  data() {
    return {
      temperature: "",
      bloodPressure: "",
      breathing: "",
      heartRate: "",
      height: "",
      weight: "",
      headCircumference: "",
      allergicHistory: "",
      pastHistory: "",
      familyHistory: "",
      BMI: "",
      selected: null,
      selectMonth: "",
      activeName: "first",
      //   选择医生
      doctor: [
        {
          value: "1",
          name: "王医生",
        },
        {
          value: "2",
          name: "李医生",
        },
      ],
      doctorValue: 0,
      // 选择科室
      department: [
        {
          value: 1,
          label: "内科",
        },
        {
          value: 2,
          label: "外科",
        },
        {
          value: 3,
          label: "神经内科",
        },
      ],
      departmentValue: "1",

      // 症状
      symptom: [
        {
          value: "1",
          label: "头疼",
        },
        {
          value: "2",
          label: "流鼻涕",
        },
      ],
      symptomValue: "2",

      // 持续时间
      Duration: [
        {
          value: "1",
          label: "三天",
        },
        {
          value: "2",
          label: "一周",
        },
      ],
      DurationValue: "2",
      statusSex: [
        {
          text: "保密",
        },
        {
          text: "男",
        },
        {
          text: "女",
        },
      ],
	  appointment_status:[
      {
        value:0,
        label:"待确认"
      },{
        value:1,
        label:"已确认"
      },{
        value:2,
        label:"已取消"
      },
    ],
	  status:1,
	  
      // 时间段
      timeLine: [
        {
          value: "1",
          label: "1个时间段",
        },
        {
          value: "2",
          label: "2个时间段",
        },
        {
          value: "3",
          label: "3个时间段",
        },
        {
          value: "4",
          label: "4个时间段",
        },
        {
          value: "5",
          label: "5个时间段",
        },
      ],
      timeLineValue: "1",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //设置当天之前的日期不能选择
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周后",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      dataPick: "",
      startTime: "",
      endTime: "",
    };
  },
  methods: {
    onDatePickerChange(date) {
      this.$emit("datechange", date);
    },

    onItemSelect(historyItem) {
      this.$emit("historyitemselect", historyItem);
    },

    handleClick(tab, event) {
     // console.log(tab, event);
    },
    AppoientmentSaveClick() {
      let data = {
        doctorID: this.doctorValue, //预约医生ID
        departmentID: this.departmentValue, //预约科室ID
        symptom: this.symptomValue, //症状
        duration: this.DurationValue,  //持续时间
        dataTime: this.dataPick, //预约日期
        startTime: this.startTime, //预约时间：开始时间
        endTime: this.endTime, //预约时间：结束时间
	  	status:this.status,
      };

      this.$emit("appoientmentsaveclick", data);
      //console.log("预约信息更改", data);
    },
    diagnosisSaveClick() {
      let data = {
        user_id: this.data.user_id,
        temperature: this.temperature, // 体温
        breathe: this.breathing,
        blood_pressure: this.bloodPressure,
        heart_rate: this.heartRate,
        stature: this.height,
        weight: this.weight,
        head_circumference: this.headCircumference,
        bmi: this.BMI,
        allergies: this.allergicHistory,
        past_history: this.pastHistory,
        family_history: this.familyHistory,
        type: "0",
      };

      this.$emit("diagnosissaveclick", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.see-patient-history-list {
  padding: 0 10px 10px;

  .see-patient-history-list-item {
    width: 100%;
    border: 1px solid #e1e1e1;

    > .header {
      padding: 0 10px;
      height: 50px;
      line-height: 50px;
      background-color: #ededed;
      border-bottom: 1px solid #e1e1e1;

      > .time {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5b5b5b;
      }

      > .button {
        height: 30px;
        margin-top: 12px;
      }
    }

    > .body {
      padding: 10px;

      > .inner {
        display: flex;
        flex-direction: column;
        border: 1px solid #979797;

        .el-row {
          width: 100%;
          height: auto;
          line-height: 38px;
          border-bottom: 1px solid #979797;
          font-size: 12px;

          &:last-child {
            border-bottom: none;
          }
        }

        .bg-purple {
          text-align: center;
          background: #EBEBEB;
          border-right: 1px solid #979797;
        }

        .bg-purple-light {
          padding-left: 15px;
        }

        .bg-purple-light-select {
          padding: 0;
          font-size: 12px;
        }
      }
    }

    .edit-buttom {
      width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
