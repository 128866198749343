<template>
  <Container class="patient-history-list hb-layout-vertical overflow">
    <div style="height: calc(100vh - 160px); overflow: auto">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <span>就诊路径</span>
          <!-- <el-button
            style="float: right"
            type="success"
            size="mini"
            @click="onDetailsDrawer()"
            >看诊详情</el-button
          > -->
        </div> 
        <div class="text item visit" v-if="clearData== false ">
          <VisitPath :Data="visitPathData"></VisitPath>
        </div>
        <div class="text item visit" v-if="clearData == true ">
          <el-empty description="暂无数据" :image-size="100"></el-empty>
        </div>
      </el-card>

      <el-card class="box-card cards">
        <div slot="header" class="clearfix">
          <span>回访记录</span>
          <el-button
            style="float: right"
            type="success"
            size="mini"
            @click="returnVisitDrawer()"
            >新增回访</el-button
          >
        </div>
        <div class="text item return-visit" v-if="clearData== false ">
          <ReturnVisitRecords :data="visitreturnDatas"></ReturnVisitRecords>
        </div>
        <div class="text item return-visit return-visit-img" v-if="clearData == true ">
          <el-empty description="暂无数据" :image-size="100"></el-empty>
        </div>
      </el-card>
    </div>

    <!-- 回访弹窗 -->
    <AddVisit
      ref="addVisit"
      :data="returnVisit"
      :paymentParam="paymentParam"
      :diagnosisDetail="diagnosisDetail"
      :medicationOptions="medicationOptions"
      :reactionOptions="reactionOptions"
      :recoveryValue="recoveryValue"
      :drawer_title="drawer_add_title"
      :size="size"
      :returnDatas="returnDatas"
      :direction="direction"
      @returnsaveclick="onReturnSaveClick"
      v-if="diagnosisDetail!==null"
    ></AddVisit>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      ["VisitPath", "ReturnVisitRecords", "VisitDetails", "AddVisit"],
      "patient.Right"
    ),
    ...components(["List", "Container"]),
  },

  props: {
    visitreturnDatas: {},
    returnDatas: {},
    diagnosisDetail: {},
    paymentParam: {},
    historyDiagnosisList: {
      type: Array,
      default: () => [],
    },
    visitPathData: {},
    DiannosisData: {},
    clearData:false
  },
  watch: {
    // visitPathData(val) {
    //   let me = this;
    //   me.visitPathLength = val.visitPathData ;
    // },
    // visitreturnDatas(val) {
    //   //console.log("visitreturnDatas",val);
    //   let me = this;
    //   me.visitreturnLength = val.visitreturnDatas ;
    // },
    diagnosisDetail:function(val){
     // console.log("diagnosisDetail",val);
    }
  },

  data() {
    return {
      direction: "rtl",
      size: "55%",
      drawer_add_title: "新增回访",
      visitPath: [
        {
          content: "患者预约",
          timestamp: "2020-01-01 13:00",
          color: "#2DBA5C",
        },
        {
          content: "门诊挂号",
          timestamp: "2020-01-01 13:00",
          color: "#2DBA5C",
        },
        {
          content: "开始就诊",
          timestamp: "2020-01-01 13:00",
          color: "#2DBA5C",
        },
        {
          content: "结束就诊",
          timestamp: "2020-01-01 13:00",
          color: "#2DBA5C",
        },
      ],
      returnVisit: [
        {
          content: "2020/1/20",
          timestamp: "2020-01-01 13:00",
          color: "#D8D8D8",
          name: "李护士",
          state: "患者恢复良好",
          tags: [
            { returnvisit_id: "2", name: "服药", parent_id: "0" },
            { returnvisit_id: "5", name: "不良反应", parent_id: "0" },
          ],
        },
        {
          content: "2020/1/10",
          timestamp: "2020-01-01 13:00",
          color: "#D8D8D8",
          name: "孙护士",
          state: "患者恢复良好",
          tags: [
            { returnvisit_id: "2", name: "服药", parent_id: "0" },
            { returnvisit_id: "5", name: "不良反应", parent_id: "0" },
          ],
        },
        {
          content: "2020/1/10",
          timestamp: "2020-01-01 13:00",
          color: "#D8D8D8",
          name: "孙护士",
          state: "患者恢复良好",
          tags: [
            { returnvisit_id: "2", name: "服药", parent_id: "0" },
            { returnvisit_id: "5", name: "不良反应", parent_id: "0" },
          ],
        },
      ],
      // 是否服药
      medicationOptions: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "2",
          label: "否",
        },
      ],
      medicationValue: "",
      // 是否有不良反应
      reactionOptions: [
        {
          value: "1",
          label: "是",
        },
        {
          value: "2",
          label: "否",
        },
      ],
      reactionValue: "",
      // 恢复情况
      recoveryOptions: [
        {
          value: "1",
          label: "良好",
        },
        {
          value: "2",
          label: "一般",
        },
        {
          value: "3",
          label: "不好",
        },
      ],
      recoveryValue: "",
      // 回访方式
      returnVisitModeOptions: [
        {
          value: "1",
          label: "电话",
        },
        {
          value: "2",
          label: "微信",
        },
      ],
      returnVisitModeValue: "",
      // 备注
      remarks: "",

      visitPathLength: 0,
      visitreturnLength: 0
    };
  },

  methods: {
    // onDetailsDrawer() {
    //   console.log("1212");
    //   this.DetailsDrawer = true;
    //   this.$emit("detailsDrawer");
    // },

    onDatePickerChange(date) {
      this.$emit("datechange", date);
    },

    onItemSelect(historyItem) {
      this.$emit("historyitemselect", historyItem);
    },

    handleClick(tab, event) {
     // console.log(tab, event);
    },
    returnVisitDrawer() {
      //   this.$emit("regionlist");
      this.$emit("returnrecords");
      this.$refs.addVisit.changeDrawer(true);
    },
    onDetailsDrawer() {
      this.$refs.returnVisit.changeDrawer(true);
    },
    onReturnSaveClick(data) {
      this.$emit("returnsaveclick", data);
    },
    changeDrawer(val) {
      this.$refs.addVisit.changeDrawer(false);
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-history-list {
  .box-card {
    box-shadow: 0 2px 7px 0 #d8d8d8;

    .visit {
      max-height: calc(100vh - 750px);
      min-height: 200px;
      overflow-y: auto;
      padding: 0 2px;
    }

    .return-visit {
      max-height: calc(100vh - 798px);
      overflow-y: auto;
      padding: 0 2px;
    }
    .return-visit-img{
      min-height: 200px;
    }
  }

  .cards {
    margin-top: 10px;
  }

  .state {
    padding: 0 0 4px;
    font-family: PingFangSC-Medium, PingFang SC;
    color: #5b5b5b;
  }

  .tags {
    padding: 13px 0 4px;
    display: flex;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
  }

  .details {
    padding: 0 20px;
    height: calc(100vh - 110px);
    overflow: auto;

    .card-details {
      margin-top: 15px;

      .clearfix {
        .title {
          font-size: 16px;
        }
      }
    }

    .header {
      line-height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      border: 1px solid #e1e1e1;
      padding-left: 15px;
    }

    .form {
      .el-row {
        width: 100%;
        border-top: 1px solid #979797;
        border-left: 1px solid #979797;

        &:last-child {
          margin-bottom: 0;
          border-bottom: 1px solid #979797;
        }
      }

      .bg-purple-light {
        border-left: 1px solid #979797;
        border-right: 1px solid #979797;
      }

      .purple-light {
        text-align: right;
        padding-right: 20px;
      }

      .grid-content {
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
      }

      .content {
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        padding-left: 15px;
      }

      .remarks {
        border-right: 1px solid #979797;
        min-height: 90px;
        padding: 10px;
        font-size: 14px;
      }

      .row-bg {
        padding: 10px 0;
      }
    }
  }
}
</style>
