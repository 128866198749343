<template>
  <Container class="centent hb-layout-flex-1 hb-layout-vertical">
    <!-- <el-card> -->
    <Header
      @submit="handleSubmit"
      ref="header"
      :loading="loading"
      @submitreset="onSubmitReset"
      @todayclick="onTodayClick"
      @weekclick="onWeekClick"
      @monthclick="onMonthClick"
      @yearclick="onYearClick"
      :warehousParam="warehousParam"
      :categoryRecords="categoryRecords"
      @getcategoryrecords="onGetCategoryRecords"
      @getshorehouserecord="onGetStorehouseRecord"
      @savestorehousestockout="onSaveStorehouseStockout"
      :recordsstockout="recordsstockout"
      :storehouseSelectDatas="storehouseSelectDatas"
      :attributeDatas="attributeDatas.items"
    ></Header>

    <List :tableData="Data.items" :loading="loading"></List>
    <!-- </el-card> -->
    <BasicFooter
      :loading="loading"
      :size="Param.page_size"
      :total="Data.total_result"
      @change="handlePaginationChange"
    ></BasicFooter>
  </Container>
</template>

<script>
import { components, blocks } from "@/helper";

export default {
  components: {
    ...components(["Header", "List"], "pharmacy.StockinRecord"),
    ...components(["Container"]),
    ...blocks(["BasicFooter"], "Footer"),
  },

  props: {
    loading: {
      default: false,
    },
    Data: {},
    Param: {},
    attributeDatas: {},
    warehousParam: {},
    categoryRecords: {},
    recordsstockout: {},
    stockoutsData: {},
    storehouseSelectDatas: {},
  },

  data() {
    return {};
  },

  methods: {
    onGetCategoryRecords() {
      this.$emit("getcategoryrecords");
    },
    onGetStorehouseRecord(val) {
      this.$emit("getshorehouserecord", val);
    },
    onSaveStorehouseStockout(dataOne, dataTwo) {
      this.$emit("savestorehousestockout", dataOne, dataTwo);
    },
    // 确定查询
    handleSubmit(form, isRestore = false) {
      this.$emit("changesubmit", form, isRestore);
    },
    onSubmitReset() {
      let me = this;
      me.$emit("submitreset");
    },
    // 分页变化改动
    handlePaginationChange(val) {
      //this.page = val;
      this.$emit("changepagination", val);
      //   this.$nextTick(() => {
      //     this.$refs.header.handleFormSubmit();
      //   });
    },
    onSubmitReset(form) {
      let me = this;
      me.$emit("submitreset", form);
    },
    onTodayClick() {
      let me = this;
      me.$emit("todayclick");
    },
    onWeekClick() {
      let me = this;
      me.$emit("weekclick");
    },
    onMonthClick() {
      let me = this;
      me.$emit("monthclick");
    },
    onYearClick() {
      let me = this;
      me.$emit("yearclick");
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .right {
    width: 300px;
  }
}
</style>
