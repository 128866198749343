/**
 * 获取医生列表 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/medical_record/method/get.medical.record.search';
//let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment_record/method/get.doctor.list/';
export default (data) => {

    return axios.post(url, qs.stringify({
          
                keywords: data
            
        }))
        .then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items
        }) => items.map(({
            medical_record_id,
            medical_record_no,
            real_name,
            age,
            sex,
            mobile,
            user_lvl_id,
            user_id
        }) => ({
            name: real_name,
            medicalID: medical_record_id,
            medicalNO: medical_record_no,
            age: age,
            sex: sex,
            mobile: mobile,
            lvl: user_lvl_id,
            userID:user_id
        })));
}