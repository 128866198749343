<template>
	<Container>
		<div class="screen">
			<div class="left-details">
				<div class="select margin">
					<el-select v-model="stockWarehouseValue" placeholder="请选择仓库">
						<el-option
							v-for="item in warehousDatas"
							:key="item.stock_warehouse_id"
							:label="item.name"
							:value="item.stock_warehouse_id">
						</el-option>
					</el-select>
				</div>
				<div class="select margin">
					<el-input
						v-model="search"
						placeholder="搜索物资名称"
						class="input-with-select search"
					>
						<el-button
							slot="append"
							icon="el-icon-search"
							:disabled="loading"
							@click="handleFormSubmit(true)"
						></el-button>
					</el-input>
				</div>
				<div class="select margin">
					<el-button icon="el-icon-refresh" @click="handleFormReset"
					>重置
					</el-button
					>
				</div>
			</div>
		</div>
	</Container>
</template>

<script>
import {components} from "@/helper";

export default {
	components: {
		...components(["Container"]),
	},
	props: {
		loading: {
			default: false,
		},
		storehouseCateDatas: {},
		brandDatas: {},
		supplierDatas: {},
		factoryDatas: {},
		smallunitDatas:{},
	    warehousDatas:{}
	},
	watch: {
		search: {
			handler(val, oldVal) {
				this.paramForm.keywords = val;
			},
			deep: true,
		},
		stockWarehouseValue: {
			handler(val, oldVal) {
				this.paramForm.stockWarehouseValue = val;
			},
			deep: true,
		},
	},
	data() {
		return {
			size: "70%",
			drawer_title: "编辑物资",
			drawer_add_title: "新增物资",
			direction: "rtl",
			search: "",
			stockWarehouseValue: "",
			paramForm: {
				keywords: undefined,
				stockWarehouseValue: undefined,
			},
		};
	},
	methods: {
		changeDrawer(v) {
			let me = this;
			me.$refs.add_drawer.changeDrawer(v);
		},
		onSubmitAddMaterial(data) {
			let me = this;
			me.$emit("submitmaterial", data);
		},
		handleFormSubmit(isRestore = false) {
			let me = this;
			me.$emit("submit", me.paramForm, isRestore);
		},
		handleFormReset() {
			let me = this;
			me.search = "";
			me.stockWarehouseValue = "";
	
			me.$emit("submitreset", me.paramForm);
		},
	},
};
</script>

<style lang="scss" scoped>
.screen {
	// margin-bottom: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	
	.left-details {
		display: flex;
		align-items: center;
		
		.date {
			width: 240px;
		}
		
		.margin {
			margin-right: 10px;
		}
	}
}

.el-range-editor.el-input__inner {
	width: 100%;
}
</style>
