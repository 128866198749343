<template>
  <Container>
    <Container class="appoientment-main-operation hb-layout-horizontal">
      <el-button type="primary" @click="addSubmit">新增内容</el-button>
    </Container>
    <!-- <el-drawer
      title="新增"
      :visible.sync="newAppointmentDrawer"
      style="fontsize: 20px"
    >
      <Add
        :searchrecords="searchrecords"
        :doctors="doctors"
        @medicalrecordsearch="onMedicalRecordSearch"
        @appointmentsaveclick="onAppointmentSaveClick"
      ></Add>
    </el-drawer> -->
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Add"], "appointment.Center"),
    ...components(["Container"]),
  },
  props: {
    searchrecords: {},
    doctors: {},
  },
  data() {
    return {
     
    };
  },
  methods: {
    onMedicalRecordSearch(val) {
      //search(3):触发上级事件
      this.$emit("medicalrecordsearch", val);
    },
    onAppointmentSaveClick(val) {
      this.$emit("appointmentsaveclick", val);
    },
    addSubmit(){

      this.$emit("addsubmit");
    }
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  padding: 0 19px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px auto 10px;
  position: relative;

  > .el-button {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}

// 新增回访
.see-patient-history-list-item {
  width: 100%;
  border: 1px solid #e1e1e1;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .edit-buttom {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
