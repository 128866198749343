/**
 * 获取患者看诊历史 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


//let url = serverConfig.BASE_API + '/v2/patient/method/get.diagnosis.time.node';

let url = serverConfig.BASE_API + '/v2/visit_path/method/get.list';

export default (data) => {

    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data)
       // .then(({data}) => processData(data));
       .then(({data}) => data);
}

// function processData({
//     start_time,
//     register_time,
//     end_time,
//     get_appointment_record
// }){

//     let {
//         appointment_date,
//         appointment_time
//     } = get_appointment_record ;

//     return [{
//         content: '患者预约',
//         timestamp: `${appointment_date.replace(/\//g , '-')} ${appointment_time}`,
//         color: '#2DBA5C',
//     }, {
//         content: '门诊挂号',
//         timestamp: register_time,
//         color: '#2DBA5C',
//     }, {
//         content: '开始就诊',
//         timestamp: start_time,
//         color: '#2DBA5C',
//     }, {
//         content: '结束就诊',
//         timestamp: end_time=="null"?无:end_time,
//         color: '#2DBA5C',
//     }] ;
// }

