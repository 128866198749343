<template>
  <Container>
    <div class="screen">
      <div class="left-details">
        <div class="select">
          <el-button type="primary" @click="addDelivery(2)">新增出库单</el-button>
        </div>
        <div class="select">
          <el-select v-model="typeValue" placeholder="请选择出库类型">
            <el-option
              v-for="item in attributeDatas"
              :key="item.stock_attribute_id"
              :label="item.name"
              :value="item.stock_attribute_id"
            >
            </el-option>
          </el-select>
        </div>
        <!-- <div class="select">
          <el-date-picker
            v-model="dataTime"
            type="daterange"
            align="right"
            unlink-panels
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            :default-time="['00:00:00', '23:59:59']"
            :picker-options="pickerOptions"
          >
          </el-date-picker>
        </div> -->
        <div class="select">
          <el-input
            v-model="search"
            placeholder="搜索出库单号"
            class="input-with-select search"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              :disabled="loading"
              @click="handleFormSubmit(true)"
            ></el-button>
          </el-input>
        </div>
        <div class="select">
          <el-button icon="el-icon-refresh" @click="handleFormReset">重置</el-button>
        </div>
      </div>
    </div>

    <AddDelivery
      ref="add_drawer"
      :direction="direction"
      :size="size"
      :drawer_title="drawer_add_title"
      :warehousParam="warehousParam"
      :pharmacyCategoryRecords="pharmacyCategoryRecords"
      @getpharmcyrecord="onGetPharmcyRecord"
      @savedelivery="onSaveDelivery"
      :pharmacyrecordsstockin="pharmacyrecordsstockin"
      :attributeDatas="attributeDatas"
      :unitList="smallunitDatas"
      :warehousDatas="warehousDatas"
    ></AddDelivery>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["AddDelivery"], "pharmacy.Delivery"),
    ...components(["Container"]),
  },
  props: {
    loading: {
      default: false,
    },
    attributeDatas: {},
    warehousParam: {},
    pharmacyCategoryRecords: {},
    pharmacyrecordsstockin: {},
    smallunitDatas:{},
    warehousDatas:{}
  },
  watch: {
    search: {
      handler(val, oldVal) {
        this.paramForm.keywords = val;
      },
      deep: true,
    },
    typeValue: {
      handler(val, oldVal) {
        this.paramForm.typeValue = val;
      },
      deep: true,
    },
    dataTime: {
      handler(val, oldVal) {
        if (val !== "") {
          this.paramForm.begin_time = val[0].toUTCString();
          this.paramForm.end_time = val[1].toUTCString();
        }
        // console.log("new",(10-val.use_level));
      },
      deep: true,
    },
  },
  data() {
    return {
      direction: "rtl",
      size: "100%",
      drawer_add_title: "新增出库",
      search: "", // 搜索出库人
      type: [
        {
          value: "1",
          label: "领料出库",
        },
        {
          value: "2",
          label: "报损出库",
        },
        {
          value: "3",
          label: "退货出库",
        },
      ],
      typeValue: "", // 出库类型
      dateValue: "", // 选择时间
      paramForm: {
        keywords: undefined,
        typeValue: undefined,
        dateValue: undefined,
      },
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dataTime: "",
    };
  },
  methods: {
    addDelivery(val) {
      this.$emit("getpharmacycategoryredords");
      this.$refs.add_drawer.changeDrawer(true, val);
    },
    onGetPharmcyRecord(val) {
      this.$emit("getpharmcyrecord", val);
    },
    onSaveDelivery(dataOne, dataTwo) {
      this.$emit("savedelivery", dataOne, dataTwo);
    },
    handleFormSubmit(isRestore = true) {
      let me = this;
      me.$emit("submit", me.paramForm, isRestore);
    },
    handleFormReset() {
      let me = this;
      me.search = "";
      me.typeValue = "";
      me.dateValue = "";
      me.dataTime = "";
      me.$emit("submitreset", me.paramForm);
      //this.$refs.form.resetFields();
      //console.log("1212");
    },
    changeDrawer(v) {
      let me = this;
      //console.log("112");
      me.$refs.add_drawer.changeDrawer(v);
    },
  },
};
</script>

<style lang="scss" scoped>
.screen {
  // margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-details {
    display: flex;
    align-items: center;
    margin-left: 5px;

    .select {
      margin: 0 5px;
    }

    .date {
      width: 240px;
    }
  }
}

.el-range-editor.el-input__inner {
  width: 100%;
}
</style>
