<template>
  <Container>
    <Header :rolesDatas="rolesDatas" :departmentDatas="departmentDatas" @roleschange="onRolesChange"></Header>
    <div class="hb-layout-fit-unsized hb-layout-horizontal">
      <div class="calendars hb-layout-vertical hb-layout-flex-1">
        <div class="calendar-details">
          <div class="toolbar hb-layout-center">
            <el-button type="primary" @click="onPreviousButtonClick">上一周</el-button>
            <el-button type="primary" @click="onNextButtonClick">下一周</el-button>
          </div>
          <div class="form">
            <!-- <ClinicScheduling
              @cellselect="onCellSelect"
              ref="scheduling"
              :users="users"
              :plans="plans"
            ></ClinicScheduling> -->
            <ClinicScheduling
              ref="scheduling"
              @cellselect="onCellSelect"
              :users="doctorDatas"
              :plans="users"
              :title="title"
            ></ClinicScheduling>
          </div>
        </div>
      </div>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";
import ClinicScheduling from "clinic-scheduling";

import "clinic-scheduling/index.css";

export default {
  components: {
    ClinicScheduling,
    ...components(["Header"], "configuration.Scheduling.Left"),
    ...components(["Container"]),
  },

  props: {
    departmentDatas:{},
    todaySchedulinngDatas: {},
    doctorDatas: {},
    rolesDatas: {},
    schedulingDatas: {},
  },
  watch: {
    schedulingDatas: function (val) {
      if (val.length > 0) {
        this.users = val;
      }
    },
  },
  data() {
    return {
      orders: ["早班", "中班", "晚班"],
      title: "全部科室",
      users: [],
      plans: [],
    };
  },

  methods: {
    onPreviousButtonClick() {
      this.$refs.scheduling.previous();
    },

    onNextButtonClick() {
      this.$refs.scheduling.next();
    },

    onCellSelect(_, plans, userId, date) {
      let data = {
        doctor_id: userId,
        scheduling_date: date,
      };
      this.$emit("schedulingplan", data);
      //console.log("单元格选中", plans, userId, date);
    },
    onRolesChange(val) {
      this.$emit("roleschange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.calendars {
  margin: 2px 15px 15px 0;

  .calendar-details {
    height: 100%;
    box-shadow: 0 2px 7px 0 #d8d8d8;
    padding: 20px 15px;

    .toolbar {
      height: 40px;
      margin-bottom: 20px;

      > button {
        margin: 0 10px;
      }
    }
    .form {
      height: calc(100vh - 310px);
      overflow-y: auto;
    }
  }
}
</style>
<style lang="scss" scoped>
.zb-layout-flex {
  display: flex;
}

.zb-layout-flex.direction-row {
  flex-direction: row;
}

.zb-layout-flex.direction-column {
  flex-direction: column;
}

.zb-layout-flex.align-start {
  align-items: flex-start;
}

.zb-layout-flex.align-end {
  align-items: flex-end;
}

.zb-layout-flex.align-center {
  align-items: center;
}

.zb-layout-flex.align-stretch {
  align-items: stretch;
}

.zb-layout-flex.justify-start {
  text-align: center;
}

.zb-layout-flex.justify-end {
  justify-content: flex-end;
}

.zb-layout-flex.justify-center {
  justify-content: center;
}

.zb-layout-flex.justify-stretch {
  justify-content: stretch;
}

.zb-layout-flex > .zb-layout-flex-item-wrapper {
  position: relative;
}

.zb-layout-flex > .zb-layout-flex-item-wrapper.flex {
  flex: 1;
}

.zb-layout-flex > .zb-layout-flex-item-wrapper > .inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.zb-layout-flex > .zb-layout-flex-item-wrapper > .inner {
  width: 100%;
  height: 100%;
}

.clinic-scheduling /deep/ .cell {
  line-height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 2px 0;
  color: #333;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
  background: floralwhite !important;
  &:hover {
    background: gray !important;
  }
}

.clinic-scheduling /deep/ .cell.title {
  border-top: 1px solid #979797 !important;
  padding: 15px 0;
}

.clinic-scheduling /deep/ span.cell {
  border-left: 1px solid #979797;
}

.clinic-scheduling /deep/ .cell .plan {
  margin: 1px 0;
}
</style>
