<template>
	<Container class="patient-detail-doctor">
		<el-card>
			<el-table class="table-list" :data="addList" highlight-current-row stripe>
				<el-table-column fixed prop="name" label="物资名称" min-width="180" align="center"></el-table-column>
				<el-table-column prop="manufactor" label="厂家" min-width="220" align="center"></el-table-column>
				<el-table-column prop="purchasePrice" label="进价（元）" min-width="130" align="center"></el-table-column>
				<el-table-column prop="amountSold" label="售价（元）" min-width="130" align="center"></el-table-column>
				<el-table-column prop="batchNumber" label="生产批号" min-width="150" align="center"></el-table-column>
				<el-table-column prop="manufactureDate" label="生产日期" min-width="150" align="center"></el-table-column>
				<el-table-column prop="validityDate" label="有效期" width="150" align="center"></el-table-column>
				<el-table-column prop="number" label="数量" width="100" align="center"></el-table-column>
			</el-table>
		</el-card>
	</Container>
</template>

<script>
import {
	components
} from '@/helper';

export default {
	
	components: components([
		'Container'
	]),
	
	data() {
		return {
			addList: [
				{
					name: "A4-活页本",     // 名称
					manufactor: "上海有加皮具有限公司",                // 厂家
					purchasePrice: "15.00",            // 进价
					amountSold: "20.00",               // 售价
					batchNumber: "H-021A4",                   // 生产批号
					manufactureDate: "2021-04-31",            // 生产日期
					validityDate: "无期限",                   // 有效期
					number: "68",            // 数量
				},
			],
		}
	},
	methods: {
	
	},
}
</script>

<style lang="scss" scoped>
	.table-list{
		height: calc(100vh - 130px);
		width: 100%;
	}
</style>
