import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";
/**
 * 医生获取看诊记录列表 API
 */


let url = serverConfig.BASE_API + '/v2/patient/method/get.diagnosis.record.list/';

export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        })).then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items,
            total_result
        }) => {
            let result = items.map(({
                impressions,
                get_doctor,
                getUser,
                get_clinical_department,
                create_time,
                appointment_record_no,
                diagnosis_record_no
            }) => ({
                impressions,
                create_time,
                doctor_name: get_doctor.nickname,
                clientName:getUser.nickname,
                clinical_name: get_clinical_department.name,
                appointment_record_no: appointment_record_no,
                diagnosis_record_no: diagnosis_record_no
            }))

            return {
                items: result,
                total_result
            };
        })
    // .then(({items}) => items.map(({
    //                                   impressions,
    //                                   get_doctor,
    //                                   get_clinical_department,
    //                                   start_time
    //                               }) => ({
    //     impressions,
    //     start_time,
    //     doctor_name: get_doctor.nickname,
    //     clinical_name: get_clinical_department.name
    // })));
}