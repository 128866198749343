<template>
  <Container class="centent hb-layout-flex-1 hb-layout-vertical">
    <!-- <el-card> -->
    <!-- <Header
      @submit="handleSubmit"
      ref="header"
      :loading="loading"
      @submitreset="onSubmitReset"
      @todayclick="onTodayClick"
      @weekclick="onWeekClick"
      @monthclick="onMonthClick"
      @yearclick="onYearClick"
    ></Header> -->

    <List :tableData="Data.items" :loading="loading"></List>
    <!-- </el-card> -->
    <BasicFooter
      :loading="loading"
      :size="Param.page_size"
      :total="Data.total_result"
      @change="handlePaginationChange"
    ></BasicFooter>
  </Container>
</template>

<script>
import { components,blocks } from "@/helper";

export default {
  components: {
    ...components(["Header", "List"], "pharmacy.StockoutWarning"),
    ...components(["Container"]),
    ...blocks(["BasicFooter"], "Footer"),
    ...blocks(["BasicPagination"], "Pagination"),
  },

  props: {
    Data: {},
    Param: {},
    loading: {
      default: false,
    },
  },

  data() {
    return {
      //loading: false,
      table: [],
      toPayment: {},
      page: {
        current: 1,
        size: 0,
        total: 0,
      },
      order: {
        order_type: undefined,
        order_field: undefined,
      },
    };
  },

  methods: {
    // 分页变化改动
    handlePaginationChange(val) {
      this.$emit("changepagination", val);
    },
    onChangeSubmit() {
      this.$nextTick(() => {
        this.$refs.header.handleFormSubmit();
      });
    },

    // 确定查询
    handleSubmit(form, isRestore = false) {
      this.$emit("changesubmit", form, isRestore);
    },
    onSubmitReset() {
      let me = this;
      me.$emit("submitreset");
    },
    onTodayClick() {
      let me = this;
      me.$emit("todayclick");
    },
    onWeekClick() {
      let me = this;
      me.$emit("weekclick");
    },
    onMonthClick() {
      let me = this;
      me.$emit("monthclick");
    },
    onYearClick() {
      let me = this;
      me.$emit("yearclick");
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .right {
    width: 300px;
  }
}
</style>
