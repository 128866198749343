<template>
    <Container class="patient-detail-info-project">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>治疗项目</span>
            </div>
            <div>
                <el-table
                    :data="diagnosisData"
                    style="width: 100%"
                    max-height="200">
                    <el-table-column
                        prop="date"
                        label="看诊时间"
                        width="200"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="项目名称"
                        min-width="120"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="remarks"
                        label="备注"
                        min-width="120" 
                        align="center">
                    </el-table-column>
                    <el-table-column
                        label="状态"
                        min-width="100" 
                        align="center">
                        <template slot-scope="scope">
                            <div class="state" size="small" v-if="scope.row.state == true">待执行</div>
                            <div size="small" v-else>已执行</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label="操作"
                        width="100" 
                        align="center">
                        <template slot-scope="scope">
                            <el-button
                                size="mini"
                                type="primary"
                                @click="handleRow(scope.row)">执行</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  
  data(){
    return {
      diagnosisData:[
          {
              date: '2020-05-21 13:40',   // 看诊时间
              name: '妇科',   // 项目名称
              remarks: '张敏',     // 备注
              operation: '待执行',   //操作  0、待执行   1、已执行
              state: true
          }
      ]
    }
  },
  methods:{
      handleRow(row){
          row.state = false;
      }
  }
}
</script>

<style  lang="scss"  scoped>
    .clearfix{
        span{
            font-size: 16px;
        }
    }
    .state{
        color: #409EFF;
    }
</style>
