<template>
  <Container class="patient-detail-doctor">
    <el-table
      :data="tableData"
      highlight-current-row
      @current-change="handleCurrentChange"
      style="width: 100%"
    >
      <el-table-column fixed type="index" width="45"> </el-table-column>
      <el-table-column
        prop="patient_information.doctorName"
        align="left"
        label="预约医生"
        width="220"
      ></el-table-column>

      <el-table-column
        prop="getDepartment.name"
        align="left"
        label="科室"
        min-width="140"
      ></el-table-column>
      <el-table-column prop="status" label="预约状态" min-width="80" align="left">
        <template slot-scope="scope">
          <el-tag :type="typeMap[scope.row.status].type" effect="plain" size="mini">
            {{ typeMap[scope.row.status].text }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="就诊类型" min-width="80" align="left">
        <template slot-scope="scope">
          <el-tag
            :type="visitStatusMap[scope.row.visit_status].type"
            effect="plain"
            size="mini"
          >
            {{ visitStatusMap[scope.row.visit_status].text }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column label="看诊状态" min-width="80" align="left">
        <template slot-scope="scope">
          <el-tag
            :type="stateMap[scope.row.diagnosis_status].type"
            effect="plain"
            size="mini"
          >
            {{ stateMap[scope.row.diagnosis_status].text }}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column
        prop="appointment_date"
        align="left"
        label="预约日期"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="start_time"
        align="left"
        label="开始时间"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="end_time"
        align="left"
        label="结束时间"
        min-width="100"
      ></el-table-column>
      <el-table-column
        prop="register_time"
        align="left"
        label="到店时间"
        min-width="140"
      ></el-table-column>
    </el-table>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  props: {
     tableData: {
    
    },
  },

  data() {
    return {
      currentRow: null,
      typeMap: [
        {
          text: "待确认",
          type: "warning",
        },
        {
          text: "已确认",
          type: "success",
        },
        {
          text: "已取消",
          type: "info",
        },
        {
          text: "客户爽约",
          type: "danger",
        },
      ],
      //看诊状态 1=到店 2=待诊 3=已诊 4=检测中
      stateMap: [
        {
          text: "待到店",
          type: "primary",
        },
        {
          text: "已到店",
          type: "success",
        },
        {
          text: "待珍",
          type: "danger",
        },
        {
          text: "已珍",
          type: "warning",
        },
      ],
      //就诊状态 1=初诊 2=复诊 3=首诊
      visitStatusMap: [
        {
          text: "待确认",
          type: "warning",
        },
        {
          text: "首诊",
          type: "success",
        },
        {
          text: "初诊",
          type: "primary",
        },
        {
          text: "复诊",
          type: "danger",
        },
      ],
    };
  },

  components: components(["Container"]),
  methods: {
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      this.$emit("currentchange", val);
     // console.log("看诊记录点击时间信息：" + JSON.stringify(val));
    },
  },
};
</script>

<style scoped></style>
