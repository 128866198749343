<template>
  <Container class="patient-detail-doctor">
    <el-table :data="tableData" highlight-current-row style="width: 100%">
      <el-table-column fixed type="index" width="45"> </el-table-column>
      <el-table-column prop="payment_no" align="left" label="流水号" min-width="220">
      </el-table-column>
      <el-table-column prop="amount" align="left" label="金额" min-width="100">
      </el-table-column>
      <el-table-column align="left" label="支付方式" min-width="140">
        <template slot-scope="scope">
          {{ stateMap[scope.row.to_payment] }}
        </template>
      </el-table-column>
      <el-table-column align="left" label="状态" min-width="100">
        <template slot-scope="scope">
          {{ statusMap[scope.row.status] }}
        </template>
      </el-table-column>
      <!-- 新增加的结束时间 -->
      <el-table-column prop="create_time" align="left" label="充值时间" min-width="160">
      </el-table-column>
    </el-table>
  </Container>
</template>
<script>
import { components } from "@/helper";

export default {
  data() {
    return {
      currentRow: null,
      stateMap: ['账户资金', '现金', '支付宝', '微信', '银行卡', '购物卡', '保险', '其它'],
      statusMap: ['待付款', '已完成', '已关闭'],
      typeMap: ['账户充值', '活动赠送', '额外赠送'],
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
  components: components(["Container"]),
  methods: {
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      this.$emit("currentchange", val);
     // console.log("看诊记录点击时间信息：" + JSON.stringify(val));
    },
  },
};
</script>

<style scoped></style>
