<template>
  <div>
    <Container class="charge-detail hb-layout-vertical">
      <BasicTop
        :real_name="medicalRecord.real_name"
        :sex="medicalRecord.sex"
        :age="medicalRecord.age"
        :medical_record_no="medicalRecord.medical_record_no"
      ></BasicTop>
      <Container class="vital-signs">
        <el-card class="box-card" v-if="diagnosisItemData.length > 0">
          <el-row style="background: #fff">
            <el-col :span="7">
              <div class="grid-content bg-purple">诊断 Diagnosis</div>
            </el-col>
            <el-col :span="15">
              <div class="grid-content bg-purple-light" v-if="diagnosisItemData.impressions.length > 0">
                {{ diagnosisItemData.impressions.join(",") }}
              </div>
            </el-col>
          </el-row>
        </el-card>
 
        <el-card class="box-card" v-if="inspectionItems.length > 0">
          <div slot="header" class="clearfix">
            <div class="title">检查项目 Examination</div>
          </div>
          <OptionColumn
            :Data="inspectionItems"
            :type="inspection"
            :show="showTrue"
            @executechange="onExecuteChange"
          ></OptionColumn>
        </el-card>

        <el-card class="box-card" v-if="treatmentItems.length > 0">
          <div slot="header" class="clearfix">
            <div class="title">治疗项目 Treatment</div>
          </div>
          <OptionColumn
            :Data="treatmentItems"
            :type="treatment"
            :show="showTrue"
            @executechange="onExecuteChange"
          ></OptionColumn>
        </el-card>

        <el-card class="box-card" v-if="westernmedicineItems.length > 0">
          <div slot="header" class="clearfix">
            <div class="title">西药处方 Pharmacy Prescription(Western Medicine)</div>
          </div>
          <OptionColumn
            :Data="westernmedicineItems"
            :type="pharmacy"
            :show="showFalse"
            @executechange="onExecuteChange"
          ></OptionColumn>
        </el-card>

        <el-card class="box-card" v-if="pharmacyItems.length > 0">
          <div slot="header" class="clearfix">
            <div class="title">中药处方 TCM Prescription</div>
          </div>
          <OptionColumn
            :Data="pharmacyItems"
            :type="pharmacy"
            :record="medicalRecord"
            :show="showFalse"
            @executechange="onExecuteChange"
          ></OptionColumn>
        </el-card>
      </Container>
      <BindExcuteUser
        ref="bind_source"
        :drawer_title="drawer_add_title"
        :size="size"
        :direction="direction"
        @binddoctorsearch="onBindDoctorSearch"
        :bindSearchDatas="bindSearchDatas"
        @submitbindsource="onSubmitBindSource"
      ></BindExcuteUser>
    </Container>
  </div>
</template>

<script>
import { components, blocks } from "@/helper";

export default {
  components: {
    ...components(["BasicTop","BindExcuteUser"], "implement.Center"),
    ...components(["List", "Container"]),
    ...blocks(["OptionColumn"], "Templates"),
  },
  props: {
    diagnosisItemData:{},
    bindSearchDatas:{},
    medicalRecord: {},
    paymentItems: {},
    inspectionItems: {},
    treatmentItems: {},
    pharmacyItems: {},
    westernmedicineItems: {},
    diagnosisRecordList: {
      //看诊列表
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
    direction: "rtl",
      size: "30%",
      drawer_add_title: "绑定执行人",
      showTrue:true,
      showFalse:false,
      inspection: "inspection",
      treatment: "treatment",
      western: "western",
      pharmacy: "pharmacy",
      pricePayable: 0,

      settlementDialog: false, // 结算的弹窗
      paymentMethod: [
        {
          value: "1",
          label: "现金",
        },
        {
          value: "2",
          label: "微信",
        },
        {
          value: "3",
          label: "保险",
        },
        {
          value: "4",
          label: "余额",
        },
        {
          value: "5",
          label: "支付宝",
        },
        {
          value: "6",
          label: "银行卡",
        },
      ],
      paymentMethodValue: "", // 支付方式
      settlementAmount: "", // 充值金额
      settlementRemarks: "", // 余额充值的备注信息
      total_num:0
    };
  },
  watch: {
    diagnosisItemData:function(val){
      // console.log("res",val);
    }

  },
  methods: {
    paymentSubmit() {
      this.$emit("paymentsubmit");
    },
    onExecuteChange(type, val,show) {
      
      if(show){
        this.$refs.bind_source.changeDrawer(true,type,val);
      }else{
        this.$emit("executechange", type, val);
      }
   
    },
    onBindDoctorSearch(query) {
       this.$emit("binddoctorsearch", query);
    },
    onSubmitBindSource(data){
       this.$emit("submitbindsource", data);
    },
    changeInfoDrawer(val){
        this.$refs.bind_source.changeDrawer(val,0,0);
    }
  },
};
</script>

<style lang="scss" scoped>
.charge-detail {
  width: 100%;

  .vital-signs {
    width: 99%;
    margin: 2px auto 15px;
    overflow: auto;
    height: calc(100vh - 170px);

    > .box-card {
      margin-bottom: 11px;
      box-shadow: 0 2px 7px 0 #d8d8d8;

      .title {
        font-size: 16px;
      }

      .el-row {
        // margin-bottom: 20px;
        height: 40px;
        line-height: 40px;
        border-top: 1px solid #e1e1e1;
        border-left: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;

        &:last-child {
          margin-bottom: 0;
          border-bottom: 1px solid #e1e1e1;
        }

        &:first-child {
          background: #ebebeb;
        }
      }

      .bg-purple {
        text-align: center;
        border-right: 1px solid #e1e1e1;
      }

      .purple {
        text-align: center;
      }

      .bg-purple-light {
        padding: 0 15px;
      }

      .purple-light {
        padding: 0 15px;
        float: right;
      }

      .display {
        display: flex;
        justify-content: space-between;
        white-space: nowrap;
        overflow: hidden;

        .display-item {
          display: flex;
          // align-items: center;

          .display-item-name {
            padding-right: 100px;
          }
        }
      }
    }

    .pay {
      margin-top: 50px;

      .method {
        display: flex;
        justify-content: flex-end;
      }

      .pay-btn {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
    }
  }
}

.printLabel {
  width: 450px;
  height: 350px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  font-size: 14px;

  .label-head {
    height: 100px;
    line-height: 100px;
    padding: 10px;

    .logo {
      float: left;
      height: 60px;
      margin-top: 10px;
    }
  }

  .label-body,
  .label-footer {
    padding: 0 10px;
  }

  .label-body {
    margin-top: -20px;
  }

  .label-footer {
    font-size: 10px;
  }

  .el-row {
    border: unset !important;
    background: unset !important;
  }

  .barCode {
    transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    /* Internet Explorer 9*/
    -moz-transform: rotate(90deg);
    /* Firefox */
    -webkit-transform: rotate(90deg);
    /* Safari 和 Chrome */
    -o-transform: rotate(90deg);
    /* Opera */

    position: absolute;
    bottom: 0;
    right: 0;
  }
}

.grade {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;

  .name {
    white-space: nowrap;
    margin-right: 5px;
    font-size: 14px;
  }

  .content {
    width: 70%;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
</style>
