/**
 * 获取某一患者的预约记录列表 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/recharge_record/method/get.list.all';
//let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment_record/method/get.doctor.list/';
export default (data) => {


   return axios.post(url, qs.stringify({
       ...data
   })).then(({data}) => data);
    //    .then(({data}) => data);
}

