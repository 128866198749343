<template>
	<Container class="details hb-layout-horizontal">
		<Container class="left hb-layout-vertical">
			<LeftMain @getleftchangeone="onGetLeftChangeOne"></LeftMain>
		</Container>
		<Container class="center hb-layout-flex-1 hb-layout-vertical">
			<CenterMain
				:listRecordsData="listRecordsData"
				:tabData="tabData"
				@editselectitem="onEditSelectItem"
				@handleselectitem="onHandleSelectItem"
				@handlepassselectitem="onHandlePassSelectItem"
				@handledelselectitem="onHandleDelDelectItem"
				@addsubmit="onAddSubmit"
				@handablechange="onHandAbleChange"
			></CenterMain>
		</Container>
		<Container class="right hb-layout-vertical">
			<RightMain
				ref="rightSelectData"
				:tabData="tabDatas"
				:listRecordsData="listRecordsData"
				:departmentData="departmentData"
				:rolesData="rolesData"
				@submititemdata="onSubmitItemData"
				:systemKeywordsData="systemKeywordsData"
			></RightMain>
		</Container>
	</Container>
</template>

<script>
import {components} from "@/helper";

export default {
	components: {
		...components(
			["Left.Main", "Center.Main", "Right.Main"],
			"configuration.Inside"
		),
		...components(["Container"]),
	},
	
	props: {
		systemKeywordsData: {},
		listRecordsData: {},
		rolesData: {},
		departmentData: {},
		tabData: 0,
	},
	
	watch: {
		tabData: function (val) {
			this.tabDatas = val;
			this.tabNum = val;
		},
	},
	data() {
		return {
			tabDatas: 0
		};
	},
	
	methods: {
		onGetLeftChangeOne(val) {
			this.$emit("getleftchangeone", val);
		},
		onHandleSelectItem(itemData) {
			this.tabDatas = this.tabNum;
			this.$refs.rightSelectData.handleSelectItems(itemData);
		},
		onHandlePassSelectItem(itemData) {
			// this.tabDatas = 91;
			this.$refs.rightSelectData.handlePassSelectItems(itemData);
		},
		onHandleDelDelectItem(itemData) {
			this.$emit("handledelitem", itemData);
		},
		onSubmitItemData(itemData) {
			this.$emit("submititemdata", itemData);
		},
		onAddSubmit() {
			this.$refs.rightSelectData.onAddSubmit();
		},
		onEditSelectItem(itemData, treeData) {
			this.$refs.rightSelectData.handleEditSelectItems(itemData, treeData);
		},
		onHandAbleChange(data) {
			this.$emit("handablechange", data);
		},
	},
};
</script>

<style lang="scss" scoped>
.details {
	.left {
		width: 300px;
	}
	
	.right {
		width: 300px;
	}
}
</style>
