<template>
<Container class="statistics">
    <div class="text item"> 
        <el-table style="width: 100%" height="calc(100vh - 225px)" v-loading="loading" :data="tableData" :highlight-current-row="true" @sort-change="sortChange">
            <el-table-column fixed type="index" width="45"> </el-table-column>
            <el-table-column fixed prop="name" label="名称" min-width="220" align="left"></el-table-column>
            <el-table-column prop="english_name" label="英文名称" min-width="220" align="left"></el-table-column>
            <el-table-column  label="数量/单位" min-width="100" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.mini_unit }}/{{ scope.row.unitName }}</div>
                </template>
            </el-table-column>
            <el-table-column  label="数量/规格单位" min-width="120" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.small_unit_num }}/{{ scope.row.smallUnitName }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="trade_price" label="进价" min-width="80" align="left">
            </el-table-column>
            <el-table-column prop="retail_price" label="售价" min-width="80" align="left"> </el-table-column>
            <el-table-column prop="stock" label="库存量" width="100" align="left"></el-table-column>
        </el-table>
    </div>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: components(["Container"]),
    props: {
        Data: {},
        loading: {
            default: false,
        },
        toPayment: {
            default: () => {},
        },
        tableData: {
            default: () => [],
        },
    },
    filters: {
        getNumber(val) {
            return this.getNumber(val);
        },
    },
    data() {
        return {
            typeMap: [
                {
                    text: "收入",
                    type: "success",
                    symbol: "+",
                },
                {
                    text: "支出",
                    type: "danger",
                    symbol: "-",
                },
            ],

            moduleMap: {
                points: "积分",
                money: "消费",
                card: "购物卡",
            },
            stateMap: ['账户资金', '现金', '支付宝', '微信', '银行卡', '购物卡', '保险', '其它'],
            statusMap: ['未支付', '已支付'],
            visitMap: [
                {
                    text: "待确认",
                    type: "",
                },
                {
                    text: "初诊",
                    type: "success",
                },
                {
                    text: "复诊",
                    type: "primary",
                },
                {
                    text: "首诊",
                    type: "info",
                },
            ],
            diagnosisMap: ['到店', '未到店'],
            typeMap: [
                {
                    text: "待确认",
                    type: "danger",
                },
                {
                    text: "确认",
                    type: "success",
                },
                {
                    text: "取消",
                    type: "info",
                },
            ],
            stateMap: [
                {
                    text: "待到店",
                    type: "",
                },
                {
                    text: "已到店",
                    type: "success",
                },
                {
                    text: "待珍",
                    type: "primary",
                },
                {
                    text: "已珍",
                    type: "info",
                },
            ],
        };
    },
    methods: {
        sortChange({
            column,
            prop,
            order
        }) {
            let sort = {
                order_type: undefined,
                order_field: undefined,
            };

            if (column && order) {
                sort.order_type = order === "ascending" ? "asc" : "desc";
                sort.order_field = prop;
            }

            this.$emit("sort", sort);
        },
        getNumber(value) {
            const toFixedNum = Number(value).toFixed(3);
            return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
        },
    },
};
</script>

<style lang="scss" scoped>
.item {
    margin-top: 10px;
}
</style>
