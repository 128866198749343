<template>
	<Container class="details hb-layout-horizontal">
		<Container class="centent hb-layout-flex-1 hb-layout-vertical">
			<LeftMain
				:loading="loading"
				@submitmaterial="onSubmitAddMaterial"
				@changeeditmaterial="onChangeEditMaterial"
				:Data="Data"
				:brandDatas="brandDatas"
				:supplierDatas="supplierDatas"
				:factoryDatas="factoryDatas"
				:smallunitDatas="smallunitDatas"
				:Param="Param"
				@changesubmit="onChangeSubmit"
				@submitreset="onSubmitReset"
				@changepagination="onChangePagination"
				@delselectitem="onDelSelectItem"
				:warehousDatas="warehousDatas.items"
				ref="left_main"
			></LeftMain>
		</Container>
	
	</Container>
</template>

<script>
import {components} from "@/helper";

export default {
	components: {
		...components(["Left.Main"], "pharmacy.Stock"),
		...components(["Container"]),
	},
	
	props: {
		storehouseCateDatas: {},
		brandDatas: {},
		supplierDatas: {},
		factoryDatas: {},
		smallunitDatas: {},  // 规格单位
		storehouseDatas: {},
		Data: {},
		storehouseRecordItem: {},
		loading: {
			default: false,
		},
		Param: {},
		warehousDatas:{}
	},
	
	data() {
		return {};
	},
	
	
	methods: {
		onSubmitAddMaterial(data) {
			let me = this;
			me.$emit("submitmaterial", data);
		},
		onChangeEditMaterial(data) {
			let me = this;
			me.$emit("changeeditmaterial", data);
		},
		onSubmitEditStorehouse(data) {
			let me = this;
			me.$emit("submiteditstorehouse", data);
		},
		onDelSelectItem(data) {
			this.$emit("delselectitem", data);
		},
		onChangeSubmit(form, isRestore = false) {
			this.$emit("changesubmit", form, isRestore);
		},
		onSubmitReset(form) {
			let me = this;
			me.$emit("submitreset", form);
		},
		pageChangeSubmit() {
			this.$nextTick(() => {
				this.$refs.left_main.pageChangeSubmit();
			});
		}, // 分页变化改动
		onChangePagination(val) {
			//this.page = val;
			this.$emit("changepagination", val);
			//   this.$nextTick(() => {
			//     this.$refs.header.handleFormSubmit();
			//   });
		},
		changeDrawer(v) {
			let me = this;
			//console.log("112");
			me.$refs.left_main.changeDrawer(v);
		},
	},
};
</script>

<style lang="scss" scoped>
.details {
	.right {
		width: 300px;
	}
}
</style>
