<template>
  <Container class="centent hb-layout-flex-1 hb-layout-vertical">
    <Container class="left hb-layout-vertical">
      <LeftMain></LeftMain>
    </Container>
    <Container class="centent hb-layout-flex-1 hb-layout-vertical">
      <CenterMain></CenterMain>
         <!-- <List></List> -->
    </Container>
    <Container class="right hb-layout-vertical">
      <RightMain></RightMain>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Left.Main", "Center.Main", "Right.Main","List"], "configuration.Hospital"),
    ...components(["Container"]),
  },

  props: {},

  data() {
    return {};
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.details {
  .left {
    width: 300px;
  }
  .right {
    width: 300px;
  }
}
</style>
