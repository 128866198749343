<template>
<div style="height: 100%; overflow: auto;">
    <Container class="see-detail hb-layout-vertical">
        <Container class="vital-signs">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <div class="title">生命体征</div>
                </div>
                <VitalSignsLayout class="body" :vital="item.vital_signs"></VitalSignsLayout>
            </el-card>
        </Container>
        <Container class="vital-signs basic">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <div class="title">基础检查</div>
                </div>
                <InspectBasicLayout ref="basicRef" :basic="basic" :clinical_department_id="item.clinical_department_id" :chief_complaint="item.chief_complaint" :impressions="item.impressions" class="body"></InspectBasicLayout>
            </el-card>
        </Container>
        <Container class="vital-signs examination">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <div class="title">检验检查</div>
                    <el-button type="primary" @click="() => $refs.inspectionRef.add()" icon="el-icon-plus" size="mini"></el-button>
                </div>

                <InspectInspectionLayout ref="inspectionRef" :inspection_record="item.get_inspection_record" :inspection_template="inspection_template" class="body"></InspectInspectionLayout>
            </el-card>
        </Container>
        <Container class="vital-signs">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <div class="title">治疗项目</div>
                    <el-button type="primary" icon="el-icon-plus" size="mini" @click="() => $refs.treatmentRef.add()"></el-button>
                </div>
                <TreatmentProjectLayout ref="treatmentRef" :treatments_template="treatments_template" :treatments="item.get_treatments" class="body"></TreatmentProjectLayout>
            </el-card>
        </Container>
        <Container class="vital-signs">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <div class="header-left-btn">
                        <div class="title">处方开药</div>
                        <div>
                            <el-radio-group v-model="prescriptionType" @change="clickPrescriptionType">
                                <el-radio-button label="1">西药</el-radio-button>
                                <el-radio-button label="2">中药</el-radio-button>
                                <el-radio-button label="3">输液</el-radio-button>
                            </el-radio-group>
                        </div>
                    </div>
                    <el-button type="primary" icon="el-icon-plus" size="mini" @click="() => $refs.prescriptionRef.add(pharmacy_template , prescriptionType)"></el-button>
                </div>

                <PrescriptionLayout ref="prescriptionRef" :pharmacy="item.get_prescription" class="body"></PrescriptionLayout>
            </el-card>

        </Container>
        <Container class="vital-signs">
            <el-card class="box-card">
                <el-row style="width: 100%;">
                    <el-col :span="3">
                        <el-tag class="basic-inspect" type="info">医生嘱咐</el-tag>
                    </el-col>
                    <el-col :span="21">
                        <div class="grid-content bg-purple-light grid-left">
                            <el-select style="width:100%;" v-model="item.medical_advice" filterable allow-create default-first-option>
                                <el-option v-for="item in doctors_advice" :key="item.doctor_enjoin_template_id" :label="item.content" :value="item.content">
                                </el-option>
                            </el-select>
                        </div>

                    </el-col>
                </el-row>
            </el-card>

        </Container>
    </Container>
</div>
</template>

<script>
import {
    components,
    layouts
} from '@/helper';

export default {
    props: {
        item: { //看诊记录
            type: Object,
            default: () => {}
        },
        diagnosis_template: { //诊断模版
            type: Array,
            default: () => []
        },
        prescription: {
            type: Object,
            default: () => {}
        },
        basic: { //基础检查
            type: Object,
            default: () => {}
        },
        treatments_template: { //治疗模版
            type: Array,
            default: () => []
        },
        inspection_template: { //检查项目模版
            type: Array,
            default: () => []
        },
        pharmacy_template: { //药品记录模版
            type: Array,
            default: () => []
        },
        doctors_advice: { //主诉模版
            type: Array,
            default: () => []
        }

    },
    components: {
        ...layouts([
            'VitalSigns',
            'inspect.Basic',
            'inspect.Inspection',
            'TreatmentProject',
            'Prescription',
        ], 'see.detail'),
        ...components([
            'Container'
        ]),

    },
    data() {
        return {
            medical_advice: '',
            prescriptionType: 1,
        }
    },
    methods: {
        /**
         * 组合保存看诊记录所需的结构
         */
        getSeeData() {
            let inspection = this.$refs.inspectionRef.getRows();
            let treatments = this.$refs.treatmentRef.getRows();
            let prescription = this.$refs.prescriptionRef.getRows();

            //console.log("获取药品数据：" + JSON.stringify(prescription));
            let {
                clinical_department_id,
                impressions,
                chief_complaint,
            } = this.$refs.basicRef.getBasicValue();

            return {
                medical_advice: this.item.medical_advice,
                diagnosis_record_no: this.item.diagnosis_record_no,
                clinical_department_id,
                impressions,
                chief_complaint,
                inspection,
                treatments,
                prescription,
            }
        },
        clickPrescriptionType() {
            this.$emit('clickPrescriptionType', this.prescriptionType)
        }
    }
};
</script>

<style lang="scss" scoped>
.see-detail {

    .vital-signs {
        margin: 2px 5px 15px;
        box-shadow: 0 2px 7px 0 #d8d8d8;

        >.body {
            height: 132px;
        }

        >.box-card {

            .clearfix {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .header-left-btn {
                    display: flex;
                    align-items: center;

                    .title {
                        font-size: 16px;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 500;
                        color: #5B5B5B;
                        line-height: 22px;
                        margin-right: 20px;
                    }

                }

            }

        }

        >.header {
            padding: 16px 0 13px;
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5B5B5B;
            line-height: 22px;
        }
    }

    .el-row {
        width: 100%;
        // border-top: 1px solid #979797;
        // border-Left: 1px solid #979797;

        &:last-child {
            margin-bottom: 0;
            // border-bottom: 1px solid #979797;
        }
    }

    .basic-inspect {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-top: 2px;
    }

    .bg-purple {
        background: #e5e9f2;
    }

    .bg-purple-light {
        // background: #fff;
        // border-Left: 1px solid #979797;
        // border-right: 1px solid #979797;
    }

    .grid-content {
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .grid-left {
        text-align: left;
        padding-left: 10px;
    }

    .row-bg {
        // padding: 10px 0;
        // background-color: #f9fafc;
    }

}
</style>
