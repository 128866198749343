<template>
  <Container class="see-patient-history-list hb-layout-vertical">
    <el-tabs v-model="activeName" type="card" @tab-click="handleClick" :stretch="true">
      <el-tab-pane label="预约详情" name="first">
         <el-empty description="暂无数据"  v-if="patientData.appointment_record_no ==''"></el-empty>
        <Appointment
          :patientData="patientData"
          :signsdata="signsdata"
          :departmentDatas="departmentDatas"
          :settingTrees="settingTrees"
          :selectTrees="selectTrees"
          :doctors="doctors"
          @appoientmentsaveclick="onAppoientmentSaveClick"
          @changestatus="onChangeStatus"
          @changediagnosisstatus="onChangeDiagnosisStatus"
          :doctorDayTime="doctorDayTime"
          :doctordaylistDataTwo="doctordaylistDataTwo"
          :classesSettingData="classesSettingData"
          :doctorClassesListData="doctorClassesListData"
           v-if="patientData.appointment_record_no !==''"
        >
        </Appointment>
      </el-tab-pane>

      <el-tab-pane label="生命体征" name="second">
          <el-empty description="暂无数据"  v-if="patientData.appointment_record_no ==''"></el-empty>
        <VitalSigns
          :patientData="patientData"
          :signsdata="signsdata"
          :doctors="doctors"
          :typeData="typeOfVisit"
          @diagnosissaveclick="onDiagnosissaveclick"
           v-if="patientData.appointment_record_no !==''"
        >
        </VitalSigns>
      </el-tab-pane>
    
    </el-tabs>
  </Container>
</template>

<script>
import { components, layouts } from "@/helper";

export default {
  components: {
    ...components(["Appointment", "Reservation", "VitalSigns"], "appointment.Right"),
    ...components(["Container"]),
  },
  props: {
    doctorClassesListData: {},
    classesSettingData: {},
    doctordaylistDataTwo: {},
    doctorDayTime: true,
    patientData: {},
    departmentDatas: {
      type: Array,
      default: () => [],
    },
    signsdata: {},
    doctors: {},
    settingDatas: {},
    settingTrees: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    patientData: function (val) {
      // if(val.length > 0) {
      //   this.typeOfVisit = val.visit_status; //就诊类型，0=首诊 1=初诊 2=复诊
      // }
      // console.log(" val.visit_status",  val.visit_status);
    },
  },
  data() {
    return {
      patientDatas: {},
      selectTrees: [],
      pickerOptions: {
        start: "09:00",
        step: "00:15",
        end: "17:30",
        // 限制时间
        selectableRange: ["10:00:00 - 10:15:00", "14:30:00 - 15:00:00"], //这里也可以绑定个变量动态限制时间
        // 限制日期
        disabledDate: this.disabledDate,
      },
      settingSelectList: [],
      settingListData: [],
      treeSelectData: [],
      settingrows: [],
      settingrowsss: [],
      temperature: "",
      bloodPressure: "",
      breathing: "",
      heartRate: "",
      height: "",
      weight: "",
      headCircumference: "",
      allergicHistory: "",
      pastHistory: "",
      familyHistory: "",
      BMI: "",
      selected: null,
      selectMonth: "",
      activeName: "first",
      //   选择医生
      doctor: [
        {
          value: "1",
          name: "王医生",
        },
        {
          value: "2",
          name: "李医生",
        },
      ],
      doctorValue: 0,

      departmentValue: "1",

      visitStatusValue: 1,
      status: 1,
      timeLineValue: "1",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //设置当天之前的日期不能选择
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周后",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      dataPick: "",
      startTime: "",
      endTime: "",
      typeOfVisit:0
    };
  },
  methods: {
    onDatePickerChange(date) {
      this.$emit("datechange", date);
    },

    onItemSelect(historyItem) {
      this.$emit("historyitemselect", historyItem);
    },

    handleClick(tab, event) {
     // console.log(tab, event);
    },
    onAppoientmentSaveClick(data) {
      this.$emit("appoientmentsaveclick", data);
      //console.log("预约信息更改", data);
    },
    onDiagnosissaveclick(data) {
      this.$emit("diagnosissaveclick", data);
    },
    onChangeStatus(val) {
      this.$emit("changestatus", val);
    },
    onChangeDiagnosisStatus() {
      this.$emit("changediagnosisstatus");
    },
  },
};
</script>

<style lang="scss" scoped>
.see-patient-history-list {
  // padding: 0 10px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 0px 2px;
  height: 100%;
  overflow: auto;
}

/deep/.el-button--primary:first-child {
  width: calc(100% - 28px) !important;
}
</style>
