/**
 * 获取看诊记录简要信息（历史记录） API
 */

import axios from 'axios' ;
import qs from 'qs' ;
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/diagnosis_record/method/get.diagnosis.record.basic.list';

export default (data) => {

    //items 值为时 后端直接没有返回的， 这里写了个三元判断
    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data)
        .then(({data}) => data)
        .then(({items}) => !items ? [] : items.map(({
                                                        chief_complaint,
                                                        get_inspection_record,
                                                        create_time,
                                                        type,
                                                        diagnosis_record_id,
                                                        impressions
                                                    }) =>({
            create_time: create_time, //看诊时间
            impressions: impressions, //诊断
            chief_complaint: chief_complaint, //主诉
            inspection_record: get_inspection_record, //检验检查
            type: type, //诊断类型
            diagnosis_record_id //诊断ID
        })));
}

