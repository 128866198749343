<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container class="patient-detail-doctor">
      <div class="details">
        <el-card>
          <Container class="see-patient-history-list-item hb-layout-vertical">
            <Container class="header hb-layout-horizontal">
              <div class="time hb-layout-flex-1">就诊时间:{{diagnosisDetail.create_time}}</div>
            </Container>
            <Container class="body">
              <Container class="inner hb-layout-horizontal">
                <el-row type="flex" align="middle">
                  <el-col :span="6">
                    <div class="grid-content bg-purple">回访人</div>
                  </el-col>
                  <el-col :span="18">
                    <div class="grid-content bg-purple-light">
                      {{ paymentParam.user_name }}
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="6">
                    <div class="grid-content bg-purple">被回访人</div>
                  </el-col>
                  <el-col :span="18">
                    <div class="grid-content bg-purple-light">
                      {{ diagnosisDetail.clientName }}
                    </div>
                  </el-col>
                </el-row>
              </Container>
              <div class="title">回访内容</div>
              <Container class="inners hb-layout-horizontal">
                <template v-for="({ name, children }, index) in settingrows">
                  <div class="row-select" :key="index">
                    <el-cascader
                      ref="cascaderAddr"
                      style="width: 100%"
                      :placeholder="name"
                      :options="children"
                      :show-all-levels="false"
                      :props="{
                        label: 'name',
                        value: 'returnvisit_id',
                        emitPath: false,
                      }"
                      @input="(value) => onItemInput(index, value)"
                      v-model="settingrows[index].returnvisit_id"
                      clearable
                    ></el-cascader>
                  </div>
                </template>
              </Container>
              <div class="title">备注</div>
              <Container class="inner hb-layout-horizontal">
                <el-col class="row-select">
                  <el-input type="textarea" v-model="param.remarks"></el-input>
                </el-col>
              </Container>

              <el-button type="success" class="edit-buttom" @click="saveClick"
                >完成回访</el-button
              >
            </Container>
          </Container>
        </el-card>
      </div>
    </Container>
  </el-drawer>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    diagnosisDetail:{},
    returnDatas: {},
    paymentParam: {},
    recoveryOptions: {},
    reactionOptions: {},
    medicationOptions: {},
    Data: {
      type: Array,
      default: () => [],
    },
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
  },
  watch: {
    returnDatas: function (val) {
      if (val.length > 0) {
        this.treeSelectData = this.getTreeData(this.arraytotree(val)); //去除最后children为空
      }
    },

    treeSelectData: function (val) {
      //初始化数据
      if (val.length > 0) {
        this.settingrows = val.map((item) => {
          return {
            returnvisit_id: item.returnvisit_id, //记录ID
            name: item.name, //模版ID
            parent_id: item.parent_id,
            children: item.children,
          };
        });
      }
    },
  },
  data() {
    return {
      settingrows: [],
      treeSelectData: [],
      drawer: false,
      param: {
        medicationValue: "",
        reactionValue: "",
        recoveryValue: "",
        returnVisitModeValue: "",
        remarks: "",
        create_id: 0,
      },
      selectItem: [],
      userInfo: "",
    };
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    this.param.create_id = this.userInfo.client_id;
  },
  methods: {
    changeDrawer(v) {
      this.drawer = v;
    },
    /**
     * 选择框的方法
     * @param index
     * @param item
     */
    onItemInput(indexs, item) {

      this.settingrows[indexs].returnvisit_id = item;
      //this.selectItem.push(item);

      this.settingrows.forEach((_item, index) => {
        if (_item.returnvisit_id === item) {
          _item.children.forEach((_children, index2) => {
            if (_children.returnvisit_id === item) {
              this.selectItem.push(_children.name);
            }
          });
        }
      });

    },

    arraytotree(arr) {
      var top = [],
        sub = [],
        tempObj = {};

      arr.forEach(function (item) {
        if (item.parent_id === 0) {
          // 顶级分类
          top.push(item);
        } else {
          sub.push(item); // 其他分类
        }
        item.children = []; // 默然添加children属性
        tempObj[item.returnvisit_id] = item; // 用当前分类的id做key，存储在tempObj中
      });

      sub.forEach(function (item) {
        // 取父级
        var parent = tempObj[item.parent_id] || { children: [] };
        // 把当前分类加入到父级的children中
        parent.children.push(item);
      });

      return top;
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },

    getRows() {
      //重组，修改看诊记录保存是所需的结构
      // let list = this.$refs["cascaderAddr"].getCheckedNodes();
      // return list;
      return this.settingrows.map((item) => {
        return {
          list: item.children,
          // returnvisit_id: item.returnvisit_id,
          // name: item.name,
          // parent_id: item.parent_id,
          // children:children
        };
      });
    },
    saveClick() {
      let me=this;
      let data = {
        user_id: me.paymentParam.client_id,
        user_name: me.diagnosisDetail.clientName , //
        create_name: me.paymentParam.user_name , //
        //create_id: me.paymentParam.create_id,
        info: me.selectItem,
        remind: me.param.remarks,
        medical_record_no: me.paymentParam.medical_record_no,
        appointment_record_no:me.paymentParam.appointment_record_no,
        create_id:me.param.create_id,
        //status: me.status,
      };
     // console.log("returnsaveclick",data);
      me.$emit("returnsaveclick", data);
    },
  },
};
</script>

<style lang="scss" scoped>
// 新增回访
.see-patient-history-list-item {
  width: 100%;
  border: 1px solid #e1e1e1;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }

      .bg-purple-light {
        padding-left: 15px;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .edit-buttom {
    width: 100%;
    margin-top: 20px;
  }
}
</style>
