<template>
	<el-drawer
		:title="drawer_title"
		:visible.sync="drawer"
		:direction="direction"
		:size="size"
	>
		<Container class="patient-detail-doctor">
			<Container class="header hb-layout-horizontal">
				<el-row style="width: 100%; margin-bottom: 5px">
					<el-col :span="4">
						<div class="hb-layout-flex-1">创建人: {{ warehousParam.create_name }}</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 title-top"><span style="color:#ff0000;font-weight: bold;">*</span>
							入库类型:
						</div>
					</el-col>
					<el-col :span="4">
						<div class="hb-layout-flex-1">
							<el-select
								v-model="warehousParam.type"
								filterable
								style="width: 100%"
								placeholder="入库类型"
							>
								<el-option
									v-for="item in attributeDatas"
									:key="item.stock_attribute_id"
									:label="item.name"
									:value="item.stock_attribute_id"
								>
								</el-option>
							</el-select>
						</div>
					</el-col>
					<el-col :span="1">
						<div class="hb-layout-flex-1 title-top">仓库:</div>
					</el-col>
					<el-col :span="4">
						<div class="hb-layout-flex-1">
							<el-select
								v-model="warehousParam.stock_warehouse_id"
								filterable
								style="width: 100%"
								placeholder="仓库"
							>
								<el-option
									v-for="item in warehousDatas"
									:key="item.stock_warehouse_id"
									:label="item.name"
									:value="item.stock_warehouse_id"
								>
								</el-option>
							</el-select>
						</div>
					</el-col>
					<el-col :span="1">
						<div class="hb-layout-flex-1 title-top">备注:</div>
					</el-col>
					<el-col :span="8">
						<div class="hb-layout-flex-1">
							<el-input
								v-model="warehousParam.remarks"
								placeholder="请输入备注"
							></el-input>
						</div>
					</el-col>
				</el-row>
			</Container>
			<Container class="see-detail-test-inspect">
				<el-row style="width: 100%; height: 50px; line-height: 35px; margin-bottom: 5px">
					<el-col :span="3">
						<div class="hb-layout-flex-1 cloumn-head">物资名称</div>
					</el-col>
					<el-col :span="1">
						<div class="hb-layout-flex-1 cloumn-head">售价</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head"><span
							style="color:#ff0000;font-weight: bold;">*</span> 生产批号
						</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head"><span
							style="color:#ff0000;font-weight: bold;">*</span> 生产日期
						</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head"><span
							style="color:#ff0000;font-weight: bold;">*</span> 有效期
						</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head"><span
							style="color:#ff0000;font-weight: bold;">*</span> 过期时间
						</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head"><span
							style="color:#ff0000;font-weight: bold;">*</span> 单位数量
						</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head">单位</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head"><span
							style="color:#ff0000;font-weight: bold;">*</span> 规格数量
						</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head">规格单位</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head">进价</div>
					</el-col>
					<el-col :span="2">
						<div class="hb-layout-flex-1 cloumn-head">操作</div>
					</el-col>
				</el-row>
			</Container>
			<Container class="see-detail-test-inspect">
				<template
					v-for="(
            { batch_number, validity_date,end_date, unit_num, small_unit_num, price }, index
          ) in rows"
				>
					<el-row style="width: 100%; margin-bottom: 5px" :key="index">
						<el-col :span="3">
							<div class="hb-layout-flex-1 cloumn-head">
								{{ rows[index].name }}
							</div>
						</el-col>
						<el-col :span="1">
							<div class="hb-layout-flex-1 cloumn-head">
								{{ rows[index].retail_price }}
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
								<el-input
									class="grid-left"
									:value="batch_number"
									@input="(value) => onBatchNumberInput(index, value)"
									placeholder="生产批号"
								></el-input>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
								<el-date-picker
									v-model="rows[index].product_date"
									value-format="yyyy-MM-dd"
									align="right"
									type="date"
									placeholder="选择日期"
									style="width: 100%"
								>
								</el-date-picker>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
								<el-input
									class="grid-left"
									:value="validity_date"
									@input="(value) => onValidityDateInput(index, value)"
									placeholder="有效期"
								></el-input>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
									<el-date-picker
									v-model="rows[index].end_date"
									value-format="yyyy-MM-dd"
									align="right"
									type="date"
									placeholder="选择日期"
									style="width: 100%"
								>
								</el-date-picker>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
								<el-input
									class="grid-left"
									:value="unit_num"
									@input="(value) => onUnitNumInput(index, value)"
									placeholder="单位数量"
								></el-input>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
								<el-select
									v-model="rows[index].unit_id"
									filterable
									style="width: 100%"
									placeholder="单位"
								>
									<el-option
										v-for="item in unitData"
										:key="item.unit_id"
										:label="item.name"
										:value="item.unit_id"
									>
									</el-option>
								</el-select>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
								<el-input
									class="grid-left"
									:value="small_unit_num"
									@input="(value) => onSmallUnitNumInput(index, value)"
									placeholder="规格数量"
								></el-input>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
								<el-select
									v-model="rows[index].small_unit_id"
									filterable
									style="width: 100%"
									placeholder="规格单位"
								>
									<el-option
										v-for="item in smallunitData"
										:key="item.unit_id"
										:label="item.name"
										:value="item.unit_id"
									>
									</el-option>
								</el-select>
							</div>
						</el-col>
						<el-col :span="2">
							<div class="hb-layout-flex-1 cloumn-head">
								<el-input
									class="grid-left"
									:value="price"
									@input="(value) => onPriceInput(index, value)"
									placeholder="进价"
								></el-input>
							</div>
						</el-col>
						<el-col
							@click.native="onDeleteButtonClick(index)"
							:span="2"
							style="height: 57px;padding: 2px 0 0 12px"
						>
							<div>
								<el-button type="primary" style="margin-top: 7px" icon="el-icon-delete"
								>删除
								</el-button
								>
							</div>
						</el-col>
					</el-row>
				</template>
			</Container>
			
			<Container class="hb-layout-horizontal">
				<el-row style="width: 86%; margin-bottom: 5px;">
					<el-col :span="4">
						<div class="cloumn-footer">
							<el-select v-model="categoryValue" filterable placeholder="请选择物资分类">
								<el-option
									v-for="item in categoryRecords"
									:key="item.storehouse_category_id"
									:label="item.name"
									:value="item.storehouse_category_id"
								>
								</el-option>
							</el-select>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="cloumn-footer">
							<el-select v-model="selectChiledValue" filterable placeholder="请选择物资名称" @change="changeItem">
								<el-option v-for="(item, index) in childSelectList" :key="item.storehouse_id" :label="item.name" :value="index">
									<span style="float: left">{{ item.name }}</span>
									<span style="float: right; margin-left: 20px; color: #8492a6; font-size: 13px;">{{ item.english_name }}</span>
								</el-option>
							</el-select>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="cloumn-footer">
							<el-input
								v-model="warehousParam.product_num"
								placeholder="入库种类数量"
								:disabled="true"
							></el-input>
						</div>
					</el-col>
					<el-col :span="4">
						<div class="cloumn-footer">
							<el-input
								v-model="warehousParam.total_num"
								placeholder="入库总数量"
								:disabled="true"
							></el-input>
						</div>
					</el-col>
					<el-col :span="8">
						<div class="cloumn-footer">
							<el-button type="info" class="edit-buttom">取消</el-button>
							<el-button type="success" class="edit-buttom" @click="saveStorehouseStockin"
							>确定
							</el-button
							>
						</div>
					</el-col>
				</el-row>
			</Container>
		</Container>
	</el-drawer>
</template>

<script>
import {components} from "@/helper";

export default {
	components: components(["Container"]),
	props: {
		attributeDatas: {},
		warehousDatas: {},
		direction: {
			type: String,
			default: "rtl",
		},
		drawer_title: "",
		size: {
			type: String,
			default: "30%",
		},
		warehousParam: {},
		warhousingData: {
			type: Array,
			default: () => [],
		},
		categoryRecords: {},
		recordsstockin: {},
		storehouseSelectDatas: {},
		unitList:{}
	},
	watch: {
		warhousingData: function (val) {
			//初始化数据
			if (val.length > 0) {
				this.rows = val.map((item) => {
					return {
						pharmacy_name: item.pharmacy_name,
					};
				});
			}
		},
		/**
		 * 监听：分类选择
		 * @param val
		 */
		categoryValue: function (val) {
			let me = this
			me.selectChiledValue = "";    // 重置已选物资项
			me.$emit("getshorehouserecord", val);
		},
		//监控gpharmacyrecords是否记录变化 add->footer->select(val)->storehouse
		// recordsstockin: function (val) {
		storehouseSelectDatas: function (val) {
			this.childSelectList = val.items;
		},
		rows: function (val) {
			let num = 0;
			
			for (let item of val) {
				num = this.NumberAdd(num, item.small_unit_num);
			}
			
			this.total_num = num;
		},
		deep: true,
		    unitList: function (val) {
      //console.log("unitlist", val);
      let me = this;
      if (val.length > 0) {
        val.map((item) => {
          switch (item.type) {
            case 1:
              me.unitData.push(item);
              break;
            case 2:
              me.smallunitData.push(item);
              break;
          }
        });
      }
      //console.log("unitData", me.unitData);
    },
	},
	data() {
		return {
			drawer: false,
			category: "",
			stockCategory: [
				{
					value: 1,
					label: "采购",
				},
				{
					value: 2,
					label: "调货",
				},
			],
			selectChiledValue: "",
			linkman: "",
			unitData: [],
			smallunitData: [],
			rows: [],
			categoryValue: "",
			selectList: {},
			childSelectList: {}, ////add->footer->select(val)->storehouse
			selectListItem: {},
		};
	},
	methods: {
		/**
		 * 物资选择
		 * @param val
		 */
		changeItem(val) {
			//console.log('SELECT', val)
			if (this.rows === undefined) {
				this.rows = [];
			}
			this.selectItem(val);
			//console.log('SELECT1', this.selectListItem)
			let obj= {
				storehouse_id: this.selectListItem.storehouse_id,
				name: this.selectListItem.name,
				retail_price: this.selectListItem.retail_price,
				unit_id: this.selectListItem.unit_id,
				small_unit_id: this.selectListItem.small_unit_id,
				batch_number: "",
				validity_date: "",
				end_date:"",
				unit_num: "",
				stype: 1,
				small_unit_num: "",
				s_num:this.selectListItem.small_unit_num, //只为计算用
				price: this.selectListItem.trade_price,
				mode:1 // 1=物资2-药品
			};
			//console.log('SELECT', obj)
			this.rows.push(obj);
		},
		//footer->child->select选择后的item
		selectItem(val) {
			this.childSelectList.findIndex((value, index, arr) => {
				if (index === val) {
					this.selectListItem = value;
				}
				//return value.time == val;
			});
		},
		saveStorehouseStockin() {
			let me = this;
			
			if (me.warehousParam.type === "" || me.warehousParam.type === null) {
				this.$alert("请选择入库类型");
				return;
			}
			if (me.warehousParam.stock_warehouse_id === "" || me.warehousParam.stock_warehouse_id === null) {
				this.$alert("请选择入库仓库");
				return;
			}
			
			for (let item of me.rows) {
				if (item.batch_number === "" || item.batch_number === null) {
					me.$alert("请选择生产批号");
					return;
				}
				if (item.product_date === "" || item.product_date === null) {
					me.$alert("请输入生产日期");
					return;
				}
				
				if (item.validity_date === "" || item.validity_date === null) {
					me.$alert("请输入有效期");
					return;
				}
				if (item.unit_num === "" || item.unit_num === null) {
					me.$alert("请输入单位数量");
					return;
				}
				if (item.small_unit_num === "" || item.small_unit_num === null) {
					me.$alert("请输入规格数量");
					return;
				}
			}
			
			me.warehousParam.balance = "add"; //加库存判断
			
	  	   // console.log('SELECT', obj);
			me.$emit("savestorehousestockin", me.warehousParam, me.rows);
		},
		changeDrawer(v) {
			this.drawer = v;
			this.warehousParam.type = "";
		},
		/**
		 * 生产批号填写的方法
		 * @param index
		 * @param batch_number
		 */
		onBatchNumberInput(index, batch_number) {
			this.rows[index].batch_number = batch_number;
		},
		/**
		 * 有效期填写的方法
		 * @param index
		 * @param validity_date
		 */
		onValidityDateInput(index, validity_date) {
			this.rows[index].validity_date = validity_date;
		},
		/**
		 * 数量填写的方法
		 * @param index
		 * @param unit_num
		 */
		onUnitNumInput(index, unit_num) {
			let me = this;
			let num = 0;

			this.rows[index].unit_num = unit_num;
			this.rows[index].small_unit_num = this.rows[index].unit_num * this.rows[index].s_num;
			for (let item of me.rows) {
				num = me.NumberAdd(num, item.small_unit_num);
			}
			
			me.warehousParam.total_num = num;
			me.warehousParam.product_num = me.rows.length;
		},
		/**
		 * 规格数量填写的方法
		 * @param index
		 * @param small_unit_num
		 */
		onSmallUnitNumInput(index, small_unit_num) {
			let me = this;
			let num = 0;
			
			me.rows[index].small_unit_num = small_unit_num;
			for (let item of me.rows) {
				num = me.NumberAdd(num, item.small_unit_num);
			}
			
			me.warehousParam.total_num = num;
			me.warehousParam.product_num = me.rows.length;
		},
		/**
		 * 进价填写的方法
		 * @param index
		 * @param price
		 */
		onPriceInput(index, price) {
			this.rows[index].price = price;
		},
		
		/**
		 * 删除的方法
		 * @param index
		 */
		onDeleteButtonClick(index, item) {
			//this.$emit('delexamination',this.rows[index].examinations_id);
			this.rows.splice(index, 1);
		},
		NumberAdd(arg1, arg2) {
			let r1, r2, m, n;
			try {
				r1 = arg1.toString().split(".")[1].length;
			} catch (e) {
				r1 = 0;
			}
			try {
				r2 = arg2.toString().split(".")[1].length;
			} catch (e) {
				r2 = 0;
			}
			m = Math.pow(10, Math.max(r1, r2));
			n = r1 >= r2 ? r1 : r2;
			return ((arg1 * m + arg2 * m) / m).toFixed(n);
		},
	},
};
</script>

<style lang="scss" scoped>
.btn {
	display: flex;
	justify-content: flex-end;
	
	.edit-buttom {
		margin-top: 20px;
	}
}

.header {
	width: 100%;
	padding: 0 10px;
	height: 50px;
	line-height: 50px;
	background-color: #ededed;
	border-bottom: 1px solid #e1e1e1;
	
	> .time {
		font-size: 12px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #5b5b5b;
	}
	
	> .button {
		height: 30px;
		margin-top: 12px;
	}
}

.title-top {
	text-align: center;
}

.cloumn-head {
	padding: 8px 0 8px 12px;
	line-height: 40px;
}

.cloumn-footer {
	padding: 8px 0px 8px 12px;
}

$see-detail-test-inspect-item-title-width: 132px;

$see-detail-test-inspect-item-height: 45px;

.see-detail-test-inspect {
	.el-row {
		width: 100%;
		border-bottom: 1px solid #979797;
		
		&:last-child {
			margin-bottom: 0;
			// border-bottom: 1px solid #979797;
		}
	}
	
	.basic-inspect {
		width: 100%;
		height: 40px;
		line-height: 40px;
		text-align: center;
	}
	
	.bg-purple {
		background: #e5e9f2;
	}
	
	.bg-purple-light {
		background: #ffffff;
		// border-left: 1px solid #979797;
		// border-right: 1px solid #979797;
	}
	
	.purple-light {
		// border-left: 1px solid #979797;
	}
	
	.grid-content {
		height: 45px;
		line-height: 45px;
		text-align: center;
	}
	
	.grid-left {
		text-align: left;
		// padding-left: 10px;
	}
	
	.grid-right {
		text-align: right;
		padding-right: 20px;
		color: #ff0000;
		// text-decoration: underline;
		position: absolute;
		right: 0;
		top: 0;
	}
	
	.row-bg {
		padding: 10px 0;
		background-color: #f9fafc;
	}
}
</style>
