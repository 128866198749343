<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container>
      <el-card>
        <Container class="see-patient-history-list-item hb-layout-vertical">
          <Container class="body">
            <Container class="inner hb-layout-horizontal">
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">姓名</div>
                </el-col>
                <el-col :span="18">
                  <el-select
                    style="width: 100%"
                    v-model="userValue"
                    filterable
                    remote
                    reserve-keyword
                    placeholder="请输入患者姓名或手机号"
                    :remote-method="remoteMethod"
                    :loading="loading"
                  >
                    <el-option
                      v-for="item in options"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">病历档案号</div>
                </el-col>
                <el-col :span="18">
                  <el-input v-model="addParam.medicalNO"></el-input>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">性别</div>
                </el-col>
                <el-col :span="18">
                  <el-select
                    style="width: 100%"
                    v-model="addParam.sexValue"
                    placeholder="请选择性别"
                  >
                    <el-option
                      v-for="item in sexOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-col>
              </el-row>

              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">联系电话</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-light">
                    <el-input
                      v-model="addParam.phoneInput"
                      placeholder="请输入联系电话"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">预约医生</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-light">
                    <el-select
                      style="width: 100%"
                      v-model="addParam.doctorValue"
                      @change="doctorChange($event)"
                    >
                      <el-option
                        v-for="item in doctors"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">预约科室</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-light">
                    <el-cascader
                      style="width: 100%"
                      v-model="addParam.departmentValue"
                      :props="departmentParams"
                      :options="departmentDatas"
                      :show-all-levels="false"
                      @change="departmentChange"
                    ></el-cascader>
                  </div>
                </el-col>
              </el-row>
              <template v-for="({ name, children }, index) in settingrowss">
                <el-row type="flex" align="middle" :key="index">
                  <el-col :span="6">
                    <div class="grid-content bg-purple">{{ name }}</div>
                  </el-col>
                  <el-col :span="18">
                    <div class="grid-content bg-purple-light-select">
                      <el-cascader
                        style="width: 100%"
                        :options="children"
                        :show-all-levels="false"
                        :props="{
                          label: 'name',
                          value: 'appointment_setting_id',
                          emitPath: false,
                        }"
                        @input="(value) => onItemInput(index, value)"
                        v-model="settingrowss[index].appointment_setting_id"
                        clearable
                      ></el-cascader>
                    </div>
                  </el-col>
                </el-row>
              </template>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">预约日期</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-light-select">
                    <el-date-picker
                      v-model="addParam.dataPick"
                      align="right"
                      type="date"
                      placeholder="选择日期"
                      style="width: 100%"
                      format="yyyy-MM-dd"
                      value-format="yyyy-MM-dd"
                      :picker-options="pickerOptions"
                      @change="onChangeDatePicker"
                    >
                    </el-date-picker>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple grid-textarea">预约时间</div>
                </el-col>
                <el-col :span="18">
                  <div
                    :class="
                      doctorDayTime == false
                        ? 'grid-content bg-purple-light-select  time-ranges'
                        : 'grid-content bg-purple-light-select'
                    "
                  >
                    <TimeRange
                      :start.sync="addParam.start"
                      :end.sync="addParam.end"
                      :selectedRanges="selectedRangesAdd"
                      :ranges="computedRanges || newranges"
                    ></TimeRange>
                  </div>
                  <div
                    :class="
                      doctorDayTime
                        ? 'grid-content bg-purple-light-select time-ranges'
                        : 'grid-content bg-purple-light-select'
                    "
                  >
                    <div class="scheduling">没有排班记录！</div>
                    
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">预约状态</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-light-select">
                    <el-select
                      style="width: 100%"
                      v-model="addParam.status"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in appointment_status"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple">就诊状态</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-light-select">
                    <el-select
                      style="width: 100%"
                      v-model="addParam.visitStatusValue"
                      placeholder="请选择"
                    >
                      <el-option
                        v-for="item in visit_status"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      ></el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple bg-textarea">备注</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-light">
                    <el-input
                      type="textarea"
                      :rows="4"
                      v-model="remind"
                      placeholder="请输入备注"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
            </Container>

            <el-button type="success" class="edit-buttom" @click="appointmentSaveClick"
              >提交</el-button
            >
          </Container>
        </Container>
      </el-card>
    </Container>
  </el-drawer>
</template>

<script>
import Container from "../../Container";

import TimeRange from "HB-TIME-RANGE";

export default {
  components: {
    Container,
    TimeRange,
  },
  props: {
    doctorClassesListData: {},
    classesSettingData: {},
    doctordaylistData: {}, //
    doctorDayTime: true,
    appoinetDate: "", //预约管理
    appoinetDates: "", //预约日历
    departmentDatas: {
      type: Array,
      default: () => [],
    },
    settingDatas: {},
    searchrecords: {},
    doctors: {},
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    doctorId: "",
  },
  mounted() {
    let that = this;

    window.PANEL = this;

    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));

    that.ranges = that.classesSettingData;

    // that.addParam.dataPick = that.appoinetDate;
  },
  data() {
    return {
      //*****************time range ************************ */
      start: null,
      end: null,

      selectedRangesAdd: [],
      ranges: [],
      newranges: [],
      computedRanges: null,
      range: [
        {
          start: "9:30",
          end: "11:30",
          step: 15,
          name: "早班",
        },
        {
          start: "13:00",
          end: "15:00",
          step: 15,
          name: "中班",
        },
        {
          start: "18:00",
          end: "20:00",
          step: 15,
          name: "晚班",
        },
      ],
      //*****************time range ************************ */
      addParam: {
        start: "",
        end: "",
        medicalNO: "",
        sexValue: "",
        phoneInput: "", // 联系方式
        doctorValue: "",
        departmentValue: "",
        dataPick: "",
        startTime: "",
        endTime: "",
        status: 0,
        visitStatusValue: 1,
      },
      drawer: false,
      treeSelectData: [],
      settingrows: [],
      settingrowss: [],
      patientSearch: "",
      newAppointmentDrawer: false,
      nameInput: "", // 姓名
      sexOptions: [
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      userValue: [],
      departmentName: "",
      DurationValue: "2",
      appointment_status: [
        {
          value: 0,
          label: "待确认",
        },
        {
          value: 1,
          label: "已确认",
        },
        {
          value: 2,
          label: "已取消",
        },{
          value: 3,
          label: "已爽约",
        }
      ],
      remind:"",
      options: [],
      list: [],
      loading: false,
      states: [],

      info: [{}],
      visit_status: [
        {
          value: 1,
          label: "首诊",
        },
        {
          value: 2,
          label: "初诊",
        },
        {
          value: 3,
          label: "复诊",
        },
      ],
      departmentParams: {
        //可以选中一级内容
        // checkStrictly: true,
        //指定触发方式
        // expandTrigger: "hover",

        label: "name",
        value: "clinical_department_id",
        children: "children",
      },
      userInfo: "",
      dataPick: "",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() + 3600 * 1000 * 24 < Date.now();
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "明天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "后天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 2);
              picker.$emit("pick", date);
            },
          },
        ],
      },
    };
  },
  watch: {
    //******************************* 医生当天预约记录 start****************************************** */
    //所有排班记录
    classesSettingData: function (val) {
      this.ranges = val;
       //console.log("classesSettingData-add",val);
    },
    //医生所在部门的班次记录
    doctorClassesListData: function (val) {
      //console.log("doctorClassesListData-add",val);
      this.classesSettingItems(val); //过滤
    },

    //当天已预约记录
    doctordaylistData: function (val) {
      this.selectedRangesAdd = val;
      //this.remoteSelectedRanges = val;
    },
    //********************************医生当天预约记录 end***************************************** */

    // doctorId:function(val){
    //   this.addParam.doctorValue = val;
    // },
    //appointment.vue
    appoinetDate: function (val) {
      this.addParam.dataPick = val;
    },
    //appointmentCalendar.vue 日历点击空白赋值开始和结束时间
    appoinetDates: function (val) {
      this.addParam.dataPick = val.dateTime;
      if (val.doctorId !== undefined) {
        this.addParam.doctorValue = val.doctorId;
        this.addParam.start = val.startTime;
        this.addParam.end = val.endTime;
        this.startTime = val.startTime;
        this.endTime = val.endTime;
      }
      //console.log("appoinetDates",val);
    },
    settingDatas: function (val) {
      if (val.length > 0) {
        this.treeSelectData = this.getTreeData(this.arraytotree(val)); //去除最后children为空
      }
    },
    treeSelectData: function (val) {
      //初始化数据
      if (val.length > 0) {
        this.settingrows = val.map((item) => {
          return {
            appointment_setting_id: item.appointment_setting_id, //记录ID
            name: item.name, //模版ID
            parent_id: item.parent_id,
            children: item.children,
          };
        });

        this.settingrowss = val.map((item) => {
          return {
            appointment_setting_id: item.appointment_setting_id, //记录ID
            name: item.name, //模版ID
            parent_id: item.parent_id,
            children: item.children,
          };
        });

      }
    },
    //search(7): 监听props值是否变化，有变化就触发
    searchrecords: function (newVal, oldVal) {
      this.states = newVal;
    },
    userValue: function () {
      let me = this;
      let arr = me.userValue;
      //str = arr.join(",")
      let strs = [];
      strs = arr.split(","); //字符分割
      strs.map(function (ele, index, arr) {
        me.info.medical_record_id = arr[1];
        me.info.medical_record_no = arr[2];
        me.info.real_name = arr[0];
        me.info.age = arr[3];
        me.info.sex = arr[4];
        me.info.mobile = arr[5];
        me.info.user_lvl_id = arr[6];
        me.info.user_id = arr[7];
        me.info.department_id = me.addParam.departmentValue;
      });
      me.addParam.medicalNO = me.info.medical_record_no;
      me.addParam.sexValue = me.info.sex;
      me.addParam.phoneInput = me.info.mobile;
    },
  },
  computed: {
     filterSelectedRanges() {
      let { startTime, endTime, selectedRanges } = this;

      return selectedRanges.filter(
        ({ start, end }) => start !== startTime && end !== endTime
      );
    },

    //search(8): 监听status值是否变化，变化就赋值
    listSet() {
      return this.states.map((item) => {
        return {
          value: `${item.name},${item.medicalID},${item.medicalNO},${item.age},${item.sex},${item.mobile},${item.lvl},${item.userID}`,
          label: `${item.name}-${item.mobile}`,
        };
      });
    },
    //*****************time range ************************ */
    applyButtonDisabled() {
      let { start, end } = this;

      return !start || !end;
    },
    //*****************time range ************************ */
  },
  methods: {
     //   //过滤
    classesSettingItems(doctorClassList) {

      let classes = (doctorClassList || []).map(({ classes_id }) => classes_id),
        me = this;

      if (classes.length) {

        me.newranges = me.ranges;
        me.computedRanges = me.newranges.filter(
          ({ classes_id }) => classes.includes(classes_id)
        );
      }

       if (!doctorClassList.length) {
          me.computedRanges = null;
       }
      //console.log("computedRanges-add",me.computedRanges);
     //  console.log("newranges-add",me.newranges);
    },
    //*****************time range ************************ */


   
    onTableRowDelete({ $index: index }) {
      let { selectedRangesAdd } = this;

      selectedRangesAdd.splice(index, 1);
    },

    onApply() {
      let me = this,
        { start, end, selectedRangesAdd } = me;

      selectedRangesAdd.push({
        start,
        end,
      });

      me.start = null;

      me.end = null;
    },
    //*****************time range ************************ */
    dataPicks() {},
    /**
     * 选择框的方法
     * @param index
     * @param item
     */
    onItemInput(index, item) {
      this.settingrowss[index].appointment_setting_id = item;
    },
    arraytotree(arr) {
      var top = [],
        sub = [],
        tempObj = {};

      arr.forEach(function (item) {
        if (item.parent_id === 0) {
          // 顶级分类
          top.push(item);
        } else {
          sub.push(item); // 其他分类
        }
        item.children = []; // 默然添加children属性
        tempObj[item.appointment_setting_id] = item; // 用当前分类的id做key，存储在tempObj中
      });

      sub.forEach(function (item) {
        // 取父级
        var parent = tempObj[item.parent_id] || {
          children: [],
        };
        // 把当前分类加入到父级的children中
        parent.children.push(item);
      });

      return top;
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    departmentChange(value) {
      if (value !== undefined) {
        for (var i = 0; i < value.length; i++) {
          this.addParam.departmentValue = value[i];
        }
      }
    },
    //选择医生时触发
    doctorChange(value) {
      let obj = {},
        me = this;

      me.selectedRangesAdd = [];

      me.addParam.start = "";

      me.addParam.end = "";

      obj = this.doctors.find((item) => {
        return item.doctorId === value;
      });
      me.info.doctorName = obj.name;
      me.info.department_id = obj.departmentID; //部门ID

      let newDay = new Date(this.addParam.dataPick);
      /** 获取医生排班记录 */
      let data = {
        // appointment_date:Date.parse(this.addParam.dataPick)/1000,
        appointment_date: me.addParam.dataPick, //newDay.getTime() / 1000,
        doctor_id: value,
        department_id: me.info.department_id,
        request_type: "add",
      };

      me.$emit("getdoctortimereturn", data);

      // 预约时间优化处理=>主要针对预约日历添加时有时间的状态
      if (me.addParam.start !== "") {
        me.appointTimeHandle();
      }

    },
    appointTimeHandle() {
      let classData = this.computedRanges;
      this.computedRanges.map((item, index) => {
      });
    },
    onChangeDatePicker(value) {
      //let newDay = new Date(value);
      /** 获取医生排班记录 */
      let data = {
        // appointment_date:Date.parse(this.addParam.dataPick)/1000,
        appointment_date: value, //newDay.getTime() / 1000,
        doctor_id: this.addParam.doctorValue,
        department_id: this.info.department_id,
        request_type: "add",
      };

      this.$emit("getdoctortimereturn", data);
    },
    symptomChange(value) {
      let obj = {};
      obj = this.symptom.find((item) => {
        return item.value === value;
      });
      this.info.doctorName = obj.label;
    },
    DurationChange(value) {
      let obj = {};
      obj = this.Duration.find((item) => {
        return item.value === value;
      });
      this.info.Duration = obj.label;
    },
    //search(1):触发事件
    remoteMethod(query) {
      if (query !== "") {
        this.loading = true;
        //search(2):触发上级事件
        this.$emit("medicalrecordsearch", query);
        setTimeout(() => {
          this.loading = false;
          this.options = this.listSet.filter((item) => {
            return item.label.toLowerCase().indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
    getRows() {
      //重组，修改看诊记录保存是所需的结构
      return this.settingrowss.map((item) => {
        return {
          appointment_setting_id: item.appointment_setting_id,
          name: item.name,
          parent_id: item.parent_id,
        };
      });
    },
    appointmentSaveClick() {
      let me = this;
      if (me.addParam.medicalNO == "" || me.addParam.medicalNO == null) {
        me.$alert("请输入选择姓名，如无患者，请去患者管理添加");

        return;
      } else if (me.addParam.doctorValue == "" || me.addParam.doctorValue == null) {
        me.$alert("请选择医生");

        return;
      } else if (me.addParam.dataPick == "" || me.addParam.dataPick == null) {
        me.$alert("请输入预约日期");

        return;
      } else if (me.addParam.start == "" || me.addParam.start == null) {
        me.$alert("请输入预约开始时间");

        return;
      } else if (me.addParam.end == "" || me.addParam.end == null) {
        me.$alert("请输入预约结束时间");

        return;
      }
      let data = {
        user_id: me.info.user_id,
        medicalNO: me.addParam.medicalNO, //
        sex: me.addParam.sexValue,
        phone: me.addParam.phoneInput,
        doctor_id: me.addParam.doctorValue,
        clinical_department_id: me.addParam.departmentValue,
        setting: me.getRows(),
        appointment_date: me.addParam.dataPick,
        appointment_time: me.addParam.startTime,
        start_time: me.addParam.start,
        end_time: me.addParam.end,
        patient_information: me.info,
        status: me.addParam.status, //预约状态 预约状态 0=待确认 1=确认 2=取消
        visit_status: me.addParam.visitStatusValue, //就诊状态 就诊状态 1=初诊 2=复诊 3=首诊
        create_id: me.userInfo.client_id, //创建人ID=doctor表
        selectedRanges: me.selectedRangesAdd,
        remarks:me.remind
        //status: this.status,
      };

      this.$emit("appointmentsaveclick", data);
    },
    changeDrawer(val) {
      this.drawer = val;
      //this.addParam = {};
      this.settingrows = [];
      this.userValue = "";
      this.addParam.doctorValue = "";
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  padding: 0 19px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 5px 5px 10px;
  position: relative;

  .search {
    width: 300px;
  }

  > .el-button {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}

// 新增回访
.see-patient-history-list-item {
  width: 100%;
  border: 1px solid #e1e1e1;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }
      .grid-textarea {
        height: 100px;
        line-height: 100px;
      }
      .bg-textarea {
        height: 96px;
        line-height: 96px;
      }

      .scheduling{
        // text-align: center;
        padding: 0 15px;
        font-size: 14px;
        font-weight: bold;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .edit-buttom {
    width: 100%;
    margin-top: 20px;
  }

  .time-ranges {
    display: none;
  }
}
</style>
