<template>
  <Container>
    <div class="screen">
      <el-button type="primary" @click="drawer = true">新增角色</el-button>
    </div>
    <div>
      <el-table
        ref="list"
        :data="RolelList"
        highlight-current-row
        stripe
        height="calc(100vh - 220px)"
        style="width: 100%">

        <el-table-column fixed prop="name" label="角色名称" width="150" align="center"></el-table-column>

        <el-table-column prop="jurisdiction" label="权限" min-width="180" align="center">
          <template slot-scope="scope">
            <span v-for="j in scope.row.jurisdiction">
              <span style="padding: 0 6px">{{j}}</span>
            </span>
          </template>
        </el-table-column>

        <el-table-column prop="personnel" label="人员" width="150" align="center"></el-table-column>

        <el-table-column label="操作" width="150" align="center">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="editRow(scope.$index, RolelList)"
              type="text"
              size="small">
              编辑
            </el-button>
            <el-button
              @click.native.prevent="deleteRow(scope.$index, RolelList)"
              type="text"
              size="small">
              删除
            </el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <el-drawer
      title="新增角色"
      :visible.sync="drawer"
      size="40%"
      style="fontSize:20px;">
      <AddRole></AddRole>
    </el-drawer>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components([
        "AddRole",
    ], "configuration.Role"),
    ...components(["Container"]),
  },
  data() {
    return {
        RolelList: [
            {
                name: "医生",     // 角色名称
                jurisdiction: ['执行站', '预约管理', '患者管理'],                // 权限
                personnel: '5',              // 人员
            }
        ],

        drawer: false

    };
  },
  methods:{
   
    // 删除一行
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    // 编辑
    editRow(index, rows){

    }
  }
};
</script>

<style lang="scss" scoped>
  .screen {
    margin-bottom: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;

  }

  .el-range-editor.el-input__inner{
    width: 100%;
  }

</style>
