<template>
  <Container class="statistics">
    <div class="text item">
      <el-table
        style="width: 100%"
        height="calc(100vh - 265px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
        <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="get_user.nickname"
          label="用户"
          width="200"
          align="left"
        ></el-table-column>
        <el-table-column label="订单" min-width="460">
          <template slot-scope="scope">
            <div class="order-summary cs-mb-5">
              <cs-icon class="cs-mr-10" :name="sourceMap[scope.row.source]" />
              <span class="cs-mr">订单号：{{ scope.row.order_no }}</span>
              <span style="margin-left:20px;">创建日期：{{ scope.row.create_time }}</span>
            </div>

            <div
              v-for="(goods, index) in scope.row.get_order_goods"
              :key="index"
              :class="{ 'order-goods-list': true, 'cs-mt-10': index > 0 }"
            >
              <el-image
                class="goods-image cs-cp"
                @click="handleView(goods.goods_id)"
                :src="goods.goods_image | getPreviewUrl"
                fit="contain"
                lazy
              >
              </el-image>

              <div class="goods-info order-text">
                <p @click="handleView(goods.goods_id)">
                  <span class="link">{{ goods.goods_name }}</span>
                  <span
                    :class="`${goods.is_service === 1 ? 'service' : 'complete'}`"
                    class="cs-pl-5"
                    >{{ serviceMap[goods.is_service] }}</span
                  >
                </p>
                <p v-if="goods.key_value" class="son">{{ goods.key_value }}</p>
                <p class="son">
                  本店价：{{ goods.shop_price | getNumber }} x {{ goods.qty }}
                </p>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="买家" align="center" min-width="160">
            <template slot-scope="scope">
              <div class="order-text">
                <p>
                  <span class="son">{{ scope.row.get_user.username }}</span>

                  <el-image
                    v-if="scope.row.get_user.level_icon"
                    class="level-icon"
                    :src="scope.row.get_user.level_icon"
                    fit="fill"
                  >
                    <div slot="error" class="image-slot">
                      <i class="el-icon-picture-outline" />
                    </div>
                  </el-image>
                </p>

                <p>
                  <span class="son">{{ scope.row.get_delivery | getDelivery }}</span>
                </p>

                <p>
                  <el-tooltip placement="top">
                    <div slot="content">
                      姓名：{{ scope.row.consignee }}<br />
                      手机：{{ scope.row.mobile }}<br />
                      <template v-if="scope.row.tel"
                        >电话：{{ scope.row.tel }}<br
                      /></template>
                      地址：{{ scope.row.complete_address }}
                      <template v-if="scope.row.zipcode"
                        ><br />邮编：{{ scope.row.zipcode }}</template
                      >
                    </div>
                    <i class="el-icon-house" />
                  </el-tooltip>

                  <el-tooltip
                    v-if="scope.row.buyer_remark"
                    :content="scope.row.buyer_remark"
                    placement="top"
                  >
                    <i class="el-icon-chat-dot-round cs-ml-10" />
                  </el-tooltip>

                  <el-tooltip v-if="scope.row.invoice_type > 0" placement="top">
                    <div slot="content">
                      发票抬头：{{ scope.row.invoice_title }}
                      <template v-if="scope.row.invoice_type === 2"
                        ><br />纳税人号：{{ scope.row.tax_number }}</template
                      >
                    </div>
                    <i class="el-icon-tickets cs-ml-10" />
                  </el-tooltip>
                </p>
              </div>
            </template>
          </el-table-column>
        
        <el-table-column label="订单金额" align="center" min-width="120">
          <template slot-scope="scope">
            <div class="order-text">
              <p class="shop-price">{{ scope.row.pay_amount | getNumber }}</p>
              <p class="son">需付款：{{ scope.row.total_amount | getNumber }}</p>
              <p class="son">含运费：{{ scope.row.delivery_fee | getNumber }}</p>
              <p class="son">
                <span :class="{ 'shop-price': scope.row.payment_code === '1' }">
                  <!-- {{_getPaymentType(scope.row.payment_code)}} -->
                </span>
              </p>

              <el-link
                v-if="scope.row.trade_status === 0 && scope.row.payment_status === 0"
                class="order-button"
                type="primary"
                @click="setOrderAmount(scope.$index)"
                :underline="false"
                >修改金额</el-link
              >
            </div>
          </template>
        </el-table-column>

          <el-table-column label="交易状态" align="center" min-width="100">
            <template slot-scope="scope">
              <div class="order-text">
                <p
                  v-for="(item, index) in getOrderStatus(scope.row)"
                  :key="index"
                  class="order-button"
                >
                  {{ item }}
                </p>

                <p>
                  <el-link
                    class="order-button"
                    type="info"
                    @click="handleInfo(scope.row.order_no)"
                    :underline="false"
                    >详情</el-link
                  >
                </p>
              </div>
            </template>
          </el-table-column>
         <el-table-column label="操作" align="center" min-width="100">
            <template slot-scope="scope">
              <div class="order-text">
                <p v-if="scope.row.delivery_status === 0 && scope.row.trade_status <= 1">
                  <el-link
                    class="order-button"
                    type="success"
                    @click="setOrderItem(scope.$index)"
                    :underline="false"
                    >修改订单</el-link
                  >
                </p>

                <p v-if="scope.row.payment_status === 1 && scope.row.trade_status === 0">
                  <el-link
                    class="order-button"
                    type="primary"
                    @click="handlePicking(1, scope.$index)"
                    :underline="false"
                    >设为配货</el-link
                  >
                </p>

                <p v-if="scope.row.payment_status === 1 && scope.row.trade_status === 1">
                  <el-link
                    class="order-button"
                    type="primary"
                    @click="handlePicking(0, scope.$index)"
                    :underline="false"
                    >取消配货</el-link
                  >
                </p>

                <p
                  v-if="
                    scope.row.payment_status === 1 &&
                    scope.row.delivery_status !== 1 &&
                    [1, 2].includes(scope.row.trade_status)
                  "
                >
                  <el-link
                    class="order-button"
                    type="primary"
                    @click="handleDelivery(scope.$index)"
                    :underline="false"
                    >确定发货
                  </el-link>
                </p>

                <p v-if="scope.row.delivery_status === 1 && scope.row.trade_status === 2">
                  <el-link
                    class="order-button"
                    type="primary"
                    @click="handleComplete(scope.$index)"
                    :underline="false"
                    >确认收货</el-link
                  >
                </p>

                <p v-if="scope.row.delivery_status !== 0">
                  <el-link
                    class="order-button"
                    type="primary"
                    @click="handleDeliveryDist(scope.row.order_no)"
                    :underline="false"
                    >物流信息</el-link
                  >
                </p>

                <p v-if="scope.row.trade_status <= 1">
                  <el-link
                    class="order-button"
                    type="danger"
                    @click="handleOrderCancel(scope.$index)"
                    :underline="false"
                    >取消订单</el-link
                  >
                </p>

                <p v-if="scope.row.trade_status === 4 && scope.row.is_delete <= 0">
                  <el-link
                    class="order-button"
                    @click="handleOrderRecycle(scope.$index, 1)"
                    :underline="false"
                    >删除订单</el-link
                  >
                </p>

                <p v-if="scope.row.is_delete > 0">
                  <el-link
                    class="order-button"
                    @click="handleOrderRecycle(scope.$index, 0)"
                    :underline="false"
                    >恢复订单</el-link
                  >
                </p>

                <p>
                  <el-tooltip
                    :disabled="scope.row.sellers_remark.length <= 0"
                    :content="scope.row.sellers_remark"
                    placement="left"
                  >
                    <el-link
                      class="order-button"
                      :type="scope.row.sellers_remark ? 'warning' : 'info'"
                      @click="setSellersRemark(scope.$index)"
                      :underline="false"
                      >备注</el-link
                    >
                  </el-tooltip>
                </p>
              </div>
            </template>
          </el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";
import util from "@/utils/util";
export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
    tableData: {
      default: () => [],
    },
    orderTotal: {
      default: () => {},
    },
  },
  data() {
    return {

      typeMap: [
        {
          text: "收入",
          type: "success",
          symbol: "+",
        },
        {
          text: "支出",
          type: "danger",
          symbol: "-",
        },
      ],

      moduleMap: {
        points: "积分",
        money: "消费",
        card: "购物卡",
      },
      stateMap: ['账户资金', '现金', '支付宝', '微信', '银行卡', '购物卡', '保险', '其它'],
      statusMap: ['未支付', '已支付'],
      tabPane: "0",
      tabList: ['全部', '未付款', '已付款', '待发货', '已发货', '已完成', '已取消', '回收站'],
      // 键名与"tabList"对应
      totalMap: [ '', 'not_paid', 'paid', 'not_shipped', 'shipped'],
      serviceMap: ['', '售后中', '已售后'],
      statusMap: ['待发货', '已发货', '已收货', '已取消'],
      sourceMap: {},
      formRemark: {
        index: undefined,
        loading: false,
        visible: false,
        request: {},
      },
      formAmount: {
        index: undefined,
        loading: false,
        visible: false,
        actual: 0,
        request: {},
      },
      formOrder: {
        index: undefined,
        loading: false,
        visible: false,
        request: {},
      },
      formDelivery: {
        index: undefined,
        loading: false,
        visible: false,
        delivery: 1,
        selection: [],
        goods: {},
        request: {},
      },
    };
  },
  filters: {
    getPreviewUrl(val) {
      console.log("getPreviewUrl", util.getImageCodeUrl(val, "goods_image_x80"));
      return val ? util.getImageCodeUrl(val, "goods_image_x80") : "";
    },
    getNumber(val) {
      return util.getNumber(val);
    },
    getDelivery(val) {
      return val ? val["alias"] : "";
    },
  },
  methods: {
    // 获取列表中的订单编号
    _getOrderNoList(val) {
      if (val === null) {
        val = this.multipleSelection;
      }

      let idList = [];
      if (Array.isArray(val)) {
        val.forEach((value) => {
          idList.push(value.order_no);
        });
      } else {
        idList.push(this.currentTableData[val].order_no);
      }

      return idList;
    },
    // 获取付款方式
    _getPaymentType(code) {
      if (this.toPayment.hasOwnProperty(code)) {
        return this.toPayment[code]["name"];
      }

      return "";
    },
    // 询问提示
    _whetherToConfirm(message = null, type = "warning") {
      let options = {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        closeOnClickModal: false,
        type,
      };

      let msg = message || "确定要执行该操作吗?";

      return this.$confirm(msg, "提示", options);
    },
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
    // 数字化标签名称
    getTabPaneName(key, value) {
      if (!this.totalMap.hasOwnProperty(key)) {
        return value;
      }
      //console.log("getTabPaneName",value);
      if (!this.orderTotal.hasOwnProperty(this.totalMap[key])) {
        return value;
      }

      if (this.orderTotal[this.totalMap[key]] <= 0) {
        return value;
      }

      return value + `(${this.orderTotal[this.totalMap[key]]})`;
    },
    // 选中数据项
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    // 获取订单来源配置
    getOrderSource() {
      getSettingList("system_shopping", "source").then((res) => {
        if (res.data) {
          res.data["source"]["value"].forEach((value, index) => {
            this.sourceMap[index] = value.icon;
          });
        }
      });
    },
    // 获取订单状态
    getOrderStatus(data) {
      let result = [];

      switch (data.trade_status) {
        // 待处理
        case 0:
          switch (data.payment_status) {
            case 0:
              result.push("等待买家付款");
              break;
            case 1:
              result.push("买家已付款");
              break;
          }
          break;
        // 配货中
        case 1:
          result.push("配货中");
          break;
        // 已发货
        case 2:
          switch (data.delivery_status) {
            case 1:
              result.push("全部发货");
              break;
            case 2:
              result.push("部分发货");
              break;
          }
          break;
        // 已完成
        case 3:
          result.push("交易成功");
          break;
        case 4:
          result.push("交易关闭");
          break;
      }

      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  overflow-y: auto;
}

.tab-box {
  padding: 5px 10px;
  background-color: #fff;
}
.el-table /deep/ td {
  background-color: #ffffff !important;
}
.order-summary {
  // color: $color-text-placehoder;
  font-size: 13px;
}
.order-text {
  p {
    margin: 0;
  }
  .son {
    //   color: $color-text-sub;
    font-size: 13px;
  }
}
.order-goods-list {
  float: left;
  .goods-image {
    float: left;
    width: 80px;
    height: 80px;
  }
  .goods-image-small {
    float: left;
    width: 60px;
    height: 60px;
  }
  .goods-info {
    padding: 0 50px 0 100px;
    .goods-name {
      height: 36px;
      line-height: 18px;
      overflow: hidden;
    }
    .link {
      &:hover {
        cursor: pointer;
        //   color: $color-primary;
        text-decoration: underline;
      }
    }
    .service {
      font-size: 13px;
      // color: $color-warning;
    }
    .complete {
      font-size: 13px;
      // color: $color-success;
    }
  }
}
.shop-price {
  // color: $color-danger;
}
.level-icon {
  margin-left: 5px;
  line-height: 0;
  vertical-align: text-bottom;
}
.order-button {
  padding: 0;
  font-size: 13px;
}
</style>
