<template>
	<div style="height: 100%; overflow: auto">
		<Container class="see-detail hb-layout-vertical">
			<Header
				@submit="handleSubmit"
				ref="header"
				:loading="loading"
				@submitreset="onSubmitReset"
				:brandDatas="brandDatas"
				:supplierDatas="supplierDatas"
				:factoryDatas="factoryDatas"
				:smallunitDatas="smallunitDatas"
				:storehouseCateDatas="storehouseCateDatas"
				:warehousDatas="warehousDatas"
			></Header>
			<Content
				ref="content"
				:loading="loading"
				:tableData="Data.items"
				@changeeditmaterial="onChangeEditMaterial"
				:storehouseRecordItem="storehouseRecordItem"
				@submitmaterial="onSubmitAddMaterial"
				:brandDatas="brandDatas"
				:supplierDatas="supplierDatas"
				:factoryDatas="factoryDatas"
				:smallunitDatas="smallunitDatas"
				:storehouseCateDatas="storehouseCateDatas"
				@delselectitem="onDelSelectItem"
			></Content>
			<BasicFooter
				:loading="loading"
				:size="Param.page_size"
				:total="Data.total_result"
				@change="handlePaginationChange"
			></BasicFooter>
		</Container>
	</div>
</template>

<script>
import {components, blocks} from "@/helper";

export default {
	components: {
		...components(["Header", "Content"], "storage.Stock.Left"),
		...components(["Container"]),
		...blocks(["BasicFooter"], "Footer"),
		...blocks(["BasicPagination"], "Pagination"),
	},
	
	props: {
		loading: {
			default: false,
		},
		storehouseCateDatas: {},
		brandDatas: {},
		supplierDatas: {},
		factoryDatas: {},
		smallunitDatas:{},
		storehouseDatas: {},
		Data: {},
		storehouseRecordItem: {},
		Param: {},
		warehousDatas:{}
		
	},
	
	data() {
		return {
			page: {
				current: 1,
				size: 0,
				total: 0,
			},
			order: {
				order_type: undefined,
				order_field: undefined,
			},
		};
	},


	
	methods: {
		// 分页变化改动
		handlePaginationChange(val) {
			this.$emit("changepagination", val);
		},
		pageChangeSubmit() {
			this.$nextTick(() => {
				this.$refs.header.handleFormSubmit();
			});
		},
		onSubmitAddMaterial(data) {
			let me = this;
			me.$emit("submitmaterial", data);
		},
		onDelSelectItem(data) {
			this.$emit("delselectitem", data);
		},
		onChangeEditMaterial(data) {
			let me = this;
			me.$emit("changeeditmaterial", data);
		},
		// 确定查询
		handleSubmit(form, isRestore = false) {
			this.$emit("changesubmit", form, isRestore);
		},
		onSubmitReset(form) {
			let me = this;
			me.$emit("submitreset", form);
		},
		onTodayClick() {
			let me = this;
			me.$emit("todayclick");
		},
		onWeekClick() {
			let me = this;
			me.$emit("weekclick");
			
		},
		onMonthClick() {
			let me = this;
			me.$emit("monthclick");
		},
		onYearClick() {
			let me = this;
			me.$emit("yearclick");
		},
		changeDrawer(v) {
			let me = this;
			me.$refs.header.changeDrawer(v);
			me.$refs.content.changeDrawers(v);
		},
	},
};
</script>

<style lang="scss" scoped></style>
