var init = {
    /* *******************************************商品管理*************************************************************************** */
    GoodsListSetting: {
     // name: "shopList", //如有有的参数，说明这个tabs有这个缓冲数据的需求
      dataName:"GoodsList",
      getUrl: "shop/Goods/getGoodsList",
    },
    AddGoodsSetting: {
        // name: "shopList", //如有有的参数，说明这个tabs有这个缓冲数据的需求
         dataName:"AddGoods",
         getUrl: "shop/Goods/getGoodsList",
       },
  };
  
  export default init;
  