<template>
  <Container class="hb-layout-vertical">
    <LeftSearch :loading="loading" @leftsearch="onleftSearch"></LeftSearch>

    <LeftScreen
      @todayevent="onTodayEvent"
      @sevenevent="onSevenEvent"
      @thirtyevent="onThirtyEvent"
      @calendarevent="onCalendarEvent"
    ></LeftScreen>

    <div class="hb-layout-flex-1" style="position: relative">
      <div class="hb-layout-fit-sized" v-if="dataLength>0">
        <List
          ref="list"
          class="hb-layout-fit-unsized"
          itemCls=".see-patient-list-item"
          @itemselect="onItemSelect"
          v-model="selected"
          :scroll="true"
          :data="medicalRecordList.items"
        >
          <template v-slot:item="{ record, selected }" >
            <LeftItem :recordData="record" :selected="selected"> </LeftItem>
          </template>
        </List>
      </div>
      <div class="hb-layout-fit-sized" v-else>
        <el-empty description="暂无数据" :image-size="100"></el-empty>
      </div>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Left.Item", "Left.Search", "Left.Screen"], "patient"),
    ...components(["List", "Container"]),
  },
  props: {
    medicalRecordList: {},
    loading: {},
  },
  watch: {

    
    'medicalRecordList.items'(data){

       // me.$nextTick(() => {
      //   if (data.length) {
      //     me.$refs.list.select(data[0]);
      //   }
      // });

      if(data.length){

        let me = this ;

        me.$nextTick(() => setTimeout(() => me.$refs.list.select(data[0]) , 0)) ;

        
      }

    },
    medicalRecordList(val) {
      let me = this;
      me.dataLength = val.items.length ;
     
      if (me.dataLength  > 0) {

        me.$nextTick(() => me.$refs.list.select(val[0]));
      }
    },
  },
  data() {
    return {
      selected: null,
      dataLength:0,
    };
  },
  methods: {
    onItemSelect(requestData) {
      //console.log("患者预约号", requestData);
      this.$emit("itemselect", requestData);
    },
    select(patient) {
      this.$refs.list.select(patient);
    },
    onleftSearch(command) {
      this.$emit("leftsearch", command);
    },
    onTodayEvent() {
      //console.log("2");
      this.$emit("todayevent");
    },
    onSevenEvent() {
      //console.log("2");
      this.$emit("sevenevent");
    },
    onThirtyEvent() {
      //console.log("2");
      this.$emit("thirtyevent");
    },
    onCalendarEvent(e) {
      this.$emit("calendarevent", e);
    },
  },
};
</script>

<style lang="scss" scoped>
$see-patient-list-header-height: 50px;

$see-patient-list-padding: 0 10px 0 10px;

.hb-layout-empty{
  width: 100%;
  height: 60px;
  line-height: 60px;
  font-size: 14px;
  text-align:center;
}

.see-patient-list {
  > .header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 16px 0 18px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;

    > .tody {
      padding-right: 15px;
    }
  }
}
</style>
