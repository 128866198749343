<template>
<Container class="statistics">
    <div class="text item">
         <!-- @row-click="detailsDrawer = true" -->
        <el-table ref="list" :data="tableData" highlight-current-row stripe height="calc(100vh - 265px)" style="width: 100%">
            <el-table-column fixed type="index" width="45"> </el-table-column>
            <el-table-column fixed prop="getPharmacy.name" label="名称" width="200" align="left"></el-table-column>
            <el-table-column prop="getPharmacy.english_name" label="英文名称" width="200" align="left"></el-table-column>
            <el-table-column label="数量/单位" min-width="90" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.unit_num }}/{{ scope.row.unitName }}</div>
                </template>
            </el-table-column>
            <el-table-column label="数量/规格单位" min-width="110" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.small_unit_num }}/{{ scope.row.smallUnitName }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="getStorehouse.stock" label="入库前库存量" width="170" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.old_stock}}/{{ scope.row.unitName }}-{{ scope.row.old_small_stock }}/{{ scope.row.smallUnitName }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="getPharmacy.trade_price" label="进价" min-width="70" align="left">
            </el-table-column>
            <el-table-column prop="getPharmacy.retail_price" label="零售价" min-width="70" align="left">
            </el-table-column>
            <el-table-column prop="batch_number" label="生产批号" min-width="110" align="left">
            </el-table-column>
            <el-table-column prop="product_date" label="生产日期" min-width="100" align="left">
            </el-table-column>
            <el-table-column prop="validity_date" label="有效期" min-width="70" align="left">
            </el-table-column>
            <el-table-column prop="end_date" label="过期日期" min-width="100" align="left">
            </el-table-column>
            <el-table-column prop="create_time" label="入库时间" width="160" align="left">
            </el-table-column>
        </el-table>
    </div>

    <el-drawer title="出库详情" :visible.sync="detailsDrawer" size="65%" style="fontSize:20px;">
        <DeliveryDetails></DeliveryDetails>
    </el-drawer>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: {
        ...components(["DeliveryDetails"], "pharmacy.Delivery"),
        ...components(["Container"]),
    },
    props: {
        tableData: {}
    },
    data() {
        return {
            detailsDrawer: false
        };
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;
}
</style>
