/**
 * 获取医生一周预约记录 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/appointment_record/method/get.doctor.appointment.basic.list.day';
//let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment_record/method/get.doctor.appointment.basic.list';

export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        })).then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items=[],
            total_result
        }) => {
            let result = items.map(({
                age,
                appointment_record_no,
                end,
                medical_record_no,
                patient,
                real_name,
                sex,
                start,
                appointment_type,
                state,
                status,
                visit_status,
                see_status,
                diagnosis_status,
                doctor_id,
                phone,
                register_time,
                department,
                departmentName,
                doctorName
        
            }) => ({
                name: real_name,
                sex: sex,
                age: age,
                doctor_id:doctor_id,
                startTime: start,
                visitTime: end,
                type: appointment_type,
                state: state,
                status: status,
                visitStatus:visit_status,
                phone: phone,
                register_time:register_time,
                appointment_record_no:appointment_record_no,
                medical_record_no:medical_record_no,
                department:department,
                departmentName:departmentName,
                doctorName:doctorName,
                diagnosis_status:diagnosis_status,
                see_status:see_status

            }))

            return {
                items: result,
                total_result
            };
        })
    // .then(({
    //     items
    // }) => items.map(
    //     ({
    //     age,
    //     appointment_record_no,
    //     end,
    //     medical_record_no,
    //     patient,
    //     real_name,
    //     sex,
    //     start
    // }) => ({
    //     name: real_name,
    //     sex: sex,
    //     age: age,
    //     arrivalTime: start,
    //     visitTime: end
    // })));
}