<template>
  <Container class="see-patient-list hb-layout-vertical">
    <el-row>
      <el-col :span="1">
        <div class="grid-content purple" v-html="'&#12288;'"></div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content purple height">项目 Item</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content purple height">数量 Number</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content purple height">单位 Price Unit</div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content purple height">备注 Description</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content purple height">价格 Amount（RMB）</div>
      </el-col>
    </el-row>

    <el-row v-for="(item, index) in Data" :key="index">
      <el-col :span="1">
        <div class="grid-content bg-purple">{{ index + 1 }}</div>
      </el-col>
      <el-col :span="6">
        <div class="grid-content bg-purple height">{{ item.name }} {{ item.englishName }}</div>
      </el-col>
      <el-col :span="3">
        <div class="grid-content bg-purple">{{ item.count }}</div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple height">
            {{ item.unit }}
            <span v-html="'&#12288;'"></span>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="grid-content bg-purple height" >
            {{ item.remark }} 
            <span v-html="'&#12288;'"></span>
            </div>
      </el-col>
      <el-col :span="5">
        <div class="grid-content bg-purple">
          <div>¥{{ item.price }}</div>
        </div>
      </el-col>
    </el-row>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
  },

  data() {
    return {
      statusOptions: [
        {
          value: undefined,
          label: "全部",
        },
        {
          value: 0,
          label: "待诊",
        },
        {
          value: 1,
          label: "接诊中",
        },
        {
          value: 2,
          label: "检测中",
        },
        {
          value: 3,
          label: "待付款",
        },
        {
          value: 4,
          label: "已付款",
        },
      ],
      //breakData:"&#12288;"
    };
  },
  watch: {},

  methods: {},
};
</script>

<style lang="scss" scoped>
$see-patient-list-header-height: 50px;

$see-patient-list-padding: 0 10px 0 10px;

.see-patient-list {
  > .header {
    width: 100%;
    height: 50px;
    line-height: 50px;
    padding: 0 16px 0 18px;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;

    > .tody {
      padding-right: 15px;
    }
  }
}
.box-card {
  margin-bottom: 15px;
  box-shadow: 0 2px 7px 0 #d8d8d8;

  .el-row {
    // margin-bottom: 20px;
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    // border-right: 1px solid #e1e1e1;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 1px solid #e1e1e1;
    }
    &:first-child {
      background: #ebebeb;
    }
  }
  .bg-purple {
    text-align: center;
    border-right: 1px solid #e1e1e1;
  }
  .height{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .purple {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  .bg-purple {
    padding: 0 5px;
    font-size: 14px;
    color: #5b5b5b;
    font-family: PingFangSC-Medium, PingFang SC;
  }

  .display {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;

    .display-item {
      display: flex;
      // align-items: center;

      .display-item-name {
        padding-right: 100px;
      }
    }
  }
}
</style>
