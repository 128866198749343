<template>
  <!-- <div>
    <div class="list" style="border-bottom: 1px solid #DCDFE6">
      <div class="left">
        <div>现金账户</div>
      </div>
      <div class="right">
        <div class="item">
          <div class="price">¥{{ parseFloat(usermoney.balance).toFixed(2) }}元</div>
          <div class="name">账户余额</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">
            ¥{{ parseFloat(usermoney.total_recharge).toFixed(2) }}元
          </div>
          <div class="name">累计充值</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">¥{{ parseFloat(usermoney.total_money).toFixed(2) }}元</div>
          <div class="name">累计消费</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">¥{{ parseFloat(usermoney.points).toFixed(2) }}元</div>
          <div class="name">账户积分</div>
        </div>
      </div>
    </div>

    <div class="list" style="padding: 10px 0 0 0">
      <div class="left">
        <div>保险账户</div>
      </div>
      <div class="right">
        <div class="item">
          <div class="price">
            <el-input v-model="insuranceAmount" placeholder="请输入已结算金额"   @blur="onBlur($event,'insurance_amount')"></el-input>
          </div>
          <div class="name">已结算金额</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">
            <el-input
              v-model="insuranceCount"
              placeholder="请输入剩余保险次数"
              @blur="onBlur($event,'insurance_count')"
            ></el-input>
          </div>
          <div class="name">剩余保险次数</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">
            <el-input v-model="insuranceBalance" placeholder="请输入福利余额"   @blur="onBlur($event,'insurance_balance')"></el-input>
          </div>
          <div class="name">福利余额</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">
            <el-input v-model="insuranceProportion" placeholder="请输入报销比例" @blur="onBlur($event,'insurance_proportion')"></el-input>
          </div>
          <div class="name">报销比例</div>
        </div>
      </div>
    </div>
  </div> -->
  <el-tabs type="border-card">
    <el-tab-pane label="现金账户">
      <div class="right">
        <div class="item">
          <div class="price">¥{{ parseFloat(usermoney.balance).toFixed(2) }}元</div>
          <div class="name">账户余额</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">
            ¥{{ parseFloat(usermoney.total_recharge).toFixed(2) }}元
          </div>
          <div class="name">累计充值</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">¥{{ parseFloat(usermoney.total_money).toFixed(2) }}元</div>
          <div class="name">累计消费</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div class="price">¥{{ parseFloat(usermoney.points).toFixed(2) }}元</div>
          <div class="name">账户积分</div>
        </div>
      </div>
    </el-tab-pane>
    <el-tab-pane label="保险账户">
      <div class="right">
        <div class="item">
          <div>
            <el-input v-model="insuranceAmount" placeholder="请输入已结算金额" suffix-icon="el-icon-edit" @blur="onBlur($event,'insurance_amount')"></el-input>
          </div>
          <div class="name" style="margin-top:5px">已结算金额</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div>
            <el-input v-model="insuranceCount" placeholder="请输入剩余保险次数" suffix-icon="el-icon-edit" @blur="onBlur($event,'insurance_count')"></el-input>
          </div>
          <div class="name" style="margin-top:5px">剩余保险次数</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div>
            <el-input v-model="insuranceBalance" placeholder="请输入福利余额" suffix-icon="el-icon-edit" @blur="onBlur($event,'insurance_balance')"></el-input>
          </div>
          <div class="name" style="margin-top:5px">福利余额</div>
        </div>
        <div class="line"></div>
        <div class="item">
          <div>
            <el-input v-model="insuranceProportion" placeholder="请输入报销比例" suffix-icon="el-icon-edit" @blur="onBlur($event,'insurance_proportion')"></el-input>
          </div>
          <div class="name" style="margin-top:5px">报销比例</div>
        </div>
      </div>
    </el-tab-pane>
  </el-tabs>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    usermoney: {},
  },
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: "",
      insuranceAmount:"",
      insuranceCount:"",
      insuranceBalance:"",
      insuranceProportion:"",

    };
  },
  watch: {
    usermoney: function (val) {
      // console.log("sdsd",val);
      this.insuranceAmount = val.insurance_amount;
      this.insuranceCount = val.insurance_count;
      this.insuranceBalance = val.insurance_balance;
      this.insuranceProportion = val.insurance_proportion;
    },
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.dynamicTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    onBlur(e,type) {
      let data = {
        [type]:e.target.value,
      //  dataname:type,
      //  datavalue:e.target.value,
       clientId:this.usermoney.user_money_id
      }
      this.$emit("insuranceblurvalue",data);
    },
  },
};
</script>

<style lang="scss" scoped>
// .patient-detail-base {

//   .list {
//     width: 100%;
//     // display: flex;
//     // align-items: center;
//     padding: 0 0 20px 0;

//     .left {
//       // width: 120px;
//       height: 50px;
//       line-height: 50px;
//       // text-align: center;
//       white-space: nowrap;
//       font-weight: 500;
//       font-size: 16px;
//       color: #5b5b5b;
//       margin-bottom: 10px;
//       // border-right: 1px solid #DCDFE6;
//       margin-right: 10px;
//     }

//     .right {
//       // width: calc(100% - 120px);
//       width: 100%;
//       display: flex;
//       align-items: center;
//       justify-content: space-between;

//       .line{
//         margin: 0 20px;
//         background: #DCDFE6;
//         width: 1px;
//         height: 60px;
        
//       }

//       .item {
//         width: 25%;
//         text-align: center;
//         overflow: hidden;
//         white-space: nowrap;
//         // margin: 0 20px;

//         .price {
//           // font-size: 18px;
//           color: #606266;
//           line-height: 30px;
//         }

//         .name {
//           font-size: 13px;
//           line-height: 30px;
//           color: rgb(128, 128, 128);
//         }
//       }
//     }
//   }
// }

.right {
  // width: calc(100% - 120px);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .line{
    margin: 0 20px;
    background: #DCDFE6;
    width: 1px;
    height: 60px;
    
  }

  .item {
    width: 25%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    // margin: 0 20px;

    .price {
      // font-size: 18px;
      color: #606266;
      line-height: 40px;
    }

    .name {
      font-size: 13px;
      line-height: 30px;
      color: rgb(128, 128, 128);
    }
  }
}

.grade {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;

  .name {
    white-space: nowrap;
    margin-right: 5px;
    font-size: 14px;
  }
  .content {
    width: 70%;
    display: flex;
    align-items: center;
    font-size: 14px;
  }
}
/deep/.el-tag + .el-tag {
  margin-right: 10px;
}
/deep/.button-new-tag {
  margin-right: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
/deep/.input-new-tag {
  width: 90px;
  margin-right: 10px;
  vertical-align: bottom;
}
/deep/.el-tag {
  margin-right: 10px;
}
/deep/.el-input__inner{
  height: 35px;
  line-height: 35px;
  border: none;
  border-bottom: 1px solid #DCDFE6;
  border-radius: 0;
}
</style>
