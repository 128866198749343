import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";
/**
 * 医生获取患者列表 API
 */
let url = serverConfig.BASE_API + '/v2/payment/method/get.historical.bills.list/';

//TODO 用患者管理的患者列表做测试，上面的接口没有数据
//let url = serverConfig.BASE_API + '/v2/patient/method/get.patient.basic.list/';


export default (data) => {
 
    return axios.post(url, {
            ...data
        }).then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items
        }) => items.map(({
            pay_amount,
            use_money,
            use_level,
            use_promotion,
            use_coupon,
            use_discount,
            total_amount,
            payment_time,
            remark
        }) => ({
            pay_amount:pay_amount, 
            use_level:use_level,  
            use_money:use_money,  
            use_promotion:use_promotion,  
            use_coupon:use_coupon,  
            use_discount:use_discount,  
            total_amount:total_amount,  
            payment_time:payment_time,  
            remark:remark, 

        })));
}