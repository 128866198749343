<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header></Header>
    <Content
      ref="rightSelectData"
      v-if="tabDatas == 1"
      @submititemdata="onSubmitItemData"
    ></Content>
    <ContentActivities
      ref="rightSelectData"
      v-if="tabDatas == 2"
      @submititemdata="onSubmitItemData"
    ></ContentActivities>
    <ContentPromotion
      ref="rightSelectData"
      v-if="tabDatas == 5"
      :departmentData="departmentData"
      :rolesData="rolesData"
      @submititemdata="onSubmitItemData"
    ></ContentPromotion>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      ["Header", "Content", "ContentActivities", "ContentPromotion"],
      "configuration.Marketing.Right"
    ),

    ...components(["Container"]),
  },

  props: {
    rolesData: {},
    departmentData: {},
    treeDatas: {},
    tabData: 0,
  },
  watch: {
    tabData: function (val) {
      this.tabDatas = val;
    },
  },

  data() {
    return {
      tabDatas: 0,
    };
  },
  methods: {
    handleSelectItems(itemData) {
      this.$refs.rightSelectData.handleSelectItemss(itemData);
    },
    onSubmitItemData(itemData) {
      this.$emit("submititemdata", itemData);
    },
    onAddSubmit() {
      this.$refs.rightSelectData.onAddSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
