<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
    :with-header="false"
  >
    <template>
    <div style="height: 100vh;">
      <div class="overflowAuto">
        <div style="page-break-after: always" ref="print" id="print">
          <div class="header-top">
            <div class="header-logo">
              <img src="../../../public/image/logopg.png" />
              <div class="header-title">我们将用卓越的医疗服务与照护切实改善您的生活质量</div>
            </div>
          </div>
          <div class="print-order">
            <el-row style="border: none; margin: 10px 0 5px">
              <el-col :span="11"> 姓名Name：{{ real_name }} </el-col>
              <el-col :span="8"> 性别Gender： {{ sexMap[sex] }} </el-col>
              <el-col :span="5"> 年龄Age：{{ age }} </el-col>
            </el-row>
            <el-row style="border: none;  margin: 10px 0 5px">
              <el-col :span="11">
                病历号Medical Record No：{{ medical_record_no }}
              </el-col>
              <el-col :span="8" v-if="diagnosisData.length>0">
                看诊医生Diagnose Doctor ： {{ diagnosisData.item.getDoctor.nickname}}
              </el-col>
              <el-col :span="5"> 日期Date： {{ currentDate }} </el-col>
            </el-row>

            <el-row style="border-bottom: 1px solid #e1e1e1;margin: 20px 0 0;">
              <el-col :span="6">
                <div class="grid-content purple">诊断 Diagnosis</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content purple">
                  {{ diagnosisData.item.impressions.join(",") }}
                </div>
              </el-col>
            </el-row>
            <div>
              <div class="title">诊疗 Consultation</div>
              <PayColumn :Data="diagnosisData.consulation"></PayColumn>
            </div>

            <div>
              <div class="title">检查项目 Examination</div>
              <PayColumn :Data="inspectionItems"></PayColumn>
            </div>
            <div v-if="treatmentItems.length > 0">
              <div class="title">治疗项目 Treatment</div>
              <PayColumn :Data="treatmentItems"></PayColumn>
            </div>
            <div  v-if="westernmedicineItems.length > 0">
              <div class="title">西药处方 Pharmacy Prescription(Western Medicine)</div>
              <PayColumn :Data="westernmedicineItems"></PayColumn>
            </div>
            <div v-if="pharmacyItems.length > 0">
              <div class="title">中药处方 TCM Prescription</div>
              <PayColumn :Data="pharmacyItems"></PayColumn>
            </div>
            <div>
              <div class="title">账单汇总表 Bill Summary</div>
              <el-row>
                <el-col :span="18">
                  <div class="grid-content purple">项目 Item</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">费用 Amount（RMB）</div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="18">
                  <div class="grid-content purple">总计金额 Total Price</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">
                    {{ parseFloat(paymentItems.pay_amount).toFixed(2) }}
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="paymentItems.use_level != 0">
                <el-col :span="18">
                  <div class="grid-content purple">会员折扣 Member Discount</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">
                    <div>
                      -{{ parseFloat(paymentItems.use_level).toFixed(2) }}
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="paymentItems['use_promotion'] != 0">
                <el-col :span="18">
                  <div class="grid-content purple">
                    优惠活动抵扣 Promotion Discount
                  </div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">
                    <div>
                      -{{ parseFloat(paymentItems.use_promotion).toFixed(2) }}
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="paymentItems.use_insurance != 0">
                <el-col :span="18">
                  <div class="grid-content purple">保险抵扣 Insurance Discount</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">
                    <div>
                      -{{ parseFloat(paymentItems.use_insurance).toFixed(2) }}
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="paymentItems.use_coupon !== 0">
                <el-col :span="18">
                  <div class="grid-content purple">优惠券抵扣 Coupon Discount</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">
                    <div>
                      -{{ parseFloat(paymentItems.use_coupon).toFixed(2) }}
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="paymentItems.use_integral != 0">
                <el-col :span="18">
                  <div class="grid-content purple">积分抵扣 Integral Discount</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">
                    <div>
                      -{{ parseFloat(paymentItems.use_integral).toFixed(2) }}
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row v-if="paymentItems.use_justment != 0">
                <el-col :span="18">
                  <div class="grid-content purple">额外抵扣 Additional Discount</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">
                    <div>
                      -{{ parseFloat(paymentItems.use_justment).toFixed(2) }}
                    </div>
                  </div>
                </el-col>
              </el-row>
              <el-row>
                <el-col :span="18">
                  <div class="grid-content purple">实付金额 Patient Amount Due</div>
                </el-col>
                <el-col :span="6">
                  <div class="grid-content purple">
                    <div>
                      {{ parseFloat(paymentItems.total_money).toFixed(2) }}
                    </div>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
          <div class="signature">
            <div>
              Signature of staff:
            </div>
            <div>
              Signature of patient:
            </div>
          </div>
          <div class="header-footer">
            <div class="footer-logo">
              <img src="../../../public/image/telephone.png" />
              <div class="footer-title">0512-6580-8308</div>
            </div>
            <div class="footer-right">
              <div class="footer-text">苏州工业园区旺墩路  新罗酒店B栋一楼</div>
              <div class="footer-text">Opening Hours 营业时间  Mon-Sun周一到周日，8:30-20:30</div>
            </div>
          </div>
        </div>
      </div>

      <div class="footer">
        <el-button type="primary"  @click="$print($refs.print)"
          >打印</el-button
        >
        <el-button @click="handleClose()">取消</el-button>
      </div>
    </div>


    </template>
  </el-drawer>
</template>

<script>
import { components, blocks } from "@/helper";
import moment from "moment";

export default {
  components: {
    // ...components([
    //     "Single"
    //    ], "charge.list"),
    ...components(["List", "Container"]),
    ...blocks(["PayColumn"], "Templates"),
  },
  props: {
    real_name: {
      type: String,
      default: "",
    },
    sex: {
      type: String | Number,
      default: "",
    },
    age: {
      type: String | Number,
      default: "",
    },
    medical_record_no: {
      type: String,
      default: "",
    },
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    diagnosisData: {},
    treatmentItems: {},
    inspectionItems: {},
    pharmacyItems: {},
    westernmedicineItems: {},
    paymentItems: {},
    paymentRecordData: {},
  },
  data() {
    return {
      drawer: false,
      sexMap: ['保密', '男', '女'],
      currentDate: moment(new Date).format('YYYY-MM-DD'), // 当前时间
    };
  },
  watch: {
    paymentRecordData:function (val) {
      //console.log("logval",val);
    }
  },

  methods: {
    changeDrawer(v) {
      //console.log("112");
      this.drawer = v;
    },
    handleClose() {
      this.drawer = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.el-drawer__body {
  overflow: auto !important;
}

.item-img {
  position: relative;
}

.overflowAuto {
  overflow-y: auto;
  height: 100%;
  padding: 20px 20px 30px;
}

.overflowAuto::-webkit-scrollbar {
  height: 6px;
  width: 6px;
}

.overflowAuto::-webkit-scrollbar-thumb {
  background: rgb(224, 214, 235);
}

.title {
  height: 30px;
  width: 100%;
  line-height: 30px;
  // padding-left: 10px;
  margin-top: 8px;
  font-weight: bold;
}

.el-row {
  border-top: 1px solid #e1e1e1;
  border-left: 1px solid #e1e1e1;
  line-height: 17px;
  // margin-left: 5px;

  &:last-child {
    margin-bottom: 0;
    border-bottom: 1px solid #e1e1e1;
  }
}

.purple {
  padding: 5px 15px;
  border-right: 1px solid #e1e1e1;
}
.grid-content {
  font-size: 12px;
}

.header-top {
  display: block;
  width: 100%;
  margin-bottom: 20px;
  margin-top: 20px;

  .header-logo{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 5px solid #97c2bb;
    padding-bottom: 20px;

    > img{
      width: 270px;
      height: 65px;
    }

    .header-title{
      color: #97c2bb;
      width: 290px;
      flex-wrap: wrap;
      font-size: 20px;
      line-height: 32px;
      text-align: end;
    }
  }

}

.signature{
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-top: 20px;

  > div{
    width: 50%;
  }
}

.header-footer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-top: 3px solid #97c2bb;
  margin-top: 20px;
  padding-top: 20px;
  // margin-bottom: 100px;

  .footer-logo{
    display: flex;
    align-items: center;

    > img{
      width: 40px;
      height: 40px;
      margin-right: 10px;
    }

    .footer-title{
      color: #97c2bb;
      font-size: 22px;
      font-weight: bold;
    }
  }

  .footer-right{
    color: #97c2bb;
    font-size: 16px;

    .footer-text{
      line-height: 24px;
    }
  }
}

.footer{
  position: fixed;
  bottom: 20px;
  z-index: 99;
  right: 30px;
}

@media print {

  @page {
    size: auto;
    margin: 10mm 9mm;
  }

  body {
    height: auto;
  }

  #print {
    // height: 291mm; //分页  根据实际
    height: 1090px; //分页  根据实际
    position: relative;

    &::before {
      position: absolute;
      content: "乐享健康新生活";
      color: #54958a;
      top: 25%;
      left: 25%;
      font-size: 48px;
      opacity: 0.1;
    }

    &::after {
      position: absolute;
      content: "乐享健康新生活";
      color: #54958a;
      bottom: 35%;
      left: 25%;
      font-size: 48px;
      opacity: 0.1;
      // transform: rotate(30deg);
    }
  }
}
#print {
  background: #fff;
  box-sizing: border-box;
  // padding-top: 40px;
}

.print-order {
  //padding: 20px;
  min-height: 780px;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}
</style>
