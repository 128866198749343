<template>
  <Container class="patient-detail-doctor">
     <el-timeline>
          <el-timeline-item
            v-for="(path, index) in Data"
            :key="index"
            :color="path.getStatus.color"
            :timestamp="path.create_time">
            {{path.getStatus.name}}
          </el-timeline-item>
        </el-timeline>
  </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
  props:{
    Data:{}
  },
  components: components([
    'Container'
  ]),
}
</script>

<style lang="scss" scoped>
.patient-detail-doctor{
  padding-left: 2px; 
}
</style>
