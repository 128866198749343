<template>
  <div class="hb-layout-fit-unsized hb-layout-horizontal">
    <div class="calendar hb-layout-vertical hb-layout-flex-1">
      <div class="calendar-details">
        <div class="toolbar hb-layout-center">
          <el-button type="primary" @click="onPreviousButtonClick">上一周</el-button>
          <el-button type="primary" @click="onNextButtonClick">下一周</el-button>
        </div>
        <div class="form">
          <Calendar
            ref="calendar"
            :times="times"
            :plans="plans"
            class="hb-layout-flex"
            @blankclick="onBlankClick"
            @weekchange="onWeekChange"
          >
            <template v-slot:plan="props">
              <div @click="onPlanClick(props.plan)" class="plan hb-layout-center">
                {{ props.plan.patient }}
              </div>
            </template>
          </Calendar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "hb_calendar/dist/calendar.css";

import Calendar from "hb_calendar";

export default {
  props: {
    doctorClassesListData: {},
    classesSettingData: {},
    doctordaylistData: {}, //
    doctorDayTime: true,
    times: Array,
    plans: Array,
  },

  components: {
    Calendar,
  },

  methods: {
    onWeekChange(date) {
      //console.log("周一日期 detail", date);
      this.$emit("weekchang", date);
    },
    onPreviousButtonClick() {
      this.$refs.calendar.previous();
    },

    onNextButtonClick() {
      this.$refs.calendar.next();
    },

    onDoctorButtonClick(plans) {
      this.plans = plans;
    },

    onPlanClick(plan) {
      this.$emit("planclick", plan);
      //console.log('预约信息' , plan) ;
    },
    onBlankClick(time) {
      this.$emit("blankclick", time);
      //console.log("空白处点击", time);
    },
    onGetDoctorTimeReturn(val) {
      this.$emit("doctortimereturn", val);
    },
  },
};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.doctors {
  width: 200px;
  padding: 5px;
  border-right: 1px solid #000;

  > button {
    margin: 10px 0 10px 0;
  }
}

.calendar {
  padding: 5px;
}

.calendar-details {
  height: 100%;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  padding: 10px;
}

.toolbar {
  height: 40px;
  margin-bottom: 20px;

  > button {
    margin: 0 10px 0 10px;
  }
}

.plan {
  background-color: rgb(235, 192, 192);
}

.plan:hover {
  background-color: rgb(242, 216, 216);
}

.hb-calendar.week {
  .title {
    height: 30px !important;
  }

  .time:hover {
    background-color: rgb(242, 216, 216);
  }

  > .times {
    border: none !important;
    border-right-width: 0 !important;
    line-height: 30px;

    > .title,
    > .time:not(:last-child) {
      border-bottom: 1px solid #979797 !important;
    }
  }

  > .weeks {
    > .week {
      border: none !important;
      border-left: 1px solid #979797 !important;
      border-right: none !important;

      > .title,
      > .time:not(.time:last-of-type) {
        border-bottom: 1px solid #979797 !important;
      }
    }
  }
}

.hb-layout-flex {
  border: 1px solid #979797 !important;
}

.form {
  height: calc(100% - 80px);
  overflow-y: auto;
}
</style>
