<template>
    
    <Container class="page hb-layout-fit-sized">
        <slot></slot>
    </Container>

</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components:components([
    'Container'
  ])
}
</script>
<style lang="scss" scoped>
	.hb-layout-fit-sized{
		margin-top: 4px;
	}
</style>