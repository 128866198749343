import axios from 'axios' ;
/**
 * 检查项目模板
 */

// export default () =>{

//     return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/inspection_template/method/get.inspection.template.list').then(({
//         data
//     }) => data)
//     .then(({
//         data
//     }) => data)
//     .then(({
//         items
//     }) => items.map(({
//         get_inspection_template
//     }) =>({
// 		Inspection_template_id:get_inspection_template.Inspection_template_id,
//         name:get_inspection_template.name,
          
//     })));
// }

export default () =>{

    return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/inspection_template/method/get.inspection.template.list').then(({
        data
    }) => data)
    .then(({
        data
    }) => data.map(({
		Inspection_template_id,
		name,
		create_time,
		update_time
	})=>({
		Inspection_template_id:Inspection_template_id,
		name:name,
		create_time:create_time,
		update_time:update_time
	})));
}