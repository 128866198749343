<template>
  <Container class="see-main-operation hb-layout-horizontal">
    <div class="header">
      <div class="title">档案详情</div>
      <div style="display: flex;align-items: center;">
        <el-button type="success" @click="updateDrawer()" icon="el-icon-tickets">新增档案</el-button>

        <div style="margin-left:10px">
          <el-dropdown split-button type="primary">
            家庭档案
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>成员1</el-dropdown-item>
              <el-dropdown-item>成员2</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        
      </div>
    </div>

    <AddInfo
      ref="medicalAdd"
      @preservation="onPreservation"
      :drawer_title="drawer_add_title"
      :size="size"
      :direction="direction"
      :regionDatas="regionDatas"
      :historyDatas="historyDatas"
    ></AddInfo>

  </Container>
</template>

<script>
import { components } from "@/helper";
export default {
  props: {
    Data: {
      //病历信息
      type: Object,
      default: () => {},
    },
    regionDatas:{},
    historyDatas:{}
  },
  components: {
    ...components(["AddInfo"], "patient.Center.Archives"),
    ...components(["List", "Container"]),
  },
  data() {
    return {
      direction: "rtl",
      size: "75%",
      drawer_add_title: "新增档案",
      // nameInput: "", //姓名
      surnameInput: "", //姓
      nameInput: "", //名
      chineseNameInput: "", //中文姓名
      sexOptions: [
        {
          value: 0,
          label: "保密",
        },
        {
          value: "1",
          label: "男",
        },
        {
          value: "2",
          label: "女",
        },
      ],
      sexValue: "", //性别
      birthdayValue: "", //生日
      statusIdInput: "", // 身份证号码
      socialSecurityInput: "", // 社保号码
      countryOptions: [
        {
          value: "1",
          label: "中国",
        },
        {
          value: "2",
          label: "美国",
        },
      ],
      countryValue: "", // 国家
      ageInput: "", // 年龄
      heightInput: "", // 身高
      weightInput: "", // 体重
      nativePlaceOptions: [
        {
          value: "1",
          label: "江苏省",
        },
        {
          value: "2",
          label: "湖北省",
        },
      ],
      nativePlaceValue: "", // 籍贯
      maritalStatusOptions: [
        {
          value: 0,
          label: "保密",
        },
        {
          value: "1",
          label: "未婚",
        },
        {
          value: "2",
          label: "已婚",
        },
      ],
      maritalStatusValue: "", //婚姻状况
      nationOptions: [
        {
          value: "1",
          label: "汉族",
        },
        {
          value: "2",
          label: "维吾尔族",
        },
      ],
      nationValue: "", // 民族
      phoneInput: "", // 手机号
      telephoneInput: "", // 电话
      postcodeInput: "", // 邮编
      locationInput: "", // 所在地区
      detailedAddressInput: "", //详细地址
      insuranceCompanyOptions: [
        {
          value: "1",
          label: "中国人寿保险",
        },
        {
          value: "2",
          label: "中国太平洋保险",
        },
      ],
      insuranceCompanyValue: "", // 保险公司
      reimbursementRatioInput: "", // 报销比例
      allergicInput: "", //过敏史
      pastInput: "", //既往史
      familyInput: "", // 家族史
      presentDiseaseInput: "", // 现病史
      traumaInput: "", // 外伤史
      operationInput: "", // 手术史
      infectiousDiseasesInput: "", // 传染病史
      vaccinationHistoryInput: "", // 预防接种史
      diagnosis_record_no: "MAA_B5148560515416360001",
    };
  },
  methods: {
    changeDrawer(val){
      this.$refs.medicalAdd.changeDrawer(val);
    },
    onPreservation(val) {
      //console.log("add2", val);
      this.$emit("preservation", val);
    },
    handleChange(value) {
     // console.log(value);
    },
    updateDrawer() {
      this.$emit("regionlist");
      this.$emit("historylist");
      this.$refs.medicalAdd.changeDrawer(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.see-main-operation {
  width: 99%;
  align-items: center;
  padding: 0 19px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 5px auto 6px;

  .header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .title {
      font-size: 16px;
      white-space: nowrap;
      margin-right: 20px;
    }
  }
}
</style>
