<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header></Header>
    <Content
      ref="rightSelectData"
      v-if="tabDatas == 1"
      @submititemdata="onSubmitItemData"
    ></Content>
    <ContentLevel
      ref="rightSelectData"
      v-if="tabDatas == 2"
      :companyDatas="companyDatas"
      @submititemdata="onSubmitItemData"
    ></ContentLevel>
    <ContentSetting
      ref="rightSelectData"
      v-if="tabDatas == 3"
      :companyDatas="companyDatas"
      :levelDatas="levelDatas"
      @submititemdata="onSubmitItemData"
      @selectlevelitem="onSelectLevelItem"
    ></ContentSetting>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      ["Header", "Content", "ContentLevel", "ContentSetting"],
      "configuration.Insurance.Right"
    ),

    ...components(["Container"]),
  },

  props: {
    levelDatas:{},
    companyDatas: {},
    treeDatas: {},
    tabData: 0,
  },
  watch: {
    tabData: function (val) {
      this.tabDatas = val;
      // console.log(this.tabDatas);
    },
  },

  data() {
    return {
      tabDatas: 0,
    };
  },
  methods: {
    handleSelectItems(itemdata) {
      this.$refs.rightSelectData.handleSelectItemss(itemdata);
    },
    onSubmitItemData(itemdata) {
      //console.log("12112", itemdata);
      this.$emit("submititemdata", itemdata);
    },
    onAddSubmit() {
      this.$refs.rightSelectData.onAddSubmit();
    },
    onSelectLevelItem(data) {
      this.$emit("selectlevelitem", data);
    },
  },
};
</script>

<style lang="scss" scoped>
// .see-patient-history-list-item {
//   width: 99%;
//   padding: 0 19px;
//   height: calc(100vh - 140px);
//   box-shadow: 0 2px 7px 0 #d8d8d8;
//   margin: 2px 0px 10px 2px;
// }
</style>
