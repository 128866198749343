<template>
  <Container class="charge-details-content">
    <div class="list">
      <div id="diseaseChart" :style="{width: '470px', height: '330px'}"></div>
    </div>
  </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props:{
    
  },
  data() {
    return {

    }
  },
  mounted(){
    this.drawLine();
  },
  methods: {
    drawLine(){
        // 基于准备好的dom，初始化echarts实例
        let diseaseChart = this.$echarts.init(document.getElementById('diseaseChart'))
        // 绘制图表
        diseaseChart.setOption({
            xAxis: {
              type: 'category',
              data: ['病种1', '病种2', '病种3', '病种4', '病种5', '病种6']
            },
            yAxis: {
              type: 'value',
            },
            grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            series: [{
                data: [120, 200, 150, 80, 70, 110],
                type: 'bar',
                barWidth: '50%',
                showBackground: true,
                backgroundStyle: {
                    color: 'rgba(180, 180, 180, 0.2)'
                },
                color: '#9FCDC0'
            }]
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.charge-details-content{
  margin-top: 15px;
  .list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
