<template>
    <Container :class="{
        [`hb-layout-${layout}`]:true
    }">
        <template v-for="record of data">
            <slot name="item" :record="record" :selected="selectedRecords.includes(record)">
            </slot>
        </template>
    </Container>
</template>

<script>

import {
    components
} from '@/helper' ;

import {
    include
} from 'zbee-sdk/package/hb-admin-system-template' ;

const from = include('array.from') ;

export default {
  props:{
    data:{
        type:Array,
        default(){

            return [] ;
        }
    },

    selections:{
        default(){

            return [] ;
        }
    },

    layout:{
        validator:layout => ['vertical' , 'horizontal'].includes(layout),
        default:'vertical'
    }
  },
  computed:{

    selectedRecords(){

        return from(this.selections) ;
    }

  },
  components:components([
      'Container'
  ])
}
</script>