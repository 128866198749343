<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header></Header>
    <Content
      :classesDatas="classesDatas"
      :todayDatas="todaySchedulinngDatas"
      @classesplanchange="onClassesPlanChange"
    ></Content>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Header", "Content"], "configuration.Scheduling.Right"),

    ...components(["Container"]),
  },

  props: {
    todaySchedulinngDatas: {},
    classesDatas: {},
  },

  data() {
    return {};
  },

  methods: {
    onClassesPlanChange(val,departmentValue) {
      this.$emit("classesplanchange", val,departmentValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.see-patient-history-list-item {
  margin: 0 0px 15px;
}
</style>
