<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header @addsubmit="onAddSubmit"></Header>
    <Content
      v-if="tabDatas == 1"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></Content>
    <ContentPhmarmacy
      v-if="tabDatas == 2"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentPhmarmacy>
    <ContentBrand
      v-if="tabDatas == 3"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentBrand>
    <ContentSupplier
      v-if="tabDatas == 4"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentSupplier>
    <ContentFactory
      v-if="tabDatas == 5"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentFactory>
    <ContentAttributeIn
      v-if="tabDatas == 6"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentAttributeIn>
    <ContentAttributeOut
      v-if="tabDatas == 7"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentAttributeOut>
    <ContentWarehouse
      v-if="tabDatas == 8"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentWarehouse>
     <ContentUnit
      v-if="tabDatas == 9"
       :tabData="tabData"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentUnit>
     <ContentUnit
      v-if="tabDatas == 10"
       :tabData="tabData"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentUnit>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      [
        "Header",
        "Content",
        "ContentPhmarmacy",
        "ContentBrand",
        "ContentSupplier",
        "ContentFactory",
        "ContentAttributeIn",
        "ContentAttributeOut",
        "ContentWarehouse",
        "ContentUnit"
      ],
      "configuration.Stock.Center"
    ),

    ...components(["Container"]),
  },

  props: {
    listRecordsData: {},
    tabData: 0,
    loading:false
  },

  data() {
    return {
      tabDatas: 0,
    };
  },
  watch: {
    tabData: function (val) {
      this.tabDatas = val;
      // console.log(this.tabDatas);
    },
  },
  methods: {
    isShowAll(val) {
      this.$refs.tree_List.isShowAlls(val);
    },
    onFilterText(val) {
      this.$refs.tree_List.onFilterText(val);
    },
    onAddSubmit() {
      this.$emit("addsubmit");
    },
    onHandleSelectItem(itemdata) {
      this.$emit("handleselectitem", itemdata);
    },
    onHandleDelDelectItem(data) {
      //console.log("1211112", data);
      this.$emit("handledelselectitem", data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
