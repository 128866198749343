<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header></Header>
    <div class="display">
      <Container class="header hb-layout-horizontal">
        <div class="time hb-layout-flex-1">乳酸菌素分散片</div>
      </Container>
      <Container class="body">
        <Container class="inner hb-layout-horizontal">
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">类目</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">西药</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">规格</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">0.5克</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">生产日期</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">2021-02-23</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">有效期</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">26个月</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">进价</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">32.00元</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">售价</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">39.00元</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">批号</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">202111</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">库存量</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">999件</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">销量</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">268件</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">厂家</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">杭州苏泊尔南洋药业有限公司</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">品牌</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">可立健</div>
            </el-col>
          </el-row>
          <el-row style="width: 100%;">
            <el-col :span="8">
              <div class="grid-content bg-purple">操作人</div>
            </el-col>
            <el-col :span="16">
              <div class="grid-content bg-purple-light">剑南春</div>
            </el-col>
          </el-row>
        </Container>
      </Container>
    </div>
  </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: {
    ...components([
        "Header",
    ], "pharmacy.Drugs.Right"),
    ...components([
        "Container"
    ]),
  },
  props: {
    
  }

}
</script>

<style lang="scss" scoped>
.see-patient-history-list-item {
  // height: 100%;
  margin: 0 0px 15px;
  // box-shadow: 0 2px 7px 0 #d8d8d8;

  .display{
    margin: 0 0 10px 2px;
    border: 1px solid #edebeb;

    > .header {
      padding: 0 10px;
      height: 50px;
      line-height: 50px;
      background-color: #EDEDED;
      border-bottom: 1px solid #e1e1e1;

      > .time {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5B5B5B;
      }

      > .button {
        height: 30px;
        margin-top: 12px;
      }
    }

    > .body {
      padding: 10px;

      > .inner {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;

        .el-row {
          width: 100%;

          &:last-child {
            border-bottom: 1px solid #e1e1e1;
          }
        }

        .grid-content {
          border-top: 1px solid #e1e1e1;
          font-size: 13px;
        }

        .bg-purple {
          line-height: 45px;
          text-align: center;
          padding-left: 10px;
          background: #EBEBEB;
          border-right: 1px solid #e1e1e1;
        }

        .bg-purple-light {
          padding: 0 10px;
          height: 45px;
          display: flex;
          flex-direction: column;
          justify-content: center;
        }

      }

    }
  }


  
}
</style>
