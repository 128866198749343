<template>
  <el-pagination
    background
    :page-size="size"
    layout="prev, pager, next"
    :total="total"
  >
  </el-pagination>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    total: "",
    size: "",
    count: "",
  },
};
</script>

<style scoped></style>
