<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Container class="header hb-layout-horizontal">
      <div class="time hb-layout-flex-1">听诊</div>
    </Container>
    <Container class="body">
      <Container class="inner hb-layout-horizontal">
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">用途</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">{{ storehouseItem.purpose }}</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">品牌</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">
              {{ storehouseItem.get_brand ? storehouseItem.get_brand.name : '' }}
            </div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">库存</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">{{ storehouseItem.stock }}</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">供应商</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">
              {{ storehouseItem.get_supplier ? storehouseItem.get_supplier.name : '' }}
            </div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">今日领用</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">{{ storehouseItem.receive_quantity }}件</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">领用医生</div>
          </el-col>
          <el-col :span="16">
            <el-row style="width: 100%;">
              <template v-if="storehouseItem.get_storehouse_receive_record">
                <!-- <el-col :span="24" v-for="(item , index ) in storehouseItem.get_storehouse_receive_record">
                  <div class="grid-content bg-purple-light" v-if="item.get_doctor"> {{item.get_doctor.nickname}}</div>
                </el-col> -->
              </template>


            </el-row>
          </el-col>
        </el-row>

      </Container>
    </Container>
  </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props: {
    storehouseItem: {
      type: Object,
      default: () => {
      }
    }
  }

}
</script>

<style lang="scss" scoped>
.see-patient-history-list-item {
  height: 100%;
  margin: 0 1px 15px;
  box-shadow: 0 2px 7px 0 #d8d8d8;


  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #EDEDED;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 15px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5B5B5B;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    > .inner {
      display: flex;
      flex-direction: column;
      border-left: 1px solid #e1e1e1;
      border-right: 1px solid #e1e1e1;

      .el-row {
        width: 100%;
        // height: 45px;
        line-height: 45px;

        &:last-child {
          border-bottom: 1px solid #e1e1e1;
        }
      }

      .grid-content {
        border-top: 1px solid #e1e1e1;
      }

      .bg-purple {
        text-align: center;
        padding-left: 10px;
        background: #EBEBEB;
        border-right: 1px solid #e1e1e1;
      }

      .bg-purple-light {
        padding-left: 10px;
        // border-Left: 1px solid #e1e1e1;
        // border-right: 1px solid #e1e1e1;
      }

    }

  }
}
</style>
