<template>
  <Container>
    <div class="screen">
      <div class="left-details">
        <div class="search">
          <el-input
            v-model="search"
            placeholder="搜索出库人"
            class="input-with-select search"
          >
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div class="select">
          <el-select v-model="typeValue" placeholder="请选择出库类型">
            <el-option
              v-for="item in attributeDatas"
              :key="item.stock_attribute_id"
              :label="item.name"
              :value="item.stock_attribute_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="date">
          <el-date-picker
            v-model="dateValue"
            type="daterange"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </div>
    </div>

  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  props: {
    attributeDatas: {},
    warehousParam: {},
    categoryRecords: {},
    recordsstockout: {},
    storehouseSelectDatas: {},
  },
  data() {
    return {
      direction: "rtl",
      size: "45%",
      drawer_add_title: "新增出库",
      search: "", // 搜索出库人
      type: [
        {
          value: "1",
          label: "领料出库",
        },
        {
          value: "2",
          label: "报损出库",
        },
        {
          value: "3",
          label: "退货出库",
        },
      ],
      typeValue: "", // 出库类型
      dateValue: "", // 选择时间
    };
  },
  methods: {
    addDelivery(val) {
      this.$emit("getcategoryrecords");
      this.$refs.add_drawer.changeDrawer(true, val);
    },
    onGetStorehouseRecord(val) {
      this.$emit("getshorehouserecord", val);
    },
    onSaveStorehouseStockout(dataOne, dataTwo) {
      this.$emit("savestorehousestockout", dataOne, dataTwo);
    },
  },
};
</script>

<style lang="scss" scoped>
.screen {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-details {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .select {
      margin: 0 10px;
    }

    .date {
      width: 240px;
    }
  }
}

.el-range-editor.el-input__inner {
  width: 100%;
}
</style>
