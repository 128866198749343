import axios from 'axios' ;

import qs from 'qs' ;

let url = 'https://api.demo.hobooa.com/mock/91/api/v2/diagnosis_record/method/set.diagnosis.record';

export default ({
    patient,
    item1,
    item2
}) =>{

    return axios.post(url , qs.stringify({
        projectId:patient.id,
        projectName:item1,
        item2
    }));
}
