<template>
  <Container class="hb-layout-fit-sized">
      <Container class="hb-layout-fit-unsized see-patient-history-list hb-layout-vertical">
        <div style="margin: 10px auto;">
          <el-date-picker
              @change="onDatePickerChange"
              value-format="yyyy-MM"
              v-model="selectMonth"
              type="month"
              placeholder="选择月">
          </el-date-picker>
        </div>
        <div style="position:relative" class="hb-layout-flex-1">
          <div class="hb-layout-fit-sized">
            <List class="hb-layout-fit-unsized" :scroll="true"  :data="historyItems" @itemselect="onItemSelect"
                itemCls=".see-patient-history-list-item">
              <template v-slot:item="{
                      record,
                      selected
                  }">
                <RightItems
                    :payment_time="record.payment_time"
                    :pay_amount="record.pay_amount"
                    :total_amount="record.total_amount"
                    :type="1"
                ></RightItems>
              </template>
            </List>
          </div>
          
          
        </div>
      </Container>
  </Container>
</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components: {
    ...components(["Right.Items"], "charge"),
    ...components(["List", "Container"]),
  },
  props: {
     historyItems: {
      type: Array,
      default: () => [],
    },
  },
  watch:{
    historyItems(historyItems){

      let me = this ;

      if(historyItems.length){

       // me.$refs.list.select(historyItems[0]) ;
        
      }
    }
  },

  data() {

    return {
      selected: null,
      selectMonth: '',
      activeName: 'first'
    };

  },

  methods: {

    onDatePickerChange(date) {

      this.$emit('datechange', date);
    },

    onItemSelect(historyItem) {

      this.$emit('historyitemselect', historyItem);
    },

    handleClick(tab, event) {
      //console.log(tab, event);
    }
  }


}
</script>

<style lang="scss" scoped>

$see-patient-list-padding: 18px 10px 0 10px;

.see-patient-history-list {
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 0px 2px;

}
.see-patient-history-list-item{
  font-size: 12px;
}

</style>
