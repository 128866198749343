<template>
  <Container>
    <div class="screen">
      <div class="left-details">
        <div class="search">
          <el-input
            v-model="search"
            placeholder="搜索员工"
            class="input-with-select search">
            <el-button slot="append" icon="el-icon-search"></el-button>
          </el-input>
        </div>
        <div class="select">
          <el-select v-model="departmentValue" placeholder="请选择部门">
            <el-option
              v-for="item in department"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
      <el-button type="primary" @click="addStaff = true">新增员工</el-button>
    </div>
    <div>
      <el-table
        ref="list"
        :data="stafflList"
        highlight-current-row
        stripe
        height="calc(100vh - 220px)"
        style="width: 100%">

        <el-table-column fixed prop="name" label="姓名" min-width="180" align="center"></el-table-column>

        <el-table-column prop="userName" label="用户名" min-width="180" align="center"></el-table-column>

        <el-table-column prop="department" label="部门" min-width="150" align="center"></el-table-column>

        <el-table-column label="操作" min-width="150" align="center">
          <template slot-scope="scope">
            <el-button
              @click.native.prevent="editRow(scope.$index, stafflList)"
              type="text"
              size="small">
              编辑
            </el-button>
            <el-button
              @click.native.prevent="deleteRow(scope.$index, stafflList)"
              type="text"
              size="small">
              删除
            </el-button>
          </template>
        </el-table-column>

      </el-table>
    </div>

    <!-- 添加员工 -->
    <el-drawer
      title="新增员工"
      :visible.sync="addStaff"
      style="fontSize:20px;">
      <AddStaff></AddStaff>
    </el-drawer>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["AddStaff"], "configuration.Staff"),
    ...components(["Container"]),
  },
  data() {
    return {
        stafflList: [
            {
                name: "可可",     // 姓名
                userName: "农家菜",                // 用户名
                department: '门诊部',              // 部门
            }
        ],

        search: "",  // 搜索员工
        department: [
          {
            value: "1",
            label: "门诊部",
          },{
            value: "2",
            label: "住院部",
          }
        ],
        departmentValue: "",  // 部门


        addStaff: false,   // 添加员工弹窗

    };
  },
  methods:{
    // 添加一行
    add() {
      if (this.stafflList == undefined) {
        this.stafflList = new Array();
      }
      let obj = {};
      obj.name = "";
      obj.userName = "";
      obj.department = "";

      this.stafflList.push(obj);
    },

    // 删除一行
    deleteRow(index, rows) {
      rows.splice(index, 1);
    },

    // 编辑
    editRow(index, rows){

    }
  }
};
</script>

<style lang="scss" scoped>
  .screen {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left-details{
      display: flex;
      align-items: center;
      margin-right: 10px;

      .select{
        margin: 0 10px;
      }

      .date{
        width: 240px;
      }
    }
  }

  .el-range-editor.el-input__inner{
    width: 100%;
  }

</style>
