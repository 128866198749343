<template>
<Container class="statistics">
    <div class="text item">
        <div class="total">
            <div class="total-amount">
                <el-image class="image img" src="../../image/wwc.png"></el-image>
                <div class="content">
	            
                      <div class="total-number" v-if="statisticsData.count_confirm_0 >= 0">{{statisticsData.count_confirm_0}}</div>
                    <div class="total-name">待确认</div>
                </div>
            </div>
            <div class="total-amount">
                <el-image class="image img" src="../../image/ywc.png"></el-image>
                <div class="content">
	       
                      <div class="total-number" v-if="statisticsData.count_confirm_1 >= 0">{{statisticsData.count_confirm_1}}</div>
                    <div class="total-name">已确认</div>
                </div>
            </div>
            <div class="total-amount">
                <el-image class="image img" src="../../image/yqx.png"></el-image>
                <div class="content">
	               
                      <div class="total-number" v-if="statisticsData.count_confirm_2 >= 0">{{statisticsData.count_confirm_2}}</div>
                    <div class="total-name">已取消</div>
                </div>
            </div>
            <div class="total-amount">
                <el-image class="image img" src="../../image/ydd.png"></el-image>
                <div class="content">
	             
                      <div class="total-number" v-if="statisticsData.count_confirm_3 >= 0">{{statisticsData.count_confirm_3}}</div>
                    <div class="total-name">已到店</div>
                </div>
            </div>
            <div class="total-amount">
                <el-image class="image img" src="../../image/sz.png"></el-image>
                <div class="content">
	             
                      <div class="total-number" v-if="statisticsData.count_status_0 >= 0">{{statisticsData.count_status_0}}</div>
                    <div class="total-name">首诊</div>
                </div>
            </div>
            <div class="total-amount">
                <el-image class="image img" src="../../image/cz.png"></el-image>
                <div class="content">
	    
                      <div class="total-number" v-if="statisticsData.count_status_1 >= 0">{{statisticsData.count_status_1}}</div>
                    <div class="total-name">初诊</div>
                </div>
            </div>
            <div class="total-amount">
                <el-image class="image img" src="../../image/fz.png"></el-image>
                <div class="content">
	             
                      <div class="total-number" v-if="statisticsData.count_status_2 >= 0">{{statisticsData.count_status_2}}</div>
                    <div class="total-name">复诊</div>
                </div>
            </div>
        </div>
        <el-table style="width: 100%" height="calc(100vh - 385px)" v-loading="loading" :data="tableData" :highlight-current-row="true" @sort-change="sortChange">
          <el-table-column fixed type="index" width="45"> </el-table-column>
            <el-table-column fixed prop="appointment_record_no" label="预约号" min-width="240" align="left"></el-table-column>

            <el-table-column prop="medical_record_no" label="病历号" min-width="180" align="left"></el-table-column>
            <el-table-column prop="getMedicalRecord.real_name" label="用户" min-width="220" align="left"></el-table-column>

            <el-table-column prop="getDoctor.nickname" label="预约医生" min-width="220" align="left">
            </el-table-column>

            <el-table-column prop="getDepartment.name" align="left" label="科室" min-width="150"></el-table-column>

            <el-table-column label="就诊类型" width="100" align="left">
                <template slot-scope="scope">
                    <el-tag :type="visitMap[scope.row.visit_status].type" effect="plain" size="mini">
                        {{ visitMap[scope.row.visit_status].text }}
                    </el-tag>
                </template>
            </el-table-column>
            <el-table-column label="预约时间" width="160" align="left">
                <template slot-scope="scope">
                    {{ scope.row.appointment_date }} {{ scope.row.appointment_time }}
                </template>
            </el-table-column>

            <el-table-column prop="start_time" label="开始时间" width="100" align="left"></el-table-column>

            <el-table-column prop="end_time" label="结束时间" width="100" align="left"></el-table-column>

            <el-table-column prop="status" label="预约状态" width="100" align="left">
                <template slot-scope="scope">
                    <el-tag :type="typeMap[scope.row.status].type" effect="plain" size="mini">
                        {{ typeMap[scope.row.status].text }}
                    </el-tag>
                </template>
            </el-table-column>

            <el-table-column label="看诊状态" width="100" align="left">
                <template slot-scope="scope">
                    <el-tag :type="stateMap[scope.row.diagnosis_status].type" effect="plain" size="mini">
                        {{ stateMap[scope.row.diagnosis_status].text }}
                    </el-tag>

                </template>
            </el-table-column>

            <el-table-column prop="register_time" label="到店时间" width="160" align="left"></el-table-column>

            
        </el-table>
    </div>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: components(["Container"]),
    props: {
        Data: {},
        loading: {
            default: false,
        },
        toPayment: {
            default: () => {},
        },
        tableData: {
            default: () => [],
        },
	    statisticsData: {
            default(){
                return {
                    count_confirm_0: 0,
                    count_confirm_1: 0,
                    count_confirm_2: 0,
                    count_confirm_3: 0,
                    count_status_0: 0,
                    count_status_1: 0,
                    count_status_2: 0,
                } ;
            }
        },
    },
    filters: {
        getNumber(val) {
            return this.getNumber(val);
        },
    },
    data() {
        return {
            moduleMap: {
                points: "积分",
                money: "消费",
                card: "购物卡",
            },
            // stateMap: ['账户资金', '现金', '支付宝', '微信', '银行卡', '购物卡', '保险', '其它'],
            // statusMap: ['未支付', '已支付'],
            visitMap: [
                {
                    text: "待确认",
                    type: "warning",
                },
                {
                    text: "首诊",
                    type: "success",
                },
                {
                    text: "初诊",
                    type: "primary",
                },
                {
                    text: "复诊",
                    type: "danger",
                },
            ],
            typeMap: [
                {
                    text: "待确认",
                    type: "warning",
                },
                {
                    text: "已确认",
                    type: "success",
                },
                {
                    text: "已取消",
                    type: "info",
                },
                 {
                    text: "客户爽约",
                    type: "danger",
                },
            ],
            stateMap: [
                // {
                //     text: "待到店",
                //     type: "primary",
                // },{
                //     text: "已到店",
                //     type: "success",
                // },
                {
                    text: "待诊",
                    type: "danger",
                },
                {
                    text: "已诊",
                    type: "warning",
                }
            ],
        };
    },
    methods: {
        sortChange({
            column,
            prop,
            order
        }) {
            let sort = {
                order_type: undefined,
                order_field: undefined,
            };

            if (column && order) {
                sort.order_type = order === "ascending" ? "asc" : "desc";
                sort.order_field = prop;
            }

            this.$emit("sort", sort);
        },
        getNumber(value) {
            const toFixedNum = Number(value).toFixed(3);
            return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
        },
    },
};
</script>

<style lang="scss" scoped>
.item {
    margin-top: 10px;

  .total{
    display: flex;
    align-items: center;

    .total-amount{
      height: 80px;
      margin: 20px 10px 20px 5px;
      padding: 0 40px 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 1px 4px 1px #d8d8e3;

      .image{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .img{
        width: 44px;
        height: 44px;
      }

      .content{
        font-family: PingFangSC-Medium, PingFang SC;

        .total-name{
          font-size: 12px;
          color: #999;
        }
        .total-number{
          font-size: 18px;
          color: #333;
        }
      }
    }
  }
}
</style>
