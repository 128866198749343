import axios from 'axios' ;
import serverConfig from "@/../config/index";

// let url = 'https://api.demo.hobooa.com/mock/91/api/v2/patient/method/get.diagnosis.record.list/';
let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment/method/get.appointment.basic.list/';



export default () =>{

    return axios.get(url).then(({
        data
    }) => data)
    .then(({
        data
    }) => data)
    .then(({
        items
    }) => items.map(({
       diagnosis_id:id,
       get_user,
       appointment_no,
       appointment_time,
       diagnosis_status
    }) =>({
        id,
        appointment_no: appointment_no,
        name: get_user.nickname,
        header: get_user.head_pic,
        member: get_user.card_type,
        time: appointment_time,
        state: diagnosis_status
    })));
}
