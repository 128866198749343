/**
 * 获取医生列表 API
 */

 import axios from 'axios';
 import qs from 'qs';
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/doctor/method/get.search';
 //let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment_record/method/get.doctor.list/';
 export default (data) => {
 
     return axios.post(url, qs.stringify({
           
                 keywords: data
             
         }))
         .then(({
             data
         }) => data)
         .then(({
             data
         }) => data)
         .then(({
             items
         }) => items.map(({
             doctor_id,
             department_id,
             nickname,
             mobile
         }) => ({
             name: nickname,
             doctorID: doctor_id,
             departmentID: department_id,
             mobile:mobile,
         })));
 }