<template>
    <el-drawer
        :title="medical_report_title"
        :visible.sync="drawer"
        :direction="direction"
        :size="size"
        :with-header="with_header"
    >
        <template>
            <div style="height: 100vh">
                <div class="overflowAuto">
                    <div style="page-break-after: always" ref="print" id="print">
                        <div class="header-top">
                            <div class="header-logo">
                                <img src="../../../../public/image/logopg.png"/>
                                <div class="header-title">
                                    我们将用卓越的医疗服务与照护切实改善您的生活质量
                                </div>
                            </div>
                        </div>
                        <div class="print-order">
                            <el-row style="border: none;margin: 10px 0 30px;font-size: 22px;font-weight: bold;">
                                <el-col :span="24">病历报告 Medical Report</el-col>
                            </el-row>
                            <el-row style="border: none; margin: 10px 0 5px">
                                <el-col :span="11">姓名 Patient's name：{{ medical_record.real_name }}</el-col>
                                <el-col :span="9">档案号 Chart No： {{ medical_record.medical_record_no }}</el-col>
                                <el-col :span="4">年龄 Age：{{ medical_record.age }}</el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">主诉 Chief Complaint:</div>
                                        <el-input class="input" type="textarea" autosize v-model="chiefComplaint"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">初次出现症状日期 Date Of Onset:</div>
                                        <el-input v-model="firstDate" class="input"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">初诊日期 Date Of First Diagnosis:</div>
                                        <el-input v-model="firstVisit" class="input"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">目前是否服用药物 Current Medications:</div>
                                        <el-input class="input" type="textarea" autosize v-model="currentMedications"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">评估结果 Examination Findings:</div>
                                        <el-input class="input" type="textarea" autosize v-model="examinationFindings"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">已进行的相关检查 Relevant Investigations Done:</div>
                                        <el-input class="input" type="textarea" autosize v-model="relatedInspection"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">临床诊断 Diagnosis:</div>
                                        <el-input class="input" type="textarea" autosize v-model="diagnosis"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">治疗措施 Treatment Recommendations:</div>
                                        <el-input class="input" type="textarea" autosize v-model="treatmentRecommendations"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row>
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">复诊时间 Follow Up Time:</div>
                                        <el-input v-model="followUpTime" class="input"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                        </div>

                        <div class="signature">
                            <!-- <div>Date: {{currentDateTime}}</div> -->
                            <div>Date: <el-date-picker v-model="dateTime" type="datetime" placeholder="选择日期时间"></el-date-picker> </div>
                            <div>Doctor's Signature:</div>
                        </div>
                        <div class="header-footer">
                            <div class="footer-logo">
                                <img src="../../../../public/image/telephone.png"/>
                                <div class="footer-title">0512-6580-8308</div>
                            </div>
                            <div class="footer-right">
                                <div class="footer-text">
                                    苏州工业园区旺墩路 新罗酒店B栋一楼
                                </div>
                                <div class="footer-text">
                                    Opening Hours 营业时间 Mon-Sun周一到周日，8:30-20:30
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer">
                    <!-- <el-button type="success" @click="preserveButton"
                    >保存
                    </el-button> -->
                    <el-button type="primary" @click="$print($refs.print)"
                    >打印
                    </el-button
                    >
                    <el-button @click="handleClose()">取消</el-button>
                </div>
            </div>
        </template>
    </el-drawer>
</template>

<script>
import {components} from "@/helper";
import moment from "moment";

export default {
    components: {
        ...components(["List", "Container"]),
    },
    props: {
        with_header: {
            type: Boolean,
            required: false,
            default: false,
        },
        direction: {
            type: String,
            default: "rtl",
        },
        medical_report_title: "",
        medical_record: {
            type: Object,
            required: true,
            default: {
                real_name: 'Empty',
                sex: 0,
                age: 0,
                medical_record_no: '-',
            },
        },
        size: {
            type: String,
            default: "42%",
        },
    },
    data() {
        return {
            drawer: false,

            chiefComplaint: "", // 主诉
            firstDate: "", //初次出现症状日期
            firstVisit: "", // 初诊日期
            currentMedications: "", // 目前是否服用药物
            examinationFindings: "", // 评估结果
            relatedInspection: "", // 已进行的相关检查
            diagnosis: "", // 临床诊断
            treatmentRecommendations: "", //治疗措施
            followUpTime: "",     // 复诊时间

            checkList: [],

            currentDateTime: moment(new Date).format('YYYY-MM-DD HH:mm:ss'), // 当前时间

            dateTime: ''
        };
    },

    methods: {
        changeDrawer(v) {
            this.drawer = v;
        },
        handleClose() {
            this.drawer = false;
        },
        preserveButton() {
            //console.log()
        },
    },
};
</script>

<style lang="scss" scoped>
.el-drawer__body {
    overflow: auto !important;
}

.item-img {
    position: relative;
}

.overflowAuto {
    overflow-y: auto;
    height: 100%;
    padding: 20px 20px 30px;
}

.overflowAuto::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.overflowAuto::-webkit-scrollbar-thumb {
    background: rgb(224, 214, 235);
}

.title {
    height: 50px;
    width: 100%;
    line-height: 60px;
    padding-left: 10px;
    // font-weight: bold;
}

.grid-content {
    font-size: 12px;
}

.row-bg {
    padding: 10px 0;
}

.header-top {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;

    .header-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 5px solid #97c2bb;
        padding-bottom: 20px;

        > img {
            width: 270px;
            height: 65px;
        }

        .header-title {
            color: #97c2bb;
            width: 290px;
            flex-wrap: wrap;
            font-size: 20px;
            line-height: 32px;
            text-align: end;
        }
    }
}

.signature {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 20px;

    > div {
        width: 50%;
    }
}

.header-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 3px solid #97c2bb;
    margin-top: 20px;
    padding-top: 20px;

    .footer-logo {
        display: flex;
        align-items: center;

        > img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        .footer-title {
            color: #97c2bb;
            font-size: 22px;
            font-weight: bold;
        }
    }

    .footer-right {
        color: #97c2bb;
        font-size: 16px;

        .footer-text {
            line-height: 24px;
        }
    }
}

.footer {
    position: fixed;
    bottom: 20px;
    z-index: 99;
    right: 30px;
}

@media print {
    @page {
        size: auto;
        margin: 2mm 9mm;
    }

    body {
        height: auto;
    }

    #print {
        // height: 291mm; //分页  根据实际
        height: 1090px; //分页  根据实际
        position: relative;

        &::before {
            position: absolute;
            content: "乐享健康新生活";
            color: #54958a;
            top: 25%;
            left: 25%;
            font-size: 48px;
            opacity: 0.1;
        }

        &::after {
            position: absolute;
            content: "乐享健康新生活";
            color: #54958a;
            bottom: 35%;
            left: 25%;
            font-size: 48px;
            opacity: 0.1;
        }
    }
}

#print {
    background: #fff;
    box-sizing: border-box;
}

.print-order {
    min-height: 780px;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
}

.el-row {
    border: none;
    margin: 20px 0;

    &:last-child {
        margin-bottom: 0;
    }
}

.purple {
    padding: 15px;
    border-right: 1px solid #e1e1e1;
}

.item {
    display: flex;
    align-items: center;

    .name {
        white-space: nowrap;
        margin-right: 10px;
    }
}

.input ::v-deep {
    .el-input__inner,
    .el-textarea__inner {
        border: 0;
        border-radius: 0;
        font-size: 13px;
        border-bottom: 1px solid #c5c5c5 !important;
        background: transparent;
        height: 20px;
        line-height: 20px !important;
        padding: 0 10px;
    }
}

.el-checkbox,
.el-checkbox__input {
    display: inline-flex;
}

.checkbox ::v-deep {
    .el-checkbox__label {
        font-size: 12px;
        line-height: 15px;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner::after {
        border-color: #333;
    }
}
</style>
