/**
 * 保存看诊记录 API
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/prescription/method/del.prescription.item';

export default (data) => {

    return axios.post(url, qs.stringify({
        "format": "json",
        ...data
    })).then(({data}) => data)
        .then(({data}) => data);
}
