import serverConfig from "@/../config/index";
import cookies from './util.cookies'
import md5 from 'js-md5';

let util = {
    cookies
  }

  /**
 * @description 更新标题
 * @param {String} titleText 标题
 */
util.title = (titleText) => {
    window.document.title = `${titleText ? `${titleText} - ` : ''}${process.env.VUE_APP_TITLE}`
  }

/**
 * MD5加密
 * @param str
 * @returns {*}
 */
// util.md5 = (str) => {
//     let crypto = require('crypto');
//     let md5 = crypto.createHash('md5');

//     md5.update(str);
//     return md5.digest('hex');
// }

/**
 * 签名生成
 * @param params
 * @returns {*}
 */
util.getSign = (params) => {
    let sorted = Object.keys(params).sort()
    let basestring = serverConfig.APP_SECRET
    const type = ['undefined', 'object', 'function']

    for (let i = 0, l = sorted.length; i < l; i++) {
        if (sorted[i] === 'sign') {
            continue
        }

        let k = sorted[i]
        if (type.indexOf(typeof params[k]) === -1) {
            basestring += k + (typeof params[k] === 'boolean' ? Number(params[k]) : params[k])
        }
    }

    basestring += serverConfig.APP_SECRET
    return md5(basestring)
}
/**
 * 根据样式编码获取缩略图地址
 * @param url
 * @param code
 * @returns {string}
 */
util.getImageCodeUrl = (url, code = '') => {
    let data = serverConfig.BASE_API
    data += '/v1/storage/method/get.storage.thumb/code/' + code
    data += '?url=' + encodeURIComponent(url)

    return data
}

/**
 * 根据样式编码生成下载链接
 * @param file
 * @param code
 * @returns {*}
 */
util.getDownloadUrl = (file, code) => {
    let data = serverConfig.BASE_API
    data += '/v1/storage/method/get.storage.download/code/' + code
    data += '?url=' + encodeURIComponent(file.url)
    data += '&filename=' + encodeURI(file.name)

    return data
}

/**
 * 根据请求参数获取缩略图地址
 * @param url
 * @param style
 * @returns {string}
 */
util.getImageStyleUrl = (url, style = '') => {
    if (url) {
        let data = serverConfig.BASE_API
        data += '/v1/storage/method/get.storage.thumb' + '?url=' + encodeURIComponent(url)
        data += style

        return data
    }

    return ''
}

/**
 * 动态生成二维码图片地址
 * @param text
 * @param expand
 * @returns {string}
 */
util.getQrcodeUrl = (text, expand = {}) => {
    let data = serverConfig.BASE_API
    data += '/v1/qrcode/method/get.qrcode.item?text='
    data += encodeURI(expand.hasOwnProperty('text') ? expand['text'] : text)

    for (const key in expand) {
        if (key === 'text' || expand[key] === '') {
            continue
        }

        if (key === 'logo') {
            data += `&${key}=${encodeURIComponent(expand[key])}`
            continue
        }

        data += `&${key}=${encodeURI(expand[key])}`
    }

    return data
}

/**
 * 动态生成条形码图片地址
 * @param text
 * @param expand
 * @returns {string}
 */
util.getBarcodeUrl = (text, expand = {}) => {
    let data = serverConfig.BASE_API
    data += '/v1/barcode/method/get.barcode.item?text='
    data += encodeURI(expand.hasOwnProperty('text') ? expand['text'] : text)

    for (const key in expand) {
        if (key === 'text' || expand[key] === '') {
            continue
        }

        data += `&${key}=${encodeURI(expand[key])}`
    }

    return data
}

/**
 * 数字 格式化
 * @param num
 * @param digits
 * @returns {string}
 */
util.numberFormatter = (num, digits = 2) => {
    const si = [{
            value: 1E18,
            symbol: 'EB'
        },
        {
            value: 1E15,
            symbol: 'PB'
        },
        {
            value: 1E12,
            symbol: 'TB'
        },
        {
            value: 1E9,
            symbol: 'GB'
        },
        {
            value: 1E6,
            symbol: 'MB'
        },
        {
            value: 1E3,
            symbol: 'KB'
        }
    ]

    for (let i = 0; i < si.length; i++) {
        if (num >= si[i].value) {
            return (num / si[i].value + 0.1).toFixed(digits).replace(/\.0+$|(\.[0-9]*[1-9])0+$/, '$1') + si[i].symbol
        }
    }

    return num.toString()
}

/**
 * 字节转字符串单位
 * @param bytes
 * @param spacer
 * @returns {string}
 */
util.bytesFormatter = (bytes, spacer = ' ') => {
    if (isNaN(bytes)) {
        return ''
    }

    const symbols = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    let exp = Math.floor(Math.log(bytes) / Math.log(2))

    if (exp < 1) {
        exp = 0
    }

    const i = Math.floor(exp / 10)
    bytes = bytes / Math.pow(2, 10 * i)

    if (bytes.toString().length > bytes.toFixed(2).toString().length) {
        bytes = bytes.toFixed(2)
    }

    // bytes + symbols[i]
    return `${bytes}${spacer}${symbols[i]}`
}

/**
 * 设置大图预览列表及顺序
 * @param srcList
 * @param index
 * @returns {{}}
 */
util.setImageSrcList = (srcList, index) => {
    if (!Array.isArray(srcList) || !srcList.length) {
        return []
    }

    const before = srcList.slice(index)
    const image = before.concat(srcList.slice(0, index))

    let imageList = []
    image.forEach(value => {
        imageList.push(value['url'])
    })

    return imageList
}

/**
 * 将数值保留2位小数返回(不会四舍五入)
 * @param value
 * @returns {string}
 */
util.getNumber = (value) => {
    const toFixedNum = Number(value).toFixed(3)
    return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : '0.00'
}

/**
 * 笛卡尔积算法
 * @param array
 * @returns {*|*[]|U}
 */
util.descartes = (array) => {
    if (array.length < 2) {
        return array[0] || []
    }

    return [].reduce.call(array, (col, set) => {
        let res = []
        col.forEach((c) => {
            set.forEach((s) => {
                let t = [].concat(Array.isArray(c) ? c : [c])
                t.push(s)
                res.push(t)
            })
        })

        return res
    })
}

/**
 * 验证URL地址
 * @param url
 * @returns {string|*}
 */
util.checkUrl = (url) => {
    if (url) {
        const blob = /^(blob)[^\s]+/
        const reg = /^((https|http|ftp|rtsp|mms)?:\/\/)[^\s]+/

        if (!blob.test(url) && !reg.test(url)) {
            return document.location.protocol + '//' + url
        }
    }
    return url
}


export default util