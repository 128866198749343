import axios from 'axios' ;

let url = 'https://api.demo.hobooa.com/mock/91/api/v2/diagnosis_record/method/get.diagnosis.record/';

export default ({
    id
}) => {

    //不知道怎么传参 ，所以ID写死了。
    return axios.get(url , {
        params:{
            medical_no:id
        }
    })
        .then(({
                   data
               }) => data)
        .then(({
                   data
               }) => data)
        .then(({
                   vital_signs,
                   getMedicalRecord,
                   getAppointment
               })=>({
            appointment_type: '',
            medical_record_no:'',
            age:'',
            sex:'',
            real_name:'',
            vital_signs: vital_signs
        }));
}
