<template>
  <Container class="details hb-layout-horizontal">
    <Container class="left hb-layout-vertical">
      <LeftMain @getleftchangefive="onGetLeftChangeFive"></LeftMain>
    </Container>
    <Container class="centent hb-layout-flex-1 hb-layout-vertical">
      <CenterMain
        :treeDatas="treeDatas"
         :tabData="tabData"
        @editselectitem="onEditSelectItem"
        @delselectitem="onDelSelectItem"
        @appendselectitem="onAppendSelectItem"
        @addtopitem="onAddTopItem"
      ></CenterMain>
    </Container>
    <Container class="right hb-layout-vertical">
      <RightMain
        ref="editSelectData"
        :treeDatas="treeDatas"
          :tabData="tabData"
        @submitexamination="onSubmitExamination"
      ></RightMain>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Left.Main", "Center.Main", "Center.Main","Right.Main"], "configuration.Diagnosis"),
    ...components(["Container"]),
  },

  props: {
    treeDatas: {},
      tabData:0
  },

  data() {
    return {
    
    };
  },

  methods: {
    onEditSelectItem(itemData, treeData) {
      this.$refs.editSelectData.onSelectData(itemData, treeData);
    },
    onAppendSelectItem(itemData) {
      this.$refs.editSelectData.onAppendSelectItem(itemData);
    },
    onSubmitExamination(data) {
      this.$emit("submitexamination", data);
    },
    onDelSelectItem(data) {
      this.$emit("delselectitem", data);
    },
    onAddTopItem() {
      this.$refs.editSelectData.addtopitem();
    },
    onGetLeftChangeFive(val) {
      this.$emit("getleftchangefive", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .left {
    width: 300px;
  }
  .right {
    width: 300px;
  }
}
</style>
