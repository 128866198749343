<template>
<vue-particle-line>
    <div class="page-account">
        <div class="container" :class="[fullWidth > 768 ? 'containerSamll' : 'containerBig']">
            <div class="swiperPross" v-if="fullWidth > 768">
                <img style="width: 510px; height: 450px" src="../../public/image/jinjihu.jpeg" />
            </div>
            <div class="index_from page-account-container">
                <div class="page-account-top">
                    <div class="page-account-top-logo">
                        <!-- <img :src="login_logo" alt="logo" /> -->
                        <img style="width: 160px; height: 50px" src="../../public/image/logo.png" />
                    </div>
                </div>
                <el-form class="login-form" status-icon :rules="loginRules" ref="loginForm" :model="loginForm" label-width="0" size="default">
                    <!-- <el-form-item prop="storeValue">
                        <el-select class="select-dropdown" placeholder="请选择" v-model="loginForm.storeValue" >
                            <el-option v-for="item in storelist.items" :key="item.uuid" :label="item.name" :value="item.uuid" />
                        </el-select>
                    </el-form-item> -->
                    <el-form-item prop="username">
                        <el-input size="small" @keyup.enter.native="handleLogin()" v-model="loginForm.username" auto-complete="off" placeholder="请输入账号">
                            <i slot="prefix" class="el-icon-user" />
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="password">
                        <el-input size="small" @keyup.enter.native="handleLogin()" :type="passwordType" v-model="loginForm.password" auto-complete="off" placeholder="请输入密码">
                            <i class="el-icon-view el-input__icon" slot="suffix" @click="showPassword" />
                            <i slot="prefix" class="el-icon-key" />
                        </el-input>
                    </el-form-item>
                    <el-form-item prop="login_code">
                        <el-row :span="34">
                            <el-col :span="14">
                                <el-input size="small" @keyup.enter.native="handleLogin()" v-model="loginForm.login_code" auto-complete="off" placeholder="请输入验证码">
                                    <i slot="prefix" class="el-icon-mobile" />
                                </el-input>
                            </el-col>
                            <el-col :span="10">
                                <div class="login-code">
                                    <BasicCode @imgCode="imgCode" ref="imgVerify" style="margin-left: 10px; width: 100px; height: 32px" />
                                </div>
                            </el-col>
                        </el-row>
                    </el-form-item>
                    <!-- <el-checkbox v-model="remember">保持登录</el-checkbox> -->
                    <el-form-item>
                        <el-button type="primary" size="small" :loading="loading" @click.native.prevent="handleLogin" class="login-submit">登 录
                        </el-button>
                    </el-form-item>

                </el-form>
            </div>
        </div>
        <!-- <Modal v-model="modals" scrollable footer-hide closable title="请完成安全校验" :mask-closable="false" :z-index="2"
               width="342">
            <div class="captchaBox">
                <div id="captcha" style="position: relative" ref="captcha"></div>
                <div id="msg"></div>
            </div>
        </Modal> -->
    </div>
</vue-particle-line>
</template>

<script>
import {
    // eslint-disable-next-line no-unused-vars
    mock,
    components,
    blocks,
} from "@/helper";
import {
    validUsername
} from "@/utils/validate";
export default {
    components: {
        ...blocks(["BasicCode"], "Captcha"),
    },
    mounted() {
        this.$refs.imgVerify.draw();
        this.getStoreRecords();
    },
    data() {
        const validateUsername = (rule, value, callback) => {
            if (!validUsername(value)) {
                callback(new Error("Please enter the correct user name"));
            } else {
                callback();
            }
        };
        const validatePassword = (rule, value, callback) => {
            if (value.length < 6) {
                callback(new Error("The password can not be less than 6 digits"));
            } else {
                callback();
            }
        };
        return {
            scope: "scope1",
            store: {},
            left_bg: require("@/assets/images/sw.jpg"),
            fullWidth: document.documentElement.clientWidth,
            errorNum: 0,
            jigsaw: null,
            login_logo: "",
            sessionId: "",
            defaultSwiperList: [],
            loginForm: {
                username: "",
                password: "",
                appkey: "75736212",
                login_code: "",
                session_id: "",
                platform: "pc",
                storeValue: ""
            },
            loading: false,
            passwordType: "password",
            redirect: undefined,
            remember: false,
            loginRules: {

                storeValue: [{
                    required: true,
                    message: '请选择门店',
                    trigger: 'change'
                }],
                username: [{
                    required: true,
                    message: "请输入账号",
                    trigger: "blur"
                }],
                password: [{
                        required: true,
                        message: "请输入密码",
                        trigger: "blur"
                    },
                    {
                        min: 6,
                        message: "密码长度最少为6位",
                        trigger: "blur"
                    },
                ],
                login_code: [{
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur"
                    },
                    {
                        min: 4,
                        max: 4,
                        message: "验证码长度为4位",
                        trigger: "blur"
                    },
                ],
            },
            storelist: {},
        };
    },
    methods: {
        imgCode(code) {
            this.code = code;
            //console.log(this.code);
        },
        getCode() {
            if (this.code == this.loginForm.login_code.toUpperCase()) {
                this.testSend = "验证成功";
            } else {
                this.testSend = "验证失败";
            }
        },
        showPwd() {
            if (this.passwordType === "password") {
                this.passwordType = "";
            } else {
                this.passwordType = "password";
            }
            this.$nextTick(() => {
                this.$refs.password.focus();
            });
        },
        /**
         * @description 是否显示实际密码
         */
        showPassword() {
            this.passwordType === "" ?
                (this.passwordType = "password") :
                (this.passwordType = "");
        },

        handleLogin() {
            this.$refs.loginForm.validate((valid) => {
                if (valid) {
                    this.loading = true;
                    this.loginForm.session_id = this.sessionId;

                    this.haslogin();
                    // this.login({ login: this.loginForm, remember: this.remember })
                    //   .then(() => {
                    //     //this.captcha = false;
                    //     this.$store.dispatch("bussiness/account/load");
                    //     this.$router.replace(this.$route.query.redirect || "/");
                    //   })
                    //   .catch(() => {
                    //     this.loading = false;
                    //   });
                }
            });
        },
        async haslogin() {
            let me = this;

            try {
                let res = await mock("user/setLoginIn", me.loginForm);

                // console.log("res",res.status);
                if (res.status === 200) {
                    me.$message({
                        type: "success",
                        message: "登录成功!",
                    });
                    
                    sessionStorage.setItem(
                        "HB-LOGIN-USER-INFO",
                        JSON.stringify({
                            token: res.data.token.token,
                            group_id: res.data.token.group_id,
                            name: res.data.doctor.nickname,
                            client_id: res.data.token.client_id,
                            scope:me.scope,
                            store_token: me.loginForm.storeValue
                        })
                    );
                    //console.log("HB-LOGIN-USER-INFO", me.loginForm.storeValue);
                    location.href = "index.html#/Workbench";

                    return;
                } else {
                    me.$message({
                        type: "warning",
                        message: res.message,
                    });
                    me.loading = false;
                }
            } catch (err) {
                //  console.log("medicalRecorsssd", err.response.data.message);
                me.$message({
                    type: "warning",
                    message: err.response.data.message,
                });
            }

            // console.log("medicalRecorsssd", res);
        },
        async getStoreRecords() {
            let res = await mock("store/getStoreRecords");
            if (res.status == "200") {
                this.storelist = res.data;
            }
        }
    },
};
</script>

<style lang="scss">
body {
    margin: 0px;
}

/* 修复input 背景不协调 和光标变色 */
/* Detail see https://github.com/PanJiaChen/vue-element-admin/pull/927 */

$bg: #283443;
$light_gray: #fff;
$cursor: #fff;
$dark_gray: #889aa4;

.login-info {
    padding-left: 60px;
}

.login-info-title {
    line-height: 90px;
}

.login-info-item {
    font-size: 14px;
}

.login-border {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 30px 55px 25px 55px;
    background-color: rgba(255, 255, 255, 0.12);
    border-radius: 6px;
}

.login-main {
    border-radius: 3px;
    box-sizing: border-box;
}

.login-main>h3 {
    margin-bottom: 20px;
}

.login-main>p {
    color: #76838f;
}

.login-logo {
    margin: 0 0 20px;
    text-align: center;
}

.login-submit {
    margin-top: 30px !important;
    width: 100%;
}

.select-dropdown {
    margin-top: 10px;
    width: 304px;

    .el-button--primary:first-child {
        width: 275px !important;
    }

}

.login-form {
    margin: 10px 0;

    // .el-form-item__content {
    //   width: 270px;
    // }
    .el-form-item {
        margin-bottom: 15px;
    }

    .el-input {
        input {
            text-indent: 5px;
            border-color: #dcdcdc;
            border-radius: 3px;
        }

        .el-input__prefix {
            i {
                padding: 0 5px;
                font-size: 16px !important;
            }
        }
    }
}

.login-code {
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-left: 10px;
}

.login-code canvas {
    width: 100px;
    height: 32px;
}

.login-code-img {
    margin-top: 5px;
    width: 100px;
    height: 32px;
}
</style>
<style lang="stylus" scoped>
.page-account {
    display: flex;
}

.page-account .code {
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-account .code .pictrue {
    height: 40px;
}

.swiperPross {
    border-radius: 6px 0px 0px 6px;
}

.swiperPross,
.swiperPic,
.swiperPic img {
    width: 510px;
    height: 100%;
}

.swiperPic img {
    width: 100%;
    height: 100%;
}

.swiperPross img {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.container {
    height: 450px !important;
    padding: 0 !important;
    /*overflow: hidden;*/
    border-radius: 6px;
    z-index: 1;
    display: flex;
}

.containerSamll {
    /*width: 56% !important;*/
    background: #fff !important;
}

.containerBig {
    width: auto !important;
    background: #f7f7f7 !important;
}

.index_from {
    padding: 45px 40px 32px 40px;
    height: 400px;
    box-sizing: border-box;
}

.page-account-top {
    padding: 20px 0 !important;
    box-sizing: border-box !important;
    display: flex;
    justify-content: center;
}

.page-account-container {
    border-radius: 0px 6px 6px 0px;
}

.btn {
    background: linear-gradient(90deg, rgba(25, 180, 241, 1) 0%, rgba(14, 115, 232, 1) 100%) !important;
}

.captchaBox {
    width: 310px;
}

input {
    display: block;
    width: 290px;
    line-height: 40px;
    margin: 10px 0;
    padding: 0 10px;
    outline: none;
    border: 1px solid #c8cccf;
    border-radius: 4px;
    color: #6a6f77;
}

#msg {
    width: 100%;
    line-height: 40px;
    font-size: 14px;
    text-align: center;
}

a:link,
a:visited,
a:hover,
a:active {
    margin-left: 100px;
    color: #0366D6;
}
</style>
