<template>
	<div>
		<Container class="charge-detail hb-layout-vertical">
			<Container class="vital-signs">
                <el-card class="box-card"> 
                    <el-row style="background:#fff;">
                        <el-col :span="5"><div class="grid-content bg-purple">诊断</div></el-col>
                        <el-col :span="19"><div class="grid-content bg-purple-light">呼吸道感染</div></el-col>
                    </el-row>
                </el-card>

				<el-card class="box-card"> 
					<div slot="header" class="clearfix">
                        <div class="title">检查项目</div>
                    </div>
                     <Column :Data="inspectionItems"></Column>
					
                </el-card>

				<el-card class="box-card"> 
					<div slot="header" class="clearfix">
                        <div class="title">西药处方</div>
                    </div>
                       <Column :Data="westernmedicineItems"></Column>
                </el-card>

				<el-card class="box-card"> 
					<div slot="header" class="clearfix">
                        <div class="title">中药处方</div>
                    </div>
                    <el-row>
                        <el-col :span="5">
							<div class="grid-content purple">药名</div>
						</el-col>
                        <el-col :span="19">
							<div class="grid-content bg-purple-light display">
								<div>明细</div>
								<div>费用</div>
							</div>
						</el-col>
                    </el-row>
					<el-row>
                        <el-col :span="5">
							<div class="grid-content bg-purple">紫苏子</div>
						</el-col>
                        <el-col :span="19">
							<div class="grid-content bg-purple-light display">
								<div class="grid-content bg-purple-light">X 3盒</div>
								<div>单价:150元</div>
							</div>
						</el-col>
                    </el-row>
                </el-card>

				<el-card class="box-card"> 
					<div slot="header" class="clearfix">
                        <div class="title">费用结算</div>
                    </div>
                    <el-row>
                        <el-col :span="5">
							<div class="grid-content purple">结算</div>
						</el-col>
                        <el-col :span="19">
							<div class="grid-content bg-purple-light display">
								<div>明细</div>
								<div>费用</div>
							</div>
						</el-col>
                    </el-row>
					<el-row>
                        <el-col :span="5">
							<div class="grid-content bg-purple">应付价格</div>
						</el-col>
                        <el-col :span="19">
							<div class="grid-content purple-light display">
								<!-- <div>呼吸科</div> -->
								<div>单价:130元</div>
							</div>
						</el-col>
                    </el-row>
					<el-row>
                        <el-col :span="5">
							<div class="grid-content bg-purple">会员折扣</div>
							</el-col>
                        <el-col :span="19">
							<div class="grid-content purple-light display">
								<!-- <div>验血单</div> -->
								<div>-70元</div>
							</div>
						</el-col>
                    </el-row>
					<el-row>
                        <el-col :span="5">
							<div class="grid-content bg-purple">优惠活动</div>
						</el-col>
                        <el-col :span="19">
							<div class="grid-content purple-light display">
								<el-input v-model="activeInput" placeholder="请输入优惠活动码"></el-input>
								<div style="margin-left:10px;">-50元</div>
							</div>
						</el-col>
                    </el-row>
					<el-row>
                        <el-col :span="5">
							<div class="grid-content bg-purple">保险赔付</div>
							</el-col>
                        <el-col :span="19">
							<div class="grid-content purple-light display">
								<!-- <div>验血单</div> -->
								<div>无</div>
							</div>
						</el-col>
                    </el-row>
					<el-row>
                        <el-col :span="5">
							<div class="grid-content bg-purple">折扣项目</div>
							</el-col>
                        <el-col :span="19">
							<div class="grid-content bg-purple-light display">
								<div>看诊</div>
								<div>原价:<span>30元</span> <span>折后价:<span>15元</span></span></div>
							</div>
						</el-col>
                    </el-row>
					<el-row>
                        <el-col :span="5">
							<div class="grid-content bg-purple">实付价格</div>
							</el-col>
                        <el-col :span="19">
							<div class="grid-content purple-light display">
								<!-- <div>验血单</div> -->
								<div>总价:270元</div>
							</div>
						</el-col>
                    </el-row>
                </el-card>

				<div class="pay">
					<div class="method">
						<el-button>保险</el-button>
					    <el-button>现金</el-button>
					    <el-button>会员卡</el-button>
					</div>
					<div class="pay-btn">
						<el-button style="width: 150px" type="success">结算</el-button>
					</div>
					
				</div>
           </Container>
		</Container>
	</div>
</template>

<script>
import {
  components,
  blocks
} from '@/helper' ;

export default {
	  components: {
        // ...components([
        //     "Single"
        //    ], "charge.list"),
        ...components([
            'List',
            'Container',
        ]),
		...blocks([
				'Column',
			], 'Templates'),
    },

    data() {
        return {
            activeInput: '',
			inspectionItems:[{
                name: '看诊',
                detail: '呼吸科',
                price: '100',
            }, {
               name: '看诊2',
                detail: '呼吸科',
                price: '100',
            }, {
                name: '看诊3',
                detail: '呼吸科',
                price: '100',
            }],
			westernmedicineItems:[{
                name: '吗丁啉',
                detail: '呼吸科',
                price: '100',
            }, {
               name: '吗啡',
                detail: '呼吸科',
                price: '100',
            }, {
                name: '草金虎',
                detail: '呼吸科',
                price: '100',
            }]
        }
    },
    methods: {
        
    }
};
</script>

<style lang="scss" scoped>
.charge-detail {
	width: 100%;

    .vital-signs {
        margin: 2px 5px 15px;
		overflow: auto;
        height: calc(100vh - 200px);

		> .box-card{
			margin-bottom: 15px;
			box-shadow: 0 2px 7px 0 #d8d8d8;

			.el-row {
                // margin-bottom: 20px;
				height: 40px;
				line-height: 40px;
				border-top: 1px solid #e1e1e1;
				border-left: 1px solid #e1e1e1;
				border-right: 1px solid #e1e1e1;
				
                &:last-child {
                    margin-bottom: 0;
					border-bottom: 1px solid #e1e1e1;
                }
				 &:first-child {
                    background: #ebebeb;
                }
            }
            .bg-purple {
				text-align: center;
                border-right: 1px solid #e1e1e1;
            }
			.purple{
				text-align: center;
			}
            .bg-purple-light {
                padding: 0 15px;
            }
			.purple-light{
				padding: 0 15px;
				float: right;
			}
			.display{
				display: flex;
				justify-content: space-between;
				white-space: nowrap;
				overflow: hidden;

				.display-item{
					display: flex;
				    // align-items: center;

					.display-item-name{
						padding-right: 100px;
					}
				}
			}
		}

		.pay{
			margin-top: 50px;

			.method{
				display: flex;
			    justify-content: flex-end;
			}
			.pay-btn{
				display: flex;
			    justify-content: flex-end;
				margin-top: 20px;
			}
		}
       
	}
}


</style>
