<template>
  <Container class="appoientment-main-operation hb-layout-horizontal">
    <div style="width: 100%">
      <Container class="see-patient-history-list-item hb-layout-vertical">
        <Container class="body">
          <Container class="inner hb-layout-horizontal">
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">上级区域</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-cascader
                    style="width: 100%"
                    v-model="selectData.parentValue"
                    :props="defaultParams"
                    :options="treeSelectData"
                    :show-all-levels="false"
                    @change="handleChange"
                  ></el-cascader>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  名称</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input v-model="selectData.name" placeholder="请输入名称"></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">英文名称</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.english_name"
                    placeholder="请输入英文名称"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple grid-textarea">备注</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="selectData.remind"
                    placeholder="请输入备注"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
          </Container>
          <div class="btn">
            <el-button type="success" class="edit-buttom" @click="submitExamination"
              >提交</el-button
            >
          </div>
        </Container>
      </Container>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  props: {
    treeDatas: {},
  },
  watch: {
    treeDatas: function (val) {
      if (val.length > 0) {
        this.treeSelectData = this.getTreeData(this.arraytotree(val)); //去除最后children为空
      }
     
    },
  },
  data() {
    return {
      selectData: [],
      Data: {
        name: "",
        english_name: "",
        price: "",
        unit: "",
        remind: "",
      },
      name: "",
      parentValue: 0,
      parentValues: "",
      treeSelectData: [],
      defaultParams: {
        //可以选中一级内容
        //checkStrictly: true,
        //指定触发方式
        //expandTrigger: "hover",

        label: "name",
        value: "returnvisit_id",
        children: "children",
      },
    };
  },
  methods: {

     //增加
    onSelectData(itemData, treeData) {
      console.log("itemData",itemData);
      this.selectData = {};
      this.selectData.parentValue = Number(itemData.returnvisit_id);
    },
    //编辑
    onAppendSelectItem(itemData) {
      this.selectData = itemData;
      this.selectData.parentValue =  itemData.parent_id;

    },
    addtopitem() {
      this.selectData = {};
      this.selectData.parentValue =  0;
    },
    handleChange(value) {
      //this.parentValue= value;
      for (var i = 0; i < value.length; i++) {
        this.parentValue = value[i];
        //  console.log("log--",value[i] );
      }
    },
    //数组转化为树
    arraytotree(arr) {
      var top = [],
        sub = [],
        tempObj = {};

      arr.forEach(function (item) {
        if (item.parent_id === 0) {
          // 顶级分类
          top.push(item);
        } else {
          sub.push(item); // 其他分类
        }
        item.children = []; // 默然添加children属性
        tempObj[item.returnvisit_id] = Number(item); // 用当前分类的id做key，存储在tempObj中
      });

      sub.forEach(function (item) {
        // 取父级
        var parent = tempObj[item.parent_id] || { children: [] };
        // 把当前分类加入到父级的children中
        parent.children.push(item);
      });

      return top;
    },
    getTreeData(data) {
      // console.log("12", data.length);
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    submitExamination() {
      if (this.selectData.name == "" || this.selectData.name == null) {
        this.$alert("请输入名称");

        return;
      } 
      let data = {
        returnvisit_id: this.selectData.returnvisit_id,
        parent_id: this.selectData.parentValue,
        name: this.selectData.name,
        english_name: this.selectData.english_name,
        remind: this.selectData.remind,
      };
      //console.log("submit", data);
      this.$emit("submititemdata", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 10px 2px;
}

.see-patient-history-list-item {
  width: 100%;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }
      .grid-textarea{
        height: 96px;
        line-height: 96px;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    // position: fixed;
    // bottom: 30px;
    // right: 20px;

    .edit-buttom {
      margin-top: 30px;
    }
  }
}
</style>
