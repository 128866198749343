<template>
	<Container class="see-main-operation hb-layout-horizontal">
		<el-row style="width: 100%;">
			<el-col :span="1" style="text-align: end;">
				<el-button type="primary" @click="handleSort('storehouse_id')">全部</el-button>
			</el-col>
			<el-col :span="2" style="text-align: end;">
				<el-button type="success" @click="handleSort('stock')">库存</el-button>
			</el-col>
			<el-col :span="19">
				<div class="search-left">
					<el-input placeholder="请输入器材" v-model="name" class="input-with-select search">
						<el-button slot="append" @click="handleSearch" icon="el-icon-search"></el-button>
					</el-input>
				</div>

			</el-col>
			<el-col :span="2">
				<el-button type="primary">新增器材</el-button>
			</el-col>
		</el-row>
	</Container>
</template>

<script>
	import Container from '../../Container';

	export default {
		components: {
			Container
		},
    data(){
		  return {
		    name: '',
        order_type: 'asc',
        order_field: 'storehouse_id'
      }
    },
    methods:{
      /**
       * 触发搜索事件
       */
      handleSearch(){
        this.$emit('search' , this.name)
      },
      /**
       * 触发排序
       */
      handleSort(order_field){
        this.order_field = order_field;
        this.order_type = this.order_type == 'asc' ? 'desc' : 'asc'

        this.$emit('sort' , {order_field: this.order_field ,order_type : this.order_type });
      }
    }

	};
</script>

<style lang="scss" scoped>
	.see-main-operation {
		align-items: center;
		padding: 0 19px;
		height: 60px;
		line-height: 60px;
		margin: 0 4px;
		box-shadow: 0 2px 7px 0 #d8d8d8;
		margin: 5px 5px 10px 1px;

		.search-left {
			width: 500px;
			height: 60px;
			line-height: 60px;
			margin-left: 20px;
		}


	}
</style>
