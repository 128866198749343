<template>
    <Container class="see-patient-history-list-item hb-layout-vertical">
        <Header></Header>
        <Content @getleftchangefour="onGetLeftChangeFour"></Content>
    </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components([
        "Header",
        "Content"
    ], "configuration.MedicalHistory.Left"),

    ...components([
        "Container"
    ]),
  },

  props: {
    
  },
  
  data() {
    return {
      
    };
  },

  methods: {
     onGetLeftChangeFour(val) {
      this.$emit("getleftchangefour", val);
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
