import {
    include
} from 'zbee-sdk/package/hb-admin-system-template' ;

const
isArray = include('is.array'),
isObject = include('is.object.simple'),
isString = include('is.string'),
capitalize = include('string.capitalize');

function getFullName(className , namespace){

    if(isString(namespace)){

        return `${namespace}.${className}` ;
    }

    return className ;
}

function fromClassNameToPath(className){

    return className.replace(/\./g , '/') ;
}

function fromClassNameToName(className){

    return className.split(/\./).map(name => capitalize(name)).join('') ;

}

function getWidget(folder , path){

    return require(`@/${folder}/${path}`).default ;
    
}

function widgets(widgets , folder , namespace , suffix){

    if(isArray(widgets)){

        let result = {} ;

        for(let widget of widgets){

            let className,
                name;

            if(isObject(widget)){

                className = widget.code ;

                name = widget.name ;
            
            }else if(isString(widget)){

                className = getFullName(widget , namespace) ;

                name = fromClassNameToName(widget) ;

            }

            if(isString(suffix) && name.lastIndexOf(suffix) !== 0){

                name = `${name}${suffix}` ;
                
            }

            result[name] = getWidget(folder , fromClassNameToPath(className)) ;
            
        }

        return result ;
    }

    return {} ;
}

export function components(components , namespace){

    return widgets(components , 'components' , namespace) ;
}

export function layouts(layouts , namespace){

    return widgets(layouts , 'layouts' , namespace , 'Layout') ;
}

export function blocks(blocks , namespace){

    return widgets(blocks , 'blocks' , namespace) ;
}

export function config(path){

    return require(`../config/${path}`) ;
}

export function mock(path , params){

    return import(`@/mock/${path}`).then(async ({
        default:fn
    }) => await fn(params)) ;
}

// export function utils(path , params){

//     return import(`@/utils/${path}`).then(async ({
//         default:fn
//     }) => await fn(params)) ;
// }

// export function plugin(path , params){

//     return import(`@/plugin/${path}`).then(async ({
//         default:fn
//     }) => await fn(params)) ;
// }


// export function store(path , params){

//     return import(`@/store/${path}`).then(async ({
//         // default:fn
//     }) => await fn(params)) ;
// }