<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header></Header>
    <div class="display"> 
    <Container class="header hb-layout-horizontal">
      <div class="time hb-layout-flex-1">A4-活页本</div>
    </Container>
    <Container class="body">
      <Container class="inner hb-layout-horizontal">
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">类目</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">办公用品</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">单位</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">本</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">品牌</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">白金丽人</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">厂家</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">上海有加皮具有限公司</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">型号</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">H-021A4</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">规格</div>
          </el-col>
          <el-col :span="16">
            <div class="grid-content bg-purple-light">A4纸</div>
          </el-col>
        </el-row>
        <el-row style="width: 100%;">
          <el-col :span="8">
            <div class="grid-content bg-purple">领用科室</div>
          </el-col>
          <el-col :span="16">
             <div class="grid-content bg-purple-light">儿科</div>
          </el-col>
        </el-row>

      </Container>
    </Container>
    
    </div>
  </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: {
    ...components([
        "Header",
    ], "storage.Material.Right"),
    ...components([
        "Container"
    ]),
  },
  props: {
    
  }

}
</script>

<style lang="scss" scoped>
.see-patient-history-list-item {
  // height: 100%;
  margin: 0 0px 15px;
  // box-shadow: 0 2px 7px 0 #d8d8d8;

  .display{
    margin: 0 0 10px 2px;
    border: 1px solid #edebeb;

    > .header {
      padding: 0 10px;
      height: 50px;
      line-height: 50px;
      background-color: #EDEDED;
      border-bottom: 1px solid #e1e1e1;

      > .time {
        font-size: 15px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5B5B5B;
      }

      > .button {
        height: 30px;
        margin-top: 12px;
      }
    }

    > .body {
      padding: 0px;

      > .inner {
        display: flex;
        flex-direction: column;
        border-left: 1px solid #e1e1e1;
        border-right: 1px solid #e1e1e1;

        .el-row {
          width: 100%;
          // height: 45px;
          line-height: 45px;

          &:last-child {
            border-bottom: 1px solid #e1e1e1;
          }
        }

        .grid-content {
          border-top: 1px solid #e1e1e1;
          font-size: 13px;
        }

        .bg-purple {
          text-align: center;
          padding-left: 10px;
          background: #EBEBEB;
          border-right: 1px solid #e1e1e1;
        }

        .bg-purple-light {
          padding-left: 10px;
          // border-left: 1px solid #e1e1e1;
          // border-right: 1px solid #e1e1e1;
        }

      }

    }
  }

  
}
</style>
