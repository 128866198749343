<template>
  <Container class="toolbar hb-layout-center">
    <el-button :type="typeClass" @click="onChangeButtonClick(1)">日视图</el-button>
    <el-button :type="weekTypeClass" @click="onChangeButtonClick(2)">周视图</el-button>
    <el-button type="primary" v-if="calendarSetting == 2" @click="onPreviousButtonClick"
      >上一周</el-button
    >
    <el-button type="primary" v-if="calendarSetting == 2" @click="onNextButtonClick"
      >下一周</el-button
    >
    <el-button type="primary" v-if="calendarSetting == 1" @click="onPreviousButtonClick"
      >上一日</el-button
    >
    <el-button type="primary" v-if="calendarSetting == 1" @click="onNextButtonClick"
      >下一日</el-button
    >
  </Container>
</template>
<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    calendarSetting: 0,
  },
  data() {
      return{
        typeClass:"success",
        weekTypeClass:"primary"
      }
  },
  methods: {
    onChangeButtonClick(val) {
      if(val==1){
        this.typeClass = "success"
        this.weekTypeClass="primary"
      }else if(val==2){
         this.typeClass = "primary"
         this.weekTypeClass="success"
      }
      this.$emit("changebuttonclick", val);
    },
    onPreviousButtonClick() {
      this.$emit("previousbuttonclick");
    },

    onNextButtonClick() {
       this.$emit("nextbuttonclick");
    },
  },
};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.doctors {
  width: 200px;
  padding: 5px;
  border-right: 1px solid #000;

  > button {
    margin: 10px 0 10px 0;
  }
}

.calendar {
  padding: 5px;
}

.calendar-details {
  height: 100%;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  padding: 10px;
}

.toolbar {
  height: 40px;
  margin-bottom: 20px;

  > button {
    margin: 0 10px 0 10px;
  }
}

.plan {
  background-color: rgb(235, 192, 192);
}

.plan:hover {
  background-color: rgb(242, 216, 216);
}

.hb-calendar.week {
  .title {
    height: 30px !important;
  }

  .time:hover {
    background-color: rgb(242, 216, 216);
  }

  > .times {
    border: none !important;
    border-right-width: 0 !important;
    line-height: 30px;

    > .title,
    > .time:not(:last-child) {
      border-bottom: 1px solid #979797 !important;
    }
  }

  > .weeks {
    > .week {
      border: none !important;
      border-left: 1px solid #979797 !important;
      border-right: none !important;

      > .title,
      > .time:not(.time:last-of-type) {
        border-bottom: 1px solid #979797 !important;
      }
    }
  }
}

.hb-layout-flex {
  border: 1px solid #979797 !important;
}

.form {
  height: calc(100% - 80px);
  overflow-y: auto;
}
</style>
