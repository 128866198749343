<template>
  <PatientLayout>
    <template v-slot:patient-list>
      <LeftMain
        ref="medicalRecordList"
        :loading="loading"
        class="hb-layout-fit-sized"
        :medicalRecordList="medicalRecordList"
        @itemselect="onItemSelect"
        @leftsearch="onleftSearch"
        @todayevent="onTodayEvent"
        @sevenevent="onSevenEvent"
        @thirtyevent="onThirtyEvent"
        @calendarevent="onCalendarEvent"
      ></LeftMain>
    </template>

    <template v-slot:patient-detail>
      <CenterMain
        ref="centermain"
        :medicalRecord="medicalRecord"
        :medicalRecordItem="medicalRecordItem"
        :diagnosisRecordList="diagnosisRecordList"
        :paymentRecord="paymentRecord"
        :rechargeRecordList="rechargeRecord"
        :appointmentRecord="appointmentRecord"
        @regionlist="onRegionList"
        @historylist="onHistoryList"
        :historyDatas="historyRecords.items"
        :regionDatas="regionRecords"
        @visitpath="onVisitPath"
        @preservation="onPreservation"
        @submitrecharge="onSubmitRecharge"
        @submitupgrade="onSubmitUpgrade"
        @gradelevel="onGradeLevel"
        @gradelrecharge="onGradelRecharge"
        :usermoney="usermoney"
        :gradelevelDatas="gradelevelRecords.items"
        :gradeRechargeDatas="gradeRechargeRecords.items"
        @historymedicalitem="getHistoryMedicalItem"
        :historyMedicalDatas="historyMedicalRecords"
        @editmedicalrecorditem="onEditMedicalRecordItem"
        :paymentParam="paymentParam"
        @treatmenttemplateparent="onTreatmentTemplateParent"
        :treatmenttemplateDatas="treatmenttemplateRecords"
        @gettreatmenttemplatechildren="onGetTreatmentTemplateChildren"
        :treatmenttemplateChildrenDatas="treatmenttemplateChildrenRecords"
        @getpaymentrecrods="onGetPaymentRecrods"
        :paymentDatas="paymentRecords.items"
        @savepaymentitem="onSavePaymentItem"
        @insurancecompany="onInsuranceCompany"
        :insuranceCompanyDatas="insuranceCompanyRecords"
        @insurancelevel="onInsuranceLevel"
        :insuranceLevelDatas="insuranceLevelRecords.items"
        @insurancesetting="onInsuranceSetting"
        :insuranceSettingDatas="insuranceSettingRecords.items"
        @saveinsuranceaccountitem="onSaveInsuranceAccountItem"
        @insuranaccount="onInsuranAccount"
        :insuranceAccountDatas="insuranceAccountRecords.items"
        @binddoctorsearch="onBindDoctorSearch"
        :bindSearchDatas="bindsearchList"
        @submitbindsource="onSubmitBindSource"
        @customersource="onCustomerSource"
        :sourceDatas="sourceRecords"
        @insuranceblurvalue="onInsuranceBlueValue"
        @remarkblurvalue="onRemarkBlueValue"
         @clearrightdata="onClearRightData"
      ></CenterMain>
    </template>

    <template v-slot:patient-history-list>
      <RightMain
        ref="rightmain"
        :historyDiagnosisList="historyDiagnosisList"
        :visitPathData="visitPathData.items"
        :DiannosisData="DiannosisItem"
        :diagnosisDetail="diagnosisDetail"
        :paymentParam="paymentParam"
        @datechange="historyScreeningTimeChange"
        @returnrecords="onReturnRecords"
        :returnDatas="returnRecords.items"
        @returnsaveclick="onReturnSaveClick"
        :visitreturnDatas="visitreturnRecords.items"
        :clearData="clearData"
      ></RightMain>
    </template>
  </PatientLayout>
</template>

<script>
import Page from "@/components/Page";

import {
  // eslint-disable-next-line no-unused-vars
  mock,
  components,
  layouts,
} from "@/helper";
import { showLoading, hideLoading } from "../utils/loading";

export default {
  components: {
    ...layouts(["Patient"]),
    ...components(["Left.Main", "Center.Main", "Right.Main"], "patient"),
  },
  data() {
    return {
      leftListParam: {
        //列表参数
        page_no: 1,
        page_size: 10,
        real_name: undefined,
      },
      nowDate: "",
      loading: {
        status: false, //预约列表loading
      },
      diagnosisDetail: {}, //点击看诊记录储存的看诊记录信息
      medicalRecordList: {}, //病历列表
      medicalRecord: {}, //触发点击后的病历对象
      medicalRecordItem: {},
      appointment_record_no_first: 0,
      appointment_record_no: "",
      diagnosisRecordList: {}, //看诊记录历史
      historyDiagnosisList: [], //历史记录
      rechargeRecordList: {},
      rechargeRecord: {}, //充值记录
      paymentRecord: {},
      appointmentRecord: {},
      history_param: {
        //历史列表筛选参数
        diagnosis_record_id: undefined,
        create_time: undefined,
      },
      sourceRecords: {}, //客户渠道
      returnVisit: [], //看诊详情
      visitPathData: [], //就诊路径
      DiannosisItem: {},
      usermoney_basic: {
        balance: 0,
        lock_balance: 0,
        lock_points: 0,
        points: 0,
        total_recharge: 0,
        total_money: 0,
      },
      usermoney: {},
      centerRecordsParam: {
        //列表参数
        appointment_date: undefined,
        dayEvent: undefined, //1
        keywords: undefined,
        eventEvent: undefined, //7
        thirtyEvent: 1, //30
      },
      rechargeParam: {
        medical_record_no: undefined,
        client_id: undefined,
        money: undefined,
        to_payment: undefined,
        cause: "账户充值",
        giveMoney: undefined,
        giveAmount: undefined,
        create_id: undefined,
      },
      paymentParam: {
        create_id: 1,
        client_id: 0,
        create_name: "收到上",
        user_name: "admin",
        type: 1,
        stock_warehouse_id: 1,
        status: 1,
        user_level: 0.8,
        user_level_id: 1,
        cause: "",
        medical_record_no: undefined,
      },
      upgradeParam: {
        client_id: undefined,
        type: undefined,
        cause: undefined,
        create_id: undefined,
      },
      gradelevelRecords: {},
      gradeRechargeRecords: {},
      regionRecords: {},
      historyRecords: {},
      historyMedicalRecords: {},
      treatmenttemplateRecords: {},
      treatmenttemplateChildrenRecords: {},
      paymentRecords: {},
      returnRecords: {},
      visitreturnRecords: {},
      insuranceCompanyRecords: {},
      insuranceLevelRecords: {},
      insuranceSettingRecords: {},
      insuranceAccountRecords: {},
      userInfo: "",
      bindsearchList: {},
      clearData:false
    };
  },
  mounted() {
    let that = this;

    that.formatDate();
    that.getMedicalRecordList();
    that.initPage();
    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    that.rechargeParam.create_id = that.userInfo.client_id;
    that.upgradeParam.create_id = that.userInfo.client_id;
    that.paymentParam.create_id = that.userInfo.client_id;
    that.paymentParam.user_name = that.userInfo.name;
    // 默认选中操作å
  },
  methods: {
    /** left start */
    /**
     * 初始化页面的方法
     */
    initPage() {
      // let that = this;
      // if (that.medicalRecordList.length) {
      //   that.$refs.medicalRecordList.select(that.medicalRecordList[0]);
      // }
    },
    onItemSelect(val) {

      
      if (val !== undefined) {
        let me = this;

        let medicalNo = val.medical_record_no;

        // 根据患者的病历号获取一个患者病历记录信息
        me.getMedicalRecord(medicalNo);
        // 根据患者的病历号获取患者的看诊记录
        me.getDiagnosisRecord(medicalNo);
        // 根据患者的病历号获取患者的缴费记录
        me.getPaymentRecord(medicalNo);
        // 根据患者的病历号获取患者的预约记录
        me.getAppointmentRecord(medicalNo);
        // 根据患者的病历号获取患者的充值记录
        me.getRechargeRecord(medicalNo);
        // that.history_param.diagnosis_record_id = medical.medical_record_id

        me.history_param.medical_record_no = medicalNo; //患者预约号
        me.paymentParam.medical_record_no = medicalNo; //赋值点击获取的数据的病历号
        me.paymentParam.client_id = val.user_id;
        //me.paymentParam.user_name = val.real_name;

        // 获取患者就诊路径
        if (me.appointment_record_no_first !== 0) {
          //就诊路径
          me.getVisitPath(me.appointment_record_no_first);
          //获取回访数据
          me.getVisitReturnRecords(me.appointment_record_no_first);
        }

        // if(this.clearData){
        //     me.visitreturnRecords.items = {};
        //     console.log("visitreturnRecords1",me.visitreturnRecords);
        // }
       

        // 根据患者的病历号获取一个患者病历档案信息
        me.getMedicalRecordItem(medicalNo);
        //获取账户信息 user_money
        me.getUserMoneyRecordItem(val.user_id);
        me.rechargeParam.client_id = val.user_id; //给clinet_id 赋值
        me.upgradeParam.client_id = val.user_id; //给clinet_id 赋值
      }

      // 获取患者回访记录
      //TODO
      //看诊详情
      //me.getVisitDetails(medicalNo);
    },
    onClearRightData(val){
       this.clearData = val;
    },
    onleftSearch(e) {
      let me = this;
      me.centerRecordsParam.keywords = e;
      me.centerRecordsParam.appointment_date = undefined;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      this.getMedicalRecordList();
    },
    onTodayEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.getMedicalRecordList();
    },
    onSevenEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.centerRecordsParam.eventEvent = 1;
      me.getMedicalRecordList();
    },
    onThirtyEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = 1;
      me.getMedicalRecordList();
    },
    onCalendarEvent(e) {
      let me = this;
      me.centerRecordsParam.appointment_date = e;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.getMedicalRecordList();
    },
    /** left end */
    /** center start */
    /**
     * 患者看诊记录点击事件,获取患者就诊路径+回访记录
     * 1:就诊路径
     * * onVisitPath
     *   参数：medicalRecordNo |患者记录病历号|
     * 2:回访记录
     *   TODO
     */
    onVisitPath(val) {
      if (val !== null) {
        let me = this;

        //me.getVisitPath(val.appointment_record_no);

        // me.appointment_record_no = val.appointment_record_no;
        me.diagnosisDetail = val; //储存看诊记录信息

        me.getVisitPath(val.appointment_record_no);

        me.paymentParam.appointment_record_no = val.appointment_record_no;

        //获取回访数据
        me.getVisitReturnRecords(val.appointment_record_no);
      }
    },
    //患者档案信息保存
    onPreservation(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.onMedicalRecordSave(data);
        })
        .catch();
    },
    //获取会员等级，升级用
    onGradeLevel() {
      let me = this;
      me.getGradeLevel();
    },
    //获取充值活动列表
    onGradelRecharge() {
      let me = this;
      me.getGradeRecharge();
    },
    onRegionList() {
      let me = this;
      me.getRegionRecords();
    },
    onHistoryList() {
      let me = this;
      me.getHistoryRecords();
    },
    getHistoryMedicalItem() {
      let me = this;
      me.getHistoryMedialRecords();
    },
    onEditMedicalRecordItem(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setMedicalRecordEditItem(data);
        })
        .catch();
    },
    onTreatmentTemplateParent() {
      let me = this;
      me.getTreatmentTemplateRecords();
    },
    onGetTreatmentTemplateChildren(val) {
      let me = this;
      me.getTreatmentTemplateChildrenRecords(val);
    },
    onGetPaymentRecrods() {
      let me = this;
      me.getPaymentRecords();
    },
    //保存结算
    onSavePaymentItem(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setPaymentRecordItem(data);
        })
        .catch();
    },
    //获取保险公司
    onInsuranceCompany() {
      let me = this;
      me.getInsuranceCompanyRecords();
    },
    //获取保险级别
    onInsuranceLevel(data) {
      let me = this;
      me.getInsuranceLevelRecords(data);
    },
    //获取保险设置信息
    onInsuranceSetting(data) {
      let me = this;
      me.getInsuranceSettingRecords(data);
    },
    //保存患者保险账户信息
    onSaveInsuranceAccountItem(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setInsuranceAccountItem(data);
        })
        .catch();
    },
    //获取患者保险账户信息
    onInsuranAccount() {
      let me = this;
      me.getInsuranceAccountRecords();
    },
    //绑定客户来源--根据关键词搜索员工信息-doctor表
    onBindDoctorSearch(query) {
      let me = this;
      me.getBindDoctorSearch(query);
    },
    //绑定客户来源
    onSubmitBindSource(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setBindSourceItem(data);
        })
        .catch();
    },
    //获取客户渠道信息
    onCustomerSource() {
      let me = this;
      me.getCustomerSource();
    },
    //保险账户修改金额
    onInsuranceBlueValue(data) {
      this.setInsuranceBlurValue(data);
    },

    // 保险备注
    onRemarkBlueValue(data) {
      data = {
        ...data,
        medical_record_no: this.paymentParam.medical_record_no,
      };
      //console.log("保险备注", data);

      this.setRemarkBlurValue(data);
    },
    /** ********************** ********************** *********************center end * ********************** **********************/
    /** * ********************** ********************** *********************right start */
    /**
     * 患者看诊详情
     */
    onVisitDetails() {
      let me = this;
      // this.getVisitDetails(me.appointment_record_no);
    },
    onReturnRecords() {
      let me = this;
      me.getReturnRecords();
    },
    onReturnSaveClick(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setReturnRecordItem(data);
        })
        .catch();
    },
    /** right end */

    async setReturnRecordItem(data) {
      let me = this;
      try {
        await mock("patient/right/setReturnRecordItem", data).then((res) => {
          if (res.status == 200) {
            me.$message({
               showClose: true,
              type: "success",
              message: "保存成功!",
            });
            me.$refs.rightmain.changeDrawer(false);
            me.getVisitReturnRecords(me.paymentParam.appointment_record_no);
          } else if (res.status == 500) {
            me.$message({
               showClose: true,
              type: "warning",
              message: res.message,
            });
          } else {
            me.$message({
               showClose: true,
              type: "warning",
              message: "保存失败",
            });
          }
        });
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    async getReturnRecords() {
      let me = this;
      //try {
      let res = await mock("configuration/Inside/Returnvisit/getRecords");
      if (res.status == 200) {
        me.returnRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    //请求客户渠道事件
    async getCustomerSource() {
      let me = this;
      let res = await mock("configuration/Inside/Source/getRecords");
      if (res.status == 200) {
        me.sourceRecords = res.data.items;
      }
    },
    /**
     * 搜索员工-绑定客户来源
     */
    async getBindDoctorSearch(e) {
      let me = this;
      //me.searchList =  me.states;
      //search(6): 获取远程返回的值并赋值
      // try {
      me.bindsearchList = await mock("patient/Center/getDoctorSearchRecords", e);
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    async getInsuranceAccountRecords() {
      let me = this;
      let data = {
        medical_record_no: this.paymentParam.medical_record_no,
      };
      //try {
      let res = await mock("configuration/Insurnace/Account/getRecords", data);
      if (res.status == 200) {
        me.insuranceAccountRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },

    async setInsuranceAccountItem(data) {
      let me = this;
      let datas = {
        balance: data.balance,
        count: data.count,
        total_account: data.total_account,
        total_count: data.total_count,
        user_id: data.user_id,
        type: data.type,
        start_time: data.start_time,
        end_time: data.end_time,
        insurance_setting_id: data.insurance_setting_id,
        insurance_account_id: data.insurance_account_id,
        insurance_id: data.insurance_id,
        insurance_level_id: data.insurance_level_id,
        create_id: data.create_id,
        medical_record_no: me.paymentParam.medical_record_no,
        is_delete: data.is_delete,
        levelname: data.levelname,
        fun: data.fun,
      };
      try {
        let res = await mock("patient/Center/setInsuranceAccountItem", datas);
        if (res.status == 200) {
          me.$message({
            type: "success",
            message: "保存成功!",
          });
          me.$refs.centermain.changeDrawer(false);
          me.getInsuranceAccountRecords();
        } else if (res.status == 500) {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
          me.$refs.centermain.changeDrawer(false);
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: "保存失败",
          });
          me.$refs.centermain.changeDrawer(false);
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    async setInsuranceBlurValue(data) {
      let me = this;

      try {
        let res = await mock("patient/Center/setInsuranceBlurValue", data);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
        } else if (res.status == 500) {
          me.$message({
            type: "warning",
            message: res.message,
          });
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: "保存失败",
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    async setRemarkBlurValue(data) {
      let me = this;

      try {
        let res = await mock("patient/Center/setRemarkBlurValue", data);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
        } else if (res.status == 500) {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: "保存失败",
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    //绑定客户来源
    async setBindSourceItem(data) {
      let me = this;
      //重组数据
      let datas = {
        bind_doctor_id: data.doctor_id,
        bind_name: data.doctor_name,
        bind_department_id: data.department_id,
        bind_source_id: data.bind_source_id[1],
        medical_record_no: me.paymentParam.medical_record_no,
      };
      try {
        let res = await mock("patient/Center/setBindSourceItem", datas);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.centermain.changeInfoDrawer(false); //关闭弹窗
          me.getMedicalRecord(me.paymentParam.medical_record_no); //刷新病例数据
        } else if (res.status == 500) {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: "保存失败",
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    async getInsuranceSettingRecords(data) {
      let me = this;
      // try {
      let res = await mock("configuration/Insurnace/Setting/getItem", data);
      if (res.status == 200) {
        me.insuranceSettingRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    async getInsuranceLevelRecords(data) {
      let me = this;
      // try {
      let res = await mock("configuration/Insurnace/Setting/getRecords", data);
      if (res.status == 200) {
        me.insuranceLevelRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    async getInsuranceCompanyRecords() {
      let me = this;
      // try {
      let res = await mock("configuration/Insurnace/Company/getRecords");
      if (res.status == 200) {
        me.insuranceCompanyRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 获取患者的简要信息列表
     */
    async getMedicalRecordList() {
      showLoading();
      let me = this;
      // try {
      let res = await mock("patient/left/getMedicalRecordList", me.centerRecordsParam);
      if (res.status == 200) {
        me.medicalRecordList = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }

      hideLoading();
    },
    async setPaymentRecordItem(data) {
      let me = this;
      try {
        let res = await mock("patient/Center/setPaymentRecordItem", data);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.centermain.changeInfoDrawer(false);
          me.getMedicalRecordList();
        } else if (res.status == 500) {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: "保存失败",
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    async getTreatmentTemplateRecords() {
      let me = this;
      // try {
      let res = await mock(
        "configuration/Appointment/TreatmentTemplate/getParentRecords"
      );
      if (res.status == 200) {
        me.treatmenttemplateRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    async getTreatmentTemplateChildrenRecords(val) {
      let me = this;
      // try {
      let res = await mock(
        "configuration/Appointment/TreatmentTemplate/getChildrenRecords",
        {
          treatment_template_id: val,
        }
      );
      if (res.status == 200) {
        me.treatmenttemplateChildrenRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },

    async getRegionRecords() {
      let me = this;
      // try {
      me.regionRecords = await mock("public/Region/getTreeRecords");
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    async getHistoryRecords() {
      let me = this;
      // try {
      let res = await mock("configuration/History/getRecords");
      if (res.status == 200) {
        me.historyRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    async getHistoryMedialRecords() {
      let me = this;
      // try {
      let res = await mock("patient/Center/getMedicalRecords", {
        medical_record_no: me.history_param.medical_record_no,
      });
      if (res.status == 200) {
        me.historyMedicalRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    //回访记录
    async getVisitReturnRecords(data) {
      let me = this;
      // try {
      let res = await mock("patient/right/getVisitReturnRecords", {
        appointment_record_no: data,
      });
      if (res.status == 200) {
        me.visitreturnRecords = res.data;
      }

      //console.log("visitreturnRecords",data);
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    async getPaymentRecords() {
      let me = this;
      // try {
      let res = await mock("configuration/Inside/Payment/getRecords");
      if (res.status == 200) {
        me.paymentRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 根据患者的病历号获取一个患者病历记录信息
     * 看诊详情
     */
    async getMedicalRecord(val) {
      let me = this;
      // try {
      me.medicalRecord = await mock("patient/middle/getMedicalRecordInfoItem", {
        medical_record_no: val,
      });
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 根据患者的病历号获取一个患者缴费记录信息
     */
    async getPaymentRecord(val) {
      let me = this;
      // try {
      me.paymentRecord = await mock("patient/Center/getPaymentRecords", {
        medical_record_no: val,
      });
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 根据患者的病历号获取一个患者预约记录信息
     */
    async getAppointmentRecord(val) {
      let me = this;
      // try {
      me.appointmentRecord = await mock("patient/Center/getAppointmentRecords", {
        medical_record_no: val,
      });
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 根据患者的病历号获取一个患者充值记录信息
     */
    async getRechargeRecord(val) {
      let me = this;
      // try {
      me.rechargeRecord = await mock("patient/Center/getRechargeRecords", {
        medical_record_no: val,
      });
      //  let res= await mock("patient/Center/getRechargeRecords", {
      //     medical_record_no: val,
      //   });
      // if(res)
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 根据患者的病历号获取一个患者病历记录信息
     * 档案信息
     */
    async getMedicalRecordItem(val) {
      let me = this;
      // try {
      me.medicalRecordItem = await mock("patient/middle/getMedicalRecordInfoItem", {
        medical_record_no: val,
      });
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 根据患者的病历号获取一个患者病历记录信息
     */
    async getDiagnosisRecord(val) {
      let me = this;
      // try {
      me.diagnosisRecordList = await mock("patient/middle/getDiagnosisRecordList", {
        medical_record_no: val,
      });

      if (me.diagnosisRecordList.items.length > 0) {
        me.appointment_record_no_first = this.get_object_first_attribute(
          me.diagnosisRecordList
        ); //获取看诊记录第一条的appointment_record_no  预约编号
      } else {
        me.appointment_record_no_first = 0;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }

      //   "获取第一条看诊记录信息的病历号：" +
      //     this.get_object_first_attribute(me.diagnosisRecordList)
      // );
    },
    /**
     * 获取患者的看诊记录的看诊详情
     */
    //async getVisitDetails() {
    //let me = this;
    // try {
    //me.medicalRecordList = await mock(
    // "patient/left/getMedicalRecordList",
    // );
    // } catch (err) {
    //   me.$message({
    //     type: "warning",
    //     message: err.response.data.message,
    //   });
    // }
    // },
    /**
     * 获取患者的看诊记录的看诊详情
     */
    async getDiannosisDetails(val) {
      let me = this;
      // try {
      me.DiannosisItem = await mock("patient/right/getDiagnosisRecordItem", val);
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },

    /**
     * 获取患者就诊路径
     */
    async getVisitPath(val) {
      let me = this;
      // try {
      me.visitPathData = await mock("patient/right/getVisitPath", {
        appointment_record_no: val,
      });
      if (me.visitPathData == null) {
        me.visitPathData = {};
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 获取患者回访记录
     */
    async getVisitDetails() {
      let me = this;
      // try {
      me.historyDiagnosisList = await mock(
        "patient/right/getVisitDetails",
        me.history_param
      );

      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    /**
     * 获取患者账户信息
     */
    async getUserMoneyRecordItem(val) {
      let me = this;
      // try {
      let res = await mock("patient/middle/getUserMoneyItem", {
        client_id: val,
      });
      if (res.status == 200) {
        me.usermoney = res.data;
      } else {
        me.usermoney = me.usermoney_basic;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },

    async onMedicalRecordSave(data) {
      let that = this;

      try {
        that.ressave = await mock("patient/middle/setMedicalRecord", data).then((res) => {
          // 获取医生一周内的预约记录
          // me.getDoctorRecord({
          //   doctor_id: e.doctorID,
          //   appointment_date: "2021-04-28",
          // });
          if (res.status === 200) {
            that.$message({
               showClose: true,
              type: "success",
              message: "保存成功!",
            });
            that.$refs.centermain.changeDrawer(false);
            that.getMedicalRecordList();
          } else if (res.status === 500) {
            that.$message({
               showClose: true,
              type: "warning",
              message: res.message,
            });
          } else {
            that.$message({
               showClose: true,
              type: "warning",
              message: "保存失败",
            });
          }
        });
      } catch (err) {
        that.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    async setMedicalRecordEditItem(data) {
      let that = this;
      try {
        that.ressave = await mock("patient/Center/setMedicalRecordEditItem", data).then(
          (res) => {
            // 获取医生一周内的预约记录
            // me.getDoctorRecord({
            //   doctor_id: e.doctorID,
            //   appointment_date: "2021-04-28",
            // });
            if (res.status === 200) {
              that.$message({
                 showClose: true,
                type: "success",
                message: "保存成功!",
              });
              that.$refs.centermain.changeDrawer(false);
              that.getMedicalRecordList();
            } else if (res.status === 500) {
              that.$message({
                 showClose: true,
                type: "warning",
                message: res.message,
              });
            } else {
              that.$message({
                 showClose: true,
                type: "warning",
                message: "保存失败",
              });
            }
          }
        );
      } catch (err) {
        that.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    onSubmitRecharge(val) {
      let me = this;
      me.$refs.centermain.changeInfoDrawer(false);
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.rechargeParam.medical_record_no = me.medicalRecord["medical_record_no"];
          me.rechargeParam.user_id = me.medicalRecord["user_id"];
          me.rechargeParam.money = val.rechargeAmount; // 充值金额
          me.rechargeParam.giveMoney = val.giveInput; // 活动赠送金额
          me.rechargeParam.giveAmount = val.giveAmount; //// 额外赠送金额
          me.rechargeParam.to_payment = val.paymentMethodValue;
          me.rechargeParam.cause = val.rechargeRemarks;
          // me.rechargeParam.create_id = me.userInfo.client_id;
          me.setRecharge();
        })
        .catch();
    },
    async setRecharge() {
      let me = this;
      try {
        let request = await mock(
          "patient/Center/setRechargeItem",
          this.rechargeParam
        ).then((res) => {
          if (res) {
            me.$message({
               showClose: true,
              type: "success",
              message: "保存成功!",
            });
            me.$refs.centermain.changeDrawer(false);
            me.getUserMoneyRecordItem(me.rechargeParam.client_id);
          } else {
            me.$message({
               showClose: true,
              type: "warning",
              message: "保存失败!",
            });
            me.$refs.centermain.changeDrawer(false);
          }
        });
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    onSubmitUpgrade(val) {
      let me = this;
      me.$refs.centermain.changeDrawer(false);
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.upgradeParam.type = val.gradeValue; // 充值金额
          me.upgradeParam.cause = val.gradeRemarks;
          me.setUpgradeLevel();
        })
        .catch();
    },
    async setUpgradeLevel() {
      let me = this;
      try {
        await mock("patient/Center/setUserLevelItem", this.upgradeParam).then((res) => {
          if (res) {
            me.$message({
               showClose: true,
              type: "success",
              message: "保存成功!",
            });
            me.$refs.centermain.changeInfoDrawer(false);
            me.getMedicalRecordList();
          } else {
            me.$message({
               showClose: true,
              type: "warning",
              message: "保存失败!",
            });
            me.$refs.centermain.changeInfoDrawer(false);
          }
        });
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 历史记录 时间筛选触发
     */
    historyScreeningTimeChange(date) {
      let me = this;
      me.history_param.create_time = date;
      me.getHistoryDiagnosisList();
    },
    //获取OBJ的第一条数据,并去除“”
    get_object_first_attribute(data) {
      for (var key in data)
        return JSON.stringify(data[key][0].appointment_record_no)
          .replace('"', "")
          .replace('"', "");
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.centerRecordsParam.appointment_date = `${year}-${month}-${day}`;
      this.nowDate = `${year}-${month}-${day}`;
    },
    async getGradeLevel() {
      let me = this;
      // try {
      let res = await mock("configuration/Marketing/Level/getRecords");
      if (res.status === 200) {
        me.gradelevelRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
    async getGradeRecharge() {
      let me = this;
      // try {
      let res = await mock("configuration/Marketing/Activities/getRecords");
      if (res.status === 200) {
        me.gradeRechargeRecords = res.data;
      }
      // } catch (err) {
      //   me.$message({
      //     type: "warning",
      //     message: err.response.data.message,
      //   });
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.search-left {
  margin: 4px 2px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  height: 60px;
  line-height: 60px;
}

.search {
  width: 295px;
  padding: 0 10px;

  > .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 160px;
    margin-right: 10px;
  }
}
</style>
