<template>
  <Container class="app hb-layout-fit-unsized hb-layout-vertical">
      <Container class="header hb-layout-horizontal">
          <div class="logo">
              <slot name="logo"></slot>
          </div>
          <div class="menu hb-layout-flex-1 hb-layout-center">
              <slot name="menu"></slot>
          </div>
          <div class="lang hb-layout-center">
              <slot name="lang"></slot>
          </div>
          <div class="user hb-layout-center">
              <slot name="user"></slot>
          </div>
      </Container>
      <Container class="body hb-layout-flex-1">
          <slot name="body"></slot>
      </Container>
  </Container>
</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components:components([
    'Container'
  ])
}
</script>

<style lang="scss" scoped>

$app-padding:11px 39px 11px 31px;

$app-header-height:66px;

$app-header-logo-width:100px;

$app-header-lang-width:100px; 

$app-header-user-width:100px;

    .app{
        > .header{
			padding:$app-padding;
			// background-color: #eeeeee;
			box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
            height: $app-header-height;
			margin-bottom: 10px;
            > .logo{
                width:$app-header-logo-width;
                display: flex;
                flex-direction: row;
                align-items: center;
            }
            >.lang{
                width:$app-header-lang-width;
            }
            >.user{
                width:$app-header-user-width;
            }
        }
    }

</style>
