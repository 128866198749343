<template>
    <div class="scroll-bar" :class="{
        vertical:isVertical,
        horizontal:isHorizontal,
        hidden
    }" :style="style"></div>
</template>

<script>

export default {
    
    props:{
        direction:{
            type:String,
            validator:direction =>[
                'vertical',
                'horizontal'
            ].includes(direction),
            default:'vertical'
        },
        size:Number,
        position:{
            type:Number,
            default:0
        },
        hidden:{
            type:Boolean,
            default:false
        }
    },

    computed:{

        style(){

            let {
                direction,
                position,
                size
            } = this ;

            if(direction === 'vertical'){

                return {
                    height:`${size}px`,
                    transform:`translate3d(0 , ${position}px  , 0)`,
                } ;    
            }

            return {
                width:`${size}px`,
                transform:`translate3d(${position}px , 0 , 0)`,
            } ;
        },

        isVertical(){

            return this.direction === 'vertical' ;
        },

        isHorizontal(){

            return this.direction === 'horizontal' ;
        }
    }
};

</script>

<style lang="scss" scoped>

    .scroll-bar{
        position: absolute;
        background-color: #000000;
        border-radius: 10px;
        opacity: .6;
        transition: opacity 200ms ease;
        &.hidden{
            opacity: 0;
        }
        &.vertical{
            width:10px;
            right:5px;
            top:0;
        }
        &.horizontal{
            height: 10px;
            bottom:5px;
            left:0;
        }
    }

</style>