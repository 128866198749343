import axios from 'axios' ;
import qs from 'qs' ;
import serverConfig from "@/../config/index";
/**
 * 主诉模版列表 API
 */


let url = serverConfig.BASE_API + '/v2/complained_template/method/get.complained.template.list';

export default (data) => {

    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data)
        .then(({data}) => data)
        .then(({items}) => items.map((item) =>({ ...item })));
}

