<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Container class="header hb-layout-horizontal">
      <div class="time hb-layout-flex-1">时间:{{ payment_time }}</div>
      <el-button type="info" plain size="mini" class="button">查看</el-button>
    </Container>
    <Container class="body">
      <Container class="inner hb-layout-horizontal">
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">诊断</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light">
                {{ pay_amount }}
            </div>
          </el-col>
        </el-row>

        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">主诉</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light">
                {{ total_amount }}
            </div>
             
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">检验检查</div>
          </el-col>
          <el-col :span="14">
           <div class="grid-content bg-purple-light"></div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">支付状态</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light">
              {{ type != undefined ? typeMap[type].text : "" }}
            </div>
          </el-col>
        </el-row>
      </Container>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    payment_time: String, //看诊时间
    pay_amount: Number, //看诊时间
    total_amount: Number, //看诊时间
    type: Number, //看诊时间
  },
  data() {
    return {
      typeMap: [
        {
          type: "",
          text: "首诊",
        },
        {
          type: "",
          text: "初诊",
        },
        {
          type: "",
          text: "复诊",
        },
      ],
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.see-patient-history-list-item {
  margin: 0 6px 15px;
  box-shadow: 0 2px 7px 0 #d8d8d8;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    > .inner {
      display: flex;
      flex-direction: column;

      .el-row {
        width: 100%;
        height: auto;
        height: 40px;
        line-height: 40px;
        // border-top: 1px solid #979797;
        // border-left: 1px solid #979797;
        border: 1px solid #979797;
        border-bottom: none;
        font-size: 12px;

        &:last-child {
            border-bottom: 1px solid #979797;
        }
      }

      .bg-purple {
          text-align: center;
          border-right: 1px solid #979797;
      }

      .bg-purple-light {
          padding-left: 10px;
          line-height: 18px;
          // border-left: 1px solid #979797;
          // border-right: 1px solid #979797;
      }
    }
  }
}
</style>
