<template>
  <div class="search-left">
    <el-input
      placeholder="搜索患者姓名"
      class="input-with-select search"
      v-model="real_name"
    >
      <el-button
        slot="append"
        icon="el-icon-search"
        v-loading.fullscreen.lock="loading.status"
        @click="leftSearch()"
      ></el-button>
    </el-input>
  </div>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    loading: {},
  },
  data() {
    return {
      real_name: "",
    };
  },
  methods: {
    /**
     * 患者列表 筛选状态
     */
    leftSearch() {
      //console.log("11");
      this.$emit("leftsearch", this.keywords);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-left {
  width: 99%;
  padding: 0 10px;
  height: 60px;
  line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 3px 5px 10px 0px;
  position: relative;

  .search {
    width: 270px;
  }

  > .el-button {
    position: absolute;
    right: 20px;
    top: 10px;
  }
}
</style>
