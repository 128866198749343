import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";
/**
 * 医生获取患者列表 API
 */
let url = serverConfig.BASE_API + '/v2/patient/method/get.patient.basic.list/';

export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        })).then(({
            data
        }) => data);
        // .then(({
        //     data
        // }) => data);
        // .then(({
        //     items
        // }) => items.map(({
        //     real_name,
        //     sex,
        //     age,
        //     medical_record_no,
        //     getUserLevel,
        //     user_id,
        //     update_time
        // }) => ({
        //     real_name, //姓名 ：no112233
        //     sex,  //性别：男、女
        //     age, //年龄：20
        //     time:update_time,
        //     // lvl: get_user.get_user_level.name, //会员级别名称
        //     userinfo:getUserLevel.name,
        //     //userinfo:"12",
        //     u_No:user_id,
        //     medicalRecordNo:medical_record_no,//病历编号
        // })));
}