<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header @addsubmit="onAddSubmit"></Header>
    <Content
      v-if="tabDatas == 1"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></Content>
    <ContentLevel
      v-if="tabDatas == 2"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentLevel>
    <ContentSetting
      v-if="tabDatas == 3"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentSetting>
    <ContentUser
      v-if="tabDatas == 4"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentUser>
    <ContentBlance
      v-if="tabDatas == 5"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
      :loading="loading"
    ></ContentBlance>

  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      [
        "Header",
        "Content",
        "ContentLevel",
        "ContentSetting",
        "ContentUser",
        "ContentBlance",
      ],
      "configuration.Insurance.Center"
    ),

    ...components(["Container"]),
  },

  props: {
    listRecordsData: {},
    tabData: 0,
    loading: {
      default: false,
    },
  },

  data() {
    return {
      tabDatas: 0,
    };
  },
  watch: {
    tabData: function (val) {
      this.tabDatas = val;
      // console.log(this.tabDatas);
    },
  },
  methods: {
    isShowAll(val) {
      this.$refs.tree_List.isShowAlls(val);
    },
    onFilterText(val) {
      this.$refs.tree_List.onFilterText(val);
    },
    onAddSubmit() {
     
      this.$emit("addsubmit");
    },
    onHandleSelectItem(itemdata) {
      this.$emit("handleselectitem", itemdata);
    },
    onHandleDelDelectItem(data) {
      //console.log("1211112", data);
      this.$emit("handledelselectitem", data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
