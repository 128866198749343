<template>
  <Container class="see-patient-list hb-layout-vertical">
    <LeftSearch :loading="loading" @leftsearch="onLeftSearch"></LeftSearch>

    <LeftScreen
      v-if="leftFee == 0"
      @changestatus="onChangeStatus"
      @todayevent="onTodayEvent"
      @sevenevent="onSevenEvent"
      @thirtyevent="onThirtyEvent"
    ></LeftScreen>

    <LeftScreenFee
      v-if="leftFee == 1"
      @changestatusfee="onChangeStatusFee"
      @todayevent="onTodayEvent"
      @sevenevent="onSevenEvent"
      @thirtyevent="onThirtyEvent"
    ></LeftScreenFee>

    <div>
      <div v-if="medicalRecordList.length > 0">
        <List
          ref="list"
          class="body hb-layout-flex-1"
          itemCls=".see-patient-list-item"
          @itemselect="onItemSelect"
          v-model="selected"
          :scroll="true"
          :data="medicalRecordList"
        >
          <template v-slot:item="{ record, selected }">
            <LeftItems
              :appointment_record_no="record.appointment_record_no"
              :amedicalRecordNo="record.medicalRecordNo"
              :lvlname="record.lvlname"
              :name="record.real_name"
              :start_time="record.start_time"
              :end_time="record.end_time"
              :paymentStatus="record.paymentStatus"
              :selected="selected"
            >
            </LeftItems>
          </template>
        </List>
      </div>
      <div class="text item return-visit" v-else>
        <el-empty description="暂无数据" :image-size="100"></el-empty>
      </div>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      ["Left.Items", "Left.Search", "Left.Screen", "Left.ScreenFee"],
      "charge"
    ),
    ...components(["List", "Container"]),
  },

  props: {
    medicalRecordList: {
      type: Array,
      default: () => [],
    },
    leftFee: 0,
  },

  watch: {
    medicalRecordList(data) {
      let me = this;

      me.$nextTick(() => {
        if (data.length) {
          me.$refs.list.select(data[0]);
        }
      });

      // if(medicalRecordList.length){

      //   me.$refs.list.select(medicalRecordList[0]) ;

      // }
    },
  },

  data() {
    return {
      loading: {},
      selected: null,
      options: [
        {
          value: undefined,
          label: "全部",
        },
        {
          value: 0,
          label: "待诊",
        },
        {
          value: 1,
          label: "已诊",
        },
        {
          value: 2,
          label: "检测中",
        },
        {
          value: 3,
          label: "待打款",
        },
        {
          value: 4,
          label: "已打款",
        },
      ],
      status: undefined,
    };
  },

  methods: {
    onItemSelect(requestData) {
      this.$emit("medicalRecordSelect", requestData);
    },
    select(patient) {
      this.$refs.list.select(patient);
    },
    /**
     * 改变状态
     */
    onChangeStatus(command) {
      this.$emit("changestatus", command);
    },
    onChangeStatusFee(command) {
      this.$emit("changestatusfee", command);
    },
    onLeftSearch(command) {
      this.$emit("leftsearch", command);
    },
    onTodayEvent() {
      this.$emit("todayevent");
    },
    onSevenEvent() {
      this.$emit("sevenevent");
    },
    onThirtyEvent() {
      this.$emit("thirtyevent");
    },
  },
};
</script>

<style lang="scss" scoped>
.name {
  font-size: 14px;
  white-space: nowrap;
  color: #606266;
}
</style>
