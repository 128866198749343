<template>
<Container class="see-patient-list hb-layout-vertical">
    <el-card shadow="always">
        <el-row type="flex" >
            <el-col :span="7">
                <el-col :span="6">
                    <el-button type="primary" size="mini">日历</el-button>
                </el-col>
            </el-col>
            <el-col :span="9">
                <el-col :span="6">
                    <el-button type="primary" size="mini">周历</el-button>
                </el-col>
            </el-col>
            <el-col :span="8">
                <el-dropdown @command="changeStatus">
                    <span class="el-dropdown-link">
                        当班状态<i class="el-icon-arrow-down el-icon--right"></i>
                    </span>
                    <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item v-for="(item , index) in options" :command="item.value" :key="index">{{item.label}}</el-dropdown-item>
                    </el-dropdown-menu>
                </el-dropdown>
            </el-col>
        </el-row>
    </el-card>

    <List ref="list" class="body hb-layout-flex-1" itemCls=".see-patient-list-item" @itemselect="onItemSelect" v-model="selected" :scroll="true" :is_test="true" :data="data">
        <template v-slot:item="{
                record,
                selected
            }">
            <Doctoritem :doctorId="record.doctorId" :name="record.name" :title="record.title" :price="record.price"  :header="record.header" :selected="selected"></Doctoritem>
        </template>
    </List>
</Container>
</template>

<script>
import {
    components
} from '@/helper';

export default {

    props: {
        data: Array
    },

    data() {

        return {
            selected: null,
            options: [{
                value: undefined,
                label: '全部'
            }, {
                value: 0,
                label: '在班'
            }, {
                value: 1,
                label: '未在班'
            }],
            status: undefined,
        };

    },

    components: components([
        'List',
        'Container', {
            name: 'Doctoritem',
            code: 'appointmentCalendar.list.doctor.Item'
        }
    ]),

    methods: {
		/**
         * 改变状态
         */
        changeStatus(command) {
            this.$emit('changeStatus', command);
        },
        onItemSelect(doctorId) {

            this.$emit('doctoritemselect', doctorId);
        },

        select(doctorId) {

            this.$refs.list.select(doctorId);
        }
    }
}
</script>

<style lang="scss" scoped>
$see-patient-list-header-height: 50px;

$see-patient-list-padding: 0 10px 0 10px;

.el-row--flex {
    display: flex;
    align-items: center;
}

.see-patient-list {

    >.header {
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 16px 0 18px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5B5B5B;

        >.tody {
            padding-right: 15px;
        }
    }

    .el-dropdown-link {
        font-size: 12px;
        padding-left: 20px;
    }

}
</style>
