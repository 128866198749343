<template>
  <Container class="patient-detail-doctor">
    <el-table
      ref="table"
      :data="tableData"
      highlight-current-row
      @current-change="handleCurrentChange"
      style="width: 100%"
    >
    <el-table-column fixed type="index" width="45"> </el-table-column>
      <el-table-column prop="create_time" align="left" label="看诊时间" min-width="220">
      </el-table-column>

      <el-table-column prop="clinical_name" align="left" label="科室" min-width="160">
      </el-table-column>
      <el-table-column prop="doctor_name" align="left" label="就诊医生" min-width="220">
      </el-table-column>
      <el-table-column prop="impressions" align="left" label="诊断结果" min-width="220">
        <template slot-scope="scope">
          {{ scope.row.impressions.join("、") }}
        </template>
      </el-table-column>
      <!-- 新增加的结束时间 -->
      <el-table-column prop="create_time" align="left" label="结束时间" min-width="160">
      </el-table-column>
    </el-table>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  data() {
    return {
      currentRow: null,
      clearData:false
    };
  },
  props: {
    tableData: {
      type: Array,
      default: () => [],
    },
  },
    watch: {
    tableData: function (data) {
      
      let me = this ;

      if(data.length){

        me.clearData = false;
        me.$emit("clearrightdata",me.clearData);
        me.$nextTick(() => me.$refs.table.setCurrentRow(data[0])) ;

      }else{
       me.clearData = true;
        me.$emit("clearrightdata",me.clearData);
      }

      

    },
  },
  components: components(["Container"]),
  methods: {
    setCurrent(row) {
      this.$refs.singleTable.setCurrentRow(row);
    },
    handleCurrentChange(val) {
      this.currentRow = val;
      this.$emit('currentchange', val);
     // console.log("看诊记录点击时间信息：" + JSON.stringify(val));
    },
  },
};
</script>

<style scoped></style>
