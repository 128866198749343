<template>
<Container class="statistics">
    <div class="text item">
        <el-table v-loading="loading" :data="tableData" highlight-current-row stripe @sort-change="sortChange" height="calc(100vh - 265px)" style="width: 100%">
            <el-table-column fixed type="index" width="45"> </el-table-column>
            <el-table-column fixed prop="getStorehouse.name" label="名称" width="200" align="left"></el-table-column>

            <el-table-column prop="getStorehouse.english_name" label="英文名称" width="200" align="left"></el-table-column>

            <el-table-column label="数量/单位" min-width="90" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.unit_num }}/{{ scope.row.unitName }}</div>
                </template>
            </el-table-column>
            <el-table-column label="数量/规格单位" min-width="110" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.small_unit_num }}/{{ scope.row.getSmallUnit.name }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="getStorehouse.stock" label="入库前库存量" width="170" align="left">
                <template slot-scope="scope">
                    <div>{{ scope.row.old_stock}}/{{ scope.row.unitName }}-{{ scope.row.old_small_stock }}/{{ scope.row.smallUnitName }}</div>
                </template>
            </el-table-column>
            <el-table-column prop="getStorehouse.trade_price" label="进价" min-width="70" align="left">
            </el-table-column>
            <el-table-column prop="getStorehouse.retail_price" label="零售价" min-width="70" align="left">
            </el-table-column>
            <el-table-column prop="batch_number" label="生产批号" min-width="110" align="left">
            </el-table-column>
            <el-table-column prop="product_date" label="生产日期" min-width="100" align="left">
            </el-table-column>
            <el-table-column prop="validity_date" label="有效期" min-width="70" align="left">
            </el-table-column>
            <el-table-column prop="end_date" label="过期日期" min-width="100" align="left">
            </el-table-column>
            <el-table-column prop="create_time" label="入库时间" width="160" align="left">
            </el-table-column>
        </el-table>
    </div>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: components(["Container"]),
    props: {
        Data: {},
        loading: {
            default: false,
        },
        tableData: {
            default: () => [],
        },
    },
    filters: {
        getNumber(val) {
            return this.getNumber(val);
        },
    },
    data() {
        return {
            typeMap: [
                {
                    text: "收入",
                    type: "success",
                    symbol: "+",
                },
                {
                    text: "支出",
                    type: "danger",
                    symbol: "-",
                },
            ],

            moduleMap: {
                points: "积分",
                money: "消费",
                card: "购物卡",
            },
            stateMap: ['账户资金', '现金', '支付宝', '微信', '银行卡', '购物卡', '保险', '其它'],
            statusMap: ['未支付', '已支付'],
            visitMap: [
                {
                    text: "待确认",
                    type: "",
                },
                {
                    text: "初诊",
                    type: "success",
                },
                {
                    text: "复诊",
                    type: "primary",
                },
                {
                    text: "首诊",
                    type: "info",
                },
            ],
            diagnosisMap: ['到店', '未到店'],
            typeMap: [
                {
                    text: "待确认",
                    type: "danger",
                },
                {
                    text: "确认",
                    type: "success",
                },
                {
                    text: "取消",
                    type: "info",
                },
            ],
            stateMap: [
                {
                    text: "待到店",
                    type: "",
                },
                {
                    text: "已到店",
                    type: "success",
                },
                {
                    text: "待珍",
                    type: "primary",
                },
                {
                    text: "已珍",
                    type: "info",
                },
            ],
        };
    },
    methods: {
        sortChange({
            column,
            prop,
            order
        }) {
            let sort = {
                order_type: undefined,
                order_field: undefined,
            };

            if (column && order) {
                sort.order_type = order === "ascending" ? "asc" : "desc";
                sort.order_field = prop;
            }

            this.$emit("sort", sort);
        },
        getNumber(value) {
            const toFixedNum = Number(value).toFixed(3);
            return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
        },
    },
};
</script>

<style lang="scss" scoped>
.item {
    margin-top: 10px;
}
/deep/::-webkit-scrollbar{
   width: 5px; 
   height: 10px; 
 }
</style>
