<template>
    <Container class="patient-detail-doctor">
        <div class="details">
            <el-card>
                <div>
                    <el-table
                        :data="transferDetails"
                        stripe
                        height="calc(100vh - 130px)"
                        style="width: 100%;">

                        <el-table-column fixed prop="medicalRecordId" label="病历号" min-width="220" align="center"></el-table-column>

                        <el-table-column prop="name" label="姓名" min-width="180" align="center"></el-table-column>

                        <el-table-column prop="sex" label="性别" width="100" align="center"></el-table-column>

                        <el-table-column prop="age" label="年龄" width="130" align="center">
                            <template slot-scope="scope">
                                <div>{{scope.row.age}}岁</div>
                            </template>
                        </el-table-column>

                        <el-table-column prop="symptom" label="症状" min-width="160" align="center"></el-table-column>

                        <el-table-column prop="time" label="转院时间" min-width="150" align="center"></el-table-column>
                    </el-table>
                </div>

            </el-card>
        </div>
    </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
 
  components: components([
    'Container'
  ]),

    data(){
        return{
            transferDetails: [
                {
                    medicalRecordId: "SM-1567256761286",     // 病历号
                    name: "简单",                // 姓名
                    sex: "女",            // 性别
                    age: "20",               // 年龄
                    symptom: "胃出血",                   // 症状
                    time: "2021-04-31",            // 转院时间 
                },{
                    medicalRecordId: "SM-1567256761286",     // 病历号
                    name: "捷克",                // 姓名
                    sex: "男",            // 性别
                    age: "20",               // 年龄
                    symptom: "胃出血",                   // 症状
                    time: "2021-04-31",            // 转院时间 
                }
            ]
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
.btn{
    display: flex;
    justify-content: flex-end;

    .edit-buttom {
        margin-top: 20px;
    }
}
</style>
