/**
 * 获取一个病历信息 API
 */

import axios from 'axios' ;
import qs from 'qs' ;
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/patient/method/get.medical.record.item';

export default (data) => {

    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data)
        .then(({data}) => data);
}

