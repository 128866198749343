<template>
  <el-card shadow="always">
    <el-row type="flex" class="display">
      <el-col :span="24">
        <el-col :span="5">
          <el-button
            type="text"
            v-if="allDoctors == 0"
            class="nameChange"
            @click="allDoctor"
            >全部医生</el-button
          >
          <el-button
            type="text"
            v-if="allDoctors != 0"
            class="name"
            @click="allDoctor"
            >全部医生</el-button
          >
        </el-col>
        <!-- <el-col :span="5">
          <el-button type="text" class="name" @click="thirtyEvent">15日</el-button>
        </el-col> -->
      </el-col>
      <el-col :span="6">
        <!-- <el-dropdown @command="changeStatus">
          <span class="el-dropdown-link">
            日历<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-calendar v-model="dataValue">
              <!-- 这里使用的是 2.5 slot 语法，对于新项目请使用 2.6 slot 语法-->
        <!-- <template slot="dateCell" slot-scope="{ date, data }">
                <p
                  :class="data.isSelected ? 'is-selected' : ''"
                  @click="handleEventClick(data.day)"
                >
                  {{ data.day.split("-").slice(1).join("-") }}
                  {{ data.isSelected ? "✔️" : "" }}
                </p>
              </template>
            </el-calendar> -->
        <!-- </el-dropdown-menu>
        </el-dropdown> -->
        <el-dropdown @command="changeStatus">
          <div class="el-dropdown-link">
            状态<i class="el-icon-arrow-down el-icon--right"></i>
          </div>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in options"
              :command="item.value"
              :key="index"
              >{{ item.label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    loading: {},
    calendarSetting: 0,
    allDoctors: 0,
  },
  watch: {
    allDoctors: function (val) {
     // console.log("allDoctors", val);
    },
  },
  data() {
    return {
      data: {
        day: "yyyy-MM-dd",
      },
      real_name: "",
      options: [
        {
          value: 0,
          label: "全部",
        },
        {
          value: 1,
          label: "待确认",
        },
        {
          value: 2,
          label: "已确认",
        },
        {
          value: 3,
          label: "取消预约",
        },
        {
          value: 4,
          label: "客户爽约",
        },
        {
          value: 5,
          label: "已到店",
        },
        {
          value: 6,
          label: "已服务",
        },
      ],
      dataValue: "",
    };
  },
  methods: {
    /**
     * 患者列表 筛选状态
     */
    changeStatus(e) {
      this.$emit("changestatus", e);
    },
    allDoctor() {
      this.$emit("alldoctor");
    },
  },
};
</script>

<style lang="scss" scoped>
.display{
  display: flex;
  align-items: center;
}
.name {
  font-size: 13px;
  color: #606266;
  padding: 3px 5px;
}

.nameChange {
  font-size: 13px;
  padding: 20px 5px;
  border-bottom: 3px solid #0eb353;
  color: #0eb353;
  border-radius: 0px;
  font-weight: bold;
}

/deep/.el-card__body {
  padding: 0 10px;
}
.el-calendar {
  width: 500px;
}
.el-calendar /deep/.el-calendar-day {
  height: 30px !important;
}
.el-dropdown-link{
  padding: 20px 0;
}
</style>
