import { render, staticRenderFns } from "./ContentSupplier.vue?vue&type=template&id=2b82c3d4&scoped=true&"
import script from "./ContentSupplier.vue?vue&type=script&lang=js&"
export * from "./ContentSupplier.vue?vue&type=script&lang=js&"
import style0 from "./ContentSupplier.vue?vue&type=style&index=0&id=2b82c3d4&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2b82c3d4",
  null
  
)

export default component.exports