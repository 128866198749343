 <template v-slot:doctor-search>
 <Container class="appoientment-main-operation hb-layout-horizontal">
    <div class="search-left">
      <el-input
        placeholder="请输入医生"
        class="input-with-select search"
        v-model="keywords"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          v-loading.fullscreen.lock="loading.status"
          @click="leftSearch()"
        ></el-button>
      </el-input>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    loading: {},
  },
  data() {
    return {
      keywords: "",
    };
  },
   methods: {
    /**
     * 患者列表 筛选状态
     */
    leftSearch() {
      this.$emit("leftsearch",this.keywords);
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
		width: 99%;
		padding: 0 10px;
		height: 60px;
		line-height: 60px;
		box-shadow: 0 2px 7px 0 #d8d8d8;
		margin: 3px 5px 10px 0px;
		position: relative;

		.search{
			width: 270px;
		}
		
		>.el-button{
			position: absolute;
			right: 20px;
			top: 10px;
		}
	}

$see-patient-list-item-height: 80px;

.see-patient-list-item {
  height: $see-patient-list-item-height;
  align-items: center;

  > .header > img {
    width: 30px;
    height: 30px;
  }

  &.selected {
    box-shadow: 0 2px 7px 0 #d8d8d8;
    margin: 2px 0;
    background: #92c2f957;
  }
  &:hover {
    background: #92c2f921;
    color: #2063b695;
  }

  border-bottom: 1px solid #e1e1e1;

  > .logo {
    width: 46px;
    height: 46px;
    margin-right: 10px;

    > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  font-size: 14px;

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;
  }

  > .grade {
    padding: 3px 12px;
    background: #f1be5e;
    border: 1px solid #979797;
    margin-right: 15px;
  }

  .time {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0b7dff;
    line-height: 15px;
    display: flex;
    justify-content: center;
  }

  > .time,
  .state {
    display: flex;
    justify-content: center;
  }
}
</style>
