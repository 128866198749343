<template>
  <el-card
    class="box-card"
    shadow="hover"
    :class="{
      'see-patient-list-item': true,
      selected,
    }"
    :body-style="{ padding: '0 10px' }"
  >
    <el-row style="width: 100%" type="flex" justify="center" align="middle">
      <el-col :span="10">
        <div style="display: flex; align-items: center">
          <div class="name">{{ recordData.real_name }}</div>
        </div>
      </el-col>
      <el-col :span="6">
        <div style="display: flex; align-items: left">
          <el-tag size="small" type="warning">{{ recordData.getUserLevel.name}}</el-tag>
        </div>
      </el-col>
      <el-col :span="4">
        <div class="name state">{{ sexMap[recordData.sex] }}</div>
      </el-col>

      <el-col :span="4">
        <div class="hb-layout-flex-1 state">{{ recordData.age }}岁</div>
      </el-col>

      <!-- 时间为两种显示状态 -->
      <!-- <el-col :span="6">
        <div class="hb-layout-flex-1 state">{{recordData.update_time}}</div>
      </el-col> -->
    </el-row>
  </el-card>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    recordData:{},
    selected: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sexMap: ['保密', '男', '女'],
      lvlMap: ['', '普通会员', '银卡', '金卡'],
    };
  },
};
</script>

<style lang="scss" scoped>
$see-patient-list-item-height: 80px;

.see-patient-list-item {
  height: $see-patient-list-item-height;
  line-height: $see-patient-list-item-height;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  font-size: 12px;
  cursor: pointer;

  &.selected {
    box-shadow: 0 2px 7px 0 #d8d8d8;
    margin: 2px 0;
    background: #92c2f957;
  }
  &:hover {
    background: #92c2f921;
    color: #2063b695;
  }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 4px;
  }

  > .grade {
    padding: 3px 12px;
    background: #f1be5e;
    border: 1px solid #979797;
    margin-right: 15px;
  }

  .time {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0b7dff;
    line-height: 15px;
    display: flex;
    justify-content: center;
  }

  > .time,
  .state {
    display: flex;
    justify-content: center;
  }
}
</style>
