<template>
    <el-drawer
        :title="referral_title"
        :visible.sync="drawer"
        :direction="direction"
        :size="size"
        :with-header="with_header"
    >
        <template>
            <div style="height: 100vh">
                <div class="overflowAuto">
                    <div style="page-break-after: always" ref="print" id="print">
                        <div class="header-top">
                            <div class="header-logo">
                                <img src="../../../../public/image/logopg.png"/>
                                <div class="header-title">我们将用卓越的医疗服务与照护切实改善您的生活质量</div>
                            </div>
                        </div>
                        <div class="print-order">
                            <el-row style="border: none;margin: 10px 0 30px;font-size: 22px;font-weight: bold;">
                                <el-col :span="24">转诊信 Medical Referral Form</el-col>
                            </el-row>

                            <!-- 患者基础信息 -->
                            <el-row style="border: none; margin: 10px 0 5px">
                                <el-col :span="11">姓名 Patient's name：{{ medical_record.real_name }}</el-col>
                                <el-col :span="9">性别 Gender： {{ sexMap[medical_record.sex] }}</el-col>
                                <el-col :span="4">年龄 Age：{{ medical_record.age }}</el-col>
                            </el-row>

                            <!-- 患者档案信息 -->
                            <el-row style="border: none; margin: 20px 0">
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">诊断 Diagnosis:</div>
                                        <el-input v-model="diagnosis" class="input" @input="diagnosisFormat(diagnosis)"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="border: none; margin: 20px 0">
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">既往病史及手术史 PMH & Surgical History:</div>
                                        <!-- <el-input v-model="medicalHistory" class="input" :value="handlePMH"></el-input> -->
                                        <el-input v-model="history" class="input" :value="handlePMH"></el-input>
                                    </div>
                                </el-col>
                            </el-row>
                            <el-row style="border: none; margin: 20px 0">
                                <el-col :span="24">
                                    <div class="item">
                                        <div class="name">建议治疗时间 Recommended Frequency of Treatment:</div>
                                        <!-- <el-input v-model="time" class="input"></el-input> -->
                                        <el-input v-model="recommend_frequency" class="input"></el-input>
                                    </div>
                                </el-col>
                            </el-row>

                            <!-- 建议治疗方案 -->
                            <div class="title">建议治疗方案 Recommended Treatment:</div>
                            <el-checkbox-group v-model="checkList">
                                <el-row>
                                    <el-col :span="12">
                                        <div class="grid-content purple purple-left">
                                            <el-checkbox label="1" class="checkbox">物理治疗 Physical Therapy</el-checkbox>
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="grid-content purple-right">
                                            <el-checkbox label="2" class="checkbox">中医治疗 TCM Treatment</el-checkbox>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <div class="grid-content purple purple-left">
                                            <el-checkbox label="3" class="checkbox" style="width: 100%">
                                                <div>治疗 Medical Treatment</div>
                                                <el-input class="input" type="textarea" autosize v-model="medicalTreatment" style="margin-top: 10px;"></el-input>
                                            </el-checkbox>
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="grid-content purple-right">
                                            <el-checkbox label="4" class="checkbox" style="width: 100%">
                                                <div>专科医生 Specialist Doctor</div>
                                                <el-input v-model="doctor" class="input"
                                                          style="margin-top: 10px;"></el-input>
                                            </el-checkbox>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="12">
                                        <div class="grid-content purple purple-left">
                                            <el-checkbox label="5" class="checkbox" style="width: 100%">
                                                <div>影像检查 Imaging Study</div>
                                                <el-input class="input" type="textarea" autosize v-model="imaginingStudy" style="margin-top: 10px;"></el-input>
                                            </el-checkbox>
                                        </div>
                                    </el-col>
                                    <el-col :span="12">
                                        <div class="grid-content purple-right">
                                            <el-checkbox label="6" class="checkbox" style="width: 100%">
                                                <div>化验 Pathology Services</div>
                                                <el-input class="input" type="textarea" autosize v-model="pathologyServices" style="margin-top: 10px;"></el-input>
                                            </el-checkbox>
                                        </div>
                                    </el-col>
                                </el-row>
                                <el-row>
                                    <el-col :span="24">
                                        <div class="grid-content purple">
                                            <el-checkbox label="7" class="checkbox" style="display: block;width: calc(100% - 83px);">
                                                <span>其他 Others:</span>
                                                <el-input class="input" type="textarea" autosize v-model="otherInput" style="margin-top: 2px;"></el-input>
                                            </el-checkbox>
                                        </div>
                                    </el-col>
                                </el-row>
                            </el-checkbox-group>
                        </div>

                        <div class="signature">
                            <div>Date: <el-date-picker v-model="dateTime" type="datetime" placeholder="选择日期时间"></el-date-picker> </div>
                            <div>Doctor's Signature:</div>
                        </div>
                        <div class="header-footer">
                            <div class="footer-logo">
                                <img src="../../../../public/image/telephone.png"/>
                                <div class="footer-title">0512-6580-8308</div>
                            </div>
                            <div class="footer-right">
                                <div class="footer-text">苏州工业园区旺墩路 新罗酒店B栋一楼</div>
                                <div class="footer-text">Opening Hours 营业时间 Mon-Sun周一到周日，8:30-20:30</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="footer">
                    <!-- <el-button type="success" @click="preserveButton">保存</el-button> -->
                    <!-- <el-button type="primary" @click="$print($refs.print)">打印</el-button> -->
                    <el-button type="primary" @click="turnhospital()">打印</el-button>
                    <el-button @click="handleClose()">取消</el-button>
                </div>
            </div>
        </template>
    </el-drawer>
</template>

<script>
import {components} from "@/helper";
import moment from "moment";
export default {
    components: {
        ...components(["List", "Container"]),
    },
    props: {
        with_header: {
            type: Boolean,
            required: false,
            default: false,
        },
        direction: {
            type: String,
            default: 'rtl',
        },
        referral_title: {
            type: String,
            default: '转诊信',
        },
        vital: {
            type: Object,
            require: true,
            default: () => {
                // allergies: '无',         // 过敏史
                // past_history: '无',      // 既往史
                // family_history: '无',    // 家族史
            }
        },
        impressions: {
            type: Array,
            default: () => [],
        },
        medical_record: {
            type: Object,
            required: true,
            default: {
                real_name: 'Empty',
                sex: 0,
                age: 0,
                medical_record_no: '-',
            },
        },
        size: {
            type: String,
            default: "42%",
        },
    },
    data() {
        return {
            sexMap: ['保密', '男', '女'],

            drawer: false,
            referralPreview: false,

            diagnosis: this.impressions.toString(),          // 诊断输入值
            history: '',     // 病史或手术史
            recommend_frequency: '',               // 建议治疗时间
            medicalTreatment: '',   // 治疗
            doctor: '',             // 专科医生
            imaginingStudy: '',     // 影像检查
            pathologyServices: '',  // 化验
            otherInput: '',         // 其他描述

            checkList: [],

            currentDateTime: moment(new Date).format('YYYY-MM-DD HH:mm:ss'), // 当前时间
            dateTime: "",
        };
    },

    methods: {
        changeDrawer(v) {
            this.drawer = v;
        },
        handleClose() {
            this.drawer = false;
        },

        /**
         *
         * @returns {string}
         */
        handlePMH() {
            //console.log('handlePMH => ', this.item);
            let vital = this.vital;
            let PMH = '无';

            if (vital.allergies !== '') {
                PMH = vital.allergies
            }

            if (vital.past_history !== '') {
                PMH = PMH + vital.past_history
            }

            if (vital.family_history !== '') {
                PMH = PMH + vital.family_history
            }

            return PMH;
            // allergies: '无',         // 过敏史
            // past_history: '无',      // 既往史
            // family_history: '无',    // 家族史

        },

        diagnosisFormat(value) {
            // console.log('diagnosisFormat => ', value)
            if (value instanceof Array) {
                this.diagnosis = value.join(',');
                return;
            }

            if (value === '') {
                this.diagnosis = '无'
                return;
            }

            this.diagnosis = value;
        },

        preserveButton() {
           // console.log()
        },

        turnhospital(){
            let me = this;
            let data = {
                patient_name:me.medical_record.real_name ,
                patient_gender:me.medical_record.sex,
                patient_age: me.medical_record.age,
                medical_record_no: me.medical_record.medical_record_no,
                appointment_record_no:me.medical_record.appointment_record_no ,
                diagnosis:me.diagnosis,
                history: me.history,
                recommend_frequency: me.recommend_frequency,
                recommend_treatment:{
                    medicalTreatment:me.medicalTreatment,
                    doctor: me.doctor,
                    imaginingStudy: me.imaginingStudy,
                    pathologyServices: me.pathologyServices,
                    otherInput: me.otherInput,
                },
                dateTime: me.dateTime, 
            };
            me.$emit("turnhospital", data);
            me.referralPreview = true;
        }    
    },
};
</script>

<style lang="scss" scoped>
.el-drawer__body {
    overflow: auto !important;
}

.item-img {
    position: relative;
}

.overflowAuto {
    overflow-y: auto;
    height: 100%;
    padding: 20px 20px 30px;
}

.overflowAuto::-webkit-scrollbar {
    height: 6px;
    width: 6px;
}

.overflowAuto::-webkit-scrollbar-thumb {
    background: rgb(224, 214, 235);
}

.title {
    height: 40px;
    width: 100%;
    line-height: 40px;
    // font-weight: bold;
}

.grid-content {
    font-size: 12px;
}


.header-top {
    display: block;
    width: 100%;
    margin-bottom: 20px;
    margin-top: 20px;

    .header-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 5px solid #97c2bb;
        padding-bottom: 20px;

        > img {
            width: 270px;
            height: 65px;
        }

        .header-title {
            color: #97c2bb;
            width: 290px;
            flex-wrap: wrap;
            font-size: 20px;
            line-height: 32px;
            text-align: end;
        }
    }
}

.signature {
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-top: 20px;

    > div {
        width: 50%;
    }
}

.header-footer {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-top: 3px solid #97c2bb;
    margin-top: 20px;
    padding-top: 20px;

    .footer-logo {
        display: flex;
        align-items: center;

        > img {
            width: 40px;
            height: 40px;
            margin-right: 10px;
        }

        .footer-title {
            color: #97c2bb;
            font-size: 22px;
            font-weight: bold;
        }
    }

    .footer-right {
        color: #97c2bb;
        font-size: 16px;

        .footer-text {
            line-height: 24px;
        }
    }
}

.footer {
    position: fixed;
    bottom: 20px;
    z-index: 99;
    right: 30px;
}

@media print {
    @page {
        size: auto;
        margin: 2mm 9mm;
    }

    body {
        height: auto;
    }

    #print {
        // height: 291mm; //分页  根据实际
        height: 1090px; //分页  根据实际
        position: relative;

        &::before {
            position: absolute;
            content: "乐享健康新生活";
            color: #54958a;
            top: 25%;
            left: 25%;
            font-size: 48px;
            opacity: 0.1;
        }

        &::after {
            position: absolute;
            content: "乐享健康新生活";
            color: #54958a;
            bottom: 35%;
            left: 25%;
            font-size: 48px;
            opacity: 0.1;
        }
    }
}

#print {
    background: #fff;
    box-sizing: border-box;
}

.print-order {
    min-height: 780px;
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 12px;
}

.el-row {
    border-top: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;

    &:last-child {
        margin-bottom: 0;
        border-bottom: 1px solid #e1e1e1;
    }
}

.purple {
    padding: 15px;
    border-left: 1px solid #e1e1e1;
}
.purple-left{
    border-right: 1px solid #e1e1e1;
}
.purple-right{
    padding: 15px;
}

.item {
    display: flex;
    align-items: center;

    .name {
        white-space: nowrap;
        margin-right: 10px;
    }
}

.input ::v-deep {
    .el-input__inner,
    .el-textarea__inner {
        width: 100% !important;
        border: 0;
        border-radius: 0;
        font-size: 13px;
        border-bottom: 1px solid #c5c5c5 !important;
        background: transparent;
        height: 20px;
        line-height: 20px !important;
        padding: 0 10px;
    }
}
.checkbox /deep/.el-checkbox__label{
    width: 100%;
}

.el-checkbox,
.el-checkbox__input {
    display: inline-flex;
}

.checkbox ::v-deep {
    .el-checkbox__label {
        font-size: 12px;
        line-height: 15px;
    }

    .el-checkbox__input.is-checked .el-checkbox__inner::after {
        border-color: #333;
    }
}
</style>
