<template>
    <Container>
        <div class="block">
            <el-timeline>
                <el-timeline-item v-for="(activity, index) in visitPathDatas" :key="index" :timestamp="activity.create_time">
                    {{activity.getStatus.name}}
                    <div class="project">
                        <el-tag>{{activity.remark}}</el-tag>
                    </div>
                     {{activity.getDoctor.nickname}}执行于
                </el-timeline-item>
            </el-timeline>
        </div>
    </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props: {
     visitPathDatas:{}
  },
  
  data(){
    return {
        
    }
  }
}
</script>

<style  lang="scss"  scoped>
    .project{
        margin: 6px 3px 10px;
    }

    
</style>
