import axios from 'axios' ;

let url = 'https://api.demo.hobooa.com/mock/91/api/v2/patient/method/get.medical.basic.record/';

export default ({
    id
}) =>{

    return axios.get(url , {
        params:{
            medical_no:id
        }
    }).then(({
        data
    }) => data)
    .then(({
        data
    }) => data)
    .then(({
        medical_id:id,
        ...values
    }) => ({
        id,
        ...values
    }));
}
