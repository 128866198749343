<template>
	<Container class="appointment-main-operation hb-layout-horizontal">
		<div style="width: 100%">
			<Container class="see-patient-history-list-item hb-layout-vertical">
				<Container class="body">
					<Container class="inner hb-layout-horizontal">
						<el-row type="flex" align="middle">
							<el-col :span="6">
								<div class="grid-content bg-purple">用户名</div>
							</el-col>
							<el-col :span="18">
								<div class="grid-content bg-purple-light">
									<el-input v-model="selectData.nickname" placeholder="请输入登录用户名"/>
								</div>
							</el-col>
						</el-row>
						<el-row type="flex" align="middle">
							<el-col :span="6">
								<div class="grid-content bg-purple">登录密码</div>
							</el-col>
							<el-col :span="18">
								<div class="grid-content bg-purple-light">
									<el-input type="password" v-model="selectData.password" placeholder="请输入登录密码"/>
								</div>
							</el-col>
						</el-row>
						<el-row type="flex" align="middle">
							<el-col :span="6">
								<div class="grid-content bg-purple">确认密码</div>
							</el-col>
							<el-col :span="18">
								<div class="grid-content bg-purple-light">
									<el-input type="password" v-model="selectData.confirm_password" placeholder="请输入确认密码"/>
								</div>
							</el-col>
						</el-row>
					</Container>
					<div class="btn">
						<el-button type="success" class="edit-button" @click="onSubmitItemData">提交</el-button>
					</div>
				</Container>
			</Container>
		</div>
	</Container>
</template>

<script>
import {components} from "@/helper";

export default {
	components: {
		...components(["Container"]),
	},
	props: {},
	data() {
		return {
			selectData: {
				nickname: '',
				password: '',
				confirm_password: '',
			},
		};
	},
	methods: {
		/**
		 * 提交数据
		 */
		onSubmitItemData() {
			let me = this;
			
			if (me.selectData.nickname === "" || me.selectData.nickname === null) {
				me.$alert("请输入登录用户名");
				return;
			}
			
			if (me.selectData.password === "" || me.selectData.password === null) {
				me.$alert("请输入登录密码");
				return;
			}
			
			if (me.selectData.password !== me.selectData.confirm_password) {
				me.$alert("两次输入的密码不一致");
				return;
			}
			
			let data = {
				nickname: me.selectData.nickname,
				password: me.selectData.password,
			};
			this.$emit("submititemdata", data);
		},
	},
};
</script>

<style lang="scss" scoped>
.appointment-main-operation {
	width: 99%;
	height: calc(100vh - 210px);
	box-shadow: 0 2px 7px 0 #d8d8d8;
	margin: 2px 0px 10px 2px;
}

.see-patient-history-list-item {
	width: 100%;
	
	> .header {
		padding: 0 10px;
		height: 50px;
		line-height: 50px;
		background-color: #ededed;
		border-bottom: 1px solid #e1e1e1;
		
		> .time {
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #5b5b5b;
		}
		
		> .button {
			height: 30px;
			margin-top: 12px;
		}
	}
	
	> .body {
		padding: 10px;
		
		.inners {
			display: flex;
			flex-direction: column;
			
			.row-select {
				margin-bottom: 7px;
			}
		}
		
		> .inner {
			display: flex;
			flex-direction: column;
			border: 1px solid #979797;
			
			.el-row {
				width: 100%;
				height: auto;
				line-height: 38px;
				border-bottom: 1px solid #979797;
				font-size: 12px;
				
				&:last-child {
					border-bottom: none;
				}
			}
			
			.bg-purple {
				text-align: center;
				background: #ebebeb;
				border-right: 1px solid #979797;
			}
			
			.grid-textarea {
				height: 138px;
				line-height: 138px;
			}
			
			.bg-purple-light-select {
				padding: 0;
				font-size: 12px;
			}
		}
		
		.title {
			line-height: 34px;
			margin-top: 10px;
			font-size: 16px;
		}
	}
	
	.btn {
		display: flex;
		justify-content: flex-end;
		
		.edit-button {
			margin-top: 30px;
		}
	}
}
</style>
