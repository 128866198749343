<template>
    <Container :scroll="scroll">
        <ListLayout ref="layout" :layout="layout" :data="data" :selections="selections" @click.native="onItemClick">
            <template v-slot:item="{
                record,
                selected
            }">
                <slot name="item" :record="record" :selected="selected"></slot>
            </template>
        </ListLayout>
    </Container>
</template>

<script>

import {
    layouts,
    components
} from '@/helper' ;

import {
    include
} from 'zbee-sdk/package/hb-admin-system-template' ;

const getParentNode = include('browser.selector.parent') ;

export default {
    model: {
        prop: 'selections',
        event: 'itemselect'
    },
    props:{
        scroll:Boolean,
        layout:String,
        data:Array,
        is_test: Boolean,
        selections:{},
        itemSelectMode:{
            type:String,
            validator:selectMode => ['single' , 'multi'].includes(selectMode),
            default:'single'
        },
        itemCls:{
            type:String,
            default:'.item'
        }
    },
    components:{
        ...components([
            'Container'
        ]),
        ...layouts([
            'List'
        ])
    },

    watch:{
        data(){

            this.initItemEls() ;
        }
    },

    mounted(){

        this.initItemEls() ;

    },

    methods:{

        initItemEls(){

            let me = this ;

            me.$nextTick(() => {

                let {
                    itemCls
                } = me;

                me.itemEls = Array.from(me.$el.querySelectorAll(itemCls)) ;

            }) ;
        },

        select(record){

            let me = this,
            {
                itemSelectMode
            } = me,{
                selectedRecords
            } = me.$refs.layout ;

            if(!selectedRecords.includes(record)){

                switch(itemSelectMode){

                    case 'single':

                        me.$emit('itemselect' , record) ;

                        break;

                    case 'multi':

                        {
                            let {
                                selections
                            } = me ;

                            me.$emit('itemselect' , [
                                ...Array.from(selections),
                                record
                            ]) ;
                        }
                }
            }
        },

        onItemClick({
            target
        }){

            let me = this,
            {
                data,
                itemEls,
                itemCls
            } = me,
            itemEl = getParentNode(target , itemCls) ;

            if(itemEl){

                let record = data[itemEls.indexOf(itemEl)] ;

                me.$emit('itemclick' , record) ;

                me.select(record) ;
            }

        }
    }
}
</script>
