<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container class="patient-detail-doctor">
      <Container class="header hb-layout-horizontal">
        <el-row style="width: 100%; margin-bottom: 5px">
          <el-col :span="2">
            <div class="hb-layout-flex-1">创建人: {{ paymentParam.create_name }}</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 title-top">支付方式:</div>
          </el-col>
          <el-col :span="4">
            <div class="hb-layout-flex-1">
              <el-select
                v-model="paymentParams.to_payment"
                filterable
                style="width: 100%"
                placeholder="支付方式"
              >
                <el-option
                  v-for="item in paymentDatas"
                  :key="item.code"
                  :label="item.name"
                  :value="item.code"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 title-top">是否结算:</div>
          </el-col>
          <el-col :span="4">
            <div class="hb-layout-flex-1">
              <el-select
                v-model="paymentParam.status"
                filterable
                style="width: 100%"
                placeholder="结算"
              >
                <el-option
                  v-for="item in payList"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 title-top">备注:</div>
          </el-col>
          <el-col :span="8">
            <div class="hb-layout-flex-1">
              <el-input v-model="paymentParam.cause" placeholder="请输入备注"></el-input>
            </div>
          </el-col>
        </el-row>
      </Container>
      <Container class="see-detail-test-inspect">
        <el-row style="width: 100%; height: 50px; line-height: 35px; margin-bottom: 5px">
          <el-col :span="4">
            <div class="hb-layout-flex-1 cloumn-head">项目名称</div>
          </el-col>
          <el-col :span="3">
            <div class="hb-layout-flex-1 cloumn-head">单位</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">市场价格</div>
          </el-col>
          <el-col :span="2">
            <div class="hb-layout-flex-1 cloumn-head">会员价格</div>
          </el-col>
          <el-col :span="5">
            <div class="hb-layout-flex-1 cloumn-head">数量</div>
          </el-col>
          <el-col :span="4">
            <div class="hb-layout-flex-1 cloumn-head">总价</div>
          </el-col>
          <el-col :span="3">
            <div class="hb-layout-flex-1 cloumn-head"></div>
          </el-col>
        </el-row>
      </Container>
      <Container class="see-detail-test-inspect">
        <template v-for="({ discount }, index) in rows">
          <el-row style="width: 100%; margin-bottom: 5px" :key="index">
            <el-col :span="4">
              <div class="hb-layout-flex-1 cloumn-head">
                {{ rows[index].name }}
              </div>
            </el-col>
            <el-col :span="3">
              <div class="hb-layout-flex-1 cloumn-head">
                {{ rows[index].unit }}
              </div>
            </el-col>

            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                {{ rows[index].price }}
              </div>
            </el-col>
            <el-col :span="2">
              <div class="hb-layout-flex-1 cloumn-head">
                {{ parseFloat(rows[index].use_price).toFixed(2) }}
              </div>
            </el-col>
            <el-col :span="5">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input-number
                  v-model="rows[index].num"
                  @change="(value) => handleNumChange(index, value)"
                  :min="1"
                  :max="10"
                  label="项目数量"
                ></el-input-number>
                <!-- <el-input
                  class="grid-left"
                  :value="num"
                  @input="(value) => onNumInput(index, value)"
                  placeholder="数量"
                ></el-input> -->
              </div>
            </el-col>
            <el-col :span="4">
              <div class="hb-layout-flex-1 cloumn-head">
                <el-input
                  class="grid-left"
                  :value="discount"
                  @input="(value) => onPriceInput(index, value)"
                  placeholder="进价"
                ></el-input>
              </div>
            </el-col>
            <el-col
              @click.native="onDeleteButtonClick(index)"
              :span="2"
              style="height: 57px; color: #ff0000"
            >
              <div>
                <el-button type="primary" style="margin-top: 7px" icon="el-icon-delete"
                  >删除</el-button
                >
              </div>
            </el-col>
          </el-row>
        </template>
      </Container>
      <Container class="hb-layout-horizontal">
        <el-row style="width: 100%; margin-bottom: 5px">
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-select v-model="categoryValue" filterable placeholder="请选择">
                <el-option
                  v-for="item in treatmenttemplateDatas"
                  :key="item.treatment_template_id"
                  :label="item.name"
                  :value="item.treatment_template_id"
                >
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-select
                v-model="selectChiledValue"
                filterable
                placeholder="请选择"
                @change="changeItem"
              >
                <el-option
                  v-for="(item, index) in treatmenttemplateChildrenDatas"
                  :key="item.treatment_template_id"
                  :label="item.name"
                  :value="index"
                >
                  <span style="float: left">{{ item.name }}</span>
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-input
                v-model="paymentParams.use_discount"
                placeholder="总金额"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="4">
            <div class="cloumn-footer">
              <el-input
                v-model="paymentParams.total_amount"
                placeholder="实付金额"
              ></el-input>
            </div>
          </el-col>
          <el-col :span="8">
            <div class="cloumn-footer">
              <el-button type="info" class="edit-buttom">取消</el-button>
              <!-- <el-button type="success" class="edit-buttom" @click="saveStorehouseStockin"
                >保存</el-button
              > -->
              <el-button type="success" class="edit-buttom" @click="savePaymentItem"
                >结算</el-button
              >
            </div>
          </el-col>
        </el-row>
      </Container>
    </Container>
  </el-drawer>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    paymentDatas: {},
    attributeDatas: {},
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    paymentParam: {},
    warhousingData: {
      type: Array,
      default: () => [],
    },
    categoryRecords: {},
    recordsstockin: {},
    storehouseSelectDatas: {},
    treatmenttemplateDatas: {},
    treatmenttemplateChildrenDatas: {},
  },
  watch: {
    //change药品分类->get药品类别
    categoryValue: function (val) {
      this.$emit("gettreatmenttemplatechildren", val);
    },
    //监控gpharmacyrecords是否记录变化 add->footer->select(val)->storehouse
    // recordsstockin: function (val) {
    treatmenttemplateChildrenDatas: function (val) {
      this.childSelectList = val;

    },
    rows: function (val) {
      let amount = 0;
      let discount = 0;
      let valArr = [];
      for (var i = 0; i < val.length; i++) {
        val[i].discount = parseFloat(
          this.NumberMul(val[i].use_price, val[i].num)
        ).toFixed(2);
        val[i].amount = parseFloat(val[i].price).toFixed(2);
        //this.now_amount += now_priceArray[i];
        amount = this.NumberAdd(amount, val[i].amount);
        discount = this.NumberAdd(discount, val[i].discount);
      }
      this.paymentParams.use_discount = parseFloat(discount).toFixed(2);
      this.paymentParams.pay_amount = parseFloat(amount).toFixed(2);
    },
    deep: true,
  },
  data() {
    return {
      drawer: false,
      category: "",
      payList: [
        {
          value: 0,
          label: "未结算",
        },
        {
          value: 1,
          label: "已结算",
        },
      ],
      selectChiledValue: "",
      linkman: "",

      rows: [],
      categoryValue: "",
      selectList: {},
      childSelectList: {}, ////add->footer->select(val)->storehouse
      selectListItem: {},
      pay_amount: 0,
      total_amount: "",
      numss: 0,
      paymentParams: {
        to_payment: 0,
        user_id:0,
        create_id: 41,
        payment_status: 0, //0=未付，1=已付
        trade_status: 0, //交易状态 0:待处理，1:已处理，2:欠账
        cause: "",
        medical_record_no: "",
        use_level: 0, //会员抵扣
        use_discount: 0, //折后价格
        total_amount: "", //实际支付价格
        use_justment: 0, //赠送金额
        pay_amount: 0, //应付金额
        treatments: [],
      },
      userInfo:""
    };
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    this.paymentParams.create_id=this.userInfo.client_id
  },
  methods: {
    changeItem(val) {
      if (this.rows == undefined) {
        this.rows = new Array();
      }
      this.selectItem(val);

      let obj = {};
      obj.treatment_template_id = this.selectListItem.treatment_template_id;
      obj.name = this.selectListItem.name;
      obj.unit = this.selectListItem.unit;
      obj.num = 1;
      obj.discount = parseFloat(
        this.NumberMul(this.selectListItem.price, this.paymentParam.user_level)
      ).toFixed(2);
      obj.use_price = this.NumberMul(
        this.selectListItem.price,
        this.paymentParam.user_level
      );
      obj.amount = parseFloat(this.selectListItem.price).toFixed(2);
      obj.price = this.selectListItem.price;
      this.rows.push(obj);
    },
    //footer->child->select选择后的item
    selectItem(val) {
      this.childSelectList.findIndex((value, index, arr) => {
        if (index === val) {
          this.selectListItem = value;
        }
        //return value.time == val;
      });
    },
    //结算方能发
    savePaymentItem() {
      let me=this;
      if (
        me.paymentParams.total_amount == "" ||
        me.paymentParams.total_amount == null
      ) {
        me.$alert("请输入实际支付金额");

        return;
      }
      me.paymentParams.treatments = me.rows;
      me.paymentParams.use_level = me.NumberSub(
        me.paymentParams.pay_amount,
        me.paymentParams.use_discount
      ); //折扣优惠金额
      me.paymentParams.use_justment = me.NumberSub(
        me.paymentParams.use_discount,
        me.paymentParams.total_amount
      ); //实际支付金额=应付金额-实际支付金额
      me.paymentParams.create_id = me.paymentParam.create_id;
      me.paymentParams.payment_status = me.paymentParam.status;
      me.paymentParams.trade_status = 1; //已处理
      me.paymentParams.medical_record_no = me.paymentParam.medical_record_no;
      me.paymentParams.user_level_id = me.paymentParam.user_level_id;
      me.paymentParams.cause = me.paymentParam.cause;
      me.paymentParams.user_id = me.paymentParam.client_id;
       //me.paymentParams.treatments.status=1;
      //me.paymentParams.use_level=
      me.$emit("savepaymentitem", me.paymentParams);
    },
    //加减方法
    handleNumChange(ins, val) {
      let fun = 0;
      if (this.numss > val) {
        fun = 1;
      }
      this.numss = val;
      let rowarr = this.rows;
      let old_amount = 0;
      let old_discount = 0;
      rowarr.findIndex((value, index, arr) => {
        if (index === ins) {
          // old_discounts =
          old_discount = this.NumberMul(val - 1, this.rows[index].use_price); //事件前的价格
          old_amount = this.NumberMul(val - 1, this.rows[index].price);
          rowarr[index].discount = this.NumberMul(val, this.rows[index].use_price); //事件后的价格
          rowarr[index].amount = this.NumberMul(val, this.rows[index].price); //事件后的价格
          if (fun === 0) {
            // 加
            this.paymentParams.use_discount = this.NumberSub(
              this.NumberAdd(this.paymentParams.use_discount, rowarr[index].discount),
              old_discount
            ); //减去事件前的价格
          } else if (fun === 1) {
            //减
            this.paymentParams.use_discount = this.NumberSub(
              this.paymentParams.use_discount,
              rowarr[index].use_price
            ); //减去事件前的价格
          }

          this.paymentParams.pay_amount = this.NumberSub(
            this.NumberAdd(this.paymentParams.pay_amount, rowarr[index].amount),
            old_amount
          ); //减去事件前的价格
        }
        //return value.time == val;
      });

      this.rows = rowarr;
    },
    changeDrawer(v) {
      this.drawer = v;
    },
    /**
     * 生产批号填写的方法
     * @param index
     * @param remark
     */
    onBatchNumberInput(index, batch_number) {
      this.rows[index].batch_number = batch_number;
    },

    /**
     * 数量填写的方法
     * @param index
     * @param remark
     */
    onNumInput(index, num) {
      this.rows[index].num = num;
    },
    /**
     * 进价填写的方法
     * @param index
     * @param remark
     */
    onPriceInput(index, price) {
      this.rows[index].price = price;
    },

    /**
     * 删除的方法
     * @param index
     */
    onDeleteButtonClick(index, item) {
      //this.$emit('delexamination',this.rows[index].examinations_id);
      this.rows.splice(index, 1);
    },
    NumberMul(arg1, arg2) {
      let m = 0;
      let s1 = arg1.toString();
      let s2 = arg2.toString();
      try {
        m += s1.split(".")[1].length;
      } catch (e) {}
      try {
        m += s2.split(".")[1].length;
      } catch (e) {}

      return (
        (Number(s1.replace(".", "")) * Number(s2.replace(".", ""))) / Math.pow(10, m)
      );
    },
    NumberAdd(arg1, arg2) {
      let r1, r2, m, n;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      n = r1 >= r2 ? r1 : r2;
      return ((arg1 * m + arg2 * m) / m).toFixed(n);
    },
    NumberSub(arg1, arg2) {
      let re1, re2, m, n;
      try {
        re1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        re1 = 0;
      }
      try {
        re2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        re2 = 0;
      }
      m = Math.pow(10, Math.max(re1, re2));
      n = re1 >= re2 ? re1 : re2;
      return ((arg1 * m - arg2 * m) / m).toFixed(n);
    },
    // 除数，被除数， 保留的小数点后的位数
    NumberDiv(arg1, arg2, digit) {
      let t1 = 0,
        t2 = 0,
        r1,
        r2;
      try {
        t1 = arg1.toString().split(".")[1].length;
      } catch (e) {}
      try {
        t2 = arg2.toString().split(".")[1].length;
      } catch (e) {}
      r1 = Number(arg1.toString().replace(".", ""));
      r2 = Number(arg2.toString().replace(".", ""));
      //获取小数点后的计算值
      var result = ((r1 / r2) * Math.pow(10, t2 - t1)).toString();
      var result2 = result.split(".")[1];
      result2 = result2.substring(0, digit > result2.length ? result2.length : digit);

      return Number(result.split(".")[0] + "." + result2);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: flex-end;

  .edit-buttom {
    margin-top: 20px;
  }
}

.header {
  width: 100%;
  padding: 0 10px;
  height: 50px;
  line-height: 50px;
  background-color: #ededed;
  border-bottom: 1px solid #e1e1e1;

  > .time {
    font-size: 12px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #5b5b5b;
  }

  > .button {
    height: 30px;
    margin-top: 12px;
  }
}

.title-top {
  text-align: center;
}

.cloumn-head {
  padding: 8px 12px;
  line-height: 40px;
}

.cloumn-footer {
  padding: 8px 12px;
}

$see-detail-test-inspect-item-title-width: 132px;

$see-detail-test-inspect-item-height: 45px;

.see-detail-test-inspect {
  .el-row {
    width: 100%;
    border-bottom: 1px solid #979797;

    &:last-child {
      margin-bottom: 0;
      // border-bottom: 1px solid #979797;
    }
  }

  .basic-inspect {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
  }

  .bg-purple {
    background: #e5e9f2;
  }

  .bg-purple-light {
    background: #ffffff;
    // border-left: 1px solid #979797;
    // border-right: 1px solid #979797;
  }

  .purple-light {
    // border-left: 1px solid #979797;
  }

  .grid-content {
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .grid-left {
    text-align: left;
    padding-left: 10px;
  }

  .grid-right {
    text-align: right;
    padding-right: 20px;
    color: #ff0000;
    // text-decoration: underline;
    position: absolute;
    right: 0;
    top: 0;
  }

  .row-bg {
    padding: 10px 0;
    background-color: #f9fafc;
  }
}
</style>
