<template>
  <Container>
    <div class="screen">
      <div class="left-details">
        <div class="select">
          <el-button type="primary" @click="changeDrawer(true)">新增药品</el-button>
        </div>
        <div class="select">
          <el-select v-model="categoryValue" placeholder="请选择类目">
            <el-option
              v-for="item in pharmacyCateDatas"
              :key="item.pharmacy_category_id"
              :label="item.name"
              :value="item.pharmacy_category_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="search select">
          <el-input
            v-model="search"
            placeholder="搜索药品名称"
            class="input-with-select search"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              :disabled="loading"
              @click="handleFormSubmit(true)"
            ></el-button>
          </el-input>
        </div>
        <div class="search select">
          <el-button icon="el-icon-refresh" @click="handleFormReset">重置</el-button>
        </div>
      </div>
    </div>

    <AddDrugs
      @submitadddrugs="onSubmitAddDrugs"
      ref="add_drawer"
      :direction="direction"
      :size="size"
      :drawer_title="drawer_add_title"
      :brandDatas="brandDatas"
      :supplierDatas="supplierDatas"
      :factoryDatas="factoryDatas"
      :pharmacyCateDatas="pharmacyCateDatas"
      :unitList="smallunitDatas"
    ></AddDrugs>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
     ...components(["AddDrugs"], "pharmacy.Drugs.Left"),
  },
  props: {
     loading: {
      default: false,
    },
    pharmacyCateDatas: {},
    brandDatas: {},
    supplierDatas: {},
    factoryDatas: {},
    smallunitDatas:{}

  },
  watch: {
    pharmacyCateDatas:function(val){
      //console.log("val",val);
    },
    search: {
      handler(val, oldVal) {
        this.paramForm.keywords = val;
      },
      deep: true,
    },
    categoryValue: {
      handler(val, oldVal) {
        this.paramForm.categoryValue = val;
      },
      deep: true,
    },
    manufactorValue: {
      handler(val, oldVal) {
        this.paramForm.manufactorValue = val;
      },
      deep: true,
    },
    supplierValue: {
      handler(val, oldVal) {
        this.paramForm.supplierValue = val;
      },
      deep: true,
    },
    brandValue: {
      handler(val, oldVal) {
        this.paramForm.brandValue = val;
      },
      deep: true,
    },
  },
  data() {
    return {
      size: "70%",
      drawer_title: "编辑药品",
      drawer_add_title: "新增药品",
      direction: "rtl",
      search: "",
      categoryValue: "",
      manufactorValue: "",
      supplierValue: "",
      brandValue: "",
      paramForm: {
        keywords: undefined,
        search: undefined,
      },
    };
  },
  methods: {
    changeDrawer(v) {
     let me=this;
      me.$refs.add_drawer.changeDrawer(v);
    },
    onSubmitAddDrugs(data) {
      let me = this;
      me.$emit("submitadddrugs", data);

    },
    handleFormSubmit(isRestore = false) {
      let me = this;
      me.$emit("submit", me.paramForm, isRestore);
    },
    handleFormReset() {
      let me = this;
      me.categoryValue = "";
      me.search = "";
      me.$emit("submitreset",me.paramForm);
    },
  },
};
</script>


<style lang="scss" scoped>
.screen {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-details {
    display: flex;
    align-items: center;
   
    .select {
      margin-right: 10px;
    }

    .date {
      width: 240px;
    }
  }
}

.el-range-editor.el-input__inner {
  width: 100%;
}
</style>
