/**
 * 获取一个病历信息 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/examinations/method/get.examinations.list';

export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        })).then(({
            data
        }) => data)
        .then(({
            data
        }) => (data))
        .then(({
            items
        }) => items.map(({
            getExaminationTemplate,
            count,
            examinations_id,
            remark,
            status
        }) => ({
            name:getExaminationTemplate.name,
            englishName:getExaminationTemplate.english_name,
            count:count,
            unit:getExaminationTemplate.unit,
            price:getExaminationTemplate.price,
            remark:remark,
            e_ID:examinations_id,
            status:status
        })));
}