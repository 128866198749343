<template>
   <Container class="see-patient-history-list-item hb-layout-vertical">
          <Container class="header hb-layout-horizontal">
            <div class="time hb-layout-flex-1" v-if="patientData !== undefined">
              预约时间:{{ patientData.appointment_date }}/{{ patientData.start_time }}-{{
                patientData.end_time
              }}
            </div>
            <div class="time hb-layout-flex-1" v-if="patientData == undefined">
              预约时间:{{}}
            </div>
            <el-button
              type="success"
              size="mini"
              class="button"
              v-if="patientData.visit_status == 1"
              >首诊</el-button
            >
            <el-button
              type="primary"
              size="mini"
              class="button"
              v-if="patientData.visit_status == 2"
              >初诊</el-button
            >
            <el-button
              type="danger"
              size="mini"
              class="button"
              v-if="patientData.visit_status == 3"
              >复诊</el-button
            >
          </Container>
          <Container class="body">
            <Container class="inner hb-layout-horizontal">
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">体温</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input type="number" oninput="if(isNaN(value)) {value = value.slice(0,value.length-1) } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+2)}" v-model="temperature" placeholder="请填写体温"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">呼吸</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input type="number" oninput ="value=value.replace(/[^\d]/g,'')" v-model="breathing" placeholder="请填写呼吸"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">血压</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input type="number" oninput ="value=value.replace(/[^\d]/g,'')" v-model="bloodPressure" placeholder="请填写血压"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">心率</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input type="number" oninput ="value=value.replace(/[^\d]/g,'')" v-model="heartRate" placeholder="请填写心率"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">身高</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input type="number" oninput="if(isNaN(value)) {value = value.slice(0,value.length-1) } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+2)}" v-model="height" placeholder="请填写身高"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">体重</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input type="number" oninput="if(isNaN(value)) {value = value.slice(0,value.length-1) } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+2)}" v-model="weight" placeholder="请填写体重"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">头围</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input
                      type="number"
                      oninput ="value=value.replace(/[^\d]/g,'')"
                      v-model="headCircumference"
                      placeholder="请填写头围"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">BMI</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input type="number" oninput="if(isNaN(value)) {value = value.slice(0,value.length-1) } if(value.indexOf('.')>0){value=value.slice(0,value.indexOf('.')+2)}" v-model="BMI" placeholder="请填写BMI"></el-input>
                  </div>
                </el-col>
              </el-row>
              <!-- <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">过敏史</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input
                      v-model="allergicHistory"
                      placeholder="请填写过敏史"
                    ></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">既往史</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input v-model="pastHistory" placeholder="请填写既往史"></el-input>
                  </div>
                </el-col>
              </el-row>
              <el-row type="flex" align="middle">
                <el-col :span="10">
                  <div class="grid-content bg-purple">家族史</div>
                </el-col>
                <el-col :span="14">
                  <div class="grid-content bg-purple-light-select">
                    <el-input
                      v-model="familyHistory"
                      placeholder="请填写家族史"
                    ></el-input>
                  </div>
                </el-col>
              </el-row> -->
            </Container>
            <el-button type="success" class="edit-buttom" @click="diagnosisSaveClick"
              >确认提交</el-button
            >
          </Container>
        </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    patientData: {},
    signsdata: {},
    doctors: {},
  },
  watch: {
    patientData({
      doctor_id,
      appointment_date,
      start_time,
      end_time,
      symptom,
      duration_time,
      clinical_department_id,
      status,
      visit_status
    }) {
      let me = this;

      me.doctorValue = doctor_id;

      me.dataPick = appointment_date;

      me.startTime = start_time;

      me.endTime = end_time;

      me.symptomValue = symptom;

      me.DurationValue = duration_time;

      me.departmentValue = clinical_department_id;

      me.status = status;

      me.visitStatusValue = visit_status;
    },
    signsdata: function (newVal, oldVal) {
      // console.log("wathc", newVal);
      // console.log("wathco", oldVal);
      if (newVal == null) {
        this.temperature = ""; //体温
        this.breathing = ""; //呼吸
        this.bloodPressure = ""; //血压
        this.heartRate = ""; //心率
        this.height = ""; //身高
        this.weight = ""; //体温
        this.headCircumference = ""; //头围
        this.BMI = ""; //bmi
        this.allergicHistory = ""; //过敏史 TODP 改为选择
        this.pastHistory = ""; //既往史
        this.familyHistory = ""; //家族史
      } else {
        this.temperature = newVal.temperature; //体温
        this.breathing = newVal.breathe; //呼吸
        this.bloodPressure = newVal.blood_pressure; //血压
        this.heartRate = newVal.heart_rate; //心率
        this.height = newVal.stature; //身高
        this.weight = newVal.weight; //体温
        this.headCircumference = newVal.head_circumference; //头围
        this.BMI = newVal.bmi; //bmi
        this.allergicHistory = newVal.allergies; //过敏史 TODP 改为选择
        this.pastHistory = newVal.past_history; //既往史
        this.familyHistory = newVal.family_history; //家族史
      }
    },
  },

  data() {
    return {
      value: [30, 60],
      marks: {
        0: '0°C',
        8: '8°C',
        37: '37°C',
        50: {
          style: {
            color: '#1989FA'
          },
          label: this.$createElement('strong', '50%')
        }
      },
      temperature: "",
      bloodPressure: "",
      breathing: "",
      heartRate: "",
      height: "",
      weight: "",
      headCircumference: "",
      allergicHistory: "",
      pastHistory: "",
      familyHistory: "",
      BMI: "",
      selected: null,
      selectMonth: "",
      activeName: "first",
      //   选择医生
      doctor: [
        {
          value: "1",
          name: "王医生",
        },
        {
          value: "2",
          name: "李医生",
        },
      ],
      doctorValue: 0,
      // 选择科室
      department: [
        {
          value: 1,
          label: "内科",
        },
        {
          value: 2,
          label: "外科",
        },
        {
          value: 3,
          label: "神经内科",
        },
      ],
      departmentValue: "1",

      // 症状
      symptom: [
        {
          value: "1",
          label: "头疼",
        },
        {
          value: "2",
          label: "流鼻涕",
        },
      ],
      symptomValue: "2",

      // 持续时间
      Duration: [
        {
          value: "1",
          label: "三天",
        },
        {
          value: "2",
          label: "一周",
        },
      ],
      DurationValue: "2",
      statusSex: [
        {
          text: "保密",
        },
        {
          text: "男",
        },
        {
          text: "女",
        },
      ],
      appointment_status: [
        {
          value: 0,
          label: "待确认",
        },
        {
          value: 1,
          label: "已确认",
        },
        {
          value: 2,
          label: "已取消",
        },
      ],
      status: 1,

      // 时间段
      timeLine: [
        {
          value: "1",
          label: "1个时间段",
        },
        {
          value: "2",
          label: "2个时间段",
        },
        {
          value: "3",
          label: "3个时间段",
        },
        {
          value: "4",
          label: "4个时间段",
        },
        {
          value: "5",
          label: "5个时间段",
        },
      ],
      timeLineValue: "1",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //设置当天之前的日期不能选择
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周后",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      dataPick: "",
      startTime: "",
      endTime: "",
    };
  },
  methods: {
    onDatePickerChange(date) {
      this.$emit("datechange", date);
    },

    onItemSelect(historyItem) {
      this.$emit("historyitemselect", historyItem);
    },

    handleClick(tab, event) {
     // console.log(tab, event);
    },

    diagnosisSaveClick() {
      let data = {
        user_id: this.patientData.user_id,
        temperature: this.temperature, // 体温
        breathe: this.breathing,
        blood_pressure: this.bloodPressure,
        heart_rate: this.heartRate,
        stature: this.height,
        weight: this.weight,
        head_circumference: this.headCircumference,
        bmi: this.BMI,
        allergies: this.allergicHistory,
        past_history: this.pastHistory,
        family_history: this.familyHistory,
        clinical_department_id:this.departmentValue,
        doctor_id:this.doctorValue,
        type: this.visitStatusValue,
      };

      this.$emit("diagnosissaveclick", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.see-patient-history-list {
  // padding: 0 10px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 0px 2px;

  .see-patient-history-list-item {
    width: 100%;
    // border: 1px solid #eeeeee;

    > .header {
      padding: 0 10px;
      height: 50px;
      line-height: 50px;
      background-color: #ededed;
      border-bottom: 1px solid #e1e1e1;

      > .time {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5b5b5b;
      }

      > .button {
        height: 30px;
        margin-top: 12px;
      }
    }

    > .body {
      padding: 10px;

      > .inner {
        display: flex;
        flex-direction: column;
        border: 1px solid #979797;

        .el-row {
          width: 100%;
          height: auto;
          line-height: 38px;
          border-bottom: 1px solid #979797;
          font-size: 12px;

          &:last-child {
            border-bottom: none;
          }
        }

        .bg-purple {
          text-align: center;
          background: #ebebeb;
          border-right: 1px solid #979797;
        }

        .bg-purple-light {
          padding-left: 15px;
        }

        .bg-purple-light-select {
          padding: 0;
          font-size: 12px;
        }
      }
    }

    .edit-buttom {
      width: 100%;
      margin-top: 20px;
    }
  }
}
/deep/.el-button--primary:first-child {
  width: 245px !important;
}
.el-dropdown-menu /deep/.el-dropdown-menu__item {
  width: 270px;
  text-align: center;
}
/deep/.el-input__inner{
  padding: 0 0 0 15px !important;
}
</style>
