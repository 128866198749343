<template>
  <Container class="statistics" ref="form">
    <div class="clearfix">
      <el-button-group>
        <el-button type="primary" @click="todayClick()">今天</el-button>
        <el-button type="primary" @click="weekClick()">本周</el-button>
        <el-button type="primary" @click="monthClick()">本月</el-button>
        <el-button type="primary" @click="yearClick()">今年</el-button>
      </el-button-group>
      <div class="reset">
        <el-button icon="el-icon-refresh" @click="handleFormReset">重置</el-button>
      </div>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["AddDelivery"], "storage.Delivery"),
    ...components(["Container"]),
  },
  props: {
     loading: {
      default: false,
    },
    attributeDatas: {},
    warehousParam: {},
    categoryRecords: {},
    recordsstockout: {},
    storehouseSelectDatas: {},
  },
  data() {
    return {
      direction: "rtl",
      size: "45%",
      drawer_add_title: "新增出库",
      search: "", // 搜索出库人
      type: [
        {
          value: "1",
          label: "领料出库",
        },
        {
          value: "2",
          label: "报损出库",
        },
        {
          value: "3",
          label: "退货出库",
        },
      ],
      typeValue: "", // 出库类型
      dateValue: "", // 选择时间
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dataTime: "",
      payValue: "", // 选择支付方式
      search: "", // 搜索
      paramForm: {
        action: undefined,
        typeValue: undefined,
        keywords: undefined,
        begin_time: undefined,
        end_time: undefined,
      },
    };
  },
  watch: {
    dataTime: {
      handler(val, oldVal) {
        if (val !== "") {
          this.paramForm.begin_time = val[0].toUTCString();
          this.paramForm.end_time = val[1].toUTCString();
        }
      },
      deep: true,
    },
    search: {
      handler(val, oldVal) {
        this.paramForm.keywords = val;
      },
      deep: true,
    },
    typeValue: {
      handler(val, oldVal) {
        this.paramForm.typeValue = val;
      },
      deep: true,
    },
  },
  methods: {
    addDelivery(val) {
      this.$emit("getcategoryrecords");
      this.$refs.add_drawer.changeDrawer(true, val);
    },
    onGetStorehouseRecord(val) {
      this.$emit("getshorehouserecord", val);
    },
    onSaveStorehouseStockout(dataOne, dataTwo) {
      this.$emit("savestorehousestockout", dataOne, dataTwo);
    },
    handleFormSubmit(isRestore = false) {
      let me = this;
      me.$emit("submit", me.paramForm, isRestore);
    },
    handleFormReset() {
      let me = this;
      me.typeValue = "";
      me.search = "";
      me.dataTime = "";
      me.$emit("submitreset",me.paramForm);
    },
    todayClick() {
      let me = this;
      me.dataTime = "";
      me.$emit("todayclick");
    },
    weekClick() {
      let me = this;
      me.dataTime = "";
      me.$emit("weekclick");
    },
    monthClick() {
      let me = this;
      me.dataTime = "";
      me.$emit("monthclick");
    },
    yearClick() {
      let me = this;
      me.dataTime = "";
      me.$emit("yearclick");
    },
  },
};
</script>

<style lang="scss" scoped>
.screen {
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-details {
    display: flex;
    align-items: center;
    margin-right: 10px;

    .select {
      margin: 0 10px;
    }

    .date {
      width: 240px;
    }
  }
}

.el-range-editor.el-input__inner {
  width: 100%;
}
.statistics {
  .clearfix {
    display: flex;
    align-items: center;
    margin-left: 10px;
    .data {
      width: 320px;
      margin-left: 10px;
    }
    .pay {
      width: 165px;
      margin: 0 10px;
    }
    .reset {
      margin: 0 10px;
    }
  }
}
.el-date-editor /deep/.el-range-separator {
  width: 20px !important;
}
.el-range-editor.el-input__inner {
  width: 100%;
}
</style>
