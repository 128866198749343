<template>
  <Container class="toolbar hb-layout-center">
    <el-row class="footer-bar">
      <el-col :span="1"><el-button type="warning" circle></el-button></el-col>
      <el-col :span="2">待确认</el-col>
      <el-col :span="1"><el-button type="success" circle></el-button></el-col>
      <el-col :span="2">已确认</el-col>
      <el-col :span="1"><el-button type="danger" circle></el-button></el-col>
      <el-col :span="2">取消预约</el-col>
      <el-col :span="1"><el-button type="danger" circle></el-button></el-col>
      <el-col :span="2">客户爽约</el-col>
      <el-col :span="1"><el-button type="primary" circle></el-button></el-col>
      <el-col :span="2">已到店</el-col>
      <el-col :span="1"><el-button type="info" circle></el-button></el-col>
      <el-col :span="2">已服务</el-col>
    </el-row>
  </Container>
</template>
<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    calendarSetting: 0,
  },
  data() {
    return {};
  },
  methods: {
    onChangeButtonClick(val) {
      this.$emit("changebuttonclick", val);
    },
    onPreviousButtonClick() {
      this.$emit("previousbuttonclick");
    },

    onNextButtonClick() {
      this.$emit("nextbuttonclick");
    },
  },
};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
  height: 100%;
  overflow: hidden;
}

.footer-bar {
  width: 100%;
  display: flex;
  justify-content: center;
  font-size: 12px;
  margin: 20px 0 30px;
  border-top: 2px solid #999;
  padding-top: 17px;
}
.primary {
  width: 10px;
}
.el-button.is-circle {
  padding: 5px;
}
</style>
