<template>
      <el-row style="width: 100%;" shadow="hover" :class="{
      'see-patient-list-item':true,
        selected
    }" >
        <el-col :span="3">
            <div class="grid-content bg-purple">{{name}}</div>
        </el-col>
        <el-col :span="2">
            <div class="grid-content bg-purple-light">{{sex}}</div>
        </el-col>
        <el-col :span="4">
            <div class="grid-content bg-purple">{{patient}}</div>
        </el-col>
        <el-col :span="4">
            <div class="grid-content bg-purple-light">{{age}}</div>
        </el-col>
        <el-col :span="3">
            <div class="grid-content bg-purple-light">-</div>
        </el-col>
        <el-col :span="4">
            <div class="grid-content bg-purple">{{start}}</div>
        </el-col>
        <el-col :span="4">
            <div class="grid-content bg-purple-light">{{end}}</div>
        </el-col>
    </el-row>

</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components: components([
    'Container'
  ]),
  props: {
    name: {
      required: true,
      type: String
    },
    patient: {
      required: true,
      type: String
    },
    sex: {
      required: true,
      type: [
        String,
        Number
      ]
    },
    start: {
      required: true,
      type: [
        String,
        Object
      ]
    },
    end: {
      required: true,
      type: [
        String,
        Object
      ]
    },
    age: {
      required: true,
      type: [
        String,
        Number
      ]
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      lvlMap:{

      },
      statusMap: [
        {
          text: '全部',
          type: ''
        },
        {
          text: '在班',
          type: 'warning'
        },
        {
          text: '未在班',
          type: 'success'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

$see-patient-list-item-height: 80px;

	.see-patient-list-item {
		height: $see-patient-list-item-height;
    line-height: $see-patient-list-item-height;
		align-items: center;

		>.header >img {
			width: 30px;
			height: 30px;
		}

		&.selected {
			box-shadow: 0 2px 7px 0 #d8d8d8;
			margin: 2px 0;
		}


		>.logo {
			width: 46px;
			height: 46px;
			margin-right: 10px;

			>img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
    font-size: 14px;
		.name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;

		}

		>.grade {
			padding: 3px 12px;
			background: #F1BE5E;
			border: 1px solid #979797;
			margin-right: 15px;
		}
    .name{
      font-size: 12px;
    }
		.time {
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #0B7DFF;
			line-height: 15px;
      display: flex;
      justify-content: center;
		}

		>.time,
		.state {
			display: flex;
      justify-content: center;

		}
	}

.grid-content{
  text-align: center;
}

</style>
