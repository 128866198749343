<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header></Header>
    <Content @getleftchangethree="onGetLeftChangeThree"></Content>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Header", "Content"], "configuration.Stock.Left"),

    ...components(["Container"]),
  },

  props: {},

  data() {
    return {};
  },

  methods: {
    onGetLeftChangeThree(val) {
      this.$emit("getleftchangethree", val);
    },
  },
};
</script>

<style lang="scss" scoped></style>
