<template>
<Container>
    <el-row>
        <el-col :span="18">
            <div class="grid-content purple">项目 Item</div>
        </el-col>
        <el-col :span="6">
            <div class="grid-content purple">价格 Amount（RMB）</div>
        </el-col>
    </el-row>
    <el-row v-for="(item, index) in Data" :key="index">
        <el-col :span="18">
            <div class="grid-content purple">{{ item.name }}{{ item.englishName }}</div>
        </el-col>
        <el-col :span="6">
            <div class="grid-content purple">
                <div>RMB {{ item.price }}</div>
            </div>
        </el-col>
    </el-row>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: components(["Container"]),
    props: {
        Data: {},
    },

    data() {
        return {
            statusOptions: [{
                    value: undefined,
                    label: "全部",
                },
                {
                    value: 0,
                    label: "待诊",
                },
                {
                    value: 1,
                    label: "接诊中",
                },
                {
                    value: 2,
                    label: "检测中",
                },
                {
                    value: 3,
                    label: "待付款",
                },
                {
                    value: 4,
                    label: "已付款",
                },
            ],
        };
    },
    watch: {},

    methods: {},
};
</script>

<style lang="scss" scoped>
$see-patient-list-header-height: 50px;

$see-patient-list-padding: 0 10px 0 10px;

.see-patient-list {
    >.header {
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 16px 0 18px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5b5b5b;

        >.tody {
            padding-right: 15px;
        }
    }
}

.el-row {
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    line-height: 17px;
    // margin-left: 5px;

    &:last-child {
        margin-bottom: 0;
        border-bottom: 1px solid #e1e1e1;
    }
}

.purple {
  padding: 5px 15px;
  border-right: 1px solid #e1e1e1;
}

.grid-content {
    font-size: 13px;
}


.display {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;

    .display-item {
        display: flex;
        // align-items: center;

        .display-item-name {
            padding-right: 100px;
        }
    }
}
</style>
