<template>
  <ClinicScheduling
    ref="scheduling"
     @cellselect="onCellSelect"
    :users="users"
    :plans="plans"
    :title="title"
    @onrreviousbuttonclick="onPreviousButtonClick"
    @onnextbuttonclick="onNextButtonClick"
  ></ClinicScheduling>
</template>

<script>
import ClinicScheduling from "clinic-scheduling";

import "clinic-scheduling/index.css";

export default {
  components: {
    ClinicScheduling, d
  },
  props: {
    plans: {},
    users: {}, 
    title: "",
  },
  watch: {
    users:function(val){
      //console.log("112",val);
      if(val.length<0){
         this.users = usersList;
      }
    }
  },
  data() {
    return {
      usersList:[],
    }

  },
  methods: {
    onPreviousButtonClick() {
      this.$refs.scheduling.previous();
    },

    onNextButtonClick() {
      this.$refs.scheduling.next();
    },

    onCellSelect(_, plans) {
     // console.log("单元格选中", plans);
    },
  },
};
</script>

<style lang="scss" scoped>
.zb-layout-flex {
  display: flex;
}

.zb-layout-flex.direction-row {
  flex-direction: row;
}

.zb-layout-flex.direction-column {
  flex-direction: column;
}

.zb-layout-flex.align-start {
  align-items: flex-start;
}

.zb-layout-flex.align-end {
  align-items: flex-end;
}

.zb-layout-flex.align-center {
  align-items: center;
}

.zb-layout-flex.align-stretch {
  align-items: stretch;
}

.zb-layout-flex.justify-start {
  text-align: center;
}

.zb-layout-flex.justify-end {
  justify-content: flex-end;
}

.zb-layout-flex.justify-center {
  justify-content: center;
}

.zb-layout-flex.justify-stretch {
  justify-content: stretch;
}

.zb-layout-flex > .zb-layout-flex-item-wrapper {
  position: relative;
}

.zb-layout-flex > .zb-layout-flex-item-wrapper.flex {
  flex: 1;
}

.zb-layout-flex > .zb-layout-flex-item-wrapper > .inner {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.zb-layout-flex > .zb-layout-flex-item-wrapper > .inner {
  width: 100%;
  height: 100%;
}

.clinic-scheduling /deep/ .cell {
  line-height: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 0;
  color: #333;
  border-right: 1px solid #979797;
  border-bottom: 1px solid #979797;
}

.clinic-scheduling /deep/ .cell.title {
  border-top: 1px solid #979797;
}

.clinic-scheduling /deep/ span.cell {
  border-left: 1px solid #979797;
}

.clinic-scheduling /deep/ .cell .plan {
  margin: 4px 0;
}
</style>
