/**
 * 获取药房列表 API
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/doctor/method/get.list';
 
 export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        }))
        .then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items
        }) => items.map(({
            doctor_id,
            nickname,
        }) => ({
            name: nickname,
            id: doctor_id,
        })));
}