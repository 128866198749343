<template>
    <Container class="appoientment-main-operation hb-layout-horizontal">
        <div style="width: 100%">
            <el-menu class="el-menu-vertical-demo" default-active="1">
                <el-menu-item index="1"  @click="getLeftChangeTwo(1)">
                    <span slot="title" >会员级别</span>
                </el-menu-item>
                <el-menu-item index="2"  @click="getLeftChangeTwo(2)">
                    <span slot="title">充值优惠</span>
                </el-menu-item>
                  <!-- <el-menu-item index="3">
                    <span slot="title">优惠券</span>
                </el-menu-item>
                  <el-menu-item index="4">
                    <span slot="title">购物卡</span>
                </el-menu-item> -->
                <el-menu-item index="5"  @click="getLeftChangeTwo(5)">
                    <span slot="title">促销活动</span>
                </el-menu-item>
            </el-menu>
        </div>
    </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"])
  },
  data() {
    return {};
  },
  methods: {
    getLeftChangeTwo(val) {
      this.$emit("getleftchangetwo", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 2px 10px 0px;

    .el-menu-item, .el-submenu__title{
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 12px;
        border-bottom: 1px solid #e1e1e1;
    }
    .el-menu-item.is-active{
        background: #92c2f957;
        box-shadow: 0 2px 7px 0 #d8d8d8;
        margin: 2px 0;
    }
    .el-menu-item.is-active:nth-child(1){
        margin: 0 0 2px;
    }
}

    
</style>
