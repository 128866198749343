<template>
  <Container class="charge-details-content">
    <div class="list">
      <div id="sourceChart" :style="{width: '600px', height: '330px'}"></div>
    </div>
  </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props:{
    
  },
  data() {
    return {

    }

  },
  mounted(){
    this.drawLine();
  },
  methods: {
    drawLine(){
        // 基于准备好的dom，初始化echarts实例
        let sourceChart = this.$echarts.init(document.getElementById('sourceChart'))
        // 绘制图表
        sourceChart.setOption({
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              right: 'right',
              top: '50'
            },
            xAxis: {
              show: false
            },
            yAxis: {
              show: false
            },
            series: [{
                name: '数据',
                type: 'pie',
                radius: ['40%', '70%'],
                right: '90',
                left: '70',
                itemStyle: {
                  borderColor: '#fff',
                  borderWidth: 2,
                },
                label: {
                  show: false,
                  position: 'center'
                },
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold'
                  }
                },
                data: [
                  {value: 1048, name: '看诊'},
                  {value: 735, name: '预约'},
                  {value: 580, name: '中药'},
                  {value: 484, name: '西药'},
                  {value: 300, name: '输液'},
                  {value: 300, name: '其他'}
                ],
                color:['#5470C6','#92CC74','#F9C858','#ED6667','#73C0DD','#3BA273']
            }]
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.charge-details-content{
  margin-top: 15px;
  .list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow-x: auto;
  }
}
</style>
