/**
 * 获取医生列表 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/doctor/method/get.doctor.list.count';
//let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment_record/method/get.doctor.list/';
export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        }))
        .then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items
        }) => items.map(({
            doctor_id,
            nickname,
            title,
            price,
            count_appointment,
            count_register,
            count_wait,
            count_end,
            department_id
        }) => ({
            name: nickname,
            doctorId: doctor_id,
            departmentID:department_id,
            title: title,
            price: price,
            count_appointment: count_appointment,
            count_register: count_register,
            count_wait: count_wait,
            count_end: count_end
        })));
}