<template>
  <Container class="patient-detail-base">
    <div class="clearfix">
      <div class="title">账户信息</div>
      <div>
        <el-button type="success" plain size="mini" class="btn" @click="gradeDialog(true)"
          >会员升级</el-button
        >
        <el-button type="primary" plain size="mini" @click="rechargeDialog(true)"
          >余额充值</el-button
        >
        <el-button type="danger" plain size="mini" @click="insuranceDialog(true)"
          >保险账户</el-button
        >
      </div>
    </div>
    <!-- 保险备注 -->
    <!-- <div class="remark"> 
      <div class="line"></div>
      <div class="input">
        <el-input placeholder="保险备注" v-model="remark_insurance" @blur="onBlur($event,'remark_insurance')">
          <el-button slot="append" icon="el-icon-edit"></el-button>
        </el-input>
      </div>
    </div> -->

    <Information :usermoney="usermoney" @insuranceblurvalue="onInsuranceBlueValue"></Information>
    <UpgradeMembers
      ref="gradeLevel"
      @submitupgrade="onSubmitUpgrade"
      :drawer_title="drawer_add_title"
      :size="size"
      :direction="direction"
      :gradelevelDatas="gradelevelDatas"
    ></UpgradeMembers>

    <Recharge
      ref="recharge"
      @submitrecharge="onSubmitRecharge"
      :drawer_title="drawer_recharge_title"
      :size="size"
      :direction="direction"
      :gradeRechargeDatas="gradeRechargeDatas"
    ></Recharge>

    <AddPayment
      ref="add_payment"
      :direction="direction"
      :size="sizepayment"
      :drawer_title="drawer_add_title"
      :paymentParam="paymentParam"
      :treatmenttemplateDatas="treatmenttemplateDatas"
      @gettreatmenttemplatechildren="onGetTreatmentTemplateChildren"
      :treatmenttemplateChildrenDatas="treatmenttemplateChildrenDatas"
      :paymentDatas="paymentDatas"
      @savepaymentitem="onSavePaymentItem"
    ></AddPayment>

    <InsuranceInfo
      ref="add_insurance"
      :direction="direction"
      :size="sizeinsurance"
      :drawer_title="drawer_insurance_title"
      :paymentParam="paymentParam"
      :treatmenttemplateDatas="treatmenttemplateDatas"
      @gettreatmenttemplatechildren="onGetTreatmentTemplateChildren"
      :treatmenttemplateChildrenDatas="treatmenttemplateChildrenDatas"
      :paymentDatas="paymentDatas"
      @savepaymentitem="onSavePaymentItem"
      :insuranceCompanyDatas="insuranceCompanyDatas"
      @insurancelevel="onInsuranceLevel"
      :insuranceLevelDatas="insuranceLevelDatas"
      @insurancesetting="onInsuranceSetting"
      :insuranceSettingDatas="insuranceSettingDatas"
      @saveinsuranceaccountitem="onSaveInsuranceAccountItem"
      :insuranceAccountDatas="insuranceAccountDatas"
    ></InsuranceInfo>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
    ...components(
      ["Recharge", "UpgradeMembers", "Information", "AddPayment", "InsuranceInfo"],
      "patient.Center.Account"
    ),
  },
  props: {
    insuranceLevelDatas: {},
    paymentDatas: {},
    paymentParam: {},
    usermoney: {},
    gradelevelDatas: {},
    gradeRechargeDatas: {},
    treatmenttemplateDatas: {},
    treatmenttemplateChildrenDatas: {},
    insuranceCompanyDatas: {},
    insuranceSettingDatas: {},
    insuranceAccountDatas:{},
    // remark: {}
  },
  data() {
    return {
      direction: "rtl",
      size: "30%",
      sizepayment: "50%",
      sizeinsurance: "90%",
      drawer_add_title: "会员升级",
      drawer_recharge_title: "余额充值",
      drawer_insurance_title: "保险账户",
      grade: [
        {
          value: "1",
          label: "白银",
        },
        {
          value: "2",
          label: "黄金",
        },
      ],
      gradeValue: "", // 选择会员等级
      gradeRemarks: "", // 会员升级的备注信息
      rechargeActivity: [
        {
          value: "1",
          label: "充100送20",
        },
        {
          value: "2",
          label: "充200送50",
        },
        {
          value: "3",
          label: "充300送100",
        },
        {
          value: "4",
          label: "充500送150",
        },
      ],
      rechargeActivityValue: "1", // 充值活动
      paymentMethodValue: "", // 支付方式
      rechargeAmount: "", // 充值金额
      giveInput: "20", // 活动赠送金额
      giveAmount: "", // 额外赠送金额
      rechargeRemarks: "", // 余额充值的备注信息

      remark_insurance: "",   // 保险备注
    };
  },
  methods: {
    changeDrawer(val) {
      this.$refs.gradeLevel.changeDrawer(val);
      this.$refs.recharge.changeDrawer(val);
      this.$refs.add_insurance.changeDrawer(val);
      
    },
    onSubmitRecharge(val) {
      this.$emit("submitrecharge", val);
    },
    onSubmitUpgrade(val) {
      this.$emit("submitupgrade", val);
    },
    gradeDialog() {
      this.$emit("gradelevel");
      this.$refs.gradeLevel.changeDrawer(true);
    },
    rechargeDialog(val) {
      this.$emit("gradelrecharge");
      this.$refs.recharge.changeDrawer(val);
    },
    paymentDialog(val) {
      this.$emit("treatmenttemplateparent");
      this.$emit("getpaymentrecrods");
      this.$refs.add_payment.changeDrawer(true, val);
    },
    insuranceDialog(val) {
      // this.$emit("treatmenttemplateparent");
      this.$emit("insurancecompany");
      this.$emit("insuranaccount");
      this.$refs.add_insurance.changeDrawer(true, val);
    },
    changeDrawer(val) {
      this.$refs.gradeLevel.changeDrawer(val);
      this.$refs.recharge.changeDrawer(val);
      this.$refs.add_payment.changeDrawer(val);
    },
    onGetTreatmentTemplateChildren(val) {
      this.$emit("gettreatmenttemplatechildren", val);
    },
    onSavePaymentItem(data) {
      this.$emit("savepaymentitem", data);
    },
    onInsuranceLevel(data) {
      this.$emit("insurancelevel", data);
    },
    onInsuranceSetting(data) {
      this.$emit("insurancesetting", data);
    },
    onSaveInsuranceAccountItem(data) {
      this.$emit("saveinsuranceaccountitem", data);
    },
    onInsuranceBlueValue(data){
      this.$emit("insuranceblurvalue",data);
    },

    onBlur(e) {
      let data = {
        remark_insurance:e.target.value
      }
      this.$emit("remarkblurvalue",data);
    },
  },
};
</script>
<style lang="scss" scoped>
.patient-detail-base {
  .clearfix {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 15px;

    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      line-height: 22px;
      margin-right: 20px;
    }

    .btn {
      margin-right: 5px;
    }
  }
  
  .remark{
    margin: 15px 0;
    display: flex;
    align-items: center;

    .line{
      width: 4px;
      height: 36px;
      background: #d3a47d;
      margin-right: 10px;
    }

    .input{
      width: 100%;
      display: flex;
      align-items: center;
    }
  }
}
</style>
