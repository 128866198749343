<template>
  <div slot="header" class="clearfix">
    <div class="title">{{Data}}</div>
    <!-- <div class="edit">{{Edit}}</div> -->
    <el-button type="primary" size="mini">{{Edit}}</el-button>
  </div>
</template>

<script>
import {
    components
} from '@/helper';

export default {
    components: components([
        'Container'
    ]),
    props: {
        Data:"",
        Edit: ''
    }
}
</script>

<style lang="scss" scoped>
 .clearfix {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #EBEEF5;
        margin-bottom: 15px;

          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5b5b5b;
            line-height: 22px;
            margin-right: 20px;
          }

          .edit{
            color: #669ff5;
            text-decoration: underline;
          }
      }
</style>