<template>
  <FinancialStatisticsLayout>
    <template v-slot:financialStatistics-operation-main>
      <div class="details">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="经营概况" name="first">
            <BusinessOverviewMain
              :statics_data="statics_data"
              :loading="loading"
              :Param="changeParam"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></BusinessOverviewMain>
          </el-tab-pane>
          <el-tab-pane label="财务流水" name="second">
            <ChargeStatisticsMain
              :loading="loading"
              :Data="paymentLogRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
              ref="transatcion"
            ></ChargeStatisticsMain>
          </el-tab-pane>
          <el-tab-pane label="充值纪录" name="third">
            <RechargeRecordMain
              :loading="loading"
              :Data="rechargeRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></RechargeRecordMain>
          </el-tab-pane>
           
          <el-tab-pane label="交易结算" name="fourth">
            <TransactionSettlementMain
              :loading="loading"
              :Data="transactionRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
              ref="transatcion"
            ></TransactionSettlementMain>
          </el-tab-pane>
          
          <el-tab-pane label="绩效记录" name="five">
            <PerformanceMain
              :loading="loading"
              :Data="performanceRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></PerformanceMain>
          </el-tab-pane>
          <el-tab-pane label="看诊记录" name="six">
            <DiagnosisMain
              :loading="loading"
              :Data="diagnosisRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></DiagnosisMain>
          </el-tab-pane>
 
          <el-tab-pane label="病历记录" name="seven">
            <MedicalRecordMain
              :loading="loading"
              :Data="medicalRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></MedicalRecordMain>
          </el-tab-pane>
               
          <el-tab-pane label="预约记录" name="eight">
            <AppointmentMain
              :loading="loading"
              :Data="appointmentRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></AppointmentMain>
          </el-tab-pane>
             
          <el-tab-pane label="检查记录" name="nine">
            <ExaminationMain
              :loading="loading"
              :Data="examinationRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></ExaminationMain>
          </el-tab-pane>
          <el-tab-pane label="治疗记录" name="ten">
            <TreatmentMain
              :loading="loading"
              :Data="treatmentsRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></TreatmentMain>
          </el-tab-pane>
     
          <el-tab-pane label="处方记录" name="eleven">
            <PharmacyMain
              :loading="loading"
              :Data="pharmacyRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></PharmacyMain>
          </el-tab-pane> 
       <!-- <el-tab-pane label="转诊记录" name="twelve">
            <ReferralMain
              :loading="loading"
              :Data="referralRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></ReferralMain>
          </el-tab-pane>  -->
          <el-tab-pane label="转院记录" name="twelve">
            <TransferMain
              :loading="loading"
              :Data="referralRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></TransferMain>
          </el-tab-pane>
          <!-- <el-tab-pane label="商品订单" name="thirteen">
            <OrderMain
              :loading="loading"
              :Data="orderRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></OrderMain>
          </el-tab-pane> -->
          <el-tab-pane label="保险记录" name="fourteen">
            <InsuranceMain
              :loading="loading"
              :Data="insuranceLogRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></InsuranceMain>
          </el-tab-pane>
          <el-tab-pane label="保险汇总" name="fifteen">
            <InsuranceSummaryMain
              :loading="loading"
              :Data="insuranceSummary"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></InsuranceSummaryMain>
          </el-tab-pane>
          <el-tab-pane label="结算记录" name="sixteen">
            <ArrearsMain
              :loading="loading"
              :Data="paymentRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></ArrearsMain>
          </el-tab-pane>
          <!-- <el-tab-pane label="请假条记录" name="sixteen">
            <LeaveMain
              :loading="loading"
              :Data="paymentRecords"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></LeaveMain>
          </el-tab-pane> -->
        </el-tabs>
      </div>
    </template>
  </FinancialStatisticsLayout>
</template>

<script>
import Page from "@/components/Page";

import {
  // eslint-disable-next-line no-unused-vars
  mock,
  components,
  layouts,
} from "@/helper";
import { showLoading, hideLoading } from "../utils/loading";
export default {
  components: {
    ...layouts(["FinancialStatistics"]),
    ...components(
      [
        "BusinessOverview.Main",
        "ChargeStatistics.Main",
        "RechargeRecord.Main",
        "TransactionSettlement.Main",
        "Performance.Main",
        "Diagnosis.Main",
        "MedicalRecord.Main",
        "Appointment.Main",
        "Order.Main",
        "Referral.Main",
        "Transfer.Main",
        "Pharmacy.Main",
        "Treatment.Main",
        "Examination.Main",
        "Insurance.Main",
        "InsuranceSummary.Main",
        "Arrears.Main",
        "Leave.Main",
      ],
      "financialStatistics"
    ),
  },
  data() {
    return {
      loading: true,
      activeName: "first",
      changeParam: {
        action: undefined,
        type: undefined,
        page_no: 1,
        page_size: 20,
        begin_time: undefined,
        end_time: undefined,
        to_payment: undefined,
        account: undefined,
        todayTime: undefined,
        today: undefined,
        week: undefined,
        month: undefined,
        year: undefined,
      },
      basicParam: {
        action: undefined,
        type: undefined,
        page_no: 1,
        page_size: 20,
        begin_time: undefined,
        end_time: undefined,
        to_payment: undefined,
        account: undefined,
        todayTime: undefined,
        weekTime: undefined,
        monthTime: undefined,
        yearTime: undefined,
      },
      transactionRecords: {},
      rechargeRecords: {},
      paymentLogRecords: {},
      performanceRecords: {},
      diagnosisRecords: {},
      medicalRecords: {},
      appointmentRecords: {},
      examinationRecords: {},
      pharmacyRecords: {},
      referralRecords: {},
      orderRecords: {},
      treatmentsRecords: {},
      paymentRecords: {},
      insuranceLogRecords: {},
      insuranceSummary: {},
      statics_data: {},
      getUrl: "",
      dataName: "",
    };
  },
  mounted() {
   // this.getStatisticData();
   let that = this;
    that.formatDate();
		// 模版数据请求
		that.initTemplate();
  },

  methods: {
    	/**
		 * 初始化模版数据
		 *
		 */
		async initTemplate() {
			let me = this;
		  me.dataName = "statics_data";
      me.getUrl = "workbench/Center/getStatisticalData";
			// me.getStorehouseRecords(0); //默认0 获取不带参数
			await me.getListRecords(me.dataName, me.getUrl, me.changeParam);
		},
    //Tab切换时间，切换时请求远程数据
    handleClick(tab, event) {
      let me = this;
      switch (Number(tab.index)) {
        case 0:
          // me.onBussiness();
          me.dataName = "statics_data";
          me.getUrl = "workbench/Center/getStatisticalData";
          break;
        case 1:
          me.dataName = "paymentLogRecords";
          me.getUrl = "financialStatistics/getPaymentLogRecords";

          break;
        case 2:
          me.dataName = "rechargeRecords";
          me.getUrl = "financialStatistics/getRechargeRecords";
          break;
        case 3:
          me.dataName = "transactionRecords";
          me.getUrl = "financialStatistics/getTransactionRecords";
          break;
        case 4:
          me.dataName = "performanceRecords";
          me.getUrl = "financialStatistics/getPerformanceRecords";
          break;
        case 5:
          me.dataName = "diagnosisRecords";
          me.getUrl = "financialStatistics/getDiagnosisRecords";
          break;
        case 6:
          me.dataName = "medicalRecords";
          me.getUrl = "financialStatistics/getMedicalRecords";
          break;
        case 7:
          me.dataName = "appointmentRecords";
          me.getUrl = "financialStatistics/getAppointmentRecords";
          break;
        case 8:
          me.dataName = "examinationRecords";
          me.getUrl = "financialStatistics/getExaminationsRecords";
          break;
        case 9:
          me.dataName = "treatmentsRecords";
          me.getUrl = "financialStatistics/getTreatmentsRecords";
          break;
        case 10:
          me.dataName = "pharmacyRecords";
          me.getUrl = "financialStatistics/getPrescriptionRecords";
          break;
        case 11:
          me.dataName = "referralRecords";
          // me.getUrl = "financialStatistics/getReferralRecords"; //转医生 TODO
          me.getUrl = "financialStatistics/getTransferRecords";
          
          break;
        case 12:
          me.dataName = "orderRecords";
          me.getUrl = "financialStatistics/getOrderRecords";
          break;
        case 13:
          me.dataName = "insuranceLogRecords";
          me.getUrl = "financialStatistics/getInsuranceLogRecords";
          break;
        case 14:
          me.dataName = "insuranceSummary";
          me.getUrl = "financialStatistics/getInsuranceLogRecords";
          break;
        case 15:
          me.dataName = "paymentRecords";
          me.getUrl = "financialStatistics/getPaymentRecords";
          break;
      }
      me.changeParam.page_no = 1;
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    //分页点击时间
    onChangePagination(val) {
      let me = this;
      me.changeParam.page_no = val.current;
      // me.changeParam.total= val.total;
      this.$nextTick(() => {
        this.$refs.transatcion.onChangeSubmit();
      });
    },
    onChangeSubmit(form, isRestore = false) {
      let me = this;
      if (isRestore) {
        me.changeParam.page_no = 1;
      }
      me.loading = true;
      me.formParam(form);
      // me.changeParam.begin_time = form.begin_time;
      // me.changeParam.end_time = form.end_time;
      // me.changeParam.to_payment = form.to_payment;
      // me.changeParam.account = form.account;
      // if (form.begin_time !== undefined) {
      //   me.changeParam.today = undefined;
      //   me.changeParam.week = undefined;
      //   me.changeParam.month = undefined;
      //   me.changeParam.year = undefined;
      // }

      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    //重置
    onSubmitReset() {
      let me = this;
      me.changeParam = me.basicParam;
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onTodayClick() {
      let me = this;
      me.changeParam.today = "today";
      me.changeParam.week = undefined;
      me.changeParam.month = undefined;
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;

      me.formatDate();
      console.log('===', me.dataName, me.getUrl, me.changeParam)
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onWeekClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = "week";
      me.changeParam.month = undefined;
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formatDate();
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onMonthClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = undefined;
      me.changeParam.month = "month";
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formatDate();
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onYearClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = undefined;
      me.changeParam.month = undefined;
      me.changeParam.year = "year";
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formatDate();
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },

    // 【调用事件{A100}】获取主诉管理模版树结构->默认parent=0
    async getListRecords(param, url, data) {
      showLoading();
      let me = this;
      //me.loading = true;
      //me.treeRecords = await mock(url, data);
      try {
        let res = await mock(url, data);

        if (res.status === 200 || res.length > 0) {
          me[param] = res.data;
          if (res.data == null) {
            me[param] = res;
          }
        }
        me.loading = false;
        //console.log("statics_data",me.statics_data);
        
        hideLoading();
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    /**
     * 统计数据
     */
    // async getStatisticData() {
    //   let me = this;
    //   try {
    //     me.statics_data = await mock("workbench/Center/getStatisticalData");
    //   } catch (err) {
    //     me.$message({
    //       type: "warning",
    //       message: err.response.data.message,
    //     });
    //   }
    // },

    formParam(form) {
      let me = this;
      let letters = {};
      Object.getOwnPropertyNames(form).forEach(function (key) {
        if (form[key] !== undefined && form[key] !== null) {
          me.changeParam[key] = form[key];
        }
      });
    },
    formatDate(val) {
      let me = this;
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      me.changeParam.todayTime = `${year}-${month}-${day}`;
      // me.nowDate = `${year}-${month}-${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  // width: 100%;
  margin: 4px 10px 20px;
  // box-shadow: 0 2px 7px 0 #d8d8d8;
}
</style>
