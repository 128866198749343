<template>
  <Container class="appoientment-main-operation hb-layout-horizontal">
    <div style="width: 100%">
      <!-- <div class="list selected">
                <div class="list-item" @click="getLeftChangeOne(1)">部门管理</div>
            </div>
            <div class="list">
                <div class="list-item"  @click="getLeftChangeOne(2)">角色管理</div>
      </div>-->
      <el-menu class="el-menu-vertical-demo" default-active="1">
        <el-menu-item index="1" @click="getLeftChangeOne(1)">
          <span slot="title">商品列表</span>
        </el-menu-item>
        <el-menu-item index="2" @click="getLeftChangeOne(2)">
          <span slot="title">新增商品</span>
        </el-menu-item>
        <!-- <el-menu-item index="9" @click="getLeftChangeOne(9)">
          <span slot="title">管理员管理</span>
        </el-menu-item>
         <el-menu-item index="3" @click="getLeftChangeOne(3)">
          <span slot="title">员工管理</span>
        </el-menu-item>
         <el-menu-item index="4" @click="getLeftChangeOne(4)">
          <span slot="title">班次管理</span>
        </el-menu-item>
         <el-menu-item index="5" @click="getLeftChangeOne(5)">
          <span slot="title">客户来源</span>
        </el-menu-item>
         <el-menu-item index="6" @click="getLeftChangeOne(6)">
          <span slot="title">回访配置</span>
        </el-menu-item>
         <el-menu-item index="7" @click="getLeftChangeOne(7)">
          <span slot="title">支付配置</span>
        </el-menu-item> -->
        <!-- <el-menu-item index="8" @click="getLeftChangeOne(8)">
          <span slot="title">标签管理</span>
        </el-menu-item> -->
         <!-- <el-menu-item index="3" @click="getLeftChangeOne(3)">
          <span slot="title">预约时间管理</span>
        </el-menu-item> -->
      </el-menu>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"])
  },
  data() {
    return {};
  },
  methods: {
    getLeftChangeOne(val) {
      this.$emit("getleftchangeone", val);
    }
  }
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 2px 10px 0px;

  // .list {
  //   width: 100%;
  //   height: 80px;
  //   line-height: 80px;
  //   padding: 0 19px;
  //   align-items: center;
  //   border-bottom: 1px solid #e1e1e1;
  //   font-size: 12px;
  //   text-align: center;

  //   &.selected:nth-child(1) {
  //     box-shadow: 0 2px 7px 0 #d8d8d8;
  //     margin: 0px 0 2px;
  //     background: #92c2f957;
  //   }
  //   &.selected {
  //     box-shadow: 0 2px 7px 0 #d8d8d8;
  //     margin: 2px 0;
  //     background: #92c2f957;
  //   }
  //   &:hover {
  //     background: #92c2f921;
  //     color: #2063b695;
  //   }
  // }

  .el-menu-item, .el-submenu__title{
    height: 60px;
    line-height: 60px;
    text-align: center;
    font-size: 12px;
    border-bottom: 1px solid #e1e1e1;
  }
  .el-menu-item.is-active{
    background: #92c2f957;
    box-shadow: 0 2px 7px 0 #d8d8d8;
    margin: 2px 0;
  }
  .el-menu-item.is-active:nth-child(1){
    margin: 0 0 2px;
  }
}
</style>
