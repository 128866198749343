<template>
  <Container class="patient-detail-base">
    <el-descriptions class="margin-top" :column="4" :size="size" border>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          姓
        </template>
        {{ base.last_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          名
        </template>
        {{ base.first_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-user"></i>
          中文名
        </template>
        {{ base.chinese_name }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-date"></i>
          出生年月
        </template>
        {{ base.birthday }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="item">
            <el-image class="img" src="../../image/xb.png"></el-image>
            <div>性别</div>
          </div>
        </template>
        {{ sexMap[base.sex] }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-mobile-phone"></i>
          手机号
        </template>
        {{ base.mobile }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="item">
            <el-image class="img" src="../../image/nl.png"></el-image>
            <div>年龄</div>
          </div>
        </template>
        {{ base.age }}岁
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <i class="el-icon-location-outline"></i>
          所在地区
        </template>
        {{ base.country }}
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="item">
            <el-image class="img" src="../../image/hy.png"></el-image>
            <div>婚姻</div>
          </div>
        </template>
        {{ mMap[base.is_marriage] }}
      </el-descriptions-item>      
      <el-descriptions-item>
        <template slot="label">
          <div class="item">
            <el-image class="img" src="../../image/sg.png"></el-image>
            <div>身高</div>
          </div>
        </template>
        {{ base.stature }}cm
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="item">
            <el-image class="img" src="../../image/tz.png"></el-image>
            <div>体重</div>
          </div>
        </template>
        {{ base.weight }}kg
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="item">
            <el-image class="img" src="../../image/gms.png"></el-image>
            <div>过敏史</div>
          </div>
        </template>
          <el-tag size="small" v-if="base.allergie !== ''">
          {{ base.allergie }}</el-tag>
      </el-descriptions-item>
      <el-descriptions-item>
        <template slot="label">
          <div class="item">
            <el-image class="img" src="../../image/jzs.png"></el-image>
            <div>家族史</div>
          </div>
        </template>
        <el-tag size="small" v-if="base.family_histories !== ''">
          {{ base.family_histories }}</el-tag
        >
      </el-descriptions-item>
      
    </el-descriptions>
  </Container>
</template>

<script>
import { components, blocks } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    base: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      size:"",
      sexMap: ["保密", "男", "女"],
      mMap: ["保密", "已婚", "未婚"], // 0=保密 1=已婚 2=未婚
    };
  },
};
</script>

<style lang="scss" scoped>
.patient-detail-base {
  .el-row {
    width: 100%;
    border-top: 1px solid #979797;
    border-left: 1px solid #979797;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 1px solid #979797;
    }
  }

  .bg-purple-light {
    border-left: 1px solid #979797;
    border-right: 1px solid #979797;
  }
  .bg-purple {
    background: #ebebeb;
  }

  .grid-content {
    height: 45px;
    line-height: 45px;
    text-align: center;
    font-size: 13px;
  }
  .row-bg {
    padding: 10px 0;
    // background-color: #f9fafc;
  }
}
.item{
  display: flex;
  align-items: center;
  margin-left: -2px;

  .img{
    width: 25px;
    height: 25px;
  }
  div{
    height: 23px;
    line-height: 25px;
  }
}

</style>
