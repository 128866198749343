<template>
  <Container class="patient-detail-base">
    <div class="clearfix">
      <el-row class="row-bg">
        <el-col>
          <div class="title">
            <div class="display">
              <div class="main-span" v-if="last_name !== '' || first_name !== '' ">
                <span class="main-span-text"> 姓名：{{ last_name }} {{ first_name }}</span>
              </div>
              <div class="main-span" v-if="chinese_name !== '' ">
                <span class="main-span-text"> 中文名：{{ chinese_name }}</span>
              </div>
              <div class="main-span">
                <span class="main-span-text"> 性别：{{ sexMap[sex] }}</span>
              </div>
              <div class="main-span">
                <span class="main-span-text"> 年龄：{{ age }}岁</span>
              </div>
              <div class="main-span">
                <span class="main-span-text">
                  病历号：{{ medical_record_no }}</span
                >
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
      <div class="right">
        <el-button
          type="success"
          plain
          size="mini"
          class="btn"
          @click="hanldbindsource()"
          v-if="doctorDatas == null"
          >绑定客户渠道</el-button
        >
        <el-button
          type="success"
          plain
          size="mini"
          class="btn"
          @click="hanldbindsource()"
          v-if="doctorDatas != null"
          >推荐人：{{ doctorDatas.nickname }}</el-button
        >
        <el-button
          type="success"
          plain
          size="mini"
          class="btn"
          @click="hanldbindsource()"
          v-if="departmentDatas != null"
          >部门：{{ departmentDatas.name }}
        </el-button>

        <el-button
          type="primary"
          plain
          size="mini"
          @click="handerbindsource()"
          v-if="bindSourceDatas == null"
          >绑定客户来源</el-button
        >
        <el-button
          type="primary"
          plain
          size="mini"
          @click="handerbindsource()"
          v-if="bindSourceDatas != null"
          >来源渠道：{{ bindSourceDatas.name }}
        </el-button>
      </div>
    </div>
    <el-row type="flex">
      <el-tag
        :key="tag"
        v-for="tag in patientTags"
        closable
        :disable-transitions="false"
        @close="handleDetale(tag)"
      >
        {{ tag }}
      </el-tag>
      <el-input
        class="input-new-tag"
        v-if="inputVisible"
        v-model="inputValue"
        ref="saveTagInput"
        size="small"
        @keyup.enter.native="handleInputConfirm"
        @blur="handleInputConfirm"
      >
      </el-input>
      <el-button v-else class="button-new-tag" size="small" @click="showInput"
        >+ 添加</el-button
      >
    </el-row>

    <BindSource
      ref="bind_source"
      :drawer_title="drawers_add_title"
      :size="size"
      :direction="direction"
      @binddoctorsearch="onBindDoctorSearch"
      :bindSearchDatas="bindSearchDatas"
      @submitbindsource="onSubmitBindSource"
    ></BindSource>

    <BinderSource
      ref="binder_source"
      :drawer_title="drawer_add_title"
      :size="size"
      :direction="direction"
      :sourceDatas="sourceDatas"
      @submitbindsource="onSubmitBindSource"
    ></BinderSource>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
    ...components(["BindSource", "BinderSource"], "patient.Center.Account"), //"BinderSource"->客户渠道，"BindSource"->客户销售
  },
  props: {
    last_name: {
      type: String,
      default: "",
    },
    first_name: {
      type: String,
      default: "",
    },
    chinese_name: {
      type: String,
      default: "",
    },
    sex: {
      type: String | Number,
      default: "",
    },
    age: {
      type: String | Number,
      default: "",
    },
    medical_record_no: {
      type: String,
      default: "",
    },
    bindSearchDatas: {},
    sourceDatas: {},
    doctorDatas: {},
    departmentDatas: {},
    bindSourceDatas: {},
  },
  data() {
    return {
      direction: "rtl",
      size: "30%",
      drawer_add_title: "绑定客户来源",
      drawers_add_title: "绑定客户渠道",
      sexMap: ["保密", "男", "女"],

      patientTags: [],
      inputVisible: false,
      inputValue: "",
    };
  },

  methods: {
    changeDrawer(val) {
      this.$refs.bind_source.changeDrawer(val);
      this.$refs.binder_source.changeDrawer(val);
    },
    hanldbindsource() {
      this.$refs.bind_source.changeDrawer(true);
    },
    handerbindsource() {
      this.$emit("customersource");
      this.$refs.binder_source.changeDrawer(true);
    },
    // 删除标签
    handleDetale(tag) {
      this.patientTags.splice(this.patientTags.indexOf(tag), 1);
    },

    showInput() {
      this.inputVisible = true;
      this.$nextTick((_) => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        this.patientTags.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    onBindDoctorSearch(query) {
      this.$emit("binddoctorsearch", query);
    },
    onSubmitBindSource(data) {
      this.$emit("submitbindsource", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-detail-base {
  .clearfix {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 15px;
    padding-bottom: 15px;
    overflow-x: auto;

    .right{
      white-space: nowrap;
    }

    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      padding: 0 0 0 2px;

      .display {
        display: flex;
        align-items: center;
      }
    }

    .btn {
      margin-right: 5px;
    }
  }
  .main-span {
    margin-right: 14px;

    .main-span-text {
      font-size: 16px;
      color: #5b5b5b;
      white-space: nowrap;
      // display: flex;
      // align-items: flex-start;
      // justify-content: center;
    }
  }
}

.tag {
  margin-right: 14px;
}
.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.bind-button-doctor {
  margin-left: 100px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>
