import axios from 'axios' ;
/**
 * 药品记录列表
 */

// export default () =>{

//     return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/pharmacy/method/get.pharmacy.list/').then(({
//         data
//     }) => data)
//     .then(({
//         data
//     }) => data)
//     .then(({
//         items
//     }) => items.map(({
//         get_pharmacy
//     }) =>({
// 		pharmacy_id:get_pharmacy.pharmacy_id,
//         name:get_pharmacy.name,
// 		purpose:purpose,
// 		usage:usage,
// 		taboo:taboo,
// 		brand_id:brand_id,
// 		repertory:repertory,
// 		supplier_id:supplier_id,
// 		expiration_time:expiration_time,
// 		status:status,
// 		is_delete:is_delete,
// 		create_time:create_time,
// 		update_time:update_time,
		
          
//     })));
// }

export default () =>{

    return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/pharmacy/method/get.pharmacy.list/').then(({
        data
    }) => data)
    .then(({
        data
    }) => data.map(({
		pharmacy_id,
		name,
		purpose,
		usage,
		taboo,
		brand_id,
		repertory,
		supplier_id,
		expiration_time,
		status,
		is_delete,
		create_time,
		update_time,
	})=>({
		pharmacy_id:pharmacy_id,
		name:name,
		purpose:purpose,
		usage:usage,
		taboo:taboo,
		brand_id:brand_id,
		repertory:repertory,
		supplier_id:supplier_id,
		expiration_time:expiration_time,
		status:status,
		is_delete:is_delete,
		create_time:create_time,
		update_time:update_time,
	})));
}