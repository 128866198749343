<template>
  <Container class="order-detail hb-layout-vertical">
    <el-table
        :data="equipment"
        @row-click="handleClickRow"
        style="width: 100%">
      <el-table-column
          prop="name"
          label="药品名称">
      </el-table-column>
      <el-table-column
          prop="purpose"
          label="用途">
      </el-table-column>
      <el-table-column
          prop="birth"
          label="品牌"
          width="120">
        <template slot-scope="scope">
          {{ scope.row.get_brand ? scope.row.get_brand.name : '' }}
        </template>
      </el-table-column>
      <el-table-column
          prop="stock"
          label="库存"
          width="80">
      </el-table-column>
      <el-table-column
          prop="type"
          label="供应商">
        <template slot-scope="scope">
          {{scope.row.get_supplier ? scope.row.get_supplier.name : ''}}
        </template>
      </el-table-column>
      <el-table-column
          fixed="right"
          label="操作"
          width="110">
        <template slot-scope="scope">
          <el-button @click.stop="handleStatus(scope.row)" type="text" size="small">{{statusMap[scope.row.status]}}</el-button>
          <el-button type="text" size="small">领取</el-button>
        </template>
      </el-table-column>
    </el-table>
  </Container>
</template>


<script>
import {
  components,
  layouts
} from '@/helper';

export default {
  components: components([
    'List',
    'Container'
  ]),
  props: {
    equipment: {
      type: Array,
      default: () => {
      }
    }
  },
  data() {
    return {
      //注意这里用于显示时使用   当时 下架状态时 应该显示上架 。 数据库字段： 0下架 1 上架
      statusMap: ['上架', '下架'],
    }
  },
  methods: {
    /**
     * 当行被顶级时触发
     */
    handleClickRow(row) {
      this.$emit('row-click', row)
    },
    handleStatus(row) {
      this.$emit('change-status', row)
    }
  }
}
</script>

<style lang="scss" scoped>
.order-detail {
  width: 99.6%;
  height: 100%;
  margin: 2px 5px 15px 1px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  padding: 15px 15px 35px;
  overflow: auto;

  .el-row {
    border-top: 1px solid #d8d8d8;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 1px solid #d8d8d8;
    }
  }


  .grid-content {
    height: 60px;
    line-height: 60px;
    text-align: center;
  }

  .receive {
    color: #19AE40;
  }

  .operation {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 25px;
  }

}
</style>
