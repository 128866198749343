<template>
  <Container class="statistics" ref="form">
    <div class="clearfix">
      <el-button-group class="margin display">
        <el-button type="primary" @click="todayClick()">今天</el-button>
        <el-button type="primary" @click="weekClick()">本周</el-button>
        <el-button type="primary" @click="monthClick()">本月</el-button>
        <el-button type="primary" @click="yearClick()">今年</el-button>
      </el-button-group>
      <div class="data margin">
        <el-date-picker
          v-model="dataTime"
          type="daterange"
          align="right"
          unlink-panels
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          :default-time="['00:00:00', '23:59:59']"
          :picker-options="pickerOptions"
        >
        </el-date-picker>
      </div>
      <div class="search margin">
        <el-input
          placeholder="请输入用户"
          class="input-with-select search"
          v-model="search"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            :disabled="loading"
            @click="handleFormSubmit(true)"
          >
          </el-button>
        </el-input>
      </div>
      <div class="search margin">
        <el-button icon="el-icon-refresh" @click="handleFormReset">重置</el-button>
      </div>
      <el-tooltip placement="right-end">
        <div slot="content">患者治疗项目记录的流水账</div>
        <i class="el-icon-question" style="font-size:22px;margin-top:13px"></i>
      </el-tooltip>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
  },
  data() {
    return {
      dataValue: "", // 时间选择
      pickerOptions: {
        shortcuts: [
          {
            text: "最近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      dataTime: "",
      search: "", // 搜索
      paramForm: {
        account: undefined,
        begin_time: undefined,
        end_time: undefined,
        time_period: null,
      },
    };
  },
  watch: {
    dataTime: {
      handler(val, oldVal) {
        if (val !== "") {
          this.paramForm.begin_time = val[0].toUTCString();
          this.paramForm.end_time = val[1].toUTCString();
        }
      },
      deep: true,
    },
    search: {
      handler(val, oldVal) {
        this.paramForm.account = val;
      },
      deep: true,
    },
  },
  methods: {
    handleFormSubmit(isRestore = false) {
      let me = this;
      me.$emit("submit", me.paramForm, isRestore);
    },
    handleFormReset() {
      let me = this;
      me.search = "";
      me.dataTime = "";
      me.today = "";
      me.week = "";
      me.month = "";
      me.year = "";
      me.paramForm.begin_time = "";
      me.paramForm.end_time = "";
      me.paramForm.time_period = null;
      me.$emit("submitreset", me.paramForm);
    },
    todayClick(){
        let me = this;
        me.dataTime = "";
        me.$emit("todayclick");
    },
    weekClick(){
        let me = this;
        me.dataTime = "";
        me.$emit("weekclick");
    },
    monthClick(){
        let me = this;
        me.dataTime = "";
        me.$emit("monthclick");
  
    },
    yearClick(){
        let me = this;
        me.dataTime = "";
        me.$emit("yearclick");

    }
  },
};
</script>

<style lang="scss" scoped>
.statistics {
  .clearfix {
    display: flex;
    align-items: center;

    .display{
      display: flex;
      flex-wrap: nowrap;
    }
    .margin{
      margin-right: 10px;
    }

    .data {
      width: 320px;
    }
    .pay {
      width: 135px;
    }
  }
}
.el-date-editor /deep/.el-range-separator {
  width: 20px !important;
}
.el-range-editor.el-input__inner {
  width: 100%;
}
</style>
