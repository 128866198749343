<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header
      @isshowall="isShowAll"
      @filtertext="onFilterText"
      @addtopitem="onAddTopItem"
    ></Header>
    <ContentSetting
      v-if="tabDatas === 1"
      ref="tree_List"
      :treeDatas="treeDatas"
      @editselectitem="onEditSelectItem"
      @delselectitem="onDelSelectItem"
      @appendselectitem="onAppendSelectItem"
    ></ContentSetting>
    <Content
      v-if="tabDatas === 2"
      ref="tree_List"
      :treeDatas="treeDatas"
      @editselectitem="onEditSelectItem"
      @delselectitem="onDelSelectItem"
      @appendselectitem="onAppendSelectItem"
    ></Content>
    <ContentTreatment
      v-if="tabDatas === 3"
      ref="tree_List"
      :treeDatas="treeDatas"
      @editselectitem="onEditSelectItem"
      @delselectitem="onDelSelectItem"
      @appendselectitem="onAppendSelectItem"
    ></ContentTreatment>
    <ContentDiagnosis
      v-if="tabDatas === 4"
      ref="tree_List"
      :treeDatas="treeDatas"
      @editselectitem="onEditSelectItem"
      @delselectitem="onDelSelectItem"
      @appendselectitem="onAppendSelectItem"
    ></ContentDiagnosis>
    <ContentClinical
      v-if="tabDatas === 5"
      ref="tree_List"
      :treeDatas="treeDatas"
      @editselectitem="onEditSelectItem"
      @delselectitem="onDelSelectItem"
      @appendselectitem="onAppendSelectItem"
    ></ContentClinical>
    <ContentComplained
      v-if="tabDatas === 6"
      ref="tree_List"
      :treeDatas="treeDatas"
      @editselectitem="onEditSelectItem"
      @delselectitem="onDelSelectItem"
      @appendselectitem="onAppendSelectItem"
    ></ContentComplained>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      [
        "Header",
        "Content",
        "ContentTreatment",
        "ContentDiagnosis",
        "ContentClinical",
        "ContentComplained",
        "ContentSetting",
      ],
      "configuration.Diagnosis.Center"
    ),

    ...components(["Container"]),
  },

  props: {
    treeDatas: {},
    tabData: 0,
  },

  data() {
    return {
      tabDatas: 0,
    };
  },
  watch: {
    tabData: function (val) {
      this.tabDatas = val;
      // console.log(this.tabDatas);
    },
  },
  methods: {
    isShowAll(val) {
      this.$refs.tree_List.isShowAlls(val);
    },
    onFilterText(val) {
      this.$refs.tree_List.onFilterText(val);
    },
    onEditSelectItem(itemData, treeData) {
      this.$emit("editselectitem", itemData, treeData);
    },
    onDelSelectItem(data) {
      this.$emit("delselectitem", data);
    },
    onAppendSelectItem(itemData) {
      this.$emit("appendselectitem", itemData);
    },
    onAddTopItem() {
      this.$emit("addtopitem");
    },
  },
};
</script>

<style lang="scss" scoped>

</style>
