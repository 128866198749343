<template>
<Container class="patient-detail-doctor">
    <el-table :data="tableData" highlight-current-row style="width: 100%">
        <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column prop="pay_amount" align="left" label="消费总金额" min-width="50"></el-table-column>
        <el-table-column prop="use_money" align="left" label="余额支付" min-width="50"></el-table-column>
        <el-table-column prop="use_level" align="left" label="会员折扣" min-width="50"></el-table-column>
        <el-table-column prop="is_insurance_payments" align="left" label="保险抵扣" min-width="50"></el-table-column>
        <el-table-column prop="total_amount" align="left" label="实际支付" min-width="60"></el-table-column>
        <!-- 新增加的结束时间 -->
        <el-table-column prop="payment_time" align="left" label="缴费时间" width="160"></el-table-column>
    </el-table>
</Container>
</template>
<script>
import {
    components
} from "@/helper";

export default {
    data() {
        return {
            currentRow: null,
        };
    },
    props: {
        tableData: {
            type: Array,
            default: () => [],
        },
    },
    components: components(["Container"]),
    methods: {
        setCurrent(row) {
            this.$refs.singleTable.setCurrentRow(row);
        },
        handleCurrentChange(val) {
            this.currentRow = val;
            this.$emit("currentchange", val);
            //console.log("看诊记录点击时间信息：" + JSON.stringify(val));
        },
    },
};
</script>

<style scoped></style>
