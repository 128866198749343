<template>
  <Container class="appoientment-main-operation hb-layout-horizontal">
    <div style="width: 100%">
      <Container class="see-patient-history-list-item hb-layout-vertical">
        <Container class="body">
          <Container class="inner hb-layout-horizontal">
            <el-row type="flex" align="middle">
                <el-col :span="6">
                  <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  部门</div>
                </el-col>
                <el-col :span="18">
                  <div class="grid-content bg-purple-light">
                    <el-select
                      v-model="selectData.department_id"
                      filterable
                      style="width: 100%"
                      placeholder="部门类型"
                    >
                      <el-option
                        v-for="item in departmentData"
                        :key="item.department_id"
                        :label="item.name"
                        :value="item.department_id"
                      >
                      </el-option>
                    </el-select>
                  </div>
                </el-col>
              </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">
                  <span style="color: #ff0000; font-weight: bold">*</span> 班次名称
                </div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input v-model="selectData.name" placeholder="班次名称"></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">英文名称</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.english_name"
                    placeholder="请输入班次英文名称"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">开始时间</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-time-select
                    v-model="selectData.start_time"
                    :picker-options="{
                      start: '01:00',
                      step: '00:30',
                      end: '24:00',
                    }"
                    placeholder="选择开始时间"
                  >
                  </el-time-select>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">结束时间</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-time-select
                    v-model="selectData.end_time"
                    :picker-options="{
                      start: '01:00',
                      step: '00:30',
                      end: '24:00',
                    }"
                    placeholder="选择结束时间"
                  >
                  </el-time-select>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple grid-textarea">备注</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="selectData.remind"
                    placeholder="请输入备注"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">显示</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light" style="padding-left: 15px">
                  <el-radio-group v-model="selectData.is_delete">
                    <el-radio :label="0">显示</el-radio>
                    <el-radio :label="1">不显示</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">按钮颜色</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-select
                    v-model="selectData.type"
                    filterable
                    style="width: 100%"
                    placeholder="请选择按钮颜色"
                  >
                    <el-option
                      v-for="item in typeColorData"
                      :key="item.type"
                      :label="item.label"
                      :value="item.type"
                    >
                    </el-option>
                  </el-select>
                </div>
              </el-col>
            </el-row>
          </Container>
          <div class="btn">
            <el-button type="success" class="edit-buttom" @click="onSubmitItemData"
              >提交</el-button
            >
          </div>
        </Container>
      </Container>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  props: {
    departmentData:{},
    treeDatas: {},
  },
  watch: {
    treeDatas: function (val) {
      if (val.length > 0) {
        this.treeSelectData = this.getTreeData(this.arraytotree(val)); //去除最后children为空
      }
    },
  },
  data() {
    return {
      selectData: [],
      Data: {
        english_name: "",
        name: "",
        person: "",
        remind: "",
        mobile: "",
        start_time: "",
        end_time: "",
        department_id:""
      },
      name: "",

      typeColorData: [
        {
          type: 'primary',
          label: '蓝色'
        },{
          type: 'success',
          label: '绿色'
        },{
          type: 'info',
          label: '灰色'
        },{
          type: 'warning',
          label: '黄色'
        },{
          type: 'danger',
          label: '红色'
        }
      ]
    };
  },
  methods: {
    handleSelectItemss(itemData) {
      this.selectData = itemData;
    },

    onSelectData(itemData, treeData) {
      this.selectData = itemData;
      this.parentValue = itemData.parent_id;

      //console.log("12", this.selectData);
    },
    addtopitem() {
      this.selectData = {};
      // console.log("11212122", this.Datas,this.selectData);
    },
    onSubmitItemData() {
      if (this.selectData.name == "" || this.selectData.name == null) {
        this.$alert("请输入班次名称");

        return;
       } else if (
        this.selectData.department_id == "" ||
        this.selectData.department_id == null
      ) {
        this.$alert("请选择部门");

        return;
      }
      let data = {
        classes_id: this.selectData.classes_id,
        name: this.selectData.name,
        english_name: this.selectData.english_name,
        start_time: this.selectData.start_time,
        end_time: this.selectData.end_time,
        is_delete: this.selectData.is_delete,
        type: this.selectData.type,
        remind: this.selectData.remind,
        department_id:this.selectData.department_id,
      };
      //console.log("1212",data);
      this.$emit("submititemdata", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 10px 2px;
}

.see-patient-history-list-item {
  width: 100%;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }

      .grid-textarea {
        height: 96px;
        line-height: 96px;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;

    .edit-buttom {
      margin-top: 30px;
    }
  }
}
</style>
