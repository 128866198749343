<template>
  <Container class="statistics">
    <div class="text item">
      <el-table
        style="width: 100%"
        height="calc(100vh - 265px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
       <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="appointment_record_no"
          label="预约号"
          min-width="240"
          align="left"
        ></el-table-column>
        <el-table-column
          fixed
          prop="medical_record_no"
          label="病例号"
          min-width="180"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="patient_name"
          label="用户"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column label="性别" width="100" align="left">
          <template slot-scope="scope">
            {{ sexMap[scope.row.patient_gender] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="patient_age"
          label="年龄"
          width="100"
          align="left"
        >
        </el-table-column>
         <el-table-column
          label="诊断"
          min-width="150"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.diagnosis }}</div>
          </template>
        </el-table-column>
         <el-table-column
          label="既往病史及手术史"
          min-width="140"
          align="left"
        >
          <template slot-scope="scope">
            <div>{{ scope.row.history }}</div>
          </template>
        </el-table-column>

        <el-table-column
          prop="recommend_frequency"
          label="建议治疗时间"
          width="150"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="recommend_treatment"
          label="建议治疗方案 "
          width="150"
          align="left"
        >
        </el-table-column>


        <el-table-column
          prop="record_date"
          label="转院信日期 "
          width="160"
          align="left"
        >
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="160"
          align="left"
        ></el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
    tableData: {
      default: () => [],
    },
  },
  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {
      sexMap:['保密', '男', '女'],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;
}
</style>
