<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header
      @isshowall="isShowAll"
      @filtertext="onFilterText"
      @addtopitem="onAddTopItem"
    ></Header>
    <Content
      ref="tree_List"
      :treeDatas="treeDatas"
      @editselectitem="onEditSelectItem"
      @delselectitem="onDelSelectItem"
      @appendselectitem="onAppendSelectItem"
    ></Content>
   

  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      [
        "Header",
        "Content",
      ],
      "configuration.MedicalHistory.Center"
    ),

    ...components(["Container"]),
  },

  props: {
    treeDatas: {},
    tabData: 0,
  },

  data() {
    return {
      tabDatas: 0,
    };
  },
  watch: {
    tabData: function (val) {
      this.tabDatas = val;
     // console.log(this.tabDatas);
    },
  },
  methods: {
    isShowAll(val) {
      this.$refs.tree_List.isShowAlls(val);
    },
    onFilterText(val) {
      this.$refs.tree_List.onFilterText(val);
    },
    onEditSelectItem(itemData, treeData) {
       
      this.$emit("editselectitem", itemData, treeData);
    },
    onDelSelectItem(data) {
      this.$emit("delselectitem", data);
    },
    onAppendSelectItem(itemData) {
      this.$emit("appendselectitem", itemData);
    },
    onAddTopItem() {
      this.$emit("addtopitem");
    },
  },
};
</script>

<style lang="scss" scoped>
// .see-patient-history-list-item {
//   width: 98%;
//   padding: 0 19px;
//   height: calc(100vh - 140px);
//   box-shadow: 0 2px 7px 0 #d8d8d8;
//   margin: 2px auto 10px;
// }
</style>
