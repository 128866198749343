<template>
  <SeeLayout>
    <template v-slot:patient-search>
      <div class="search-left">
        <el-input
          placeholder="请输入内容"
          class="input-with-select search"
          v-model="patientsParam.real_name"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            v-loading.fullscreen.lock="loading.patients"
            @click="patientsSearch()"
          ></el-button>
        </el-input>
      </div>
    </template>
    <template v-slot:patient-list>
      <ListPatient
        ref="patientlist"
        class="hb-layout-fit-sized"
        :data="patients"
        @changeStatus="changeStatus"
        @patientitemselect="onPatientItemSelect"
      ></ListPatient>
    </template>
    <template v-slot:see-operation-main>
      <OperationMain
        @starttreatment="onStartTreatment"
        @endtreatment="onEndTreatment"
        @referral="onReferral"
        @save="onSave"
        :type="patient ? patient.type : ''"
        :medical_basic="medical_basic"
      ></OperationMain>
    </template>
    <template v-slot:see-detail>
      <Detail
        ref="detailRef"
        :item="diagnosis"
        :basic="basic"
        :treatments_template="treatments_template"
        :pharmacy_template="pharmacy_template"
        :inspection_template="inspection_template"
        :doctors_advice="doctors_advice"
        :prescription="prescription"
        @clickPrescriptionType="clickPrescriptionType"
      ></Detail>
    </template>
    <template v-slot:patient-history-list>
      <ListPatientHistory
        @datechange="onListPatientHistoryDateChange"
        class="hb-layout-fit-sized"
        :data="histories"
        :diagnosis_record_history="diagnosis_record_history"
        :prescription_history="prescription_history"
        @historyitemselect="onHistoryItemSelect"
      ></ListPatientHistory>
    </template>
  </SeeLayout>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  mock,
  components,
  layouts,
} from "@/helper";

export default {
  components: {
    ...layouts(["See"]),
    ...components(
      ["operation.Main", "Detail", "list.Patient", "list.patient.History"],
      "see"
    ),
  },

  data() {
    return {
      histories: [],
      patients: [], //预约列表
      patient: null, //选中的预约记录
      loading: {
        patients: false, //预约列表loading
      },
      patientsParam: {
        //预约列表参数
        real_name: undefined,
        diagnosis_status: "0",
        appointment_date: "",
        page_no: 1,
        page_size: 10,
      },
      ressave: "12",
      input: "",
      diagnosis: {}, //看诊记录
      prescription: {
        type: 0,
        list: [{}],
      },
      basic: {
        //基本检查
        clinical_department: [], //科室模版
        diagnosis_template: [], //诊断模版
        complained_template: [], //主诉模版
      },
      treatments_template: [], //治疗项目
      inspection_template: [], //检查项目
      pharmacy_template: [], //药品记录
      doctors_advice: [], //嘱咐模版
      diagnosis_record_history: [], //历史看诊记录
      prescription_history: [], //处方开药的记录
      history_param: {
        //用于历史筛选
        diagnosis_record_no: undefined,
        create_time: undefined,
        user_id: undefined,
      },
      medical_basic: {}, //病历简要信息
    };
  },

  async mounted() {
    let that = this;

    this.formatDate();
    // 获取患者列表
    await that.getPatientsList();

    // 模版数据请求
    await this.initTemplate();

    if (that.patients.length) {
      that.$refs.patientlist.select(that.patients[0]);

      // 选择药品类型  1 为默认值
      that.clickPrescriptionType(1);
    }

    // let me = this, patients = await mock('see/patient/list');

    // me.patients = patients;
    //
    // if (patients.length) {
    //
    // 	me.$refs.patientlist.select(patients[0]);
    // }
  },
  methods: {
    /**
     * 搜索患者
     */
    patientsSearch() {
      this.loading.patients = true;
      this.getPatientsList();
    },

    /**
     * 获取患者列表
     */
    async getPatientsList() {
      this.patients = await mock(
        "see/Left/getAppointmentBasicList",
        this.patientsParam
      );

      this.loading.patients = false;
    },

    /**
     * 患者列表 筛选状态
     */
    changeStatus(e) {
      this.patientsParam.diagnosis_status = e;
      this.loading.patients = true;
      this.getPatientsList();
    },

    /**
     * 保存看诊记录
     */
    async onSave() {
      let me = this;
      me.$confirm("确认保存看诊信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // 这里获取看着需要保存的数据
          let seeData = me.$refs.detailRef.getSeeData();
          me.loading.patients = true;
          me.ressave = await mock("see/diagnosis/setDiagnosisRecord", seeData).then(
            (res) => {
              me.getPatientsList();
              me.$message({
                type: "success",
                message: "保存成功!",
              });
            }
          );
        })
        .catch();
    },

    /**
     * 选择药品类型时出发
     */
    async clickPrescriptionType(e) {
      //点击药品类型
      // 药品记录
      this.pharmacy_template = await mock("see/diagnosis/getPharmacyList", {
        pharmacy_category_id: e,
      });
    },

    /**
     * 初始化处方开药记录时出发
     */
    async initPrescription(e) {
      //点击药品类型
      // 药品记录
      return await mock("see/diagnosis/getPharmacyList", { pharmacy_category_id: e });
    },

    onReferral() {
    },

    /**
     * 开始接诊
     *
     */
    onStartTreatment() {
      let me = this;
      me.$confirm("确认开始接诊吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // 这里获取看着需要保存的数据
          me.ressave = await mock(
            "see/diagnosis/setDiagnosisStartTime",
            me.history_param
          ).then((res) => {
            if (res) {
              this.loading.patients = true;
              this.getPatientsList();
              me.$message({
                type: "success",
                message: "操作成功!",
              });
            }
          });
        })
        .catch();
    },

    /**
     * 结束接诊
     *
     */
    onEndTreatment() {
      let me = this;
      me.$confirm("确认结束接诊吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          // 这里获取看着需要保存的数据
          me.ressave = await mock(
            "see/diagnosis/setDiagnosisEndTime",
            me.history_param
          ).then((res) => {
            if (res) {
              this.loading.patients = true;
              this.getPatientsList();
              me.$message({
                type: "success",
                message: "操作成功!",
              });
            }
          });
        })
        .catch();
    },

    async onListPatientHistoryDateChange(date) {
      let me = this;

      me.history_param.create_time = date;

      // 历史用药
      me.prescription_history = await mock(
        "see/history/getHistoryPrescriptionList",
        me.history_param
      );

      // 历史看诊
      me.diagnosis_record_history = await mock(
        "see/history/getDiagnosisRecordBasicList",
        me.history_param
      );

      // let me = this,
      // 	{
      // 		patient
      // 	} = me;
      //
      //   me.histories = await mock('see/diagnosis/list', patient, date);
    },

    /**
     * 初始化模版数据
     *
     */
    async initTemplate() {
      let me = this;
      // 诊断列表
      me.basic.diagnosis_template = await mock("see/diagnosis/getDiagnosisTemplateList");

      // 科室分类
      me.basic.clinical_department = await mock(
        "see/diagnosis/getClinicalDepartmentList"
      );

      //注意 有的地方数据联动，所以现在写的是死数据

      // 主诉模板表
      me.basic.complained_template = await mock(
        "see/diagnosis/getComplainedTemplateList",
        { clinical_department_id: 1 }
      );

      // 治疗项目模版
      me.treatments_template = await mock("see/diagnosis/getTreatmentsTemplateList", {
        clinical_department_id: 1,
      });

      // 检查项目模板
      me.inspection_template = await mock("see/diagnosis/getInspectionTemplateList");

      // 嘱咐模版
      me.doctors_advice = await mock("see/diagnosis/getDoctorsAdviceTemplateList");
    },

    /**
     * 用户获取看着记录的，  两个用法，一种点击预约记录，一种点击历史记录
     *
     */
    async getDiagnosisRecord(data) {
      let me = this;

      // 看诊记录
      me.diagnosis = await mock("see/diagnosis/getDiagnosisRecord", data).then(
        async (res) => {
          let val = res.get_prescription;

          //遍历处方开药记录，绑定 药品相应的模版数据
          if (val && val.length > 0) {
            for (let item of val) {
              let index = val.indexOf(item);

              let pharmacy_template = await mock("see/diagnosis/getPharmacyList", {
                pharmacy_category_id: item.type,
              });

              item.pharmacy_template = pharmacy_template;
              if (pharmacy_template.length > 0) {
                let pharmacy_template_index = pharmacy_template.findIndex((p_item) => {
                  return p_item.pharmacy_id == item.pharmacy_id;
                });

                if (pharmacy_template_index > -1) {
                  item.dosage_template =
                    pharmacy_template[pharmacy_template_index].dosage;
                  item.remark_template =
                    pharmacy_template[pharmacy_template_index].remarks;
                }
              }
            }
          }
          res.get_prescription = val;

          me.history_param.diagnosis_record_no = res.diagnosis_record_no;
          // 历史用药
          me.prescription_history = await mock(
            "see/history/getHistoryPrescriptionList",
            me.history_param
          );

          return res;
        }
      );
    },

    /**
     * 点击历史记录时出发 获取看诊记录
     * @param history
     * @returns {Promise<void>}
     */
    async onHistoryItemSelect(history) {
      let me = this;
      // 获取看诊记录
      await me.getDiagnosisRecord({ diagnosis_record_id: history.diagnosis_record_id });
    },

    /**
     * 点击预约时出发 获取看诊记录
     * @param patient
     * @returns {Promise<void>}
     */
    async onPatientItemSelect(patient) {

      let me = this;

      me.patient = patient;

      // 获取看诊记录
      me.getDiagnosisRecord({ appointment_record_no: patient.appointment_record_no });

      me.history_param.user_id = patient.user_id;

      // 历史看诊
      me.diagnosis_record_history = await mock(
        "see/history/getDiagnosisRecordBasicList",
        me.history_param
      );

      // 获取病历信息【简要】
      me.medical_basic = await mock("see/diagnosis/getMedicalBasicRecord", {
        user_id: patient.user_id,
      });

      // me.histories = await mock('see/diagnosis/list' , patient) ;

      // me.diagnosis = await mock('see/diagnosis', patient);
      // 看诊历史记录
      // me.histories = await mock('see/diagnosis/list' , patient) ;
      // 诊断模版
      // me.diagnosis_template=await mock("see/patient/listdt",patient);


      // 药品记录
      // me.pharmacy=await mock("see/patient/listpharmacy",patient);
    },
     formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.patientsParam.appointment_date = `${year}-${month}-${day}`;

    },
  },
};
</script>
<style lang="scss" scoped>
.search-left {
  margin: 4px 2px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  height: 60px;
  line-height: 60px;
}

.search {
  width: 295px;
  padding: 0 10px;

  > .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 160px;
    margin-right: 10px;
  }
}
</style>
