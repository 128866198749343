<template>
  <Container class="appoientment-detail hb-layout-vertical">
    <div class="screen">
      <div class="search" style="margin-left: 10px">
        <el-input
          v-model="search"
          placeholder="搜索角色名称"
          class="input-with-select search"
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <div>
      <el-table
        ref="list"
        :data="listRecordsData.filter(filter)"
        highlight-current-row
        @current-change="appointmentDetailChange"
        stripe
        height="calc(100vh - 290px)"
        style="width: 100%"
      >
        <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="name"
          label="角色名称"
          min-width="200"
          align="left"
        >
        </el-table-column>
        <el-table-column
          prop="english_name"
          label="角色英文名称"
          min-width="200"
          align="left"
        >
        </el-table-column>
         <el-table-column prop="reducible" label="可预约" min-width="100" align="left">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.reducible"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="2"
              :inactive-value="1"
              @change="handChange(scope.row.user_roles_id, scope.row.reducible)"
            >
            </el-switch>
          </template>
        </el-table-column>

          <el-table-column prop="see_status" label="看诊权限" min-width="100" align="left">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.see_status"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="2"
              :inactive-value="1"
              @change="handSeeChange(scope.row.user_roles_id, scope.row.see_status)"
            >
            </el-switch>
          </template>
        </el-table-column>
       
        <el-table-column prop="remind" label="备注" min-width="300" align="left">
        </el-table-column>
        <!-- <el-table-column prop="reducible" label="可预约" min-width="100" align="left">
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.reducible"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="handChange(scope.row.user_roles_id, scope.row.reducible)"
            >
            </el-switch>
          </template>
        </el-table-column> -->
         <el-table-column label="排班" min-width="80" align="left">
          <template slot-scope="scope">
            {{ schedulingMap[scope.row.scheduling_status] }}
          </template>
        </el-table-column>
         <el-table-column label="显示" min-width="80" align="left">
          <template slot-scope="scope">
            {{ statusMap[scope.row.is_delete] }}
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="80" align="left">
          <template slot-scope="scope">
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                操作<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  icon="el-icon-plus"
                  @click.native="handleClick(scope.row)"
                  >编辑</el-dropdown-item
                >
                <el-dropdown-item
                  icon="el-icon-delete"
                  @click.native="handleDelClick(scope.row.user_roles_id)"
                  >删除</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components, layouts } from "@/helper";

export default {
  components: {
    ...components(["List", "Container", "appointment.list.patient.Item"]),
  },
  props: {
    listRecordsData: {
      type: Array,
      default: () => [],
    },
    doctors: {},
  },

  data() {
    return {
      departmentValue: "",
      search: "",
      type: null,
      statusMap: ['显示', '不显示'],
      schedulingMap:['要排班','不要排班']
    };
  },
  watch: {
    search: function (val, oldVal) {
      this.tableData = this.otableData.filter((item) => ~item.name.indexOf(val));
    },
  },
  methods: {
    handleClick(itemdata) {
      this.$emit("handleselectitem", itemdata);
    },
    handleDelClick(itemdata) {
      let data = {
        user_roles_id: itemdata,
      };
     // console.log("1212", data);
      this.$emit("handledelselectitem", data);
    },
    filter(data) {
      let result = false,
        { search, type } = this;

      if (search) {
        result = data.name.toLowerCase().includes(search.toLowerCase());
      } else {
        result = true;
      }

      if (type !== null) {
        result = data.type === type;
      }

      return result;
    },

    appointmentDetailChange(val) {
      // console.log("预约信息 fasasu", val);
      this.$emit("appointmentdetailchange", val);
    },
    filterTag(value, row) {
      return row.type === value;
    },
    handChange(No, val) {
      let data = {
        user_roles_id: No,
        reducible: val,
        change:"roles"
      };
      //this.diagnosisValue =
      this.$emit("handablechange", data);
      //console.log("ssd", appointNo,val);
    },
     handSeeChange(No, val) {
      let data = {
        user_roles_id: No,
        see_status: val,
        change:"roles"
      };
      //this.diagnosisValue =
      this.$emit("handablechange", data);
   
    },
    
  },
};
</script>

<style lang="scss" scoped>
.appoientment-detail {
  width: 99%;
  height: calc(100% - 90px);
  margin: 2px auto 15px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  padding: 15px 15px 35px;
  // overflow: auto;

  .screen {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .el-row {
    border-top: 1px solid #d8d8d8;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 1px solid #d8d8d8;
    }
  }

  .grid-content {
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
}

.el-button-group {
  display: flex;
  vertical-align: middle;
  flex-wrap: nowrap;
}
</style>
