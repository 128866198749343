/**
 * 获取患者看诊历史 API
 */

 import axios from 'axios';
 import qs from 'qs';
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/patient/method/get.diagnosis.record.item';

 export default (data) => {
 
    return axios.get(url, {
            params: {
                diagnosis_record_no: data
            }
        }).then(({
            data
        }) => data)
        .then(({
            data
        }) => data);
}