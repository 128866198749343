<template>
  <div style="height: 100%; overflow: auto">
    <Container class="vital-signs diagnosis">
      <div class="patient-detail-info-project">
        <div v-if="visitPathDatas !== '' ">
          <ExecutiveHistory :visitPathDatas="visitPathDatas"></ExecutiveHistory>
        </div>
        <div class="text item return-visit" v-if="visitPathDatas == '' ">
          <el-empty description="暂无数据" :image-size="100"></el-empty>
        </div>
      </div>
			
		</Container>
  </div>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components([
      "ExecutiveHistory"
    ], "implement.Right"),
    ...components([
        "Container"
    ]),
  },
  props:{
    visitPathDatas:{},
  },
  watch: {
    visitPathDatas(val) {
      let me = this;
    },
  },
  
  data() {
    return {
      
    };
  },

  methods: {
    
  },
};
</script>

<style lang="scss" scoped>
.patient-detail-info-project{
  width: 100%;
  padding: 20px 15px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 3px 0 10px 2px;

}

</style>
