 /**
  * 商品列表获取所有商品
  */
 
 import axios from 'axios';
 import qs from 'qs';
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v1/goods/method/get.goods.admin.list';
 //let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment_record/method/get.doctor.list/';
 export default (data) => {
 

    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data);
        // .then(({data}) => data);
}

