<template>
  <el-card shadow="always">
    <el-row type="flex" class="display">
      <el-col :span="24">
        <el-col :span="5">
          <el-button type="text" class="nameChange" @click="todayEvent" v-if="changeNum==1">今日</el-button>
          <el-button type="text" class="name" @click="todayEvent" v-if="changeNum!=1">今日</el-button>
        </el-col>
        <el-col :span="5">
          <el-button type="text" class="nameChange" @click="sevenEvent" v-if="changeNum==2">7日</el-button>
          <el-button type="text" class="name" @click="sevenEvent" v-if="changeNum!=2">7日</el-button>
        </el-col>
        <el-col :span="5">
          <el-button type="text" class="nameChange" @click="thirtyEvent" v-if="changeNum==3">30日</el-button>
          <el-button type="text" class="name" @click="thirtyEvent" v-if="changeNum!=3">30日</el-button>
        </el-col>
      </el-col>
      <el-col :span="9">
         <el-dropdown @command="changeStatus">
          <span class="el-dropdown-link">
            接诊状态<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) in options"
              :command="item.value"
              :key="index"
              >{{ item.label }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    loading: {},
  },
  data() {
    return {
      data: {
        day: "yyyy-MM-dd",
      },
      real_name: "",
      options: [
        {
          value: "all",
          label: "全部",
        },
        {
          value: 1,
          label: "待诊",
        },
        {
          value: 2,
          label: "已诊",
        }
        
      ],
      dataValue: "",
      changeNum: 1,
    };
  },
  methods: {
    /**
     * 患者列表 筛选状态
     */
    // changeStatus(e) {
    //   this.patientsParam.diagnosis_status = e;
    //   this.loading.patients = true;
    //   this.getPatientsList();
    // },
    changeStatus(e) {
      // console.log("sreccn 1");
       this.$emit("changestatus", e);
    },
    todayEvent() {
      this.changeNum=1;
      this.$emit("todayevent");
    },
    sevenEvent() {
      this.changeNum=2;
      this.$emit("sevenevent");
    },
    thirtyEvent() {
      this.changeNum=3;
      this.$emit("thirtyevent");
    },
    handleEventClick(e) {
      this.$emit("calendarevent", e);
    },
  },
};
</script>
<style lang="scss" scoped>
.display{
  display: flex;
  align-items: center;
}
.name{
  font-size: 13px;
  color: #606266;
  padding: 20px 5px;
}
.nameChange{
  font-size: 13px;
  padding: 20px 5px;
  border-bottom: 3px solid #0eb353;
  color: #0eb353;
  border-radius: 0px;
  font-weight: bold;
}

// .name:focus{
//   border: 1px solid #84c4bc;
//   background: #e6fdfbb6;
//   color: #5ba097;
// }

/deep/.el-card__body{
  padding: 0px 10px;
}
.el-calendar{
  width: 500px;
}
.el-calendar /deep/.el-calendar-day {
  height: 30px!important;
}
</style>

