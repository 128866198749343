<template>
  <Container class="appoientment-main-operation hb-layout-horizontal">
    <div v-for="({ name, classes_id, type,getDepartment }, index) in classesDatas" :key="index">

      <el-button :type="type" plain @click="planchange(classes_id,getDepartment.department_id)">
        [{{getDepartment.name}}]--{{ name }}
        <div v-for="{ isd, icd } in todayDatas" :key="icd">
          <i class="el-icon-arrow-down el-icon--right" v-if="classes_id == icd"></i>
        </div>
      </el-button>

      <!-- <el-button v-if="index == 1" type="success" plain @click="planchange(classes_id)">
        {{ name }}//{{index}}
        <div v-for="{ isd, icd } in todayDatas" :key="icd">
          <i class="el-icon-arrow-down el-icon--right" v-if="classes_id == icd"></i>
        </div>
      </el-button>

      <el-button v-if="index == 2" type="warning" plain @click="planchange(classes_id)"
        >{{ name }}////{{index}}
        <div v-for="{ isd, icd } in todayDatas" :key="icd">
          <i class="el-icon-arrow-down el-icon--right" v-if="classes_id == icd"></i>
        </div>
      </el-button> -->
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  props: {
    classesDatas: {},
    todayDatas: {},
  },
  data() {
    return {};
  },
  methods: {
    planchange(val,departmentValue) {
        this.$emit("classesplanchange",val,departmentValue);
       // console.log("sd", val,departmentValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 100%;
  padding: 14px 30px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 10px 2px;
  display: flex;
  flex-direction: column;
}
.el-button {
  width: 100%;
  margin: 13px 0;
}
</style>
