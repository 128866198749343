<template>
  <Container class="statistics">
    <div class="box-card">
        <div class="card-list-item">
					<div class="item-title">收费</div>
					<div class="item-content">
            <el-image class="item-img" src="../../image/work_2.png"></el-image>
            <div>
              <div v-if="statics_data.total_transaction==0" >0.00 元</div>
              <div v-if="statics_data && statics_data.total_transaction>0">{{ statics_data.total_transaction}} 元</div>

              <div class="text">现金收入</div>
            </div>
          </div>
				</div>
        <div class="card-list-item">
					<div class="item-title">充值</div>
          <div class="content">
            <div class="item-content" style="padding:38px 0 39px">
              <el-image class="item-img" src="../../image/work_3.png"></el-image>
              <div>
                 <div v-if="statics_data.total_recharge==0" >0.00 元</div>
                     <div v-if="statics_data && statics_data.total_recharge>0">{{ statics_data.total_recharge}} 元</div>
                <div class="text">会员充值</div>
              </div>
            </div>
            <div class="line"></div>
            <div class="item-content" style="padding:38px 0 39px">
              <el-image class="item-img" src="../../image/work_4.png"></el-image>
              <div>
                <div>{{statics_data.count_recharge}} 人</div>
                <div class="text">充值人数</div>
              </div>
            </div>
          </div>
				</div>
        <div class="card-list-item">
					<div class="item-title">保险金额</div>
					<div class="item-content">
            <el-image class="item-img" src="../../image/work_5.png"></el-image>
            <div>
              <div v-if="statics_data.total_insurance==0" >0.00 元</div>
              <div v-if="statics_data && statics_data.total_insurance>0">{{ statics_data.total_insurance}} 元</div>

              <div class="text">保险抵扣</div>
            </div>
          </div>
				</div>
    </div>
  </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props:{
	statics_data:{}
  },
  data(){
    return {
      statistics_data:{}
    }
  },
  methods:{
	  
  }
}
</script>

<style  lang="scss" scoped>
.statistics{
  .box-card{
    margin: 20px 20px 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .card-list-item{
      width: 32%;
      padding: 20px 30px;
      box-shadow: 0px 0px 10px 0px #C5C5C5;
      border-radius: 12px;

      .item-title{
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #919191;
        line-height: 33px;
      }

      .item-content{
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 40px 0;

        .item-img{
          width: 40px;
          height: 40px;
          border-radius: 6px;
          margin-right: 14px;
        }

        div{
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #000000;
          line-height: 33px;

          .text{
            font-size: 14px;
            color: #999;
            line-height: 10px;
          }
        }
      }

      .line{
        width: 2px;
        height: 50px;
        background: #b0b0b0;
      }

      .content{
        display: flex;
        align-items: center;
        justify-content: space-around;
      }
    }
  }
}
</style>
