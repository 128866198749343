/**
 * 获取药房列表 API
 */

 import axios from 'axios' ;
 import qs from 'qs' ;
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/classes_scheduling/method/get.list';
 
 export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        }))
        .then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items
        }) => items.map(({
            classes_scheduling_id,
            doctor_id,
            scheduling_date,
            doctor_name,
            count,
            getClasses
        }) => ({
            userId: doctor_id,
            order: getClasses.name,
            count:count,
            date:scheduling_date
        })));
}