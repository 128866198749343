<template>
  <PharmacyLayout>
    <template v-slot:pharmacy-details>
      <div class="details">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="药品" name="first">
            <DrugsDetails
              ref="pharmacyRecords"
              :loading="loading"
              @submitadddrugs="onSubmitAddDrugs"
              :Data="pharmacyRecords"
              :pharmacyItem="pharmacyRecordItem"
              @changeeditdrugs="onChangeEditDrugs"
              :brandDatas="brandRecords.items"
              :supplierDatas="supplierRecords.items"
              :factoryDatas="factoryRecords.items"
              :pharmacyCateDatas="pharmacyCateRecords.items"
              :smallunitDatas="unitRecords.items"
              :Param="changeParam"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
              @delselectitem="onDelSelectItem"
            ></DrugsDetails>
          </el-tab-pane>
          <el-tab-pane label="库存查询" name="second">
            <StockDetails
              ref="stockData"
              :Data="stockData"
              :Param="changeParam"
              :loading="loading"
              :warehousParam="topHeaderParam"
              :stockData="stockData"
              :smallunitDatas="unitRecords.items"
              :attributeDatas="attributeInList"
              :warehousDatas="warehousList"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
            ></StockDetails>
          </el-tab-pane>
          <el-tab-pane label="入库单" name="third">
            <WarehousingMain
              ref="stockinsData"
              :warehousParam="warehousParam"
              :pharmacyCategoryRecords="pharmacyCateRecords"
              @getpharmacycategoryredords="onGetPharmacyCategoryRedords"
              :pharmacyrecordsstockin="pharmacyrecordsstockin.items"
              :smallunitDatas="unitRecords.items"
              @getpharmcyrecord="onGetPharmcyRecord"
              @savewarehousing="onSaveWarehousing"
              :warehousDatas="warehousList"
              :attributeDatas="attributeInList"
              :Data="stockinsData"
              :Param="changeParam"
              :loading="loading"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
            ></WarehousingMain>
          </el-tab-pane>
          <el-tab-pane label="出库单" name="fourth">
            <DeliveryMain
              ref="stockoutsData"
              :warehousParam="warehousParam"
              :pharmacyCategoryRecords="pharmacyCateRecords"
              @getpharmacycategoryredords="onGetPharmacyCategoryRedords"
              :pharmacyrecordsstockin="pharmacyrecordsstockin.items"
              :smallunitDatas="unitRecords.items"
              @getpharmcyrecord="onGetPharmcyRecord"
              @savedelivery="onSaveDelivery"
              :attributeDatas="attributeOutList"
              :Data="stockoutsData"
              :Param="changeParam"
              :loading="loading"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
               :warehousDatas="warehousList"
            ></DeliveryMain>
          </el-tab-pane>
          <el-tab-pane label="入库记录" name="fifth">
            <StockinRecordMain
              ref="stockinRecordsList"
              :loading="loading"
              :Data="stockinRecordsList"
              :Param="changeParam"
              :warehousParam="topHeaderParam"
              :categoryRecords="categoryRecords"
              :attributeDatas="attributeInList"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></StockinRecordMain>
          </el-tab-pane>
          <el-tab-pane label="出库记录" name="sexth">
            <StockoutRecordMain
              ref="stockoutRecordsList"
              :loading="loading"
              :Data="stockoutRecordsList"
              :Param="changeParam"
              :warehousParam="topHeaderParam"
              :categoryRecords="categoryRecords"
              :attributeDatas="attributeOutList"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></StockoutRecordMain>
          </el-tab-pane>
          <el-tab-pane label="库存预警" name="seventh">
            <StockinWarningMain
              ref="stockWarningList"
              :loading="loading"
              :Data="stockWarningList"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></StockinWarningMain>
          </el-tab-pane>
          <el-tab-pane label="过期预警" name="eightth">
            <StockoutWarningMain
              ref="timeWarningList"
              :loading="loading"
              :Data="timeWarningList"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></StockoutWarningMain>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </PharmacyLayout>
</template>

<script>
import { mock, components, layouts } from "@/helper";
import { showLoading, hideLoading } from "../utils/loading";
export default {
  components: {
    ...layouts(["Pharmacy"]),
    ...components(
      [
        "Drugs.Details",
        "Stock.Details",
        "Warehousing.Main",
        "Delivery.Main",
        "StockinRecord.Main",
        "StockoutRecord.Main",
        "StockinWarning.Main",
        "StockoutWarning.Main",
      ],
      "pharmacy"
    ),
  },

  data() {
    return {
      loading: true,
      topHeaderParam: {
        create_user: 5,
        create_name: "王智慧",
        type: "",
        stype: 1,
        stock_warehouse_id: 1,
        remarks: "",
      },
      activeName: "first",
      pharmacyRecords: {},
      pharmacyRecordItem: {},
      warehousParam: {
        type: "",
        stock_warehouse_id: "",
        stype: 2,
        remarks: "",
      },
      changeParam: {
        action: undefined,
        type: undefined,
        page_no: 1,
        page_size: 20,
        current: 1,
        begin_time: undefined,
        end_time: undefined,
        to_payment: undefined,
        account: undefined,
        todayTime: undefined,
        today: undefined,
        week: undefined,
        month: undefined,
        year: undefined,
        stype: undefined,
      },
      basicParam: {
        action: undefined,
        type: undefined,
        page_no: 1,
        page_size: 20,
        current: 1,
        begin_time: undefined,
        end_time: undefined,
        to_payment: undefined,
        account: undefined,
        todayTime: undefined,
        weekTime: undefined,
        monthTime: undefined,
        yearTime: undefined,
        stype: undefined,
        warning: undefined,
      },
      pharmacyCategoryRecords: {},
      pharmacyrecordsstockin: {},
      stockinsData: {},
      stockoutsData: {},
      brandRecords: {},
      supplierRecords: {},
      factoryRecords: {},
      pharmacyCateRecords: {},
      stockinRecordsList: {},
      attributeRecords: {},
      categoryRecords: {},
      userInfo: "",
      stockWarningList: {},
      timeWarningList: {},
      stockoutRecordsList: {},
      attributeInList: {},
      attributeOutList: {},
      dataName: "",
      getUrl: "",
      getUrls: "",
      delUrl: "",
      refName: "",
      stockData: {},
      warehousList: {},
      unitRecords: {}, // 规格单位
    };
  },
  async mounted() {
    let that = this;
    // 模版数据请求
    that.initTemplate();
    that.getFactoryRecords();
    that.getSupplierRecords();
    that.getBrandRecords();
    that.getWarehouseList(); // 获取仓库列表
    that.getPharmacyCategoryRecords();
    that.getAttributeInList();
    that.getAttributeOutList();
    that.getUnitRecords();
    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    that.warehousParam.create_user = that.userInfo.client_id;
    that.warehousParam.create_name = that.userInfo.name;
  },
  methods: {
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 初始化和tab事件** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /
    /**
     * 初始化模版数据
     *
     */
    async initTemplate() {
      let me = this;
      me.dataName = "pharmacyRecords";
      me.getUrl = "pharmacy/Left/getPharmacyRecords";
      me.delUrl = "pharmacy/Left/delItem";
      // me.getStorehouseRecords(0); //默认0 获取不带参数
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    handleClick(tab, event) {
      let me = this;
      me.changeParam.page_no = 1;
      me.changeParam.current = 1;
      switch (Number(tab.index)) {
        case 0:
          me.dataName = "pharmacyRecords";
          me.getUrl = "pharmacy/Left/getPharmacyRecords";
          me.delUrl = "Storage/Material/delStorehouseItem";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 1:
          me.dataName = "stockData";
          me.getUrl = "pharmacy/Stock/getStockRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 2:
          //me.getStockinRecords(2); //1=库房 2== 药房
          me.changeParam.stype = 2;
          me.dataName = "stockinsData";
          me.getUrl = "pharmacy/Left/getStockinRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 3:
          //me.getStockoutRecords(2); //1=库房 2== 药房
          me.changeParam.stype = 2;
          me.dataName = "stockoutsData";
          me.getUrl = "pharmacy/Stockout/getStockoutRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 4:
          //me.getStockinRecords(1); //1=库房 2== 药房
          me.changeParam.stype = 2;
          me.dataName = "stockinRecordsList";
          me.getUrl = "pharmacy/StockinRecord/getStockinRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 5:
          me.changeParam.stype = 2;
          me.dataName = "stockoutRecordsList";
          me.getUrl = "pharmacy/StockoutRecord/getStockoutRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);

          break;
        case 6:
          me.changeParam.warning = 1;
          me.dataName = "stockWarningList";
          me.getUrl = "pharmacy/Warning/getWarningRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 7:
          me.changeParam.warning = 2;
          me.dataName = "timeWarningList";
         me.getUrl = "pharmacy/Warning/getStockWarningRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
      }
    },
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 公共方法 start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ///
    onDelSelectItem(data) {
      //console.log(data);
      let me = this;
      me.$confirm("确认删除信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.delPublicItem(me.delUrl, data);
          //me.delExaminationTemplateItem(data);
        })
        .catch();
    },
    async delPublicItem(url, data) {
      let me = this;
      let res = await mock(url, data);
      //console.log(res);
      if (res.status === 200) {
        me.$message({
           showClose: true,
          type: "success",
          message: "删除成功!",
        });
        // me.onGetLeftChange(fun);
        me.getListRecords(me.dataName, me.getUrl, me.changeParam);
      } else {
        me.$message({
           showClose: true,
          type: "warning",
          message: res.message,
        });
      }
    },
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 药品 start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ///

    onSubmitAddDrugs(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setDregesRecordItem(data);
        })
        .catch();
    },
    onSubmitEditDrugs(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setPharmacyItem(data);
        })
        .catch();
    },
    //编辑药品记录
    onChangeEditDrugs(val) {
      let me = this;
      me.getPharmacyRecordItem(val);
    },
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 药品 end  入库start** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /
    // 新赠入库，获取药品分类
    onGetPharmacyCategoryRedords() {
      let me = this;
      //me.getPharmacyCategoryRedords();
      me.getStockinPharmacyRecords();
    },
    //change药品分类->get药品类别
    onGetPharmcyRecord(val) {
      let me = this;
      me.getStockinPharmacyRecords(val);
    },
    // 公用
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 药品 end  入库start** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /
    //新赠入库 保存
    onSaveDelivery(dataOne, dataTwo) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setStockoutRecordItem(dataOne, dataTwo); //保存stockin数据
          // me.setStockinRecords(dataTwo);//保存stockin_record数据
        })
        .catch();
    },
    ///** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 入库end 出库start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /

    //新赠入库 保存
    onSaveWarehousing(dataOne, dataTwo) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          me.setStockinRecordItem(dataOne, dataTwo); //保存stockin数据
          // me.setStockinRecords(dataTwo);//保存stockin_record数据
        })
        .catch();
    },

    ///** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 入库end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /

    /**
     * 获取仓库列表
     */
    getWarehouseList() {
      let me = this;
      me.getUrls = "configuration/Stock/Warehouse/getRecords";
      me.getListRecords("warehousList", me.getUrls);
    },

    // 规格单位
    getUnitRecords() {
      let me = this;
      let dataName = "unitRecords";
      let url = "configuration/Stock/Unit/getRecords";
      let data = {
       // type: 2,
        param: true,
      };
      me.getListRecords(dataName, url, data);
    },

    // 【调用事件{A100}】获取主诉管理模版树结构->默认parent=0
    async getListRecords(param, url, data) {
      showLoading();
      let me = this;
      //me.loading = true;
      //me.treeRecords = await mock(url, data);
      try {
        let res = await mock(url, data);

        //console.log(res.status===200);
        if (res.status === 200 || res.length > 0) {
          me[param] = res.data;
          if (res.data == null) {
            me[param] = res;
          }
          // console.log(param, me[param]);
        }
        me.loading = false;
        hideLoading();
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    //分页点击时间
    onChangePagination(val) {
      let me = this;
      me.changeParam.page_no = val.current;
      me.changeParam.current = val.current;
      me.loading = true;
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onChangeSubmit(form, isRestore = false) {
      let me = this;
      if (isRestore) {
        me.changeParam.page_no = 1;
      }
      me.loading = true;
      //自动把提交事件时的tophead参数合并到changeParam
      //以便通过changeParam把所有参数一起传给后端
      me.formParam(form, "add");
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    //重置
    onSubmitReset(form) {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = undefined;
      me.changeParam.month = undefined;
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formParam(form, "del");
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onTodayClick() {
      let me = this;
      me.changeParam.today = "today";
      me.changeParam.week = undefined;
      me.changeParam.month = undefined;
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formatDate();
      // me.getStockinRecordsList(1);
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onWeekClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = "week";
      me.changeParam.month = undefined;
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formatDate();
      // me.getStockinRecordsList(1);
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onMonthClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = undefined;
      me.changeParam.month = "month";
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formatDate();
      // me.getStockinRecordsList(1);
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onYearClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = undefined;
      me.changeParam.month = undefined;
      me.changeParam.year = "year";
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formatDate();
      // me.getStockinRecordsList(1);
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },

    getAttributeInList() {
      let me = this;
      let data = {
        type: 1,
      };
      me.getUrls = "configuration/Stock/Attribute/getRecords";
      me.getListRecords("attributeInList", me.getUrls, data);
    },
    getAttributeOutList() {
      let me = this;
      let data = {
        type: 2,
      };
      me.getUrls = "configuration/Stock/Attribute/getRecords";
      me.getListRecords("attributeOutList", me.getUrls, data);
    },
    async setDregesRecordItem(val) {
      let me = this;
      try {
        let res = await mock("pharmacy/Left/setPharmacyRecordItem", val);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.pharmacyRecords.changeDrawer(false);
          // me.dataName = "pharmacyRecords";
          // me.getUrl = "pharmacy/Left/getPharmacyRecords";
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    //编辑药品记录
    async setPharmacyItem(val) {
      let me = this;
      try {
        let res = await mock("pharmacy/Left/setPharmacyEditItem", val);
        if (res == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    getPharmacyRecordItem(val) {
      let me = this;
      let data = {
        pharmacy_id: val,
      };
      let dataName = "pharmacyRecordItem";
      let url = "pharmacy/Left/getPharmacyRecordItem";
      me.getListRecords(dataName, url, data);
    },
    //change药品分类->get药品类别
    getStockinPharmacyRecords(val) {
      let me = this;
      let data = {
        pharmacy_category_id: val,
      };
      let dataName = "pharmacyrecordsstockin";
      let url = "public/Pharmacy/getRecords";
      me.getListRecords(dataName, url, data);
    },
    //保存stockin数据
    async setStockinRecordItem(dataOne, dataTwo) {
      let me = this;
      try {
        let res = await mock("pharmacy/Left/setStockinRecordItem", {
          stockinData: dataOne,
          stockinRecordData: dataTwo,
        });
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.stockinsData.changeDrawer(false);
          me.dataName = "stockinsData";
          me.getUrl = "pharmacy/Left/getStockinRecords";
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    //保存stockin数据
    async setStockoutRecordItem(dataOne, dataTwo) {
      let me = this;
      try {
        let res = await mock("pharmacy/Stockout/setStockoutRecordItem", {
          stockoutData: dataOne,
          stockoutRecordData: dataTwo,
        });
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.stockoutsData.changeDrawer(false);
          me.dataName = "stockoutsData";
          me.getUrl = "pharmacy/Stockout/getStockoutRecords";
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    //生产厂家
    getFactoryRecords() {
      let me = this;
      let dataName = "factoryRecords";
      let url = "configuration/Stock/Factory/getRecords";
      me.getListRecords(dataName, url);
    },
    //供应商
    getSupplierRecords() {
      let me = this;
      let dataName = "supplierRecords";
      let url = "configuration/Stock/Supplier/getRecords";
      me.getListRecords(dataName, url);
    },
    //品牌
    getBrandRecords() {
      let me = this;
      let dataName = "brandRecords";
      let url = "configuration/Stock/Brand/getRecords";
      me.getListRecords(dataName, url);
    },
    //物资类目
    getPharmacyCategoryRecords() {
      let me = this;
      let dataName = "pharmacyCateRecords";
      let url = "configuration/Stock/Pharmacy/getRecords";
      me.getListRecords(dataName, url);
    },
    formParam(form, action) {
      let me = this;
      if (action == "add") {
        //赋值
        Object.getOwnPropertyNames(form).forEach(function (key) {
          if (form[key] !== undefined && form[key] !== null) {
            me.changeParam[key] = form[key];
          }
        });
      } else {
        //clear
        Object.getOwnPropertyNames(form).forEach(function (key) {
          me.changeParam[key] = undefined;
        });
      }
    },
    formatDate(val) {
      let me = this;
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      me.changeParam.todayTime = `${year}-${month}-${day}`;
      // me.nowDate = `${year}-${month}-${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  margin: 4px 10px 20px;
}
</style>
