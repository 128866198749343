<template>
  <Container class="see-main-operation hb-layout-horizontal">
    <div class="user-info">
      <div class="display">
        <div class="main-span">
          <el-tag :type="typeMap[visitStatus].type">{{ typeMap[visitStatus].text }}</el-tag>
        </div>
        <div class="main-span">
          <span class="main-span-text"> 姓名：{{ real_name }}</span>
        </div>
        <div class="main-span">
          <span class="main-span-text"> 性别：{{ sexMap[sex] }}</span>
        </div>
        <div class="main-span">
          <span class="main-span-text"> 年龄：{{ age }}岁</span>
        </div>
        <div class="main-span">
          <span class="main-span-text"> 病历号：{{ medical_record_no }}</span>
        </div>
        <div class="main-span" v-if="userPaymentResult==1">
          <el-tag type="danger">欠费中</el-tag>
        </div>
      </div>
    </div>

    <div class="btn" v-if="paymentItems.paymentStatus>1">
      <div class="btn-between">
        <el-button type="success" @click="previewDialog()">打印预览</el-button>
      </div>
    </div>

    <Preview
      ref="preview"
      :direction="direction"
      :size="size"
      :drawer_title="drawer_add_title"
      :real_name="real_name"
      :sex="sex"
      :age="age"
      :medical_record_no="medical_record_no"
      :diagnosisData="diagnosisData"
      :inspectionItems="inspectionItems"
      :treatmentItems="treatmentItems"
      :pharmacyItems="pharmacyItems"
      :westernmedicineItems="westernmedicineItems"
      :paymentItems="paymentItems"
      :paymentRecordData="paymentRecordData"
      v-if="paymentItems.paymentStatus>1"
    ></Preview>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Preview"], "payment"),
    ...components(["Container"]),
  },
  props: {
    real_name: {
      type: String,
      default: "",
    },
    sex: {
      type: String | Number,
      default: "",
    },
    age: {
      type: String | Number,
      default: "",
    },
    medical_record_no: {
      type: String,
      default: "",
    },
    visitStatus:0,
    diagnosisData: {},
    treatmentItems: {},
    inspectionItems: {},
    pharmacyItems: {},
    westernmedicineItems: {},
    paymentItems: {},
    paymentRecordData: {},
    userPaymentResult:0
  },
  watch: {
    paymentRecordData:function (val) {
      console.log("logval1111",val);
    }
  },
  data() {
    return {
      direction: "rtl",
      size: "900px",
      drawer_add_title: "消费结算支付",
      sexMap: ['保密', '男', '女'],
      typeMap: [
        {}, // 必须保留  => 作用：诊断类型起始索引为1，对象默认起始索引为0
        {
          type: "success",
          text: "首诊",
        },
        {
          type: "primary",
          text: "初诊",
        },
        {
          type: "danger",
          text: "复诊",
        }
      ],
    };
  },
  methods: {
    previewDialog() {
      this.$refs.preview.changeDrawer(true);
    },
  },
};
</script>

<style lang="scss" scoped>
.see-main-operation {
  align-items: center;
  padding: 0 19px;
  height: 60px;
  // line-height: 60px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 5px auto 10px;
  width: 99%;
  display: flex;
  justify-content: space-between;

  .see-main-operation:active {
    background-color: #ff0000;
  }

  > .user-info {

    .display{
      display: flex;
      align-items: center;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;

      .main-span{
        margin-right: 15px;

        .main-span-text {
          font-size: 16px;
          color: #5b5b5b;
          white-space: nowrap;
        }
      }
    }
  }

  > .btn {
    margin-left: 25px;
    white-space: nowrap;
    float: right;

    > .btn-between {
      margin-left: 10px;

      > .select-but {
        margin-right: 10px;
      }
    }
  }
}
</style>
