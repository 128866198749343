<template>
  <Container class="statistics">
    <div class="text item">
      <div class="total">
        <div class="total-amount">
          <el-image class="image" src="../../image/pay.png"></el-image>
          <div class="content">
	             <div class="total-number" v-if="statisticsData && statisticsData.total_price >= 0">{{statisticsData.total_price.toFixed(2)}}</div>
            <div class="total-name">收支总金额</div>
          </div>
        </div>
        <!-- <div class="total-amount">
          <el-image class="image img" src="../../image/ywc.png"></el-image>
          <div class="content">
                      <div class="total-number" v-if="statisticsData && statisticsData.count_settlement_1>0">{{statisticsData.count_settlement_1}}</div>
            <div class="total-name">已结算</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image img" src="../../image/wwc.png"></el-image>
          <div class="content">
                      <div class="total-number" v-if="statisticsData && statisticsData.count_settlement_0>0">{{statisticsData.count_settlement_0}}</div>
            <div class="total-name">未结算</div>
          </div>
        </div> -->
      </div>
      <el-table
        style="width: 100%"
        height="calc(100vh - 385px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
        <el-table-column fixed type="index" width="45"> </el-table-column>

        <el-table-column
          fixed
          prop="get_user.nickname"
          label="姓名"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column
          fixed
          prop="medical_record_no"
          label="病历号"
          min-width="240"
          align="left"
        ></el-table-column>

        <el-table-column
          fixed
          prop="getInsurance.name"
          label="保险公司"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column label="政策/年" min-width="200" align="left">
          <template slot-scope="scope">
            {{ scope.row.getInsuranceLevel.name }}/{{
              scope.row.getInsuranceLevel.years
            }}/{{ scope.row.stype }}
          </template>
        </el-table-column>

        <el-table-column label="类型" min-width="100" align="left">
          <template slot-scope="scope">
            {{ stypeMap[scope.row.stype] }}
          </template>
        </el-table-column>

        <el-table-column prop="amount" label="收支金额" min-width="130" align="left">
          <template slot-scope="scope">
            <el-tag :type="typeMap[scope.row.type].type" effect="plain" size="mini">
              {{ typeMap[scope.row.type].symbol }}
              {{ Number(scope.row.amount).toFixed(2) }}
            </el-tag>
          </template>
        </el-table-column>
         <el-table-column prop="amount" label="次数" min-width="130" align="left">
          <template slot-scope="scope">
            <el-tag :type="typeMap[scope.row.type].type" effect="plain" size="mini">
              {{ typeMap[scope.row.type].symbol }}
              {{ scope.row.count}}
            </el-tag>
          </template>
        </el-table-column>

         <el-table-column
          prop="getDoctor.nickname"
          label="创建人"
          width="100"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="200"
          align="left"
        ></el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
	  tableData: {
		  default: () => [],
	  },
	  statisticsData: {
      default(){
        return {
          total_price: 0,
        } ;
      }
    },
  },
  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {
      typeMap: [
        {
          text: "收入",
          type: "success",
          symbol: "+",
        },
        {
          text: "支出",
          type: "danger",
          symbol: "-",
        },
      ],
      stypeMap: ["", "门诊福利", "物理治疗"],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;

  .total{
    display: flex;
    align-items: center;

    .total-amount{
      height: 80px;
      margin: 20px 10px 20px 5px;
      padding: 0 40px 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 1px 4px 1px #d8d8e3;

      .image{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .img{
        width: 44px;
        height: 44px;
      }

      .content{
        font-family: PingFangSC-Medium, PingFang SC;

        .total-name{
          font-size: 12px;
          color: #999;
        }
        .total-number{
          font-size: 18px;
          color: #333;
        }
      }
    }
  }
}
</style>
