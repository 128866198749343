<template>
  <el-card class="box-card" shadow="hover" :class="{
      'see-patient-list-item':true,
        selected
    }" :body-style="{ padding: '0 10px' }">
    <el-row style="width: 100%" type="flex" justify="center" align="middle">
      <el-col :span="8"> 
          <div class="name">{{ name }}</div>
      </el-col>
      <el-col :span="7"> 
        <div style="display: flex; align-items: left">
          <el-tag size="small" type="warning">{{ lvlname }}</el-tag>
        </div>
      </el-col>
      
      <el-col :span="3">
        <div class="name state">{{start_time}}</div>
      </el-col>
      <el-col :span="4">
        <div class="hb-layout-flex-1 state">{{ end_time }}</div>
      </el-col>
  
      <el-col :span="4">
        <div class="hb-layout-flex-1 state">
           <el-tag :type="typeMap[executeStatus].type" effect="plain" size="mini">
              {{ typeMap[executeStatus].text }}
            </el-tag>
        </div>
      </el-col>

    </el-row>

  </el-card>
</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components: components([
    'Container'
  ]),
  props: {
    //{{status[executeStatus]}}
    start_time: {
      required: true,
      type: String
    },
    lvlname: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    end_time: {
      required: true,
      type: String
    },
    executeStatus: {
      required: true,
      type: String | Number
    },

    selected: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      status: ['', '待执行', '执行中', '已执行'],
      lvlMap: ['', '金卡', '银卡'],
       typeMap: [
        {
          text: "全部",
          type: "warning",
        },
        {
          text: "待执行",
          type: "success",
        },
        {
          text: "执行中",
          type: "danger",
        },
        {
          text: "已执行",
          type: "info",
        },
      ],
    }
    
  }
}
</script>

<style lang="scss" scoped>

$see-patient-list-item-height: 80px;

.see-patient-list-item {
  height: $see-patient-list-item-height;
  line-height: $see-patient-list-item-height;
  align-items: center;
  border-bottom: 1px solid #e1e1e1;
  font-size: 12px;
  cursor: pointer;

  	&.selected {
			box-shadow: 0 2px 7px 0 #d8d8d8;
			margin: 2px 0;
      background: #92c2f957;
		}
    &:hover{
      background: #92c2f921;
      color: #2063b695;
    }

  .name {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    padding-right: 10px;

  }

  > .grade {
    padding: 3px 12px;
    background: #F1BE5E;
    border: 1px solid #979797;
    margin-right: 15px;
  }

  .time {
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #0B7DFF;
    line-height: 15px;
    display: flex;
    justify-content: center;
  }

  > .time,
  .state {
    display: flex;
    justify-content: center;

  }
}


</style>
