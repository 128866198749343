import axios from 'axios' ;

/**
 * 诊断模板获取
 */
export default () =>{

    return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/diagnosis/method/get.diagnosistemplate.list/').then(({
        data
    }) => data)
    .then(({
        data
    }) => data.map(({
        diagnosis_template_id,
		name
    }) =>({
		diagnosis_template_id:diagnosis_template_id,
        name:name,
          
    })));
}

// export default()=>({
// 	id,
// 	user_id
// },date=Date.now())=>{
// 	return axions.get(url,{
// 		params:{
// 			medical_no:id,
// 			user_id:user_id,
// 			date
// 		}
// 	}).then(({
// 		data
// 	})=>data).then(({
//         data
//     }) => data).then(({
// 		items
// 	})=>item.map(({
		
// 	})));
// }

