<template>
	<Container class="see-patient-history-list-item hb-layout-vertical">
		<Header @addsubmit="onAddSubmit"></Header>
		<Content
			v-if="tabDatas === 1"
			:listRecordsData="listRecordsData"
			@handleselectitem="onHandleSelectItem"
			@handledelselectitem="onHandleDelDelectItem"
		></Content>
		<ContentRoles
			v-if="tabDatas === 2"
			:listRecordsData="listRecordsData"
			@handleselectitem="onHandleSelectItem"
			@handledelselectitem="onHandleDelDelectItem"
			@handablechange="onHandAbleChange"
		></ContentRoles>
		<ContentUser
			v-if="tabDatas === 3"
			:listRecordsData="listRecordsData"
			@handleselectitem="onHandleSelectItem"
			@handlepassselectitem="onHandlePassSelectItem"
			@handledelselectitem="onHandleDelDelectItem"
			@handablechange="onHandAbleChange"

		></ContentUser>
		<ContentClasses
			v-if="tabDatas === 4"
			:listRecordsData="listRecordsData"
			@handleselectitem="onHandleSelectItem"
			@handledelselectitem="onHandleDelDelectItem"
		></ContentClasses>
		<ContentSource
			v-if="tabDatas === 5"
			:treeDatas="listRecordsData"
			@editselectitem="onEditSelectItem"
			@handleselectitem="onHandleSelectItem"
			@handledelselectitem="onHandleDelDelectItem"
		></ContentSource>
		<ContentReturn
			v-if="tabDatas === 6"
			:treeDatas="listRecordsData"
			@editselectitem="onEditSelectItem"
			@handleselectitem="onHandleSelectItem"
			@handledelselectitem="onHandleDelDelectItem"
		></ContentReturn>
		<!-- <ContentPayment
		  v-if="tabDatas === 7"
		  :listRecordsData="listRecordsData"
		  @handleselectitem="onHandleSelectItem"
		  @handledelselectitem="onHandleDelDelectItem"
		></ContentPayment>
		<ContentTags
		  v-if="tabDatas === 8"
		  :listRecordsData="listRecordsData"
		  @handleselectitem="onHandleSelectItem"
		  @handledelselectitem="onHandleDelDelectItem"
		></ContentTags> -->
		<ContentManage
			v-if="tabDatas === 9"
			:listRecordsData="listRecordsData"
			@handleselectitem="onHandleSelectItem"
			@handlepassselectitem="onHandlePassSelectItem"
			@handledelselectitem="onHandleDelDelectItem"
		></ContentManage>
		<ContentStore
			v-if="tabDatas === 7"
			:listRecordsData="listRecordsData"
			@handleselectitem="onHandleSelectItem"
			@handlepassselectitem="onHandlePassSelectItem"
			@handledelselectitem="onHandleDelDelectItem"
		></ContentStore>
	</Container>
</template>

<script>
import {components} from "@/helper";

export default {
	components: {
		...components(
			[
				"Header",
				"Content",
				"ContentRoles",
				"ContentUser",
				"ContentClasses",
				"ContentSource",
				"ContentReturn",
				"ContentPayment",
				"ContentTags",
				"ContentManage",
				"ContentStore"
			],
			"configuration.Inside.Center"
		),
		
		...components(["Container"]),
	},
	
	props: {
		listRecordsData: {},
		tabData: 0,
	},
	
	data() {
		return {
			tabDatas: 0,
		};
	},
	watch: {
		tabData: function (val) {
			this.tabDatas = val;
		},
	},
	methods: {
		isShowAll(val) {
			this.$refs.tree_List.isShowAlls(val);
		},
		onFilterText(val) {
			this.$refs.tree_List.onFilterText(val);
		},
		onAddSubmit() {
			this.$emit("addsubmit");
		},
		onHandleSelectItem(itemData) {
			this.$emit("handleselectitem", itemData);
		},
		onHandlePassSelectItem(itemData) {
			this.$emit("handlepassselectitem", itemData);
		},
		onHandleDelDelectItem(itemData) {
			this.$emit("handledelselectitem", itemData);
		},
		onEditSelectItem(itemData, treeData) {
			this.$emit("editselectitem", itemData, treeData);
		},
		onHandAbleChange(itemData) {
			this.$emit("handablechange", itemData);
		},
	},
};
</script>

<style lang="scss" scoped>
// .see-patient-history-list-item {
//   width: 98%;
//   padding: 0 19px;
//   height: calc(100vh - 140px);
//   box-shadow: 0 2px 7px 0 #d8d8d8;
//   margin: 2px auto 10px;
// }
</style>
