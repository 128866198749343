<template>
  <Page class="patient hb-layout-horizontal">
      <Container class="center hb-layout-flex-1 hb-layout-vertical">
          <Container class="body hb-layout-flex-1">
              <slot name="configuration-details"></slot>
          </Container>
      </Container>
  </Page>
</template>

<script>

import {
    components
} from '@/helper' ;

export default {
  components:components([
      'Container',
      'Page'
  ])
}
</script>

<style lang="scss" scoped>

$page-patient-left-width:300px;

$page-patient-right-width:300px;

$page-patient-header-height:60px;

.patient{

    > .right{
        width:$page-patient-right-width;

		> .header{
			height: 60px;

			> div{
				font-size: 16px;
				font-family: PingFangSC-Medium, PingFang SC;
				font-weight: 500;
				color: #5B5B5B;
				line-height: 22px;
			}
		}
		.right-header{
			box-shadow: 0 2px 7px 0 #d8d8d8;
			margin: 5px 0 10px 7px;
		}
		> .body{
			margin-left: 5px;
		}
    }
}

</style>
