<template>
  <div style="height: 100%; overflow: auto">
    <Container class="see-detail hb-layout-vertical">
      <Container class="vital-signs">
        <el-card class="box-card">
          <div class="display">
            <div class="user-info" v-if="medical_basic">
              <div class="follow-up-visit">
                <el-tag :type="typeMap[visitStatus].type">{{
                  typeMap[visitStatus].text
                }}</el-tag>
              </div>
              <div class="card-number">病例号：{{ medical_basic.medical_record_no }}</div>
              <div class="name">姓名：{{ medical_basic.real_name }}</div>
              <div class="info">性别：{{ sexMap[medical_basic.sex] }}</div>
              <div class="info">年龄：{{ medical_basic.age }}岁</div>
            </div>
          </div>
        </el-card>
      </Container>
      <Container class="vital-signs">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div class="title">生命体征 Vital Signs</div>
          </div>
          <VitalSigns class="body" :vital="item.vital_signs"></VitalSigns>
        </el-card>
      </Container>
      <Container class="vital-signs basic">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div class="title">基础检查 Inspect Basic</div>
          </div>
          <InspectBasic
            ref="basicRef"
            :basic="basic"
            :clinical_department_id="item.clinical_department_id"
            :chief_complaint="item.chief_complaint"
            :impressions="item.impressions"
            :start_reception="start_reception"
            class="body"
          ></InspectBasic>
        </el-card>
      </Container>
      <Container class="vital-signs examination">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div class="title">检验检查 Inspection</div>
            <el-button
              type="primary"
              @click="() => $refs.inspectionRef.add()"
              icon="el-icon-plus"
              size="mini"
            ></el-button>
          </div>

          <InspectExamination
            ref="inspectionRef"
            :examinations="item.get_examinations"
            :examination_template="examination_template"
            @delexamination="onDelExamination"
            :start_reception="start_reception"
            class="body"
          ></InspectExamination>
        </el-card>
      </Container>
      <Container class="vital-signs">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div class="title">治疗项目 Treatment</div>
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="() => $refs.treatmentRef.add()"
            ></el-button>
          </div>
          <TreatmentProject
            ref="treatmentRef"
            :treatments_template="treatments_template"
            :treatments="item.get_treatments"
            @deltreatment="onDelTreatment"
            :start_reception="start_reception"
            class="body"
          ></TreatmentProject>
        </el-card>
      </Container>
      <Container class="vital-signs">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <div class="header-left-btn">
              <div class="title">处方开药 Prescription</div>
              <div>
                <el-radio-group
                  v-model="prescriptionType"
                  @change="clickPrescriptionType"
                >
                  <el-radio-button label="1">西药</el-radio-button>
                  <el-radio-button label="2">中药</el-radio-button>
                  <el-radio-button label="3">输液</el-radio-button>
                </el-radio-group>
              </div>
            </div>
            <!-- <el-button type="primary" icon="el-icon-minus" size="mini" @click="() => $refs.prescriptionRef.del()"></el-button> -->
            <el-button
              type="primary"
              icon="el-icon-plus"
              size="mini"
              @click="
                () => $refs.prescriptionRef.add(pharmacy_template, prescriptionType)
              "
            ></el-button>
          </div>

          <Prescription
            ref="prescriptionRef"
            :pharmacy="item.get_prescription"
            :pharmacy_template="pharmacy_template"
            @delprescription="onDelPrescription"
            :start_reception="start_reception"
            :usageData = "DrugsSettingArrayOne"
            :dosage_template = "DrugsSettingArrayTwo"
            :remark_template = "DrugsSettingArrayThree"
            class="body"
          ></Prescription>
        </el-card>
      </Container>
      <Container class="vital-signs">
        <el-card class="box-card">
          <el-row style="width: 100%">
            <el-col :span="3">
              <el-tag class="basic-inspect" type="info">医嘱</el-tag>
            </el-col>
            <el-col :span="21">
              <div class="grid-content bg-purple-light grid-left">
                <el-autocomplete
                  style="width: 100%"
                  v-model="item.medical_advice"
                  :fetch-suggestions="querySearch"
                  placeholder="请输入嘱咐内容"
                  @select="handleSelect"
                ></el-autocomplete>
                <!-- <el-select
                  style="width: 100%"
                  v-model="item.medical_advice"
                  filterable
                  allow-create
                  default-first-option
                >
                  <el-option
                    v-for="item in doctors_advice"
                    :key="item.doctor_enjoin_template_id"
                    :label="item.content"
                    :value="item.content"
                  >
                  </el-option>
                </el-select> -->
              </div>
            </el-col>
          </el-row>
        </el-card>
      </Container>
    </Container>
  </div>
</template>

<script>
import { components, layouts } from "@/helper";

export default {
  props: {
    start_reception: false,
    item: {
      //看诊记录
      type: Object,
      default: () => {},
    },
    diagnosis_template: {
      //诊断模版
      type: Array,
      default: () => [],
    },
    prescription: {
      type: Object,
      default: () => {},
    },
    basic: {
      //基础检查
      type: Object,
      default: () => {},
    },
    treatments_template: {
      //治疗模版
      type: Array,
      default: () => [],
    },
    examination_template: {
      //检查项目模版
      type: Array,
      default: () => [],
    },
    pharmacy_template: {
      //药品记录模版
      type: Array,
      default: () => [],
    },
    doctors_advice: {
      //主诉模版
      type: Array,
      default: () => [],
    },
    type: {
      type: Number | String,
      default: "",
    },
    visitStatus: 0,
    medical_basic: {
      type: Object,
      default: () => {},
    },
    DrugsSettingData: {},
  },
  components: {
    ...components(
      [
        "VitalSigns",
        "inspect.Basic",
        "inspect.Inspection",
        "inspect.Examination",
        "TreatmentProject",
        "Prescription",
      ],
      "see.Center"
    ),
    ...components(["Container"]),
  },
  data() {
    return {
      medical_advice: "",
      prescriptionType: 1,
      restaurants: [],

      sexMap: ["保密", "男", "女"],
      typeMap: [
        {},
        {
          type: "success",
          text: "首诊",
        },
        {
          type: "primary",
          text: "初诊",
        },
        {
          type: "danger",
          text: "复诊",
        },
      ],
      DrugsSettingArrayOne: [],
      DrugsSettingArrayTwo: [],
      DrugsSettingArrayThree: [],
      DrugsSettingArrayFour: [],
    };
  },
  watch: {
    DrugsSettingData: function (val) {
      let me = this;
       //console.log("DrugsSettingArray",val);
      if (val.length > 0) {
        val.map((item) => {
          switch (Number(item.type)) {
            case 1:
              me.DrugsSettingArrayOne.push(item);
              break;
            case 2:
              me.DrugsSettingArrayTwo.push(item);
              break;
            case 3:
              me.DrugsSettingArrayThree.push(item);
              break;
            case 4:
              me.DrugsSettingArrayFour.push({value:item.name});
              break;
          }
        });
      }
     // console.log("DrugsSettingArrayFour",me.DrugsSettingArrayFour);
    },
  },
  mounted() {
    this.restaurants = this.loadAll();
  },
  methods: {
    /**
     * 组合保存看诊记录所需的结构
     */
    getSeeData() {
      let inspection = this.$refs.inspectionRef.getRows();
      let treatments = this.$refs.treatmentRef.getRows();
      let prescription = this.$refs.prescriptionRef.getRows();

      //console.log("获取药品数据：" + JSON.stringify(prescription));
      let {
        clinical_department_id,
        impressions,
        chief_complaint,
      } = this.$refs.basicRef.getBasicValue();

      return {
        medical_advice: this.item.medical_advice,
        diagnosis_record_no: this.item.diagnosis_record_no,
        clinical_department_id,
        impressions,
        chief_complaint,
        inspection,
        treatments,
        prescription,
      };
    },
    clickPrescriptionType() {
      this.$emit("clickPrescriptionType", this.prescriptionType);
    },
    onDelExamination(val) {
      this.$emit("delexamination", val);
    },
    onDelTreatment(val) {
      this.$emit("deltreatment", val);
    },
    onDelPrescription(val) {
      this.$emit("delprescription", val);
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString
        ? restaurants.filter(this.createFilter(queryString))
        : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    handleSelect(item) {
      //console.log(item);
    },
    loadAll() {
      let me = this;
      return me.DrugsSettingArrayFour;
    },
  },
};
</script>

<style lang="scss" scoped>
.see-detail {
  width: 99%;
  margin: auto;

  .vital-signs {
    margin: 0px 0 10px;
    box-shadow: 0 2px 7px 0 #d8d8d8;

    > .body {
      height: 132px;
    }

    > .box-card {
      .clearfix {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-left-btn {
          display: flex;
          align-items: center;

          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5b5b5b;
            line-height: 22px;
            margin-right: 20px;
          }
        }
      }

      .display {
        width: 100%;
        overflow-x: auto;
        display: flex;
        align-items: center;

        .user-info {
          display: flex;
          align-items: center;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #5b5b5b;
          white-space: nowrap;

          > .card-number {
            padding-left: 15px;
          }

          > .name {
            padding-left: 15px;
          }

          > .info {
            padding-left: 15px;
          }

          > .btn-between {
            margin-left: 15px;
            display: flex;
            align-items: center;
            white-space: nowrap;
          }
        }
        .btn {
          margin-left: 25px;
          white-space: nowrap;

          > .btn-between {
            margin-left: 10px;

            > .select-but {
              margin: 0 10px;
            }
          }
        }
      }
    }

    > .header {
      padding: 16px 0 13px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      line-height: 22px;
    }
  }

  .el-row {
    width: 100%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .basic-inspect {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-top: 2px;
  }

  .bg-purple {
    background: #e5e9f2;
  }

  .grid-content {
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .grid-left {
    text-align: left;
    padding-left: 10px;
  }
}
</style>
