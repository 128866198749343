<template>
  <StorageLayout>
    <template v-slot:storage-details>
      <div class="details">
        <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
          <el-tab-pane label="物资" name="first">
            <MaterialDetails
              ref="storehouseRecords"
              :loading="loading"
              @submitmaterial="onSubmitAddMaterial"
              :Data="storehouseRecords"
              :storehouseRecordItem="storehouseRecordItem"
              @changeeditmaterial="onChangeEditMaterial"
              :brandDatas="brandRecords.items"
              :supplierDatas="supplierRecords.items"
              :factoryDatas="factoryRecords.items"
              :smallunitDatas="unitRecords.items"
              :storehouseCateDatas="storehouseCateRecords.items"
              :Param="changeParam"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
              @delselectitem="onDelSelectItem"
            ></MaterialDetails>
          </el-tab-pane>
          <el-tab-pane label="库存查询" name="second">
            <StockDetails
              ref="stockData"
              :Data="stockData"
              :Param="changeParam"
              :loading="loading"
              :warehousParam="topHeaderParam"
              :recordsstockin="recordsstockin"
              @getshorehouserecord="onGetStorehouseRecord"
              @savestorehousestockin="onSaveStorehouseStockin"
              :stockData="stockData"
              :smallunitDatas="unitRecords.items"
              :storehouseSelectDatas="storehouseSelectRecords"
              :attributeDatas="attributeInList"
              :warehousDatas="warehousList"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
            ></StockDetails>
          </el-tab-pane>
          <el-tab-pane label="入库单" name="third">
            <WarehousingMain
              ref="stockinsData"
              :warehousParam="topHeaderParam"
               :smallunitDatas="unitRecords.items"
              :categoryRecords="storehouseCateRecords.items"
              :recordsstockin="recordsstockin"
              @getshorehouserecord="onGetStorehouseRecord"
              @savestorehousestockin="onSaveStorehouseStockin"
              :stockinsData="stockinsData"
              :storehouseSelectDatas="storehouseSelectRecords"
              :attributeDatas="attributeInList"
              :warehousDatas="warehousList"
              :Data="stockinsData"
              :Param="changeParam"
              :loading="loading"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
            ></WarehousingMain>
          </el-tab-pane>
          <el-tab-pane label="出库单" name="fourth">
            <DeliveryMain
              ref="stockoutsData"
              :warehousParam="topHeaderParam"
               :smallunitDatas="unitRecords.items"
              :categoryRecords="storehouseCateRecords.items"
              @getshorehouserecord="onGetStorehouseRecord"
              @savestorehousestockout="onSaveStorehouseStockout"
              :storehouseSelectDatas="storehouseSelectRecords"
              :attributeDatas="attributeOutList"
              :warehousDatas="warehousList"
              :Data="stockoutsData"
              :Param="changeParam"
              :loading="loading"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @changepagination="onChangePagination"
            ></DeliveryMain>
          </el-tab-pane>
          <el-tab-pane label="入库记录" name="fifth">
            <StockinRecordMain
              ref="stockinRecordsList"
              :loading="loading"
              :Data="stockinRecordsList"
              :Param="changeParam"
              :attributeDatas="attributeInList"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></StockinRecordMain>
          </el-tab-pane>
          <el-tab-pane label="出库记录" name="sexth">
            <StockoutRecordMain
              ref="stockoutRecordsList"
              :loading="loading"
              :Data="stockoutRecordsList"
              :Param="changeParam"
              :attributeDatas="attributeOutList"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></StockoutRecordMain>
          </el-tab-pane>
          <el-tab-pane label="库存预警" name="seventh">
            <StockinWarningMain
              ref="stockWarningList"
              :loading="loading"
              :Data="stockWarningList"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></StockinWarningMain>
          </el-tab-pane>
          <el-tab-pane label="过期预警" name="eightth">
            <StockoutWarningMain
              ref="timeWarningList"
              :loading="loading"
              :Data="timeWarningList"
              :Param="changeParam"
              @changepagination="onChangePagination"
              @changesubmit="onChangeSubmit"
              @submitreset="onSubmitReset"
              @todayclick="onTodayClick"
              @weekclick="onWeekClick"
              @monthclick="onMonthClick"
              @yearclick="onYearClick"
            ></StockoutWarningMain>
          </el-tab-pane>
        </el-tabs>
      </div>
    </template>
  </StorageLayout>
</template>

<script>
import { mock, components, layouts } from "@/helper";
import { showLoading, hideLoading } from "@/utils/loading";

export default {
  components: {
    ...layouts(["Storage"]),
    ...components(
      [
        "Material.Details",
        "Stock.Details",
        "Warehousing.Main",
        "Delivery.Main",
        "StockinRecord.Main",
        "StockoutRecord.Main",
        "StockinWarning.Main",
        "StockoutWarning.Main",
      ],
      "storage"
    ),
  },
  data() {
    return {
      loading: true,
      activeName: "first",
      topHeaderParam: {
        create_user: 5,
        create_name: "王智慧",
        type: "",
        stype: 1,
        stock_warehouse_id: "",
        remarks: "",
      },
      categoryRecords: {},
      recordsstockin: {},
      storehouseRecords: {},
      storehouseRecordItem: {},
      stockinsData: {},
      stockoutsData: {},
      storehouseSelectRecords: {}, //add->footer->select(val)->storehouse
      brandRecords: {},
      supplierRecords: {},
      factoryRecords: {},
      unitRecords: {}, // 规格单位
      storehouseCateRecords: {},
      attributeRecords: {},
      userinfo: "",
      changeParam: {
        action: undefined,
        type: undefined,
        page_no: 1,
        page_size: 20,
        current: 1,
        begin_time: undefined,
        end_time: undefined,
        to_payment: undefined,
        account: undefined,
        todayTime: undefined,
        today: undefined,
        week: undefined,
        month: undefined,
        year: undefined,
      },
      basicParam: {
        action: undefined,
        type: undefined,
        page_no: 1,
        page_size: 20,
        current: 1,
        begin_time: undefined,
        end_time: undefined,
        to_payment: undefined,
        account: undefined,
        todayTime: undefined,
        weekTime: undefined,
        monthTime: undefined,
        yearTime: undefined,
        warning: undefined,
      },
      timeParam: {
        today: undefined,
        week: undefined,
        month: undefined,
        year: undefined,
        end_time: undefined,
        begin_time: undefined,
      },
      stockoutRecordsList: {},
      stockinRecordsList: {},
      stockWarningList: {},
      timeWarningList: {},
      dataName: "",
      getUrl: "",
      getUrls: "",
      delUrl: "",
      refName: "",
      attributeInList: {},
      attributeOutList: {},
      warehousList: {},
      stockData:{}
    };
  },
  async mounted() {
    let that = this;
    // 模版数据请求
    that.initTemplate();

    that.getFactoryRecords();
    that.getSupplierRecords();
    that.getBrandRecords();
    that.getStorehouseCategoryRecords();
    that.getAttributeInList();
    that.getWarehouseList(); // 获取仓库列表
    that.getAttributeOutList();
    that.getUnitRecords();
    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    that.topHeaderParam.create_user = that.userInfo.client_id;
    that.topHeaderParam.create_name = that.userInfo.name;
  },
  methods: {
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 药品 start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /
    /**
     * 初始化模版数据
     *
     */
    async initTemplate() {
      let me = this;
      me.dataName = "storehouseRecords";
      me.getUrl = "Storage/Material/getStorehouseRecords";
      me.delUrl = "Storage/Material/delStorehouseItem";
      // me.getStorehouseRecords(0); //默认0 获取不带参数
      await me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    handleClick(tab, event) {
      let me = this;
      me.changeParam.page_no = 1;
      me.changeParam.current = 1;
      switch (Number(tab.index)) {
        case 0:
          me.dataName = "storehouseRecords";
          me.getUrl = "Storage/Material/getStorehouseRecords";
          me.delUrl = "Storage/Material/delStorehouseItem";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 1:
         me.dataName = "stockData";
         me.getUrl = "Storage/Stock/getStockRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
        me.getListRecords(me.dataName, me.getUrl, me.changeParam);
        break;
        case 2:
          // me.getStockinRecords(1); //1=库房 2== 药房
          // me.getAttributeRecords(1);
          me.changeParam.stype = 1;
          me.dataName = "stockinsData";
          me.getUrl = "Storage/Stockin/getStockinRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);

          break;
        case 3:
          // me.getStockoutRecords(1); //1=库房 2== 药房
          // me.getAttributeRecords(2);
          me.changeParam.stype = 1;
          me.dataName = "stockoutsData";
          me.getUrl = "Storage/Stockout/getStockoutRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);

          break;
        case 4:
          // me.getStockinRecordsList(1); //1=库房 2== 药房
          me.changeParam.stype = 1;
          me.dataName = "stockinRecordsList";
          me.getUrl = "Storage/StockinRecord/getStockinRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 5:
          // me.getStockoutRecordsList(1); //1=库房 2== 药房
          me.changeParam.stype = 1;
          me.dataName = "stockoutRecordsList";
          me.getUrl = "Storage/StockoutRecord/getStockoutRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 6:
          me.changeParam.warning = 1;
          me.dataName = "stockWarningList";
          me.getUrl = "Storage/Warning/getWarningRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
        case 7:
          me.changeParam.warning = 1;
          me.dataName = "timeWarningList";
          me.getUrl = "Storage/Warning/getStockWarningRecords";
          // me.getStorehouseRecords(0); //默认0 获取不带参数
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
          break;
      }
    },
    onDelSelectItem(data) {
      let me = this;
      me.$confirm("确认删除信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.delPublicItem(me.delUrl, data);
          //me.delExaminationTemplateItem(data);
        })
        .catch();
    },
    async delPublicItem(url, data) {
      let me = this;
      let res = await mock(url, data);
      if (res.status === 200) {
        me.$message({
           showClose: true,
          type: "success",
          message: "删除成功!",
        });
        // me.onGetLeftChange(fun);
        await me.getListRecords(me.dataName, me.getUrl, me.changeParam);
      } else {
        me.$message({
           showClose: true,
          type: "warning",
          message: res.message,
        });
      }
    },
    /**
     * 新增提交
     */
    onSubmitAddMaterial(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.setMaterialRecordItem(data);
        })
        .catch();
    },
    /**
     * 编辑提交
     * @param data
     */
    onSubmitEditDrugs(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.setPharmacyItem(data);
        })
        .catch();
    },
    /**
     * 编辑药品记录时点击获取单列记录 storehouse
     * @param val
     */
    onChangeEditMaterial(val) {
      let me = this;
      me.getStorehouseRecordItem(val);
    },
    onSubmitEditStorehouse(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.setStorehouseRecordItem(data);
        })
        .catch();
    },
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 药品 end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 药品 end  入库start** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /
    // // 新赠入库，获取药品分类
    // onGetCategoryRedords() {
    //   let me = this;
    //   me.getCategoryRedords();
    // },
    //change药品分类->get药品类别
    onGetStorehouseRecord(val) {
      let me = this;
      me.getStorehouseRecords(val);
    },

    // 公用
    //** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 药品 end  入库start** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /
    //新赠入库 保存
    onSaveStorehouseStockout(dataOne, dataTwo) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.setStockoutRecordItem(dataOne, dataTwo); //保存stockin数据
          // me.setStockinRecords(dataTwo);//保存stockin_record数据
        })
        .catch();
    },
    ///** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 入库end 出库start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /

    //新赠入库 保存
    onSaveStorehouseStockin(dataOne, dataTwo) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          await me.setStockinRecordItem(dataOne, dataTwo); //保存stockin数据
          // me.setStockinRecords(dataTwo);//保存stockin_record数据
        })
        .catch();
    },

    ///** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 入库end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /

    // 【调用事件{A100}】获取主诉管理模版树结构->默认parent=0
    async getListRecords(param, url, data) {
      showLoading();
      let me = this;
      //me.loading = true;
      //me.treeRecords = await mock(url, data);
      try {
        let res = await mock(url, data);

        if (res.status === 200 || res.length > 0) {
          me[param] = res.data;
          if (res.data === null) {
            me[param] = res;
          }
        }
        me.loading = false;
        hideLoading();
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    //分页点击时间
    onChangePagination(val) {
      let me = this;
      //let refName = me.dataName;
      me.changeParam.page_no = val.current;
      me.changeParam.current = val.current;
      me.loading = true;
      // me.changeParam.total= val.total;
      // this.$nextTick(() => {
      //   this.$refs[refName].pageChangeSubmit();
      // });
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onChangeSubmit(form, isRestore = false) {
      let me = this;
      if (isRestore) {
        me.changeParam.page_no = 1;
        me.changeParam.current = 1;
      }
      me.loading = true;
      //自动把提交事件时的tophead参数合并到changeParam
      //以便通过changeParam把所有参数一起传给后端
      me.formParam(form, "add");
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    //重置
    onSubmitReset(form) {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = undefined;
      me.changeParam.month = undefined;
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      me.formParam(form, "del");
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onTodayClick() {
      let me = this;

      me.changeParam.today = "today";
      me.changeParam.week = undefined;
      me.changeParam.month = undefined;
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      // me.timeParam.today = "today";
      // me.formParam(me.timeParam,"add");
      me.formatDate();
      // me.getStockinRecordsList(1);
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onWeekClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = "week";
      me.changeParam.month = undefined;
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      // me.timeParam.week = "week";
      // me.formParam(me.timeParam,"add");
      me.formatDate();
      // me.getStockinRecordsList(1);
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onMonthClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = undefined;
      me.changeParam.month = "month";
      me.changeParam.year = undefined;
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      //  me.timeParam.month = "month";
      // me.formParam(me.timeParam,"add");
      me.formatDate();
      // me.getStockinRecordsList(1);
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    onYearClick() {
      let me = this;
      me.changeParam.today = undefined;
      me.changeParam.week = undefined;
      me.changeParam.month = undefined;
      me.changeParam.year = "year";
      me.changeParam.end_time = undefined;
      me.changeParam.begin_time = undefined;
      //  me.timeParam.year = "year";
      // me.formParam(me.timeParam,"add");
      me.formatDate();
      // me.getStockinRecordsList(1);
      me.getListRecords(me.dataName, me.getUrl, me.changeParam);
    },
    ///** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** 记录end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** /

    /**
     * 获取入库类型
     */
    getAttributeInList() {
      let me = this;
      let data = {
        type: 1,
      };
      me.getUrls = "configuration/Stock/Attribute/getRecords";
      me.getListRecords("attributeInList", me.getUrls, data);
    },
    /**
     * 获取出库类型
     */
    getAttributeOutList() {
      let me = this;
      let data = {
        type: 2,
      };
      me.getUrls = "configuration/Stock/Attribute/getRecords";
      me.getListRecords("attributeOutList", me.getUrls, data);
    },
    /**
     * 获取仓库列表
     */
    getWarehouseList() {
      let me = this;
      me.getUrls = "configuration/Stock/Warehouse/getRecords";
      me.getListRecords("warehousList", me.getUrls);
    },

    /**
     * 设置物资
     * @param val
     * @returns {Promise<void>}
     */
    async setMaterialRecordItem(val) {
      let me = this;
      try {
        let res = await mock("Storage/Material/setMaterialRecordItem", val);
        if (res.status === 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.storehouseRecords.changeDrawer(false);
          me.dataName = "storehouseRecords";
          me.getUrl = "Storage/Material/getStorehouseRecords";
          await me.getListRecords(me.dataName, me.getUrl, me.changeParam);
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 获取仓库记录列表
     * @param val
     */
    getStorehouseRecords(val) {
      let me = this;
      let data = {
        storehouse_category_id: val,
      };
      if (val !== 0) {
        let urls = "Storage/Material/getStorehouseRecords";
        me.getListRecords("storehouseSelectRecords", urls, data);
      } else {
        let urls = "Storage/Material/getStorehouseRecords";
        me.getListRecords("storehouseRecords", urls, data);
      }
    },
    getStorehouseRecordItem(val) {
      let me = this;
      let data = {
        storehouse_id: val,
      };
      let dataName = "storehouseRecordItem";
      let urls = "Storage/Material/getStorehouseRecordItem";
      me.getListRecords(dataName, urls, data);
    },
    //编辑物资记录
    async setStorehouseRecordItem(val) {
      let me = this;
      try {
        let res = await mock("Storage/Material/setStorehouseRecordItem", val);
        if (res.status === 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.storehouseRecords.changeDrawer(false);
          me.dataName = "storehouseRecords";
          me.getUrl = "Storage/Material/getStorehouseRecords";
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    //change药品分类->get药品类别
    async getStockinPharmacyRecords(val) {
      let me = this;
      me.recordsstockin = await mock("public/Storehouse/getRecords", {
        storehouse_category_id: val,
      });
    },
    //保存stockin数据
    async setStockinRecordItem(dataOne, dataTwo) {
      let me = this;
      try {
        let res = await mock("Storage/Stockin/setStockinRecordItem", {
          stockinData: dataOne,
          stockinRecordData: dataTwo,
        });
        if (res.status === 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.stockinsData.changeDrawer(false);
          me.dataName = "stockinsData";
          me.getUrl = "Storage/Stockin/getStockinRecords";
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    //保存stockin数据
    async setStockoutRecordItem(dataOne, dataTwo) {
      let me = this;
      try {
        let res = await mock("Storage/Stockout/setStockoutRecordItem", {
          stockoutData: dataOne,
          stockoutRecordData: dataTwo,
        });
        if (res.status === 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.$refs.stockoutsData.changeDrawer(false);
          me.dataName = "stockoutsData";
          me.getUrl = "Storage/Stockout/getStockoutRecords";
          me.getListRecords(me.dataName, me.getUrl, me.changeParam);
        } else {
          me.$message({
             showClose: true,
            type: "warning",
            message: res.message,
          });
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    //生产厂家
    getFactoryRecords() {
      let me = this;
      let dataName = "factoryRecords";
      let url = "configuration/Stock/Factory/getRecords";
      me.getListRecords(dataName, url);
    },
    //供应商
    getSupplierRecords() {
      let me = this;
      let dataName = "supplierRecords";
      let url = "configuration/Stock/Supplier/getRecords";
      me.getListRecords(dataName, url);
    },
    //品牌
    getBrandRecords() {
      let me = this;
      let dataName = "brandRecords";
      let url = "configuration/Stock/Brand/getRecords";
      me.getListRecords(dataName, url);
    },
    //物资类目
    getStorehouseCategoryRecords() {
      let me = this;
      let dataName = "storehouseCateRecords";
      let url = "configuration/Stock/Storehouse/getRecords";
      me.getListRecords(dataName, url);
    },
    // 规格单位
    getUnitRecords() {
      let me = this;
      let dataName = "unitRecords";
      let url = "configuration/Stock/Unit/getRecords";
      let data = {
        //type: 2,
        param: true,
      };
      me.getListRecords(dataName, url, data);
    },

    formParam(form, action) {
      let me = this;
      if (action === "add") {
        Object.getOwnPropertyNames(form).forEach(function (key) {
          if (form[key] !== undefined && form[key] !== null) {
            me.changeParam[key] = form[key];
          }
        });
      } else {
        Object.getOwnPropertyNames(form).forEach(function (key) {
          me.changeParam[key] = undefined;
        });
      }
    },
    formatDate(val) {
      let me = this;
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      me.changeParam.todayTime = `${year}-${month}-${day}`;
      // me.nowDate = `${year}-${month}-${day}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  // width: 100%;
  margin: 4px 10px 20px;
  // box-shadow: 0 2px 7px 0 #d8d8d8;
}
</style>
