<template>
  <WorkbenchLayout>
    <template v-slot:workbench-operation-main>
      <div class="work-content">
        <!-- 欢迎语 -->
        <div class="header">
          <el-image
            style="width: 25px; height: 22px"
            :src="iconMessage"
          ></el-image>
          <div class="header-title">
            欢迎你 {{ userInfo.name }} 医生，请开始今天的工作吧！
          </div>
        </div>

        <!-- 组件引入 -->
        <CenterStatistics :statics_data="statics_data"></CenterStatistics>
        <CenterIncome :statics_data="statics_data"></CenterIncome>
        <BottonMain></BottonMain>
      </div>
    </template>
  </WorkbenchLayout>
</template>

<script>
import { mock, components, layouts } from "@/helper";

export default {
  components: {
    ...layouts(["Workbench"]),
    ...components(
      ["Center.Statistics", "Center.Income", "Botton.Main"],
      "workbench"
    ),
  },
  data() {
    return {
      statics_data: {},
      userInfo: "", // 当前登陆账号信息
      iconMessage: "../../image/work_1.png", // 消息icon图标
    };
  },
  mounted() {
    this.getStatisticData();
    this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
  },
  methods: {
    /**
     * 首页统计数据
     */
    async getStatisticData() {
      let me = this;

      let res = await mock("workbench/Center/getStatisticalData");
      me.statics_data = res.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.work-content {
  height: 91vh;
  overflow: auto;

  .header {
    width: 100%;
    height: 77px;
    line-height: 77px;
    margin: 4px 0px 20px;
    box-shadow: 0 2px 7px 0 #d8d8d8;
    background: #e3efed;
    display: flex;
    align-items: center;
    justify-content: center;

    > .header-title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #6eaaa2;
      margin-left: 12px;
    }
  }
}
</style>
