<template>
	<Container class="centent hb-layout-flex-1 hb-layout-vertical">

		<Header
			@submit="handleSubmit"
			@submitreset="onSubmitReset"
			ref="header"
			:loading="loading"
			:warehousParam="warehousParam"
			:categoryRecords="categoryRecords"
			@getcategoryrecords="onGetCategoryRecords"
			@getshorehouserecord="onGetStorehouseRecord"
			@savestorehousestockout="onSaveStorehouseStockout"
			:recordsstockout="recordsstockout"
			:storehouseSelectDatas="storehouseSelectDatas"
			:attributeDatas="attributeDatas.items"
			:warehousDatas="warehousDatas.items"
			:smallunitDatas="smallunitDatas"
		></Header>
		
		<List :tableData="Data.items" :loading="loading"></List>

		<BasicFooter
			:loading="loading"
			:current="Param.current"
			:size="Param.page_size"
			:total="Data.total_result"
			@change="handlePaginationChange"
		></BasicFooter>
	</Container>
</template>

<script>
import {components, blocks} from "@/helper";

export default {
	components: {
		...components(["Header", "List"], "storage.Delivery"),
		...components(["Container"]),
		...blocks(["BasicFooter"], "Footer"),
	},
	
	props: {
		Data: {},
		Param: {},
		loading: {
			default: false,
		},
		attributeDatas: {},
		warehousDatas: {},
		warehousParam: {},
		categoryRecords: {},
		recordsstockout: {},
		stockoutsData: {},
		storehouseSelectDatas: {},
		smallunitDatas:{}
	},
	
	data() {
		return {};
	},
	
	methods: {
		onGetCategoryRecords() {
			this.$emit("getcategoryrecords");
		},
		onGetStorehouseRecord(val) {
			this.$emit("getshorehouserecord", val);
		},
		onSaveStorehouseStockout(dataOne, dataTwo) {
			this.$emit("savestorehousestockout", dataOne, dataTwo);
		},
		// 分页变化改动
		handlePaginationChange(val) {
			this.$emit("changepagination", val);
		},
		pageChangeSubmit() {
			this.$nextTick(() => {
				this.$refs.header.handleFormSubmit();
			});
		},
		
		// 确定查询
		handleSubmit(form, isRestore = false) {
			this.$emit("changesubmit", form, isRestore);
		},
		onSubmitReset(form) {
			let me = this;
			me.$emit("submitreset", form);
		},
		changeDrawer(v) {
			let me = this;
			me.$refs.header.changeDrawer(v);
		},
	},
};
</script>

<style lang="scss" scoped>
.details {
	.right {
		width: 300px;
	}
}
</style>
