<template>
  <Container class="charge-details-content">
    <div class="list">
      <div id="regionChart" :style="{width: '470px', height: '330px'}"></div>
    </div>
  </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props:{
    
  },
  data() {
    return {

    }
  },
  mounted(){
    this.drawLine();
  },
  methods: {
    drawLine(){
        // 基于准备好的dom，初始化echarts实例
        let regionChart = this.$echarts.init(document.getElementById('regionChart'))
        // 绘制图表
        regionChart.setOption({
            tooltip: {
              trigger: 'item'
            },
            legend: {
              orient: 'vertical',
              right: 'right',
              top: '30'
            },
            xAxis: {
              show: false
            },
            yAxis: {
              show: false
            },
            series: [{
                name: '数据',
                type: 'pie',
                right: '80',
                emphasis: {
                  label: {
                    show: true,
                    fontSize: '20',
                    fontWeight: 'bold'
                  }
                },
                data: [
                  {value: 2548, name: '内科'},
                  {value: 345, name: '外科'},
                  {value: 233, name: '妇科'},
                  {value: 1654, name: '儿科'},
                  {value: 145, name: '男科'},
                  {value: 675, name: '其他科室'}
                ],
                color:['#5470C6','#92CC74','#F9C858','#ED6667','#73C0DD','#3BA273']
            }]
        });
    }
  }


}
</script>

<style lang="scss" scoped>
.charge-details-content{
  margin-top: 15px;
  .list{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
</style>
