<template>
  <el-card class="box-card" shadow="hover" :class="{
      'see-patient-list-item':true,
        selected
    }" :body-style="{ padding: '20px 10px' }">
    <el-row style="width: 100%" type="flex" justify="center" align="middle">
      <el-col :span="5">
        <el-avatar :src="header">
          <el-avatar
              src="https://ss3.bdstatic.com/70cFv8Sh_Q1YnxGkpoWK1HF6hhy/it/u=2606549919,3922038670&fm=26&gp=0.jpg"/>
        </el-avatar>
      </el-col>
      <el-col :span="7">
        <div class="name">{{ name }}</div>
      </el-col>
      <el-col :span="4">
        <el-tag size="small" type="warning">{{ member }}</el-tag>
      </el-col>

      <el-col :span="5">
        <div v-if="typeof time === 'string'" class="hb-layout-flex-1 time">{{ time }}</div>
        <div v-else class="hb-layout-flex-1 time hb-layout-vertical">
          <span>{{ time.start }}</span>
          <span>-</span>
          <span>{{ time.end }}</span>
        </div>
      </el-col>

      <el-col :span="5">

        <el-tag size="small" :type="statusMap[state].type">{{  statusMap[state].text }}</el-tag>
      </el-col>
    </el-row>

  </el-card>
</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components: components([
    'Container'
  ]),
  props: {
    header: {
      required: true,
      type: String
    },
    name: {
      required: true,
      type: String
    },
    member: {
      required: true,
      type: [
        String,
        Number
      ]
    },
    time: {
      required: true,
      type: [
        String,
        Object
      ]
    },
    state: {
      required: true,
      type: [
        String,
        Number
      ]
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      lvlMap:{

      },
      statusMap: [
        {
          text: '待诊',
          type: ''
        },
        {
          text: '已诊',
          type: 'warning'
        },
        {
          text: '检测中',
          type: 'success'
        },
        {
          text: '待打款',
          type: 'danger'
        },
        {
          text: '已打款',
          type: 'info'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

$see-patient-list-item-height: 80px;

	.see-patient-list-item {
		height: $see-patient-list-item-height;
		align-items: center;

		>.header >img {
			width: 30px;
			height: 30px;
		}

		&.selected {
			box-shadow: 0 2px 7px 0 #d8d8d8;
			margin: 2px 0;
      background: #92c2f957;
		}
    &:hover{
      background: #92c2f921;
      color: #2063b695;
    }

		border-bottom: 1px solid #e1e1e1;

		>.logo {
			width: 46px;
			height: 46px;
			margin-right: 10px;

			>img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
    font-size: 14px;
		.name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;

		}

		>.grade {
			padding: 3px 12px;
			background: #F1BE5E;
			border: 1px solid #979797;
			margin-right: 15px;
		}
    .name{
      font-size: 12px;
    }
		.time {
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #0B7DFF;
			line-height: 15px;
      display: flex;
      justify-content: center;
		}

		>.time,
		.state {
			display: flex;
      justify-content: center;

		}
	}


</style>
