/**
 * 获取一个病历信息 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/prescription/method/get.prescription.list';

export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        })).then(({
            data
        }) => data)
        .then(({
            data
        }) => (data))
        .then(({
            items
        }) => items.map(({
            getPharmacy,
            count,
            prescription_id,
            remark,
            status
        }) => ({
            name:getPharmacy.name,
            englishName:getPharmacy.english_name,
            count:count,
            unit:getPharmacy.unit,
            price:getPharmacy.retail_price,
            remark:remark,
            e_ID:prescription_id,
            status:status
        })));
}