/**
 * 获取医生一周预约记录 API
 */

 import axios from 'axios';
 import qs from 'qs';
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/appointment_record/method/get.doctor.appointment.basic.list';
 //let url = 'https://api.demo.hobooa.com/mock/91/api/v2/appointment_record/method/get.doctor.appointment.basic.list';

 export default (data) => {
 
     return axios.post(url, qs.stringify({
             ...data
         }))
         .then(({
             data
         }) => data)
         .then(({
             data
         }) => data)
         .then(({
             items
         }) => items.map(({
             real_name,
             sex,
             age,
             medical_record_no,
             appointment_record_no,
             start,
             end,
             patient,
             duration,
             doctor_id,
             status,
             diagnosis_status,
             see_status
         }) => ({
            real_name: real_name,
            sex: sex,
            age: age,
            medical_record_no: medical_record_no,
            appointment_record_no: appointment_record_no,
            start: start,
            end: end,
            duration:duration,
            patient: patient,
            doctor:doctor_id,
            status,
            diagnosis_status,
            see_status
         })));
 }