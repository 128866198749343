<template>
	<Container class="see-detail-treatment-project">
		<div>
			<el-row type="flex" class="row-border">
				<!-- <el-col :span="1">
					<div class="grid-content">选择</div>
				</el-col> -->
				<el-col :span="6">
					<div class="grid-content">药物名称</div>
				</el-col>
				<el-col :span="4">
					<div class="grid-content"> 用法</div>
				</el-col>
				<el-col :span="5">
					<div class="grid-content">用量</div>
				</el-col>
				<el-col :span="2">
					<div class="grid-content">数量</div>
				</el-col>
				<el-col :span="5">
					<div class="grid-content"> 备注/禁忌</div>
				</el-col>
        <el-col :span="2">
					<div class="grid-content"></div>
				</el-col>
			</el-row>

      <template v-for="({
          pharmacy_template
        } , index) in rows">

        <el-row type="flex" class="row-border">
          <!-- <el-col :span="1">
            <div class="grid-content bg-purple">
              <input type="checkbox"  @del="del(index)" />
            </div>
          </el-col> -->
          <el-col :span="6">
            <div class="grid-content bg-purple" style="border-right: 1px solid #979797;">

              <el-select style="width:100%;" v-model="rows[index].pharmacy_id" @change="selectPrescriptionChange($event, index)"  filterable default-first-option>
                <el-option v-for="item in pharmacy_template" :key="item.pharmacy_id" :label="item.full_name" :value="item.pharmacy_id">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="4">
            <!-- {{usage}} -->
            <div class="grid-content bg-purple">
              <el-select style="width:100%;" v-model="rows[index].usage" filterable allow-create default-first-option placeholder="选择用法">
                <el-option v-for="(item,index) in usageData" :key="index" :label="item.name" :value="item.name"></el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple-light">
              <el-select style="width:100%;" v-model="rows[index].dosage" filterable allow-create default-first-option
                         placeholder="选择用量信息">
                <el-option v-for="(item,index) in dosage_template" :key="index" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>
          </el-col>
          <el-col :span="2">
            <div class="grid-content bg-purple">
              <!-- <el-input-number v-model="rows[index].count"  :min="1" :max="10" label="描述文字" size="small"></el-input-number> -->
              <el-input v-model="rows[index].count" placeholder="请填写数量"></el-input>
            </div>
          </el-col>
          <el-col :span="5">
            <div class="grid-content bg-purple-light bg-flex">
              <el-select style="width:100%;" v-model="rows[index].remark" multiple filterable allow-create default-first-option placeholder="选择备注信息">
                <el-option v-for="(item, index) in remark_template" :key="index" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>
          </el-col>
           <el-col :span="2">
            <div class="grid-content bg-purple">
               <el-button type="primary" icon="el-icon-minus" size="mini"  @click.native="onDeleteButtonClick(index)"></el-button>
            </div>
          </el-col>
        </el-row>
      </template>
		</div>
	</Container>

</template>

<script>
	import {
		components
	} from '@/helper';

	export default {
		props: {
			list: {
				type: Array,
				default:  () => []
			},
      pharmacy:{
        type: Array,
        default: () => []
      },
      pharmacy_template:{
         type: Array,
        default: () => []
      },
      usageData:{},
      dosage_template:{},
      remark_template:{}
		},
		components: components([
			'Container'
		]),
		data() {
			return {
        rows: []
			}
		},
    watch:{
		  "pharmacy":{
        deep:true,
        immediate: true,
        handler(newVal,oldVal) {
          this.rows = newVal.map((item)=>{
            return {
              prescription_id: item.prescription_id,  //记录ID
              pharmacy_id: item.pharmacy_id,  //药品ID
              type:  item.type,   //药品类型 1 西药 2中药 3 输液
              usage:  item.usage,   //用法
              dosage:  item.dosage,   //用量
              count:  item.count,   //数量
              remark:  item.remark,   //备注
              dosage_template: item.dosage_template,
              remark_template: item.remark_template,
              pharmacy_template: item.pharmacy_template   //药品模版
            };
          });
         // console.log("数据"+JSON.stringify( newVal));

        }
      }
    },
		methods: {
      /**
       * 添加一条空数据
       * @param pharmacy_template
       */
      add(pharmacy_template , prescriptionType){

        this.rows.push({
          prescription_id: 0,  //记录ID
          pharmacy_id: undefined,  //药品ID
          type:  prescriptionType,   //药品类型 1 西药 2中药 3 输液
          usage:  '',   //用法
          dosage:  '',   //用量
          count:  1,   //数量
          remark:  '',   //备注
          dosage_template: undefined,
          remark_template: undefined,
          pharmacy_template: pharmacy_template   //药品模版
        }) ;
      },
     del(index){
        // console.log("delprescription",index);
        // this.$emit('delprescription',this.rows[index].prescription_id);
         this.rows.splice(index, 1);
      },
      //选择药品后
      selectPrescriptionChange(event , index){

        let indexOfs = this.rows[index].pharmacy_template.findIndex((item)=> {
          return item.pharmacy_id == event;
        });

        if(indexOfs > -1){
          let row = this.rows[index].pharmacy_template[indexOfs];
          this.rows[index].usage = row.usage;
          this.rows[index].dosage_template = row.dosage;
          this.rows[index].remark_template = row.remarks;
        }
      },
         /**
     * 删除的方法
     * @param index
     */
    onDeleteButtonClick(index) {
     this.$emit('delprescription',this.rows[index].prescription_id);
      this.rows.splice(index, 1);
    },

      /**
       * 返回添加的数据
       * @returns {*}
       */
      getRows(){
        //重组，修改看诊记录保存是所需的结构
        return this.rows.map((item)=>{
          return {
            prescription_id: item.prescription_id,
            pharmacy_id: item.pharmacy_id,
            type: item.type,
            usage: item.usage,
            dosage: item.dosage,
            count: item.count,
            remark: item.remark
          }
        });

      }
		}
	};
</script>

<style lang="scss" scoped>
	.see-detail-treatment-project {
		.el-row {
			width: 100%;
			border-top: 1px solid #979797;
			border-left: 1px solid #979797;

			// background-color: #fffbe5;

			&:last-child {
				margin-bottom: 0;
				border-bottom: 1px solid #979797;
			}
		}
		.row-border{
			border-right: 1px solid #979797;
		}

		.bg-purple {
			// background: #e5e9f2;
		}

		.bg-purple-light {
			// background: #fff;
			border-left: 1px solid #979797;
			border-right: 1px solid #979797;
		}

    .bg-flex{
      overflow: hidden;
      white-space: nowrap;
    }

		.grid-content {
			height: 40px;
			line-height: 40px;
			text-align: center;
      font-size: 13px;
		}

		.row-bg {
			padding: 10px 0;
			// background-color: #f9fafc;
		}
	}

  /deep/.el-select__tags{
    overflow: hidden;
    white-space: nowrap;
    flex-wrap: nowrap;
  }
</style>
