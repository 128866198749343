<template>
  <Container class="statistics">
    <div class="text item">
      
             <el-table
			 style="width: 100%"
			 height="calc(100vh - 200px)"
               :data="tableData"
               @selection-change="handleSelectionChange"
               @sort-change="sortChange">
               <el-table-column align="center" type="selection" width="55"/>
     
               <el-table-column
                 v-if="tabPane === 'delete'"
                 label="来源"
                 align="center"
                 width="80">
                 <template slot-scope="scope">
                   <span>{{scope.row.status ? '出售中' : '已下架'}}</span>
                 </template>
               </el-table-column>
     
               <el-table-column
                 label="商品"
                 prop="goods_id"
                 sortable="custom"
                 min-width="380">
                 <template slot-scope="scope">
                   <div class="goods-summary cs-mb-5">
                     <span class="cs-mr">商品货号：{{scope.row.goods_code}}</span>
                     <span>创建日期：{{scope.row.create_time}}</span>
                   </div>
     
                   <el-image
                     class="goods-image cs-cp"
                     @click="handleView(scope.row.goods_id)"
                     :src="scope.row.attachment | getPreviewUrl"
                     fit="contain"
                     lazy/>
     
                   <div class="goods-info cs-ml">
                     <p class="action">
                       <span
                         @click="handleView(scope.row.goods_id)"
                         :title="scope.row.name"
                         class="link">{{scope.row.name}}</span>
     
                         <i
                            class="el-icon-edit goods-edit active"
                            @click="setGoodsName(scope.$index)"/>
                     </p>
     
                     <p class="action">
                       <span
                         :title="scope.row.product_name"
                         class="son">{{scope.row.product_name}}</span>
     
                       <i 
                          class="el-icon-edit goods-edit active"
                          @click="setGoodsProduct(scope.$index)"/>
                     </p>
     
                     <p>
                       <u v-for="(item, index) in goodsTab" :key="index">
                         <el-tag
                           v-if="scope.row[index]"
                           :type="item.type"
                           :disable-transitions="true"
                           class="cs-mr-10"
                           effect="dark"
                           size="mini">
                           {{item.name}}
                         </el-tag>
                       </u>
                     </p>
                   </div>
                 </template>
               </el-table-column>
     
               <el-table-column
                 label="本店价"
                 prop="shop_price"
                 sortable="custom">
                 <template slot-scope="scope">
                   <div class="action">
                     <span class="goods-shop-price">{{scope.row.shop_price | getNumber}}</span>
                     <i v-if="tabPane !== 'delete' && auth.price"
                        class="el-icon-edit-outline goods-edit active"
                        @click="setGoodsPriceOrStore(scope.$index, 'price')"/>
                   </div>
                 </template>
               </el-table-column>
     
               <el-table-column
                 label="库存"
                 prop="store_qty"
                 sortable="custom">
                 <template slot-scope="scope">
                   <div class="action">
                     <span>{{scope.row.store_qty}}</span>
                     <i v-if="tabPane !== 'delete' && auth.store"
                        class="el-icon-edit-outline goods-edit active"
                        @click="setGoodsPriceOrStore(scope.$index, 'store')"/>
                   </div>
                 </template>
               </el-table-column>
     
               <el-table-column
                 label="总销量"
                 prop="sales_sum"
                 sortable="custom">
               </el-table-column>
     
               <el-table-column
                 label="排序值"
                 prop="sort"
                 align="center"
                 sortable="custom"
                 min-width="110">
                 <template slot-scope="scope">
                   <el-input-number
                     v-if="tabPane !== 'delete' && auth.sort"
                     v-model="scope.row.sort"
                     style="width: 88px;"
                     size="mini"
                     controls-position="right"
                     :min="0"
                     :max="255"
                     @change="handleSort(scope.$index)">
                   </el-input-number>
                   <span v-else>
                     {{scope.row.sort}}
                   </span>
                 </template>
               </el-table-column>
     
               <el-table-column
                 label="操作"
                 align="center">
                 <template slot-scope="scope">
                   <div class="goods-text">
                     <p v-if="tabPane !== 'delete' && auth.set">
                       <el-link
                         class="button"
                         type="primary"
                         @click="handleEdit(scope.row.goods_id)"
                         :underline="false">编辑商品</el-link>
                     </p>
     
                     <p v-if="tabPane !== 'delete' && auth.copy">
                       <el-link
                         class="button"
                         type="primary"
                         @click="handleCopy(scope.row.goods_id)"
                         :underline="false">复制商品</el-link>
                     </p>
     
                     <p v-if="tabPane !== 'delete' && auth.shelves">
                       <el-link
                         class="button"
                         type="primary"
                         @click="handleStatus(scope.$index, Number(!scope.row.status))"
                         :underline="false">{{scope.row.status ? '下架' : '上架'}}</el-link>
                     </p>
     
                     <p v-if="auth.del">
                       <el-link
                         class="button"
                         type="primary"
                         @click="handleDelete(scope.$index, true)"
                         :underline="false">{{tabPane === 'delete' ? '彻底删除' : '删除'}}</el-link>
                     </p>
     
                     <p v-if="tabPane === 'delete' && auth.restore">
                       <el-link
                         class="button"
                         type="primary"
                         @click="handleDelete(scope.$index, false)"
                         :underline="false">恢复</el-link>
                     </p>
                   </div>
                 </template>
               </el-table-column>
             </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";
import util from "@/utils/util";
export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },

    tableData: {},
    orderTotal: {
      default: () => {},
    },
  },
  watch:{
	  tableData: function(val){
			//console.log(val);	  
		}
  },
 data() {
     return {
       currentTableData: [],
       multipleSelection: [],
       auth: {
         add: false,
         set: false,
         del: false,
         restore: false,
         copy: false,
         shelves: false,
         recommend: false,
         new: false,
         hot: false,
         sort: false,
         price: false,
         store: false
       },
       tabPane: 'sale',
       tabList: { sale: '出售中', stock: '已下架', delete: '回收站' },
       goodsTab: {
         is_postage: {
           type: '',
           name: '包邮'
         },
         is_recommend: {
           type: 'success',
           name: '推荐'
         },
         is_new: {
           type: 'danger',
           name: '新品'
         },
         is_hot: {
           type: 'warning',
           name: '热卖'
         }
       },
       dialogLoading: false,
       nameForm: {},
       nameFormVisible: false,
       productForm: {},
       productFormVisible: false,
       sellForm: [],
       sellLoading: false,
       sellFormVisible: false,
       sellFormLoading: false,
       rules: {
         name: [
           {
             required: true,
             message: '商品名称不能为空',
             trigger: 'blur'
           },
           {
             max: 200,
             message: '长度不能大于 200 个字符',
             trigger: 'blur'
           }
         ],
         product_name: [
           {
             required: true,
             message: '商品促销名不能为空',
             trigger: 'blur'
           },
           {
             max: 100,
             message: '长度不能大于 100 个字符',
             trigger: 'blur'
           }
         ]
       },
       type: '',
       typeMap: {
         price: '修改价格',
         store: '修改库存'
       }
     }
   },
   filters: {
     getPreviewUrl(val) {
       if (Array.isArray(val) && val.length > 0) {
         if (val[0].source) {
           return util.getImageCodeUrl(val[0].source, 'goods_image_x80')
         }
       }
 
       return ''
     },
     getNumber(val) {
       return util.getNumber(val)
     }
   },
   watch: {
     tableData: {
       handler(val) {
         this.currentTableData = val
         this.multipleSelection = []
       },
       immediate: true
     }
   },
   activated() {
     this.updateChange({
       name: 'goods-admin-list',
       source: this.currentTableData,
       key: 'goods_id'
     })
   },
   mounted() {
   },
   methods: {
     // 获取列表中的编号
     _getIdList(val) {
       if (val === null) {
         val = this.multipleSelection
       }
 
       let idList = []
       if (Array.isArray(val)) {
         val.forEach(value => {
           idList.push(value.goods_id)
         })
       } else {
         idList.push(this.currentTableData[val].goods_id)
       }
 
       return idList
     },
     // 点击切换标签
     handleClick(tab) {
       let config = { status: 1, is_delete: 0 }
       switch (tab.name) {
         case 'stock':
           config.status = 0
           break
 
         case 'delete':
           config.is_delete = 1
           break
       }
 
       this.$emit('tabs', config)
     },
     // 选中数据项
     handleSelectionChange(val) {
       this.multipleSelection = val
     },
     // 获取排序字段
     sortChange({ column, prop, order }) {
       let sort = {
         order_type: undefined,
         order_field: undefined
       }
 
       if (column && order) {
         sort.order_type = order === 'ascending' ? 'asc' : 'desc'
         sort.order_field = prop
       }
 
       this.$emit('sort', sort)
     },
     // 打开商品预览
     handleView(goods_id) {
       this.$router.push({
         name: 'goods-admin-view',
         params: { goods_id }
       })
     },
     // 批量设置上下架状态
     handleStatus(val, status) {
       let goods_id = this._getIdList(val)
       if (goods_id.length === 0) {
         this.$message.error('请选择要操作的数据')
         return
       }
 
       this.$confirm('确定要执行该操作吗?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
         closeOnClickModal: false
       })
         .then(() => {
           setShelvesGoodsList(goods_id, status)
             .then(() => {
               util.deleteDataList(this.currentTableData, goods_id, 'goods_id')
               if (this.currentTableData.length <= 0) {
                 this.$emit('refresh', true)
               }
 
               this.$message.success('操作成功')
             })
         })
         .catch(() => {
         })
     },
     // 批量设置是否推荐
     handleRecommend(val, isRecommend) {
       let goods_id = this._getIdList(val)
       if (goods_id.length === 0) {
         this.$message.error('请选择要操作的数据')
         return
       }
 
       this.$confirm('确定要执行该操作吗?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
         closeOnClickModal: false
       })
         .then(() => {
           setRecommendGoodsList(goods_id, isRecommend)
             .then(() => {
               this.currentTableData.forEach((value, index) => {
                 if (goods_id.indexOf(value.goods_id) !== -1) {
                   this.$set(this.currentTableData, index, {
                     ...value,
                     is_recommend: isRecommend
                   })
                 }
               })
 
               this.$message.success('操作成功')
             })
         })
         .catch(() => {
         })
     },
     // 批量设置是否新品
     handleNew(val, isNew) {
       let goods_id = this._getIdList(val)
       if (goods_id.length === 0) {
         this.$message.error('请选择要操作的数据')
         return
       }
 
       this.$confirm('确定要执行该操作吗?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
         closeOnClickModal: false
       })
         .then(() => {
           setNewGoodsList(goods_id, isNew)
             .then(() => {
               this.currentTableData.forEach((value, index) => {
                 if (goods_id.indexOf(value.goods_id) !== -1) {
                   this.$set(this.currentTableData, index, {
                     ...value,
                     is_new: isNew
                   })
                 }
               })
 
               this.$message.success('操作成功')
             })
         })
         .catch(() => {
         })
     },
     // 批量设置是否热卖
     handleHot(val, isHot) {
       let goods_id = this._getIdList(val)
       if (goods_id.length === 0) {
         this.$message.error('请选择要操作的数据')
         return
       }
 
       this.$confirm('确定要执行该操作吗?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
         closeOnClickModal: false
       })
         .then(() => {
           setHotGoodsList(goods_id, isHot)
             .then(() => {
               this.currentTableData.forEach((value, index) => {
                 if (goods_id.indexOf(value.goods_id) !== -1) {
                   this.$set(this.currentTableData, index, {
                     ...value,
                     is_hot: isHot
                   })
                 }
               })
 
               this.$message.success('操作成功')
             })
         })
         .catch(() => {
         })
     },
     // 批量删除或恢复
     handleDelete(val, isDelete) {
       let goods_id = this._getIdList(val)
       if (goods_id.length === 0) {
         this.$message.error('请选择要操作的数据')
         return
       }
 
       this.$confirm('确定要执行该操作吗?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
         closeOnClickModal: false
       })
         .then(() => {
           let status = null
           switch (this.tabPane) {
             case 'sale':
             case 'stock':
               status = 1
               break
 
             case 'delete':
               status = isDelete ? 2 : 0
               break
           }
 
           delGoodsList(goods_id, status)
             .then(() => {
               util.deleteDataList(this.currentTableData, goods_id, 'goods_id')
               if (this.currentTableData.length <= 0) {
                 this.$emit('refresh', true)
               }
 
               this.$message.success('操作成功')
             })
         })
         .catch(() => {
         })
     },
     // 设置排序值
     handleSort(index) {
       setGoodsSort(
         this.currentTableData[index].goods_id,
         this.currentTableData[index].sort
       )
     },
     // 复制一个商品
     handleCopy(goods_id) {
       this.$confirm('确定要执行该操作吗?', '提示', {
         confirmButtonText: '确定',
         cancelButtonText: '取消',
         type: 'warning',
         closeOnClickModal: false
       })
         .then(() => {
           copyGoodsItem(goods_id)
             .then(res => {
               this.currentTableData.unshift({
                 ...res.data,
                 sales_sum: 0,
                 comment_sum: 0
               })
 
               this.$message.success('操作成功')
             })
         })
         .catch(() => {
         })
     },
     // 修改商品名称对话框
     setGoodsName(val) {
       const data = this.currentTableData[val]
       this.nameForm = {
         goods_id: data.goods_id,
         name: data.name,
         index: val
       }
 
       this.dialogLoading = false
       this.nameFormVisible = true
 
       this.$nextTick(() => {
         this.$refs.name.clearValidate()
         this.$refs.nameInput.select()
       })
     },
     // 请求修改商品名称
     handleSetName() {
       this.$refs.name.validate(valid => {
         if (valid) {
           this.dialogLoading = true
           const index = this.nameForm.index
 
           setGoodsItem(this.nameForm)
             .then(() => {
               this.currentTableData[index].name = this.nameForm.name
               this.nameFormVisible = false
               this.$message.success('操作成功')
             })
             .catch(() => {
               this.dialogLoading = false
             })
         }
       })
     },
     // 修改商品促销名
     setGoodsProduct(val) {
       const data = this.currentTableData[val]
       this.productForm = {
         goods_id: data.goods_id,
         product_name: data.product_name,
         index: val
       }
 
       this.dialogLoading = false
       this.productFormVisible = true
 
       this.$nextTick(() => {
         this.$refs.product.clearValidate()
         this.$refs.productInput.select()
       })
     },
     // 请求修改商品促销名
     handleSetProduct() {
       this.$refs.product.validate(valid => {
         if (valid) {
           this.dialogLoading = true
           const index = this.productForm.index
 
           setGoodsItem(this.productForm)
             .then(() => {
               this.currentTableData[index].product_name = this.productForm.product_name
               this.productFormVisible = false
               this.$message.success('操作成功')
             })
             .catch(() => {
               this.dialogLoading = false
             })
         }
       })
     },
     // 计算实际库存
     countRealStore(value) {
       value.real_store = value.store_qty + value.alter
     },
     // 修改商品价格或库存
     setGoodsPriceOrStore(index, type) {
       this.sellForm = []
       this.sellLoading = true
       this.sellFormVisible = true
       this.sellFormLoading = false
       this.type = type
 
       let specCombo = []
       const data = this.currentTableData[index]
 
       getGoodsSpecList(data.goods_id)
         .then(res => {
           if (res.data) {
             for (let key in res.data) {
               if (!Object.prototype.hasOwnProperty.call(res.data, key)) {
                 continue
               }
 
               specCombo.push({
                 ...res.data[key],
                 alter: 0,
                 real_store: res.data[key].store_qty
               })
             }
           } else {
             specCombo.push({
               key_value: '-',
               price: data.shop_price,
               store_qty: data.store_qty,
               alter: 0,
               real_store: data.store_qty
             })
           }
 
           this.sellForm = {
             goods_id: data.goods_id,
             spec_combo: specCombo,
             empty_spec: res.data == null,
             index
           }
         })
         .finally(() => {
           this.sellLoading = false
         })
     },
     // 请求修改价格或库存
     handleGoodsPriceOrStore() {
       const data = this.sellForm.spec_combo
       let formData = { goods_id: this.sellForm.goods_id }
 
       if (this.sellForm.empty_spec) {
         formData.shop_price = data[0].price
         formData.store_qty = data[0].real_store
       } else {
         formData.spec_combo = []
         data.forEach(value => {
           formData.spec_combo.push({
             ...value,
             store_qty: value.real_store
           })
         })
       }
 
       this.sellFormLoading = true
       setGoodsItem(formData)
         .then(res => {
           this.$set(
             this.currentTableData,
             this.sellForm.index,
             {
               ...this.currentTableData[this.sellForm.index],
               ...res.data
             }
           )
 
           this.sellFormVisible = false
           this.$message.success('操作成功')
         })
         .catch(() => {
           this.sellFormLoading = false
         })
     },
     // 新增商品
     handleCreate() {
       this.$router.push({
         name: 'goods-admin-create'
       })
     },
     // 编辑商品
     handleEdit(key) {
       this.$router.push({
         name: 'goods-admin-update',
         params: {
           goods_id: key
         }
       })
     }
   }
 }
 </script>
 
 <style lang="scss" scoped>
 .tab-box {
   padding: 5px 10px;
   background-color: #FFF;
 }
 
 .el-table /deep/ td {
   background-color: #FFFFFF !important;
 }
 
 .goods-summary {
   color: #000;
   font-size: 13px;
 }
 
 .goods-shop-price {
   color: #000;
 }
 
 .goods-image {
   float: left;
   width: 80px;
   height: 80px;
 }
 
 .goods-info {
   float: left;
   width: 70%;
 
   .son {
     color: #000;
     font-size: 13px;
   }
 
   p {
     margin: 0;
 
     .link:hover {
       cursor: pointer;
       color: #000;
       text-decoration: underline;
     }
   }
 }
 
 .active {
   display: none;
 }
 
 .action:hover .active {
   display: inline;
 }
 
 .goods-edit {
   padding-left: 5px;
   font-size: 13px;
   color: #000;
 
   &:hover {
     cursor: pointer;
     color: #000;
   }
 }
 
 .goods-text {
   p {
     margin: 0;
   }
 
   .button {
     padding: 0;
     font-size: 13px;
   }
 }
 
 
 
 </style>

