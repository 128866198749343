<template>
<Container class="statistics">
    <div class="text item">
        <el-table ref="list" :data="tableData" highlight-current-row stripe height="calc(100vh - 265px)" style="width: 100%">
            <el-table-column fixed type="index" width="40"></el-table-column>
            <el-table-column fixed prop="stockout_no" label="出库单号" min-width="220" align="left"></el-table-column>

            <el-table-column prop="getAttribute.name" label="出库类型" min-width="150" align="left"></el-table-column>
            <el-table-column prop="getWarehouse.name" label="出库仓库" min-width="150" align="left"></el-table-column>

            <el-table-column prop="create_name" label="出库人" min-width="150" align="left"></el-table-column>

            <el-table-column prop="product_num" label="种类数量" min-width="150" align="left"></el-table-column>
            <el-table-column prop="total_num" label="入库总数量" min-width="150" align="left"></el-table-column>
            <el-table-column prop="remarks" label="备注" min-width="150" align="left"></el-table-column>

            <el-table-column prop="create_time" label="出库日期" width="160" align="left"></el-table-column>
        </el-table>
    </div>

    <el-drawer title="出库详情" :visible.sync="detailsDrawer" size="65%" style="fontSize:20px;">
        <DeliveryDetails></DeliveryDetails>
    </el-drawer>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: {
        ...components(["DeliveryDetails"], "pharmacy.Delivery"),
        ...components(["Container"]),
    },
    props: {
        tableData: {}
    },
    data() {
        return {
            detailsDrawer: false
        };
    },
    methods: {
    }
};
</script>

<style lang="scss" scoped>
.item {
    margin-top: 10px;
}
</style>
