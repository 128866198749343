<template>
  <AppointmentLayout>
    <!--    左：医生列表-->
    <template v-slot:doctor-list>
      <LeftMain
        ref="doctorlist"
        class="hb-layout-fit-sized"
        :loading="loading"
        :data="doctors"
        @changestatus="changeStatus"
        @leftsearch="onleftSearch"
        @doctoritemselect="onDoctorItemSelect"
        @alldoctor="onAllDoctor"
        @todayevent="onTodayEvent"
        @sevenevent="onSevenEvent"
        @thirtyevent="onThirtyEvent"
        @calendarevent="onCalendarEvent"
        :allDoctors="allDoctors"
      ></LeftMain>
    </template>
    <!--    中：数据列表-->
    <template v-slot:appoientment-detail>
      <CenterMain
        ref="appoientment"
        :appoinetDate="nowDate"
        :appointmentrecords="appointmentRecords"
        :searchrecords="searchList"
        :doctors="doctors"
        @settinglist="onSettingList"
        @emptychange="onEmptyChange"
        @appointmentdetailchange="onAppointmentDetailChange"
        @medicalrecordsearch="onMedicalRecordSearch"
        @appointmentsaveclick="onAppointmentSaveClick"
        :settingDatas="settingRecords.items"
        :departmentDatas="departmentRecords"
        @handstatechange="onHandStateChange"
        @doctortimereturn="onGetDoctorTimeReturn"
        :doctorDayTime="doctorDayTime"
        :doctordaylistData="doctordayrecords"
        :doctorClassesListData ="doctorClassesList"
        :classesSettingData="classesSetting.items"
         :doctorId="centerRecordsParam.doctor_id"
      ></CenterMain>
    </template>
    <!--    右：列表数据详情-->
    <template v-slot:patient-detail>
      <RightMain
        :patientData="patientRecord"
        :signsdata="signsRecord"
        @appoientmentsaveclick="onAppoientmentSaveClick"
        @diagnosissaveclick="onDiagnosisSaveClick"
        :doctors="doctors"
        :settingDatas="settingRecords.items"
        :settingTrees="settingTrees"
        :departmentDatas="departmentRecords"
        @changestatus="onChangeStatus"
        @changediagnosisstatus="onChangeDiagnosisStatus"
        :doctorDayTime="doctorDayTime"
        :doctordaylistDataTwo="doctordayrecordstwo"
        :doctorClassesListData ="doctorClassesList"
        :classesSettingData="classesSetting.items"
      ></RightMain>
    </template>
  </AppointmentLayout>
</template>

<script>
//import Page from "@/components/Page";

import {
  // eslint-disable-next-line no-unused-vars
  mock,
  components,
  layouts,
} from "@/helper";
export default {
  components: {
    ...layouts(["Appointment"]),
    ...components(["Left.Main", "Center.Main", "Right.Main"], "appointment"),
  },
  data() {
    return {
      doctorInput: "", // 搜索医生
      doctors: [], //医生列表
      searchList: {},
      appointmentRecords: {}, // 点击医生获取的预约记录
      doctor: null, //选中的预约医生信息
      loading: {
        status: false, //预约列表loading
      },
      patientRecord: Object, //患者预约详情信息
      Detail: {
        appointment_date: "",
        appointment_record_id: "",
        appointment_record_no: "",
        appointment_remark: null,
        appointment_time: "",
        appointment_type: "",
        card_number: "",
        clinical_department_id: "",
        create_time: "",
        diagnosis_status: "",
        doctor_id: "",
        patient_information: {
          age: "",
          medical_record_id: "",
          medical_record_no: "",
          mobile: "",
          photo: null,
          real_name: "",
          sex: 0,
          user_id: 0,
        },
      },
      appointmentRecordNo: null, //患者预约编号
      doctorsParam: {
        //医生列表参数
        keywords: undefined,
        diagnosis_status: undefined,
        appointment_date: undefined,
        dayEvent: undefined, //1
        eventEvent: 1, //7
        thirtyEvent: undefined, //30
        page_no: 1,
        page_size: 10,
      },
      nowDate: "",
      states: {},
      signsRecord: Object, //患者生命体征详情信息
      centerRecordsParam: {
        //点击医生获取医生预约列表参数onItemInput
        doctor_id: undefined,
        appointment_date: undefined,
        allDoctor: 0,
        dayEvent: undefined, //1
        eventEvent: 1, //7
        week_data: 1,
        thirtyEvent: undefined, //30
      },
      settingRecords: {},
      settingTrees: [],
      departmentRecords: [],
      userInfo: {},
      allDoctors:0,
      doctordayrecords: {}, //当天排班记录
      doctorDayTime: true, // 选择的医生当天是否可约
      classesSetting: {},
      doctorClassesList: [], //医生所在部门班次记录
      doctordayrecordstwo:{}, //right 当天排班记录
      basicDepartmentID:0
    };
  },

  computed: {},
  mounted() {
    let that = this;

    // 获取医生列表
    that.getDoctorsList();

    // that.formatDate();

    //获取周一时间
    that.weekCheckingIn();

 
    //默认appointment_date为周一时间
    that.centerRecordsParam.appointment_date = that.MondayTime;

    // if (that.doctors.length) {
    //   that.$refs.doctorlist.select(that.doctors[0]);
    // }
    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));

    that.getStoreRecordsDepartmentID();

        // 模版数据请求
    that.initTemplate();
  },
  methods: {
    /**
     * 初始化模版数据
     *
     */
    initTemplate() {
      let me = this;

      me.formatDate();

      //list记录
      me.onSettingList();
      //trees记录
      me.getSettingTrees();
      //科室list记录
      me.getDeatrtmentList();
      //排班设置list记录
      me.getClassesSettingRecords();
      // 获取所有医生当天内的预约记录
      // me.getAppointmentRecords({
      //   //'doctor_id': doctor.doctor_id,
      //   doctor_id: 0,
      //   appointment_date: this.nowDate,
      // });

      //预约记录--默认全部医生-一周
      me.getAppointmentRecords();
    },
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *left start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    /**
     * 医生列表 筛选状态
     */
    changeStatus(e) {
      this.doctorsParam.diagnosis_status = e;
      this.loading.status = true;
      this.getDoctorsList();
    },
    onleftSearch(e) {
      this.doctorsParam.keywords = e;
      this.getDoctorsList();
    },
    onAllDoctor() {
      let me = this;
      me.allDoctors = 0;
      me.centerRecordsParam.doctor_id = undefined;
      me.getAppointmentRecords();
    },
    onTodayEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.centerRecordsParam.week_data = undefined;
      me.getAppointmentRecords();
      me.doctorsParam.appointment_date = this.nowDate;
      me.doctorsParam.dayEvent = 1;
      me.doctorsParam.eventEvent = undefined;
      me.doctorsParam.thirtyEvent = undefined;
      me.getDoctorsList();
    },
    onSevenEvent() {
      let me = this;
      //me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.centerRecordsParam.eventEvent = 1;
      me.centerRecordsParam.week_data = 1;
      me.getAppointmentRecords();
      //me.doctorsParam.appointment_date = this.nowDate;
      me.doctorsParam.dayEvent = undefined;
      me.doctorsParam.eventEvent = 1;
      me.doctorsParam.thirtyEvent = undefined;
      me.getDoctorsList();
    },
    onThirtyEvent() {
      let me = this;
      // me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.week_data = undefined;
      me.centerRecordsParam.thirtyEvent = 1;
      me.getAppointmentRecords();
      // me.doctorsParam.appointment_date = this.nowDate;
      me.doctorsParam.dayEvent = undefined;
      me.doctorsParam.eventEvent = undefined;
      me.doctorsParam.thirtyEvent = 1;
      me.getDoctorsList();
    },
    onCalendarEvent(e) {
      let me = this;
      me.centerRecordsParam.appointment_date = e;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.getAppointmentRecords();
      
      me.doctorsParam.appointment_date = e;
      me.doctorsParam.dayEvent = 1;
      me.doctorsParam.eventEvent = undefined;
      me.doctorsParam.thirtyEvent = undefined;
      me.getDoctorsList();
    },
    /**
     * 点击医生时出发 获取医生一周内的预约记录
     * @param doctor
     * @returns {Promise<void>}
     */
    onDoctorItemSelect(val) {
      let me = this;
      // if(me.centerRecordsParam.dayEvent==1){
      //    me.formatDate();
      // }else if(me.centerRecordsParam.eventEvent==1){
      //    me.weekCheckingIn();
      //    me.centerRecordsParam.appointment_date = me.MondayTime;
      // }
      me.allDoctors = 0;
      me.doctor = val;
      me.centerRecordsParam.doctor_id = val.doctorId;
      // me.centerRecordsParam.appointment_date = me.nowDate;
      me.getAppointmentRecords();
    },
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *left end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *center start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    /**
     * 点击获取预约详细信息
     * 和Detial的 onPlanClick 联动
     */
    onAppointmentDetailChange(val) {
      let me = this;
      //更改预约编号'
      if (val != null) {
        me.appointmentRecordNo = val.appointment_record_no;
        //请求预约详情
        me.getAppointmentRecordDetail(val.appointment_record_no);
        //请求生命体征
        me.getSignsRecord(val.appointment_record_no);

        // 根据医生I0D请求医生当天预约记录
        // let data = {
        //   appointment_date: me.centerRecordsParam.appointment_date,
        //   doctor_id: val.doctor_id,
        // };
        // me.getDoctorDayList(data);

        //请求医生当天是否可约，并返回医生部门班次记录
        let doctorData = {
          appointment_date: me.centerRecordsParam.appointment_date,
          department_id: val.department_id,
          doctor_id: val.doctor_id,
           click:"plan"
        };
        me.getDoctorTimeReturnRecords(doctorData); 
      }
    },
    /**
     * 根据搜索词搜索患者信息
     */
    //search(5):触发上级事件
    onMedicalRecordSearch(val) {
      this.getMedicalRecordSearch(val);
    },
    onAppointmentSaveClick(val) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.AppoientmentRecordSave(val);
        })
        .catch();
    },
    //预约诊状、持续时间
    onSettingList() {
      let me = this;
      me.getSettingRecords();
    },
    //到店状态
    onHandStateChange(data) {
      let me = this;
      me.setStateItem(data);
    },
    onGetDoctorTimeReturn(val) {
      let me = this;
      me.getDoctorTimeReturnRecords(val); //请求医生当天是否可约，并返回医生部门班次记录
    },
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *center end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *right start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    //预约编辑
    onAppoientmentSaveClick(val) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.onAppoientmentSave(val);
        })
        .catch();
    },
    onEmptyChange() {
      this.patientRecord = this.Detail;
    },
    /**
     * 生命体征添加
     */
    onDiagnosisSaveClick(val) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.onDiagnosisSave(val);
        })
        .catch();
    },

    /**
     * 取消预约，客户爽约
     */
    onChangeStatus(val) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.setStatusSave(val);
        })
        .catch();
    },

    /**
     * 到店
     */
    onChangeDiagnosisStatus() {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.setDiagnosisStatusSave();
        })
        .catch();
    },
    /** ** ** ** ** ** ** ** ** ** right end ** ** ** ** ** ** ** ** ** */
   
   //获取门店绑定的预约排班部门ID

   async getStoreRecordsDepartmentID() {

      let me = this;
     // console.log("basicDepartmenntID",  me.userInfo);
      let data={
          STORETOKEN:me.userInfo.store_token
      }
      let res = await mock("store/getStoreRecords",data);
      if (res.status == "200") {
       // console.log("resData",res.data);
         //me.basicDepartmentID = res.data.items[0].department_id;
         me.userInfo.department_id = res.data.items[0].department_id;
        
      }

    //   console.log("basicDepartmentID",  me.basicDepartmentID );
    },
    /**
     * 取消预约-提交
     */
    async setStatusSave(e) {
      let me = this;
      try {
        let data = {
          appointment_record_no: me.appointmentRecordNo, //预约号
          medical_record_no: me.patientRecord.patient_information.medical_record_no,
          status: e,
          create_id: me.userInfo.client_id,
          create_user: me.userInfo.name,
        };
        let res = await mock("appointmentCalendar/right/setStatusItem", data);
        if (res.status == 200) {
          me.$message({
            showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.getAppointmentRecords();
        }
      } catch (err) {
        me.$message({
          showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 到店提交
     */
    async setDiagnosisStatusSave() {
      let me = this;
      try {
        let data = {
          appointment_record_no: me.appointmentRecordNo, //预约号
          medical_record_no: me.patientRecord.patient_information.medical_record_no,
          diagnosis_status: 1,
          create_id: me.userInfo.client_id,
          create_user: me.userInfo.name,
        };
        let res = await mock("public/State/setAppointmentDiagnosisItem", data);
        if (res.status == 200) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.getAppointmentRecords();
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 获取看诊项->预约管理
     */
    async setStateItem(data) {
      let me = this;
      try {
        let res = await mock("public/State/setAppointmentDiagnosisItem", data);
        if (res) {
          me.$message({
             showClose: true,
            type: "success",
            message: "保存成功!",
          });
          me.getAppointmentRecords();
          me.getDoctorsList();
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }

    },
    /**
     * 获取看诊项->预约管理
     */
    async getSettingRecords() {
      let me = this;
      try {
        let res = await mock("configuration/Appointment/Setting/getRecords");
        if (res.status === 200) {
          me.settingRecords = res.data;
        }
      } catch (err) {
        me.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }

    },
    /**
     * 科室记录
     */
    async getDeatrtmentList() {
      let me = this;
      try {
        me.departmentRecords = await mock(
          "configuration/Appointment/ClinicalDepartment/getTreeList"
        );
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }

    },
    /**
     * 获取看诊项->预约管理->预约项树型数据
     */
    async getSettingTrees() {
      let me = this;

      try {
        me.settingTrees = await mock("configuration/Appointment/Setting/getTreeList");
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }

    },
    /**
     * 获取医生列表
     */
    async getDoctorsList() {
      this.doctors = await mock(
        "appointment/left/getDoctorCountRecords",
        this.doctorsParam
      );


      this.loading.doctors = false;
    },
    /**
     * 点击获取医生一周预约记录，二种调用办法
     * 1:点击医生获取
     * 2:点击上一周，下一周获取
     */

    async getAppointmentRecords() {
      let me = this;

      // 1:点击医生获取
      me.appointmentRecords = await mock(
        "appointment/center/getAppointmentDoctorRecords",
        me.centerRecordsParam
      );
      //2:点击上一周，下一周获取
      //待实现 TODO
    },
    /**
     * 获取预约详情
     */
    async getAppointmentRecordDetail(e) {
      let me = this;

      me.patientRecord = await mock("appointment/right/getAppointmentRecordDetail", e);
    },
    /**
     * 根据医生、日期获取医生的排班记录
     */
    async getDoctorTimeReturnRecords(e) {
      let me = this;
      let res = await mock("appointment/center/getDoctorTimeReturnRecords", e);
      if (res.status == "200" && res.data !== null) {
         //console.log("classesItems",res.data.classesItems,res.data.classesItems.length==0);
         //根据res.data.classesItems.length==0判断该医生该时间段没有排班
        //if(res.data.compareTime>0){
        if(res.data.classesItems.length!==0){
           me.doctorDayTime = true; //今日可约
        }else{
           me.doctorDayTime = false;
        } 
        me.doctorClassesList = res.data.classesItems; //[{classes_id: 1}] //医生部门排班记录


         //根据排班设置list记录
         //let departmentID = me.doctorClassesList[0].department_id;
        //me.getClassesSettingRecords();

        let data = {
          appointment_date: e.appointment_date,
          doctor_id: e.doctor_id,
          request_type:e.request_type
        };
        me.getDoctorDayList(data);
      } else {
        me.doctorDayTime = false;
      }
    },

    /**
     *排班配置
     */
    async getClassesSettingRecords() {
      let me = this;
      //console.log("basicDepartmenntID",  me.userInfo );
      let data = {
        department_id: me.userInfo.department_id, 
      };
      let res = await mock("appointment/center/getClassSettingRecords", data);
      if (res.status == "200") {
        me.classesSetting = res.data; //
        // me.classesSetting.items = [{
        //     "classes_id": 1,
        //     "start": "9:30",
        //     "end": "11:30",
        //     "step": 15,
        //     "name": "早班",
        //     "english_name": "12"
        // }, {
        //     "classes_id": 2,
        //     "start": "13:00",
        //     "end": "17:30",
        //     "step": 15,
        //     "name": "中班",
        //     "english_name": ""
        // }, {
        //     "classes_id": 9,
        //     "start": "18:00",
        //     "end": "21:30",
        //     "step": 15,
        //     "name": "晚班",
        //     "english_name": ""
        // }]
        // //
        //
      }
    },

    /**
     * 根据医生ID、时间获取医生当天的预约记录
     */
    async getDoctorDayList(e) {
      let me = this;
      //me.searchList =  me.states;
      //search(6): 获取远程返回的值并赋值
      let res = await mock("appointment/center/getDoctorDayRecords", e);
      if (res.status == "200" && res.data !== null) {
          if(e.request_type=="add"){
             me.doctordayrecords = res.data;
          }else{
            //me.doctordayrecordstwo = res.data;
          }
     
      } else {
        me.doctordayrecords = [];
       // me.doctordayrecordstwo = [];
      }
    },
    /**
     * 搜索患者
     */
    async getMedicalRecordSearch(e) {
      let me = this;
      //me.searchList =  me.states;
      //search(6): 获取远程返回的值并赋值
      me.searchList = await mock("appointment/getSearchRecords", e);

    },
    /**
     * 提交新的预约记录
     */
    async AppoientmentRecordSave(e) {
      let me = this;
      try {
        let res = await mock("appointment/center/setAppoientmentRecordDetail", e);
        if (res.status == "200") {
          me.$message({
           showClose: true,
            type: "success",
            message: "保存成功!",
          });

          me.$refs.appoientment.changeDrawer(false);
          me.getAppointmentRecords();
        }
      } catch (err) {
        if (err.status == "500") {
          me.$message({
             showClose: true,
            type: "warning",
            message: err.response.data.message,
          });
        }
      }
    },
    //预约记录编辑
    async onAppoientmentSave(e) {
      let me = this;
      let data = {
        appointment_record_no: me.appointmentRecordNo, //预约号
        doctor_id: e.doctorID, //预约医生ID
        clinical_department_id: e.departmentID, ////预约科室ID
        // symptom: e.symptom, // //症状
        // duration_time: e.duration, ////持续时间
        setting: e.setting,
        appointment_date: e.dataTime, ////持续时间
        start_time: e.startTime, ////预约时间：开始时间
        end_time: e.endTime, ////预约时间：结束时间
        status: e.status, //// 就诊状态 1=初诊 2=复诊 3=首诊
        visit_status: e.visitStatusValue, //就诊状态 1=初诊 2=复诊 3=首诊
        create_id: me.userInfo.client_id, //创建人ID=doctor表
        medical_record_no: me.patientRecord.patient_information.medical_record_no,
        create_user: me.userInfo.name,
        remarks:e.remarks
      };

      try {
        me.ressave = await mock("appointment/right/setAppointmentItem", data).then(
          (res) => {
            // 获取医生一周内的预约记录
            //TODO 刷新数据
            // me.getDoctorRecord({
            //   doctor_id: e.doctorID,
            //   appointment_date: "2021-04-28",
            // });
            me.getAppointmentRecords();
            me.$message({
               showClose: true,
              type: "success",
              message: "保存成功!",
            });
          }
        );
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    //生命体征
    async onDiagnosisSave(e) {
      let me = this;
      let data = {
        user_id: me.patientRecord.patient_information.user_id,
        temperature: e.temperature, // 体温
        breathe: e.breathe,
        blood_pressure: e.blood_pressure,
        heart_rate: e.heart_rate,
        stature: e.stature,
        weight: e.weight,
        head_circumference: e.head_circumference,
        bmi: e.bmi,
        allergies: e.allergies,
        past_history: e.past_history,
        family_history: e.family_history,
        type: e.type,
        clinical_department_id: e.clinical_department_id,
        appointment_record_no: me.appointmentRecordNo,
        medical_record_no: me.patientRecord.patient_information.medical_record_no,
        see_status: 1, //待诊 //写死的，看诊状态根据这个逻辑来处理，同时把数据　插入 diagnosis_record 表
        create_id: me.userInfo.client_id, //创建人ID=doctor表
        create_user: me.userInfo.name,
        doctor_id: e.doctor_id,
      };
      try {
        me.ressave = await mock("appointment/right/setDiagnosis", data).then((res) => {
          if (res.status == 200) {
            me.$message({
               showClose: true,
              type: "success",
              message: "保存成功!",
            });
          }
        });
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }
    },

    /**
     * 获取生命体征
     */
    async getSignsRecord(e) {
      // this.signsRecord = await mock("appointment/right/getPatientVitalSigns", e);
      try {
        let res = await mock("appointment/right/getPatientVitalSigns", e);
       // if(res.status="200"){
        this.signsRecord = res.item;
       // }

      
      } catch (err) {
        this.signsRecord = [];
      }
    },
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      this.centerRecordsParam.appointment_date = `${year}-${month}-${day}`;
      this.nowDate = `${year}-${month}-${day}`;
      this.doctorsParam.appointment_date = `${year}-${month}-${day}`;

    },
    //获取当周的周一和周日日期
    weekCheckingIn() {
      let me = this;
      me.weekChecking_inloading = true;
      let now = new Date();
      let nowTime = now.getTime();
      let day = now.getDay() || 7; // 不加 || 7.周末会变成下周一
      let oneDayTime = 24 * 60 * 60 * 1000;
      let MondayTime = nowTime - (day - 1) * oneDayTime; //显示周一
      //var SundayTime = nowTime + (7 - day) * oneDayTime; //显示周日
      //调用方法
      me.MondayTime = me.formatWeek(new Date(MondayTime));
    },
    formatWeek(date) {
      let myyear = date.getFullYear();
      let mymonth = date.getMonth() + 1;
      let myweekday = date.getDate();
      if (mymonth < 10) {
        mymonth = "0" + mymonth;
      }
      if (myweekday < 10) {
        myweekday = "0" + myweekday;
      }
      return myyear + "-" + mymonth + "-" + myweekday;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-left {
  margin: 4px 2px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  height: 60px;
  line-height: 60px;
}

.search {
  width: 295px;
  padding: 0 10px;

  > .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 160px;
    margin-right: 10px;
  }
}
</style>
