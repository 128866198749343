/**
 * 诊断模版列表 API
 */

 import axios from 'axios';
 import qs from 'qs';
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/storehouse_category/method/get.storehousecategory.list';
 
 export default (data) => {
 
     return axios.post(url, qs.stringify({
             ...data
         })).then(({
             data
         }) => data)
         .then(({
             data
         }) => data);
 }