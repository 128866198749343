import axios from 'axios' ;

let url = 'https://api.demo.hobooa.com/mock/91/api/v2/patient/method/get.diagnosis.record.basic.list/';

export default ({
    id
} , date = Date.now()) =>{

    return axios.get(url , {
        params:{
            medical_no:id,
            date
        }
    }).then(({
        data
    }) => data)
    .then(({
        data
    }) => data)
    .then(({
        items
    }) => items.map(({
        diagnosis_id:id,
        create_time:time
    }) =>({
        id,
        time
    })));
}
