<template>
  <SeeLayout> 
    <template v-slot:patient-list>
      <LeftMain
        ref="patientlist"
        class="hb-layout-fit-sized"
        :loading="loading"
        :medicalRecordList="medicalRecordList"
        @changestatus="onChangeStatus"
        @patientitemselect="onPatientItemSelect"
        @todayevent="onTodayEvent"
        @sevenevent="onSevenEvent"
        @thirtyevent="onThirtyEvent"
        @leftsearch="onLeftSearch"
      ></LeftMain>
    </template>
    <template v-slot:see-operation-main>
      <CenterTopHeader
        ref="centertopheader"
        @starttreatment="onStartTreatment"
        @endtreatment="onEndTreatment"
        @referral="onReferral"
        @save="onSave"
        :type="ptype"
        :item="diagnosis"
        :medical_basic="medical_basic"
        :start_reception="start_reception"
        :end_reception="end_reception"
        :visitStatus="visitStatus"
        @turnhospital="onTurnHospital"
      ></CenterTopHeader>
    </template>
    <template v-slot:see-detail>
      <CenterMain
        ref="detailRef"
        :item="diagnosis"
        :basic="basic"
        :treatments_template="treatments_template"
        :pharmacy_template="pharmacy_template"
        :examination_template="examination_template"
        :doctors_advice="doctors_advice"
        :prescription="prescription"
        @clickPrescriptionType="clickPrescriptionType"
        @delexamination="onDelExamination"
        @deltreatment="onDelTreatment"
        @delprescription="onDelPrescription"
        :start_reception="start_reception"
        :medical_basic="medical_basic"
        :visitStatus="visitStatus"
        :DrugsSettingData="DrugsSettingData"
      ></CenterMain>
    </template>
    <template v-slot:patient-history-list>
      <ListPatientHistory
        @datechange="onListPatientHistoryDateChange"
        class="hb-layout-fit-sized"
        :data="histories"
        :diagnosis_record_history="diagnosis_record_history"
        :prescription_history="prescription_history"
        @historyitemselect="onHistoryItemSelect"
      ></ListPatientHistory>
    </template>
  </SeeLayout>
</template>

<script>
import {
  // eslint-disable-next-line no-unused-vars
  mock,
  components,
  layouts,
} from "@/helper";

export default {
  components: {
    ...layouts(["See"]),
    ...components(
      ["Left.Main", "Center.TopHeader", "Center.Main", "list.patient.History"],
      "see"
    ),
  },

  data() {
    return {
      start_reception: 0,
      end_reception: 0,
      histories: [],
      patients: [], //预约列表
      patient: null, //选中的预约记录
      loading: {
        status: false, //预约列表loading
      },
      patientsParam: {
        //预约列表参数
        real_name: undefined,
        diagnosis_status: "0",
        see_status: "0",
        appointment_date: "",
        page_no: 1,
        page_size: 10,
        status: "0,1,2",
      },
      ressave: "12",
      input: "",
      diagnosis: {}, //看诊记录
      vitalsignData: {}, //生命体征
      prescription: {
        type: 0,
        list: [{}],
      },
      medicalRecordList: [], //病历列表
      visitStatus: 0, //点击选择后的患者的就诊状态，传入center->topheader
      basic: {
        //基本检查
        clinical_department: [], //科室模版
        diagnosis_template: [], //诊断模版
        complained_template: [], //主诉模版
      },
      treatments_template: [], //治疗项目
      examination_template: [], //检查项目
      //inspection_template: [], //检查项目
      pharmacy_template: [], //药品记录
      doctors_advice: [], //嘱咐模版
      diagnosis_record_history: [], //历史看诊记录
      prescription_history: [], //处方开药的记录
      history_param: {
        //用于历史筛选
        diagnosis_record_no: undefined,
        medicalRecordNo: undefined,
        create_time: undefined,
        user_id: undefined,
        see_status: 2, //已诊
        payment_status: 1, //待付款
        create_user: undefined,
        create_id: undefined,
      },
      medical_basic: {}, //病历简要信息
      ptype: 1,
      centerRecordsParam: {
        //列表参数
        appointment_date: undefined,
        dayEvent: 1, //1
        eventEvent: undefined, //7
        thirtyEvent: undefined, //30
        // status: "0,1,2",
        //diagnosis_status: "2,3,4,5", // 看诊状态 1=到店 2=待诊 3=已诊 4=检测中 5=待付款 6=已支付
        see_status: "1,2",

        //[1,1,1,0,0,0]
      },
      userInfo: "",
      systemKeywords:"",
      seeStatus:"",
      DrugsSettingData:{}
    };
  },

  async mounted() {
    let that = this;

    that.formatDate();
   
    // 模版数据请求
    await that.initTemplate();

    // if (that.patients.length) {
    //   that.$refs.patientlist.select(that.patients[0]);

    //   // 选择药品类型  1 为默认值
    //   //that.clickPrescriptionType(1);
    // }
    this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    this.history_param.create_id = this.userInfo.client_id;
    this.history_param.create_user = this.userInfo.name;
    this.centerRecordsParam.systemKeywords = this.userInfo.systemKeywords;
    this.centerRecordsParam.seeStatus = this.userInfo.seeStatus;

    //console.log("system keywords",this.systemKeywords);
    // let me = this, patients = await mock('see/patient/list');

    // me.patients = patients;x

     // 获取患者列表
    await that.getPatientsList();

    //
    if (this.patients.length) {
    
    	me.$refs.patientlist.select(patients[0]);
    }
    //setInterval(that.getPatientsList, 4000);
  },
  methods: {
    /**
     * 初始化模版数据
     *
     */
    async initTemplate() {
      let me = this;
      // 诊断列表
      me.basic.diagnosis_template = await mock("see/Center/getDiagnosisTemplateList");

      // 科室分类
      me.basic.clinical_department = await mock("see/Center/getClinicalDepartmentList");

      //注意 有的地方数据联动，所以现在写的是死数据

      // 主诉模板表
      me.basic.complained_template = await mock("see/Center/getComplainedTemplateList", {
        // clinical_department_id: 1,
      });

      // 治疗项目模版
      me.treatments_template = await mock("see/Center/getTreatmentsTemplateList", {
        //clinical_department_id: 1,
      });

      // 检查项目模板
      //me.inspection_template = await mock("see/Center/getInspectionTemplateList");
      me.examination_template = await mock("see/Center/getExaminationTemplateList");
      // 嘱咐模版
      me.doctors_advice = await mock("see/Center/getDoctorsAdviceTemplateList");
      
      me.getDrugsSetting();
      //默认西药处方  // 选择药品类型  1 为默认值
      me.clickPrescriptionType(1);
    },

    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *left start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

    /**
     * 患者列表 筛选状态
     */
    onChangeStatus(e) {
      let me = this;
      let statusVlaue = e;
      if (statusVlaue == "all") {
        me.centerRecordsParam.see_status = "1,2";
      } else {
        me.centerRecordsParam.see_status = statusVlaue;
      }

      this.getPatientsList();
    },
    onLeftSearch(e) {
      let me = this;
      me.centerRecordsParam.keywords = e;
      me.centerRecordsParam.appointment_date = undefined;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      this.getPatientsList();
    },
    onTodayEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.getPatientsList();
    },
    onSevenEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.centerRecordsParam.eventEvent = 1;
      me.getPatientsList();
    },
    onThirtyEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = 1;
      me.getPatientsList();
    },
    onCalendarEvent(e) {
      let me = this;
      me.centerRecordsParam.appointment_date = e;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.getPatientsList();
    },
    /**
     * 点击预约时出发 获取看诊记录
     * @param patient
     * @returns {Promise<void>}
     */
    onPatientItemSelect(Data) {

      let me = this;

      me.patient = Data;

      // 获取看诊记录
      //me.getDiagnosisRecord({ appointment_record_no: me.patient.appointmentRecordNo });

      me.history_param.user_id = Data.user_id;

  
      me.history_param.medicalRecordNo = Data.medicalRecordNo;

      me.visitStatus = Data.visitStatus; //设定点击后患者的就诊状态存入Data


      // 历史看诊
      //me.getHistoryRedocd();

      // 获取病历信息【简要】
      me.getMedicalBasicRedocd(Data.user_id);
      // 获取看诊记录--生命体征
      me.getDiagnosisRecord(me.patient);

      // me.histories = await mock('see/diagnosis/list' , patient) ;
      me.getHistoryDiagnosisRecord(me.patient);

      me.getHistoryPrescriptionRecord(me.patient);

     
   
      //看诊历史记录
      //me.histories = await mock('see/diagnosis/list' , patient) ;
      // 诊断模版
      // me.diagnosis_template=await mock("see/patient/listdt",patient);


      // 药品记录
      // me.pharmacy=await mock("see/patient/listpharmacy",patient);
    },
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *left end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *Center start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    //删除检验检查记录
    onDelExamination(val) {
      let me = this;
      me.delExaminationItem(val);
    },
    //删除检验检查记录
    onDelTreatment(val) {
      let me = this;
      me.delTreatmentsItem(val);
    },
    onDelPrescription(val) {
      let me = this;
      me.delPrescriptionItem(val);
    },

    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *Center end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/
    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *Right start ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

    /** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** *Right end ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** ** **/

    //  /**
    //  * 获取患者列表
    //  */
    // async getPatientsList() {
    //   this.medicalRecordList = await mock(
    //     "see/Left/getAppointmentBasicList",
    //     this.centerRecordsParam
    //   );

    //   this.loading.patients = false;
    // },

    async getPatientsList() {
      let me = this;

      me.medicalRecordList = await mock(
        "see/Left/getAppointmentBasicList",
        me.centerRecordsParam
      );
    },

    /**
     * 保存看诊记录
     */
    async onSave() {
      let me = this;
      if (me.start_reception == 1) {
        me.$confirm("确认保存看诊信息吗？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(async () => {
          // 这里获取看着需要保存的数据
          let seeData = me.$refs.detailRef.getSeeData();
          me.loading.patients = true;
          try {
            me.ressave = await mock("see/Center/setDiagnosisRecord", seeData).then(
              (res) => {
                //me.getPatientsList();
                me.$message({
                  showClose: true,
                  type: "success",
                  message: "保存成功!",
                });
              }
            );
          } catch (err) {
            me.$message({
              showClose: true,
              type: "warning",
              message: err.response.data.message,
            });
          }
        });
      } else {
        me.$message({
          showClose: true,
          type: "warning",
          message: "请先操作开始接诊！",
        });
      }
    },

    /**
     * 选择药品类型时出发
     */
    async clickPrescriptionType(e) {
      //点击药品类型
      // 药品记录
      this.pharmacy_template = await mock("see/Center/getPharmacyList", {
        type: e,
      });
    },

    onReferral() {
    },

    /**
     * 转院
     */
    onTurnHospital(data) {
      let me = this;
      me.$confirm("确认保存信息吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.onTurnHospitalSave(data);
        })
        .catch();
    },
    async onTurnHospitalSave(data){
      let that = this;
      try {
        that.ressave = await mock("see/Center/setTransferRecord", data).then((res) => {
          console.log("aaa", res)
          if (res.status === 200) {
            that.$message({
              showClose: true,
              type: "success",
              message: "保存成功!",
            });
            that.$refs.centertopheader.referralPreview(false);
            // that.getMedicalRecordList();
          } else if (res.status === 500) {
            that.$message({
              showClose: true,
              type: "warning",
              message: res.message,
            });
          } else {
            that.$message({
              showClose: true,
              type: "warning",
              message: "保存失败",
            });
          }
        })
        
      } catch (err) {
           console.log("aaa-err", err);
        that.$message({
          type: "warning",
          message: err.response.data.message,
        });
      }
    },
    /**
     * 开始接诊
     *
     */
    onStartTreatment() {
      let me = this;
      me.$confirm("确认开始接诊吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        // 这里获取看着需要保存的数据
        try {
          me.ressave = await mock(
            "see/Center/setDiagnosisStartTime",
            me.history_param
          ).then((res) => {
            if (res) {
              me.loading.patients = true;
              //me.getPatientsList();
              me.start_reception = 1; //更改开始接诊状态；
              me.$message({
                showClose: true,
                type: "success",
                message: "操作成功!",
              });
            } else {
              me.$message({
                showClose: true,
                type: "warning",
                message: "操作失败!",
              });
            }
          });
        } catch (err) {
          me.$message({
            showClose: true,
            type: "warning",
            message: err.response.data.message,
          });
        }
      });
    },

    /**
     * 结束接诊
     *
     */
    onEndTreatment() {
      let me = this;
      me.$confirm("确认结束接诊吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        // 这里获取看着需要保存的数据
        try {
          me.ressave = await mock(
            "see/Center/setDiagnosisEndTime",
            me.history_param
          ).then((res) => {
            if (res) {
              this.loading.patients = true;
              //this.getPatientsList();
              me.end_reception = 1; //更改结束接诊状态；
              me.$message({
                showClose: true,
                type: "success",
                message: "操作成功!",
              });
            } else {
              me.$message({
                showClose: true,
                type: "warning",
                message: "操作失败!",
              });
            }
          });
        } catch (err) {
          me.$message({
            showClose: true,
            type: "warning",
            message: err.response.data.message,
          });
        }
      });
    },

    async onListPatientHistoryDateChange(date) {
      let me = this;

      me.history_param.create_time = date;

      // 历史用药
      me.prescription_history = await mock(
        "see/history/getHistoryPrescriptionList",
        me.history_param
      );

      // 历史看诊
      me.diagnosis_record_history = await mock(
        "see/history/getDiagnosisRecordBasicList",
        me.history_param
      );

      // let me = this,
      // 	{
      // 		patient
      // 	} = me;
      //
      //   me.histories = await mock('see/diagnosis/list', patient, date);
    },

    /**
     * 用户获取看着记录的，  两个用法，一种点击预约记录，一种点击历史记录
     *
     */
    async getDiagnosisRecord(data) {
      let me = this;

      // 看诊记录
      try {
        me.diagnosis = await mock("see/Center/getDiagnosisRecord", data).then(
          async (res) => {
            me.medical_basic.appointment_record_no = res.appointment_record_no;
            //更改开始接诊、结束接诊状态
            if (res.start_time !== null) {
              me.start_reception = 1;
            } else {
              me.start_reception = 0;
            }

             
            if (res.end_time !== null) {
              me.end_reception = 1;
            } else {
              me.end_reception = 0;
            }
            let val = res.get_prescription;

            //遍历处方开药记录，绑定 药品相应的模版数据
            if (val && val.length > 0) {
              for (let item of val) {
                let index = val.indexOf(item);

                let pharmacy_template = await mock("see/Center/getPharmacyList", {
                  pharmacy_category_id: item.type,
                });

                item.pharmacy_template = pharmacy_template;
                if (pharmacy_template.length > 0) {
                  let pharmacy_template_index = pharmacy_template.findIndex((p_item) => {
                    return p_item.pharmacy_id == item.pharmacy_id;
                  });

                  if (pharmacy_template_index > -1) {
                    item.dosage_template =
                      pharmacy_template[pharmacy_template_index].dosage;
                    item.remark_template =
                      pharmacy_template[pharmacy_template_index].remarks;
                  }
                }
              }
            }
            res.get_prescription = val;

            me.history_param.diagnosis_record_no = res.diagnosis_record_no;
            // 历史用药
            // me.prescription_history = await mock(
            //   "see/history/getHistoryPrescriptionList",
            //   me.history_param
            // );

            return res;
          }
        );
      } catch (err) {
        me.$message({
          showClose: true,
          type: "warning",
          message: err.response.data.message,
        });
      }

    },

    /**
     * 点击历史记录时出发 获取看诊记录
     * @param history
     * @returns {Promise<void>}
     */
    async onHistoryItemSelect(history) {
      let me = this;
      // 获取看诊记录
      await me.getDiagnosisRecord({ diagnosis_record_id: history.diagnosis_record_id });
    },
    async getHistoryRedocd(Data) {
      let me = this;
      // 获取看诊记录
      await me.getDiagnosisRecord({ diagnosis_record_id: Data.diagnosis_record_id });
    },
    async getMedicalBasicRedocd(Data) {
      let me = this;

      me.medical_basic = await mock("see/Center/getMedicalBasicRecord", {
        user_id: Data,
      });

    },
    async delExaminationItem(val) {
      await mock("see/Center/delExaminationItem", {
        examinations_id: [val],
      });
    },
    async delTreatmentsItem(val) {
      await mock("see/Center/delTreatmentsItem", {
        treatments_id: [val],
      });
    },
    async delPrescriptionItem(val) {
      await mock("see/Center/delPrescriptionItem", {
        prescription_id: [val],
      });
    },
    async getHistoryDiagnosisRecord(val) {
      let me = this;
      me.diagnosis_record_history = await mock("see/Right/getHistoryRedords", val);
    },
    async getHistoryPrescriptionRecord(val) {
      let me = this;
      me.prescription_history = await mock(
        "see/Right/getHistoryPrescriptionRecords",
        val
      );
    },
    receptionStatus() {
      let me = this;
      if (me.start_reception == 0) {
        me.$message({
          showClose: true,
          type: "warning",
          message: "请先操作开始接诊！",
        });
      }
    },
    //用法、用量、禁忌、医嘱
     async getDrugsSetting() {
      let me = this;
      let res = await mock("configuration/DrugsSetting/getRecords");
      if(res.status=="200"){
          me.DrugsSettingData = res.data.items;
      }
    },
  
    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.centerRecordsParam.appointment_date = `${year}-${month}-${day}`;
      this.nowDate = `${year}-${month}-${day}`;

    },
  },
};
</script>
<style lang="scss" scoped>
.search-left {
  margin: 4px 2px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  height: 60px;
  line-height: 60px;
}

.search {
  width: 295px;
  padding: 0 10px;

  > .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 160px;
    margin-right: 10px;
  }
}
</style>
