<template>
	<Container class="see-patient-history-list-item hb-layout-vertical">
		<Header></Header>
		<Content
			ref="rightSelectData"
			v-if="tabDatas === 1"
			@submititemdata="onSubmitItemData"
		></Content>
		<ContentRoles
			ref="rightSelectData"
			v-if="tabDatas === 2"
			@submititemdata="onSubmitItemData"
			:systemKeywordsData="systemKeywordsData"
		></ContentRoles>
		<ContentUser
			ref="rightSelectData"
			v-if="tabDatas === 3"
			:departmentData="departmentData"
			:rolesData="rolesData"
			@submititemdata="onSubmitItemData"
		></ContentUser>
		<ContentClasses
			ref="rightSelectData"
			v-if="tabDatas === 4"
			:departmentData="departmentData"
			@submititemdata="onSubmitItemData"
		></ContentClasses>
		<ContentSource
			ref="rightSelectData"
			v-if="tabDatas === 5"
			:treeDatas="listRecordsData"
			@submititemdata="onSubmitItemData"
		></ContentSource>
		<ContentReturn
			ref="rightSelectData"
			v-if="tabDatas === 6"
			:treeDatas="listRecordsData"
			@submititemdata="onSubmitItemData"
		></ContentReturn>
		<!-- <ContentPayment
		  ref="rightSelectData"
		  v-if="tabDatas === 7"
		  @submititemdata="onSubmitItemData"
		></ContentPayment>
		<ContentTags
		  ref="rightSelectData"
		  v-if="tabDatas === 8"
		  @submititemdata="onSubmitItemData"
		></ContentTags> -->
		<ContentManage
			ref="rightSelectData"
			v-if="tabDatas === 9"
			@submititemdata="onSubmitItemData"
		></ContentManage>
		<ContentManage
			ref="rightSelectData"
			v-if="tabDatas === 91"
			:tabDatas="tabDatas"
			@submititemdata="onSubmitItemData"
		></ContentManage>
		<ContentStore
			ref="rightSelectData"
			v-if="tabDatas === 7"
			@submititemdata="onSubmitItemData"
		></ContentStore>
	</Container>
</template>

<script>
import {components} from "@/helper";

export default {
	components: {
		...components(
			[
				"Header",
				"Content",
				"ContentRoles",
				"ContentUser",
				"ContentClasses",
				"ContentSource",
				"ContentReturn",
				"ContentPayment",
				"ContentTags",
				"ContentManage",
				"ContentPassword",
				"ContentStore"
			],
			"configuration.Inside.Right"
		),
		
		...components(["Container"]),
	},
	
	props: {
		systemKeywordsData: {},
		listRecordsData: {},
		rolesData: {},
		departmentData: {},
		treeDatas: {},
		tabData: 0,
	},
	watch: {
		tabData: function (val) {
			this.tabDatas = val;
		},
	},
	
	data() {
		return {
			tabDatas: 0,
		};
	},
	methods: {
		handleSelectItems(itemData) {
			this.$refs.rightSelectData.onSelectData(itemData);
		},
		handlePassSelectItems(itemData) {
			this.$refs.rightSelectData.handlePassSelectItems(itemData);
		},
		handleEditSelectItems(itemData) {
			this.$refs.rightSelectData.onAppendSelectItem(itemData);
		},
		onSubmitItemData(itemData) {
			this.$emit("submititemdata", itemData);
		},
		onAddSubmit() {
			this.$refs.rightSelectData.addtopitem();
		},
	},
};
</script>

<style lang="scss" scoped>
// .see-patient-history-list-item {
//   width: 99%;
//   padding: 0 19px;
//   height: calc(100vh - 140px);
//   box-shadow: 0 2px 7px 0 #d8d8d8;
//   margin: 2px 0px 10px 2px;
// }
</style>
