<template>
  <Container class="statistics">
    <div class="text item">
      <div class="total">
        <div class="total-amount">
          <el-image class="image" src="../../image/male.png"></el-image>
          <div class="content">
	        
               <div class="total-number">{{statisticsData.count_sex_1}}</div>
            <div class="total-name">男</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image" src="../../image/female.png"></el-image>
          <div class="content">
	
                 <div class="total-number">{{statisticsData.count_sex_2}}</div>
            <div class="total-name">女</div>
          </div>
        </div>
      </div>
      <el-table
        style="width: 100%"
        height="calc(100vh - 385px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
       <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="medical_record_no"
          label="病历号"
          min-width="240"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="real_name"
          label="用户"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column  prop="age" label="年龄" min-width="130" align="left">
        </el-table-column>

        <el-table-column   label="性别" min-width="130" align="left">
           <template slot-scope="scope">
            {{ sexMap[scope.row.sex] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="mobile"
          label="电话"
          min-width="170"
          align="left"
        >
        </el-table-column>
    

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="200"
          align="left"
        ></el-table-column>


      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
	  tableData: {
		  default: () => [],
	  },
	  statisticsData: {},
  },
  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {

      typeMap: [
        {
          text: "收入",
          type: "success",
          symbol: "+",
        },
        {
          text: "支出",
          type: "danger",
          symbol: "-",
        },
      ],

      moduleMap: {
        points: "积分",
        money: "消费",
        card: "购物卡",
      },
      stateMap: ['账户资金', '现金', '支付宝', '微信', '银行卡', '购物卡', '保险', '其它'],
      statusMap: ['未支付', '已支付'],
      sexMap: ['保密', '男', '女'],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;

  .total{
    display: flex;
    align-items: center;

    .total-amount{
      height: 80px;
      margin: 20px 10px 20px 5px;
      padding: 0 40px 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 1px 4px 1px #d8d8e3;

      .image{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
      }
      .img{
        width: 44px;
        height: 44px;
      }

      .content{
        font-family: PingFangSC-Medium, PingFang SC;

        .total-name{
          font-size: 12px;
          color: #999;
        }
        .total-number{
          font-size: 18px;
          color: #333;
        }
      }
    }
  }
}
</style>
