<template>
  <Container  class="patient-detail-info-project">
    <el-row class="main" justify="center" align="bottom">
      <el-col :span="24">
        <el-row type="flex">
          <span class="main-span-text">姓名：{{real_name}}</span>
          <span class="main-span-text">性别：{{sexMap[sex]}}</span>
          <span class="main-span-text">年龄：{{age}}岁</span>
          <span class="main-span-text">病历号：{{medical_record_no}}</span>
        </el-row>
      </el-col>
    </el-row>
  </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props:{
    real_name: {
      type: String,
      default: ""
    },
    sex: {
      type: String | Number,
      default: ""
    },
    age: {
      type: String | Number,
      default: ""
    },
    medical_record_no: {
      type: String,
      default: ""
    }
  },
  data(){
    return {
      sexMap: ['保密', '男', '女'],
    }
  }
}
</script>

<style  lang="scss"  scoped>
  .patient-detail-info-project{
    width: 99%;
    align-items: center;
    padding: 0 18px;
    height: 60px;
    line-height: 60px;
    box-shadow: 0 2px 7px 0 #d8d8d8;
    margin: 5px auto 10px;

    .main{
      width: 100%;
      display: flex;
      justify-content: space-between;
      

      .main-span-text{
        font-size: 16px;
        color: #5B5B5B;
        font-weight: 500;
        font-family: PingFangSC-Medium, PingFang SC;
        white-space: nowrap;
        margin-right: 14px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 13px;
      }

      .tag{
        margin-right: 14px;
      }
    }

    .right{
      width: 240px !important;
      justify-content: flex-end;
      display: flex;
    }
  }
</style>
