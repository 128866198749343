<template>
  <div style="height: 95%; overflow: auto">
    <Container class="see-detail hb-layout-vertical">
      <!-- <el-card class="box-card"> -->
      <Header
        @submit="handleSubmit"
        ref="header"
        :loading="loading"
        @submitreset="onSubmitReset"
        @todayclick="onTodayClick"
        @weekclick="onWeekClick"
        @monthclick="onMonthClick"
        @yearclick="onYearClick"
      ></Header>
	    <List :tableData="Data.items" :statisticsData="Data.statistics" :loading="loading"></List>
      <!-- </el-card> -->
      <BasicFooter
        :loading="loading"
        :size="Param.page_size"
        :total="Data.total_result"
        @change="handlePaginationChange"
      ></BasicFooter>

      <!-- <el-card class="box-card" shadow="never"> -->
      <!-- <BasicPagination :total="100" :size="20"  ></BasicPagination> -->
      <!-- </el-card> -->
    </Container>
  </div>
</template>

<script>
import { components, layouts, blocks } from "@/helper";

export default {
  props: {
    Data: {},
    Param: {},
    loading: {
      default: false,
    },
  },
  components: {
    ...components(["Header", "List"], "financialStatistics.Arrears"),
    ...blocks(["BasicFooter"], "Footer"),
    ...blocks(["BasicPagination"], "Pagination"),
    ...components(["Container"]),
  },
  data() {
    return {
      //loading: false,
      table: [],
      toPayment: {},
      page: {
        current: 1,
        size: 0,
        total: 0,
      },
      order: {
        order_type: undefined,
        order_field: undefined,
      },
    };
  },
  methods: {
    // 分页变化改动
    handlePaginationChange(val) {
      //this.page = val;
      this.$emit("changepagination", val);
      //   this.$nextTick(() => {
      //     this.$refs.header.handleFormSubmit();
      //   });
    },
    onChangeSubmit() {
      this.$nextTick(() => {
        this.$refs.header.handleFormSubmit();
      });
    },

    // 确定查询
    handleSubmit(form, isRestore = false) {
      this.$emit("changesubmit", form, isRestore);
      //   if (isRestore) {
      //     this.page.current = 1;
      //   }

      //   this.loading = true;
      //   getTransactionList({
      //     ...form,
      //     ...this.order,
      //     page_no: this.page.current,
      //     page_size: this.page.size,
      //   })
      //     .then((res) => {
      //       this.table = res.data.items || [];
      //       this.page.total = res.data.total_result;
      //     })
      //     .finally(() => {
      //       this.loading = false;
      //     });
    },
    onSubmitReset(data) {
      let me = this;
      me.$emit("submitreset",data);
    },
    onTodayClick() {
      let me = this;
      me.$emit("todayclick");
    },
    onWeekClick() {
      let me = this;
      me.$emit("weekclick");
   
    },
    onMonthClick() {
      let me = this;
      me.$emit("monthclick");
    },
    onYearClick() {
      let me = this;
      me.$emit("yearclick");
    },
  },
};
</script>

<style lang="scss" scoped></style>
