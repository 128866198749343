<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Container class="header hb-layout-horizontal">
      <div class="time hb-layout-flex-1">时间:{{create_time}}</div>
    </Container>
    <Container class="body">
      <Container class="inner hb-layout-horizontal">
        <div class="flex">
          <div class="flex-left">药品名称</div>
          <div class="flex-right">{{ pharmacy_name }}</div>
        </div>
        <div class="flex">
          <div class="flex-left">用量</div>
          <div class="flex-right">{{ dosage }}</div>
        </div>
        <div class="flex" style="border-bottom: 1px solid #979797;">
          <div class="flex-left">备注</div>
          <div class="flex-right">{{ remark ? remark.join('、') : '' }}</div>
        </div>

      </Container>
    </Container>
  </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  props: {
    create_time: String, //创建时间
    dosage: String, //剂量
    remark: Array, //备注
    pharmacy_name: String //药品名称
  },
  data(){
    return {
      typeMap: [
        {
          type: '',
          text: '首诊'
        },
        {
          type: '',
          text: '初诊'
        },
        {
          type: '',
          text: '复诊'
        },
      ]
    }
  },
  methods:{

  }
}
</script>

<style lang="scss" scoped>
.see-patient-history-list-item {
  margin: 0 6px 15px;
  box-shadow: 0 2px 7px 0 #d8d8d8;

  >.header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #EDEDED;
    border-bottom: 1px solid #e1e1e1;

    >.time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5B5B5B;
    }

    >.button {
      height: 30px;
      margin-top: 12px;
    }
  }

  >.body {
    padding: 10px;

    >.inner {
      display: flex;
      flex-direction: column;

      .el-row {
        width: 100%;
        height: auto;
        border: 1px solid #979797;
        border-bottom: none;
        font-size: 12px;

        &:last-child {
          border-bottom: 1px solid #979797;
        }
      }

      .bg-purple {
        text-align: center;
      }

      .bg-purple-light {
        padding: 11px 10px;
        border-left: 1px solid #979797;
      }


      .flex{
        width: 100%;
        display: flex;
        align-items: center;
        border-top: 1px solid #979797;
        border-left: 1px solid #979797;
        border-right: 1px solid #979797;

        .flex-left{
          width: 41%;
          text-align: center;
          
        }
        .flex-right{
          width: 59%;
          min-height: 39px;
          padding: 10px;
          line-height: 20px;
          border-left: 1px solid #979797;
        }
      }
    }

  }
}
</style>
