import Vue from 'vue';

import VueRouter from 'vue-router' ;

import pages from '@/../config/pages' ;


Vue.use(VueRouter) ;

const routes = [] ;

for(let {
    page
} of pages){

    routes.push({
        path:`/${page}`,
        component:() => import(`@/pages/${page}`)
    }) ;
}


export default new VueRouter({
    routes 
}) ;