<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container class="patient-detail-doctor">
      <div class="details">
        <el-card>
          <Container class="see-patient-history-list-item hb-layout-vertical">
            <Container class="body">
              <Container class="inner hb-layout-horizontal">
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  药品名称</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.name"
                        placeholder="请输入药品名称"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">英文名称(全)</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.english_name"
                        placeholder="英文名称(全)"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">英文名称(简)</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.en_name"
                        placeholder="英文名称(简)"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  药品类目</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.pharmacy_category_id"
                        filterable
                        style="width: 100%"
                        placeholder="请选择药品类目"
                      >
                        <el-option
                          v-for="item in pharmacyCateDatas"
                          :key="item.pharmacy_category_id"
                          :label="item.name"
                          :value="item.pharmacy_category_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">生产厂家</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.factory_id"
                        filterable
                        placeholder="请选择生产厂家"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in factoryDatas"
                          :key="item.factory_id"
                          :label="item.name"
                          :value="item.factory_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">供应商</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.supplier_id"
                        filterable
                        placeholder="请选择供应商"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in supplierDatas"
                          :key="item.supplier_id"
                          :label="item.name"
                          :value="item.supplier_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">品牌</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.brand_id"
                        filterable
                        placeholder="请选择供应商"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in brandDatas"
                          :key="item.brand_id"
                          :label="item.name"
                          :value="item.brand_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">国药准字</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.pharmacy_code"
                        placeholder="请输入国药准字"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">批准文号</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.approval_number"
                        placeholder="请输入批准文号"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">生产批号</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.batch_number"
                        placeholder="请输入生产批号"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">药品编号</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.drug_number"
                        placeholder="请输入药品编号"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">条形码</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.bar_code"
                        placeholder="请输入条形码"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">用途</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.purpose"
                        placeholder="请输入用途"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">用量</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.dosage"
                        placeholder="请输入用量"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>

                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  单位</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.unit_id"
                        filterable
                        placeholder="请选择药品单位"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in unitData"
                          :key="item.unit_id"
                          :label="item.name"
                          :value="item.unit_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  规格数量</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                      v-model="drugsParam.small_unit_num"
                      placeholder="请输入规格数量"
                    ></el-input>
                    </div>
                  </el-col>
                </el-row>

                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  规格单位</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-select
                        v-model="drugsParam.small_unit_id"
                        filterable
                        placeholder="请选择规格单位"
                        style="width: 100%"
                      >
                        <el-option
                          v-for="item in smallunitData"
                          :key="item.unit_id"
                          :label="item.name"
                          :value="item.unit_id"
                        >
                        </el-option>
                      </el-select>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">最小制剂单位</div>
                  </el-col>
                  <el-col :span="9">
                    <el-input
                      v-model="drugsParam.mini_unit"
                      placeholder="最小制剂单位(包装规格)"
                    ></el-input>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  进价</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.trade_price"
                        placeholder="请输入进价"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  售价</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.retail_price"
                        placeholder="请输入售价"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">药品类别</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light" style="padding-left: 15px">
                      <el-checkbox v-model="zh">中药</el-checkbox>
                      <el-checkbox v-model="en">西药</el-checkbox>
                      <el-checkbox v-model="dr">输液</el-checkbox>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">处方</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light" style="padding-left: 15px">
                      <el-radio v-model="drugsParam.classes" label="1">处方药</el-radio>
                      <el-radio v-model="drugsParam.classes" label="2">非处方药</el-radio>
                    </div>
                  </el-col>
                </el-row>

                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">库存预警值</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.stock_warning"
                        placeholder="请输入库存预警值"
                      ></el-input>
                    </div>
                  </el-col>
                  <el-col :span="3">
                    <div class="grid-content bg-purple">启用</div>
                  </el-col>
                  <el-col :span="9">
                    <div class="grid-content bg-purple-light" style="padding-left: 15px">
                      <el-radio v-model="drugsParam.status" label="1">启用</el-radio>
                      <el-radio v-model="drugsParam.status" label="2">不启用</el-radio>
                    </div>
                  </el-col>
                </el-row>
                <el-row type="flex" align="middle">
                  <el-col :span="3">
                    <div class="grid-content bg-purple">备注</div>
                  </el-col>
                  <el-col :span="21">
                    <div class="grid-content bg-purple-light">
                      <el-input
                        v-model="drugsParam.remarks"
                        placeholder="请输入备注"
                      ></el-input>
                    </div>
                  </el-col>
                </el-row>
              </Container>
              <div class="btn">
                <el-button type="info" class="edit-buttom">取消</el-button>
                <el-button type="success" class="edit-buttom" @click="submitAddDrugs"
                  >确定</el-button
                >
              </div>
            </Container>
          </Container>
        </el-card>
      </div>
    </Container>
  </el-drawer>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    pharmacyCateDatas: {},
    brandDatas: {},
    supplierDatas: {},
    factoryDatas: {},
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
     unitList: {}, // 规格单位
  },
  mounted() {
      this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
      this.drugsParam.create_id=this.userInfo.client_id;
  },
  data() {
    return {
      drugsParam: {
        name: "", // 药品名称
        english_name: "", // 药品名称
        en_name: "", // 药品名称
        pharmacy_category_id:"", //药品分类
        factory_id: "", //生产厂家
        supplier_id: "", //供应商
        brand_id: "", //品牌
        pharmacy_code: "", //国药准字
        approval_number: "", //批准文号
        batch_number: "", // 生产批号
        drug_number: "", //药品编号
        bar_code: "", //条形码
        purpose: "", //用途
        dosage: "", //用量
        unit_id: "", //单位
        small_unit_id: "", //最小单位//规格制剂单位
        taboo: "", //禁忌
        effective_date: "", //有效期
        small_unit_num: "",
        factory_date: "", //生产日期
        trade_price: "", //批发价
        retail_price: "", //零售价
        mini_unit: "", //最小制剂单位
        stock_warning: 10, //库存预警值
        zh: 0,
        en: 0,
        dr: 0,
        classes: "1",
        remarks: "",
        status: "1",
        create_id:""
      },
      pharmacyCategory: [
        { value: 1, label: "肠道类" },
        { value: 2, label: "皮肤类（痤疮）" },
        { value: 3, label: "胃消化道类" },
        { value: 4, label: "抗生素类" },
        { value: 5, label: "肛肠类" },
        { value: 6, label: "妇产类" },
        { value: 7, label: "输液注射类" },
        { value: 8, label: "维生素类（口服）" },
        { value: 9, label: "皮肤类（其它）" },
        { value: 10, label: "抗真菌类" },
        { value: 11, label: "眼科类" },
      ],
      factoryData: [
        { value: "1", label: "广东恒健制药有限公司" },
        { value: "2", label: "福元药业股份有限公司" },
        { value: "3", label: "北京同仁堂大药房" },
        { value: "4", label: "哈药集团三精制药有限公司" },
      ],
      supplierData: [
        { value: "1", label: "苏州海德大药房" },
        { value: "2", label: "上海海德大药房" },
      ],
      brandData: [
        { value: "2", label: "999" },
        { value: "3", label: "北京同仁堂" },
        { value: "4", label: "21金维他" },
        { value: "5", label: "哈药" },
        { value: "6", label: "奥诺" },
        { value: "7", label: "朗迪" },
      ],
      unitData: [],
      smallunitData: [],
      effectiveData: [
        { value: "1", label: "6个月" },
        { value: "2", label: "12个月" },
        { value: "3", label: "18个月" },
        { value: "4", label: "24个月" },
        { value: "5", label: "36个月" },
      ],
      zh: "",
      en: "",
      dr: "",
      typeValue: [], // 药品类型
      manufactor: "", // 厂家
      licence: "", // 国药准字
      barCode: "", // 条形码
      dose: "", // 剂量
      preparation: "", // 制剂
      packing: "", // 包装
      purchasePrice: "", // 进价
      amountSold: "", // 售价
      drawer: false,
      userInfo:""
    };
  },
  watch: {
    zh: {
      handler(val, oldVal) {
        if (val) {
          this.drugsParam.zh = 1;
        } else {
          this.drugsParam.zh = 0;
        }
      },
      deep: true,
    },
    en: {
      handler(val, oldVal) {
        if (val) {
          this.drugsParam.en = 1;
        } else {
          this.drugsParam.en = 0;
        }
      },
      deep: true,
    },
    dr: {
      handler(val, oldVal) {
        if (val) {
          this.drugsParam.dr = 1;
        } else {
          this.drugsParam.dr = 0;
        }
      },
      deep: true,
    },

    unitList: function (val) {
      //console.log("unitlist", val);
      let me = this;
      if (val.length > 0) {
        val.map((item) => {
          switch (item.type) {
            case 1:
              me.unitData.push(item);
              break;
            case 2:
              me.smallunitData.push(item);
              break;
          }
        });
      }
      //console.log("unitData", me.unitData);
    },
  },
  methods: {
    submitAddDrugs() {
      let me = this;
      if (this.drugsParam.name == "" || this.drugsParam.name == null) {
        this.$alert("请输入药品名称");

        return;
      }
      if (this.drugsParam.pharmacy_category_id == "" || this.drugsParam.pharmacy_category_id == null) {

        this.$alert("请输入药品类目");

        return;
      }
      if (this.drugsParam.unit_id == "" || this.drugsParam.unit_id == null) {
        this.$alert("请选择药品单位");

        return;
      }
      if (this.drugsParam.small_unit_id == "" || this.drugsParam.small_unit_id == null) {
        this.$alert("请选择药品规格单位");

        return;
      }
      if (this.drugsParam.trade_price == "" || this.drugsParam.trade_price == null) {
        this.$alert("请输入药品进价");

        return;
      }
      
      if (this.drugsParam.retail_price == "" || this.drugsParam.retail_price == null) {
        this.$alert("请输入药品售价");

        return;
      }
       if (this.drugsParam.small_unit_num == "" || this.drugsParam.small_unit_num == null) {
        this.$alert("请输入药品规格单位数量");

        return;
      }
      me.$emit("submitadddrugs", me.drugsParam);
    
    },
    changeDrawer(v) {
      //console.log("112");
      this.drawer = v;
    },
  },
};
</script>

<style lang="scss" scoped>
// 新增回访
.see-patient-history-list-item {
  width: 100%;
  border: 1px solid #e1e1e1;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;

    .edit-buttom {
      // width: 100%;
      margin-top: 20px;
    }
  }
}
</style>
