<template>
      <el-table
            :Data="Data"
            style="width: 100%"
            >
            <el-table-column
                fixed
                prop="real_name"
                label="姓名"
                width="150"
                align="center">
            </el-table-column>
            <el-table-column
                prop="sex"
                label="性别"
                width="80"
                align="center">
            </el-table-column>
            <el-table-column
                prop="age"
                label="年龄"
                width="120"
                align="center">
            </el-table-column>
            <el-table-column
                prop="phone"
                label="联系电话"
                width="120"
                align="center">
            </el-table-column>
            
            <el-table-column
                prop="typeOfVisit"
                label="就诊类型"
                width="100"
                align="center">
            </el-table-column>
            <el-table-column
                prop="department"
                label="科室"
                width="100"
                align="center">
            </el-table-column>
            <el-table-column
                prop="doctor"
                label="医生"
                width="100"
                align="center">
            </el-table-column>
            <el-table-column
                prop="appointmentStatus"
                label="预约状态"
                width="100"
                align="center">
            </el-table-column>
            <el-table-column
                prop="arrivalTime"
                label="到店时间"
                width="160"
                align="center">
            </el-table-column>
            <el-table-column
                prop="timeOfAppointment"
                label="预约时间"
                width="160"
                align="center">
            </el-table-column>
            <el-table-column
                prop="visitTime"
                label="看诊时间"
                width="160"
                align="center">
            </el-table-column>
            <el-table-column
                fixed="right"
                label="操作"
                width="80"
                align="center">
                <template slot-scope="scope">
                    <el-button @click="handleClick(scope.row)" type="text" size="small">到店</el-button>
                </template>
            </el-table-column>
            
        </el-table>

</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components: components([
    'Container'
  ]),
  props: {
    name: {
      required: true,
      type: String
    },
    patient: {
      required: true,
      type: String
    },
    sex: {
      required: true,
      type: [
        String,
        Number
      ]
    },
    start: {
      required: true,
      type: [
        String,
        Object
      ]
    },
    end: {
      required: true,
      type: [
        String,
        Object
      ]
    },
    age: {
      required: true,
      type: [
        String,
        Number
      ]
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      lvlMap:{

      },
      statusMap: [
        {
          text: '全部',
          type: ''
        },
        {
          text: '在班',
          type: 'warning'
        },
        {
          text: '未在班',
          type: 'success'
        },
      ]
    }
  }
}
</script>

<style lang="scss" scoped>

$see-patient-list-item-height: 80px;

	.see-patient-list-item {
		height: $see-patient-list-item-height;
    line-height: $see-patient-list-item-height;
		align-items: center;

		>.header >img {
			width: 30px;
			height: 30px;
		}

		&.selected {
			box-shadow: 0 2px 7px 0 #d8d8d8;
			margin: 2px 0;
		}


		>.logo {
			width: 46px;
			height: 46px;
			margin-right: 10px;

			>img {
				width: 100%;
				height: 100%;
				border-radius: 50%;
			}
		}
    font-size: 14px;
		.name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;

		}

		>.grade {
			padding: 3px 12px;
			background: #F1BE5E;
			border: 1px solid #979797;
			margin-right: 15px;
		}
    .name{
      font-size: 12px;
    }
		.time {
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #0B7DFF;
			line-height: 15px;
      display: flex;
      justify-content: center;
		}

		>.time,
		.state {
			display: flex;
      justify-content: center;

		}
	}

.grid-content{
  text-align: center;
}

</style>
