<template>
<Container>
    <div>
        <el-table style="width: 100%" height="calc(100vh - 265px)" v-loading="loading" :data="tableData" :highlight-current-row="true" stripe  @sort-change="sortChange">
            <el-table-column fixed type="index" width="40"></el-table-column>
            <el-table-column fixed prop="stockout_no" label="出库单号" min-width="180" align="left"></el-table-column>

            <el-table-column prop="getAttribute.name" label="出库类型" min-width="180" align="left"></el-table-column>
            <el-table-column prop="getWarehouse.name" label="出库仓库" min-width="180" align="left"></el-table-column>

            <el-table-column prop="create_name" label="出库人" min-width="150" align="left"></el-table-column>
            <el-table-column prop="product_num" label="种类数量" min-width="150" align="left"></el-table-column>
            <el-table-column prop="total_num" label="入库总数量" min-width="150" align="left"></el-table-column>

            <el-table-column prop="remarks" label="备注" min-width="150" align="left"></el-table-column>

            <el-table-column prop="create_time" label="出库时间" width="160" align="left"></el-table-column>
        </el-table>
    </div>

    <el-drawer title="出库详情" :visible.sync="detailsDrawer" size="65%" style="fontsize: 20px">
        <DeliveryDetails></DeliveryDetails>
    </el-drawer>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: {
        ...components(["DeliveryDetails"], "pharmacy.Delivery"),
        ...components(["Container"]),
    },
    props: {
        tableData: {},
         loading: {
            default: false,
        },
    },
    data() {
        return {
            detailsDrawer: false,
        };
    },
    methods: {
         sortChange({
            column,
            prop,
            order
        }) {
            let sort = {
                order_type: undefined,
                order_field: undefined,
            };

            if (column && order) {
                sort.order_type = order === "ascending" ? "asc" : "desc";
                sort.order_field = prop;
            }

            this.$emit("sort", sort);
        },
    },
};
</script>

<style lang="scss" scoped></style>
