<template>
    <div v-if="scroll" class="hb-container" @mousewheel.stop="onMouseWheel">
        <div ref="innerEl" class="inner hb-container hb-layout-fit-unsized">
            <slot></slot>
        </div>
        <Bar :hidden="bar.hidden" :size="bar.size" :position="bar.position"></Bar>
    </div>
    <div v-else class="hb-container">
       <slot></slot>
    </div>
</template>

<script>

import {
    components
} from '@/helper' ;

import {
    include
} from 'zbee-sdk/package/hb-admin-system-template';

const buffer = include('function.buffer');

export default {
    props:{
        scroll:{
            type:Boolean,
            defualt:false
        }
    },

    data(){

        return {
            bar:{
                hiddenDelay:500,
                hidden:false,
                minSize:30,
                size:0,
                position:0,
                ratio:1
            }
        } ;
    },
    
    components:components([
        'Bar'
     ] , 'container'),

    mounted(){

        let me = this ;

        me.delayHideBar = buffer(() => me.hideBar() , {
            buffer:me.bar.hiddenDelay
        }) ;

    },

    methods:{

        showBar(){

            let me = this,
            {
                innerEl
            } = me.$refs,
            {
                scrollHeight,
                clientHeight
            } = innerEl;

            if(scrollHeight === clientHeight){

                return ;
            }


            let {
                bar
            } = me,{
                minSize
            } = bar,{
                scrollTop
            } = innerEl,
            offset = scrollHeight - clientHeight,
            size = clientHeight - offset,
            ratio = 1;

            if(size < minSize){

                ratio = (clientHeight - (size = minSize)) / offset ;
            
            }

            bar.hidden = false ;

            bar.ratio = ratio ;

            bar.size = size ;

            bar.position = scrollTop * ratio ;
        },

        hideBar(){

            this.bar.hidden = true ;
        },

        onMouseWheel({
            deltaY
        }){

            let me = this,
            {
                innerEl
            } = me.$refs ;

            innerEl.scrollTop += deltaY ;

            me.showBar() ;

            me.delayHideBar() ;
        }
    }
};


</script>