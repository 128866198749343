<template>
<Container class="see-detail-basic-inspect">
    <el-row style="width: 100%">
        <el-col :span="3">
            <el-tag class="basic-inspect" type="info">诊断 Diagnosis</el-tag>
        </el-col>
        <el-col :span="21">
            <div class="grid-content bg-purple-light grid-left">
                <!-- <FastInput class="fast-input" :value.sync="value" :promptTexts="promptTexts"></FastInput> -->
                <el-cascader style="width: 100%" filterable :options="basic.diagnosis_template" :show-all-levels="false" v-model="impressionss" @change="startReception" :props="{ label: 'full_name', value: 'name', multiple: true, emitPath: false }" clearable></el-cascader>
            </div>
        </el-col>
    </el-row>
    <el-row style="width: 100%">
        <el-col :span="3">
            <el-tag class="basic-inspect" type="info">科室 Department</el-tag>
        </el-col>
        <el-col :span="21">
            <div class="grid-content bg-purple-light grid-left">
                <el-cascader style="width: 100%;" filterable v-model="clinical_department_idd" :options="basic.clinical_department" :show-all-levels="false" @change="startReception" :props="{ label: 'name', value: 'clinical_department_id', emitPath: false }" clearable></el-cascader>
            </div>
        </el-col>
    </el-row>
    <el-row style="width: 100%">
        <el-col :span="3">
            <el-tag class="basic-inspect" type="info">主诉 Chief Complaint</el-tag>
        </el-col>
        <el-col :span="21">
            <div class="grid-content bg-purple-light grid-left">
                <el-cascader style="width: 100%" filterable v-model="chief_complaintt" :options="basic.complained_template" @change="startReception" :props="{ label: 'name', value: 'name', multiple: true }" clearable></el-cascader>
            </div>
        </el-col>
    </el-row>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

//import FastInput from "HB-FAST-INPUT";

export default {
    props: {
        start_reception: 0,
        basic: {
            type: Object,
            default: () => ({
                complained_template: [],
                clinical_department: [],
                diagnosis_template: [],
            }),
        },
        clinical_department_id: {
            type: Number,
            default: 0,
        },
        impressions: {},
        chief_complaint: {},
    },
    components: {
      ...components(["Container"]),
     // FastInput
    },

    data() {
        return {
            value:[],
            initPromptTexts: [{
                text: '陈治文'
            }, {
                text: '叶红刚'
            }, {
                text: 'RUFU'
            }, {
                text: '陈文'
            }, {
                text: '陈文1'
            }, {
                text: '陈文3'
            }
            ],
            impressionss: "",
            clinical_department_idd: "",
            chief_complaintt: "",
        };
    },
    computed: {

        promptTexts() {

            let {
                initPromptTexts,
                value
            } = this,
            texts = initPromptTexts.map(({
                text
            }) => text);

            return [
                ...initPromptTexts,
                ...value.filter(({
                    text
                }) => !texts.includes(text))
            ]

        },

        jsonValue() {

            return JSON.stringify(this.value);
        }
    },
    watch: {
        impressions: {
            handler(val, oldVal) {
                this.impressionss = val;
            },
            deep: true,
        },
        clinical_department_id: {
            handler(val, oldVal) {
                this.clinical_department_idd = val;
            },
            deep: true,
        },
        chief_complaint: {
            handler(val, oldVal) {
                this.chief_complaintt = val;
            },
            deep: true,
        },
    },
    methods: {
        /**
         * 返回基础检查的值å
         */
        getBasicValue() {
            return {
                clinical_department_id: this.clinical_department_idd,
                impressions: this.impressionss,
                chief_complaint: this.chief_complaintt,
            };
        },
        startReception() {
            let me = this;
            if (me.start_reception == 0) {
                me.impressionss = {};
                me.clinical_department_idd = {};
                me.chief_complaintt = {}
                me.$message({
                    type: "warning",
                    message: "请先操作开始接诊！",
                });
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.fast-input{
    width:100%;
    height:40px;
    z-index: 1;
    padding:0px;
    ::v-deep >.text{
        margin-top:2px;
        line-height:1;
        &:not([contenteditable=true]){
            padding:1px;

        }
    }
}
$see-detail-basic-inspect-item-title-width: 132px;

$see-detail-basic-inspect-item-height: 45px;

.see-detail-basic-inspect {
    .el-row {
        width: 100%;

        // border-top: 1px solid #979797;
        // border-left: 1px solid #979797;
        &:last-child {
            margin-bottom: 0;
            // border-bottom: 1px solid #979797;
        }
    }

    .basic-inspect {
        width: 100%;
        height: 40px;
        line-height: 40px;
        text-align: center;
        margin-top: 2px;
    }

    .bg-purple {
        background: #e5e9f2;
    }

    .bg-purple-light {
        background: #ffffff;
        // border-left: 1px solid #979797;
        // border-right: 1px solid #979797;
    }

    .grid-content {
        height: 45px;
        line-height: 45px;
        text-align: center;
    }

    .grid-left {
        text-align: left;
        padding-left: 10px;
    }

    // .row-bg {
    // 	padding: 10px 0;
    // 	background-color: #f9fafc;
    // }
}
</style>
