<template>
    <Container class="patient-detail-info-project">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>诊断结果</span>
            </div>
            <div>
                <el-table
                    :data="diagnosisData"
                    style="width: 100%"
                    max-height="200">
                    <el-table-column
                        prop="date"
                        label="看诊时间"
                        width="200"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="department"
                        label="科室"
                        mix-width="120"
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="doctor"
                        label="医生"
                        mix-width="120" 
                        align="center">
                    </el-table-column>
                    <el-table-column
                        prop="diagnosisResults"
                        label="诊断结果"
                        mix-width="350" 
                        align="center">
                    </el-table-column>
                </el-table>
            </div>
        </el-card>
    </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
  components: components([
    'Container'
  ]),
  
  data(){
    return {
      diagnosisData:[
          {
              date: '2020-05-21 13:40',   // 看诊时间
              department: '妇科',   // 科室
              doctor: '张敏',     // 医生
              diagnosisResults: '宫寒',   //诊断结果
          }
      ]
    }
  }
}
</script>

<style  lang="scss"  scoped>
    .clearfix{
        span{
            font-size: 16px;
        }
    }
</style>
