<template>
  <Container class="centent hb-layout-flex-1 hb-layout-vertical">
    <!-- <el-card> -->
    <Header
     @submit="handleSubmit"
      @submitreset="onSubmitReset"
       ref="header"
      :loading="loading"
      :warehousParam="warehousParam"
      :pharmacyCategoryRecords="pharmacyCategoryRecords"
      @getpharmacycategoryredords="onGetPharmacyCategoryRedords"
      @getpharmcyrecord="onGetPharmcyRecord"
      @savedelivery="onSaveDelivery"
      :pharmacyrecordsstockin="pharmacyrecordsstockin"
      :attributeDatas="attributeDatas.items"
      :smallunitDatas="smallunitDatas"
      :warehousDatas="warehousDatas.items"
    ></Header>

    <List :tableData="Data.items" :loading="loading"></List>
    <!-- </el-card> -->
    <BasicFooter
      :loading="loading"
      :size="Param.page_size"
      :total="Data.total_result"
      @change="handlePaginationChange"
    ></BasicFooter>
  </Container>
</template>

<script>
import { components,blocks } from "@/helper";

export default {
  components: {
    ...components(["Header", "List"], "pharmacy.Delivery"),
    ...components(["Container"]),
    ...blocks(["BasicFooter"], "Footer"),
  },

  props: {
    Data: {},
    Param: {},
    loading: {
      default: false,
    },
    attributeDatas: {},
    warehousParam: {},
    pharmacyCategoryRecords: {},
    pharmacyrecordsstockin: {},
    stockoutsData: {},
    smallunitDatas:{},
    warehousDatas:{}
  },

  data() {
    return {};
  },

  methods: {
    onGetPharmacyCategoryRedords() {
      this.$emit("getpharmacycategoryredords");
    },
    onGetPharmcyRecord(val) {
      this.$emit("getpharmcyrecord", val);
    },
    onSaveDelivery(dataOne, dataTwo) {
      this.$emit("savedelivery", dataOne, dataTwo);
    },
     // 分页变化改动
    handlePaginationChange(val) {
      //this.page = val;
      this.$emit("changepagination", val);
      //   this.$nextTick(() => {
      //     this.$refs.header.handleFormSubmit();
      //   });
    },
    onChangeSubmit() {
      this.$nextTick(() => {
        this.$refs.header.handleFormSubmit();
      });
    },

    // 确定查询
    handleSubmit(form, isRestore = false) {
      this.$emit("changesubmit", form, isRestore);
    },
    onSubmitReset(form) {
      let me = this;
      me.$emit("submitreset",form);
    },
    changeDrawer(v) {
      let me = this;
      //console.log("112");
      me.$refs.header.changeDrawer(v);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .right {
    width: 300px;
  }
}
</style>
