<template>
  <Container class="statistics">
    <div class="text item">
      <el-table
        style="width: 100%"
        height="calc(100vh - 265px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
       <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="diagnosis_record_no"
          label="看诊号"
          min-width="220"
          align="left"
        ></el-table-column>

         <el-table-column
          fixed
          prop="medical_record_no"
          label="病历号"
          min-width="180"
          align="left"
        ></el-table-column>

         <el-table-column
          fixed
          prop="appointment_record_no"
          label="预约号"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="get_user.nickname"
          label="用户"
          min-width="220"
          align="left"
        ></el-table-column>
      
        <el-table-column
          prop="getDoctor.nickname"
          label="医生"
          min-width="220"
          align="left"
        >
        </el-table-column>
        <el-table-column label="就诊类型" width="100" align="left">
          <template slot-scope="scope">
              <el-tag :type="visitMap[scope.row.type].type" effect="plain" size="mini">
                  {{ visitMap[scope.row.type].text }}
              </el-tag>
          </template>
        </el-table-column>
        
        <el-table-column
          prop="start_time"
          label="开始看诊时间"
          width="160"
          align="left"
        >
        </el-table-column>

         <el-table-column
          prop="end_time"
          label="结束看诊时间"
          width="160"
          align="left"
        >
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="160"
          align="left"
        ></el-table-column>
        
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
    tableData: {
      default: () => [],
    },
  },
  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {
      visitMap: [
        {
            text: "待确认",
            type: "warning",
        },
        {
            text: "首诊",
            type: "success",
        },
        {
            text: "初诊",
            type: "primary",
        },
        {
            text: "复诊",
            type: "danger",
        },
      ],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;
}
</style>
