<template>
  <ChargeLayout>
    <template v-slot:patient-list>
      <LeftMain
        ref="medicalRecordList"
        class="hb-layout-fit-sized"
        :loading="loading"
        :medicalRecordList="medicalRecordList"
        @medicalRecordSelect="onItemSelect"
        @todayevent="onTodayEvent"
        @sevenevent="onSevenEvent"
        @thirtyevent="onThirtyEvent"
        @changestatus="onChangeStatus"
        @changestatusfee="onChangeStatusFee"
        @leftsearch="onLeftSearch"
        :leftFee="leftFee"
      ></LeftMain>
    </template>
    <template v-slot:charge-operation-main>
      <CenterTopHeader
        :real_name="medicalRecord.real_name"
        :sex="medicalRecord.sex"
        :age="medicalRecord.age"
        :medical_record_no="medicalRecord.medical_record_no"
        :diagnosisData="diagnosisItem"
        :inspectionItems="examination"
        :treatmentItems="treatment"
        :pharmacyItems="chinamedicine"
        :westernmedicineItems="westernmedicine"
        :paymentItems="paymentParam"
        :paymentRecordData="paymentRecordItem"
        :visitStatus="visitStatus"
        :userPaymentResult="userPaymentResult"
        v-if="medicalRecordarr.length>0"
      ></CenterTopHeader>
    </template>
    <template v-slot:charge-detail>
      <CenterMain
        ref="center_main"
        :inspectionItems="examination"
        :treatmentItems="treatment"
        :pharmacyItems="chinamedicine"
        :westernmedicineItems="westernmedicine"
        :paymentItems="paymentParam"
        @getpaymentrecrods="onGetPaymentRecrods"
        :paymentDatas="paymentRecords.items"
        @getpaymentaccount="onGetPaymentAccount"
        :usermoneyData="usermoney"
        @savepaymentitem="onPaymentSubmit"
        @insuranceaccount="onInsuranceAccount"
        :insuranceAccountDatas="insuranceAccountRecords.items"
        :diagnosisData="diagnosisItem"
        :paymentRecordData="paymentRecordItem"
        @submithangingorder="onSubmitHangingOrder"
         v-if="medicalRecordarr.length>0"
      ></CenterMain>
    </template>

    <template v-slot:patient-history-list>
      <RightMain :historyItems="historyData"></RightMain>
    </template>
  </ChargeLayout>
</template>

<script>
import Page from "@/components/Page";

import {
  // eslint-disable-next-line no-unused-vars
  mock,
  components,
  layouts,
} from "@/helper";
export default {
  components: {
    ...layouts(["Charge"]),
    ...components(
      ["Left.Main", "Center.TopHeader", "Center.Main", "Right.Main"],
      "charge"
    ),
  },
  data() {
    return {
      leftListParam: {
        //列表参数
        page_no: 1,
        page_size: 10,
        keyword: "",
        appdate: "",
        payment_status: "1,2,3",
      },

      medicalRecordList: [], //病历列表
      medicalRecord: {}, //触发点击 后的病历对象
      examination: {
        item: {
          detailed_list: {},
        },
      }, //检查明细
      treatment: {
        item: {
          detailed_list: {},
        },
      }, //治疗项目
      chinamedicine: {
        item: {
          detailed_list: {},
        },
      }, //治
      westernmedicine: {
        item: {
          detailed_list: {},
        },
      },
      loading: {
        status: false, //预约列表loading
      },
      appointment_record_no_first: "",
      appointment_record_no: "",
      diagnosisRecordList: {}, //看诊记录历史
      historyDiagnosisList: [], //历史记录
      history_param: {
        //历史列表筛选参数
        diagnosis_record_id: undefined,
        create_time: undefined,
      },
      returnVisit: [], //看诊详情
      visitPathData: [], //就诊路径
      DiannosisItem: {},
      nowDate: "",
      centerRecordsParam: {
        //列表参数
        appointment_date: undefined,
        dayEvent: 1, //1
        eventEvent: undefined, //7
        thirtyEvent: undefined, //30
        //status: "3,4",
        payment_status: "1,2,3,4", //默认all(1,2,3,4)
      },
      historyData: [],
      visitStatus: 0, //点击选择后的患者的就诊状态，传入center->topheader
      price_payable: 0, //应付价格
      userdiscount: 0, //会员折扣'
      paymentParam: {
        medical_record_no: "",
        appointment_record_no: "",
        pay_amount: 0, ////应付价格
        use_level: 0, //会员折扣
        use_promotion: 0, //优惠促销活动抵扣价格
        use_coupon: 0, //优惠劵抵扣
        use_discount: 0, // 折扣项目价格
        total_money: 0, //应付金额
        total_amount: 0, //实际金额
        use_insurance: 0, //保险抵扣
        use_integral: 0, //积分抵扣
        use_justment: 0, //额外抵扣
        remarkL: "",
        doctor_id: 0,
        user_id: 0,
        to_payment: 1, //z支付方式
        cause: "消费结算",
        payment_status: 1,
        trade_status: 1,
        create_user_id: 1,
        payment_status: 0, //支付状态
        execute_status: 0, //执行状态
        create_id: 0,
        pay_type: 3, // //1=看诊2=检查3=诊疗4=药品5=产品
        arrears: 0, ////0=已交1=欠费
        pro_type: 6, ////1=看诊2=检查3=诊疗4=药品5=产品 6=全部
      },
      paymentRecords: {},
      usermoney: "",
      insuranceAccountRecords: {},
      userInfo: "",
      diagnosisItem: {},
      paymentRecordItem: {},
      leftFee: 0, //0 正常结算 1 欠费
      medicalRecordarr:[],
      userPaymentResult:0
    };
  },

  watch: {
    paymentParam: {
      handler(val, oldVal) {
        this.paymentParam.use_level =
          Number(val.pay_amount) * ((100 - Number(this.userdiscount)) / 100);
        this.paymentParam.total_money = Number(val.pay_amount) - Number(val.use_level);
      },
      deep: true,
    },
  },
  mounted() {
    let that = this;

    that.initPage();

    that.formatDate();

    that.getMedicalRecordList();

    that.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
    that.paymentParam.create_id = this.userInfo.client_id;

   // console.log("medicalRecordList2", that.medicalRecordList);
    // 默认选中操作å
  },
  methods: {
    levelPrice(newValue, oldValue) {
      return Number(newValue);
    },
    /*************************************left start **************************************/
    /**
     * 初始化页面的方法
     */
    initPage() {},
    /**
     * 获取患者的简要信息列表
     */
    async getMedicalRecordList() {
      let me = this;

      me.medicalRecordList = await mock(
        "charge/Left/getMedicalRecordList",
        me.centerRecordsParam
      );

      // if (me.medicalRecordList.length) {
      //   me.$refs.medicalRecordList.select(me.medicalRecordList[0]);
      // }
      // console.log("medicalRecordList1", me.medicalRecordList);
    },
    onItemSelect(val) {

      console.log("onItemSelect", val);
      let me = this;

      //me.paymentStatus= val.paymentStatus;

      // 根据患者的病历号获取患者的简要信息
      me.getMedicalRecord(val.medicalRecordNo);

     //获取诊疗费配置信息和其它信息
      let userData = {
        appointment_record_no:val.appointment_record_no,
        user_id:val.user_id
      }
      me.getDiagnosisItem(userData);

      // 根据患者的病历号获取患者的检查项目
      me.getExaminationRecord(val.appointment_record_no);
      // // 根据患者的病历号获取患者的治疗明细
      me.getTreatmentRecord(val.appointment_record_no);
      // // 根据患者的病历号获取患者的中
      me.getPharmacyChinaRecord(val.appointment_record_no);
      me.getWesternmedicineRecord(val.appointment_record_no);
     // me.getHistoryRecord(val.medicalRecordNo);

      me.getPaymentRecordItem(val.appointment_record_no);

      me.getUserMoneyRecordItem(val.user_id);

      me.paymentParam.user_id = val.user_id;
      me.paymentParam.appointment_record_no = val.appointment_record_no;
      me.paymentParam.medical_record_no = val.medicalRecordNo;
      me.paymentParam.paymentStatus = val.paymentStatus;

      me.paymentParam.pay_amount = 0;
      me.paymentParam.use_level = 0;

      me.visitStatus = val.visitStatus; //设定点击后患者的就诊状态存入Data
    },
    /**
     * 患者列表 正常收费筛选状态
     */
    onChangeStatus(e) {
      let me = this;
      let statusVlaue = e;
      if (statusVlaue == "all") {
        me.centerRecordsParam.payment_status = "1,2,3,4";
      } else if (statusVlaue == 3) {
        me.leftFee = 1;
        me.centerRecordsParam.payment_status = statusVlaue;
      } else {
        me.centerRecordsParam.payment_status = statusVlaue;
      }

      this.getMedicalRecordList();
    },
    /**
     * 患者列表 欠费筛选状态
     */
    onChangeStatusFee(e) {
      let me = this;
      let statusVlaue = e;
      if (statusVlaue == "all") {
        me.centerRecordsParam.payment_status = "1,2,3,4,5";
      } else if (statusVlaue == 6) {
        me.leftFee = 0;
      } else {
        me.centerRecordsParam.payment_status = statusVlaue;
      }

      this.getMedicalRecordList();
    },
    onLeftSearch(e) {
      let me = this;
      me.centerRecordsParam.keywords = e;
      me.centerRecordsParam.appointment_date = undefined;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      this.getMedicalRecordList();
    },
    onTodayEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.getMedicalRecordList();
    },
    onSevenEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.centerRecordsParam.eventEvent = 1;
      me.getMedicalRecordList();
    },
    onThirtyEvent() {
      let me = this;
      me.centerRecordsParam.appointment_date = this.nowDate;
      me.centerRecordsParam.dayEvent = undefined;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = 1;
      me.getMedicalRecordList();
    },
    onCalendarEvent(e) {
      let me = this;
      me.centerRecordsParam.appointment_date = e;
      me.centerRecordsParam.dayEvent = 1;
      me.centerRecordsParam.eventEvent = undefined;
      me.centerRecordsParam.thirtyEvent = undefined;
      me.getMedicalRecordList();
    },
    /*************************************** left end **************************************/
    /*************************************** center  first **************************************/
    onPaymentSubmit(data) {
      let me = this;
      me.paymentParam.to_payment = data.to_payment;
      me.paymentParam.total_amount = data.total_amount;
      me.paymentParam.payment_status = data.payment_status;
      me.paymentParam.execute_status = data.execute_status;
      me.paymentParam.insurance_amount = data.insurance_amount;
      me.paymentParam.insuranceAID = data.insuranceAID;
      me.$confirm("确认结算吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {

          if(data.payment_status==3){
            this.onPaymentHangingRecordSave()
          }else{
            this.onPaymentRecordSave();
          }
          
        })
        .catch();
    },
    //挂单
    onSubmitHangingOrder(data) {
      let me = this;
      me.paymentParam.payment_status = 3; // 付款状态 1=未付 2=已付 3=欠款
      me.paymentParam.execute_status = 1; //执行状态 1=未执行 2=已执行
      me.paymentParam.arrears_status = 1; // 3=欠款
      me.$confirm("确认结算吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.onHangingOrderSave();
        })
        .catch();
    },

    onGetPaymentRecrods() {
      let me = this;
      me.getPaymentRecords();
    },
    onGetPaymentAccount() {
      let me = this;
      me.getUserMoneyRecordItem(me.paymentParam.user_id);
    },
    onInsuranceAccount() {
      let me = this;
      me.getInsuranceAccountItem();
    },
    /*************************************** center end **************************************/
    /**
     * 获取支付方式
     */
    async getPaymentRecords() {
      let me = this;

      let data={
        type:"payment"
      }
      let res = await mock("configuration/Inside/Payment/getRecords",data);
      if (res.status == 200) {
        me.paymentRecords = res.data;
      }
    },
    async getInsuranceAccountItem() {
      let me = this;
      let data = {
        medical_record_no: this.paymentParam.medical_record_no,
        is_delete: 1,
      };
      let res = await mock("configuration/Insurnace/Account/getRecords", data);
      if (res.status == 200) {
        me.insuranceAccountRecords = res.data;
      }
    },

    /**
     * 根据预约号获取看诊订单详情
     */
    async getPaymentRecordItem(val) {
      let me = this;

      me.paymentRecordItem = await mock("charge/Center/getPaymentRecordItem", {
        appointment_record_no: val,
      });
    },
    /**
     * 获取患者账户信息
     */
    async getUserMoneyRecordItem(val) {
      let me = this;

      let request = await mock("patient/middle/getUserMoneyItem", {
        client_id: val,
      });
      if (request != null) {
        me.usermoney = request.data;
      } else {
        me.usermoney = me.usermoney_basic;
      }
    },

    /**
     * 根据患者的病历号获取一个患者病历记录信息
     */
    async getMedicalRecord(val) {
      let me = this;
      me.medicalRecord = await mock("charge/Center/getMedicalRecordInfoItem", {
        medical_record_no: val,
      });
      me.medicalRecordarr = Object.getOwnPropertyNames( me.medicalRecord);
      //console.log("medicalRecord",me.medicalRecordarr.length);
      me.paymentParam.medical_record_no = me.medicalRecord.medical_record_no;
      me.userdiscount = Number(me.medicalRecord.discount);
    },
    /**
     * 根据患者的病历号获取一个患者病历记录信息
     */
    async getDiagnosisItem(val) {
      let me = this;
      me.diagnosisItem = await mock("charge/Center/getDiagnosisRecord", val);
      if (me.diagnosisItem.consulation.length > 0) {
        me.paymentParam.consulation = me.diagnosisItem.consulation;
      }
      me.userPaymentResult = me.diagnosisItem.userPaymentResult;
    

      me.diagnosisItem.consulation.forEach((item) => {
        //遍历prodAllPrice这个字段，并累加
        me.paymentParam.pay_amount = me.floatAdd(me.paymentParam.pay_amount, item.price);
      });
    },
    /**
     * 根据患者的病历号获取一个患者检查项目
     */
    async getExaminationRecord(val) {
      let me = this;
      me.examination = await mock("charge/Center/getExaminationRecord", {
        appointment_record_no: val,
      });
      if (me.examination.length > 0) {
        me.paymentParam.examination = me.examination;
      }
      me.examination.forEach((item) => {
        //遍历prodAllPrice这个字段，并累加
        me.paymentParam.pay_amount = me.floatAdd(me.paymentParam.pay_amount, item.price);
        // me.paymentParam.appointment_record_no = item.appointment_record_no;
      });
      //返回
      //me.sum = me.sum +sums;
    },
    /**
     * 根据患者的病历号获取一个患者治疗项目
     */
    async getTreatmentRecord(val) {
      let me = this;
      me.treatment = await mock("charge/Center/getTreatmentRecord", {
        appointment_record_no: val,
      });
      if (me.treatment.length > 0) {
        me.paymentParam.treatments = me.treatment;
      }
      me.treatment.forEach((item) => {
        //遍历prodAllPrice这个字段，并累加
        me.paymentParam.pay_amount = me.floatAdd(me.paymentParam.pay_amount, item.price);
      });
    },
    /**
     * 根据患者的病历号获取一个患者中药
     */
    async getPharmacyChinaRecord(val) {
      let me = this;
      me.chinamedicine = await mock("charge/Center/getPharmacyRecord", {
        appointment_record_no: val,
        type: 1,
      });
      if (me.chinamedicine.length > 0) {
        me.paymentParam.chinamedicine = me.chinamedicine;
      }
      me.chinamedicine.forEach((item) => {
        //遍历prodAllPrice这个字段，并累加
        me.paymentParam.pay_amount = me.floatAdd(me.paymentParam.pay_amount, item.price);
      });
    },
    /**
     * 根据患者的病历号获取一个患者中药
     */
    async getWesternmedicineRecord(val) {
      let me = this;
      me.westernmedicine = await mock("charge/Center/getPharmacyRecord", {
        appointment_record_no: val,
        type: 2,
      });
      if (me.westernmedicine.length > 0) {
        me.paymentParam.westernmedicine = me.westernmedicine;
      }
      me.westernmedicine.forEach((item) => {
        //遍历prodAllPrice这个字段，并累加
        me.paymentParam.pay_amount = me.floatAdd(me.paymentParam.pay_amount, item.price);
      });
    },
    async onPaymentRecordSave() {
      let me = this;
      try {
            let resSave =  await mock("charge/Center/setPaymentRecordOrder", me.paymentParam).then(
              (res) => {
                //me.getPatientsList();
                me.$message({
                  showClose: true,
                  type: "success",
                  message: "保存成功!",
                });
              }
            );
          } catch (err) {
            me.$message({
              showClose: true,
              type: "warning",
              message: err.response.data.message,
            });
          }

      // let res = await mock("charge/Center/setPaymentRecordOrder", me.paymentParam);
      // if (res) {
      //   me.$message({
      //      showClose: true,
      //     type: "success",
      //     message: "结算成功!",
      //   });
      //   me.$refs.center_main.changeInfoDrawer(false);
      //   me.getMedicalRecordList();
      // } else {
      //   me.$message({
      //      showClose: true,
      //     type: "warning",
      //     message: "结算失败!",
      //   });
      // }
    },
     async onPaymentHangingRecordSave() {
      let me = this;

       try {
            let resSave =  await mock("charge/Center/setPaymentRecordHangingOrder", me.paymentParam).then(
              (res) => {
                //me.getPatientsList();
                me.$message({
                  showClose: true,
                  type: "success",
                  message: "保存成功!",
                });
              }
            );
          } catch (err) {
            me.$message({
              showClose: true,
              type: "warning",
              message: err.response.data.message,
            });
          }


      // let res = await mock("charge/Center/setPaymentRecordHangingOrder", me.paymentParam);
      // if (res) {
      //   me.$message({
      //      showClose: true,
      //     type: "success",
      //     message: "结算成功!",
      //   });
      //   me.$refs.center_main.changeInfoDrawer(false);
      //   me.getMedicalRecordList();
      // } else {
      //   me.$message({
      //      showClose: true,
      //     type: "warning",
      //     message: "结算失败!",
      //   });
      // }
    },
    async onHangingOrderSave() {
      let me = this;
      try {
        let res = await mock("charge/Center/setHangingOrderItem", me.paymentParam);
        if (res.status == "200") {
          me.$message({
             showClose: true,
            type: "success",
            message: "挂单成功!",
          });
          me.$refs.center_main.changeInfoDrawer(false);
          me.getMedicalRecordList();
        }
      } catch (err) {
        me.$message({
           showClose: true,
          type: "warning",
          message: "挂单失败，联系管理员！",
        });
      }
    },
    async getHistoryRecord(val) {
      let me = this;
      me.historyData = await mock("charge/Right/getHistoryRecords", {
        medical_record_no: val,
      });
    },

    formatDate() {
      let date = new Date();
      let year = date.getFullYear(); // 年
      let month = date.getMonth() + 1; // 月
      let day = date.getDate(); // 日
      //   let week = date.getDay(); // 星期
      //   let weekArr = [ "星期日", "星期一", "星期二", "星期三", "星期四", "星期五", "星期六" ];
      //   let hour = date.getHours(); // 时
      //   hour = hour < 10 ? "0" + hour : hour; // 如果只有一位，则前面补零
      //   let minute = date.getMinutes(); // 分
      //   minute = minute < 10 ? "0" + minute : minute; // 如果只有一位，则前面补零
      //   let second = date.getSeconds(); // 秒
      //   second = second < 10 ? "0" + second : second; // 如果只有一位，则前面补零
      //this.nowDate = `${year}/${month}/${day} ${hour}:${minute}:${second} ${weekArr[week]}`;
      this.centerRecordsParam.appointment_date = `${year}-${month}-${day}`;
      this.nowDate = `${year}-${month}-${day}`;
    },
    floatAdd(arg1, arg2) {
      var r1, r2, m;
      try {
        r1 = arg1.toString().split(".")[1].length;
      } catch (e) {
        r1 = 0;
      }
      try {
        r2 = arg2.toString().split(".")[1].length;
      } catch (e) {
        r2 = 0;
      }
      m = Math.pow(10, Math.max(r1, r2));
      return (arg1 * m + arg2 * m) / m;
    },
  },
};
</script>

<style lang="scss" scoped>
.search-left {
  margin: 3px 2px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  height: 60px;
  line-height: 60px;
}

.search {
  width: 295px;
  padding: 0 10px;

  > .el-input {
    position: relative;
    font-size: 14px;
    display: inline-block;
    width: 160px;
    margin-right: 10px;
  }
}
</style>
