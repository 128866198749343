<template>
  <Container class="see-detail hb-layout-vertical">
    <Container>
      <Header
        ref="topHead"
        @preservation="onPreservation"
        :Data="medicalRecordItem"
        @regionlist="onRegionList"
        @historylist="onHistoryList"
        :regionDatas="regionDatas"
        :historyDatas="historyDatas"
      ></Header>
    </Container>

    <div style="height: calc(100vh - 145px); overflow: auto;width: 99%; margin: auto;">
      <Container class="vital-signs">
        <el-card class="box-card" style="margin-top: 4px;">
          <BasicTop
            ref="basic_top"
            :last_name="medicalRecord.last_name"
            :first_name="medicalRecord.first_name"
            :chinese_name="medicalRecord.chinese_name"
            :sex="medicalRecord.sex"
            :age="medicalRecord.age"
            :medical_record_no="medicalRecord.medical_record_no"
            :doctorDatas="medicalRecord.getBindDoctor"
            :departmentDatas="medicalRecord.getBindDepartment"
            :bindSourceDatas="medicalRecord.getBindSource"
            @binddoctorsearch="onBindDoctorSearch"
            :bindSearchDatas="bindSearchDatas"
            @submitbindsource="onSubmitBindSource"
            @customersource="onCustomerSource"
            :sourceDatas="sourceDatas"
          ></BasicTop>
        </el-card>
      </Container>

      <!-- 账户信息 -->
      <Container class="vital-signs">
        <el-card class="box-card">
          <AccountInformation
            ref="information"
            :usermoney="usermoney"
            @submitrecharge="onSubmitRecharge"
            @submitupgrade="onSubmitUpgrade"
            @gradelevel="onGradeLevel"
            @gradelrecharge="onGradelRecharge"
            :gradelevelDatas="gradelevelDatas"
            :gradeRechargeDatas="gradeRechargeDatas"
            :paymentParam="paymentParam"
            @treatmenttemplateparent="onTreatmentTemplateParent"
            :treatmenttemplateDatas="treatmenttemplateDatas"
            @gettreatmenttemplatechildren="onGetTreatmentTemplateChildren"
            :treatmenttemplateChildrenDatas="treatmenttemplateChildrenDatas"
            @getpaymentrecrods="onGetPaymentRecrods"
            :paymentDatas="paymentDatas"
            @savepaymentitem="onSavePaymentItem"
            @insurancecompany="onInsuranceCompany"
            :insuranceCompanyDatas="insuranceCompanyDatas.items"
            @insurancelevel="onInsuranceLevel"
            :insuranceLevelDatas="insuranceLevelDatas"
            @insurancesetting="onInsuranceSetting"
            :insuranceSettingDatas="insuranceSettingDatas"
            @saveinsuranceaccountitem="onSaveInsuranceAccountItem"
            @insuranaccount="onInsuranAccount"
            :insuranceAccountDatas="insuranceAccountDatas"
            @insuranceblurvalue="onInsuranceBlueValue"
            @remarkblurvalue="onRemarkBlueValue"
          ></AccountInformation>
        </el-card>
      </Container>

      <Container class="vital-signs">
        <el-card class="box-card">
          <EmptyHeader
            :Data="BasicInfoTitle"
            :Edit="BasicInfoEdit"
            @click.native="editInfo"
          ></EmptyHeader>
          <BasicInfo :base="medicalRecord"></BasicInfo>
        </el-card>
      </Container>

      <Container class="vital-signs">
        <!-- <el-card class="box-card" > -->
          <el-tabs v-model="activeName" type="border-card" @tab-click="handleClick">
            <!-- 看诊记录 -->
            <el-tab-pane :label="VisitRecordsTitle" name="first">
              <VisitRecords
                :tableData="diagnosisRecordList.items"
                @currentchange="clickVisitPath"
                 v-if="diagnosisRecordList.items!==''"
                @clearrightdata="onClearRightData"
              ></VisitRecords>
            </el-tab-pane>
            <!-- 收费记录 -->
            <el-tab-pane :label="ChargeRecordTitle" name="second">
              <ChargeRecord
                :tableData="paymentRecord.items"
              ></ChargeRecord>
            </el-tab-pane>
            <!-- 预约记录 -->
            <el-tab-pane :label="AppointmentRecordTitle" name="third">
              <AppointmentRecord
                :tableData="appointmentRecord.items"
                v-if="appointmentRecord.items!==''"
              ></AppointmentRecord>
            </el-tab-pane>
            <!-- 充值记录 -->
            <el-tab-pane :label="RechargeRecordTitle" name="flour">
              <RechargeRecord
                :tableData="rechargeRecordList.items"
              ></RechargeRecord>
            </el-tab-pane>
          </el-tabs>
        <!-- </el-card> -->

        <el-card class="box-card" shadow="never">
          <BasicPagination
            :total="diagnosisRecordList.total_result"
            :size="pagintaion.size"
          ></BasicPagination>
        </el-card>
      </Container>
    </div>

    <ArchivesEditInfo
      ref="medicalEdit"
      @preservation="onPreservation"
      :drawer_title="drawer_add_title"
      :size="size"
      :direction="direction"
      :regionDatas="regionDatas"
      :historyDatas="historyDatas"
      :historyMedicalDatas="historyMedicalDatas"
      @editmedicalrecorditem="onEditMedicalRecordItem"
      @insurancelevel="onInsuranceLevel"
    ></ArchivesEditInfo>
  </Container>
</template>

<script>
import { components, blocks } from "@/helper";

export default {
  props: {
    insuranceLevelDatas: {},
    insuranceCompanyDatas: {},
    paymentDatas: {},
    paymentParam: {},
    historyMedicalDatas: {},
    historyDatas: {},
    regionDatas: {},
    gradelevelDatas: {},
    gradeRechargeDatas: {},
    medicalRecord: {
      //病历信息
      type: Object,
      default: () => {},
    },
    usermoney: {},
    medicalRecordItem: {},
    rechargeRecordList: {},
    paymentRecord: {},
    appointmentRecord: {},
    treatmenttemplateDatas: {},
    treatmenttemplateChildrenDatas: {},
    diagnosisRecordList: {},
    insuranceSettingDatas:{},
    insuranceAccountDatas:{},
    bindSearchDatas:{},
    sourceDatas:{}
},
  components: {
    ...blocks(["EmptyHeader"], "Header"),
    ...blocks(["BasicPagination"], "Pagination"),
    ...components(
      [
        "Header",
        "BasicInfo",
        "VisitRecords",
        "BasicTop",
        "AccountInformation",
        "ChargeRecord",
        "AppointmentRecord",
        "RechargeRecord",
        "Archives.EditInfo",
      ],
      "patient.Center"
    ),
    ...components(["Container"]),
  },
  watch: {
     bindSearchDatas: function (newVal) {
      //console.log("wathc1", newVal);
     // this.states = newVal;
    },
  },
  data() {
    return {
      direction: "rtl",
      size: "75%",
      drawer_add_title: "编辑档案",
      BasicInfoTitle: "基本信息",
      BasicInfoEdit: "编辑",

      VisitRecordsTitle: "看诊记录",
      ChargeRecordTitle: "消费记录",
      AppointmentRecordTitle: "预约记录",
      RechargeRecordTitle: "充值记录",
      pagintaion: {
        size: 1,
      },
      activeName: "first",
    };
  },
  methods: {

    onClearRightData(val){
      
       this.$emit("clearrightdata",val);
    },
    changeDrawer(val) {
      this.$refs.topHead.changeDrawer(val);
      this.$refs.basic_top.changeDrawer(val);
      this.$refs.information.changeDrawer(val);
    },
    changeInfoDrawer(val) {
      this.$refs.basic_top.changeDrawer(val);
    },
    clickVisitPath(val) {
      this.$emit("visitpath", val);
    },
    onPreservation(val) {
      //"add2", val);
      this.$emit("preservation", val);
    },

    handleClick(tab, event) {
     // console.log(tab, event);
    },
    onSubmitRecharge(val) {
      this.$emit("submitrecharge", val);
    },
    onSubmitUpgrade(val) {
      this.$emit("submitupgrade", val);
    },
    informationn() {
      let me = this;
      me.$refs.information.gradeDialogg();
      // console.log("12121212",me.$refs.information.gradeDialogg);
    },
    onGradeLevel() {
      this.$emit("gradelevel");
    },
    onGradelRecharge() {
      this.$emit("gradelrecharge");
    },
    onRegionList() {
      this.$emit("regionlist");
    },
    onHistoryList() {
      this.$emit("historylist");
    },
    editInfo() {
      //console.log("12");
      this.$emit("regionlist");
      this.$emit("historylist");
      this.$emit("historymedicalitem");
      this.$refs.medicalEdit.changeDrawer(true);
    },
    onEditMedicalRecordItem(data) {
      this.$emit("editmedicalrecorditem", data);
    },
    onTreatmentTemplateParent() {
      this.$emit("treatmenttemplateparent");
    },
    onGetTreatmentTemplateChildren(val) {
      this.$emit("gettreatmenttemplatechildren", val);
    },
    onGetPaymentRecrods() {
      this.$emit("getpaymentrecrods");
    },
    onSavePaymentItem(data) {
      this.$emit("savepaymentitem", data);
    },
    onInsuranceCompany() {
      this.$emit("insurancecompany");
    },
    onInsuranceLevel(data) {
      this.$emit("insurancelevel", data);
    },
    onInsuranceSetting(data) {
      this.$emit("insurancesetting", data);
    },
    onSaveInsuranceAccountItem(data) {
      this.$emit("saveinsuranceaccountitem", data);
    },
    onInsuranAccount(){
       this.$emit("insuranaccount");
    },
    onBindDoctorSearch(query) {
       this.$emit("binddoctorsearch", query);
    },
    onSubmitBindSource(data){
       this.$emit("submitbindsource", data);
    },
    onCustomerSource(){
      this.$emit('customersource');
    },
    onInsuranceBlueValue(data){
      this.$emit("insuranceblurvalue",data);
    },
    onRemarkBlueValue(data) {
      this.$emit("remarkblurvalue",data);
    }
    
  },
};
</script>

<style lang="scss" scoped>
.see-detail {
  
  .vital-signs {
    margin: 0px 0 10px;
    box-shadow: 0 2px 7px 0 #d8d8d8;

    > .body {
      height: 132px;
    }

    > .box-card {
      width: 100%;
      
      .clearfix {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .header-left-btn {
          display: flex;
          align-items: center;

          .title {
            font-size: 16px;
            font-family: PingFangSC-Medium, PingFang SC;
            font-weight: 500;
            color: #5b5b5b;
            line-height: 22px;
            margin-right: 20px;
          }
        }
      }
    }

    > .header {
      padding: 16px 0 13px;
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      line-height: 22px;
    }
  }

  .el-row {
    width: 100%;
    // border-top: 1px solid #979797;
    // border-left: 1px solid #979797;

    &:last-child {
      margin-bottom: 0;
      // border-bottom: 1px solid #979797;
    }
  }

  .basic-inspect {
    width: 100%;
    height: 40px;
    line-height: 40px;
    text-align: center;
    margin-top: 2px;
  }

  .bg-purple {
    background: #e5e9f2;
  }

  .bg-purple-light {
    // background: #fff;
    // border-left: 1px solid #979797;
    // border-right: 1px solid #979797;
  }

  .grid-content {
    height: 45px;
    line-height: 45px;
    text-align: center;
  }

  .grid-left {
    text-align: left;
    padding-left: 10px;
  }

  .row-bg {
    // padding: 10px 0;
    // background-color: #f9fafc;
  }
}
</style>
