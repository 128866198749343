<template>
  <Container class="statistics">
    <div class="text item">
      <div class="total">
        <div class="total-amount">
          <el-image class="image" src="../../image/meet.png"></el-image>
          <div class="content">
            <div
              class="total-number"
              v-if="statisticsData && statisticsData.total_price_pay >= 0"
            >
              {{ statisticsData.total_price_pay.toFixed(2) }}
            </div>
            
            <div class="total-name">应付总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image" src="../../image/cash.png"></el-image>
          <div class="content">
            <div
              class="total-number"
              v-if="statisticsData && statisticsData.total_price_money >= 0"
            >
              {{ statisticsData.total_price_money.toFixed(2) }}
            </div>
            <div class="total-name">现金支付总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image" src="../../image/member.png"></el-image>
          <div class="content">
            <div
              class="total-number"
              v-if="statisticsData && statisticsData.total_price_level >= 0"
            >
              {{ statisticsData.total_price_level.toFixed(2) }}
            </div>
            <div class="total-name">会员优惠总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image" src="../../image/insurance.png"></el-image>
          <div class="content">
            <div
              class="total-number"
              v-if="statisticsData && statisticsData.total_price_insurance >= 0"
            >
              {{ statisticsData.total_price_insurance.toFixed(2) }}
            </div>
            <div class="total-name">保险总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image" src="../../image/discount.png"></el-image>
          <div class="content">
            <div
              class="total-number"
              v-if="statisticsData && statisticsData.total_price_justment >= 0"
            >
              {{ statisticsData.total_price_justment.toFixed(2) }}
            </div>
            <div class="total-name">现场优惠总金额</div>
          </div>
        </div>
        <div class="total-amount">
          <el-image class="image" src="../../image/actual_payment.png"></el-image>
          <div class="content">
            <div
              class="total-number"
              v-if="statisticsData && statisticsData.total_price >= 0"
            >
              {{ statisticsData.total_price.toFixed(2) }}
            </div>
            <div class="total-name">实际支付总金额</div>
          </div>
        </div>
      </div>
      <el-table
        style="width: 100%"
        height="calc(100vh - 385px)"
        v-loading="loading"
        :data="tableData"
        :highlight-current-row="true"
        @sort-change="sortChange"
      >
        <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column
          fixed
          prop="get_user.nickname"
          label="姓名"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="medical_record_no"
          label="病历号"
          min-width="180"
          align="left"
        ></el-table-column>

        <el-table-column
          prop="appointment_record_no"
          label="预约号"
          min-width="220"
          align="left"
        ></el-table-column>

        <el-table-column prop="pay_amount" label="应付金额" width="120" align="left">
          <template slot-scope="scope">
            {{ Number(scope.row.pay_amount).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column prop="use_money" label="账户余额" width="120" align="left">
          <template slot-scope="scope">
            {{ Number(scope.row.use_money).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column prop="use_level" label="会员优惠" width="120" align="left">
          <template slot-scope="scope">
            {{ Number(scope.row.use_level).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="use_insurance"
          label="保险支付"
          width="120"
          align="left"
        >
          <template slot-scope="scope">
            {{ Number(scope.row.use_insurance).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="use_justment"
          label="现场优惠"
          width="120"
          align="left"
        >
          <template slot-scope="scope">
            {{ Number(scope.row.use_justment).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column
          prop="total_amount"
          label="实际支付"
          width="120"
          align="left"
        >
          <template slot-scope="scope">
            {{ Number(scope.row.total_amount).toFixed(2) }}
          </template>
        </el-table-column>

        <el-table-column label="支付类型" width="120" align="left">
           <template slot-scope="scope">
          
              {{ stateMap[scope.row.payment_type] }}
          </template>
        </el-table-column>

         <el-table-column label="支付状态" width="120" align="left">
           <template slot-scope="scope">
          
              {{ payMap[scope.row.payment_status] }}
          </template>
        </el-table-column>

        <el-table-column
          prop="create_time"
          label="创建时间"
          width="160"
          align="left"
        ></el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    Data: {},
    loading: {
      default: false,
    },
    toPayment: {
      default: () => {},
    },
	  tableData: {
		  default: () => [],
	  },
	  statisticsData: {
      default(){
        return {
          total_price_pay: 0,
          total_price_money: 0,
          total_price_level: 0,
          total_price_insurance: 0,
          total_price_justment: 0,
          total_price: 0,
        } ;
      }
    },
  },
  filters: {
    getNumber(val) {
      return this.getNumber(val);
    },
  },
  data() {
    return {
      typeMap: [
        {
          text: "收入",
          type: "success",
          symbol: "+",
        },
        {
          text: "支出",
          type: "danger",
          symbol: "-",
        },
      ],
      moduleMap: {
        points: "积分",
        money: "消费",
        card: "购物卡",
      },
      stateMap: [
        "账户资金",
        "现金",
        "支付宝",
        "微信",
        "银行卡",
        "购物卡",
        "保险",
        "其它",
      ],
      payMap: [
        "",
        "未付",
        "已付",
        "欠费",
        "挂单结算"
      ],
      statusMap: ["未支付", "已支付"],
      visitMap: ["", "初诊", "复诊", "首诊"],
    };
  },
  methods: {
    sortChange({ column, prop, order }) {
      let sort = {
        order_type: undefined,
        order_field: undefined,
      };

      if (column && order) {
        sort.order_type = order === "ascending" ? "asc" : "desc";
        sort.order_field = prop;
      }

      this.$emit("sort", sort);
    },
    getNumber(value) {
      const toFixedNum = Number(value).toFixed(3);
      return value ? toFixedNum.substring(0, toFixedNum.toString().length - 1) : "0.00";
    },
  },
};
</script>

<style lang="scss" scoped>
.item {
  margin-top: 10px;

  .total{
    display: flex;
    align-items: center;

    .total-amount{
      height: 80px;
      margin: 20px 10px 20px 5px;
      padding: 0 40px 0 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      box-shadow: 0px 1px 4px 1px #d8d8e3;

      .image{
        width: 50px;
        height: 50px;
        border-radius: 50%;
        margin-right: 12px;
      }

      .content{
        font-family: PingFangSC-Medium, PingFang SC;

        .total-name{
          font-size: 12px;
          color: #999;
        }
        .total-number{
          font-size: 18px;
          color: #333;
        }
      }
    }
  }
}
</style>
