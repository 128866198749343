<template>
    <List class="menu" layout="horizontal" itemCls=".menu-item" v-model="selectedMenuItem" :data="items" @itemselect="onMenuItemSelect">
        <template v-slot:item="{
            record,
            selected
        }">
           <MenuItem :key="record.title" :selected="selected">{{record.title}}</MenuItem>
        </template>
    </List>
</template>

<script>

import {
    components
} from '@/helper' ;

export default {

    props:{
        items:Array
    },

    data(){

        return {
            selectedMenuItem:null
        } ;
    },

    mounted(){

        let me = this,
        {
            items,
            $router:router
        } = me;

        router.onReady(() => {

            let {
                path
            } = router.history.current,
            selectedMenuItem;

            if(path === '/'){

               selectedMenuItem = items[0] ;
            
            }else{

                let [
                    ,
                    page
                ] = path.match(/^\/(.+)$/) ;

                for(let item of items){

                    if(item.page === page){

                        selectedMenuItem = item ;

                        break ;
                    }
                }
            }

            me.selectedMenuItem = selectedMenuItem ;

        }) ;
        
    },

    components:components([{
        name:'MenuItem',
        code:'app.menu.Item'
    },
        'List'
    ]),

    methods:{

        onMenuItemSelect(item){

            let me = this,
            {
                page
            } = item;

            me.$emit('menuitemselect' , `/${page}`) ;
        }
    }
}
</script>

<style lang="scss" scoped>

$app-menu-item-split-width:34px;

    .menu{
        cursor: pointer;
		margin: 0 40px;
        .menu-item:not(:last-child){
            margin-right: $app-menu-item-split-width;
        }
		.menu-item{
			font-size: 18px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			// color: #3B3B3B;
			line-height: 25px;
			white-space: nowrap;
		}
    }

</style>