<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Header @addsubmit="onAddSubmit"></Header>
    <Content
      v-if="tabDatas == 1"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
    ></Content>
    <ContentActivities
      v-if="tabDatas == 2"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
    ></ContentActivities>
    <ContentPromotion
      v-if="tabDatas == 5"
      :listRecordsData="listRecordsData"
      @handleselectitem="onHandleSelectItem"
      @handledelselectitem="onHandleDelDelectItem"
    ></ContentPromotion>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Header", "Content", "ContentActivities","ContentPromotion"], "configuration.Marketing.Center"),

    ...components(["Container"]),
  },

  props: {
    listRecordsData: {},
    tabData: 0,
  },

  data() {
    return {
      tabDatas: 0,
    };
  },
  watch: {
    tabData: function (val) {
      this.tabDatas = val;
      // console.log(this.tabDatas);
    },
  },
  methods: {
    isShowAll(val) {
      this.$refs.tree_List.isShowAlls(val);
    },
    onFilterText(val) {
      this.$refs.tree_List.onFilterText(val);
    },
    onAddSubmit() {
      this.$emit("addsubmit");
    },
    onHandleSelectItem(itemdata) {
      this.$emit("handleselectitem", itemdata);
    },
    onHandleDelDelectItem(data) {
      //  console.log("1211112",data);
     this.$emit("handledelselectitem", data);
    },
  },
};
</script>

<style lang="scss" scoped>
// .see-patient-history-list-item {
//   width: 98%;
//   padding: 0 19px;
//   height: calc(100vh - 140px);
//   box-shadow: 0 2px 7px 0 #d8d8d8;
//   margin: 2px auto 10px;
// }
</style>
