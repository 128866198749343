<template>
  <Container class="see-patient-history-list-item hb-layout-vertical">
    <Container class="header hb-layout-horizontal">
      <div class="time hb-layout-flex-1" v-if="patientData !== undefined">
        预约时间:{{ patientData.appointment_date }}/{{ patientData.start_time }}-{{
          patientData.end_time
        }}
      </div>
      <div class="time hb-layout-flex-1" v-if="patientData == undefined">
        预约时间:{{}}
      </div>
      <el-button
        type="success"
        size="mini"
        class="button"
        v-if="patientData.visit_status == 1"
        >首诊</el-button
      >
      <el-button
        type="primary"
        size="mini"
        class="button"
        v-if="patientData.visit_status == 2"
        >初诊</el-button
      >
      <el-button
        type="danger"
        size="mini"
        class="button"
        v-if="patientData.visit_status == 3"
        >复诊</el-button
      >
    </Container>
    <Container class="body">
      <Container class="inner">
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">姓名</div>
          </el-col>
          <el-col :span="14">
            <el-tooltip class="item" effect="dark" :content="patient_information.real_name" placement="top">
              <div class="grid-content bg-purple-light bg-purple-text">
                {{
                  patient_information.real_name === undefined
                    ? ""
                    : patient_information.real_name
                }}
              </div>
            </el-tooltip>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">性别</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light" v-if="patient_information.sex">
              {{ statusSex[patient_information.sex].text }}
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">出生年月</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light">
              {{
                patient_information.birthday === undefined
                  ? "暂无"
                  : patient_information.birthday
              }}
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">联系方式</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light">
              {{
                patient_information.mobile === undefined
                  ? "暂无"
                  : patient_information.mobile
              }}
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">预约医生</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light-select">
              <el-select v-model="doctorValue">
                <el-option
                  v-for="item in doctors"
                  :key="item.doctorId.value"
                  :label="item.name"
                  :value="item.doctorId"
                ></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">预约科室</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light-select">
              <el-cascader
                style="width: 100%"
                v-model="departmentValue"
                :props="departmentParams"
                :options="departmentDatas"
                :show-all-levels="false"
                 @change="departmentChange"
              ></el-cascader>
            </div>
          </el-col>
        </el-row>
        <template v-for="({ name, appointment_setting_id }, index) in settingTrees">
          <el-row type="flex" align="middle" :key="index">
            <el-col :span="10">
              <div class="grid-content bg-purple">
                {{ name }}
              </div>
            </el-col>
            <el-col :span="14">
              <div class="grid-content bg-purple-light-select" v-if="selectTrees[index]">
                <el-cascader
                  :options="settingTrees[index].children"
                  :show-all-levels="false"
                  :props="{
                    label: 'name',
                    value: 'appointment_setting_id',
                    emitPath: false,
                  }"
                  @input="(value) => onItemInput(index, value)"
                  v-model="selectTrees[index].appointment_setting_id"
                  clearable
                ></el-cascader>
              </div>
            </el-col>
          </el-row>
        </template>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">预约日期</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light-select">
              <el-date-picker
                v-model="dataPick"
                align="right"
                type="date"
                format="yyyy-MM-dd"
                value-format="yyyy-MM-dd"
                placeholder="选择日期"
                :picker-options="pickerOptions"
              >
              </el-date-picker>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple grid-textarea">
              <span style="color: #ff0000; font-weight: bold">*</span> 预约时间
            </div>
          </el-col>
          <el-col :span="14">
            <div
              :class="
                doctorDayTime == false
                  ? 'grid-content bg-purple-light-select time-ranges'
                  : 'grid-content bg-purple-light-select'
              "
            >
              <TimeRange
                :start.sync="start"
                :end.sync="end"
                :selectedRanges="filterSelectedRanges"
                :ranges="computedRanges || newranges"
              ></TimeRange>
            </div>
            <div
              :class="
                doctorDayTime
                  ? 'grid-content bg-purple-light-select time-ranges'
                  : 'grid-content bg-purple-light-select'
              "
            >
              没有排班记录！
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">预约状态</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light-select">
              <el-select v-model="status" placeholder="请选择">
                <el-option
                  v-for="item in appointment_status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple">就诊状态</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light-select">
              <el-select v-model="visitStatusValue" placeholder="请选择">
                <el-option
                  v-for="item in visit_status"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>
          </el-col>
        </el-row>
        <el-row type="flex" align="middle">
          <el-col :span="10">
            <div class="grid-content bg-purple bg-textarea">备注</div>
          </el-col>
          <el-col :span="14">
            <div class="grid-content bg-purple-light-select">
              <el-input
                type="textarea"
                :rows="4"
                v-model="remind"
                placeholder="请输入备注"
              ></el-input>
            </div>
          </el-col>
        </el-row>
      </Container>
      <div>
        <el-dropdown
          split-button
          type="primary"
          class="edit-buttom"
          @click="AppoientmentSaveClick"
        >
          确认修改
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item @click.native="changeStatus(2)">取消预约</el-dropdown-item>
            <el-dropdown-item @click.native="changeStatus(3)">客户爽约</el-dropdown-item>
            <el-dropdown-item @click.native="changeDiagnosisStatus()"
              >客户到店</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";
import Container from "../../Container";
import TimeRange from "HB-TIME-RANGE";

export default {
  components: {
    Container,
    TimeRange,
  },
  props: {
    doctorClassesListData: {},
    classesSettingData: {},
    doctordaylistDataTwo: {},
    doctorDayTime: true,
    patientData: {},
    signsdata: {},
    doctors: {},
    departmentDatas: {
      type: Array,
      default: () => [],
    },
    settingTrees: {
      type: Array,
      default: () => [],
    },
  },

  mounted(){

      let me = this ;

      me.ranges = me.classesSettingData ;
  },

  watch: {
    //******************************* 医生当天预约记录 start****************************************** */
     //所有排班记录
    classesSettingData: function (val) {
      this.ranges = val;
    },
    //医生所在部门的班次记录
    doctorClassesListData: function (val) {
      this.classesSettingItems(val); //过滤
    },
      //医生所在部门的班次记录
    doctordaylistDataTwo: function (val) {
      this.selectedRanges = val;
      this.remoteSelectedRanges = val;
    },
    //********************************医生当天预约记录 end***************************************** */
    patientData({
      doctor_id,
      appointment_date,
      start_time,
      end_time,
      symptom,
      duration_time,
      clinical_department_id,
      status,
      setting,
      visit_status,
      patient_information,
      remarks
    }) {
      let me = this;

      me.doctorValue = doctor_id;

      me.dataPick = appointment_date;

      me.start = start_time;

      me.end = end_time;

      me.startTime = start_time;

      me.endTime = end_time;

      me.symptomValue = symptom;

      me.DurationValue = duration_time;

      me.departmentValue = clinical_department_id;

      me.status = status;

      me.visitStatusValue = visit_status;

      me.patient_information = patient_information;

      me.remind = remarks;

      let result = [];


      if (setting !== null) {
        for (let { appointment_setting_id, ...options } of setting) {
          result.push({
            appointment_setting_id: Number(appointment_setting_id),
            ...options,
          });
        }
        me.selectTrees = result;
      } else {
        me.selectTrees = me.selectTreesData;

      }
    },
    signsdata: function (newVal, oldVal) {
      if (newVal == null) {
        this.temperature = ""; //体温
        this.breathing = ""; //呼吸
        this.bloodPressure = ""; //血压
        this.heartRate = ""; //心率
        this.height = ""; //身高
        this.weight = ""; //体温
        this.headCircumference = ""; //头围
        this.BMI = ""; //bmi
        this.allergicHistory = ""; //过敏史 TODP 改为选择
        this.pastHistory = ""; //既往史
        this.familyHistory = ""; //家族史
      } else {
        this.temperature = newVal.temperature; //体温
        this.breathing = newVal.breathe; //呼吸
        this.bloodPressure = newVal.blood_pressure; //血压
        this.heartRate = newVal.heart_rate; //心率
        this.height = newVal.stature; //身高
        this.weight = newVal.weight; //体温
        this.headCircumference = newVal.head_circumference; //头围
        this.BMI = newVal.bmi; //bmi
        this.allergicHistory = newVal.allergies; //过敏史 TODP 改为选择
        this.pastHistory = newVal.past_history; //既往史
        this.familyHistory = newVal.family_history; //家族史
      }
    },
    //预约项-默认值-parent_id=0
    settingTrees: function (val) {
      let result = [];
      let me = this;
      for (let { appointment_setting_id, name, parent_id } of val) {
        if (parent_id == 0) {
          result.push({
            appointment_setting_id: appointment_setting_id,
            name: name,
            parent_id: parent_id,
          });
        }
      }
      me.selectTreesData = result;
    },
  },

  computed: {
    filterSelectedRanges() {
      let { startTime, endTime, selectedRanges } = this;

      return selectedRanges.filter(
        ({ start, end }) => start !== startTime && end !== endTime
      );
    },

    //*****************time range ************************ */
    applyButtonDisabled() {
      let { start, end } = this;

      return !start || !end;
    },
    //*****************time range ************************ */
  },
  data() {
    return {
      //*****************time range ************************ */
      start: "",
      end: "",
      startTime: "",
      endTime: "",
      selectedRanges: [],
      newranges:[],
      ranges: [],
      computedRanges: null,
      range: [
        {
          start: "9:30",
          end: "11:30",
          step: 15,
          name: "早班",
        },
        {
          start: "13:00",
          end: "15:00",
          step: 15,
          name: "中班",
        },
        {
          start: "18:00",
          end: "20:00",
          step: 15,
          name: "晚班",
        },
      ],
      //*****************time range ************************ */
      patient_information: "",
      selectTreesData: [],
      selectTrees: [],
      selected: null,
      selectMonth: "",
      activeName: "first",
      doctorValue: "",

      departmentValue: "",

      DurationValue: "",
      statusSex: [
        {
          text: "保密",
        },
        {
          text: "男",
        },
        {
          text: "女",
        },
      ],
      remind:"",
      appointment_status: [
        {
          value: 0,
          label: "待确认",
        },
        {
          value: 1,
          label: "已确认",
        },
        {
          value: 2,
          label: "已取消",
        },{
          value: 3,
          label: "已爽约",
        },
      ],
      status: 0,
      // statusSex: [
      //   {
      //     value: "0",
      //     text: "保密",
      //   },
      //   {
      //     value: "1",
      //     text: "男",
      //   },
      //   {
      //     value: "2",
      //     text: "女",
      //   },
      // ],
      visit_status: [
        {
          value: 1,
          label: "首诊",
        },
        {
          value: 2,
          label: "初诊",
        },
        {
          value: 3,
          label: "复诊",
        },
      ],
      visitStatusValue: 1,
      timeLineValue: "1",
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now(); //设置当天之前的日期不能选择
        },
        shortcuts: [
          {
            text: "今天",
            onClick(picker) {
              picker.$emit("pick", new Date());
            },
          },
          {
            text: "昨天",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() - 3600 * 1000 * 24);
              picker.$emit("pick", date);
            },
          },
          {
            text: "一周后",
            onClick(picker) {
              const date = new Date();
              date.setTime(date.getTime() + 3600 * 1000 * 24 * 7);
              picker.$emit("pick", date);
            },
          },
        ],
      },
      dataPick: "",

      departmentParams: {
        //可以选中一级内容
        // checkStrictly: true,
        //指定触发方式
        // expandTrigger: "hover",

        label: "name",
        value: "clinical_department_id",
        children: "children",
      },
    };
  },
  methods: {
    //   //过滤
    classesSettingItems(doctorClassList) {

      let classes = (doctorClassList || []).map(({ classes_id }) => classes_id),
        me = this;

      if (classes.length) {
        
        me.newranges = me.ranges;

        me.computedRanges = me.newranges.filter(
          ({ classes_id }) => classes.includes(classes_id)
        );
      }
       if (!doctorClassList.length) {
          me.computedRanges = null;
       }
    },
    //*****************time range ************************ */
    onTableRowDelete({ $index: index }) {
      let { selectedRanges } = this;

      selectedRanges.splice(index, 1);
    },

    onApply() {
      let me = this,
        { start, end, selectedRanges } = me;

      selectedRanges.push({
        start,
        end,
      });

      me.start = null;

      me.end = null;

     // console.log("121212");
    },
    //*****************time range ************************ */
    /**
     * 选择框的方法
     * @param index
     * @param item
     */
    onItemInput(index, item) {
      this.selectTrees[index].appointment_setting_id = item;
    },
    /**
     * 选择框的方法
     * @param index
     * @param item
     */
    onDepartmentItemInput(index, item) {
      this.departmentValue = item;
    },
    arraytotree(arr) {
      var top = [],
        sub = [],
        tempObj = {};

      arr.forEach(function (item) {
        if (item.parent_id === 0) {
          // 顶级分类
          top.push(item);
        } else {
          sub.push(item); // 其他分类
        }
        item.children = []; // 默然添加children属性
        tempObj[item.appointment_setting_id] = item; // 用当前分类的id做key，存储在tempObj中
      });

      sub.forEach(function (item) {
        // 取父级
        var parent = tempObj[item.parent_id] || {
          children: [],
        };
        // 把当前分类加入到父级的children中
        parent.children.push(item);
      });

      return top;
    },
    getTreeData(data) {
      for (var i = 0; i < data.length; i++) {
        if (data[i].children.length < 1) {
          // children若为空数组，则将children设为undefined
          data[i].children = undefined;
        } else {
          // children若不为空数组，则继续 递归调用 本方法
          this.getTreeData(data[i].children);
        }
      }
      return data;
    },
    // getTreeLast(datalist) {
    //   let me = this;
    //   for (var i = 0; i < datalist.length; i++) {
    //       if (datalist[i].children === undefined) {
    //          return datalist[i].appointment_setting_id;
    //       } else {
    //         me.getTreeLast(datalist[i].children);
    //       }
    //   }
    // },
    onDatePickerChange(date) {
      this.$emit("datechange", date);
    },

    onItemSelect(historyItem) {
      this.$emit("historyitemselect", historyItem);
    },

    handleClick(tab, event) {
    },
    departmentChange(value) {
      //this.parentValue= value;
      for (var i = 0; i < value.length; i++) {
        this.departmentValue = value[i];
      }
    },
    getRows() {
      //重组，修改看诊记录保存是所需的结构
      return this.selectTrees.map((item) => {
        return {
          appointment_setting_id: item.appointment_setting_id,
          name: item.name,
          parent_id: item.parent_id,
        };
      });
    },
    AppoientmentSaveClick() {
      let me = this;
      let data = {
        doctorID: this.doctorValue, //预约医生ID
        departmentID: this.departmentValue, //预约科室ID
        // symptom: this.symptomValue, //症状
        // duration: this.DurationValue, //持续时间
        setting: me.getRows(),
        dataTime: this.dataPick, //预约日期
        startTime: this.start, //预约时间：开始时间
        endTime: this.end, //预约时间：结束时间
        status: this.status, //预约状态 预约状态 0=待确认 1=确认 2=取消
        visitStatusValue: this.visitStatusValue, //就诊状态 就诊状态 1=初诊 2=复诊 3=首诊
        medical_record_no: this.patient_information.medical_record_no,
        remarks:me.remind
      };

      //console.log("appoientmentsaveclick",data);
     this.$emit("appoientmentsaveclick", data);
    },
    changeStatus(val) {
      this.$emit("changestatus", val);
    },
    changeDiagnosisStatus() {
      this.$emit("changediagnosisstatus");
    },
  },
};
</script>

<style lang="scss" scoped>
.see-patient-history-list {
  // padding: 0 10px 10px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 0px 2px;

  .see-patient-history-list-item {
    width: 100%;
    
    // border: 1px solid #eeeeee;

    > .header {
      padding: 0 10px;
      height: 50px;
      line-height: 50px;
      background-color: #ededed;
      border-bottom: 1px solid #e1e1e1;

      > .time {
        font-size: 12px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5b5b5b;
      }

      > .button {
        height: 30px;
        margin-top: 12px;
      }
    }

    > .body {
      width: 100%;
      padding: 10px;

      > .inner {
        display: flex;
        flex-direction: column;
        border: 1px solid #979797;

        .el-row {
          width: 100%;
          height: auto;
          line-height: 38px;
          border-bottom: 1px solid #979797;
          font-size: 12px;

          &:last-child {
            border-bottom: none;
          }
        }

        .bg-purple {
          text-align: center;
          background: #ebebeb;
          border-right: 1px solid #979797;
        }
        .grid-textarea {
          height: 100px;
          line-height: 100px;
        }
        .bg-textarea{
          height: 96px;
          line-height: 96px;
        }

        .bg-purple-light {
          padding: 0 15px;
        }

        .bg-purple-textarea{
          height: 50px;
          line-height: 50px;
        }

        .bg-purple-text{
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        .bg-purple-light-select {
          padding: 0;
          font-size: 12px;
        }
      }
    }

    .edit-buttom {
      width: 100%;
      margin-top: 20px;
      white-space: nowrap;
    }
  }
}

.el-dropdown-menu ::v-deep .el-dropdown-menu__item {
  width: 270px;
  text-align: center;
}

.time-ranges {
  display: none;
}
</style>