<template>
    <Container class="patient-detail-info-project">
        <el-card class="box-card">
            <div slot="header" class="clearfix">
                <span>处方开药</span>
            </div>
            <div>
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="中药" name="first">
                        <TraditionalChineseMedicine></TraditionalChineseMedicine>
                    </el-tab-pane>
                    <el-tab-pane label="西药" name="second">
                        <WesternMedicine></WesternMedicine>
                    </el-tab-pane>
                    <el-tab-pane label="输液" name="third">
                        <Infusion></Infusion>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-card>
    </Container>
</template>

<script>
import {
  components
} from '@/helper';

export default {
    components: {
		...components([
            "TraditionalChineseMedicine",
			"WesternMedicine",
            "Infusion"
        ], "implement.Center"),
        ...components(["Container"]),
	},
  
    data(){
        return {
        activeName: 'first'
        }
    },
    methods: {
      handleClick(tab, event) {
       // console.log(tab, event);
      }
    }
}
</script>

<style  lang="scss"  scoped>
    .clearfix{
        span{
            font-size: 16px;
        }
    }
</style>
