<template>
  <Container class="appoientment-detail hb-layout-vertical">
    <div class="screen">
      <el-button-group>
        <el-button
          :type="topHeaderChange == 6 ? topTypeChange : topType"
          @click="onTypeButtonClick(6)"
          >全部</el-button
        >
        <el-button
          :type="topHeaderChange == 0 ? topTypeChange : topType"
          @click="onTypeButtonClick(0)"
          >待确认</el-button
        >
        <el-button
          :type="topHeaderChange == 1 ? topTypeChange : topType"
          @click="onTypeButtonClick(1)"
          >已确认</el-button
        >
         <el-button
          :type="topHeaderChange == 7 ? topTypeChange : topType"
          @click="onTypeButtonClick(7)"
          >到店</el-button
        >
        <el-button
          :type="topHeaderChange == 2 ? topTypeChange : topType"
          @click="onTypeButtonClick(2)"
          >待诊</el-button
        >
        <el-button
          :type="topHeaderChange == 3 ? topTypeChange : topType"
          @click="onTypeButtonClick(3)"
          >已诊</el-button
        >
        <el-button
          :type="topHeaderChange == 4 ? topTypeChange : topType"
          @click="onTypeButtonClick(4)"
          >取消</el-button
        >
         <el-button
          :type="topHeaderChange == 5 ? topTypeChange : topType"
          @click="onTypeButtonClick(5)"
          >爽约</el-button
        >
      </el-button-group>
      <!-- <div style="margin-left: 10px">
        <el-cascader
          style="width: 100%"
          placeholder="请选择科室"
          v-model="departmentValue"
          :props="departmentParams"
          :options="departmentDatas"
          :show-all-levels="false"
          @change="departmentChange"
        ></el-cascader>
      </div> -->
      <div class="search" style="margin-left: 10px">
        <el-input
          v-model="search"
          placeholder="搜索患者"
          class="input-with-select search"
        >
          <el-button slot="append" icon="el-icon-search"></el-button>
        </el-input>
      </div>
    </div>
    <div>
      <el-table
        ref="list"
        :data="tableData.filter(filter)"
        highlight-current-row
        @current-change="appointmentDetailChange"
        :default-sort = "{prop: 'startTime', order: 'descending'}"
        stripe
        height="calc(100vh - 250px)"
        style="width: 100%"
      >
        <el-table-column fixed type="index" width="45"> </el-table-column>
        <el-table-column fixed prop="name" label="姓名" width="200" align="left">
        </el-table-column>
        <el-table-column label="性别" width="80" align="left">
          <template slot-scope="scope">
            {{ sexMap[scope.row.sex] }}
          </template>
        </el-table-column>
        <el-table-column prop="age" label="年龄" width="80" align="left">
           <template slot-scope="scope">
              <span v-if="scope.row.age=='null'">保密</span>
              <span v-else>{{scope.row.age}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="phone" label="联系电话" min-width="130" align="left">
        </el-table-column>
        <el-table-column prop="status" label="预约状态" min-width="100" align="left">
          <template slot-scope="scope">
            <el-tag :type="typeMap[scope.row.status].type" effect="plain" size="mini">
              {{ typeMap[scope.row.status].text }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="到店状态" min-width="100" align="left">
          <template slot-scope="scope">
            <el-tag :type="stateMap[scope.row.state].type" effect="plain" size="mini">
              {{ stateMap[scope.row.state].text }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="就诊类型" min-width="100" align="left">
          <template slot-scope="scope">
            <el-tag
              :type="visitStatusMap[scope.row.visitStatus].type"
              effect="plain"
              size="mini"
            >
              {{ visitStatusMap[scope.row.visitStatus].text }}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="科室" min-width="150" align="left">
          <template slot-scope="scope">
            {{ scope.row.departmentName }}
          </template>
        </el-table-column>
        <el-table-column prop="doctorName" label="医生" min-width="100" align="left">
        </el-table-column>
        <el-table-column prop="startTime" label="预约时间" width="150" align="left">
        </el-table-column>
        <el-table-column prop="register_time" label="到店时间" width="150" align="left">
        </el-table-column>
        <el-table-column
          prop="state"
          fixed="right"
          label="到店"
          width="65"
          align="left"
        >
          <template slot-scope="scope">
            <el-switch
              v-model="scope.row.state"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="
                handChange(
                  scope.row.appointment_record_no,
                  scope.row.state,
                  scope.row.medical_record_no,
                  scope.row.doctor_id
                )
              "
            >
            </el-switch>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </Container>
</template>

<script>
import { components, layouts } from "@/helper";

export default {
  components: {
    ...components(["List", "Container", "appointment.list.patient.Item"]),
  },
  props: {
    departmentDatas: {
      type: Array,
      default: () => [],
    },
    tableData: {
      type: Array,
      default: () => [],
    },
    doctors: {},
  },
  mounted() {
    this.userInfo = JSON.parse(sessionStorage.getItem("HB-LOGIN-USER-INFO"));
  },
  data() {
    return {
      topHeaderChange: 6,
      topType: "primary",
      topTypeChange: "success",
      userinfo: "",
      diagnosisValue: 0,
      departmentOptions: [
        {
          value: 1,
          label: "内科",
        },
        {
          value: 2,
          label: "外科",
        },
        {
          value: 3,
          label: "神经内科",
        },
      ],
      sexMap: ["保密", "男", "女"],
      typeMap: [
        {
          text: "待确认",
          type: "warning",
        },
        {
          text: "确认",
          type: "success",
        },
        {
          text: "取消预约",
          type: "info",
        },
        {
          text: "客户爽约",
          type: "danger",
        },
      ],
      //看诊状态 1=到店 2=待诊 3=已诊 4=检测中
      stateMap: [
        {
          text: "待到店",
          type: "",
        },
        {
          text: "已到店",
          type: "success",
        },
        {
          text: "待珍",
          type: "primary",
        },
        {
          text: "已珍",
          type: "info",
        },
      ],
      //就诊状态 1=初诊 2=复诊 3=首诊
      visitStatusMap: [
        {
          text: "待确认",
          type: "warning",
        },
        {
          text: "首诊",
          type: "success",
        },
        {
          text: "初诊",
          type: "primary",
        },
        {
          text: "复诊",
          type: "danger",
        },
      ],
      departmentValue: "",
      search: "",
      type: undefined,
      diagnosis_status: undefined,
      see_status: undefined,
      statusSuccess: undefined,
      departmentParams: {
        //可以选中一级内容
        // checkStrictly: true,
        //指定触发方式
        // expandTrigger: "hover",

        label: "name",
        value: "clinical_department_id",
        children: "children",
      },
    };
  },
  watch: {
    search: function (val, oldVal) {
      this.tableData = this.otableData.filter((item) => ~item.name.indexOf(val));
    },

    tableData(tableData) {
      let me = this,
        { list } = me.$refs;

      me.$nextTick(() => {
        let { data } = list;

        if (data.length) {
          list.setCurrentRow(data[0]);
        } else {
          // data = this.patientRecord;
          // list.setCurrentRow(data[0]);
          this.$emit("emtpychange");
        }
      });
    },
  },
  methods: {
    departmentMap(val) {
      let me = this;
      let obj = {};
      obj = me.departmentOptions.find((item) => {
        //遍历list的数据
        return item.value === val; //筛选出匹配数据
      });
      return obj.label; //获取list里面的name
      //return obj.lable;
    },
    doctorMap(val) {
      let me = this;
      let obj = {};
      obj = me.doctors.find((item) => {
        //遍历list的数据
        return item.doctorId === val; //筛选出匹配数据
      });
      return obj.name;
    },
    filter(data) {
      let result = false,
        { search, status, diagnosis_status, see_status,statusSuccess } = this;

      if (search) {
        result = data.name.toLowerCase().includes(search.toLowerCase());
      } else {
        result = true;
      }

      if (status !== undefined) {
        result = data.status === status;
      }

      if (diagnosis_status !== undefined) {
        result = data.diagnosis_status === diagnosis_status;
      }

      if (see_status !== undefined) {
        result = data.see_status === see_status;
      }

      if (statusSuccess !== undefined) {
        result = data.status > 1;
      }

      return result;
    },
    handChange(appointNo, val, medicalNo, doctor_id) {
      let data = {
        appointment_record_no: appointNo,
        diagnosis_status: val,
        create_id: this.userInfo.client_id,
        medical_record_no: medicalNo,
        create_user: this.userInfo.name,
        doctor_id: doctor_id,
      };
      //this.diagnosisValue =
      this.$emit("handstatechange", data);
    },
    departmentChange(value) {
      //   let obj = {};
      //   obj = this.department.find((item) => {
      //     return item.value === value;
      //   });
      //   this.info.departmentName = obj.label;
    },
    handleClick() {},
    onTypeButtonClick(type) {
      this.topHeaderChange = type;
      if (type == 0) {
        this.status = type;
        this.diagnosis_status = undefined;
        this.see_status = undefined;
      } else if (type == 1) {
        this.status = 1;
        this.see_status = undefined;
        this.diagnosis_status = undefined;
      } else if (type == 2) {
        this.status = undefined;
        this.diagnosis_status = undefined;
        this.see_status = 1;
      } else if (type == 3) {
        this.status = undefined;
        this.diagnosis_status = undefined;
        this.see_status = 2;
      } else if (type == 4) {
        this.status = 2;
        this.diagnosis_status = undefined;
        this.see_status = undefined;
      } else if (type == 5) {
        this.status = 3;
        this.diagnosis_status = undefined;
        this.see_status = undefined;
      } else if (type == 6) {
        this.status = undefined;
        this.diagnosis_status = undefined;
        this.see_status = undefined;
       } else if (type == 7) {
        this.status = undefined;
        this.diagnosis_status = 1;
        this.see_status = undefined;
      }
      this.statusSuccess = undefined;

      let me = this,
        { list } = me.$refs;

      me.$nextTick(() => {
        let { data } = list;

        if (data.length) {
          list.setCurrentRow(data[0]);
        } else {
          this.$emit("emtpychange");
        }
      });
    },
    onStatusButtonClick(type) {
      this.topHeaderChange = 4;
      this.statusSuccess = type;
      this.status = 0;
      let me = this,
        { list } = me.$refs;

      me.$nextTick(() => {
        let { data } = list;

        if (data.length) {
          list.setCurrentRow(data[0]);
        } else {
          this.$emit("emtpychange");
        }
      });
    },

    onDoctorButtonClick(plans) {
      this.plans = plans;
    },
    onPlanClick() {
      this.$emit("planclick");
    },
    onItemSelect(doctorId) {
      this.$emit("planclick", doctorId);
    },
    appointmentDetailChange(val) {
      this.$emit("appointmentdetailchange", val);
    },
    filterTag(value, row) {
      return row.type === value;
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-detail {
  width: 99%;
  height: 100%;
  margin: 2px auto 15px;
  box-shadow: 0 2px 7px 0 #d8d8d8;
  padding: 15px 15px 35px;
  overflow: auto;

  .screen {
    margin-bottom: 10px;
    display: flex;
    align-items: center;
  }

  .el-row {
    border-top: 1px solid #d8d8d8;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 1px solid #d8d8d8;
    }
  }

  .grid-content {
    height: 60px;
    line-height: 60px;
    text-align: center;
  }
}

.el-button-group {
  display: flex;
  vertical-align: middle;
  flex-wrap: nowrap;
}
</style>
