<template>
  <Container class="details hb-layout-horizontal">
    <Container class="left hb-layout-vertical">
      <LeftMain @getleftchangetwo="onGetLeftChangeTwo"></LeftMain>
    </Container>
    <Container class="centent hb-layout-flex-1 hb-layout-vertical">
      <CenterMain
        :listRecordsData="listRecordsData"
        :tabData="tabData"
        @handleselectitem="onHandleSelectItem"
        @handledelselectitem="onHandleDelDelectItem"
        @addsubmit="onAddSubmit"
      ></CenterMain>
    </Container>
    <Container class="right hb-layout-vertical">
      <RightMain
        ref="rightSelectData"
        :tabData="tabData"
        @submititemdata="onSubmitItemData"
      ></RightMain>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(
      ["Left.Main", "Center.Main", "Center.Main", "Right.Main"],
      "configuration.Marketing"
    ),
    ...components(["Container"]),
  },

  props: {
    listRecordsData: {},
    rolesData:{},
    departmentData:{},
    tabData: 0,
  },

  data() {
    return {};
  },

  methods: {
    onGetLeftChangeTwo(val) {
      this.$emit("getleftchangeTwo", val);
    },
    onHandleSelectItem(itemdata) {
      this.$refs.rightSelectData.handleSelectItems(itemdata);
    },
    onHandleDelDelectItem(itemdata) {
      this.$emit("handledelitem", itemdata);
    },
    onSubmitItemData(itemdata) {
       //console.log("122",itemdata);
      this.$emit("submititemdata", itemdata);
    },
    onAddSubmit() {
      this.$refs.rightSelectData.onAddSubmit();
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .left {
    width: 300px;
  }
  .right {
    width: 300px;
  }
}
</style>
