<template>
	<Container class="see-detail-vital-signs">
		<el-descriptions class="margin-top" :column="4" :size="size" border>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/tw.png"></el-image>
						<div>体温 T</div>
					</div>
				</template>
				{{ vital.temperature }}℃
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/xy.png"></el-image>
						<div>血压 BP</div>
					</div>
				</template>
				{{ vital.blood_pressure }}ph
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/hx.png"></el-image>
						<div>呼吸 R</div>
					</div>
				</template>
				{{ vital.breathe }}次/分钟
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/xl.png"></el-image>
						<div>心率 HR</div>
					</div>
				</template>
				{{ vital.heart_rate }}次/分钟
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/sg.png"></el-image>
						<div>身高 Ht</div>
					</div>
				</template>
				{{ vital.stature }}cm
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/tz.png"></el-image>
						<div>体重 Wt</div>
					</div>
				</template>
				{{ vital.weight }}kg
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/gms.png"></el-image>
						<div>过敏史 AY</div>
					</div>
				</template>
				{{ vital.allergies }}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/jws.png"></el-image>
						<div>既往史 Pmh</div>
					</div>
				</template>
				{{ vital.past_history }}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/twei.png"></el-image>
						<div>头围</div>
					</div>
				</template>
				{{ vital.head_circumference }}cm
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/bmi.png"></el-image>
						<div>BMI</div>
					</div>
				</template>
				{{ vital.bmi }}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/jzs.png"></el-image>
						<div>家族史</div>
					</div>
				</template>
				{{ vital.family_history }}
			</el-descriptions-item>
			<el-descriptions-item>
				<template slot="label">
					<div class="item">
						<el-image class="img" src="../../image/qt.png"></el-image>
						<div>其他</div>
					</div>
				</template>
				{{ vital.other }}
			</el-descriptions-item>
		</el-descriptions>
	</Container>

</template>

<script>
	import {
		components
	} from '@/helper';

	export default {
		components: components([
			'Container'
		]),
    props:{
      vital:{
        type: Object,
        default: ()=>({})
      },
	  size:""
    },
		data() {
			return {

			}

		}
	};
</script>

<style lang="scss" scoped>

	.see-detail-vital-signs {
		.el-row {
			width: 100%;
			border-top: 1px solid #979797;
			border-left: 1px solid #979797;

			&:last-child {
				margin-bottom: 0;
				border-bottom: 1px solid #979797;
			}
		}
		.bg-purple {
			background: #EBEBEB;
		}

		.bg-purple-light {
			// background: #fff;
			border-left: 1px solid #979797;
			border-right: 1px solid #979797;
		}

		.grid-content {
			height: 45px;
			line-height: 45px;
			text-align: center;
			font-size: 13px;
		}
		.row-bg {
			padding: 10px 0;
		}
	}
	.item{
		display: flex;
		align-items: center;
		margin-left: -2px;

		.img{
			width: 25px;
			height: 25px;
		}
		div{
			height: 23px;
			line-height: 25px;
		}
	}
</style>
