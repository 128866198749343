/**
 * 获取统计数据 API
 */

import axios from 'axios' ;
import qs from 'qs' ;
import serverConfig from "@/../config/index";

let url = serverConfig.BASE_API + '/v2/doctor/method/get.doctor.statistical/';

 export default (data) => {
 

    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data);
        // .then(({data}) => data);
}
