/**
 * 获取看诊记录简要信息（历史记录） API
 */

import axios from 'axios' ;
import qs from 'qs' ;
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/prescription/method/get.history.prescription.list';

export default (data) => {

    //items 值为时 后端直接没有返回的， 这里写了个三元判断
    return axios.post(url, qs.stringify({
        ...data
    })).then(({data}) => data)
        .then(({data}) => data)
        .then(({items}) => !items ? [] : items.map(({
                                                        prescription_id,
                                                        dosage,
                                                        pharmacy_id,
                                                        remark,
                                                        get_pharmacy,
                                                        create_time
                                                    }) =>({
            prescription_id, //记录ID
            dosage, //用量
            pharmacy_id,    //药品ID
            remark, //备注
            create_time,    //创建时间
            pharmacy_name: get_pharmacy.name //药品名称
        })));
}

