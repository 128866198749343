<template>
  <Container class="centent hb-layout-flex-1 hb-layout-vertical">

    <Header
      @submit="handleSubmit"
      @submitreset="onSubmitReset"
      ref="header"
      :loading="loading"
      :warehousParam="warehousParam"
      :pharmacyCategoryRecords="pharmacyCategoryRecords"
      @getpharmacycategoryredords="onGetPharmacyCategoryRedords"
      @getpharmcyrecord="onGetPharmcyRecord"
      @savewarehousing="onSaveWarehousing"
      :pharmacyrecordsstockin="pharmacyrecordsstockin"
      :attributeDatas="attributeDatas.items"
      :smallunitDatas="smallunitDatas"
      :warehousDatas="warehousDatas.items"
    ></Header>

    <List :tableData="Data.items" :loading="loading"></List>

    <BasicFooter
      :loading="loading"
      :current="Param.current"
      :size="Param.page_size"
      :total="Data.total_result"
      @change="handlePaginationChange"
    ></BasicFooter>

  </Container>
</template>

<script>
import { components, blocks } from "@/helper";

export default {
  components: {
    ...components(["Header", "List"], "pharmacy.Warehousing"),
    ...components(["Container"]),
    ...blocks(["BasicFooter"], "Footer"),
  },

  props: {
    loading: {
      default: false,
    },
    Data: {},
    Param: {},
    attributeDatas: {},
    warehousParam: {},
    pharmacyCategoryRecords: {},
    pharmacyrecordsstockin: {},
    stockinsData: {},
    smallunitDatas:{},
    warehousDatas:{}
  },
 
  data() {
    return {};
  },

  methods: {
    onGetPharmacyCategoryRedords() {
      this.$emit("getpharmacycategoryredords");
    },
    onGetPharmcyRecord(val) {
      this.$emit("getpharmcyrecord", val);
    },
    onSaveWarehousing(dataOne, dataTwo) {
      this.$emit("savewarehousing", dataOne, dataTwo);
    },
    // 确定查询
    handleSubmit(form, isRestore = false) {
      this.$emit("changesubmit", form, isRestore);
    },
    onSubmitReset(form) {
      let me = this;
      me.$emit("submitreset",form);
    },
    // 分页变化改动
    handlePaginationChange(val) {
      this.$emit("changepagination", val);
    },
    pageChangeSubmit() {
      this.$nextTick(() => {
        this.$refs.header.handleFormSubmit();
      });
    },
    changeDrawer(v) {
      let me = this;
      me.$refs.header.changeDrawer(v);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .right {
    width: 300px;
  }
}
</style>
