<!-- 治疗项目 -->
<template>
	<Container class="appointment-main-operation hb-layout-horizontal">
		<div style="width: 100%" v-loading="loading">
			<el-tree
				v-if="hackReset"
				:props="defaultProps"
				:data="treeData"
				show-checkbox
				node-key="id"
				:filter-node-method="filterNode"
				:default-expand-all="isShowAll"
				:default-expanded-keys="expanded"
				:expand-on-click-node="false"
				ref="treeList"
			>
				<div class="custom-tree-node" slot-scope="{ node, data }">
					<div class="node-title text-overflow">{{ node.label }}/{{ data.english_name }}</div>
					<div class="node-right">
						<div v-if="data.parent_id !== 0" class="node-item text-overflow">{{ data.price }}</div>
						<div v-if="data.parent_id !== 0" class="node-item text-overflow">{{ data.unit }}</div>
						<div class="node-item right">
							<el-button type="text" size="mini" @click="() => append(data)">增加</el-button>
							<el-button type="text" size="mini" @click="() => remove(node, data)">删除</el-button>
							<el-button type="text" size="mini" @click="() => edit(node, data)">编辑</el-button>
						</div>
					</div>
				</div>
			</el-tree>
		</div>
	</Container>
</template>

<script>
import {components} from "@/helper";

export default {
	components: {
		...components(["Container"]),
	},
	
	props: {
		treeDatas: {},
	},
	
	data() {
		return {
			hackReset: true,
			loading: false,
			isShowAll: true,
			treeData: [],
			defaultProps: {
				label: "name",
			},
			expanded: [],
			count: 1,
		};
	},
	watch: {
		treeDatas: function (val) {
			//初始化数据
			if (val.length > 0) {
				this.treeData = this.arrayToTree(val);
			}
		},
	},
	methods: {
		handleCheckChange(data, checked, indeterminate) {
			//
		},
		handleNodeClick(data) {
			//
		},
		/**
		 * 展开或收起节点
		 * @param val
		 */
		isShowAlls(val) {
			this.filterText = "";
			this.expanded = [];
			this.hackReset = false;
			
			this.$nextTick(() => {
				this.isShowAll = val;
				this.hackReset = true;
			});
		},
		onFilterText(val) {
			this.$refs.treeList.filter(val);
		},
		// 批量删除规则
		removeList() {
			this.remove(this.$refs.treeList.getCheckedKeys(true));
		},
		// 过滤节点
		filterNode(value, data) {
			if (!value) {
				return true;
			}
			return data.name.indexOf(value) !== -1;
		},
		//数组转化为树
		arrayToTree(arr) {
			var top = [],
				sub = [],
				tempObj = {};
			
			arr.forEach(function (item) {
				if (item.parent_id === 0) {
					// 顶级分类
					top.push(item);
				} else {
					sub.push(item); // 其他分类
				}
				item.children = []; // 默然添加children属性
				tempObj[item.treatment_template_id] = item; // 用当前分类的id做key，存储在tempObj中
			});
			
			sub.forEach(function (item) {
				// 取父级
				var parent = tempObj[item.parent_id] || {children: []};
				// 把当前分类加入到父级的children中
				parent.children.push(item);
			});
			
			return top;
		},
		loadNode(node, resolve) {
			if (node.level === 0) {
				return resolve([{name: "region1"}, {name: "region2"}]);
			}
			if (node.level > 3) return resolve([]);
			
			var hasChild;
			if (node.data.name === "region1") {
				hasChild = true;
			} else if (node.data.name === "region2") {
				hasChild = false;
			} else {
				hasChild = Math.random() > 0.5;
			}
			
			setTimeout(() => {
				var data;
				if (hasChild) {
					data = [
						{
							name: "zone" + this.count++,
						},
						{
							name: "zone" + this.count++,
						},
					];
				} else {
					data = [];
				}
				
				resolve(data);
			}, 500);
		},
		
		append(data) {
			this.$emit("appendselectitem", data);
		},
		
		remove(node, data) {
			
			this.$emit("delselectitem", data);
			const parent = node.parent;
			const children = parent.data.children || parent.data;
			const index = children.findIndex(d => d.id === data.id);
			children.splice(index, 1);
		},
		edit(node, data) {
			this.$emit("editselectitem", data, this.treeData);
		},
	},
};
</script>

<style lang="scss" scoped>
.appointment-main-operation {
	width: 99%;
	padding: 19px;
	height: calc(100vh - 210px);
	box-shadow: 0 2px 7px 0 #d8d8d8;
	margin: 2px auto 10px;
	overflow: auto;
	
	.list {
		width: 100%;
		height: 80px;
		line-height: 80px;
		padding: 0 19px;
		align-items: center;
		border-bottom: 1px solid #e1e1e1;
		font-size: 12px;
		text-align: center;
		
		&.selected {
			box-shadow: 0 2px 7px 0 #d8d8d8;
			margin: 2px 0;
			background: #92c2f957;
		}
		
		&:hover {
			background: #92c2f921;
			color: #2063b695;
		}
	}
}

.custom-tree-node {
	flex: 1;
	display: flex;
	align-items: center;
	justify-content: space-between;
	font-size: 14px;
	padding-right: 8px;
	
	.node-title {
		width: 50%;
	}
	
	.node-right {
		width: 50%;
		display: flex;
		align-items: center;
		justify-content: flex-end;
		
		.right {
			width: 34%;
			display: flex;
			justify-content: flex-end;
		}
		
		.node-item {
			width: 33%;
		}
	}
	
	.text-overflow {
		text-overflow: ellipsis;
		white-space: nowrap;
		overflow: hidden;
	}
}
</style>
