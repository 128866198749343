<template>
    <Container class="appoientment-main-operation hb-layout-horizontal">
        <div style="width: 100%">
          <div class="list selected">
                <div class="list-item" @click="getLeftChange(6)">预约设置</div>
            </div>
            <div class="list">
                <div class="list-item" @click="getLeftChange(1)">检验检查</div>
            </div>
            <div class="list">
                <div class="list-item"  @click="getLeftChange(2)">治疗项目</div>
            </div>
            <div class="list">
                <div class="list-item"  @click="getLeftChange(3)">诊断管理</div>
            </div>
             <div class="list">
                <div class="list-item"  @click="getLeftChange(4)">科室管理</div>
            </div>
             <div class="list">
                <div class="list-item"  @click="getLeftChange(5)">主诉管理</div>
            </div>
        </div>
    </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  data() {
    return {
      
    };
  },
  methods:{
    getLeftChange(val){
      this.$emit("getleftchange",val);
    }
  }
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
//   padding: 0 19px;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 2px 10px 0px;

    .list{
        width: 100%;
        height: 80px;
        line-height: 80px;
        padding: 0 19px;
        align-items: center;
        border-bottom: 1px solid #e1e1e1;
        font-size: 12px;
        text-align: center;

         &.selected:nth-child(1) {
            margin: 0 0 2px;
        }

        &.selected {
            box-shadow: 0 2px 7px 0 #d8d8d8;
            margin: 2px 0;
            background: #92c2f957;
        }
        &:hover {
            background: #92c2f921;
            color: #2063b695;
        }
    }
}

    
</style>
