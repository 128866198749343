<template>
    <Container class="see-patient-list hb-layout-vertical">
        <el-row>
            <el-col :span="8">
                <div class="grid-content purple height">项目 Item</div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content purple height">数量 Number</div>
            </el-col>
            <el-col :span="5">
                <div class="grid-content purple height">单位 Price Unit</div>
            </el-col>
            <el-col :span="4">
                <div class="grid-content purple height">备注 Description</div>
            </el-col>
            <el-col :span="4">
                <div class="grid-content purple">
                    <div>操作 Operation</div>
                </div>
            </el-col>
        </el-row>
        <el-row v-for="(item, index) in Data" :key="index">
            <el-col :span="8">
                <div class="grid-content bg-purple height">{{ item.name }} {{ item.englishName }}</div>
            </el-col>
            <el-col :span="3">
                <div class="grid-content bg-purple">{{ item.count }}</div>
            </el-col>
            <el-col :span="5">
                <div class="grid-content bg-purple height">{{ item.unit }} <span v-html="'&#12288;'"></span></div>
            </el-col>
            <el-col :span="4">
                <div class="grid-content bg-purple height">{{ item.remark }} <span v-html="'&#12288;'"></span></div>
            </el-col>
            <el-col :span="4" fixed="right" label="操作" width="100" align="center" style="line-height: 34px">
                <el-button v-if="item.status===2||item.status===1" size="mini" type="primary" @click="handleRow(type,item.e_ID,show)">执行
                </el-button>
                <el-button v-if="item.status===3" size="mini" type="success">已执行</el-button>
                <el-button v-if="item.status===3 && type==='pharmacy'" size="mini" type="primary"
                           @click="printLabel(2, item, record)">打印标签
                </el-button>
            </el-col>
        </el-row>
    </Container>
</template>

<script>
import {components} from "@/helper";
import {needCLodop, loadCLodop, getLodop} from "../../utils/LodopFuncs";

export default {
    components: components(["Container"]),
    props: {
        Data: {},
        type: "",
        record: {},
        show:false
    },

    data() {
        return {
            sexMap: ['保密', '男', '女'],
            printLabelInfo: {
                // 基础信息
                logoSrc: 'http://192.168.172.123:8080/image/rufu-label-black.png',  // logo 地址为绝对地址打包前需要替换
                qrCode: 'https://mp.weixin.qq.com/a/~ziVV6ad-3KI8kE6dApXAdw~~',
                date: '2021-08-24 16:00',
                tel: 'Tel 电话：0512-6580 8308',
                address: 'Address 地址：苏州工业园区旺墩路 新罗酒店B栋一楼',

                // 患者信息
                chartNo: '21080350872',
                name: 'Mark Elliot Zuckerberg',
                age: 18,
                gender: 'M',

                // 药品信息
                drugName: 'NIFURATEL AND NYSTATIN VAGINAL OINTMENT 0.5G*20万IU*1BOX',
                usage: '1 tablet once a day / 一天一次一片',
                doctorOrder: '挤出全部药物后，抽出注射器，连用6天。建议使用1~2个疗程或遵医嘱。',
            }
        };
    },
    watch: {

    },

    methods: {
        handleRow(type, val,show) {
            this.$emit("executechange", type, val,show);
           // console.log("121",type,val);
        },

        /**
         * 打印标签
         * @param printType 打印方式，直接打印：printType === 1；预览打印 printType !== 1;
         * @param item      药品信息
         * @param record    档案信息
         */
        printLabel(printType, item, record) {

            // 档案信息
            this.printLabelInfo.chartNo = record.medical_record_no;
            this.printLabelInfo.name = record.real_name;
            this.printLabelInfo.age = record.age;
            this.printLabelInfo.gender = this.sexMap[record.sex];

            // 药品信息
            this.printLabelInfo.drugName = item.name;
            this.printLabelInfo.usage = item.unit;
            this.printLabelInfo.doctorOrder = item.remark.join(',');

            // 组件加载
            if (needCLodop()) {
                loadCLodop();
            }
            // 动态参数
            let printLabel = this.printLabelInfo;

            // 打印模板
            this.createLodopLabel(printType, printLabel);
        },
        /**
         * 打印模板
         * @param printType     打印类型
         * @param printLabel    动态参数
         */
        createLodopLabel(printType, printLabel) {
            let LODOP = getLodop();

            LODOP.PRINT_INITA(0, 0, 265, 340, "");      // 初始化
            LODOP.SET_PRINT_PAGESIZE(1, 700, 900, "");     // 设置打印纸（指定尺寸）
            // LODOP.SET_PRINT_PAGESIZE(2, 0, 0,"7090");   // 备用：设置打印纸（指定尺寸在系统中的别名）
            LODOP.SET_PRINT_MODE("PRINT_NOCOLLATE", 1); // 设置打印模式

            // LOGO
            LODOP.ADD_PRINT_IMAGE(10, 200, 55, 176, printLabel.logoSrc);

            // 二维码：小程序
            LODOP.SET_PRINT_STYLEA(0, "Stretch", 2);
            LODOP.ADD_PRINT_BARCODE(255, 190, 80, 80, "QRCode", printLabel.qrCode);

            // 病例号
            LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);
            LODOP.ADD_PRINT_TEXT(10, 185, 190, 20, "Chart No. 病历号：" + printLabel.chartNo);

            // 打印日期
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_TEXT(180, 185, 175, 20, "Date 日期：" + printLabel.date);


            // 姓名
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_LINE(280, 170, 10, 170, 0, 1);  // 分割线 1
            LODOP.ADD_PRINT_TEXT(10, 165, 300, 20, "Name 姓名：" + printLabel.name);

            // 年龄
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_TEXT(10, 150, 115, 20, "Age 年龄：" + printLabel.age);

            // 性别
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_TEXT(125, 150, 115, 20, "Gender 性别：" + printLabel.gender);


            // 药名
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_LINE(280, 135, 10, 135, 0, 1);  // 分割线 2
            LODOP.ADD_PRINT_TEXT(10, 130, 318, 40, "Drug Name 药名：" + printLabel.drugName);

            // 药品用法
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_TEXT(10, 100, 318, 40, "Usage 用法：" + printLabel.usage);

            // 医嘱
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_TEXT(10, 80, 320, 40, "Doctor's Order 医嘱：" + printLabel.doctorOrder);

            // 电话
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 8);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_LINE(280, 40, 10, 40, 0, 1);    // 分割线 3
            LODOP.ADD_PRINT_TEXT(10, 30, 120, 20, printLabel.tel);

            // 地址
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 6);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_TEXT(130, 30, 210, 20, printLabel.address);

            // 一维码：病例号
            LODOP.SET_PRINT_STYLEA(0, "FontSize", 6);
            LODOP.SET_PRINT_STYLEA(0, "Angle", 270);
            LODOP.ADD_PRINT_BARCODE(300, 40, 160, 25, "Code93", printLabel.chartNo);
            LODOP.SET_PRINT_STYLEA(0, "ShowBarText", 0);

            if (printType === 1) {
                LODOP.PRINT();      // 直接打印
            } else {
                LODOP.PREVIEW();    // 预览打印
            }

        },
    },
};
</script>

<style lang="scss" scoped>
$see-patient-list-header-height: 50px;

$see-patient-list-padding: 0 10px 0 10px;

.see-patient-list {
    > .header {
        width: 100%;
        height: 50px;
        line-height: 50px;
        padding: 0 16px 0 18px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #5b5b5b;

        > .tody {
            padding-right: 15px;
        }
    }
}

.box-card {
  margin-bottom: 15px;
  box-shadow: 0 2px 7px 0 #d8d8d8;

  .el-row {
    // margin-bottom: 20px;
    height: 40px;
    line-height: 40px;
    border-top: 1px solid #e1e1e1;
    border-left: 1px solid #e1e1e1;
    border-right: 1px solid #e1e1e1;

    &:last-child {
      margin-bottom: 0;
      border-bottom: 1px solid #e1e1e1;
    }
    &:first-child {
      background: #ebebeb;
    }
  }
  .bg-purple {
    text-align: center;
    border-right: 1px solid #e1e1e1;
  }
  .height{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .purple {
    text-align: center;
    font-size: 14px;
    font-family: PingFangSC-Medium, PingFang SC;
  }
  .bg-purple {
    padding: 0 5px;
    font-size: 14px;
    color: #5b5b5b;
    font-family: PingFangSC-Medium, PingFang SC;
  }

  .display {
    display: flex;
    justify-content: space-between;
    white-space: nowrap;
    overflow: hidden;

    .display-item {
      display: flex;
      // align-items: center;

      .display-item-name {
        padding-right: 100px;
      }
    }
  }
}
</style>
