<template>
  <el-dialog
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :width="size"
  >
    <Container>
      <div class="grade">
        <div class="name">充值活动：</div>
        <div class="content">
          <el-select
            style="width: 100%"
            v-model="rechargeActivityValue"
            placeholder="请选择充值活动"
            :loading="loading"
            clearable
            @clear="delValue"
          >
            <el-option
              v-for="item in gradeRechargeDatas"
              :key="item.recharge_activities_id"
              :label="item.name"
              :value="item.recharge_activities_id"
            >
            </el-option>
          </el-select>
          <!-- <el-select v-model="rechargeActivityValue" v-for="(item,index) in gradeRechargeDatas" :key="item.recharge_activities_id" placeholder="请选择充值活动"
                       @change="((value)=>{handleSelectClick(value, index)})">
              <el-option :label="item.name" :value="item.recharge_activities_id"></el-option>
            </el-select> -->
        </div>
      </div>
      <div class="grade">
        <div class="name">支付方式：</div>
        <div class="content">
          <el-select
            style="width: 100%"
            v-model="rechargeParam.paymentMethodValue"
            placeholder="请选择支付方式"
          >
            <el-option
              v-for="item in paymentMethod"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="grade">
        <div class="name">充值金额：</div>
        <div class="content">
          <el-input
            style="width: 60%"
            v-model="rechargeParam.rechargeAmount"
            placeholder="请输入充值金额"
          ></el-input>
          <div class="gives">
            送：
            <el-input
              v-model="rechargeParam.giveInput"
              :disabled="true"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="grade">
        <div class="name">额外赠送：</div>
        <div class="content">
          <el-input
            style="width: 100%"
            v-model="rechargeParam.giveAmount"
            placeholder="请输入额外赠送金额"
          ></el-input>
        </div>
      </div>
      <div class="grade">
        <div class="name">备注信息：</div>
        <div class="content">
          <el-input
            style="width: 100%"
            v-model="rechargeParam.rechargeRemarks"
            placeholder="输入备注信息"
          ></el-input>
        </div>
      </div>
      <div class="dialog-footer">
        <el-button @click="changeDrawer(false)">取 消</el-button>
        <el-button type="primary" @click="submitRecharge">确 定</el-button>
      </div>
    </Container>
  </el-dialog>
</template>
<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    drawer_title: "",
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
    gradeRechargeDatas: {},
  },
  data() {
    return {
      clearable:true,
      loading: false,
      grade: [
        {
          value: "1",
          label: "白银",
        },
        {
          value: "2",
          label: "黄金",
        },
      ],
      paymentMethod: [
        {
          value: "1",
          label: "现金",
        },
        {
          value: "3",
          label: "微信",
        },
        {
          value: "2",
          label: "支付宝",
        },
        {
          value: "8",
          label: "江西银行",
        },
         {
          value: "9",
          label: "工商银行",
        },
      ],
      gradeValue: "", // 选择会员等级
      gradeRemarks: "", // 会员升级的备注信息
      rechargeActivityValue: "", // 充值活动
      rechargeParam: {
        paymentMethodValue: "", // 支付方式
        rechargeAmount: "", // 充值金额
        allmoney: "",
        giveInput: "", // 活动赠送金额
        giveAmount: "", // 额外赠送金额
        rechargeRemarks: "账户充值", // 余额充值的备注信息
      },
      drawer: false,
    };
  },
  watch: {
    rechargeActivityValue: {
      handler(val, oldVal) {
        //console.log("val",val);
        this.gradeRechargeDatas.findIndex((value, index, arr) => {
         // console.log("value",value);
          if (value["recharge_activities_id"] === val) {
            // console.log("vaky",value);
            this.rechargeParam.giveInput = value["give_amount"];
             this.rechargeParam.rechargeAmount = value["amount"];
          }
          //return value.time == val;
        });
      },
      deep: true,
    },
  },
  methods: {
    submitRecharge() {
      
       if (this.rechargeParam.rechargeAmount == "" || this.rechargeParam.rechargeAmount  == null) {
        this.$alert("请输入充值金额！");

        return;
      }

       if (this.rechargeParam.paymentMethodValue == "" || this.rechargeParam.paymentMethodValue  == null) {
        this.$alert("请选择支付方式！");

        return;
      }
      this.$emit("submitrecharge", this.rechargeParam);
      // console.log("1212", this.rechargeParam);
    },
    changeDrawer(val) {
      this.drawer = val;
    },
    delValue(){
      this.rechargeParam.giveInput ="";
      this.rechargeParam.rechargeAmount ="";
    }
  },
};
</script>
<style lang="scss" scoped>
.patient-detail-base {
  .clearfix {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #ebeef5;
    margin-bottom: 15px;

    .title {
      font-size: 16px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      line-height: 22px;
      margin-right: 20px;
    }

    .btn {
      margin-right: 5px;
    }
  }

  .list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2% 10%;

    .item {
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      padding: 0 10px;

      .price {
        font-size: 18px;
        color: #5b5b5b;
        line-height: 30px;
      }

      .name {
        font-size: 14px;
        line-height: 30px;
      }
    }
  }
}

.grade {
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-bottom: 10px;

  .name {
    white-space: nowrap;
    margin-right: 5px;
    font-size: 14px;
  }
  .content {
    width: 80%;
    display: flex;
    align-items: center;
    font-size: 14px;

    .gives{
      margin-left: 22px;
      width: calc(100% - 66%);
      display: flex;
      align-items: center;
    }
  }
}
</style>
