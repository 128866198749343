<template>
	<Container class="appointment-main-operation hb-layout-horizontal">
		<div style="width: 100%">
			<Container class="see-patient-history-list-item hb-layout-vertical">
				<Container class="body">
					<Container class="inner hb-layout-horizontal">
						<template v-if="tabDatas === 91">
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">
										<span class="required-flag">* </span>
										<span>用户名</span>
									</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input v-model="selectData.username" placeholder="请输入登录用户名"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">
										<span class="required-flag">* </span>
										<span>登录密码</span>
									</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input type="password" v-model="selectData.password" placeholder="请输入登录密码"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">
										<span class="required-flag">* </span>
										<span>确认密码</span>
									</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input type="password" v-model="confirm_password" placeholder="请输入确认密码"/>
									</div>
								</el-col>
							</el-row>
						</template>
						<template v-if="tabDatas !== 91">
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">
										<span class="required-flag">* </span>
										<span>姓名</span>
									</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input v-model="selectData.nickname" placeholder="真实姓名"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">英文姓名</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input v-model="selectData.english_name" placeholder="请输入英文姓名"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">
										<span class="required-flag">* </span>
										<span>联系电话</span>
									</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input v-model="selectData.mobile" placeholder="请输入联系电话"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">微信号</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input v-model="selectData.wchat" placeholder="请输入微信号"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">QQ号</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input v-model="selectData.qq" placeholder="请输入QQ号"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">
										<span class="required-flag">* </span>
										<span>邮箱</span>
									</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input v-model="selectData.email" placeholder="请输入邮箱"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple grid-textarea">备注</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light">
										<el-input type="textarea" :rows="6" placeholder="请输入备注"
										          v-model="selectData.profile"/>
									</div>
								</el-col>
							</el-row>
							<el-row type="flex" align="middle">
								<el-col :span="6">
									<div class="grid-content bg-purple">显示</div>
								</el-col>
								<el-col :span="18">
									<div class="grid-content bg-purple-light" style="padding-left: 15px">
										<el-radio-group v-model="selectData.is_delete">
											<el-radio :label="0">显示</el-radio>
											<el-radio :label="1">不显示</el-radio>
										</el-radio-group>
									</div>
								</el-col>
							</el-row>
						</template>
					</Container>
					<div class="btn">
						<el-button type="success" class="edit-button" @click="onSubmitItemData">提交</el-button>
					</div>
				</Container>
			</Container>
		</div>
	</Container>
</template>

<script>
import {components} from "@/helper";

export default {
	components: {
		...components(["Container"]),
	},
	props: {
		treeDatas: {},
		rolesData: {},
		departmentData: {},
		tabDatas: 0,
	},
	watch: {
		treeDatas: function (val) {
			if (val.length > 0) {
				this.treeSelectData = this.getTreeData(this.arraytotree(val)); //去除最后children为空
			}
		},
	},
	data() {
		return {
			selectData: {
				doctor_id: null,
				nickname: null,
				english_name: null,
				title: null,
				department_id: null,
				roles_id: null,
				mobile: null,
				email: null,
				qq: null,
				wchat: null,
				is_delete: null,
				profile: null,
			},
			confirm_password: null, // 确认密码
		};
	},
	
	methods: {
		
		handleSelectItemss(itemData) {
			this.selectData = itemData;
		},
		
		/**
		 * 选中数据，修改密码，数据处理
		 * @param itemData
		 */
		handlePassSelectItems(itemData) {
			this.selectData = itemData;
		},
		
		/**
		 * 数据选中处理
		 * @param itemData
		 * @param treeData
		 */
		onSelectData(itemData, treeData) {
			this.selectData = itemData;
			this.parentValue = itemData.parent_id;
		},
		
		onAppendSelectItem() {
			this.selectData = {};
		},
		
		/**
		 * 数据提交
		 */
		onSubmitItemData() {
			let me = this,
				data = {
					doctor_id: me.selectData.doctor_id
				};
			
			if (me.tabDatas === 91) {
				
				if (!Boolean(me.selectData.username)) {
					me.$alert("请输入登录用户名");
					return;
				}
				
				if (!Boolean(me.selectData.password)) {
					me.$alert("请输入登录密码");
					return;
				}
				
				if (!Boolean(me.confirm_password)) {
					me.$alert("请输入确认密码");
					return;
				}
				
				if (me.selectData.password !== me.confirm_password) {
					me.$alert("密码不一致，请重新输入");
					return;
				}
				
				data = {
					...data,
					username: me.selectData.username,
					password: me.selectData.password,
				};
			} else {
				
				if (!Boolean(me.selectData.nickname)) {
					me.$alert("请输入管理员姓名");
					return;
				}
				
				if (!Boolean(me.selectData.mobile)) {
					me.$alert("请输入联系电话");
					return;
				}
				
				if (!Boolean(me.selectData.email)) {
					me.$alert("请输入邮箱");
					return;
				}
				
				data = {
					...data,
					nickname: me.selectData.nickname,
					english_name: me.selectData.english_name,
					title: "管理员",
					department_id: 0,
					roles_id: 5, //默认管理员
					mobile: me.selectData.mobile,
					email: me.selectData.email,
					qq: me.selectData.qq,
					wchat: me.selectData.wchat,
					is_delete: me.selectData.is_delete,
					profile: me.selectData.profile,
				};
			}
			
			me.$emit("submititemdata", data);
		},
	},
};
</script>

<style lang="scss" scoped>
.appointment-main-operation {
	width: 99%;
	height: calc(100vh - 210px);
	box-shadow: 0 2px 7px 0 #d8d8d8;
	margin: 2px 0px 10px 2px;
}

.see-patient-history-list-item {
	width: 100%;
	
	> .header {
		padding: 0 10px;
		height: 50px;
		line-height: 50px;
		background-color: #ededed;
		border-bottom: 1px solid #e1e1e1;
		
		> .time {
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #5b5b5b;
		}
		
		> .button {
			height: 30px;
			margin-top: 12px;
		}
	}
	
	> .body {
		padding: 10px;
		
		.inners {
			display: flex;
			flex-direction: column;
			
			.row-select {
				margin-bottom: 7px;
			}
		}
		
		> .inner {
			display: flex;
			flex-direction: column;
			border: 1px solid #979797;
			
			.el-row {
				width: 100%;
				height: auto;
				line-height: 38px;
				border-bottom: 1px solid #979797;
				font-size: 12px;
				
				&:last-child {
					border-bottom: none;
				}
			}
			
			.bg-purple {
				text-align: center;
				background: #ebebeb;
				border-right: 1px solid #979797;
			}
			
			.grid-textarea {
				height: 138px;
				line-height: 138px;
			}
			
			.bg-purple-light-select {
				padding: 0;
				font-size: 12px;
			}
			
			.required-flag {
				color: #ff0000;
				font-weight: bold;
			}
		}
		
		.title {
			line-height: 34px;
			margin-top: 10px;
			font-size: 16px;
		}
	}
	
	.btn {
		display: flex;
		justify-content: flex-end;
		
		.edit-button {
			margin-top: 30px;
		}
	}
}
</style>
