<template>
	<Container class="see-detail-test-inspect">
		<template v-for="({ edit, remark, inspection_template_id } , index) in rows">
			<el-row v-if="!edit" style="width: 100%;margin-bottom:5px" :key="index">
				<el-col :span="3">
					<el-cascader
						:options="inspection_template"
						:show-all-levels="false"
						:props="{label: 'name', value: 'inspection_template_id' ,emitPath: false }"
						:disabled="true"
						@input="value => onItemInput(index , value)"
						v-model="rows[index].inspection_template_id"
						clearable>
					</el-cascader>
				</el-col>
				<el-col :span="21">
					<el-row style="width: 100%;border: none;">
						<el-col :span="18">
							<el-input disabled class="grid-left" :value="remark" placeholder="填写备注"></el-input>
						</el-col>
						<el-col :span="3" @click.native="onUpdateButtonClick(index)" class="hb-layout-center" style="height:40px;color: #ff0000;">
							<span>修改</span>
						</el-col>
						<el-col :span="3" @click.native="onDeleteButtonClick(index)" class="hb-layout-center" style="height:40px;color: #ff0000;">
							<span>删除</span>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
			<el-row v-else style="width: 100%;margin-bottom:5px" :key="index">
				<el-col :span="3">
					<el-cascader
						:options="inspection_template"
                        :show-all-levels="false"
						:props="{label: 'name', value: 'inspection_template_id' ,emitPath: false }"
						@input="value => onItemInput(index , value)"
                        v-model="rows[index].inspection_template_id"
						clearable>
					</el-cascader>
				</el-col>
				<el-col :span="21">
					<el-row style="width: 100%;border: none;">
						<el-col :span="21">
							<el-input class="grid-left" :value="remark" @input="value => onRemarkInput(index , value)" placeholder="填写备注"></el-input>
						</el-col>
						<el-col :span="3" @click.native="onSaveButtonClick(index)" class="hb-layout-center" style="height:40px;color: #ff0000;">
							<span>保存</span>
						</el-col>
					</el-row>
				</el-col>
			</el-row>
		</template>
	</Container>
</template>

<script>
	import {
		components
	} from '@/helper';

	export default {
		components: components([
			'Container'
		]),
    props:{
      inspection_template:{
        type: Array,
        default:  () => []
      },
      inspection_record:{
        type: Array,
        default:  () => []
      }
    },
		data() {
			return {
				rows: [],
				value: ''
			}
		},
    watch:{
      inspection_record: function (val){
        //初始化数据
        if(val.length > 0){
          this.rows = val.map((item) =>{
            return {
              inspection_record_id: item.inspection_record_id,
              inspection_template_id: item.inspection_template_id,
              remark:  item.remark,
              image:  item.remark,
              edit: false
            }
          });


        }
      }
    },
		methods:{

			add(){

				this.rows.push({
          inspection_record_id: '',
          inspection_template_id: undefined,
          remark:  '',
          image:  '',
          edit: true
				}) ;
			},

			// getItemLabel(values){
      //
			// 	let {
			// 		inspectOptions
			// 	} = this,
			// 	label = '';
			// 	for(let value of values){
      //
			// 		for(let {
			// 			value:itemValue,
			// 			label:itemLabel,
			// 			children
			// 		} of inspectOptions){
      //
			// 			if(itemValue === value){
      //
			// 				label = itemLabel ;
      //
			// 				inspectOptions = children ;
      //
			// 				break ;
			// 			}
			// 		}
			// 	}
      //
			// 	return label ;
			// },
      /**
       * 选择框的方法
       * @param index
       * @param item
       */
			onItemInput(index , item){
				this.rows[index].inspection_template_id = item ;
			},

      /**
       * 备注填写的方法
       * @param index
       * @param remark
       */
			onRemarkInput(index , remark){

				this.rows[index].remark = remark ;
			},

      /**
       * 修改的方法
       * @param index
       */
			onUpdateButtonClick(index){

				this.rows[index].edit = true ;
			},

      /**
       * 保存的方法
       * @param index
       */
			onSaveButtonClick(index){

				this.rows[index].edit = false ;
			},

      /**
       * 删除的方法
       * @param index
       */
			onDeleteButtonClick(index){

				this.rows.splice(index , 1) ;
			},

      /**
       * 获取检验检查
       * @returns {*}
       */
      getRows(){
			  //重组，修改看诊记录保存是所需的结构
			  return this.rows.map((item)=>{
			    return {
            inspection_record_id: item.inspection_record_id,
            inspection_template_id: item.inspection_template_id,
            remark: item.remark,
            image: item.image
          }
        });

      }
		}
	};
</script>

<style lang="scss" scoped>
	$see-detail-test-inspect-item-title-width:132px;

	$see-detail-test-inspect-item-height:45px;

	.see-detail-test-inspect {
		.el-row {
			width: 100%;
			// border: 1px solid #979797;
			border-bottom: none;

			&:last-child {
				margin-bottom: 0;
				// border-bottom: 1px solid #979797;
			}
		}

		.basic-inspect {
			width: 100%;
			height: 40px;
			line-height: 40px;
			text-align: center;
		}

		.bg-purple {
			background: #e5e9f2;
		}

		.bg-purple-light {
			background: #ffffff;
			// border-Left: 1px solid #979797;
			// border-right: 1px solid #979797;
		}

		.purple-light {
			// border-Left: 1px solid #979797;
		}

		.grid-content {
			height: 45px;
			line-height: 45px;
			text-align: center;
		}

		.grid-left {
			text-align: left;
			padding-left: 10px;
		}

		.grid-right {
			text-align: right;
			padding-right: 20px;
			color: #ff0000;
			// text-decoration: underline;
			position: absolute;
			right: 0;
			top: 0;
		}

		.row-bg {
			padding: 10px 0;
			background-color: #f9fafc;
		}
	}
</style>
