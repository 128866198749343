/**
 * 获取一个病历信息 API
 */

 import axios from 'axios';
 import qs from 'qs';
 import serverConfig from "@/../config/index";
 
 
 let url = serverConfig.BASE_API + '/v2/payment/method/get.medical.basic.item/';
 
 export default (data) => {
 
     return axios.post(url, qs.stringify({
             ...data
         })).then(({
             data
         }) => data)
         .then(({
             data
         }) => data)
         .then(({
             item
         }) =>({
             age:item.age,
             real_name:item.real_name,
             sex:item.sex,
             medical_record_no:item.medical_record_no,
             discount:item.get_user.get_user_level.discount
         }));
 }