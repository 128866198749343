<template>
<Container class="statistics">
    <div class="text item">
        <el-table ref="list" :data="tableData" highlight-current-row stripe @row-click="detailsDrawer()" height="calc(100vh - 265px)" style="width: 100%">
            <el-table-column fixed type="index" width="40"></el-table-column>

            <el-table-column fixed prop="stockin_no" label="入库单号" min-width="220" align="left"></el-table-column>
            <el-table-column prop="getAttribute.name" label="入库类型" min-width="150" align="left"></el-table-column>
            <el-table-column prop="getWarehouse.name" label="入库仓库" min-width="150" align="left"></el-table-column>

            <el-table-column prop="create_name" label="入库人" min-width="150" align="left"></el-table-column>

            <el-table-column prop="product_num" label="种类数量" min-width="150" align="left"></el-table-column>
            <el-table-column prop="total_num" label="入库总数量" min-width="150" align="left"></el-table-column>

            <el-table-column prop="remarks" label="备注" min-width="150" align="left"></el-table-column>

            <el-table-column prop="create_time" label="入库日期" min-width="180" align="left"></el-table-column>
        </el-table>
    </div>

    <el-drawer title="入库详情" :visible.sync="detailsDrawer" size="65%" style="fontSize:20px;">
        <WarehousingDetails></WarehousingDetails>
    </el-drawer>
</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: {
        ...components(["WarehousingDetails"], "pharmacy.Warehousing"),
        ...components(["Container"]),
    },

    props: {
        tableData: {}
    },

    data() {
        return {
            detailsDrawer: false
        };
    },
};
</script>

<style lang="scss" scoped>
.item {
    // margin-top: 10px;
}
</style>
