<template>
  <el-drawer
    :title="drawer_title"
    :visible.sync="drawer"
    :direction="direction"
    :size="size"
  >
    <Container class="patient-detail-doctor">
      <div class="details">
        <div class="header">时间:{{ data.start_time }}/{{ data.end_time }}</div>
        <el-card class="box-card card-details">
          <div slot="header" class="clearfix">
            <div class="title">生命体征</div>
          </div>
          <div class="form">
            <el-row style="width: 100%">
              <el-col :span="2">
                <div class="grid-content bg-purple">体温</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.body_temperature }}°C
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">呼吸</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.respiration }}次/分钟
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">血压</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.blood_pressure }}ph
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">心率</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.heart_rate }}次/分钟
                </div>
              </el-col>
            </el-row>
            <el-row style="width: 100%">
              <el-col :span="2">
                <div class="grid-content bg-purple">身高</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.height }}cm
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">体重</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.weight }}kg
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">头围</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.head_circumference }}cm
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">BMI</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">{{ data.vital_signs.bmi }}</div>
              </el-col>
            </el-row>
            <el-row style="width: 100%">
              <el-col :span="2">
                <div class="grid-content bg-purple">过敏史</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.allergics }}
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">既往史</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.past_history }}
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">家族史</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.familly_history }}
                </div>
              </el-col>
              <el-col :span="2">
                <div class="grid-content bg-purple">其他</div>
              </el-col>
              <el-col :span="4">
                <div class="grid-content bg-purple-light">
                  {{ data.vital_signs.other }}
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <el-card class="box-card card-details">
          <div slot="header" class="clearfix">
            <div class="title">基础检查</div>
          </div>
          <div class="form">
            <el-row style="width: 100%">
              <el-col :span="5">
                <div class="grid-content bg-purple">诊断</div>
              </el-col>
              <el-col :span="19">
                <div class="content bg-purple-light">
                  {{ data.impressions ? data.impressions.join("、") : "" }}
                </div>
              </el-col>
            </el-row>
            <el-row style="width: 100%">
              <el-col :span="5">
                <div class="grid-content bg-purple">科室</div>
              </el-col>
              <el-col :span="19">
                <div class="content bg-purple-light">呼吸科</div>
              </el-col>
            </el-row>
            <el-row style="width: 100%">
              <el-col :span="5">
                <div class="grid-content bg-purple">主诉</div>
              </el-col>
              <el-col :span="19">
                <div
                  class="content bg-purple-light"
                  v-for="(item, index) in data.chief_complaint"
                  :key="index"
                >
                  {{ item ? item.join("、") : "" }}
                </div>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <el-card class="box-card card-details">
          <div slot="header" class="clearfix">
            <div class="title">检验检查</div>
          </div>
          <div class="form">
            <el-row style="width: 100%">
              <el-col :span="5">
                <div class="grid-content bg-purple">采血化验</div>
              </el-col>
              <el-col :span="19">
                <div class="content bg-purple-light">白细胞过低</div>
              </el-col>
            </el-row>
            <el-row style="width: 100%">
              <el-col :span="5">
                <div class="grid-content bg-purple">雾化效果</div>
              </el-col>
              <el-col :span="19">
                <div class="content bg-purple-light">效果明显，下次继续</div>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <el-card class="box-card card-details">
          <div slot="header" class="clearfix">
            <div class="title">开药处方</div>
          </div>
          <div class="form">
            <el-row style="width: 100%">
              <el-col :span="5">
                <div class="grid-content bg-purple">阿莫西林</div>
              </el-col>
              <el-col :span="19">
                <div class="content bg-purple-light purple-light">3盒</div>
              </el-col>
            </el-row>
            <el-row style="width: 100%">
              <el-col :span="5">
                <div class="grid-content bg-purple">板蓝根颗粒</div>
              </el-col>
              <el-col :span="19">
                <div class="content bg-purple-light purple-light">2盒</div>
              </el-col>
            </el-row>
          </div>
        </el-card>

        <el-card class="box-card card-details">
          <div slot="header" class="clearfix">
            <div class="title">医生医嘱</div>
          </div>
          <div class="form">
            <el-row style="width: 100%; mix-height: 100px">
              <el-col :span="24">
                <div class="remarks">{{ data.medical_advice }}</div>
              </el-col>
            </el-row>
          </div>
        </el-card>
      </div>
    </Container>
  </el-drawer>
</template>

<script>
import { components } from "@/helper";

export default {
  components: components(["Container"]),
  props: {
    data: {},
    direction: {
      type: String,
      default: "rtl",
    },
    drawer_title: "",
    size: {
      type: String,
      default: "30%",
    },
  },
  data() {
      return {
          drawer:false
      }
  },
  methods: {
    changeDrawer(v) {
      //console.log("112");
      this.drawer = v;
    },
  },
};
</script>

<style lang="scss" scoped>
.patient-detail-doctor {
  .details {
    padding: 0 20px;
    height: calc(100vh - 110px);
    overflow: auto;

    .card-details {
      margin-top: 15px;

      .clearfix {
        .title {
          font-size: 16px;
        }
      }
    }

    .header {
      line-height: 40px;
      font-size: 14px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
      border: 1px solid #e1e1e1;
      padding-left: 15px;
    }

    .form {
      .el-row {
        width: 100%;
        border-top: 1px solid #979797;
        border-left: 1px solid #979797;

        &:last-child {
          margin-bottom: 0;
          border-bottom: 1px solid #979797;
        }
      }

      .bg-purple-light {
        border-left: 1px solid #979797;
        border-right: 1px solid #979797;
      }

      .purple-light {
        text-align: right;
        padding-right: 20px;
      }
      .bg-purple {
        background: #ebebeb;
      }

      .grid-content {
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        text-align: center;
        overflow: hidden;
        white-space: nowrap;
      }

      .content {
        height: 45px;
        line-height: 45px;
        font-size: 14px;
        padding-left: 15px;
      }

      .remarks {
        border-right: 1px solid #979797;
        min-height: 90px;
        padding: 10px;
        font-size: 14px;
      }

      .row-bg {
        padding: 10px 0;
      }
    }
  }
}

.el-tag.el-tag--info {
  margin: 0 9px 9px 0;
}
</style>
