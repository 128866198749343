import axios from 'axios' ;

/**
 * 科室分类获取
 */
export default () =>{

    return axios.get('https://api.demo.hobooa.com/mock/91/api/v2/clinical_department/method/get.clinical.department.item/').then(({
        data
    }) => data)
    .then(({
        data
    }) => data)
    .then(({
        items
    }) => items.map(({
        clinical_department_id:id,
		name
    }) =>({
		id,
        name:name
    })));
}