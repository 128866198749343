<template>
    <Container class="patient-detail-doctor">
        <div class="card">
            <div class="header">
                <div class="name">角色名称：</div>
                <el-input v-model="nameInput" placeholder="请输入角色名称"></el-input>
            </div>
            <div class="checkbox">
                <div class="name">选择权限：</div>
                 <el-checkbox-group v-model="checkList">
                    <el-checkbox label="工作台"></el-checkbox>
                    <el-checkbox label="执行站"></el-checkbox>
                    <el-checkbox label="预约管理"></el-checkbox>
                    <el-checkbox label="预约日历"></el-checkbox>
                    <el-checkbox label="患者管理"></el-checkbox>
                    <el-checkbox label="看真管理"></el-checkbox>
                    <el-checkbox label="库房管理"></el-checkbox>
                    <el-checkbox label="药房管理"></el-checkbox>
                    <el-checkbox label="收费管理"></el-checkbox>
                    <el-checkbox label="财务统计"></el-checkbox>
                    <el-checkbox label="设置"></el-checkbox>
                </el-checkbox-group>
            </div>
        </div>
        
        <div class="btn">
            <el-button type="primary">提交</el-button>
         </div>
    </Container>

</template>

<script>
import {
  components
} from '@/helper';

export default {
 
  components: components([
    'Container'
  ]),

    data(){
        return{
           nameInput: '' ,
           checkList: [],
        }
    },
    methods: {
        
    },
}
</script>

<style lang="scss" scoped>
.card{
    padding: 20px;
    border: 1px solid #ebebeb;
    // height: calc(100vh - 81px);
}
.header{
    display: flex;
    align-items: center;
    margin-bottom: 20px;
}
.name{
    white-space: nowrap;
    margin-right: 6px;
    font-size: 14px;
}
.checkbox{
    display: flex;
}
.el-checkbox-group {
    display: flex;
    flex-direction: column;
}
.el-checkbox{
    padding-bottom: 10px;
}
.btn{
    // margin-top: 60px;
    padding: 60px 20px;
    display: flex;
    justify-content: flex-end;
}
</style>
