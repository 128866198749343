<template>
  <Container class="details hb-layout-horizontal">
    <Container class="centent hb-layout-flex-1 hb-layout-vertical">
      <LeftMain
        :schedulingDatas="schedulingDatas"
        :rolesDatas="rolesDatas"
        :departmentDatas="departmentDatas"
        :doctorDatas="doctorDatas"
        @schedulingplan="onSchedulingPlan"
        @roleschange="onRolesChange"
      ></LeftMain>
    </Container>
    <Container class="right hb-layout-vertical">
      <RightMain
        :classesDatas="classesDatas"
        :todaySchedulinngDatas="todaySchedulinngDatas"
        @classesplanchange="onClassesPlanChange"
      ></RightMain>
    </Container>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Left.Main", "Right.Main"], "configuration.Scheduling"),
    ...components(["Container"]),
  },

  props: {
    todaySchedulinngDatas: {},
    doctorDatas: {},
    departmentDatas:{},
    rolesDatas: {},
    schedulingDatas: {},
    classesDatas: {},
  },

  data() {
    return {};
  },

  methods: {
    onSchedulingPlan(data) {
      this.$emit("schedulingplan", data);
    },
    onClassesPlanChange(val,departmentValue) {
      this.$emit("classesplanchange", val,departmentValue);
    },
    onRolesChange(val) {
      this.$emit("roleschange", val);
    },
  },
};
</script>

<style lang="scss" scoped>
.details {
  .right {
    width: 300px;
  }
}
</style>
