<template>
  <Container>
    <div class="screen">
      <div class="left-details">
        <div class="add margin">
          <el-button type="primary" @click="changeDrawer(true)">新增物资</el-button>
        </div>
        <div class="select margin">
          <el-select v-model="categoryValue" placeholder="请选择类目">
            <el-option
              v-for="item in storehouseCateDatas"
              :key="item.storehouse_category_id"
              :label="item.name"
              :value="item.storehouse_category_id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="select margin">
          <el-input
            v-model="search"
            placeholder="搜索物资名称"
            class="input-with-select search"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              :disabled="loading"
              @click="handleFormSubmit(true)"
            ></el-button>
          </el-input>
        </div>
        <div class="select margin">
          <el-button icon="el-icon-refresh" @click="handleFormReset">重置 </el-button>
        </div>
      </div>
    </div>

    <AddMaterial
      @submitmaterial="onSubmitAddMaterial"
      ref="add_drawer"
      :direction="direction"
      :size="size"
      :drawer_title="drawer_add_title"
      :brandDatas="brandDatas"
      :supplierDatas="supplierDatas"
      :factoryDatas="factoryDatas"
      :unitList="smallunitDatas"
      :storehouseCateDatas="storehouseCateDatas"
    ></AddMaterial>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
    ...components(["AddMaterial"], "storage.Material.Left"),
  },
  props: {
    loading: {
      default: false,
    },
    storehouseCateDatas: {},
    brandDatas: {},
    supplierDatas: {},
    factoryDatas: {},
    smallunitDatas: {},
  },
  watch: {
    search: {
      handler(val, oldVal) {
        this.paramForm.keywords = val;
      },
      deep: true,
    },
    categoryValue: {
      handler(val, oldVal) {
        this.paramForm.categoryValue = val;
      },
      deep: true,
    },
    manufactorValue: {
      handler(val, oldVal) {
        this.paramForm.manufactorValue = val;
      },
      deep: true,
    },
    supplierValue: {
      handler(val, oldVal) {
        this.paramForm.supplierValue = val;
      },
      deep: true,
    },
    brandValue: {
      handler(val, oldVal) {
        this.paramForm.brandValue = val;
      },
      deep: true,
    },
  },
  data() {
    return {
      size: "70%",
      drawer_title: "编辑物资",
      drawer_add_title: "新增物资",
      direction: "rtl",
      search: "",
      categoryValue: "",
      manufactorValue: "",
      supplierValue: "",
      brandValue: "",
      paramForm: {
        keywords: undefined,
        categoryValue: undefined,
        manufactorValue: undefined,
        supplierValue: undefined,
        brandValue: undefined,
      },
    };
  },
  methods: {
    changeDrawer(v) {
      let me = this;
      me.$refs.add_drawer.changeDrawer(v);
    },
    onSubmitAddMaterial(data) {
      let me = this;
      me.$emit("submitmaterial", data);
    },
    handleFormSubmit(isRestore = false) {
      let me = this;
      me.$emit("submit", me.paramForm, isRestore);
    },
    handleFormReset() {
      let me = this;
      me.search = "";
      me.categoryValue = "";
      me.$emit("submitreset", me.paramForm);
    },
  },
};
</script>

<style lang="scss" scoped>
.screen {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left-details {
    display: flex;
    align-items: center;

    .date {
      width: 240px;
    }

    .margin {
      margin-right: 10px;
    }
  }
}

.el-range-editor.el-input__inner {
  width: 100%;
}
</style>
