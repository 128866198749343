/**
 * 获取一个病历信息 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/treatments/method/get.treatments.list';

export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        })).then(({
            data
        }) => data)
        .then(({
            data
        }) => (data))
        .then(({
            items
        }) => items.map(({
            getTreatmentTemplate,
            count,
            treatments_id,
            remark,
            status
        }) => ({
            name:getTreatmentTemplate.name,
            englishName:getTreatmentTemplate.english_name,
            count:count,
            unit:getTreatmentTemplate.unit,
            price:getTreatmentTemplate.price,
            remark:remark,
            e_ID:treatments_id,
            tatus:status
        })));
}