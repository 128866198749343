<template>
  <Container class="appoientment-main-operation hb-layout-horizontal">
    <div style="width: 100%">
      <Container class="see-patient-history-list-item hb-layout-vertical">
        <Container class="body">
          <Container class="inner hb-layout-horizontal">
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple"><span style="color:#ff0000;font-weight: bold;">*</span>  充值优惠</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input v-model="selectData.name" placeholder="例：充1000送100"></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">充值金额(¥)</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.amount"
                    placeholder="请输入充值金额"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
              <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">赠送金额(¥)</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    v-model="selectData.give_amount"
                    placeholder="请输入赠送金额"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple grid-textarea">备注</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light">
                  <el-input
                    type="textarea"
                    :rows="4"
                    v-model="selectData.remind"
                    placeholder="请输入备注"
                  ></el-input>
                </div>
              </el-col>
            </el-row>
            <el-row type="flex" align="middle">
              <el-col :span="6">
                <div class="grid-content bg-purple">显示</div>
              </el-col>
              <el-col :span="18">
                <div class="grid-content bg-purple-light" style="padding-left: 15px">
                  <el-radio-group v-model="selectData.is_delete">
                    <el-radio :label="0">显示</el-radio>
                    <el-radio :label="1">不显示</el-radio>
                  </el-radio-group>
                </div>
              </el-col>
            </el-row>
          </Container>
          <div class="btn">
            <el-button type="success" class="edit-buttom" @click="onSubmitItemData"
              >提交</el-button
            >
          </div>
        </Container>
      </Container>
    </div>
  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Container"]),
  },
  props: {
    treeDatas: {},
  },
  watch: {
    treeDatas: function (val) {
      if (val.length > 0) {
        this.treeSelectData = this.getTreeData(this.arraytotree(val)); //去除最后children为空
      }
    },
  },
  data() {
    return {
      selectData: [],
      Data: {
        recharge_activities_id: "",
        name: "",
        amount: "",
        remind: "",
        give_amount: "",
      },
      name: "",
    };
  },
  methods: {
    handleSelectItemss(itemData) {
      this.selectData = itemData;
    },

    onSubmitItemData() {
      if ( this.selectData.name== "" ||  this.selectData.name== null) {
        this.$alert("请输入充值优惠名称");

        return;
      }
      let data = {
        recharge_activities_id: this.selectData.recharge_activities_id,
        name: this.selectData.name,
        amount: this.selectData.amount,
        give_amount: this.selectData.give_amount,
        is_delete: this.selectData.is_delete,
        remind: this.selectData.remind,
      };
      //console.log("1212",data);
      this.$emit("submititemdata", data);
    },
    onAddSubmit() {
      this.selectData = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.appoientment-main-operation {
  width: 99%;
  height: calc(100vh - 210px);
  box-shadow: 0 2px 7px 0 #d8d8d8;
  margin: 2px 0px 10px 2px;
}

.see-patient-history-list-item {
  width: 100%;

  > .header {
    padding: 0 10px;
    height: 50px;
    line-height: 50px;
    background-color: #ededed;
    border-bottom: 1px solid #e1e1e1;

    > .time {
      font-size: 12px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 500;
      color: #5b5b5b;
    }

    > .button {
      height: 30px;
      margin-top: 12px;
    }
  }

  > .body {
    padding: 10px;

    .inners {
      display: flex;
      flex-direction: column;

      .row-select {
        margin-bottom: 7px;
      }
    }

    > .inner {
      display: flex;
      flex-direction: column;
      border: 1px solid #979797;

      .el-row {
        width: 100%;
        height: auto;
        line-height: 38px;
        border-bottom: 1px solid #979797;
        font-size: 12px;

        &:last-child {
          border-bottom: none;
        }
      }

      .bg-purple {
        text-align: center;
        background: #ebebeb;
        border-right: 1px solid #979797;
      }
      .grid-textarea{
        height: 96px;
        line-height: 96px;
      }

      .bg-purple-light-select {
        padding: 0;
        font-size: 12px;
      }
    }

    .title {
      line-height: 34px;
      margin-top: 10px;
      font-size: 16px;
    }
  }

  .btn {
    display: flex;
    justify-content: flex-end;
    // position: fixed;
    // bottom: 30px;
    // right: 20px;

    .edit-buttom {
      margin-top: 30px;
    }
  }
}
</style>
