<template>
<Container class="botton-detail">
    <Container class="charge-details">
        <div class="box-card">
            <div>收费明细</div>
            <ChargeDetails></ChargeDetails>
        </div>
    </Container>
    <Container class="charge-details">
        <div class="box-card">
            <div>病种分析</div>
            <DiseaseAnalysis></DiseaseAnalysis>
        </div>
    </Container>
    <Container class="charge-details">
        <div class="box-card">
            <div>看病区域</div>
            <TreatmentArea></TreatmentArea>
        </div>
    </Container>
</Container>
</template>

<script>
import {
    components,
    layouts
} from "@/helper";

export default {
   
    components: {
        ...components([
            "ChargeDetails",
            "DiseaseAnalysis",
            "TreatmentArea"
            ], "workbench.Botton"),
        ...components(["Container"]),
    },
    data() {
        return {};
    },
    methods: {
        
    },
};
</script>

<style lang="scss" scoped>
.botton-detail{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    .charge-details{
        width: 32%;
        margin: 12px 20px;
        padding: 20px 30px;
        box-shadow: 0px 0px 10px 0px #C5C5C5;
        border-radius: 12px;

        .box-card{
            div{
                font-size: 16px;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #000000;
                line-height: 25px;
            }
        }
    }
}
</style>
