<template>
	<Container>
		<!-- 数据列表 -->
        <el-table class="table-list"  ref="list" :data="tableData" :highlight-current-row="true" stripe @sort-change="sortChange">
            <el-table-column fixed type="index" width="40"></el-table-column>
            <el-table-column fixed prop="stockin_no" label="入库单号" min-width="220" align="left"></el-table-column>
            <el-table-column prop="getAttribute.name" label="入库类型" min-width="180" align="left"></el-table-column>
            <el-table-column prop="getWarehouse.name" label="入库仓库" min-width="180" align="left"></el-table-column>
            <el-table-column prop="create_name" label="入库人" min-width="200" align="left"></el-table-column>
            <el-table-column prop="product_num" label="种类数量" min-width="150" align="left"></el-table-column>
            <el-table-column prop="total_num" label="入库总数量" min-width="150" align="left"></el-table-column>
            <el-table-column prop="remarks" label="备注" min-width="180" align="left"></el-table-column>
            <el-table-column prop="create_time" label="入库时间" width="160" align="left"></el-table-column>
        </el-table>
		<!-- 详情弹窗 -->
	    <el-drawer title="入库详情" :visible.sync="detailsDrawer" size="65%" style="fontsize: 20px">
	        <WarehousingDetails></WarehousingDetails>
	    </el-drawer>
	</Container>
</template>

<script>
import {
    components
} from "@/helper";

export default {
    components: {
        ...components(["WarehousingDetails"], "storage.Warehousing"),
        ...components(["Container"]),
    },

    props: {
        tableData: {},
        loading: {
            default: false,
        },
    },
    watch: {
        tableData: function (val) {
            //
        }
    },

    data() {
        return {
            detailsDrawer: false,
        };
    },
    methods: {
        sortChange({
            column,
            prop,
            order
        }) {
            let sort = {
                order_type: undefined,
                order_field: undefined,
            };

            if (column && order) {
                sort.order_type = order === "ascending" ? "asc" : "desc";
                sort.order_field = prop;
            }

            this.$emit("sort", sort);
        },
	    /**
	     * 详情弹窗
	     * @param row
	     */
	    showDetails(row) {
		    // 获取入库单详情
		    // 入库单号    val.stockin_id
		    // 入库单ID    val.stockin_no
		    //console.log('详情弹窗', row.stockin_id, row.stockin_no)
		   
		    let me = this
		    me.detailsDrawer = true
		    
	    },
	    
    },
};
</script>

<style lang="scss" scoped>
.table-list{
	height: calc(100vh - 265px);
	width: 100%;
}
</style>
