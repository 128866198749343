<template>
  <el-card class="box-card" shadow="hover" :class="{
      'see-patient-list-item':true,
        selected
    }" :body-style="{ padding: '0 10px' }">
    <el-row style="width: 100%" type="flex" justify="center" align="middle">
      <el-col :span="6">
        <div class="name">{{ name }}</div>
      </el-col>
      <el-col :span="5">
        <div class="name">预约({{add}})</div>
      </el-col>
      <el-col :span="5">
        <div class="name">到店({{add}})</div>
      </el-col>
      <el-col :span="4">
        <div class="name">待诊({{adds}})</div>
      </el-col>
      <el-col :span="4">
        <div class="name">已诊({{adds}})</div>
      </el-col>
    </el-row>

  </el-card>
</template>

<script>

import {
  components
} from '@/helper' ;

export default {
  components: components([
    'Container'
  ]),
  props: {
    header: {
      required: true,
      type: String
    },
    price: {
      required: true
    },
    name: {
      required: true,
      type: String
    },
    doctorId: {
      required: true,
      type: [
        String,
        Number
      ]
    },
    title: {
      required: true,
      type: [
        String
      ]
    },
    selected: {
      type: Boolean,
      default: false
    }
  },
  data(){
    return {
      lvlMap:{

      },
      statusMap: [
        {
          text: '全部',
          type: ''
        },
        {
          text: '在班',
          type: 'warning'
        },
        {
          text: '未在班',
          type: 'success'
        },
      ],
      order: 13,
      treated: 3
    }
  },
  computed:{
      add:function(){
        this.order=this.order+Math.floor(Math.random()*10);
        return this.order;
      },
      adds:function(){
        this.treated=this.treated+Math.floor(Math.random()*10);
        return this.treated;
      },
  },
}
</script>

<style lang="scss" scoped>

$see-patient-list-item-height: 80px;

	.see-patient-list-item {
		height: $see-patient-list-item-height;
    line-height: $see-patient-list-item-height;
		align-items: center;
		border-bottom: 1px solid #e1e1e1;


		&.selected {
			box-shadow: 0 2px 7px 0 #d8d8d8;
			margin: 2px 0;
      background: #92c2f957;
		}
    &:hover{
      background: #92c2f921;
      color: #2063b695;
    }

		.name {
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
			width: 100%;

		}

		>.grade {
			padding: 3px 12px;
			background: #F1BE5E;
			border: 1px solid #979797;
			margin-right: 15px;
		}
    .name{
      font-size: 12px;
    }
		.time {
			font-size: 12px;
			font-family: PingFangSC-Medium, PingFang SC;
			font-weight: 500;
			color: #0B7DFF;
			line-height: 15px;
      display: flex;
      justify-content: center;
		}

		>.time,
		.state {
			display: flex;
      justify-content: center;

		}
	}


</style>
