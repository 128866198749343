<template>
    <div class="hb-component menu-item hb-layout-center" :class="{
        selected
    }">
        <slot></slot>
    </div>
</template>

<script>

export default {
  props:{
      selected:{
          type:Boolean,
          default:false
      }
  }
}
</script>

<style lang="scss" scoped>

    .menu-item{
        &.selected{
            color:#75AEA7;
        }
    }

</style>