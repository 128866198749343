/**
 * 获取预约记录 API
 */

import axios from 'axios';
import qs from 'qs';
import serverConfig from "@/../config/index";


let url = serverConfig.BASE_API + '/v2/appointment_record/method/get.appointment.basic.list';
//let url = serverConfig.BASE_API + '/v2/payment/method/get.patient.basic.list/';



export default (data) => {

    return axios.post(url, qs.stringify({
            ...data
        }))
        .then(({
            data
        }) => data)
        .then(({
            data
        }) => data)
        .then(({
            items
        }) => items.map(({
            appointment_record_id,
            appointment_record_no,
            time,
            user_id,
            appointment_type,
            diagnosis_status,
            get_medical_record,
            get_user,
            get_user_level
        }) => ({
            appointment_record_id,
            appointment_record_no,
            age: get_medical_record.age,
            user_id: user_id,
            sex: get_medical_record.sex,
            name: get_medical_record.real_name,
            header: get_user.head_pic,
            member: "金卡",
            time: time,
            state: diagnosis_status,
            type: appointment_type,
            medical_record_no: get_medical_record.medical_record_no
        })));
}