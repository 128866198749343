<template>
  <Container>
    <Header
    ref="header"
      :appoinetDate="appoinetDate"
      :searchrecords="searchrecords"
      :doctors="doctors"
      @settinglist="onSettingList"
      @medicalrecordsearch="onMedicalRecordSearch"
      @appointmentsaveclick="onAppointmentSaveClick"
      :settingDatas="settingDatas"
      :departmentDatas="departmentDatas"
       @doctortimetreturn="onGetDoctorTimeReturn"
      :doctorDayTime="doctorDayTime"
      :doctordaylistData="doctordaylistData"
      :classesSettingData=" classesSettingData"
      :doctorClassesListData="doctorClassesListData"
      :doctorId="doctorId"
    ></Header>
    <Content
      :doctors="doctors"
      :tableData="appointmentrecords.items"
      @appointmentdetailchange="onAppointmentDetailChange"
      @emtpychange="onEmtpyChange"
      :departmentDatas="departmentDatas"
      @handstatechange="onHandStateChange"
  
    ></Content>

  </Container>
</template>

<script>
import { components } from "@/helper";

export default {
  components: {
    ...components(["Header", "Content"], "appointment.Center"),
    ...components(["Container"]),
  },
  props: {
    doctorClassesListData:{},
    classesSettingData:{},
    doctordaylistData:{},
    doctorDayTime:true,
    appoinetDate:"",//当天日期
    appointmentrecords: {},
    searchrecords: {},
    doctors: {},
    settingDatas:{},
    departmentDatas: {
      type: Array,
      default: () => [],
    },
    doctorId:""

  },

  data() {
    return {
      tableData: [
        {
          name: "James",
          sex: "男",
          age: "21岁",
          phone: "16797650082",
          state: "待诊",
          typeOfVisit: "初诊",
          department: "内科",
          doctor: "赵圣贤医生",
          appointmentStatus: "待确认", // 0、待确认  1、已确认   2、已取消
          arrivalTime: "2021-05-19 11:00",
          timeOfAppointment: "2021-05-19 11:30",
          visitTime: "2021-05-19 11:30",
        },
      ],
      departmentOptions: [
        {
          value: "1",
          label: "内科",
        },
        {
          value: "2",
          label: "外科",
        },
        {
          value: "3",
          label: "神经内科",
        },
      ],
      departmentValue: "",
    };
  },
  methods: {
    onDoctorButtonClick(plans) {
      this.plans = plans;
    },
    onHandStateChange(data){
       this.$emit("handstatechange",data)
    },
    onAppointmentDetailChange(val) {
      this.$emit("appointmentdetailchange", val);
    },
    onItemSelect(doctorId) {
      this.$emit("planclick", doctorId);
    },
    onEmtpyChange() {
      this.$emit("emptychange");
    },
    onMedicalRecordSearch(val) {
      //search(4):触发上级事件
      this.$emit("medicalrecordsearch", val);
    },
    onAppointmentSaveClick(val) {
      this.$emit("appointmentsaveclick", val);
    },
    onSettingList(){
       this.$emit("settinglist");
    },
    changeDrawer(val){
      this.$refs.header.changeDrawer(val);
    },
     onGetDoctorTimeReturn(val){
      this.$emit("doctortimereturn", val);
    }
  },
};
</script>

<style lang="scss" scoped>

</style>
